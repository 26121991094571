import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PractitionerType } from '@shared/enums/practitioner.enum';
import { Item } from '@shared/models/item.model';
import { Practitioner } from '@shared/models/practitioner.model';
import { DataPageWithNames } from '@shared/modules/general-commons/components/data-table/data-table.model';
import { StatisticsPlot, StatisticsTable } from '@shared/modules/statistics/statistics.model';
import { Observable } from 'rxjs';
import { PaginationSortingModel } from 'src/app/shared/components/table/models/pagination-sorting.model';
import { BaseHttpService } from 'src/app/shared/services/http/base-http.service';
import { HttpUtils } from 'src/app/utils/http.utils';
import { TreatmentCenterDetails } from './models/treatment-center-details.model';

@Injectable({
  providedIn: 'root'
})
export class TreatmentCenterService extends BaseHttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient, { baseUrl: 'orgtree/treatment-centers' });
  }

  getTreatmentCenterDetails(treatmentCenterId: number): Observable<TreatmentCenterDetails> {
    return this.httpClient.get<TreatmentCenterDetails>(`${this.baseUrl}/${treatmentCenterId}`);
  }

  getTreatmentCenterPractitioners(treatmentCenterId: number, pageSort?: PaginationSortingModel): Observable<DataPageWithNames<any>> {
    if (pageSort) {
      const params = HttpUtils.pageSortToParams(pageSort);
      return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}/${treatmentCenterId}/practitioners`, { params });
    }
    return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}/${treatmentCenterId}/practitioners`);
  }

  getTreatmentCenterUsers(treatmentCenterId: number, pageSort?: PaginationSortingModel): Observable<DataPageWithNames<any>> {
    if (pageSort) {
      const params = HttpUtils.pageSortToParams(pageSort);
      return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}/${treatmentCenterId}/users`, { params });
    }
    return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}/${treatmentCenterId}/users`);
  }

  getPractitionersByTreatmentCenterAndType(treatmentCenterId: number, practitionerType: PractitionerType): Observable<Practitioner[]> {
    return this.httpClient.get<Practitioner[]>(`${this.baseUrl}/${treatmentCenterId}/practitioners/${practitionerType}`);
  }

  getLanguagesByTcId(tcId: number): Observable<Item[]> {
    return this.httpClient.get<Item[]>(`${this.baseUrl}/${tcId}/languages`);
  }

  getTCStatistics(treatmentCenterId: number): Observable<StatisticsTable> {
    return this.httpClient.get<StatisticsTable>(`${this.baseUrl}/${treatmentCenterId}/statistics/table`);
  }

  getTCStatisticsPlot(treatmentCenterId: number): Observable<StatisticsPlot> {
    return this.httpClient.get<StatisticsPlot>(`${this.baseUrl}/${treatmentCenterId}/statistics/plot`);
  }
}
