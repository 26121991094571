import {
  emptyPartialValidationConfig,
  NestedPartialValidationConfigs,
  PartialValidationConfig
} from '@/src/app/shared/validation/partial-validation-config.model';
import { StxValidators } from '@/src/app/shared/validation/validators';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { updateValueAndValidityForControl } from './validation.utils';

function updateValueAndValidityForSelectedControls(formGroup: UntypedFormGroup, controlNames: string[]): void {
  controlNames.forEach(key => {
    updateValueAndValidityForControl(formGroup.get(key));
  });
}

function touchControlsByName(formGroup: UntypedFormGroup, controlNames: string[]): void {
  controlNames.forEach(name => {
    formGroup.get(name)?.markAsTouched();
  });
}

function touchControlsAndRemoveRequiredError(controls: AbstractControl[]): void {
  controls.forEach(control => {
    control.markAsTouched();
    if (control.hasError(StxValidators.requiredErrorName)) {
      const { required, ...otherErrors } = control.errors;
      control.setErrors(otherErrors);
    }
  });
}

function areFullyValidatedControlsValid(formGroup: UntypedFormGroup, controlNames: string[]): boolean {
  return controlNames.every(controlName => !!formGroup.get(controlName)?.valid);
}

function filterPartiallyValidatedControls(formGroup: UntypedFormGroup): AbstractControl[] {
  return Object.values(formGroup.controls ?? {})
    .filter(control => !(control instanceof UntypedFormGroup))
    .filter(control => !control.valid && !!control.errors)
    .filter(control => Object.keys(control.errors ?? {}).some(errorName => errorName !== StxValidators.requiredErrorName));
}

function hasNoPartialCrossFieldErrors(formGroup: UntypedFormGroup, crossFieldErrorNames: string[] = []) {
  if (crossFieldErrorNames?.length > 0) {
    formGroup.markAsTouched();
    return Object.keys(formGroup.errors ?? {}).filter(error => crossFieldErrorNames.includes(error)).length === 0;
  }
  return true;
}

function touchAndPartiallyValidateNestedFormGroups(rootFormGroup: UntypedFormGroup, rootConfig: PartialValidationConfig) {
  let nestedFormGroupsPartiallyValid = true;
  Object.entries(rootConfig.nestedPartialValidationConfigs ?? {}).forEach(
    ([nestedFormGroupName, nestedFormGroupConfig]: [string, PartialValidationConfig]) => {
      nestedFormGroupsPartiallyValid =
        nestedFormGroupsPartiallyValid &&
        touchAndPartiallyValidateControls(rootFormGroup.controls[nestedFormGroupName] as UntypedFormGroup, nestedFormGroupConfig);
    }
  );
  return nestedFormGroupsPartiallyValid;
}

export function touchAndPartiallyValidateControls(
  formGroup: UntypedFormGroup,
  config: PartialValidationConfig = { controlNamesForFullValidation: [] }
): boolean {
  touchControlsByName(formGroup, config.controlNamesForFullValidation);
  updateValueAndValidityForSelectedControls(formGroup, config.controlNamesForFullValidation);

  const fullyValidatedControlsValid = areFullyValidatedControlsValid(formGroup, config.controlNamesForFullValidation);

  const noPartialCrossFieldErrors = hasNoPartialCrossFieldErrors(formGroup, config.crossFieldErrorNames);

  const invalidControlsOmittingRequiredValidator = filterPartiallyValidatedControls(formGroup);
  touchControlsAndRemoveRequiredError(invalidControlsOmittingRequiredValidator);

  const controlsValidOmittingRequiredValidator = invalidControlsOmittingRequiredValidator.length === 0;

  const nestedFormGroupsPartiallyValid = touchAndPartiallyValidateNestedFormGroups(formGroup, config);

  return (
    fullyValidatedControlsValid && noPartialCrossFieldErrors && controlsValidOmittingRequiredValidator && nestedFormGroupsPartiallyValid
  );
}

export function populateWithDefaults(rootFormGroup: UntypedFormGroup, rootConfig: PartialValidationConfig): PartialValidationConfig {
  const nestedFormGroupNames: string[] =
    Object.keys(rootFormGroup.controls ?? {}).filter(controlName => rootFormGroup.controls[controlName] instanceof UntypedFormGroup) ?? [];

  const defaultConfigs: NestedPartialValidationConfigs = nestedFormGroupNames
    .filter(
      nestedFormGroupName =>
        rootConfig.nestedPartialValidationConfigs == null || rootConfig.nestedPartialValidationConfigs[nestedFormGroupName] == null
    )
    .map(nestedFormGroupNameForDefaultConfig => ({ [nestedFormGroupNameForDefaultConfig]: emptyPartialValidationConfig }))
    .reduce((resultObj, defaultConfig) => ({ ...resultObj, ...defaultConfig }), {});

  return {
    ...rootConfig,
    nestedPartialValidationConfigs: { ...rootConfig.nestedPartialValidationConfigs, ...defaultConfigs }
  };
}
