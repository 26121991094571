import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { PermissionModule } from '@core/permissions/permission.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';

@Component({
  selector: 'stx-simple-report-cell',
  templateUrl: './simple-report-cell.component.html'
})
export class SimpleReportCellComponent {
  @Input() rowInputs: string[];
  @Input() rowInputsToTranslate: string[];
  @Input() inlineInputs: string[];
  @Input() inlineInputsToTranslate: string[];
}

@NgModule({
  declarations: [SimpleReportCellComponent],
  imports: [CommonModule, TranslateModule, FlexLayoutModule, RouterModule, PermissionModule, GeneralCommonsModule],
  exports: [SimpleReportCellComponent]
})
export class SimpleReportCellComponentModule {}
