import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { PatientDetailsBlockMode } from '@shared/enums/patient-details-block.enum';
import { CountryDictionary } from '@shared/models/geo.model';
import { GeoService } from '@shared/services/geo.service';
import { DictionaryTranslation, PatientListItem } from '@src/app/features/patient/models/patient.model';
import { PatientDataService } from '@src/app/features/patient/services/patient-data.service';
import { PatientDictionariesService } from '@src/app/features/patient/services/patient-dictionaries.service';
import { combineLatest } from 'rxjs';

class PatientDetailsBlockData {
  readonly mode = PatientDetailsBlockMode.FORM_ON_TOP;

  constructor(
    public readonly patientListItem: PatientListItem,
    public readonly genders: DictionaryTranslation[],
    public readonly countries: CountryDictionary[]
  ) {}
}

@Component({
  templateUrl: './patient-aware-page.component.html',
  providers: [PatientDataService]
})
export class PatientAwarePageComponent extends BaseComponent {
  patientDetailsBlockData: PatientDetailsBlockData | null = null;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly geoService: GeoService,
    private readonly patientDataService: PatientDataService,
    private readonly patientDictionariesService: PatientDictionariesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
    super();
    this.subSink.sink = route.params.subscribe(params => {
      const patientId = params['patientId'];
      if (patientId === 'new') {
        patientDataService.load(null);
      } else {
        patientDataService.load(patientId);
      }
    });

    this.subSink.sink = combineLatest([
      patientDictionariesService.dictionaries$,
      geoService.getCountryDictionaries,
      patientDataService.patient$
    ]).subscribe(([dictionaries, countries, patient]) => {
      if (patient) {
        this.patientDetailsBlockData = new PatientDetailsBlockData(patient.patientDetailsBlockPatient, dictionaries.genders, countries);
      } else {
        this.patientDetailsBlockData = null;
      }
      changeDetectorRef.markForCheck();
    });
  }
}
