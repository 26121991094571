import { PermissionModule } from '@/src/app/core/permissions/permission.module';
import { RecordDateFilterComponentModule } from '@/src/app/features/reports/components/report-filters/components/record-date-filter/record-date-filter.component';
import { SearchModule } from '@/src/app/features/search/search.module';
import { FormModule } from '@/src/app/shared/components/form/form.module';
import { MaterialModule } from '@/src/app/shared/modules/material/material.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TreatmentStatusIndicatorComponentModule } from '@shared/components/treatment-status-indicator/treatment-status-indicator.component';
import { FormsCommonsModule } from '@shared/modules/forms-commons/forms-commons.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { TreatmentDateComponentModule } from '@src/app/features/reports/components/treatment-date/treatment-date.component';
import { SuspiciousRecordReasonDialogComponent } from './components/suspicious-record-reason-dialog/suspicious-record-reason-dialog.component';
import { SuspiciousRecordComponent } from './components/suspicious-record/suspicious-record.component';
import { SuspiciousRecordsComparisonPageComponent } from './components/suspicious-records-comparison-page/suspicious-records-comparison-page.component';
import { SuspiciousRecordsSearchPageComponent } from './components/suspicious-records-search-page/suspicious-records-search-page.component';
import { ScrollableContainerComponent } from '@shared/components/scrollable-container/scrollable-container.component';

@NgModule({
  declarations: [
    SuspiciousRecordsSearchPageComponent,
    SuspiciousRecordsComparisonPageComponent,
    SuspiciousRecordComponent,
    SuspiciousRecordReasonDialogComponent
  ],
  imports: [
    CommonModule,
    FormModule,
    ReactiveFormsModule,
    MaterialModule,
    RecordDateFilterComponentModule,
    SearchModule,
    TranslateModule.forChild(),
    FlexLayoutModule,
    PermissionModule,
    RouterModule,
    ReadonlyCommonsModule,
    GeneralCommonsModule,
    FormsCommonsModule,
    TreatmentStatusIndicatorComponentModule,
    TreatmentDateComponentModule,
    ScrollableContainerComponent
  ]
})
export class SuspiciousRecordsReviewModule {}
