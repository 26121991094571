import { Patient } from '@/src/app/features/patient/models/patient.model';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { ReviewModel } from '@/src/app/features/surgical/models/review.model';
import { SurgicalIntervention } from '@/src/app/features/surgical/models/surgical-intervention.model';
import { StatusBarBaseComponent } from '@/src/app/shared/components/treatment/components/status-bar-base/status-bar-base.component';
import { FormStatus } from '@/src/app/shared/enums/form-status.enum';
import { formStatusMap } from '@/src/app/utils/form.utils';
import { PatientUtils } from '@/src/app/utils/patient.utils';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'stx-surgical-treatment-status-bar',
  templateUrl: './surgical-treatment-status-bar.component.html',
  styleUrls: ['./../treatment-status-bar/treatment-status-bar.component.scss']
})
export class SurgicalTreatmentStatusBarComponent extends StatusBarBaseComponent {
  _surgicalTreatment: SurgicalIntervention;

  get surgicalTreatment() {
    return this._surgicalTreatment;
  }
  @Input() set surgicalTreatment(value: SurgicalIntervention) {
    if (value) {
      this._surgicalTreatment = value;
    }
  }
  @Input() patient: Patient;
  @Input() suspiciousRecordReviewer: string;
  @Input() reviewModel: ReviewModel;
  constructor(
    dialog: MatDialog,
    translateService: TranslateService,
    cd: ChangeDetectorRef,

    patientService: PatientService
  ) {
    super(dialog, translateService, cd, patientService);
  }
  get addBorescopeAllowed(): boolean {
    // TODO#11043: this should be a flag set on BE
    return (
      (this._surgicalTreatment.status === formStatusMap.get(FormStatus.IN_PROGRESS) ||
        this._surgicalTreatment.status === formStatusMap.get(FormStatus.SUBMITTED) ||
        this._surgicalTreatment.status === formStatusMap.get(FormStatus.ACCEPTED)) &&
      this._surgicalTreatment.treatmentCenter.allowBorescope &&
      this._surgicalTreatment.primaryCleftPalate &&
      PatientUtils.isPatientValidForTreatmentAndInScope(this.patient.patientDetailsBlockPatient)
    );
  }
}
