import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AuthService } from 'src/app/features/authentication/services/auth.service';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { NavItem } from './model/nav-item.model';
import { navItems } from './nav.utils';
import { TranslateService } from '@ngx-translate/core';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { newPatientRoute, uploadOfflineRecordRoute } from '@utils/routing.utils';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { User } from '@shared/models/user/user.model';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

@Component({
  selector: 'stx-nav-base',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseNavComponent extends BaseComponent {
  userEmail: string;
  profileName: string;
  navItems: NavItem[];
  canChangeProfile: boolean;
  hasActiveProfile: boolean;

  readonly PermissionEnum = PermissionEnum;
  readonly newPatientRoute = newPatientRoute;
  readonly uploadOfflineRecordRoute = uploadOfflineRecordRoute;

  constructor(
    public authService: AuthService,
    public currentUserService: CurrentUserService,
    public router: Router,
    public cd: ChangeDetectorRef,
    public translateService: TranslateService,
    public wsHelper: WsHelperService
  ) {
    super();
  }

  getNavItems(): void {
    this.subSink.sink = this.currentUserService.getUserSubject().subscribe(user => {
      if (user) {
        this.userEmail = user.email;
        if (user.activeProfile) {
          this.setProfileName(user);
          this.hasActiveProfile = true;
        } else {
          this.hasActiveProfile = false;
        }
        if (user.permissions) {
          const userPermissions = user.permissions.map(x => x.name);
          this.navItems = navItems.filter(x => userPermissions.includes(x.permission) || !x.permission);
        }
        if (user.profiles) {
          this.canChangeProfile = user.profiles.length > 1;
        }
        this.cd.detectChanges();
      }
    });
  }

  setProfileName(user: User): void {
    if (user.activeProfile.name) {
      this.profileName = user.activeProfile.name;
    } else {
      this.profileName = user.activeProfile.role.name;
    }
  }

  logout(): void {
    this.wsHelper.call(this.authService.logout()).subscribe(() => {
      this.authService.cleanUpLogoutUser();
      this.router.navigateByUrl('/login');
    });
  }
}
