import { ChangeDetectorRef, Component, ElementRef, forwardRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'stx-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignaturePadComponent),
      multi: true
    }
  ]
})
export class SignaturePadComponent implements OnInit, ControlValueAccessor {
  private previousWindowWidth: number = -1;
  signaturePad: SignaturePad;
  hideClearIcon: boolean = true;
  @Input() disabled: boolean;
  @Input() label: string;
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  private onChangeFn = (imageUrl: string) => {};
  private onTouchedFn = () => {};

  constructor(private cd: ChangeDetectorRef) {}

  writeValue(imageUrl: string): void {
    this.onChangeFn(imageUrl);
  }

  registerOnChange(fn: (imageUrl: string) => {}): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.clearPad();
      this.signaturePad.off();
    } else {
      this.signaturePad.on();
    }
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.initSignaturePad();
  }

  private initSignaturePad() {
    const options = { throttle: 0, minDistance: 0, maxWidth: 1.5 };
    this.signaturePad = new SignaturePad(this.canvas.nativeElement, options);
    this.resizeCanvas(true);
    this.registerEventListeners();
  }

  private registerEventListeners() {
    this.signaturePad.addEventListener('endStroke', this.onStrokeEnd.bind(this));
  }

  private onStrokeEnd() {
    if (!this.disabled) {
      this.touch();
      this.writeValue(this.signaturePad.toDataURL());
    }
  }

  clearPad() {
    this.writeValue(null);
    this.signaturePad.clear();
    this.hideClearIcon = true;
    this.cd.detectChanges();
  }

  touch() {
    this.hideClearIcon = false;
    this.cd.detectChanges();
    this.onTouchedFn();
  }

  @HostListener('window:resize')
  resizeCanvas(force: boolean = false) {
    // Ignore resize events that are caused by scrolling in iOS Safari and are not triggered by a change in window width
    if (this.previousWindowWidth !== window.innerWidth || force) {
      // Workaround for changing canvas size: https://github.com/szimek/signature_pad/issues/476#issuecomment-612173534
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      this.canvas.nativeElement.width = this.canvas.nativeElement.offsetWidth * ratio;
      this.canvas.nativeElement.height = this.canvas.nativeElement.offsetHeight * ratio;
      this.canvas.nativeElement.getContext('2d').scale(ratio, ratio);

      this.previousWindowWidth = window.innerWidth;

      this.clearPad();
    }
  }
}
