import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { isPublication } from '@shared/components/table/models/table-structure.model';
import { isLink } from '@shared/modules/general-commons/components/data-table/data-table-generic-column-definition.component';
import { DataPage, DataPageWithNames } from '@shared/modules/general-commons/components/data-table/data-table.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export class FrontendTableSearch<SearchFiltersModel, DataRowModel> {
  private config: FrontendTableSearchConfig<SearchFiltersModel, DataRowModel>;
  private dataSourceSubject$: BehaviorSubject<DataPageWithNames<DataRowModel> | null>;
  private currentPageSubject$: BehaviorSubject<DataPageWithNames<DataRowModel> | null>;
  public currentPage$: Observable<DataPageWithNames<DataRowModel> | null>;

  constructor(config: FrontendTableSearchConfig<SearchFiltersModel, DataRowModel>) {
    this.dataSourceSubject$ = new BehaviorSubject(null);
    this.currentPageSubject$ = new BehaviorSubject(null);
    this.currentPage$ = this.currentPageSubject$.asObservable();
    this.config = config;
  }

  getFilteredTablePage(
    pageSort?: PaginationSortingModel,
    searchFilters?: SearchFiltersModel,
    forceUpdate?: boolean
  ): Observable<DataPageWithNames<DataRowModel>> {
    if (!this.dataSourceSubject$.value || forceUpdate) {
      return this.fetchAllRowsAndUpdateSubjects(searchFilters);
    } else {
      this.currentPageSubject$.next(this.getFilteredAndSortedPage(this.dataSourceSubject$.value, pageSort, searchFilters));
      return this.currentPageSubject$.asObservable();
    }
  }
  private fetchAllRowsAndUpdateSubjects(
    searchFilters: SearchFiltersModel,
    pageSort: PaginationSortingModel = { ...firstPagePageSort, pageSize: defaultPageSize }
  ): Observable<DataPageWithNames<DataRowModel>> {
    return this.config.fetchFunction().pipe(
      tap(singlePageData => {
        this.dataSourceSubject$.next(singlePageData);
      }),
      map(singlePageData => {
        const firstPage = this.getFilteredAndSortedPage(singlePageData, pageSort, searchFilters);
        this.currentPageSubject$.next(firstPage);
        return firstPage;
      })
    );
  }

  private getFilteredAndSortedPage(
    dataSource: DataPageWithNames<DataRowModel>,
    pageSort: PaginationSortingModel,
    searchFilters?: SearchFiltersModel
  ): DataPageWithNames<DataRowModel> {
    return this.buildSortedPageWithNames(this.config.applyFiltersFunction(dataSource.data.page.content, searchFilters), pageSort);
  }

  private sort(dataRows: DataRowModel[], pageSort: PaginationSortingModel): DataRowModel[] {
    if (!!pageSort.sortedBy && !!pageSort.sortDirection) {
      if (pageSort.sortDirection === 'desc') {
        return dataRows.sort((a, b) => tableCellComparator(b[pageSort.sortedBy], a[pageSort.sortedBy]));
      }
      if (pageSort.sortDirection === 'asc') {
        return dataRows.sort((a, b) => tableCellComparator(a[pageSort.sortedBy], b[pageSort.sortedBy]));
      }
    }
    return dataRows;
  }

  private buildSortedPageWithNames(rawData: DataRowModel[], pageSort: PaginationSortingModel): DataPageWithNames<DataRowModel> {
    if (pageSort.pageNumber * pageSort.pageSize > rawData.length) {
      pageSort = { ...pageSort, pageNumber: 0 };
    }

    const sortedData = this.sort(rawData, pageSort);
    const startIndex = pageSort.pageNumber * pageSort.pageSize;
    const endIndex = (pageSort.pageNumber + 1) * pageSort.pageSize;
    const totalPages = Math.floor(sortedData.length / pageSort.pageSize);
    return {
      data: {
        page: {
          content: [...sortedData].slice(startIndex, endIndex),
          first: pageSort.currentPageIndex === 0,
          last: pageSort.currentPageIndex === totalPages,
          number: pageSort.currentPageIndex,
          numberOfElements: Math.min(pageSort.pageSize, sortedData.length),
          size: pageSort.pageSize,
          totalElements: sortedData.length,
          totalPages: totalPages,
          pageable: {
            ...this.dataSourceSubject$.value.data.page.pageable,
            pageSize: pageSort.pageSize,
            pageNumber: pageSort.pageNumber
          },
          empty: sortedData.length > 0
        } as DataPage<DataRowModel>
      },
      labels: [...this.dataSourceSubject$.value.labels]
    } as DataPageWithNames<DataRowModel>;
  }
}

export const firstPagePageSort = {
  pageNumber: 0,
  pageSize: 10000,
  currentPageIndex: 0
};

export const defaultPageSize = 25;

export type FrontendTableSearchConfig<SearchFiltersType, DataRowModel> = {
  fetchFunction: () => Observable<DataPageWithNames<DataRowModel>>;
  applyFiltersFunction: (dataSource: DataRowModel[], searchFilters: SearchFiltersType) => DataRowModel[];
};

// predicates
export type SearchFilterPredicate = (obj: unknown) => boolean;
export const valueInArray =
  <T>(propName: string, arr: T[]) =>
  (obj: T) =>
    obj.hasOwnProperty(propName) && arr.some(el => el.toString() === obj[propName].toString());

export const isSubstringInObject = (substring: string) => (obj: unknown) => {
  return Object.values(obj).some(cell => {
    // TODO: #6742 apply solution for consistent collation
    if (typeof cell === 'string' || typeof cell === 'number') {
      return (
        cell
          .toString()
          .toLocaleLowerCase()
          .indexOf(substring ?? '') !== -1
      );
    } else {
      return isSubstringInObject(substring)(cell);
    }
  });
};

// filtering
export type FilterConfig = {
  predicate: SearchFilterPredicate;
  condition: () => boolean;
};

export function getFilterResultsIntersection<T>(dataSource: T[], filters: FilterConfig[]): T[] {
  let resultSetsForIntersection = getFilterResults(dataSource, filters);
  return dataSource.filter(element => resultSetsForIntersection.indexOf(element) !== -1);
}

export function getFilterResultsUnion<T>(dataSource: T[], filters: FilterConfig[]): T[] {
  let resultSetsForUnion = getFilterResults(dataSource, filters);
  return resultSetsForUnion.length > 0 ? resultSetsForUnion : dataSource;
}

export function getFilterResults<T>(dataSource: T[], filters: FilterConfig[]): T[] {
  let resultSets: T[] = [];
  (filters ?? []).forEach(filter => {
    if (filter.condition()) {
      const filteredElements = (dataSource ?? []).filter(element => filter.predicate(element));
      filteredElements.forEach(element => resultSets.push(element));
    }
  });

  return resultSets;
}

// sorting
export const tableCellComparator = (a: unknown, b: unknown) => {
  if (!a && !b) {
    return 0;
  }

  if (!a && b) {
    return -1;
  }

  if (!b && a) {
    return 1;
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return Math.sign(a - b);
  }

  if (typeof a === 'string' && typeof b === 'string') {
    // TODO: #6742 apply solution for consistent collation
    return a.localeCompare(b, ['en-US'], { sensitivity: 'base' });
  }

  if (isPublication(a) && isPublication(b)) {
    return tableCellComparator(a.link.text, b.link.text);
  }

  if (isLink(a) && isLink(b)) {
    return tableCellComparator(a.text, b.text);
  }

  return 0;
};
