<stx-partner-surveys *stxPermitted="partnerSurveysDisplayPermission"></stx-partner-surveys>
<stx-password-expiry></stx-password-expiry>
<stx-expandable-static-file [fileInfo]="expandableFilesRegistry.smile_train_publication_access"></stx-expandable-static-file>
<stx-expandable-static-file [fileInfo]="expandableFilesRegistry.automatic_rejection_of_palate_cases"></stx-expandable-static-file>
<stx-expandable-static-file [fileInfo]="expandableFilesRegistry.medical_advisory_on_outreach_surgeries"></stx-expandable-static-file>
<stx-expandable-static-file [fileInfo]="expandableFilesRegistry.anniversary"></stx-expandable-static-file>
<stx-expandable-static-file [fileInfo]="expandableFilesRegistry.speech_updates"></stx-expandable-static-file>
<stx-expandable-static-file [fileInfo]="expandableFilesRegistry.safety_and_quality_protocols_2023"></stx-expandable-static-file>
<stx-expandable-static-file [fileInfo]="expandableFilesRegistry.medical_forms"></stx-expandable-static-file>
<div class="m-b-15"></div>
