import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { DictionaryTranslation } from '../../patient/models/patient.model';

export const treatmentModelOptions = [
  {
    name: 'serviceIndiv',
    label: 'speech_treatment.service_indiv',
    value: false
  },
  {
    name: 'serviceGroup',
    label: 'speech_treatment.service_group',
    value: false
  },
  {
    name: 'serviceCamp',
    label: 'speech_treatment.service_camp',
    value: false
  }
];

export const treatmentFrequencyOptions = [
  {
    name: 'serviceDaily',
    label: 'speech_treatment.service_daily',
    value: false
  },
  {
    name: 'serviceWeekly',
    label: 'speech_treatment.service_weekly',
    value: false
  },
  {
    name: 'serviceMonthly',
    label: 'speech_treatment.service_monthly',
    value: false
  }
];

export const recommendationsOptions = [
  {
    name: 'recommendSpeechTherapy',
    label: 'speech_treatment.recommend_speech_therapy',
    value: false
  },
  {
    name: 'recommendHomeTherapy',
    label: 'speech_treatment.recommend_home_therapy',
    value: false
  },
  {
    name: 'recommendVpdAssessment',
    label: 'speech_treatment.recommend_further_assessment_for_vpd',
    value: false
  },
  {
    name: 'recommendRelease',
    label: 'speech_treatment.recommend_release',
    value: false
  }
];

export const speechTreatmentParentOrderNames = [
  ParentOrderName.SPEECH_TREATMENT_SAMPLE_SENTENCE_REPETITION,
  ParentOrderName.SPEECH_TREATMENT_SAMPLE_SYLLABLE_REPETITION
];

export const treatmentModelFields = ['serviceCamp', 'serviceIndiv', 'serviceGroup'];
export const frequencyFields = ['serviceDaily', 'serviceWeekly', 'serviceMonthly', 'serviceOther'];
export const recommendationFields = ['recommendSpeechTherapy', 'recommendHomeTherapy', 'recommendRelease', 'recommendVpdAssessment'];

export const goalCorrectAllTimes = 1;

export const defaultTelehealthCountValue = 0;

export const speechTreatmentGoals: DictionaryTranslation[] = [
  { name: 'placementErrorPb', label: 'speech_assessment.placement_error_pb', value: 'p/b' },
  { name: 'placementErrorTd', label: 'speech_assessment.placement_error_td', value: 't/d' },
  { name: 'placementErrorKg', label: 'speech_assessment.placement_error_kg', value: 'k/g' },
  { name: 'placementErrorFv', label: 'speech_assessment.placement_error_fv', value: 'f/v' },
  { name: 'placementErrorSz', label: 'speech_assessment.placement_error_sz', value: 's/z' },
  { name: 'placementErrorShzh', label: 'speech_assessment.placement_error_shzh', value: 'sh/zh' },
  { name: 'placementErrorChj', label: 'speech_assessment.placement_error_chj', value: 'ch' }
];
