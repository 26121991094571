import { Injectable } from '@angular/core';
import { BaseNutritionManagementService } from '@src/app/features/nutrition-management/services/base-nutrition-management.service';
import { NutritionAssessment } from '@src/app/features/nutrition-management/models/nutrition-management.model';
import { HttpClient } from '@angular/common/http';
import { nutritionAssessmentResourcesRoute } from '@utils/routing.utils';

@Injectable({
  providedIn: 'root'
})
export class NutritionAssessmentService extends BaseNutritionManagementService<NutritionAssessment> {
  constructor(httpClient: HttpClient) {
    super(httpClient, nutritionAssessmentResourcesRoute);
  }
}
