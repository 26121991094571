import { Injectable } from '@angular/core';
import { BaseHttpService } from '@shared/services/http/base-http.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SurgicalIntervention } from './models/surgical-intervention.model';
import { DictionaryTranslation } from '../patient/models/patient.model';
import { map, shareReplay } from 'rxjs/operators';
import { SurgeryFollowUp } from './models/surgery-follow-up.model';
import { Borescope } from './models/borescope.model';
import { shareReplayBufferSize } from '@utils/http.utils';
import { staticDictionariesUrl } from '@utils/http/http.const';
import { environment } from '@src/environments/environment';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Injectable({
  providedIn: 'root'
})
export class SurgicalService extends BaseHttpService {
  saQOverrideSubmitLocation = 'submit-with-override';
  followUpUrl = 'follow-ups';
  borescopesUrl = 'borescopes';
  private readonly apiUrl: string = environment.apiUrl;

  private interventionDictionary$: Observable<Map<string, DictionaryTranslation[]>>;
  private followUpDictionary$: Observable<Map<string, DictionaryTranslation[]>>;

  constructor(httpClient: HttpClient) {
    super(httpClient, { baseUrl: 'surgery' });
  }

  public getSurgeryStaticDictionaries(): Observable<Map<string, DictionaryTranslation[]>> {
    if (!this.interventionDictionary$) {
      this.interventionDictionary$ = this.httpClient
        .get<Map<string, DictionaryTranslation[]>>(`${this.baseUrl}/${staticDictionariesUrl}`)
        .pipe(shareReplay(shareReplayBufferSize))
        .pipe(map(res => new Map(Object.entries(res))));
    }
    return this.interventionDictionary$;
  }

  public saveSurgery(surgery: SurgicalIntervention): Observable<SurgicalIntervention> {
    return this.httpClient.post<SurgicalIntervention>(`${this.baseUrl}/save`, surgery);
  }

  public submitSurgery(surgery: SurgicalIntervention): Observable<SurgicalIntervention> {
    return this.httpClient.post<SurgicalIntervention>(`${this.baseUrl}/submit`, surgery);
  }

  public submitSurgeryWithSaQProtocolsOverride(surgery: SurgicalIntervention): Observable<SurgicalIntervention> {
    return this.httpClient.post<SurgicalIntervention>(`${this.baseUrl}/${this.saQOverrideSubmitLocation}`, surgery);
  }

  public getSurgicalIntervention(surgeryId: TreatmentId): Observable<SurgicalIntervention> {
    return this.httpClient.get<SurgicalIntervention>(`${this.baseUrl}/${surgeryId}`);
  }

  unlockSurgicalIntervention(surgeryId: TreatmentId): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}/unlock/${surgeryId}`, null);
  }

  deleteSurgicalIntervention(surgeryId: TreatmentId): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${surgeryId}`);
  }

  getFollowUpStaticDictionaries(): Observable<Map<string, DictionaryTranslation[]>> {
    if (!this.followUpDictionary$) {
      this.followUpDictionary$ = this.httpClient
        .get<Map<string, DictionaryTranslation[]>>(`api/${this.followUpUrl}/${staticDictionariesUrl}`)
        .pipe(shareReplay(shareReplayBufferSize))
        .pipe(map(res => new Map(Object.entries(res))));
    }
    return this.followUpDictionary$;
  }

  saveSurgeryFollowUp(surgeryFollowUp: SurgeryFollowUp): Observable<SurgeryFollowUp> {
    return this.httpClient.post<SurgeryFollowUp>(`${this.apiUrl}/${this.followUpUrl}/save`, surgeryFollowUp);
  }

  submitSurgeryFollowUp(surgeryFollowUp: SurgeryFollowUp): Observable<SurgeryFollowUp> {
    return this.httpClient.post<SurgeryFollowUp>(`${this.apiUrl}/${this.followUpUrl}/submit`, surgeryFollowUp);
  }

  getSurgeryFollowUp(followUpId: TreatmentId): Observable<SurgeryFollowUp> {
    return this.httpClient.get<SurgeryFollowUp>(`${this.apiUrl}/${this.followUpUrl}/${followUpId}`);
  }

  unlockSurgeryFollowUp(followUpId: TreatmentId): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/${this.followUpUrl}/unlock/${followUpId}`, null);
  }

  deleteSurgeryFollowUp(followUpId: TreatmentId): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${this.followUpUrl}/${followUpId}`);
  }

  saveBorescope(borescope: Borescope): Observable<Borescope> {
    return this.httpClient.post<Borescope>(`${this.apiUrl}/${this.borescopesUrl}/save`, borescope);
  }

  submitBorescope(borescope: Borescope): Observable<Borescope> {
    return this.httpClient.post<Borescope>(`${this.apiUrl}/${this.borescopesUrl}/submit`, borescope);
  }

  getBorescope(borescopeId: TreatmentId): Observable<Borescope> {
    return this.httpClient.get<Borescope>(`${this.apiUrl}/${this.borescopesUrl}/${borescopeId}`);
  }

  unlockBorescope(borescopeId: TreatmentId): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/${this.borescopesUrl}/unlock/${borescopeId}`, null);
  }

  deleteBorescope(borescopeId: TreatmentId): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${this.borescopesUrl}/${borescopeId}`);
  }
}
