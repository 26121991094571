import { Component, Input } from '@angular/core';
import { BaseReportWithPatientData } from '../../models/compact-report-with-patient-data.model';

@Component({
  selector: 'stx-compact-patient-data',
  templateUrl: './compact-patient-data.component.html'
})
export class CompactPatientDataComponent {
  @Input() recordData: BaseReportWithPatientData;
}
