import { OrthoStageValidationData } from '@/src/app/features/ortho/models/ortho-stage-validation-data.model';
import { BaseTreatment, TreatmentId } from '@/src/app/features/surgical/models/base-treatment.model';
import { BaseHttpService } from '@/src/app/shared/services/http/base-http.service';
import { TreatmentFormApi } from '@/src/app/shared/services/treatment-form-api';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PatientId } from '@src/app/features/patient/models/patient.model';

export abstract class OrthoFormApiBase<TreatmentModel extends BaseTreatment>
  extends BaseHttpService
  implements TreatmentFormApi<TreatmentModel>
{
  constructor(httpClient: HttpClient, apiRoute: string) {
    super(httpClient, { baseUrl: apiRoute });
  }
  get(id: TreatmentId): Observable<TreatmentModel> {
    return this.httpClient.get<TreatmentModel>(`${this.baseUrl}/${id}`);
  }
  save(assessment: TreatmentModel): Observable<TreatmentModel> {
    return this.httpClient.post<TreatmentModel>(`${this.baseUrl}/save`, assessment);
  }

  submit(assessment: TreatmentModel): Observable<TreatmentModel> {
    return this.httpClient.post<TreatmentModel>(`${this.baseUrl}/submit`, assessment);
  }
  delete(id: TreatmentId): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`);
  }
  unlock(id: TreatmentId): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}/unlock/${id}`, null);
  }

  abstract getValidationData(patientId: PatientId, recordId?: TreatmentId): Observable<OrthoStageValidationData>;
}
