<stx-notice *ngIf="existingOnlinePrf === null" noticeType="notice">
  <div class="normal fs-16 m-b-20 flex-row-container wrap align-left layout-align-center">
    <div class="align-left layout-align-center display-flex">
      <mat-icon class="sign-step-icon" svgIcon="tablet-sign"></mat-icon>
    </div>
    <div class="fs-14 m-l-10">{{ 'patient.release_form.electronic' | translate }}</div>
  </div>
  <div>
    <stx-document-language-select [formLanguages]="formLanguages" (languageSelected)="openDialog($event)"></stx-document-language-select>
  </div>
</stx-notice>
<stx-notice *ngIf="existingOnlinePrf !== null" [noticeType]="patientDataMatchesReleaseFormData ? 'success' : 'error'">
  <div class="normal fs-16 m-b-20 flex-row-container wrap align-left layout-align-center">
    <div class="align-left layout-align-center display-flex">
      <mat-icon class="sign-step-icon" svgIcon="tablet-sign"></mat-icon>
    </div>
    <div class="fs-14 m-l-10">{{ 'patient.release_form.electronic' | translate }}</div>
  </div>
  <p *ngIf="patientDataMatchesReleaseFormData === false">
    <em>{{ 'patient.online_release_form.warning' | translate }}</em>
  </p>
  <p *ngIf="existingPdf">
    <stx-file-icon></stx-file-icon>
    <a [href]="existingPdf.url" target="_blank">
      {{ existingPdf.createdAt | date : 'yyyy-MM-dd' }}
    </a>
    <stx-remove-icon (click)="remove()"></stx-remove-icon>
  </p>
  <p *ngIf="!existingPdf">
    <stx-file-icon></stx-file-icon>
    {{ now | date : 'yyyy-MM-dd' }} [100%]
    <stx-remove-icon (click)="remove()"></stx-remove-icon>
  </p>
  <div *ngIf="existingOnlinePrf?.base64EncodedSignatureImage">
    <stx-base64-img [base64]="existingOnlinePrf.base64EncodedSignatureImage"></stx-base64-img>
  </div>
</stx-notice>
