<stx-generic-page>
  <stx-password-expiry *ngIf="isViewDashboardForUser()"></stx-password-expiry>
  <div class="flex-column-container">
    <div class="data-section">
      <h3>{{ 'surgical_qa_dashboard.first_header' | translate }}</h3>
      <div class="first-paragraph-container">
        <p>{{ 'surgical_qa_dashboard.text_below_first_header' | translate }}</p>
        <p>{{ 'surgical_qa_dashboard.text_below_first_header.flag' | translate }}</p>
      </div>
      <h3>{{ 'surgical_qa_dashboard.second_header' | translate }}</h3>
      <div>
        <p>{{ 'surgical_qa_dashboard.text_below_second_header_1' | translate }}</p>
        <p>{{ 'surgical_qa_dashboard.text_below_second_header_2' | translate }}</p>
        <p>{{ 'surgical_qa_dashboard.text_below_second_header_3' | translate }}</p>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="criteriaContent"></ng-container>
  </div>
</stx-generic-page>

<ng-template #criteriaContent>
  <div class="flex-column-container">
    <div class="layout-columns-1-1-1">
      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'surgical_qa_dashboard.pre_operative_cleft_lip_grading_criteria' | translate }}</div>
              <div class="data-tile-content">
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade1'"
                  [descriptionParagraph]="'surgical_qa_dashboard.pre_operative_cleft_lip_grading_criteria_grade1_description'"
                  [tagName]="'surgical_qa_dashboard_mild'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade2'"
                  [descriptionParagraph]="'surgical_qa_dashboard.pre_operative_cleft_lip_grading_criteria_grade2_description'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade3'"
                  [descriptionParagraph]="'surgical_qa_dashboard.pre_operative_cleft_lip_grading_criteria_grade3_description'"
                  [tagName]="'surgical_qa_dashboard.average'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade4'"
                  [descriptionParagraph]="'surgical_qa_dashboard.pre_operative_cleft_lip_grading_criteria_grade4_description'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade5'"
                  [descriptionParagraph]="'surgical_qa_dashboard.pre_operative_cleft_lip_grading_criteria_grade5_description'"
                  [tagName]="'surgical_qa_dashboard.severe'"
                ></stx-dashboard-grade-item>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'surgical_qa_dashboard.post_operative_cleft_lip_grading_criteria' | translate }}</div>
              <div class="data-tile-content">
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade1'"
                  [descriptionParagraph]="'surgical_qa_dashboard.post_operative_cleft_lip_grading_criteria_grade1_description'"
                  [tagName]="'surgical_qa_dashboard.unacceptable'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade2'"
                  [descriptionParagraph]="'surgical_qa_dashboard.post_operative_cleft_lip_grading_criteria_grade2_description'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade3'"
                  [descriptionParagraph]="'surgical_qa_dashboard.post_operative_cleft_lip_grading_criteria_grade3_description'"
                  [tagName]="'surgical_qa_dashboard.acceptable'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade4'"
                  [descriptionParagraph]="'surgical_qa_dashboard.post_operative_cleft_lip_grading_criteria_grade4_description'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade5'"
                  [descriptionParagraph]="'surgical_qa_dashboard.post_operative_cleft_lip_grading_criteria_grade5_description'"
                  [tagName]="'surgical_qa_dashboard.excellent'"
                ></stx-dashboard-grade-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'surgical_qa_dashboard.post_operative_cleft_palate_grading_criteria' | translate }}</div>
              <div class="data-tile-content">
                <p>
                  {{ 'surgical_qa_dashboard.fail' | translate }} —
                  {{ 'surgical_qa_dashboard.fistula_present' | translate }}
                </p>
                <p>
                  {{ 'surgical_qa_dashboard.pass' | translate }} —
                  {{ 'surgical_qa_dashboard.no_fistula' | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
