import { BaseReportTableComponent } from '@/src/app/features/reports/components/base-report-table/base-report-table.component';
import { CompactPatientDataModule } from '@/src/app/features/reports/components/compact-patient-data/compact-patient-data.module';
import { CompactPatientHeaderModule } from '@/src/app/features/reports/components/compact-patient-header/compact-patient-header.module';
import { PatientLinkComponentModule } from '@/src/app/features/reports/components/patient-link/patient-link.component';
import { ReportService } from '@/src/app/features/reports/components/report-filters/services/report.service';
import { TreatmentDateComponentModule } from '@/src/app/features/reports/components/treatment-date/treatment-date.component';
import { SurgicalTreatmentCompactReportRecord } from '@/src/app/features/reports/features/surgical-treatments-compact-report/surgical-treatments-compact-report.model';
import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { ReadonlyCommonsModule } from '@/src/app/shared/modules/readonly-commons/readonly-commons.module';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { FormModule } from '@shared/components/form/form.module';
import { ImageThumbnailComponentModule } from '@shared/components/image-thumbnail/image-thumbnail.component';
import { TreatmentStatusIndicatorComponentModule } from '@shared/components/treatment-status-indicator/treatment-status-indicator.component';
import { AgeYearMonthPipeModule } from '@shared/modules/readonly-commons/pipes/age-year-month/age-year-month.pipe';
import { ReportTableDataService } from 'src/app/features/reports/components/report-filters/services/report-table-data.service';
import { LocalNamePipeModule } from '@shared/pipes/local-name.pipe';

@Component({
  selector: 'stx-surgical-treatment-compact-report-table',
  templateUrl: './surgical-treatment-compact-report-table.component.html'
})
export class SurgicalTreatmentCompactReportTableComponent
  extends BaseReportTableComponent<SurgicalTreatmentCompactReportRecord>
  implements OnInit, OnDestroy
{
  @Input() deIdentified = false;
  @Input() showPagination: boolean;
  displayedColumns: string[] = ['patient'];

  constructor(
    public changeDetector: ChangeDetectorRef,
    public reportTableDataService: ReportTableDataService,
    public reportService: ReportService,
    public route: ActivatedRoute
  ) {
    super('SURGICAL_TREATMENTS_COMPACT', changeDetector, reportTableDataService, reportService, route);
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }
}

@NgModule({
  declarations: [SurgicalTreatmentCompactReportTableComponent],
  imports: [
    MatTableModule,
    MatSortModule,
    TranslateModule,
    CommonModule,
    MatIconModule,
    RouterModule,
    MatPaginatorModule,
    PatientLinkComponentModule,
    TreatmentStatusIndicatorComponentModule,
    FlexModule,
    MatDividerModule,
    TreatmentDateComponentModule,
    FormModule,
    ImageThumbnailComponentModule,
    EmptyTableStateComponentModule,
    AgeYearMonthPipeModule,
    CompactPatientDataModule,
    ReadonlyCommonsModule,
    GeneralCommonsModule,
    CompactPatientHeaderModule,
    LocalNamePipeModule
  ],
  exports: [SurgicalTreatmentCompactReportTableComponent]
})
export class SurgicalTreatmentCompactReportTableComponentModule {}
