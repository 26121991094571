import { orthoStaticDictionariesProvider } from '@/src/app/features/ortho/components/ortho-readonly-assessment/ortho-readonly-assessment.component';
import { DentitionTreatment } from '@/src/app/features/ortho/models/dentition-treatment.model';
import { PsioTreatment } from '@/src/app/features/ortho/models/psio-treatment.model';
import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { MediaSet } from '@/src/app/shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';
import { BaseReadonlyViewComponent } from '@/src/app/shared/modules/readonly-commons/utils/base-readonly-view.component';
import { dictionaryFromMapProvider } from '@/src/app/shared/services/dictionary-provider';
import { Component } from '@angular/core';
import { OrthoService } from '@src/app/features/ortho/services/ortho.service';

const facialPhotoLabelsInOrder = [
  { labels: ['ortho.photo.facial', 'order_name.frontal'], parentOrderName: ParentOrderName.ORTHO_FRONTAL_POST },
  { labels: ['ortho.photo.facial', 'order_name.left'], parentOrderName: ParentOrderName.ORTHO_LEFT_POST },
  { labels: ['ortho.photo.facial', 'order_name.right'], parentOrderName: ParentOrderName.ORTHO_RIGHT_POST },
  { labels: ['ortho.photo.facial', 'order_name.basal'], parentOrderName: ParentOrderName.ORTHO_BASAL_POST },
  { labels: ['order_name.frontal_smile'], parentOrderName: ParentOrderName.ORTHO_FRONTAL_SMILE_POST },
  { labels: ['ortho.photo.facial', 'order_name.maxillary'], parentOrderName: ParentOrderName.ORTHO_MAXILLARY_POST }
];

const intraoralPhotoLabelsInOrder = [
  {
    labels: ['ortho.photo.intraoral', 'order_name.intra_oral_frontal'],
    parentOrderName: ParentOrderName.ORTHO_INTRA_ORAL_FRONTAL_POST
  },
  {
    labels: ['ortho.photo.intraoral', 'ortho.photo.left_occlusion'],
    parentOrderName: ParentOrderName.ORTHO_LEFT_OCCLUSION_POST
  },
  {
    labels: ['ortho.photo.intraoral', 'ortho.photo.right_occlusion'],
    parentOrderName: ParentOrderName.ORTHO_RIGHT_OCCLUSION_POST
  },
  {
    labels: ['ortho.photo.intraoral', 'order_name.maxillary'],
    parentOrderName: ParentOrderName.ORTHO_MAXILLARY_ARCH_POST
  },
  {
    labels: ['ortho.photo.intraoral', 'ortho.photo.mandibular_arch'],
    parentOrderName: ParentOrderName.ORTHO_MANDIBULAR_ARCH_POST
  }
];

const xrayPhotoLabelsInOrder = [
  {
    labels: ['order_name.cephalometric_radiograph'],
    parentOrderName: ParentOrderName.ORTHO_CEPHALOMETRIC_RADIOGRAPH_POST
  },
  { labels: ['order_name.panoramic_radiograph'], parentOrderName: ParentOrderName.ORTHO_PANORAMIC_RADIOGRAPH_POST },
  {
    labels: ['order_name.cleft_site_occlusal_or_periapical_radiograph'],
    parentOrderName: ParentOrderName.ORTHO_CLEFT_SITE_OCCLUSAL_OR_PERIAPICAL_RADIOGRAPH_POST
  }
];

export type OrthoTreatment = PsioTreatment & DentitionTreatment;

@Component({
  selector: 'stx-ortho-readonly-treatment',
  templateUrl: './ortho-readonly-treatment.component.html',
  providers: [dictionaryFromMapProvider(OrthoService, orthoStaticDictionariesProvider)]
})
export class OrthoReadonlyTreatmentComponent extends BaseReadonlyViewComponent<OrthoTreatment> {
  photoSets: MediaSet[] = [];

  protected afterTreatmentSet(): void {
    this.photoSets = [
      {
        sectionLabelKey: 'ortho.facial_photos',
        mediaWithLabels: this.getPhotosWithLabels(facialPhotoLabelsInOrder)
      },
      {
        sectionLabelKey: 'ortho.intraoral_photos',
        mediaWithLabels: this.getPhotosWithLabels(intraoralPhotoLabelsInOrder)
      },
      { sectionLabelKey: 'ortho.xrays_photos', mediaWithLabels: this.getPhotosWithLabels(xrayPhotoLabelsInOrder) }
    ];
  }
}
