export enum ProblemType {
  DUPLICATE_RECORD = 'DUPLICATE_RECORD',
  OTHER = 'OTHER',
  MISSING_PHOTOS = 'MISSING_PHOTOS',
  PHOTOS_DONT_MEET_STANDARDS = 'PHOTOS_DONT_MEET_STANDARDS',
  UNACCEPTABLE_AGE = 'UNACCEPTABLE_AGE',
  SURGERY_TYPE_MISLABELED = 'SURGERY_TYPE_MISLABELED',
  SURGERY_NOT_FUNDED_BY_ST = 'SURGERY_NOT_FUNDED_BY_ST',
  SURGERY_RESULTS_NOT_EVIDENT = 'SURGERY_RESULTS_NOT_EVIDENT',
  EXCEEDS_QUOTA = 'EXCEEDS_QUOTA',
  DUPLICATE_PRIMARY_OPERATION = 'DUPLICATE_PRIMARY_OPERATION',
  INACTIVITY = 'INACTIVITY',
  SAQ_PROTOCOLS_VIOLATED = 'SAQ_PROTOCOLS_VIOLATED'
}

export enum SuspiciousRecordProblemType {
  UNREVIEWED = 'UNREVIEWED',
  NOT_PROBLEMATIC = 'NOT_PROBLEMATIC',
  PROBLEMATIC_SEVERE = 'PROBLEMATIC_SEVERE',
  PROBLEMATIC_MODERATE = 'PROBLEMATIC_MODERATE',
  PROBLEMATIC_MILD = 'PROBLEMATIC_MILD',
  AUTOMATICALLY_REJECTED = 'AUTOMATICALLY_REJECTED',
  SKIPPED = 'SKIPPED'
}
