import { MediaType } from '@/src/app/shared/enums/media.enum';
import { MediaResource } from '@/src/app/shared/models/media-resource.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-readonly-view-audio',
  templateUrl: './readonly-view-audio.component.html'
})
export class ReadonlyViewAudioComponent {
  @Input() audio: MediaResource;

  private getUrl(mediaType: MediaType) {
    return (this.audio?.urlList || []).find(url => url.urlType === mediaType && url.mediaFileAvailable)?.url;
  }

  get rawUrl(): string {
    return this.getUrl(MediaType.RAW);
  }

  get convertedUrl(): string {
    return this.getUrl(MediaType.CONVERTED);
  }
}
