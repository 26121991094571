<div [class.text-wrap]="isTextWrapped" [class.data-table-inactive]="inactive" class="flex-column-container">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'text'">
      {{ text }}
    </ng-container>
    <ng-container *ngSwitchCase="'link'">
      <a [routerLink]="link"> {{ text }} </a>
    </ng-container>
    <ng-container *ngSwitchCase="'email'">
      <a [href]="link"> {{ text }} </a>
    </ng-container>
    <ng-container *ngSwitchCase="'textWithOnHoverMessage'">
      <div *ngIf="!!textWithOnHoverMessage.onHoverMessage; else withoutMessage" [matTooltip]="textWithOnHoverMessage.onHoverMessage">
        {{ textWithOnHoverMessage.text }}
      </div>
      <ng-template #withoutMessage>
        {{ textWithOnHoverMessage.text }}
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'publication'">
      <a [href]="publication.link.link" target="_blank"> {{ publication.link.text }} </a>
      <div class="authors">{{ publication.authors }}</div>
      <div class="publisher-info">{{ publication.publisherInfo }}</div>
    </ng-container>
  </ng-container>
</div>
