<div>
  <div class="form-row" [ngClass]="{ 'flex-row-important': print }">
    <stx-date
      [label]="'nutrition_management.assessment_date'"
      [controlName]="'evaluationDate'"
      [formGroup]="nutritionInfo"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [maxDateToday]="!maxDate"
      [print]="print"
      [formMode]="formMode"
    ></stx-date>
    <stx-select
      [label]="'treatment_center.treatment_center'"
      [controlName]="'treatmentCenterId'"
      [dictionaryList]="tcList"
      [formGroup]="nutritionManagementFormGroup"
      [print]="print"
      [formMode]="formMode"
    ></stx-select>
    <stx-select
      [label]="'practitioner.nutritionist'"
      [controlName]="'practitionerId'"
      [dictionaryList]="practitionerList"
      [formGroup]="nutritionManagementFormGroup"
      [print]="print"
      [formMode]="formMode"
    ></stx-select>
  </div>
  <div class="form-row">
    <div class="form-columns-1-1-1" [ngClass]="{ 'flex-row-important': print }">
      <div>
        <div class="form-row form-row-valign-bottom" [ngClass]="{ 'flex-row-important': print }">
          <stx-nutrition-decimal-numbers-input
            [formMode]="formMode"
            [formGroup]="nutritionInfo"
            [controlName]="'weight'"
            [label]="'patient.weight'"
            [subLabel]="('unit.kg' | translate) + ' (0.01 — 200.00)'"
            [print]="print"
          ></stx-nutrition-decimal-numbers-input>
          <stx-nutrition-decimal-numbers-input
            [formMode]="formMode"
            [formGroup]="nutritionInfo"
            [controlName]="'height'"
            [label]="'nutrition_management.length_height'"
            [subLabel]="('unit.cm' | translate) + ' (0.1 — 200.0)'"
            [print]="print"
          ></stx-nutrition-decimal-numbers-input>
          <stx-nutrition-decimal-numbers-input
            [formMode]="formMode"
            [formGroup]="nutritionInfo"
            [controlName]="'hemoglobin'"
            [label]="'nutrition_management.hemoglobin'"
            [subLabel]="('unit.hemoglobin' | translate) + ' (0.1 — 100.0)'"
            [print]="print"
          ></stx-nutrition-decimal-numbers-input>
          <stx-nutrition-decimal-numbers-input
            [formMode]="formMode"
            [formGroup]="nutritionInfo"
            [controlName]="'muac'"
            [label]="'nutrition_management.muac_short'"
            [subLabel]="('unit.cm' | translate) + ' (0.1 — 100.0)'"
            [print]="print"
          ></stx-nutrition-decimal-numbers-input>
        </div>
        <div class="form-row" [ngClass]="{ 'flex-row-important': print }">
          <stx-nutrition-decimal-numbers-input
            [formMode]="formMode"
            [formGroup]="nutritionInfo"
            [controlName]="'headCircumference'"
            [label]="'nutrition_management.head_circumference'"
            [subLabel]="('unit.cm' | translate) + ' (0.1 — 100.0)'"
            [print]="print"
          ></stx-nutrition-decimal-numbers-input>
          <stx-nutrition-decimal-numbers-input
            [formMode]="formMode"
            [formGroup]="nutritionInfo"
            [controlName]="'tricepsSkinfold'"
            [label]="'nutrition_management.triceps_skinhold'"
            [subLabel]="('unit.mm' | translate) + ' (0.1 — 100.0)'"
            [print]="print"
          ></stx-nutrition-decimal-numbers-input>
          <stx-nutrition-decimal-numbers-input
            [formMode]="formMode"
            [formGroup]="nutritionInfo"
            [controlName]="'subscapularSkinfold'"
            [label]="'nutrition_management.subscapular_skinfold'"
            [subLabel]="('unit.mm' | translate) + ' (0.1 — 100.0)'"
            [print]="print"
          ></stx-nutrition-decimal-numbers-input>
        </div>
        <div class="form-row" *ngIf="weightMeasureInfoLabel">
          <span class="form-element-secondary-label fs-14 p-t-15"> {{ weightMeasureInfoLabel | translate }}</span>
        </div>
      </div>
      <div class="photo-container">
        <stx-image
          label="nutrition_management.photo"
          [formMode]="formMode"
          [parentOrderName]="ParentOrderName.NUTRITION_MANAGEMENT"
          [controlName]="ParentOrderName.NUTRITION_MANAGEMENT.toString()"
          [formGroup]="nutritionInfo"
          [print]="print"
        ></stx-image>
      </div>
    </div>
  </div>
</div>
