import { Component } from '@angular/core';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { dictionaryFromMapProvider } from '@shared/services/dictionary-provider';
import { Borescope } from '@src/app/features/surgical/models/borescope.model';
import { MediaSet } from '@shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';
import { BaseReadonlyViewComponent } from '@shared/modules/readonly-commons/utils/base-readonly-view.component';

@Component({
  selector: 'stx-borescope-readonly',
  templateUrl: './borescope-readonly.component.html',
  providers: [dictionaryFromMapProvider(undefined, s => null)]
})
export class BorescopeReadonlyComponent extends BaseReadonlyViewComponent<Borescope> {
  media: MediaSet[] = [];

  protected afterTreatmentSet(): void {
    this.media = [
      {
        sectionLabelKey: 'borescope.photos',
        mediaWithLabels: [
          this.findMediaWithLabels(ParentOrderName.BORESCOPE_IDENTIFICATION, ['borescope.photo.identification']),
          this.findMediaWithLabels(ParentOrderName.BORESCOPE_UNREPAIRED, ['borescope.photo.unrepaired']),
          this.findMediaWithLabels(ParentOrderName.BORESCOPE_REPAIRED, ['borescope.photo.repaired'])
        ]
      },
      {
        sectionLabelKey: 'borescope.videos',
        mediaWithLabels: [
          this.findMediaWithLabels(ParentOrderName.BORESCOPE_NASAL_LINING, ['borescope.video.nasal_lining']),
          this.findMediaWithLabels(ParentOrderName.BORESCOPE_VIDEOS, ['borescope.video.transpos']),
          this.findMediaWithLabels(ParentOrderName.BORESCOPE_ORAL_LINING, ['borescope.video.oral_lining'])
        ]
      }
    ];
  }
}
