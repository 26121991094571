import { Injectable } from '@angular/core';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { Patient } from '@src/app/features/patient/models/patient.model';
import { PatientService } from '@src/app/features/patient/patient.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PatientManagementService {
  constructor(private readonly wsHelper: WsHelperService, private readonly patientService: PatientService) {}

  loadPatientForClone(patientIdToClone: number): Observable<Patient> {
    return this.wsHelper.callWithSpinner(this.patientService.getPatientById(patientIdToClone)).pipe(
      map(patientToClone => {
        const { id, ...patientData } = patientToClone;
        return { id: undefined, patientToCloneId: id, ...patientData };
      })
    );
  }
}
