import { EducationalResourcesService } from '@/src/app/features/educational-resources/educational-resources.service';
import { CleftPublication } from '@/src/app/features/educational-resources/models/cleft-publication.model';
import { CleftPublicationsSearchModel } from '@/src/app/features/educational-resources/models/cleft-publications-search.model';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import {
  DataTableDataProvider,
  GenericDataTableDataProvider
} from '@/src/app/shared/modules/general-commons/components/data-table/data-table-data-provider';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { expandAnimationMetadata } from '@/src/app/utils/animation.utils';
import { FormMode } from '@/src/app/utils/form.utils';
import {
  defaultPageSize,
  firstPagePageSort,
  FrontendTableSearch,
  getFilterResultsIntersection,
  getFilterResultsUnion,
  isSubstringInObject,
  valueInArray
} from '@/src/app/utils/frontend-table-search-helper';
import { scrollToTop } from '@/src/app/utils/scroll.utils';
import { trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NavigationSubTabEnum } from '@shared/components/navigation/model/nav-item-enum.enum';
import { PermissionEnum } from 'src/app/core/permissions/permission.enum';
import { PaginationSortingModel } from 'src/app/shared/components/table/models/pagination-sorting.model';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  templateUrl: './cleft-publications-page.component.html',
  animations: [trigger('searchFormTrigger', expandAnimationMetadata)]
})
export class CleftPublicationsPageComponent extends BaseComponent implements OnInit {
  readonly NavigationSubTabEnum = NavigationSubTabEnum;
  readonly PermissionEnum = PermissionEnum;
  readonly FormMode = FormMode;
  private tableSearch: FrontendTableSearch<CleftPublicationsSearchModel, CleftPublication>;
  isExpanded = true;
  publicationDataProvider: DataTableDataProvider<CleftPublication>;
  publicationYears: number[];
  publicationSearchForm: UntypedFormGroup;
  searchFilters: CleftPublicationsSearchModel;
  paginationSortingModel: PaginationSortingModel;

  get colWidthConfig() {
    return this.publicationDataProvider.data$.subscribe(data => {
      if (data) {
        if (data.columnLabels.length === 4) {
          return [10, 20, 10, 60];
        }
        if (data.columnLabels.length === 3) {
          return [20, 10, 60];
        }
      }
    });
  }
  constructor(
    private resourcesService: EducationalResourcesService,
    private formBuilder: UntypedFormBuilder,
    private wsHelper: WsHelperService,
    private globalErrorHandlerService: GlobalErrorHandlerService
  ) {
    super();

    this.tableSearch = new FrontendTableSearch({
      fetchFunction: () => this.resourcesService.fetchAllCleftPublications(),
      applyFiltersFunction: (dataRows: CleftPublication[], searchFilters: CleftPublicationsSearchModel) => {
        if (searchFilters) {
          let filterResults = getFilterResultsUnion(dataRows, [
            {
              condition: () => searchFilters.years?.length > 0,
              predicate: valueInArray('yearPublished', [...searchFilters.years])
            }
          ]);

          const stringSearchTerm: string = searchFilters.searchTerm?.toString().toLocaleLowerCase().trim();
          filterResults = getFilterResultsIntersection(filterResults, [
            {
              condition: () => true,
              predicate: isSubstringInObject(stringSearchTerm)
            }
          ]);

          return filterResults;
        }
        return [...dataRows];
      }
    });

    this.publicationDataProvider = new GenericDataTableDataProvider(
      {
        loadFunction: pageSort => this.tableSearch.getFilteredTablePage(pageSort, this.searchFilters)
      },
      this.wsHelper,
      this.globalErrorHandlerService
    );

    this.paginationSortingModel = { ...firstPagePageSort, pageSize: defaultPageSize };
  }

  ngOnInit() {
    this.initializeSearchForm();
    this.getYearFilters();
    this.publicationDataProvider.reload();
  }
  search(): void {
    const selectedYears = this.publicationSearchForm.value.years
      .map((v, i) => (v ? this.publicationYears[i] : null))
      .filter(v => v !== null);
    this.searchFilters = { ...this.publicationSearchForm.value, years: selectedYears };
    this.publicationDataProvider.reload(this.paginationSortingModel);
  }

  resetFilters() {
    this.searchFilters = { years: [], searchTerm: '' };
    this.publicationSearchForm.reset();
  }

  newPublicationAdded() {
    this.resetFilters();
    this.subSink.sink = this.wsHelper
      .callWithSpinner(this.tableSearch.getFilteredTablePage(this.paginationSortingModel, this.searchFilters, true))
      .subscribe(() => {
        this.getYearFilters();
        this.publicationDataProvider.reload(this.paginationSortingModel);
        scrollToTop();
      });
  }

  updatePaginationModel(pageEvent: PageEvent) {
    this.paginationSortingModel = {
      pageNumber: pageEvent.pageIndex,
      pageSize: pageEvent.pageSize,
      currentPageIndex: pageEvent.pageIndex
    };
  }

  private getYearFilters(): void {
    this.wsHelper.callWithSpinner(this.resourcesService.getYearFilters()).subscribe(filters => {
      this.publicationYears = filters.years;
      this.addCheckboxes();
    });
  }

  private initializeSearchForm(): void {
    this.publicationSearchForm = this.formBuilder.group({
      years: new UntypedFormArray([]),
      searchTerm: []
    });
  }

  private addCheckboxes() {
    const checkboxFormArray = this.publicationSearchForm.controls.years as UntypedFormArray;
    checkboxFormArray.clear();
    this.publicationYears.forEach(() => {
      checkboxFormArray.push(new UntypedFormControl(false));
    });
  }
}
