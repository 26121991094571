import { STX_MOMENT_DATE_FORMATS } from '@/src/app/utils/date.utils';
import { _isNumberValue } from '@angular/cdk/coercion';
import { HttpParams } from '@angular/common/http';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import * as moment from 'moment';

export const shareReplayBufferSize = 1;

export const httpStatusPreconditionFailed = 428;
export const httpStatusResourceNotFound = 404;
export const httpStatusMethodNotAllowed = 405;
export const httpStatusNotAuthorized = 401;
export const httpStatusBadRequest = 400;
export const httpStatusConflict = 409;

export class HttpUtils {
  static pageSortToParams(pageSort?: PaginationSortingModel): HttpParams {
    let params: HttpParams = new HttpParams();

    if (pageSort) {
      params = params.set('size', pageSort.pageSize.toString()).set('page', pageSort.pageNumber.toString());

      if (pageSort.sortedBy || pageSort.sortDirection) {
        params = params.set('sort', `${pageSort.sortedBy},${pageSort.sortDirection}`);
      }
    }

    return params;
  }

  static convertToSearchParams(searchModel?: any, pageSort?: PaginationSortingModel): HttpParams {
    let httpParams = HttpUtils.pageSortToParams(pageSort);
    if (searchModel) {
      for (const key in searchModel) {
        if (key && searchModel.hasOwnProperty(key) && searchModel[key]) {
          if (searchModel[key].length > 0 || searchModel[key] === true || _isNumberValue(searchModel[key])) {
            httpParams = httpParams.set(key, searchModel[key]);
          } else if (searchModel[key] instanceof Date || moment.isMoment(searchModel[key])) {
            httpParams = httpParams.set(key, moment(searchModel[key]).format(STX_MOMENT_DATE_FORMATS.display.dateInput));
          }
        }
      }
    }
    return httpParams;
  }

  static mergeParams(paramsList: HttpParams[]): HttpParams {
    let mergedParams = new HttpParams();

    paramsList.forEach(params => {
      params.keys().forEach(paramName => {
        mergedParams = mergedParams.set(paramName, params.get(paramName));
      });
    });

    return mergedParams;
  }
}

export enum HttpCallStatus {
  IN_PROGRESS,
  COMPLETED,
  ERROR
}
