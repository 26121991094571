import { Component, Input, NgModule } from '@angular/core';
import { ReportService } from '@src/app/features/reports/components/report-filters/services/report.service';
import { SectionedCommonReportDictionaryItem } from '@src/app/features/reports/components/report-filters/models/report-filters.model';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormModule } from '@shared/components/form/form.module';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxGroupFilterComponentModule } from '@src/app/features/reports/components/report-filters/components/checkbox-group-filter/checkbox-group-filter.component';

@Component({
  selector: 'stx-sectioned-checkbox-group-filter',
  templateUrl: './sectioned-checkbox-group-filter.component.html'
})
export class SectionedCheckboxGroupFilterComponent<SECTION> {
  @Input() enumValToTranslationLabel: Map<SECTION, string>;
  @Input() enumToDicMapping: SectionedCommonReportDictionaryItem;
  @Input() formGroupReferenceName: string;
  @Input() filterName: string;

  constructor(public readonly reportService: ReportService) {}
}

@NgModule({
  imports: [CommonModule, FlexLayoutModule, FormModule, TranslateModule, CheckboxGroupFilterComponentModule],
  declarations: [SectionedCheckboxGroupFilterComponent],
  exports: [SectionedCheckboxGroupFilterComponent]
})
export class SectionedCheckboxGroupFilterModule {}
