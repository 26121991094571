export interface TableStructure<T = any> {
  labels: TableColumn[];
  data: TableData<T>;
}

export interface TableColumn {
  name: string;
  text: string;
}

export type TableColumns = TableColumn[];

export interface TableDataCell {
  name?: string;
  link?: string;
  text?: string;
  inactive?: boolean;
}

export interface PublicationTableDataCell {
  link: TableDataCell;
  authors: string;
  publisherInfo: string;
}

export interface TableDataCellWithOnHoverMessage {
  text: string;
  onHoverMessage?: string;
}

export function isDataWithOnHoverMessage(obj: unknown): obj is TableDataCellWithOnHoverMessage {
  return ['text', 'onHoverMessage'].every(prop => obj.hasOwnProperty(prop));
}

export function isPublication(obj: unknown): obj is PublicationTableDataCell {
  return ['link', 'authors', 'publisherInfo'].every(prop => obj.hasOwnProperty(prop));
}

export interface TableData<T = TableRow> {
  page: TablePage<T>;
  sortProperty: string;
  sortDirection: string;
  pageNumber: number;
  pageSize: number;
}

export interface TablePage<T> {
  content: Array<T>;
  pageable: string;
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  sort: TableSort;
  number: number;
  numberOfElements: number;
  size: number;
  empty: boolean;
}

export interface TableRow<T = TableDataCell | PublicationTableDataCell | string> {
  [key: string]: T;
}

export interface TableSort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface SimpleTable {
  name: string;
  firstColumn: string;
}
