import { Injectable } from '@angular/core';
import { PatientActionType } from '@shared/components/patient-details-actions/patient-action-type.enum';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { Patient } from '@src/app/features/patient/models/patient.model';
import { PatientService } from '@src/app/features/patient/patient.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PatientDataService {
  readonly patient$ = new BehaviorSubject<Patient | null>(null);

  constructor(private readonly wsHelper: WsHelperService, private readonly patientService: PatientService) {}

  load(patientId: number | null) {
    if (patientId !== null) {
      this.wsHelper.pipeToSubject(this.patientService.getPatientById(patientId), this.patient$, { redirectOn404StatusCode: true });
    } else {
      this.patient$.next(null);
    }
  }

  refreshLoadedPatient(): void {
    const id = this.patient$.value?.id;
    if (!!id) {
      this.wsHelper.pipeToSubject(this.patientService.getPatientById(id), this.patient$);
      this.patientService.emitUpdatePatients(PatientActionType.FETCH);
    }
  }
}
