import { Injectable } from '@angular/core';
import { dateTimeFormat } from '@utils/date.utils';
import { DatePipe } from '@angular/common';
import { DataPageWithNames } from '@shared/modules/general-commons/components/data-table/data-table.model';

@Injectable({
  providedIn: 'root'
})
export class DataTableService {
  private readonly datePipe = new DatePipe('en-US');

  get userDataTableTransformation() {
    return (dataWithLabels: DataPageWithNames<any>) => {
      const page = dataWithLabels.data.page;

      const pageFormatted = {
        ...page,
        content: page.content.map(row => ({
          ...row,
          lastLogin: this.datePipe.transform(row.lastLogin, dateTimeFormat)
        }))
      };

      return {
        ...dataWithLabels,
        data: { page: pageFormatted }
      };
    };
  }
}
