import {
  isDataWithOnHoverMessage,
  isPublication,
  PublicationTableDataCell,
  TableDataCellWithOnHoverMessage
} from '@/src/app/shared/components/table/models/table-structure.model';
import { Component, Input } from '@angular/core';

type Link = {
  text: string;
  link: string;
};

export function isLink(obj: unknown): obj is Link {
  return ['text', 'link'].every(prop => obj.hasOwnProperty(prop));
}

@Component({
  selector: 'stx-data-table-generic-column-definition',
  templateUrl: './data-table-generic-column-definition.component.html',
  styleUrls: ['./data-table-generic-column-definition.component.scss']
})
export class DataTableGenericColumnDefinitionComponent {
  link: string | null;
  text: string | null;
  publication: PublicationTableDataCell | null;
  textWithOnHoverMessage: TableDataCellWithOnHoverMessage | null;
  type: 'link' | 'text' | 'publication' | 'email' | 'textWithOnHoverMessage' = 'text';
  @Input() inactive: boolean;
  @Input() isTextWrapped = false;
  @Input() set data(data: string | Link | number | PublicationTableDataCell | TableDataCellWithOnHoverMessage) {
    this.link = null;
    this.text = null;
    this.publication = null;
    if (!data) {
      return;
    }
    if (typeof data === 'string' || typeof data === 'number') {
      this.text = data.toString();
      this.type = 'text';
      return;
    }
    if (isPublication(data)) {
      this.publication = data;
      this.type = 'publication';
      return;
    }

    if (isLink(data)) {
      this.text = data.text;
      this.link = data.link;
      if (data.link?.includes('mailto:')) {
        this.type = 'email';
        return;
      } else {
        this.type = this.link !== null ? 'link' : 'text';
        return;
      }
    }

    if (isDataWithOnHoverMessage(data)) {
      this.type = 'textWithOnHoverMessage';
      this.textWithOnHoverMessage = data;
      return;
    }
  }
}
