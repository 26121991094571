import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { EducationalResourcesService } from '@/src/app/features/educational-resources/educational-resources.service';
import { MedicalMeeting, MedicalMeetingCategory } from '@/src/app/features/educational-resources/models/medical-meeting.model';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { NavigationSubTabEnum } from '@/src/app/shared/components/navigation/model/nav-item-enum.enum';
import { cleftPublications, resourcesRoute, videosRoute } from '@/src/app/utils/routing.utils';
import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResourceSection } from '../../models/resource.model';
import { staticFilesRegistry } from '@shared/modules/static-files/static-files.utils';

@Component({
  selector: 'stx-education-and-research-page',
  templateUrl: './education-and-research-page.component.html',
  styleUrls: ['./education-and-research-page.component.scss']
})
export class EducationAndResearchPageComponent extends BaseComponent implements OnInit {
  protected readonly staticFilesRegistry = staticFilesRegistry;
  readonly NavigationSubTabEnum = NavigationSubTabEnum;
  readonly source = educationAndResearchSections;
  readonly resourcesRoute = resourcesRoute;
  readonly weeklyAbstractsRoute = cleftPublications;
  readonly videosRoute = videosRoute;
  readonly PermissionEnum = PermissionEnum;
  conferences: WritableSignal<MedicalMeeting[]> = signal(null);
  webinars: WritableSignal<MedicalMeeting[]> = signal(null);
  private educationalResourcesService: EducationalResourcesService = inject(EducationalResourcesService);

  ngOnInit(): void {
    this.fetchAndPopulateMedicalMeetings();
  }

  fetchAndPopulateMedicalMeetings(update = false) {
    this.subSink.sink = this.educationalResourcesService.fetchMedicalMeetings(update).subscribe(result => {
      if (result) {
        this.webinars.set(result.filter(x => x.category === MedicalMeetingCategory.CONFERENCE));
        this.conferences.set(result.filter(x => x.category === MedicalMeetingCategory.WEBINAR));
      }
    });
  }
}

export const educationAndResearchSections: readonly ResourceSection[] = [
  {
    resources: [
      {
        label: 'resources_for_partners.education_and_training.simulator.label',
        urlList: [
          {
            label: 'English',
            resourceName: 'Smile_Train_Virtual_Surgery_Simulator_Instructions',
            putBuiltUrlInLabel: true
          },
          {
            label: 'resources_for_partners.education_and_training.simulator.access',
            labelUrl: 'https://smiletrain.biodigital.com/#/'
          },
          {
            label: 'resources_for_partners.education_and_training.simulator.download',
            labelUrl: 'http://www.smiletrain.org/landing/virtual-surgery-simulator'
          }
        ],
        useColumnViewForUrlList: true,
        isSeparated: true
      },
      {
        label: 'resources.education_and_research.manual',
        resourceName: 'Manual_for_pre_surgical_infant_orthopedics-Dra_Marta_Mejia_',
        translationList: [['en']]
      }
    ],

    isSeparated: true
  },
  {
    sectionTitle: 'resources.education_and_research.examples',
    resources: [
      {
        label: 'treatment.presurgical_orthopedics',
        resourceName: 'STX_sample_cases_PSIO_2020-October',
        translationList: [['']]
      }
    ]
  }
];
