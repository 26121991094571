import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LanguagePair } from '@shared/components/language-switcher/language.model';
import { BaseComponent } from '../../base-component/base.component';

@Component({
  selector: 'stx-document-language-select',
  templateUrl: './document-language-select.component.html',
  styleUrls: ['./document-language-select.component.scss']
})
export class DocumentLanguageSelectComponent extends BaseComponent {
  @Input() formLanguages: Array<LanguagePair>;
  @Output() languageSelected = new EventEmitter<string>();

  selectLanguage(selectedLanguage: LanguagePair) {
    this.languageSelected.emit(selectedLanguage.shortcut);
  }
}
