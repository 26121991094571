<div class="align-left flex-row-container gap-20-grid color-link">
  <div class="cursor-pointer" *ngIf="patientTreatment.unlockable">
    <mat-icon
      data-testid="button-patient-detail-unlock"
      [matTooltip]="'treatment.status_bar.unlock.tooltip' | translate"
      [matTooltipClass]="'tooltip'"
      (click)="openDialog(DialogMode.UNLOCK)"
      matTooltipPosition="below"
      >lock_open</mat-icon
    >
  </div>
  <div class="cursor-pointer" *ngIf="canUnlink && !patientTreatment.isReadonly">
    <mat-icon
      data-testid="button-patient-detail-unlink"
      class="unlink-icon"
      [matTooltip]="'patient.tooltip.unlink' | translate"
      (click)="openDialog(DialogMode.UNLINK)"
      matTooltipPosition="below"
      >link_off</mat-icon
    >
  </div>
  <div class="cursor-pointer" *ngIf="patientTreatment.deletable && !patientTreatment.isReadonly">
    <mat-icon
      data-testid="button-patient-detail-delete"
      [matTooltip]="'delete' | translate"
      [matTooltipClass]="'tooltip'"
      (click)="openDialog(DialogMode.DELETE_PATIENT)"
      matTooltipPosition="below"
      >delete_outline</mat-icon
    >
  </div>
</div>
