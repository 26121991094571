import { DictionaryTranslation } from '@/src/app/features/patient/models/patient.model';
import { ProblemType, SuspiciousRecordProblemType } from '@/src/app/features/suspicious-records/enums/problem-type.enum';
import { SuspiciousRecordReviewActionType } from '@/src/app/features/suspicious-records/enums/suspicious-records.enum';
import { SuspiciousRecordReviewResult } from '@/src/app/features/suspicious-records/models/suspicious-record-review-result.model';
import { SuspiciousRecordDialog } from '@/src/app/features/suspicious-records/models/suspicious-record.model';
import { ProblematicRecord } from '@/src/app/features/suspicious-records/models/suspicious-records-and-frauds.model';
import { SuspiciousRecordsService } from '@/src/app/features/suspicious-records/suspicious-records.service';
import {
  problematicOptions,
  problemSeverityMap,
  rejectionReasonMap,
  rejectionReasons,
  TREATMENT_STATE_ERROR
} from '@/src/app/features/suspicious-records/suspicious-records.util';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { SpinnerService } from '@/src/app/shared/components/spinner/service/spinner.service';
import { GlobalErrorHandlerService } from '@/src/app/shared/services/global-error-handler.service';
import { atLeastOneRequired, StxValidators } from '@/src/app/shared/validation/validators';
import { intervention, patientDetailsPath, surgicalRoute } from '@/src/app/utils/routing.utils';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  templateUrl: './suspicious-record-reason-dialog.component.html',
  styleUrls: ['./suspicious-record-reason-dialog.component.scss']
})
export class SuspiciousRecordReasonDialogComponent extends BaseComponent implements OnInit {
  readonly patientDetailsPath = patientDetailsPath;
  readonly surgicalRoute = surgicalRoute;
  readonly intervention = intervention;
  readonly problematicOptions = problematicOptions;

  reasonFormGroup: UntypedFormGroup;
  previousProblematicRecords: ProblematicRecord[];
  rejectionReasons: DictionaryTranslation[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private suspiciousRecordsService: SuspiciousRecordsService,
    @Inject(MAT_DIALOG_DATA) public data: SuspiciousRecordDialog,
    private globalErrorHandler: GlobalErrorHandlerService,
    private spinnerService: SpinnerService,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<SuspiciousRecordReasonDialogComponent>,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeSearchForm();
    this.findProblematicRecords();
    this.getRejectionReasons();
    this.setSubscriptions();
  }

  private initializeSearchForm(): void {
    this.reasonFormGroup = this.formBuilder.group(
      {
        accept: [this.data.isAccepted],
        status: [this.data.isAccepted ? SuspiciousRecordProblemType.NOT_PROBLEMATIC : null, StxValidators.required],
        duplicatePrimaryOperation: [],
        photosDontMeetStandards: [!this.data.isAccepted],
        surgeryTypeMislabeled: [],
        surgeryNotFundedByST: [],
        surgeryResultsNotEvident: [],
        saqProtocolsViolated: [],
        externalComments: [null, Validators.maxLength(512)],
        internalComments: [null, Validators.maxLength(512)]
      },
      {
        validators: [this.rejectionReasonsRequiredIfNoDuplicatesPresent()]
      }
    );
  }

  private rejectionReasonsRequiredIfNoDuplicatesPresent(): ValidatorFn {
    let rejectionReasonsValidator: ValidatorFn = atLeastOneRequired(rejectionReasons, 'rejectionReasons', 'accept', false);

    if (!this.hasDuplicates()) {
      return rejectionReasonsValidator;
    }
    return Validators.nullValidator;
  }

  private findProblematicRecords(): void {
    this.wsHelperService
      .call(this.suspiciousRecordsService.getPreviousProblematicRecords(this.data.recordId), {
        handleErrorCallback: SuspiciousRecordReasonDialogComponent.handleGetValidationDataError
      })
      .subscribe(records => {
        if (!!records) {
          this.previousProblematicRecords = records;
          this.previousProblematicRecords.forEach(record => {
            record.statusKey = this.getSeverity(problemSeverityMap.get(record.suspiciousRecordStatus));
          });
        }
      });
  }

  private static handleGetValidationDataError = (error: HttpErrorResponse) => {
    if (error.status && error.status === TREATMENT_STATE_ERROR) {
      return;
    }
  };

  private setSubscriptions(): void {
    this.subSink.sink = this.reasonFormGroup.get('accept').valueChanges.subscribe(value => {
      if (value) {
        this.reasonFormGroup.controls.duplicatePrimaryOperation.setValue(false);
        this.reasonFormGroup.controls.photosDontMeetStandards.setValue(false);
        this.reasonFormGroup.controls.surgeryNotFundedByST.setValue(false);
        this.reasonFormGroup.controls.surgeryResultsNotEvident.setValue(false);
        this.reasonFormGroup.controls.surgeryTypeMislabeled.setValue(false);
        this.reasonFormGroup.controls.saqProtocolsViolated.setValue(false);
      }
    });
    this.subSink.sink = this.reasonFormGroup.controls.duplicatePrimaryOperation.valueChanges.subscribe(value =>
      this.setAcceptToFalseIfNotNull(value)
    );
    this.subSink.sink = this.reasonFormGroup.controls.photosDontMeetStandards.valueChanges.subscribe(value =>
      this.setAcceptToFalseIfNotNull(value)
    );
    this.subSink.sink = this.reasonFormGroup.controls.surgeryNotFundedByST.valueChanges.subscribe(value =>
      this.setAcceptToFalseIfNotNull(value)
    );
    this.subSink.sink = this.reasonFormGroup.controls.surgeryResultsNotEvident.valueChanges.subscribe(value =>
      this.setAcceptToFalseIfNotNull(value)
    );
    this.subSink.sink = this.reasonFormGroup.controls.surgeryTypeMislabeled.valueChanges.subscribe(value =>
      this.setAcceptToFalseIfNotNull(value)
    );
    this.subSink.sink = this.reasonFormGroup.controls.saqProtocolsViolated.valueChanges.subscribe(value =>
      this.setAcceptToFalseIfNotNull(value)
    );
  }

  private setAcceptToFalseIfNotNull(value: unknown) {
    if (value) {
      this.reasonFormGroup.controls.accept.setValue(false);
    }
  }

  private getSeverity(problemType: SuspiciousRecordProblemType): string {
    switch (problemType) {
      case SuspiciousRecordProblemType.PROBLEMATIC_MILD:
        return 'suspicious_records.severity.mild';
      case SuspiciousRecordProblemType.PROBLEMATIC_MODERATE:
        return 'suspicious_records.severity.moderate';
      case SuspiciousRecordProblemType.PROBLEMATIC_SEVERE:
        return 'suspicious_records.severity.severe';
      default:
        return '';
    }
  }

  private getRejectionReasons(): void {
    this.wsHelperService.callWithSpinner(this.suspiciousRecordsService.getProblemTypes()).subscribe(problems => {
      this.rejectionReasons = problems;
      this.rejectionReasons.forEach(reason => {
        reason.name = rejectionReasonMap.get(reason.value);
      });
      this.cd.detectChanges();
    });
  }

  skipRecord(): void {
    this.subSink.sink = this.suspiciousRecordsService
      .skipRecord(this.data.recordId, this.spinnerService, this.globalErrorHandler, this.cd)
      .subscribe({
        next: () => this.dialogRef.close(true)
      });
  }

  save(): void {
    if (!this.reasonFormGroup.valid) {
      this.reasonFormGroup.markAllAsTouched();
      return;
    }

    let actionType: SuspiciousRecordReviewActionType;
    let problems: ProblemType[] | null = null;
    let problemComment: string | null = null;

    if (this.reasonFormGroup.controls.accept.value) {
      actionType = SuspiciousRecordReviewActionType.ACCEPT;
    } else {
      actionType = SuspiciousRecordReviewActionType.REJECT;
      problems = this.getProblems();
      problemComment = this.reasonFormGroup.controls.externalComments.value;
    }

    this.subSink.sink = this.suspiciousRecordsService
      .saveSuspiciousRecordReviewResult(
        this.data,
        new SuspiciousRecordReviewResult(
          actionType,
          this.data.recordId,
          this.reasonFormGroup.controls.status.value,
          this.reasonFormGroup.controls.internalComments.value,
          this.data.dupTreatmentIds,
          problems,
          problemComment
        ),
        this.spinnerService,
        this.globalErrorHandler,
        this.cd
      )
      .subscribe({
        next: () => this.dialogRef.close(true)
      });
  }

  private getProblems(): ProblemType[] {
    let problems: ProblemType[] = [];
    if (this.reasonFormGroup.controls.duplicatePrimaryOperation.value) {
      problems.push(ProblemType.DUPLICATE_PRIMARY_OPERATION);
    }
    if (this.reasonFormGroup.controls.photosDontMeetStandards.value) {
      problems.push(ProblemType.PHOTOS_DONT_MEET_STANDARDS);
    }
    if (this.reasonFormGroup.controls.surgeryNotFundedByST.value) {
      problems.push(ProblemType.SURGERY_NOT_FUNDED_BY_ST);
    }
    if (this.reasonFormGroup.controls.surgeryResultsNotEvident.value) {
      problems.push(ProblemType.SURGERY_RESULTS_NOT_EVIDENT);
    }
    if (this.reasonFormGroup.controls.surgeryTypeMislabeled.value) {
      problems.push(ProblemType.SURGERY_TYPE_MISLABELED);
    }
    if (this.reasonFormGroup.controls.saqProtocolsViolated.value) {
      problems.push(ProblemType.SAQ_PROTOCOLS_VIOLATED);
    }
    if (this.hasDuplicates()) {
      problems.push(ProblemType.DUPLICATE_RECORD);
    }
    return problems;
  }

  private hasDuplicates(): boolean {
    return this.data.dupTreatmentIds?.length !== 0;
  }

  setDefaultRejectionReason(): void {
    this.reasonFormGroup.get('photosDontMeetStandards')?.setValue(true);
  }
}
