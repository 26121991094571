import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { OperationDateLink } from './treatment-date.model';

@Component({
  selector: 'stx-treatment-date',
  templateUrl: './treatment-date.component.html',
  styleUrls: ['./treatment-date.component.scss']
})
export class TreatmentDateComponent {
  @Input() direction: 'row' | 'column' = 'row';
  @Input() createDateLink: OperationDateLink;
  @Input() createDate: string;
  @Input() submitDate: string;
  @Input() operationDateLink: OperationDateLink;
  @Input() operationDate: string;
}

@NgModule({
  declarations: [TreatmentDateComponent],
  imports: [MatIconModule, CommonModule, RouterModule, FlexLayoutModule],
  exports: [TreatmentDateComponent]
})
export class TreatmentDateComponentModule {}
