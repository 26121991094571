<stx-form-panel>
  <form [formGroup]="form">
    <div>
      <div *ngFor="let item of formConfigurationData" class="form-row">
        <div class="flex-column-container" *ngIf="item.typeOfElement === GenericFormElementType.Input">
          <stx-input
            id="{{ item.nameOfElement }}"
            [label]="item.nameToDisplay"
            [formGroup]="form"
            [controlName]="item.formName"
            [formMode]="item.formMode"
            [type]="item.dataType"
          ></stx-input>
        </div>
        <div class="flex-column-container" *ngIf="item.typeOfElement === GenericFormElementType.Select">
          <stx-select
            id="{{ item.nameOfElement }}"
            [label]="item.nameToDisplay"
            [dictionaryList]="item.options"
            [formGroup]="form"
            [controlName]="item.formName"
            [formMode]="item.formMode"
          ></stx-select>
          <div></div>
        </div>
      </div>
    </div>
    <stx-action-buttons [showSecondary]="false" [asyncPrimaryAction]="submitForm"></stx-action-buttons>
  </form>
</stx-form-panel>
