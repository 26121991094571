<stx-readonly-field [label]="label | async">
  <ng-container [ngSwitch]="mediaType">
    <stx-readonly-view-video *ngSwitchCase="MediaContentType.VIDEO" [video]="media"></stx-readonly-view-video>
    <stx-readonly-view-image
      [shouldDisablePhotoControls]="shouldDisablePhotoControls"
      *ngSwitchCase="MediaContentType.IMAGE"
      [photo]="media"
    ></stx-readonly-view-image>
    <stx-readonly-view-audio *ngSwitchCase="MediaContentType.AUDIO" [audio]="media"></stx-readonly-view-audio>
  </ng-container>
</stx-readonly-field>
