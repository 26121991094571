import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule, OnChanges } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

import { QaReviewReliabilitySummaryColumn, QaReviewReliabilitySummaryViewRecord } from './qa-reviewer-reliability-report-summary.model';
import { QaReviewerReliabilitySummaryRecord } from '@src/app/features/reports/components/report-filters/models/report-data.model';
import { TableStructure } from '@/src/app/shared/components/table/models/table-structure.model';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { ReportUtilsService } from '@src/app/features/reports/components/report-filters/services/report-utils.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-qa-reviewer-reliability-report-summary',
  templateUrl: './qa-reviewer-reliability-report-summary.component.html'
})
export class QaReviewerReliabilityReportSummaryComponent implements OnChanges {
  private static readonly percentageColumns: QaReviewReliabilitySummaryColumn[] = ['accuracy'];

  @Input() summaryPage: TableStructure<QaReviewerReliabilitySummaryRecord>;

  columns: Array<keyof QaReviewerReliabilitySummaryRecord> = [];
  headers: Record<string, string> = {};
  records: QaReviewReliabilitySummaryViewRecord[] = [];

  constructor(private reportUtilsService: ReportUtilsService) {}

  ngOnChanges() {
    this.prepareTable();
  }

  private prepareTable() {
    this.headers = this.reportUtilsService.getPageHeaders({
      columns: this.summaryPage.labels
    });
    this.columns = this.reportUtilsService.getColumnsList(this.summaryPage.labels);
    this.records = this.summaryPage.data.page.content.map(record =>
      this.reportUtilsService.formatRecordNumericColumns<QaReviewReliabilitySummaryViewRecord>({
        record,
        columns: QaReviewerReliabilityReportSummaryComponent.percentageColumns,
        postfix: '%',
        precision: 0
      })
    );
  }
}

@NgModule({
  imports: [CommonModule, EmptyTableStateComponentModule, MatTableModule],
  declarations: [QaReviewerReliabilityReportSummaryComponent],
  exports: [QaReviewerReliabilityReportSummaryComponent]
})
export class QaReviewerReliabilityReportSummaryComponentModule {}
