import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReportDownloaderComponentModule } from '../../components/report-downloader/report-downloader.component';
import { ReportConfig, ReportDownloadTypes } from '../../components/report-filters/models/report-filters.model';
import { ReportFiltersModule } from '../../components/report-filters/report-filters.component';
import { ReportTableDataService } from '../../components/report-filters/services/report-table-data.service';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import { SurgicalFollowUpVisitsReportTableComponentModule } from './components/surgical-follow-up-visits-report-table/surgical-follow-up-visits-report-table.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';

@Component({
  selector: 'stx-surgical-follow-up-visits-report',
  templateUrl: './surgical-follow-up-visits-report.component.html'
})
export class SurgicalFollowUpVisitsReportComponent {
  readonly reportConfig: ReportConfig = {
    type: 'SURGICAL_FOLLOW_UP_VISITS',
    downloadOptions: {
      [ReportDownloadTypes.EXCEL]: true
    },
    filters: {
      age: true,
      gender: true,
      geography: true,
      organizationsTree: true,
      recordDate: true,
      status: true
    }
  };
  constructor(public reportService: ReportService, private reportTableDataService: ReportTableDataService) {}

  applyFilters(pagination: PaginationSortingModel) {
    this.reportTableDataService.prepareList('SURGICAL_FOLLOW_UP_VISITS', this.reportService.model, pagination);
  }
}

@NgModule({
  declarations: [SurgicalFollowUpVisitsReportComponent],
  imports: [
    ReportFiltersModule,
    TranslateModule,
    ReportDownloaderComponentModule,
    CommonModule,
    SurgicalFollowUpVisitsReportTableComponentModule,
    ReportHeaderComponentModule,
    GeneralCommonsModule
  ],
  exports: [SurgicalFollowUpVisitsReportComponent]
})
export class SurgicalFollowUpVisitsReportComponentModule {}
