<stx-authentication-container>
  <div *ngIf="showExpiredCredentialsMessage" class="login-error p-10 m-b-10 fs-14 flex-row-container flex-center">
    <mat-icon class="warning-icon">warning</mat-icon>
    <div class="black-text m-l-10">{{ 'error.credentials_expired' | translate }}</div>
  </div>
  <form [formGroup]="resetPasswordForm" class="password-form flex-column-container">
    <h3>RESET PASSWORD</h3>
    <label for="passwordReset" class="m-b-5">{{ 'password' | translate }}</label>
    <mat-form-field appearance="outline" #passwordReset class="input-field-auth" [ngClass]="{ 'm-b-95': hasErrors('password') }">
      <input
        data-testid="input-password-reset"
        [type]="hidePassword ? 'password' : 'text'"
        matInput
        formControlName="password"
        autocomplete="new-password"
        class="input-field-auth"
        id="passwordReset"
      />
      <mat-icon
        [ngClass]="{ visibility: !hidePassword, 'visibility-off': hidePassword }"
        matSuffix
        (click)="hidePassword = !hidePassword"
        >{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon
      >
      <mat-error class="stx-mat-error-align">
        <stx-multi-error-validator [violatedRules]="resetPasswordForm.get('password').errors"></stx-multi-error-validator>
      </mat-error>
    </mat-form-field>
    <label for="passwordConfirmReset" class="m-b-5">{{ 'settings.password_confirmation' | translate }}</label>
    <mat-form-field appearance="outline" class="input-field-auth">
      <input
        data-testid="input-password-confirm-reset"
        matInput
        formControlName="passwordConfirm"
        [type]="hideConfirmPassword ? 'password' : 'text'"
        class="input-field-auth"
        id="passwordConfirmReset"
        autocomplete="new-password"
      />
      <mat-icon
        [ngClass]="{ visibility: !hideConfirmPassword, 'visibility-off': hideConfirmPassword }"
        matSuffix
        (click)="hideConfirmPassword = !hideConfirmPassword"
        >{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon
      >
      <mat-error class="stx-mat-error-align" *ngIf="confirmPasswordNotMatch">{{ 'error.confirm_password' | translate }}</mat-error>
    </mat-form-field>
    <stx-action-buttons
      [showSecondary]="false"
      [asyncPrimaryAction]="resetPassword"
      [primaryDisabled]="resetPasswordForm.invalid"
    ></stx-action-buttons>
  </form>
</stx-authentication-container>
