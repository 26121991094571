import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrfValidationService {
  private prfValiation$ = new Subject<void>();

  validatePrfData() {
    this.prfValiation$.next();
  }

  getPrfValidation(): Observable<void> {
    return this.prfValiation$.asObservable();
  }
}
