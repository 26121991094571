import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ReportDownloaderComponentModule } from '../../components/report-downloader/report-downloader.component';
import { ReportConfig, ReportDownloadTypes } from '../../components/report-filters/models/report-filters.model';
import { ReportFiltersComponent, ReportFiltersModule } from '../../components/report-filters/report-filters.component';
import { ReportTableDataService } from '../../components/report-filters/services/report-table-data.service';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import { OrthodonticTreatmentReportTableComponentModule } from './components/orthodontic-treatment-report-table/orthodontic-treatment-report-table.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { AuthService } from '@src/app/features/authentication/services/auth.service';
import { PermissionEnum } from '@core/permissions/permission.enum';

@Component({
  selector: 'stx-orthodontic-treatments-report',
  templateUrl: './orthodontic-treatment-report.component.html'
})
export class OrthodonticTreatmentReportComponent extends BaseComponent implements OnInit {
  @ViewChild(ReportFiltersComponent) reportFiltersComponent: ReportFiltersComponent;
  readonly reportConfig: ReportConfig = {
    type: 'ORTHODONTIC_TREATMENTS',
    downloadOptions: {
      [ReportDownloadTypes.EXCEL]: true,
      [ReportDownloadTypes.EXCEL_ADMIN]: true,
      [ReportDownloadTypes.EXCEL_ADMIN_ID]: true
    },
    filters: {
      recordDate: true,
      gender: true,
      age: true,
      includePhotos: true,
      practitioners: true,
      geography: true,
      organizationsTree: true,
      orthoTypes: true,
      orthoStatuses: true,
      orthoQaGrading: this.authService.checkUserPermission(PermissionEnum.VIEW_REPORT_QA_FILTER)
    }
  };

  constructor(
    public reportService: ReportService,
    private reportTableDataService: ReportTableDataService,
    private authService: AuthService
  ) {
    super();
  }

  applyFilters(pagination: PaginationSortingModel) {
    this.reportTableDataService.prepareList('ORTHODONTIC_TREATMENTS', this.reportService.model, pagination);
  }

  ngOnInit(): void {
    this.subSink.sink = this.reportService.initialized$.subscribe(value => {
      if (value && this.reportService.reportFiltersFormGroup.status !== 'VALID') {
        this.reportService.showFilters();
      }
    });
  }
}

@NgModule({
  declarations: [OrthodonticTreatmentReportComponent],
  imports: [
    ReportFiltersModule,
    TranslateModule,
    ReportDownloaderComponentModule,
    CommonModule,
    OrthodonticTreatmentReportTableComponentModule,
    ReportHeaderComponentModule,
    GeneralCommonsModule
  ],
  exports: [OrthodonticTreatmentReportComponent]
})
export class OrthodonticTreatmentReportComponentModule {}
