import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PermissionModule } from '@core/permissions/permission.module';
import { TranslateModule } from '@ngx-translate/core';
import { ActionPromptModule } from '@shared/components/action-prompt/action-prompt.module';
import { FormModule } from '@shared/components/form/form.module';
import { PatientDocumentUploadModule } from '@shared/components/patient-document-upload/patient-document-upload.module';
import { UppyModule } from '@shared/components/uppy/uppy.module';
import { MaterialModule } from '@shared/modules/material/material.module';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { PatientReadonlyComponent } from '@src/app/features/patient/components/patient-readonly/patient-readonly.component';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { PatientDetailsBlockComponentModule } from '@shared/components/patient-details-block/patient-details-block.component';
import { FormsCommonsModule } from '@shared/modules/forms-commons/forms-commons.module';
import { PatientFormComponent } from '@src/app/features/patient/components/patient-form/patient-form.component';
import { PatientDictionariesService } from '@src/app/features/patient/services/patient-dictionaries.service';
import { PatientAwarePageComponent } from '@src/app/features/patient/components/patient-aware-page/patient-aware-page.component';
import { PatientDetailsPageComponent } from '@src/app/features/patient/components/patient-details-page/patient-details-page.component';
import { PatientTreatmentMinimizedComponent } from '@src/app/features/patient/components/patient-treatment-minimized/patient-treatment-minimized.component';
import { PatientTreatmentHistoryPageComponent } from '@src/app/features/patient/components/patient-treatment-history-page/patient-treatment-history-page.component';
import { SurgicalModule } from '@src/app/features/surgical/surgical.module';
import { SpeechModule } from '@src/app/features/speech/speech.module';
import { OrthognathicModule } from '@src/app/features/orthognathic/orthognathic.module';
import { OrthoModule } from '@src/app/features/ortho/ortho.module';
import { NutritionModule } from '@src/app/features/nutrition/nutrition.module';
import { PatientDetailsHeadingComponentModule } from '@shared/components/patient-details-heading/patient-details-heading.component';
import { PatientManagementService } from '@src/app/features/patient/services/patient-management.service';
import { PatientTreatmentDatesComponentModule } from '@shared/components/patient-treatment-dates/patient-treatment-dates.component';
import { FeatureToggleModule } from '@core/feature-toggle/feature-toggle.module';
import { NutritionManagementModule } from '@src/app/features/nutrition-management/nutrition-management.module';

@NgModule({
  declarations: [
    PatientAwarePageComponent,
    PatientDetailsPageComponent,
    PatientFormComponent,
    PatientReadonlyComponent,
    PatientTreatmentHistoryPageComponent,
    PatientTreatmentMinimizedComponent
  ],
  providers: [PatientDictionariesService, PatientManagementService],
  imports: [
    ActionPromptModule,
    CommonModule,
    FlexLayoutModule,
    FormModule,
    FormsCommonsModule,
    GeneralCommonsModule,
    MaterialModule,
    NutritionModule,
    OrthoModule,
    OrthognathicModule,
    PatientDetailsBlockComponentModule,
    PatientDetailsHeadingComponentModule,
    PatientDocumentUploadModule,
    PatientTreatmentDatesComponentModule,
    PermissionModule,
    ReactiveFormsModule,
    ReadonlyCommonsModule,
    RouterModule,
    SpeechModule,
    SurgicalModule,
    TranslateModule.forChild(),
    UppyModule,
    FeatureToggleModule,
    NutritionManagementModule
  ],
  exports: []
})
export class PatientModule {}
