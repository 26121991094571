<div class="resources-navigation p-10">
  <a
    mat-button
    *ngFor="let item of navigationItemToDisplay"
    [routerLink]="['/' + item.href]"
    class="resource-item"
    routerLinkActive="active"
    >{{ item.text | translate }}</a
  >
</div>
