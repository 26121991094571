<stx-generic-page>
  <stx-password-expiry *ngIf="isViewDashboardForUser()"></stx-password-expiry>
  <div class="flex-column-container">
    <div class="data-section">
      <h3>{{ 'ortho_qa_dashboard.psio.grading.header' | translate }}</h3>
      <ng-container *ngTemplateOutlet="gradingDescription"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="psio"></ng-container>
    <div class="data-section">
      <h3>{{ 'ortho_qa_dashboard.mixed.grading.header' | translate }}</h3>
      <ng-container *ngTemplateOutlet="gradingDescription"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="mixed"></ng-container>
    <div class="data-section">
      <h3>{{ 'ortho_qa_dashboard.permanent.grading.header' | translate }}</h3>
      <ng-container *ngTemplateOutlet="gradingDescription"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="permanent"></ng-container>
  </div>
</stx-generic-page>
<ng-template #gradingDescription>
  <div>
    <p>{{ 'ortho_qa_dashboard.grading_1' | translate }}</p>
    <p>{{ 'ortho_qa_dashboard.grading_2' | translate }}</p>
  </div>
</ng-template>

<ng-template #psio>
  <div class="flex-column-container">
    <div class="layout-columns-1-1">
      <div class="layout-columns-1-1">
        <div>
          <div class="data-tile-container">
            <div class="data-tile-row">
              <div class="data-tile">
                <div class="data-tile-header">{{ 'ortho_qa_dashboard.unilateral_pre_psio.header' | translate }}</div>
                <div class="data-tile-content">
                  <stx-dashboard-grade-item
                    [grade]="'surgical_qa_dashboard.grade1'"
                    [descriptionParagraph]="'ortho_qa_dashboard.unilateral_pre_psio.grade1'"
                    [boldParagraph]="'ortho_qa_dashboard.psio.not_candidate'"
                    [tagName]="'surgical_qa_dashboard_mild'"
                  ></stx-dashboard-grade-item>
                  <hr class="data-section-separator" />
                  <stx-dashboard-grade-item
                    [grade]="'surgical_qa_dashboard.grade2'"
                    [descriptionParagraph]="'ortho_qa_dashboard.unilateral_pre_psio.grade2'"
                    [boldParagraph]="'ortho_qa_dashboard.psio.would_benefit'"
                    [tagName]="'suspicious_records.severity.moderate'"
                  ></stx-dashboard-grade-item>
                  <hr class="data-section-separator" />
                  <stx-dashboard-grade-item
                    [grade]="'surgical_qa_dashboard.grade3'"
                    [descriptionParagraph]="'ortho_qa_dashboard.unilateral_pre_psio.grade3'"
                    [boldParagraph]="'ortho_qa_dashboard.psio.would_greatly_benefit'"
                    [tagName]="'surgical_qa_dashboard.severe'"
                  ></stx-dashboard-grade-item>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="data-tile-container">
            <div class="data-tile-row">
              <div class="data-tile">
                <div class="data-tile-header">{{ 'ortho_qa_dashboard.unilateral_post_psio.header' | translate }}</div>
                <div class="data-tile-content">
                  <ng-container *ngTemplateOutlet="postPsioGrades"></ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="layout-columns-1-1">
        <div>
          <div class="data-tile-container">
            <div class="data-tile-row">
              <div class="data-tile">
                <div class="data-tile-header">{{ 'ortho_qa_dashboard.bilateral_pre_psio.header' | translate }}</div>
                <div class="data-tile-content">
                  <stx-dashboard-grade-item
                    [grade]="'surgical_qa_dashboard.grade1'"
                    [descriptionParagraph]="'ortho_qa_dashboard.bilateral_pre_psio.grade1'"
                    [boldParagraph]="'ortho_qa_dashboard.psio.not_candidate'"
                    [tagName]="'surgical_qa_dashboard_mild'"
                  ></stx-dashboard-grade-item>
                  <hr class="data-section-separator" />
                  <stx-dashboard-grade-item
                    [grade]="'surgical_qa_dashboard.grade2'"
                    [descriptionParagraph]="'ortho_qa_dashboard.bilateral_pre_psio.grade2'"
                    [boldParagraph]="'ortho_qa_dashboard.psio.would_benefit'"
                    [tagName]="'suspicious_records.severity.moderate'"
                  ></stx-dashboard-grade-item>
                  <hr class="data-section-separator" />
                  <stx-dashboard-grade-item
                    [grade]="'surgical_qa_dashboard.grade3'"
                    [descriptionParagraph]="'ortho_qa_dashboard.bilateral_pre_psio.grade3'"
                    [boldParagraph]="'ortho_qa_dashboard.psio.would_greatly_benefit'"
                    [tagName]="'surgical_qa_dashboard.severe'"
                  ></stx-dashboard-grade-item>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="data-tile-container">
            <div class="data-tile-row">
              <div class="data-tile">
                <div class="data-tile-header">{{ 'ortho_qa_dashboard.bilateral_post_psio.header' | translate }}</div>
                <div class="data-tile-content">
                  <ng-container *ngTemplateOutlet="postPsioGrades"></ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #postPsioGrades>
  <stx-dashboard-grade-item
    [grade]="'surgical_qa_dashboard.grade1'"
    [descriptionParagraph]="'ortho_qa_dashboard.post_psio.grade1'"
    [boldParagraph]="'ortho_qa_dashboard.failed_case'"
  ></stx-dashboard-grade-item>
  <hr class="data-section-separator" />
  <stx-dashboard-grade-item
    [grade]="'surgical_qa_dashboard.grade2'"
    [descriptionParagraph]="'ortho_qa_dashboard.post_psio.grade2'"
    [boldParagraph]="'ortho_qa_dashboard.psio.minimal_benefit'"
  ></stx-dashboard-grade-item>
  <hr class="data-section-separator" />
  <stx-dashboard-grade-item
    [grade]="'surgical_qa_dashboard.grade3'"
    [descriptionParagraph]="'ortho_qa_dashboard.post_psio.grade3'"
    [boldParagraph]="'ortho_qa_dashboard.psio.somewhat_favourable'"
  ></stx-dashboard-grade-item>
  <hr class="data-section-separator" />
  <stx-dashboard-grade-item
    [grade]="'surgical_qa_dashboard.grade4'"
    [descriptionParagraph]="'ortho_qa_dashboard.post_psio.grade4'"
    [boldParagraph]="'ortho_qa_dashboard.psio.favourable'"
  ></stx-dashboard-grade-item>
  <hr class="data-section-separator" />
  <stx-dashboard-grade-item
    [grade]="'surgical_qa_dashboard.grade5'"
    [descriptionParagraph]="'ortho_qa_dashboard.post_psio.grade5'"
    [boldParagraph]="'ortho_qa_dashboard.psio.extremely_favourable'"
  ></stx-dashboard-grade-item>
</ng-template>

<ng-template #mixed>
  <div class="flex-column-container">
    <div class="layout-columns-1-1">
      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'ortho_qa_dashboard.pre_mixed.header' | translate }}</div>
              <div class="data-tile-content">
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade1'"
                  [bulletedList]="[
                    'ortho_qa_dashboard.pre_mixed.grade1_1',
                    'ortho_qa_dashboard.pre_mixed.grade1_2',
                    'ortho_qa_dashboard.pre_mixed.grade1_3',
                    'ortho_qa_dashboard.pre_mixed.grade1_4',
                    'ortho_qa_dashboard.pre_mixed.grade1_5',
                    'ortho_qa_dashboard.pre_mixed.grade1_6',
                    'ortho_qa_dashboard.pre_mixed.grade1_7'
                  ]"
                  [boldParagraph]="'ortho_qa_dashboard.mixed.not_candidate'"
                  [tagName]="'ortho_qa_dashboard.tag.goslon.mild'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade2'"
                  [bulletedList]="[
                    'ortho_qa_dashboard.pre_mixed.grade2_1',
                    'ortho_qa_dashboard.pre_mixed.grade2_2',
                    'ortho_qa_dashboard.pre_mixed.grade2_3',
                    'ortho_qa_dashboard.pre_mixed.grade2_4',
                    'ortho_qa_dashboard.pre_mixed.grade2_5',
                    'ortho_qa_dashboard.pre_mixed.grade2_6',
                    'ortho_qa_dashboard.pre_mixed.grade2_7'
                  ]"
                  [boldParagraph]="'ortho_qa_dashboard.mixed.is_candidate'"
                  [tagName]="'ortho_qa_dashboard.tag.goslon.moderate'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade3'"
                  [bulletedList]="[
                    'ortho_qa_dashboard.pre_mixed.grade3_1',
                    'ortho_qa_dashboard.pre_mixed.grade3_2',
                    'ortho_qa_dashboard.pre_mixed.grade3_3',
                    'ortho_qa_dashboard.pre_mixed.grade3_4',
                    'ortho_qa_dashboard.pre_mixed.grade3_5'
                  ]"
                  [boldParagraph]="'ortho_qa_dashboard.mixed.is_candidate'"
                  [tagName]="'ortho_qa_dashboard.tag.goslon.severe'"
                ></stx-dashboard-grade-item>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'ortho_qa_dashboard.post_mixed.header' | translate }}</div>
              <div class="data-tile-content">
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade1'"
                  [paragraphList]="[
                    'ortho_qa_dashboard.post_mixed.grade1_1',
                    'ortho_qa_dashboard.post_mixed.grade1_2',
                    'ortho_qa_dashboard.post_mixed.grade1_3'
                  ]"
                  [boldParagraph]="'ortho_qa_dashboard.failed_case'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade2'"
                  [paragraphList]="[
                    'ortho_qa_dashboard.post_mixed.grade2_1',
                    'ortho_qa_dashboard.post_mixed.grade2_2',
                    'ortho_qa_dashboard.post_mixed.grade2_3'
                  ]"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade3'"
                  [paragraphList]="[
                    'ortho_qa_dashboard.post_mixed.grade3_1',
                    'ortho_qa_dashboard.post_mixed.grade3_2',
                    'ortho_qa_dashboard.post_mixed.grade3_3'
                  ]"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade4'"
                  [paragraphList]="[
                    'ortho_qa_dashboard.post_mixed.grade4_1',
                    'ortho_qa_dashboard.post_mixed.grade4_2',
                    'ortho_qa_dashboard.post_mixed.grade4_3'
                  ]"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade5'"
                  [paragraphList]="[
                    'ortho_qa_dashboard.post_mixed.grade5_1',
                    'ortho_qa_dashboard.post_mixed.grade5_2',
                    'ortho_qa_dashboard.post_mixed.grade5_3'
                  ]"
                ></stx-dashboard-grade-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #permanent>
  <div class="flex-column-container">
    <div class="layout-columns-1-2">
      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'ortho_qa_dashboard.pre_permanent.header' | translate }}</div>
              <div class="data-tile-content">
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade1'"
                  [paragraphList]="['ortho_qa_dashboard.pre_permanent.grade1']"
                  [boldParagraph]="'ortho_qa_dashboard.permanent.not_candidate'"
                  [tagName]="'ortho_qa_dashboard.tag.goslon.mild'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade2'"
                  [paragraphList]="['ortho_qa_dashboard.pre_permanent.grade2']"
                  [boldParagraph]="'ortho_qa_dashboard.permanent.is_candidate'"
                  [tagName]="'ortho_qa_dashboard.tag.goslon.moderate'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade3'"
                  [paragraphList]="['ortho_qa_dashboard.pre_permanent.grade3']"
                  [boldParagraph]="'ortho_qa_dashboard.permanent.is_candidate'"
                  [tagName]="'ortho_qa_dashboard.tag.goslon.severe'"
                ></stx-dashboard-grade-item>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'ortho_qa_dashboard.post_permanent.header' | translate }}</div>
              <div class="data-tile-content">
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade1'"
                  [paragraphList]="[
                    'ortho_qa_dashboard.post_permanent.grade1_1',
                    'ortho_qa_dashboard.post_permanent.grade1_2',
                    'ortho_qa_dashboard.post_permanent.grade1_3'
                  ]"
                  [boldParagraph]="'ortho_qa_dashboard.failed_case'"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade2'"
                  [paragraphList]="[
                    'ortho_qa_dashboard.post_permanent.grade2_1',
                    'ortho_qa_dashboard.post_permanent.grade2_2',
                    'ortho_qa_dashboard.post_permanent.grade2_3'
                  ]"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade3'"
                  [paragraphList]="[
                    'ortho_qa_dashboard.post_permanent.grade3_1',
                    'ortho_qa_dashboard.post_permanent.grade3_2',
                    'ortho_qa_dashboard.post_permanent.grade3_3'
                  ]"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade4'"
                  [paragraphList]="[
                    'ortho_qa_dashboard.post_permanent.grade4_1',
                    'ortho_qa_dashboard.post_permanent.grade4_2',
                    'ortho_qa_dashboard.post_permanent.grade4_3'
                  ]"
                ></stx-dashboard-grade-item>
                <hr class="data-section-separator" />
                <stx-dashboard-grade-item
                  [grade]="'surgical_qa_dashboard.grade5'"
                  [paragraphList]="[
                    'ortho_qa_dashboard.post_permanent.grade5_1',
                    'ortho_qa_dashboard.post_permanent.grade5_2',
                    'ortho_qa_dashboard.post_permanent.grade5_3'
                  ]"
                ></stx-dashboard-grade-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
