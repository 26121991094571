import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GeneralCommonsModule } from '../../modules/general-commons/general-commons.module';
import { DataBlockComponent } from './data-block.component';

@NgModule({
  declarations: [DataBlockComponent],
  imports: [CommonModule, FontAwesomeModule, GeneralCommonsModule],
  exports: [DataBlockComponent]
})
export class DataBlockModule {}
