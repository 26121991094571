import { DataBlockModule } from '@/src/app/shared/components/data-block/data-block.module';
import { FormsCommonsModule } from '@/src/app/shared/modules/forms-commons/forms-commons.module';
import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionModule } from 'src/app/core/permissions/permission.module';
import { FormModule } from 'src/app/shared/components/form/form.module';
import { TableModule } from 'src/app/shared/components/table/table.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { CleftPublicationsFormComponent } from './components/cleft-publications-form/cleft-publications-form.component';
import { CleftPublicationsPageComponent } from './components/cleft-publications-page/cleft-publications-page.component';
import { EducationAndResearchPageComponent } from './components/education-and-research-page/education-and-research-page.component';
import { MedicalFormsPageComponent } from './components/medical-forms-page/medical-forms-page.component';
import { MedicalMeetingFormComponent } from './components/medical-meeting-form/medical-meeting-form.component';
import { MedicalMeetingsListComponent } from './components/medical-meetings-list/medical-meetings-list.component';
import { NutritionResourcesPageComponent } from './components/nutrition-resources-page/nutrition-resources-page.component';
import { ProtocolsAdvisoriesGuidelinesPageComponent } from './components/protocols-advisories-guidelines-page/protocols-advisories-guidelines-page.component';
import { ResourcesForFamiliesPageComponent } from './components/resources-for-families-page/resources-for-families-page.component';
import { ResourcesNavigationComponent } from './components/resources-navigation/resources-navigation.component';
import { UserGuidePageComponent } from './components/user-guide-page/user-guide-page.component';
import { VideosPageComponent } from './components/videos-page/videos-page.component';
import { GenericResourceListModule } from './shared/generic-resource-list.module';
import { SpeechResourcesPageComponent } from './components/speech-resources-page-component/speech-resources-page.component';
import { WhoReferenceDataComponent } from '@src/app/features/educational-resources/components/who-reference-data/who-reference-data.component';
import { StaticFilesModule } from '@shared/modules/static-files/static-files.module';

@NgModule({
  declarations: [
    CleftPublicationsFormComponent,
    CleftPublicationsPageComponent,
    EducationAndResearchPageComponent,
    MedicalFormsPageComponent,
    NutritionResourcesPageComponent,
    ProtocolsAdvisoriesGuidelinesPageComponent,
    ResourcesForFamiliesPageComponent,
    ResourcesNavigationComponent,
    UserGuidePageComponent,
    VideosPageComponent,
    MedicalMeetingsListComponent,
    MedicalMeetingFormComponent,
    SpeechResourcesPageComponent,
    WhoReferenceDataComponent
  ],
  imports: [
    CommonModule,
    DataBlockModule,
    FlexLayoutModule,
    FormsCommonsModule,
    FormModule,
    GeneralCommonsModule,
    GenericResourceListModule,
    MaterialModule,
    PermissionModule,
    ReactiveFormsModule,
    RouterModule,
    TableModule,
    TranslateModule,
    FontAwesomeModule,
    StaticFilesModule
  ],
  exports: [MedicalMeetingsListComponent, ResourcesNavigationComponent, ResourcesNavigationComponent]
})
export class EducationalResourcesModule {}
