import { faEnvelope, faExternalLinkAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'stx-simple-details-value',
  templateUrl: './simple-details-value.component.html',
  styleUrls: ['./simple-details-value.component.scss']
})
export class SimpleDetailsValueComponent {
  @Input()
  labelKey?: any;

  @Input()
  value: any;

  preIcon: IconProp = null;
  postIcon: IconProp = null;

  @Input()
  href: string = null;

  target: string = '_self';

  @Input()
  set phone(value: string) {
    if (value) {
      this.value = value;
      this.preIcon = faPhone;
    }
  }

  @Input()
  set email(value: string) {
    if (value) {
      this.value = value;
      this.href = 'mailto:' + value;
      this.preIcon = faEnvelope;
    }
  }

  @Input()
  set externalLink(value: string) {
    if (value) {
      this.value = value;
      this.href = value;
      this.target = '_blank';
      this.postIcon = faExternalLinkAlt;
    }
  }

  get isEmpty(): boolean {
    return !this.value || this.value === '';
  }
}
