import { CleftPublicationsSearchModel } from '@/src/app/features/educational-resources/models/cleft-publications-search.model';
import { MedicalMeeting } from '@/src/app/features/educational-resources/models/medical-meeting.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseHttpService } from 'src/app/shared/services/http/base-http.service';
import { HttpUtils } from 'src/app/utils/http.utils';
import { DataPageWithNames } from '../../shared/modules/general-commons/components/data-table/data-table.model';
import { WsHelperService } from '../../shared/services/ws-helper.service';
import { firstPagePageSort } from '../../utils/frontend-table-search-helper';
import { CleftPublication } from './models/cleft-publication.model';

const medicalLibraryRecordsUrl = 'medical-library-records';
const medicalMeetingsUrl = 'medical-meetings';
@Injectable({
  providedIn: 'root'
})
export class EducationalResourcesService extends BaseHttpService {
  private readonly _medicalMeetingsSubject$ = new BehaviorSubject<MedicalMeeting[] | null>(null);
  public readonly medicalMeetings$ = this._medicalMeetingsSubject$.asObservable();
  constructor(httpClient: HttpClient, private wsHelper: WsHelperService) {
    super(httpClient, { baseUrl: 'educational' });
  }

  fetchAllCleftPublications(): Observable<DataPageWithNames<CleftPublication>> {
    const params = HttpUtils.convertToSearchParams({ active: true, inactive: false } as CleftPublicationsSearchModel, firstPagePageSort);
    return this.wsHelper.callWithSpinner(
      this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}/${medicalLibraryRecordsUrl}`, { params })
    );
  }

  getYearFilters(): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/${medicalLibraryRecordsUrl}/filters`);
  }

  saveCleftPublications(cleftPublication: CleftPublication): Observable<CleftPublication> {
    return this.httpClient.post<CleftPublication>(`${this.baseUrl}/${medicalLibraryRecordsUrl}`, cleftPublication);
  }

  fetchMedicalMeetings(update = false): Observable<MedicalMeeting[]> {
    if (update || !this._medicalMeetingsSubject$.value) {
      return this.wsHelper
        .callWithSpinner(this.httpClient.get<MedicalMeeting[]>(`${this.baseUrl}/${medicalMeetingsUrl}`))
        .pipe(tap(response => this._medicalMeetingsSubject$.next(response)));
    }
    return this.medicalMeetings$;
  }

  saveMedicalMeeting(medicalMeeting: MedicalMeeting): Observable<MedicalMeeting> {
    return this.wsHelper.callWithSpinner(this.httpClient.post<MedicalMeeting>(`${this.baseUrl}/${medicalMeetingsUrl}`, medicalMeeting));
  }
}
