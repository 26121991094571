export enum ModifyPatientPrecondition {
  CHANGES_SUBMITTED_NUTRITION_MANAGEMENT_AGE_GROUP = 'CHANGES_SUBMITTED_NUTRITION_MANAGEMENT_AGE_GROUP',
  TRIGGERS_SAQ_REJECTION = 'TRIGGERS_SAQ_REJECTION'
}

export const preconditionToUserMessageMap = new Map<ModifyPatientPrecondition, string>([
  [ModifyPatientPrecondition.CHANGES_SUBMITTED_NUTRITION_MANAGEMENT_AGE_GROUP, 'patient.dialog.nutrition_affected'],
  [ModifyPatientPrecondition.TRIGGERS_SAQ_REJECTION, 'patient.dialog.surgical_treatment_affected']
]);

export function preconditionEnumToUserMessages(preconditions: ModifyPatientPrecondition[]): string[] {
  return preconditions.map(modifyPatientPrecondition => preconditionToUserMessageMap.get(modifyPatientPrecondition));
}
