import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

@Injectable({ providedIn: 'root' })
export class TempLangInterceptor implements HttpInterceptor {
  constructor(private currentUserService: CurrentUserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req.clone();

    if (
      req.method === 'GET' &&
      this.currentUserService.getCurrentUser() &&
      this.currentUserService.getCurrentUser().tempLang &&
      this.currentUserService.getCurrentUser().tempLang !== this.currentUserService.getCurrentUser().lang
    ) {
      request = request.clone({ headers: req.headers.set('X-Accept-Language', this.currentUserService.getCurrentUser().tempLang) });
    }

    return next.handle(request);
  }
}
