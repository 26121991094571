<stx-generic-page>
  <stx-report-header>
    {{ 'reports.surgical_treatments_compact' | translate }}
  </stx-report-header>
  <stx-report-filters [reportConfig]="reportConfig" (applyFilters)="applyFilters($event)"></stx-report-filters>
  <stx-surgical-treatment-compact-report-table
    *ngIf="reportService.initialized$ | async"
    [deIdentified]="deIdentify"
    [showPagination]="showPagination"
  ></stx-surgical-treatment-compact-report-table>
</stx-generic-page>
