<stx-page-with-sidebar>
  <ng-container slot="body">
    <stx-treatment-center-details [treatmentCenterId]="treatmentCenterId"></stx-treatment-center-details>
  </ng-container>
  <ng-container slot="sidebar" *ngIf="statisticsTable">
    <div class="flex-fill">
      <stx-statistics [statisticsTable]="statisticsTable" [statisticsPlots]="statisticsPlots"></stx-statistics>
    </div>
  </ng-container>
</stx-page-with-sidebar>
