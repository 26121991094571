<div class="form-columns-1-1-1">
  <ng-container *ngFor="let col of columns; first as isFirst; last as isLast">
    <div>
      <ng-container *ngTemplateOutlet="col.template"></ng-container>
      <div *ngIf="showSearchButton" class="search-btn">
        <stx-submit-button *ngIf="isFirst" class="block" (action)="searchButtonClicked.emit()" [buttonDisabled]="searchButtonDisabled">{{
          searchButtonText | translate
        }}</stx-submit-button>
      </div>
    </div>
    <hr *ngIf="!isLast" class="vertical-separator" />
  </ng-container>
</div>
