import { Component, Input } from '@angular/core';
import { ReportOptions } from '@src/app/features/reports/models/reports.model';

@Component({
  selector: 'stx-report-link-list',
  templateUrl: './report-link-list.component.html'
})
export class ReportLinkListComponent {
  @Input() options: ReportOptions[];
}
