import { PractitionerService } from '@/src/app/features/practitioner/practitioner.service';
import { ModalComponent } from '@/src/app/shared/components/modal/modal.component';
import { StxValidators } from '@/src/app/shared/validation/validators';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { FormMode } from '@/src/app/utils/form.utils';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { AuthService } from '@src/app/features/authentication/services/auth.service';

@Component({
  selector: 'stx-practitioner-create-note-modal',
  templateUrl: './practitioner-create-note-modal.component.html',
  styleUrls: ['./practitioner-create-note-modal.component.scss']
})
export class PractitionerCreateNoteModalComponent implements OnInit {
  noteForm: UntypedFormGroup;
  formMode: FormMode.NEW;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { practitionerId: number },
    public dialogRef: MatDialogRef<ModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private practititionerService: PractitionerService,
    private wsHelperService: WsHelperService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.initializeNoteForm();
  }

  initializeNoteForm(): void {
    this.noteForm = this.formBuilder.group({
      noteFormControl: [null, [StxValidators.required]]
    });
  }

  submitNote: AsyncButtonClickAction = () => {
    const note = this.noteForm.get('noteFormControl').value;

    if (this.authService.checkUserPermission(PermissionEnum.ADD_PRACTITIONER_NOTE)) {
      return this.wsHelperService.callWithSpinner(
        this.practititionerService.submitNote(this.data.practitionerId, note).pipe(
          tap({
            next: () => {
              this.dialogRef.close({ data: note });
            },
            error: () => {
              this.dialogRef.close();
            }
          })
        )
      );
    }
  };

  onBackClicked() {
    this.dialogRef.close();
  }
}
