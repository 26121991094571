<stx-patient-forms-headers-container
  *ngIf="!expandable"
  [formType]="formType"
  cssClass="m-b-24 m-t-24"
></stx-patient-forms-headers-container>
<stx-treatment-status-bar *ngIf="!expandable" [treatment]="orthognathicTreatment" (deleteClicked)="delete()"></stx-treatment-status-bar>
<ng-container *ngIf="isReadonlyView">
  <div class="page-section-container">
    <stx-orthognatic-treatment-readonly [treatment]="orthognathicTreatment"></stx-orthognatic-treatment-readonly>
  </div>
</ng-container>
<ng-container *ngIf="!isReadonlyView">
  <form [formGroup]="treatmentFormGroup" *ngIf="patient">
    <stx-form-panel>
      <div class="form-row">
        <stx-date
          [label]="'treatment.operation_date'"
          [controlName]="'orthognOperationDate'"
          [formGroup]="treatmentFormGroup"
          [minDate]="minDate"
          [maxDateToday]="true"
          [formMode]="formMode"
        ></stx-date>
        <stx-select
          [label]="'treatment_center.treatment_center'"
          [controlName]="'treatmentCenterId'"
          [dictionaryList]="tcList"
          [formGroup]="treatmentFormGroup"
          [formMode]="formMode"
        ></stx-select>
        <div class="flex-column-container">
          <stx-file
            [shouldDisplayRemoveIcon]="shouldDisplayRemoveFileIcon"
            [parentOrderName]="ParentOrderName.ORTHOGNATHIC"
            [label]="'treatment.orthognathic_treatment'"
            [acceptedTypes]="acceptedTypes"
            [formControlName]="ParentOrderName.ORTHOGNATHIC"
            [formGroup]="treatmentFormGroup"
            [customErrorMessage]="'error.upload_file' | translate"
            [isRequired]="true"
          ></stx-file>
        </div>
      </div>
    </stx-form-panel>

    <stx-action-buttons *ngIf="!print" [asyncPrimaryAction]="onSubmitButtonClicked()" [showSecondary]="false"></stx-action-buttons>
  </form>
</ng-container>
