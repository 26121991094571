import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'stx-image-rotator',
  templateUrl: './image-rotator.component.html',
  styleUrls: ['./image-rotator.component.scss']
})
export class ImageRotatorComponent {
  @Input() imageNativeElement: HTMLImageElement;
  @Input() imageContainerNativeElement: HTMLDivElement;
  @Input() adjustVerticalMargins: boolean = true;
  @Input() adjustHorizontalMargins: boolean;
  // Set usePng in order to use the rotator icon that does not scale with the page on zoom
  @Input() usePng: boolean;
  @Input() rotateContainer: boolean = true;
  @Input() heightAuto: boolean;
  // Only used when heightAuto
  @Input() maxWidth = 400;
  @Input() defaultWidth: string = '100%';

  @Output() rotated: EventEmitter<any> = new EventEmitter();

  private rotationCounter = 0;
  private initialImageHeight: number = -1;
  private initialImageWidth: number = -1;

  get rotationClass() {
    return `rot-${this.rotationCounter}`;
  }

  rotate() {
    if (!this.imageNativeElement) {
      return;
    }
    this.saveInitialImageDimensions();

    const oldRotationClass = this.rotationClass;
    this.rotationCounter = ++this.rotationCounter % 4;

    let containerMargin = this.calcMargin();
    this.applyVerticalMarginsToContainer(containerMargin);
    this.applyHorizontalMarginsToContainer(containerMargin);

    this.adjustImageWidth(this.maxWidth);

    this.applyRotation(this.rotationClass, oldRotationClass);
    this.rotated.emit({
      containerMargin: containerMargin,
      rotationCounter: this.rotationCounter,
      originalVertical: this.isVertical,
      originalHorizontal: this.isHorizontal
    });
  }

  private adjustImageWidth(maxWidth: number) {
    if (this.heightAuto && this.isVertical) {
      let newWidthValue: string = this.defaultWidth;
      if (this.rotationCounter === 1 || this.rotationCounter === 3) {
        newWidthValue = `${(this.initialImageWidth * maxWidth) / this.initialImageHeight}px`;
      }
      this.imageNativeElement.style.width = newWidthValue;
    }
  }

  private applyRotation(newRotationClass: string, oldRotationClass: string) {
    if (this.rotateContainer) {
      this.imageContainerNativeElement.classList.add(newRotationClass);
      this.imageContainerNativeElement.classList.remove(oldRotationClass);
    } else {
      this.imageNativeElement.classList.add(newRotationClass);
      this.imageNativeElement.classList.remove(oldRotationClass);
    }
  }

  private applyVerticalMarginsToContainer(containerMargin: number) {
    if (this.adjustVerticalMargins && this.isHorizontal && this.imageContainerNativeElement) {
      this.imageContainerNativeElement.style.marginTop = `${containerMargin}px`;
      this.imageContainerNativeElement.style.marginBottom = `${containerMargin}px`;
    }
  }
  private applyHorizontalMarginsToContainer(containerMargin: number) {
    if (this.adjustHorizontalMargins && this.isVertical && this.imageContainerNativeElement) {
      this.imageContainerNativeElement.style.marginLeft = `${containerMargin}px`;
      this.imageContainerNativeElement.style.marginRight = `${containerMargin}px`;
    }
  }

  private calcMargin(): number {
    if (this.rotationCounter === 1 || this.rotationCounter === 3) {
      if (this.isHorizontal) {
        return (this.initialImageWidth - this.initialImageHeight) / 2;
      } else if (this.isVertical) {
        return (this.initialImageHeight - this.initialImageWidth) / 2;
      }
    }
    return 0;
  }

  private saveInitialImageDimensions() {
    if (this.initialImageHeight === -1) {
      this.initialImageHeight = this.imageNativeElement.height;
    }
    if (this.initialImageWidth === -1) {
      this.initialImageWidth = this.imageNativeElement.width;
    }
    if (this.heightAuto && this.isVertical) {
      this.initialImageHeight = this.imageNativeElement.naturalHeight;
      this.initialImageWidth = this.imageNativeElement.naturalWidth;
    }
  }

  private get isVertical(): boolean {
    return this.initialImageHeight > this.initialImageWidth;
  }

  private get isHorizontal(): boolean {
    return this.initialImageHeight < this.initialImageWidth;
  }
}

export interface ImageRotatedEvent {
  containerMargin: number;
  rotationCounter: number;
  originalVertical: boolean;
  originalHorizontal: boolean;
}
