<stx-data-block
  [header]="fileInfo.title | translate"
  [headerSubtitle]="fileInfo.subtitle | translate"
  [expandableBody]="isExpandableBody"
  [expandableHeader]="true"
>
  <ng-container>
    <div class="dashboard-announcement" *stxContentFromHtmlFile="visibleBodyInfo"></div>
  </ng-container>
  <ng-container slot="expandedBody" *ngIf="isExpandableBody">
    <div class="dashboard-announcement" *stxContentFromHtmlFile="hiddenBodyInfo"></div>
  </ng-container>
</stx-data-block>
<div class="m-b-15"></div>
