<stx-generic-wide-page>
  <stx-page-header [name]="'partners' | translate"></stx-page-header> <stx-partner-search></stx-partner-search>
  <div class="data-section">
    <stx-data-table
      [dataProvider]="searchResultsDataProvider"
      [config]="{ topPaginator: true, hideUninitialized: true, stickyColumnClass: 'sticky-first' }"
    >
      <tr mat-row *matRowDef="let row; columns: []"></tr>
    </stx-data-table>
  </div>
</stx-generic-wide-page>
