import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dotProperties from 'dot-properties';

const baseTranslationsUrl = '/assets/i18n/';
const translationSuffix = '.properties';

export class StxTranslateLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    return this.httpClient
      .get(`${baseTranslationsUrl}${lang}${translationSuffix}`, {
        responseType: 'text'
      })
      .pipe(map(propertiesString => dotProperties.parse(propertiesString)));
  }
}
