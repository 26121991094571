import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '@shared/components/form/form.module';
import { PatientMatchingComponent } from '@shared/components/patient-matching/patient-matching.component';
import { MaterialModule } from '../../modules/material/material.module';
import { ReadonlyImageModule } from '../readonly-image/readonly-image.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';

@NgModule({
  declarations: [PatientMatchingComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormModule,
    RouterModule,
    ReadonlyImageModule,
    GeneralCommonsModule
  ],
  exports: [PatientMatchingComponent]
})
export class PatientMatchingModule {}
