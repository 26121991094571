<div [formGroup]="itemForm">
  <mat-form-field appearance="outline" class="full-width form-element-label" *ngIf="isShown">
    <input
      data-testid="input-multi-select"
      type="text"
      [placeholder]="inputPlaceholder | translate"
      formControlName="autocompleteInput"
      matInput
      [matAutocomplete]="auto"
    />
    <mat-icon matSuffix>expand_more</mat-icon>
  </mat-form-field>

  <!-- Duplicated in autocomplete-single-select -->
  <mat-autocomplete #auto="matAutocomplete" (opened)="autocompleteOpened()">
    <cdk-virtual-scroll-viewport
      itemSize="{{ matOptionHeight }}"
      minBufferPx="{{ matOptionHeight * 10 }}"
      maxBufferPx="{{ matOptionHeight * 12 }}"
      class="virtual-scroll"
    >
      <mat-option *cdkVirtualFor="let item of filteredItems; trackBy: trackById" [value]="valuesSelection" (click)="toggleOption(item)">
        <mat-checkbox
          data-testid="checkbox-autocomplete-multi-select"
          (change)="toggleOption(item)"
          [checked]="valuesSelection.isSelected(item)"
          (click)="$event.stopPropagation()"
        >
          {{ item.name }}
        </mat-checkbox>
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </mat-autocomplete>
</div>
