<div>
  <mat-radio-group class="form-element-group-row" [value]="print ? '' : stage">
    <div *ngFor="let option of stageOptions">
      <mat-radio-button
        data-testid="radio-stage-indicator"
        [ngClass]="{ print: print }"
        class="indicator-value"
        [value]="option.value"
        [disabled]="true"
      >
        {{ option.label | translate }}
      </mat-radio-button>
    </div>
  </mat-radio-group>
</div>
