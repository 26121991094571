export enum ParentOrderName {
  OPERATION_PRE_FRONTAL = 'OPERATION_PRE_FRONTAL',
  OPERATION_POST_FRONTAL = 'OPERATION_POST_FRONTAL',
  OPERATION_PRE_INTRA_ORAL = 'OPERATION_PRE_INTRA_ORAL',
  OPERATION_POST_INTRA_ORAL = 'OPERATION_POST_INTRA_ORAL',
  OPERATION_PRE_NASAL_BASE = 'OPERATION_PRE_NASAL_BASE',
  OPERATION_POST_NASAL_BASE = 'OPERATION_POST_NASAL_BASE',
  OPERATION_PRE_CLEFT_SIDE = 'OPERATION_PRE_CLEFT_SIDE',
  OPERATION_POST_CLEFT_SIDE = 'OPERATION_POST_CLEFT_SIDE',
  OPERATION_FRONTAL = 'OPERATION_FRONTAL',
  OPERATION_FOLLOW_UP = 'OPERATION_FOLLOW_UP',
  RELEASE_FORM = 'RELEASE_FORM',
  ELECTRONICALLY_SIGNED_RELEASE_FORM = 'ELECTRONICALLY_SIGNED_RELEASE_FORM',
  FOLLOW_UP_FRONT = 'FOLLOW_UP_FRONT',
  FOLLOW_UP_FRONT_LIP = 'FOLLOW_UP_FRONT_LIP',
  FOLLOW_UP_PALATE = 'FOLLOW_UP_PALATE',
  FOLLOW_UP_OTHER_1 = 'FOLLOW_UP_OTHER_1',
  FOLLOW_UP_OTHER_2 = 'FOLLOW_UP_OTHER_2',
  ORTHOGNATHIC = 'ORTHOGNATHIC',
  SPEECH_ASSESSMENT_SAMPLE_SYLLABLE_REPETITION = 'SPEECH_ASSESSMENT_SAMPLE_SYLLABLE_REPETITION',
  SPEECH_ASSESSMENT_SAMPLE_SENTENCE_REPETITION = 'SPEECH_ASSESSMENT_SAMPLE_SENTENCE_REPETITION',
  SPEECH_ASSESSMENT_INTRAORAL_ASSESSMENT_PICTURE = 'SPEECH_ASSESSMENT_INTRAORAL_ASSESSMENT_PICTURE',
  SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_VIDEO = 'SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_VIDEO',
  SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_REST = 'SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_REST',
  SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_PHONATION = 'SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_PHONATION',
  SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_VIDEO = 'SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_VIDEO',
  SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_REST = 'SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_REST',
  SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_PHONATION = 'SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_PHONATION',
  BORESCOPE_IDENTIFICATION = 'BORESCOPE_IDENTIFICATION',
  BORESCOPE_UNREPAIRED = 'BORESCOPE_UNREPAIRED',
  BORESCOPE_REPAIRED = 'BORESCOPE_REPAIRED',
  BORESCOPE_VIDEOS = 'BORESCOPE_VIDEOS',
  BORESCOPE_NASAL_LINING = 'BORESCOPE_NASAL_LINING',
  BORESCOPE_ORAL_LINING = 'BORESCOPE_ORAL_LINING',
  ORTHO_FRONTAL_PRE = 'ORTHO_FRONTAL_PRE',
  ORTHO_LEFT_PRE = 'ORTHO_LEFT_PRE',
  ORTHO_RIGHT_PRE = 'ORTHO_RIGHT_PRE',
  ORTHO_BASAL_PRE = 'ORTHO_BASAL_PRE',
  ORTHO_MAXILLARY_PRE = 'ORTHO_MAXILLARY_PRE',
  ORTHO_APPLIANCE_PRE = 'ORTHO_APPLIANCE_PRE',
  ORTHO_APPLIANCE_BABY_PRE = 'ORTHO_APPLIANCE_BABY_PRE',
  ORTHO_FRONTAL_POST = 'ORTHO_FRONTAL_POST',
  ORTHO_LEFT_POST = 'ORTHO_LEFT_POST',
  ORTHO_RIGHT_POST = 'ORTHO_RIGHT_POST',
  ORTHO_BASAL_POST = 'ORTHO_BASAL_POST',
  ORTHO_MAXILLARY_POST = 'ORTHO_MAXILLARY_POST',
  ORTHO_FRONTAL_SMILE_POST = 'ORTHO_FRONTAL_SMILE_POST',
  ORTHO_FRONTAL_SMILE_PRE = 'ORTHO_FRONTAL_SMILE_PRE',
  ORTHO_INTRA_ORAL_FRONTAL_PRE = 'ORTHO_INTRA_ORAL_FRONTAL_PRE',
  ORTHO_LEFT_OCCLUSION_PRE = 'ORTHO_LEFT_OCCLUSION_PRE',
  ORTHO_RIGHT_OCCLUSION_PRE = 'ORTHO_RIGHT_OCCLUSION_PRE',
  ORTHO_MAXILLARY_ARCH_PRE = 'ORTHO_MAXILLARY_ARCH_PRE',
  ORTHO_MANDIBULAR_ARCH_PRE = 'ORTHO_MANDIBULAR_ARCH_PRE',
  ORTHO_CEPHALOMETRIC_RADIOGRAPH_PRE = 'ORTHO_CEPHALOMETRIC_RADIOGRAPH_PRE',
  ORTHO_PANORAMIC_RADIOGRAPH_PRE = 'ORTHO_PANORAMIC_RADIOGRAPH_PRE',
  ORTHO_CLEFT_SITE_OCCLUSAL_OR_PERIAPICAL_RADIOGRAPH_PRE = 'ORTHO_CLEFT_SITE_OCCLUSAL_OR_PERIAPICAL_RADIOGRAPH_PRE',
  ORTHO_INTRA_ORAL_FRONTAL_POST = 'ORTHO_INTRA_ORAL_FRONTAL_POST',
  ORTHO_LEFT_OCCLUSION_POST = 'ORTHO_LEFT_OCCLUSION_POST',
  ORTHO_RIGHT_OCCLUSION_POST = 'ORTHO_RIGHT_OCCLUSION_POST',
  ORTHO_MAXILLARY_ARCH_POST = 'ORTHO_MAXILLARY_ARCH_POST',
  ORTHO_MANDIBULAR_ARCH_POST = 'ORTHO_MANDIBULAR_ARCH_POST',
  ORTHO_CEPHALOMETRIC_RADIOGRAPH_POST = 'ORTHO_CEPHALOMETRIC_RADIOGRAPH_POST',
  ORTHO_PANORAMIC_RADIOGRAPH_POST = 'ORTHO_PANORAMIC_RADIOGRAPH_POST',
  ORTHO_CLEFT_SITE_OCCLUSAL_OR_PERIAPICAL_RADIOGRAPH_POST = 'ORTHO_CLEFT_SITE_OCCLUSAL_OR_PERIAPICAL_RADIOGRAPH_POST',
  ORTHO_IN_PROGRESS_1 = 'ORTHO_IN_PROGRESS_1',
  ORTHO_IN_PROGRESS_2 = 'ORTHO_IN_PROGRESS_2',
  ORTHO_IN_PROGRESS_3 = 'ORTHO_IN_PROGRESS_3',
  ORTHO_IN_PROGRESS_4 = 'ORTHO_IN_PROGRESS_4',
  ORTHO_IN_PROGRESS_5 = 'ORTHO_IN_PROGRESS_5',
  ORTHO_IN_PROGRESS_6 = 'ORTHO_IN_PROGRESS_6',
  ORTHO_IN_PROGRESS_7 = 'ORTHO_IN_PROGRESS_7',
  ORTHO_IN_PROGRESS_8 = 'ORTHO_IN_PROGRESS_8',
  ORTHO_IN_PROGRESS_9 = 'ORTHO_IN_PROGRESS_9',
  SPEECH_TREATMENT_SAMPLE_SYLLABLE_REPETITION = 'SPEECH_TREATMENT_SAMPLE_SYLLABLE_REPETITION',
  SPEECH_TREATMENT_SAMPLE_SENTENCE_REPETITION = 'SPEECH_TREATMENT_SAMPLE_SENTENCE_REPETITION',
  PATIENT_PHOTO = 'PATIENT_PHOTO',
  INDIA_DECLARATION_FORM = 'INDIA_DECLARATION_FORM',
  NUTRITION_MANAGEMENT = 'NUTRITION_MANAGEMENT',
  NUTRITION_SCREENING_HISTORICAL = 'NUTRITION_SCREENING_HISTORICAL',
  NUTRITION_FOLLOW_UP_HISTORICAL = 'NUTRITION_FOLLOW_UP_HISTORICAL',
  ANESTHESIA_PRE_OPERATIVE = 'ANESTHESIA_PRE_OPERATIVE',
  ANESTHESIA_INTRA_OPERATIVE = 'ANESTHESIA_INTRA_OPERATIVE',
  ANESTHESIA_POST_OPERATIVE = 'ANESTHESIA_POST_OPERATIVE'
}
