<stx-form-panel>
  <p class="form-section-heading">
    {{ 'ortho.general_patient_information' | translate }}
  </p>
  <stx-ortho-shared-general-info
    #sharedGeneralInfo
    [tcList]="tcList"
    [orthoFormType]="orthoFormType"
    [orthoStage]="OrthoStage.MID_TREATMENT_ASSESSMENT"
    [minCareProvidedDate]="minEvaluationDate"
    [maxCareProvidedDate]="maxEvaluationDate"
    [print]="print"
    [formMode]="formMode"
  ></stx-ortho-shared-general-info>
  <hr class="form-subsection-separator" />
  <div class="form-row">
    <stx-radio-group
      [label]="'presurgical.complication'"
      [layoutDirection]="'row'"
      [controlName]="'complication'"
      [formGroup]="formGroup"
      [formMode]="formMode"
      [options]="yesNo"
      [print]="print"
    ></stx-radio-group>
    <stx-textarea [label]="'if_yes_explain'" [controlName]="'complicationMore'" [formGroup]="formGroup" [formMode]="formMode">
    </stx-textarea>
  </div>
</stx-form-panel>
