<div [ngClass]="{ 'p-t-30 page-break-inside-avoid': print }">
  <ng-container *ngIf="nutritionFormType === NutritionType.INFANT">
    <div>
      <p class="form-subsection-heading">
        {{ 'nutrition_management.birthweight' | translate | sectionNumber : nutritionFormType : NutritionSection.BIRTHWEIGHT }}
      </p>
    </div>

    <div [ngClass]="{ 'page-break-after-avoid': print }" class="form-row" *ngIf="nutritionBirthweightOptions">
      <stx-radio-group
        [layoutDirection]="'column'"
        [controlName]="'birthWeightStatus'"
        [formGroup]="formGroup"
        [formMode]="formMode"
        [options]="nutritionBirthweightOptions"
        [print]="print"
      ></stx-radio-group>
    </div>
  </ng-container>
</div>
