<stx-generic-page>
  <stx-details-page *ngIf="practitioner" [name]="practitioner.name" pageTitleKey="practitioner.practitioner">
    <div class="layout-columns-1-2">
      <div class="data-tile-container">
        <div class="data-tile-row">
          <div class="data-tile">
            <div class="data-tile-content">
              <stx-simple-details-value [value]="practitioner.id"></stx-simple-details-value>
              <stx-simple-details-value [email]="practitioner.email"></stx-simple-details-value>
            </div>
          </div>
        </div>
        <hr class="data-tile-row-separator" />
        <ng-container *ngFor="let spec of specializations; let first = first">
          <hr *ngIf="!first" class="data-tile-row-separator" />
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-content">
                <stx-simple-details-value [value]="spec.specialization | translate | uppercase"></stx-simple-details-value>
                <stx-simple-details-value labelKey="practitioner.num_of_ops" [value]="spec.surgeryNumber"></stx-simple-details-value>
                <stx-simple-details-value labelKey="practitioner.activity_range" [value]="spec.activityRange"></stx-simple-details-value>
                <ng-container *ngIf="first && isSurgeon">
                  <a *stxPermitted="permissionEnum.GET_PRACTITIONER_QA_REVIEW_DETAILS" [routerLink]="[allQaResults]">
                    {{ 'practitioner.all_qa_results' | translate }}
                  </a>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div>
        <div class="data-section" *stxPermitted="permissionEnum.GET_PRACTITIONER_TREATMENT_CENTERS">
          <stx-table
            (sortingPagination)="changeTreatmentCenterPageSort($event)"
            *ngIf="treatmentCentersTableData"
            [data]="treatmentCentersTableData"
            [topPagination]="false"
          ></stx-table>
        </div>
        <div class="data-section">
          <div class="data-section-heading" *stxPermitted="permissionEnum.ADD_PRACTITIONER_NOTE">
            <button data-testid="button-practitioner-details-new-note" mat-button color="primary" (click)="onNewNoteButtonClicked()">
              <mat-icon>add_circle_outline</mat-icon>
              {{ 'practitioner.new_note' | translate }}
            </button>
          </div>
          <div class="data-tile-container" *stxPermitted="permissionEnum.READ_PRACTITIONER_NOTE">
            <ng-container *ngFor="let note of notes; let first = first">
              <hr *ngIf="!first" class="data-section-separator" />
              <div class="data-tile-row">
                <div class="data-tile">
                  <div class="data-tile-content">
                    <p>{{ note.createdBy }} <stx-value-separator></stx-value-separator> {{ note.createdAt | date : dateFormat }}</p>
                    <p>{{ note.note }}</p>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="data-tile-row" *ngIf="notes.length === 0">
              <div class="data-tile">
                <div class="data-tile-content">
                  <stx-empty-table-state></stx-empty-table-state>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </stx-details-page>
</stx-generic-page>
