import { Component } from '@angular/core';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { dictionaryFromMapProvider } from '@shared/services/dictionary-provider';
import { BaseReadonlyViewComponent } from '@shared/modules/readonly-commons/utils/base-readonly-view.component';
import { MediaSet } from '@shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';
import { NutritionScreening } from '@src/app/features/nutrition/models/nutrition-screening.model';

//This is a legacy code we should not use it in future

@Component({
  selector: 'stx-nutrition-screening-readonly',
  templateUrl: './nutrition-screening-readonly.component.html',
  providers: [dictionaryFromMapProvider(undefined, s => null)]
})
export class NutritionScreeningReadonlyComponent extends BaseReadonlyViewComponent<NutritionScreening> {
  photos: MediaSet[] = [];

  protected afterTreatmentSet(): void {
    this.photos = [
      {
        mediaWithLabels: [this.findMediaWithLabels(ParentOrderName.NUTRITION_SCREENING_HISTORICAL, ['nutrition_screening.frontal_photo'])]
      }
    ];
  }
}
