<stx-patient-forms-headers-container
  *ngIf="!expandable"
  [formType]="formType"
  cssClass="m-l-24 m-b-24"
></stx-patient-forms-headers-container>
<stx-treatment-status-bar
  *ngIf="!expandable"
  [treatment]="speechAssessment"
  (unlockClicked)="unlock()"
  (deleteClicked)="delete()"
></stx-treatment-status-bar>

<ng-container *ngIf="isReadonlyView">
  <div class="page-section-container">
    <stx-speech-assessment-readonly [treatment]="speechAssessment"></stx-speech-assessment-readonly>
  </div>
</ng-container>

<form *ngIf="!isReadonlyView && staticDictionaries && tcList && patient" class="m-t-24" [formGroup]="treatmentFormGroup">
  <stx-form-panel>
    <stx-speech-basic-info
      [isTreatment]="false"
      [tcList]="tcList"
      [formGroup]="treatmentFormGroup"
      [dateOfBirth]="patient.dateOfBirth"
      [formMode]="formMode"
      [print]="print"
      [expandable]="expandable"
    ></stx-speech-basic-info>

    <div class="form-row">
      <stx-checkbox
        [name]="'treatment.telehealth_assessment'"
        [controlName]="'telehealth'"
        [formGroup]="treatmentFormGroup"
        [formMode]="formMode"
      ></stx-checkbox>
    </div>

    <hr class="form-section-separator" />

    <div class="form-row" [ngClass]="{ 'p-t-30': print }">
      <div>
        <p class="form-subsection-heading">
          {{ 'speech.current_stage' | translate }}
        </p>
        <stx-radio-group
          [layoutDirection]="'column'"
          [controlName]="'assessmentStage'"
          [formGroup]="treatmentFormGroup"
          [formMode]="formMode"
          [options]="getAvailableAssessmentStages()"
        ></stx-radio-group>
      </div>
    </div>

    <hr class="form-section-separator hide-on-print" />

    <stx-speech-assessment-perceptual
      [formGroup]="treatmentFormGroup"
      [formMode]="formMode"
      [staticDictionaries]="staticDictionaries"
      [print]="print"
    ></stx-speech-assessment-perceptual>
    <div class="page-break"></div>

    <hr class="form-section-separator" />

    <stx-speech-sample-video-recording
      class="display-block"
      [formType]="formType"
      [formMode]="formMode"
      [formGroup]="treatmentFormGroup"
      [print]="print"
    ></stx-speech-sample-video-recording>

    <hr class="form-section-separator hide-on-print" />

    <stx-intraoral-assessment
      [formGroup]="treatmentFormGroup"
      [formMode]="formMode"
      [staticDictionaries]="staticDictionaries"
      [print]="print"
    ></stx-intraoral-assessment>
  </stx-form-panel>

  <stx-instrumental-assessment
    [formGroup]="treatmentFormGroup"
    [formMode]="formMode"
    [print]="print"
    [birthDate]="patient.dateOfBirth"
  ></stx-instrumental-assessment>

  <stx-speech-assessment-recommendations
    [formMode]="formMode"
    [print]="print"
    [formGroup]="treatmentFormGroup"
    [formGroupDataUpdate$]="formGroupDataReadyEvent$"
  ></stx-speech-assessment-recommendations>

  <stx-action-buttons
    *ngIf="!print"
    [asyncSecondaryAction]="onSaveButtonClicked()"
    [asyncPrimaryAction]="onSubmitButtonClicked()"
  ></stx-action-buttons>
</form>
