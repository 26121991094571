<h4>{{ 'user.personal_data' | translate }}</h4>
<form [formGroup]="personalDataForm" class="page-section flex-column-container">
  <div class="form-row">
    <stx-input [formMode]="editFormMode" [formGroup]="personalDataForm" controlName="lastName" type="text" label="last_name"></stx-input>
    <stx-input [formMode]="editFormMode" [formGroup]="personalDataForm" controlName="firstName" type="text" label="first_name"></stx-input>
    <stx-input
      [formMode]="editFormMode"
      [formGroup]="personalDataForm"
      controlName="middleName"
      type="text"
      label="patient.middle_name"
    ></stx-input>
  </div>
  <div class="form-row">
    <stx-input [formMode]="editFormMode" [formGroup]="personalDataForm" controlName="email" type="text" label="email"></stx-input>
    <stx-select
      [formMode]="editFormMode"
      [formGroup]="personalDataForm"
      controlName="lang"
      label="user.language_preferences"
      [dictionaryList]="languagesList"
    ></stx-select>
    <stx-select
      [formMode]="editFormMode"
      [formGroup]="personalDataForm"
      controlName="enabled"
      label="user.state"
      [dictionaryList]="activeInactiveList"
    ></stx-select>
  </div>
</form>
