export type StaticFileNames = 'partner_survey' | 'partner_tcs_survey' | 'cleft_publications_education_research';

export type ExpandableStaticFileNames =
  | 'medical_forms'
  | 'safety_and_quality_protocols_2023'
  | 'speech_updates'
  | 'anniversary'
  | 'automatic_rejection_of_palate_cases'
  | 'smile_train_publication_access'
  | 'medical_advisory_on_outreach_surgeries';

export type StaticFileInfo = { fileDir: string; translated: boolean };

export const staticFilesPath = 'assets/static-html-files';
export type StaticFileRegistry = Record<StaticFileNames, StaticFileInfo>;

export const staticFilesRegistry: StaticFileRegistry = {
  partner_survey: { fileDir: 'partner-survey', translated: true },
  partner_tcs_survey: { fileDir: 'partner-tcs-survey', translated: true },
  cleft_publications_education_research: { fileDir: 'cleft-publications-education-research', translated: false }
};

type ExpandableStaticFileRegistry = Record<ExpandableStaticFileNames, ExpandableFileInfo>;

export const expandableStaticFileRegistry: ExpandableStaticFileRegistry = {
  medical_forms: {
    translated: true,
    title: 'dashboard.medical_forms.header',
    visibleDir: 'medical-forms-info/visible'
  },
  safety_and_quality_protocols_2023: {
    translated: false,
    title: 'dashboard.saq_protocols_2023.header',
    visibleDir: 'safety-and-quality-protocols/visible-2023'
  },
  speech_updates: {
    translated: true,
    title: 'dashboard.speech_updates.header',
    visibleDir: 'speech-updates/visible'
  },
  anniversary: {
    translated: true,
    title: 'dashboard.25th_anniversary.header',
    visibleDir: '25th-anniversary/visible'
  },
  automatic_rejection_of_palate_cases: {
    translated: true,
    title: 'dashboard.automatic_rejection_of_palate_cases.header',
    visibleDir: 'automatic-rejection-of-palate-cases/visible'
  },
  smile_train_publication_access: {
    translated: false,
    title: 'dashboard.smile_train_publication_access',
    visibleDir: 'smile-train-publication-access/visible'
  },
  medical_advisory_on_outreach_surgeries: {
    translated: true,
    title: 'dashboard.automatic_rejection_of_palate_cases.header',
    visibleDir: 'medical-advisory-on-outreach-surgeries/visible'
  }
};

export type ExpandableFileInfo = {
  title: string;
  subtitle?: string;
  hiddenDir?: string;
  visibleDir: string;
  translated: boolean;
};
