<mat-expansion-panel
  class="flex-column-container mat-exp-header m-t-40"
  [expanded]="true"
  hideToggle
  (expandedChange)="showArrowUp = $event"
>
  <mat-expansion-panel-header class="full-width" collapsedHeight="auto" expandedHeight="auto">
    <div
      class="expandable-form-container custom-flex-100 {{ print ? 'flex-row-container layout-align-center' : 'flex-column-container' }} "
    >
      <div class="header-wrapper">
        <div *ngIf="showStatus" class="treatment-status-indicator-wrapper">
          <stx-treatment-status-indicator [showLabel]="false" [treatmentStatus]="status" [print]="print"></stx-treatment-status-indicator>
        </div>
        <mat-panel-title class="not-print flex-nogrow">
          <stx-patient-forms-headers-container [formType]="formType">
            <mat-icon *ngIf="!print">{{ showArrowUp ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
          </stx-patient-forms-headers-container>
        </mat-panel-title>
      </div>
      <ng-content select="stx-patient-treatment-minimized"></ng-content>
    </div>
  </mat-expansion-panel-header>
  <ng-content> </ng-content>
</mat-expansion-panel>
