import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FormModule } from '@shared/components/form/form.module';
import { CheckboxOption, ReportFilterChange, ReportFiltersParams } from '../../models/report-filters.model';
import { ReportFiltersHelperService } from '../../services/report-filters-helper.service';
import { ReportDictionariesService } from '../../services/report-dictionaries.service';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'stx-gender-filter',
  templateUrl: './gender-filter.component.html'
})
export class GenderFilterComponent extends BaseComponent implements OnInit {
  @Input() formGroupReference: UntypedFormGroup;
  @Output() filterChange = new EventEmitter<ReportFilterChange>();
  genderOptions: CheckboxOption[];
  genderInitialParams: string[] = [];

  constructor(
    private reportDictionariesService: ReportDictionariesService,
    private reportFiltersHelperService: ReportFiltersHelperService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.genderOptions = this.getCheckboxGroupOptions();
    this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(this.formGroupReference, this.genderOptions);
    this.watchFilterChanges();
    this.watchQueryParamsChange();
  }

  private getCheckboxGroupOptions(): CheckboxOption[] {
    return this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(this.reportDictionariesService.dictionaries.genders);
  }

  private emitFilterModel(change: Record<string, boolean>): void {
    const selectedGenders = this.reportFiltersHelperService.getSelectedCheckboxOptions(change);

    this.filterChange.emit({
      filterName: 'genders',
      payload: {
        genders: selectedGenders
      }
    });
  }

  private watchFilterChanges() {
    if (this.formGroupReference) {
      this.subSink.sink = this.formGroupReference.valueChanges.subscribe(this.emitFilterModel.bind(this));
    }
  }

  private watchQueryParamsChange() {
    this.subSink.sink = this.route.queryParams
      .pipe(filter((params: ReportFiltersParams) => !!params.genders?.length))
      .subscribe((params: ReportFiltersParams) => {
        this.reportFiltersHelperService.setCheckboxFormGroupValues(this.formGroupReference, params.genders);
      });
  }
}

@NgModule({
  declarations: [GenderFilterComponent],
  imports: [FormModule],
  exports: [GenderFilterComponent]
})
export class GenderPickerModule {}
