import { IfPipe } from '@/src/app/shared/modules/general-commons/pipes/if/if.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { PatientRecordIconComponent } from '@shared/components/patient-record-icon/patient-record-icon.component';
import { Base64ImgComponent } from '@shared/modules/general-commons/components/base64-img/base64-img.component';
import { DataTableGenericColumnDefinitionComponent } from '@shared/modules/general-commons/components/data-table/data-table-generic-column-definition.component';
import { DataTableComponent } from '@shared/modules/general-commons/components/data-table/data-table.component';
import { FileIconComponent } from '@shared/modules/general-commons/components/file-icon/file-icon.component';
import { GenericWidePageComponent } from '@shared/modules/general-commons/components/generic-page-wide/generic-wide-page.component';
import { GenericPageComponent } from '@shared/modules/general-commons/components/generic-page/generic-page.component';
import { PageHeaderComponent } from '@shared/modules/general-commons/components/page-header/page-header.component';
import { RemoveIconComponent } from '@shared/modules/general-commons/components/remove-icon/remove-icon.component';
import { StaticFileLinkComponent } from '@shared/modules/general-commons/components/static-file-link/static-file-link.component';
import { TooltipComponent } from '@shared/modules/general-commons/components/tooltip/tooltip.component';
import { ValueSeparatorComponent } from '@shared/modules/general-commons/components/value-separator/value-separator.component';
import { UpDownChevronComponent } from './components/up-down-chevron/up-down-chevron.component';
import { FocusElementDirective } from './directives/focus-element.directive';
import { DateCustomPipe } from './pipes/date-custom.pipe';

@NgModule({
  declarations: [
    Base64ImgComponent,
    DataTableComponent,
    DataTableGenericColumnDefinitionComponent,
    FileIconComponent,
    GenericPageComponent,
    GenericWidePageComponent,
    PageHeaderComponent,
    RemoveIconComponent,
    StaticFileLinkComponent,
    TooltipComponent,
    ValueSeparatorComponent,
    PatientRecordIconComponent,
    UpDownChevronComponent,
    IfPipe,
    FocusElementDirective,
    DateCustomPipe
  ],
  imports: [
    CommonModule,
    EmptyTableStateComponentModule,
    FlexLayoutModule,
    FlexModule,
    FontAwesomeModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    RouterModule,
    TranslateModule.forChild(),
    MatIconModule
  ],
  exports: [
    Base64ImgComponent,
    DataTableComponent,
    DataTableGenericColumnDefinitionComponent,
    FileIconComponent,
    GenericPageComponent,
    GenericWidePageComponent,
    MatSortModule,
    PageHeaderComponent,
    RemoveIconComponent,
    StaticFileLinkComponent,
    TooltipComponent,
    ValueSeparatorComponent,
    PatientRecordIconComponent,
    UpDownChevronComponent,
    IfPipe,
    FocusElementDirective,
    DateCustomPipe
  ]
})
export class GeneralCommonsModule {}
