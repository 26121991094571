<div [ngClass]="{ 'p-t-30 page-break-inside-avoid': print }" class="form-columns-1-1">
  <div [formGroup]="formGroup">
    <div>
      <p class="form-section-heading">
        {{
          'nutrition_management.anemia_management_plan'
            | translate
            | sectionNumber : nutritionFormType : NutritionSection.ANEMIA_MANAGEMENT_PLAN
        }}
      </p>
    </div>
    <div class="form-element-secondary-label fs-14">
      <p *ngIf="this.nutritionFormType === 'INFANT'">
        {{ 'nutrition_management.anemia_management.infant.subtitle' | translate }}
        <br />
        {{ 'nutrition_management.anemia_management.infant.subtitle_2' | translate }}
      </p>
      <p *ngIf="this.nutritionFormType !== 'INFANT'">
        {{ 'nutrition_management.anemia_management.subtitle' | translate }}
      </p>
    </div>
    <div class="form-row">
      <stx-checkbox-group-with-text-field
        [layoutDirection]="'column'"
        [options]="anemiaManagementOptions"
        [formMode]="formMode"
        [print]="print"
        [formGroup]="formGroup"
        [formGroupErrorNames]="['anemiaManagementFields']"
        [deselectTriggerControlName]="'none'"
        [textFieldParentControlName]="'other'"
        [textFieldControlName]="'otherMore'"
      ></stx-checkbox-group-with-text-field>
    </div>
  </div>
</div>
