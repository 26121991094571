<ng-container *ngIf="records.length">
  <mat-paginator
    (page)="changePage($event)"
    [length]="recordsPage.data.page.totalElements"
    [pageIndex]="pagination.pageNumber"
    [pageSize]="pagination.pageSize"
    [pageSizeOptions]="pageSizes"
  ></mat-paginator>
  <div class="table-container-common">
    <table
      [dataSource]="records"
      class="mat-elevation-z2"
      mat-table
      matSort
      (matSortChange)="sort($event)"
      aria-describedby="table-container-common"
    >
      <ng-container [matColumnDef]="'patient'">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ headers.patient | translate }}
        </th>
        <td mat-cell *matCellDef="let record">
          <stx-patient-link [patientId]="record.patientId">
            <span class="block full-width fs-14">
              {{ record.firstName }} {{ record.firstNameLoc }} {{ record.middleName }} {{ record.middleNameLoc }}<br />
              {{ record.lastName }} {{ record.lastNameLoc }} {{ record.patientId }}
            </span>
          </stx-patient-link>
        </td>
      </ng-container>

      <ng-container *ngFor="let columnName of columnsIterable; index as index; first as isFirst" [matColumnDef]="columnName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ headers[columnName] }}</th>
        <td mat-cell *matCellDef="let record" class="align-middle">
          <span class="display-block full-width" [ngClass]="isFirst ? '' : 'text-right'">
            {{ record[columnName] }}
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsAll"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsAll" class="table-row"></tr>
    </table>
  </div>
  <mat-paginator
    (page)="changePage($event)"
    [length]="recordsPage.data.page.totalElements"
    [pageIndex]="pagination.pageNumber"
    [pageSize]="pagination.pageSize"
    [pageSizeOptions]="pageSizes"
  ></mat-paginator>
</ng-container>

<stx-empty-table-state *ngIf="!records.length"></stx-empty-table-state>
