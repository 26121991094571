import { TableStructure } from '@/src/app/shared/components/table/models/table-structure.model';
import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { forkJoin } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { QaReviewerReliabilityRecord, QaReviewerReliabilitySummaryRecord } from '../../components/report-filters/models/report-data.model';
import { ReportConfig } from '../../components/report-filters/models/report-filters.model';
import { ReportFiltersModule } from '../../components/report-filters/report-filters.component';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import { QaReviewerReliabilityRecordsModule } from './components/qa-reviewer-reliability-records/qa-reviewer-reliability-records.component';
import { QaReviewerReliabilityReportSummaryComponentModule } from './components/qa-reviewer-reliability-report-summary/qa-reviewer-reliability-report-summary.component';
import { QaReviewerReliabilityService } from './services/qa-reviewer-reliability/qa-reviewer-reliability.service';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-qa-reviewer-reliability-report',
  templateUrl: './qa-reviewer-reliability-report.component.html'
})
export class QaReviewerReliabilityReportComponent extends BaseComponent implements OnInit {
  pagination: PaginationSortingModel = {
    pageNumber: 0,
    pageSize: 25,
    sortDirection: '',
    sortedBy: ''
  };

  recordsPage: TableStructure<QaReviewerReliabilityRecord>;

  reportConfig: ReportConfig = {
    type: 'QA_REVIEWER_RELIABILITY',
    downloadOptions: {
      excel: true
    },
    filters: {
      date: true,
      qaStages: true
    }
  };
  summaryPage: TableStructure<QaReviewerReliabilitySummaryRecord>;
  tablesReady = false;

  constructor(
    public reportService: ReportService,
    private changeDetectorRef: ChangeDetectorRef,
    private qaReviewerReliabilityService: QaReviewerReliabilityService,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.watchReportData();
    this.reportService.showFilters();
  }

  applyFilters() {
    this.pagination.pageNumber = 0;

    this.wsHelperService
      .callWithSpinner(
        forkJoin([
          this.qaReviewerReliabilityService.fetchRecords(this.pagination),
          this.qaReviewerReliabilityService.fetchSummary(this.pagination)
        ])
      )
      .pipe(
        first(),
        finalize(() => {
          this.onResponseComplete();
        })
      )
      .subscribe();
  }

  changePage(pagination: PaginationSortingModel) {
    this.pagination = pagination;
    this.wsHelperService
      .callWithSpinner(this.qaReviewerReliabilityService.fetchRecords(this.pagination))
      .pipe(
        first(),
        finalize(() => {
          this.onResponseComplete();
        })
      )
      .subscribe();
  }

  private onResponseComplete() {
    this.tablesReady = true;
    this.reportService.hideFilters();
    this.changeDetectorRef.detectChanges();
  }

  private watchReportData() {
    this.subSink.sink = this.qaReviewerReliabilityService.records$.subscribe(page => {
      this.recordsPage = page;
    });
    this.subSink.sink = this.qaReviewerReliabilityService.summary$.subscribe(page => {
      this.summaryPage = page;
    });
  }
}

@NgModule({
  declarations: [QaReviewerReliabilityReportComponent],
  imports: [
    CommonModule,
    QaReviewerReliabilityRecordsModule,
    QaReviewerReliabilityReportSummaryComponentModule,
    ReportFiltersModule,
    ReportHeaderComponentModule,
    TranslateModule,
    GeneralCommonsModule
  ],
  exports: [QaReviewerReliabilityReportComponent]
})
export class QaReviewerReliabilityReportComponentModule {}
