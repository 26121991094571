import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ReportDownloadTypes,
  ReportFilterChangeValue,
  ReportFilterName,
  ReportFiltersModel,
  ReportType
} from '../models/report-filters.model';
import { ArrayUtils } from '@utils/array.utils';
import { BaseHttpService } from '@shared/services/http/base-http.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TableStructure } from '@shared/components/table/models/table-structure.model';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { HttpUtils } from '@utils/http.utils';
import { FileUtils, Mime } from '@utils/file.utils';
import { shareReplay, tap } from 'rxjs/operators';
import { ReportApiUrlService } from './report-api-url.service';
import { saveAs } from 'file-saver';
import { WsHelperService } from '@shared/services/ws-helper.service';

export interface DownloadZipOptions {
  endpoint: string;
  filename: string;
  mime: Mime;
  filtersModel?: ReportFiltersModel;
}

@Injectable({
  providedIn: 'root'
})
export class ReportRequestService extends BaseHttpService {
  constructor(httpClient: HttpClient, private reportApiUrlService: ReportApiUrlService, private wsHelperService: WsHelperService) {
    super(httpClient, { baseUrl: 'reports' });
  }

  getReportsListData(
    reportType: ReportType,
    filtersModel?: ReportFiltersModel,
    paginationSortingModel?: PaginationSortingModel
  ): Observable<TableStructure> {
    const apiUrl = this.reportApiUrlService.getListApiUrl(this.baseUrl, reportType);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params: HttpParams;
    let filterQueryParams: HttpParams;

    params = HttpUtils.pageSortToParams(paginationSortingModel);

    if (filtersModel) {
      filterQueryParams = this.getRequestParams(filtersModel);

      filterQueryParams.keys().forEach(paramName => {
        params = params.set(paramName, filterQueryParams.get(paramName));
      });
    }

    return this.httpClient.get<TableStructure>(apiUrl, { params, headers });
  }

  getRequestParams(filtersModel: ReportFiltersModel): HttpParams {
    const filtersList = Array.from(filtersModel.values()).map(filterModel => Object.entries(filterModel));
    let requestParams: HttpParams = new HttpParams();

    ArrayUtils.flat<Array<[ReportFilterName, ReportFilterChangeValue]>>(filtersList)
      .filter(([, parameterValues]) => !!parameterValues.length)
      .forEach(([parameterName, parameterValues]) => {
        const name = encodeURIComponent(parameterName);
        const value = parameterValues.map(parameterValue => encodeURIComponent(parameterValue)).join(',');
        const isNullParamValue = value === encodeURIComponent(null);

        requestParams = isNullParamValue ? requestParams.delete(name) : requestParams.set(name, value);
      });

    return requestParams;
  }

  downloadCsvReport(filtersModel: ReportFiltersModel, reportType: ReportType, downloadOptionType: ReportDownloadTypes): Observable<Blob> {
    let params: HttpParams = new HttpParams();
    const filterQueryParams: HttpParams = this.getRequestParams(filtersModel);
    const headers = new HttpHeaders().set('Accept', Mime.CSV);
    const apiUrl = this.reportApiUrlService.getDownloadApiUrl(this.baseUrl, reportType, downloadOptionType);

    filterQueryParams.keys().forEach(paramName => {
      params = params.set(paramName, filterQueryParams.get(paramName));
    });

    const downloadReport$ = this.httpClient.get(apiUrl, { responseType: 'blob', headers, params }).pipe(shareReplay());

    this.wsHelperService.call(downloadReport$).subscribe(res => {
      saveAs(res, `${reportType.toLowerCase()}.csv`);
    });

    return downloadReport$;
  }

  downloadZippedReport(
    filtersModel: ReportFiltersModel,
    reportType: ReportType,
    downloadOptionType: ReportDownloadTypes,
    downloadFileName: string
  ): Observable<ArrayBuffer> {
    let params: HttpParams = new HttpParams();
    const filterQueryParams: HttpParams = this.getRequestParams(filtersModel);
    const headers = new HttpHeaders().set('Accept', Mime.ZIP);
    const apiUrl = this.reportApiUrlService.getDownloadApiUrl(this.baseUrl, reportType, downloadOptionType);

    filterQueryParams.keys().forEach(paramName => {
      params = params.set(paramName, filterQueryParams.get(paramName));
    });

    const downloadReportZipped$ = this.httpClient.get(apiUrl, { responseType: 'arraybuffer', headers, params }).pipe(
      tap(file => {
        FileUtils.downloadFile(file, downloadFileName, Mime.ZIP);
      })
    );

    return this.wsHelperService.call(downloadReportZipped$);
  }

  getBinary(options: DownloadZipOptions) {
    const url = `${this.baseUrl}${options.endpoint}`;
    const headers = new HttpHeaders().set('Accept', options.mime);
    const params = this.getRequestParams(options.filtersModel || {});

    return this.httpClient.get(url, { responseType: 'arraybuffer', headers, params }).pipe(
      tap(file => {
        FileUtils.downloadFile(file, options.filename, options.mime);
      })
    );
  }
}
