import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryProvider } from '@shared/services/dictionary-provider';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'dict'
})
export class DictPipe implements PipeTransform {
  constructor(private readonly dictionaryProvider: DictionaryProvider) {}
  transform(value: any, dictionaryName: string) {
    return this.dictionaryProvider.getDictionary(dictionaryName).pipe(
      map(dictionary => {
        if (!dictionary) {
          throw new Error(`Dictionary "${dictionaryName}" not found!`);
        }
        return dictionary.find(entry => entry.value === value)?.label;
      })
    );
  }
}
