import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { langToUppyLocaleMap } from '@utils/language.utils';
import { UppyFile } from '@uppy/core';

interface UppyI18nConfigItem {
  translationKey: string;
  uppyProperty: string;
  suffix?: string;
}

const uppyI18nConfig: Array<UppyI18nConfigItem> = [
  {
    translationKey: 'upload_box.general_message',
    uppyProperty: 'dropPaste',
    suffix: ' %{browse}'
  },
  {
    translationKey: 'upload_box.drop_hint',
    uppyProperty: 'dropHint'
  },
  {
    translationKey: 'upload_box.browse_button',
    uppyProperty: 'browse'
  }
];

export class UppyUtils {
  static getUppyCustomI18nObservable(translateService: TranslateService): Observable<{ [key: string]: string }> {
    return translateService.get(uppyI18nConfig.map(uppyI18nConfigItem => uppyI18nConfigItem.translationKey));
  }

  static getUppyLocaleConfig(translations: { [p: string]: string }, currentLang: string): any {
    const defaultLanguageLocales = langToUppyLocaleMap.get(currentLang);
    const customStrings = {};
    uppyI18nConfig.forEach(uppyI18nConfigItem => {
      let translation = translations[uppyI18nConfigItem.translationKey];
      if (!!uppyI18nConfigItem.suffix) {
        translation = translation + uppyI18nConfigItem.suffix;
      }
      customStrings[uppyI18nConfigItem.uppyProperty] = translation;
    });
    if (!!defaultLanguageLocales) {
      return { ...defaultLanguageLocales, strings: { ...defaultLanguageLocales.strings, ...customStrings } };
    } else {
      return { strings: customStrings };
    }
  }

  static isFileEmpty(file: UppyFile): boolean {
    return file.size === 0;
  }
}

interface UppyErrorPatternAndUserMessage {
  regex: RegExp;
  translationKey: string;
}

export class UppyRestrictionUtils {
  private static translationMapping: Array<UppyErrorPatternAndUserMessage> = [
    {
      regex: /^You can only upload \d* file[s]?$/,
      translationKey: 'error.too_many_files'
    },
    {
      regex: /^You can only upload: /,
      translationKey: 'error.unsupported_media_title'
    },
    {
      regex: /exceeds maximum allowed size of/,
      translationKey: 'upload_box.file_to_big'
    }
  ];

  static getTranslationKeyForErrorMessage(errorMessage: string): string | undefined {
    return this.translationMapping
      .filter(uppyPatternAndMessage => uppyPatternAndMessage.regex.test(errorMessage))
      .map(uppyPatternAndMessage => uppyPatternAndMessage.translationKey)
      .shift();
  }
}
