import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { GenericResourceListComponent } from './generic-resource-list/generic-resource-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [GenericResourceListComponent],
  imports: [CommonModule, FlexLayoutModule, TranslateModule, FontAwesomeModule, MatIconModule],
  exports: [GenericResourceListComponent]
})
export class GenericResourceListModule {}
