<div class="form-row">
  <stx-checkbox
    [name]="'ortho.marked_discontinued'"
    [controlName]="'isAbandoned'"
    [formMode]="formMode"
    [tooltip]="tooltipText"
  ></stx-checkbox>
</div>
<div class="form-row">
  <stx-textarea [label]="'ortho.abandon_reason_more'" [controlName]="'abandonReasonMore'" [formMode]="formMode"> </stx-textarea>
</div>
