import { NutritionType } from '@src/app/features/nutrition-management/enums/nutrition-type.enum';
import {
  CheckboxWithSubtitle,
  NutritionCheckboxItem,
  NutritionRadioItem
} from '@src/app/features/nutrition-management/models/nutrition-item.model';
import { PatientCareType } from '@src/app/features/nutrition-management/enums/patient-care-type.enum';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const nutritionTypeToShortLabelMap = new Map([
  [NutritionType.INFANT, 'nutrition_management.infant.short'],
  [NutritionType.TODDLER, 'nutrition_management.toddler.short'],
  [NutritionType.ADOLESCENT, 'nutrition_management.adolescent.short'],
  [NutritionType.ADULT, 'nutrition_management.adult']
]);

const notBiologicalMother: CheckboxWithSubtitle = {
  name: 'notBiologicalMother',
  label: 'nutrition_management.feeding_assessment.not_bio_mother',
  subtitle: 'nutrition_management.feeding_assessment.not_bio_mother.subtitle',
  value: false
};
const adultAdolescentDifficulty: CheckboxWithSubtitle = {
  name: 'difficulty',
  label: 'nutrition_management.feeding_info.difficulty',
  value: false
};

const noProblem: CheckboxWithSubtitle = {
  name: 'noProblem',
  label: 'nutrition_management.none',
  value: false
};

export const feedingAssessmentOptions: Record<NutritionType, CheckboxWithSubtitle[][]> = {
  [NutritionType.INFANT]: [
    [
      notBiologicalMother,
      {
        name: 'difficulty',
        label: 'nutrition_management.birthweight_feeding_info.difficulty',
        value: false
      },
      {
        name: 'otherComplications',
        label: 'nutrition_management.birthweight_feeding_info.other',
        value: false,
        subtitle: 'nutrition_management.birthweight_feeding_info.other.subtitle'
      }
    ],
    [
      {
        name: 'difficultyBreast',
        label: 'nutrition_management.birthweight_feeding_info.mother_difficulty',
        value: false,
        subtitle: 'nutrition_management.birthweight_feeding_info.mother_difficulty.subtitle'
      },
      {
        name: 'other',
        label: 'nutrition_management.feeding_assessment.other_remarks',
        value: false
      },
      noProblem
    ]
  ],
  [NutritionType.TODDLER]: [
    [
      notBiologicalMother,
      {
        name: 'difficulty',
        label: 'nutrition_management.feeding_info.difficulty.child',
        value: false
      }
    ],
    [
      {
        name: 'otherComplications',
        label: 'nutrition_management.feeding_info.other.child',
        value: false,
        subtitle: 'nutrition_management.birthweight_feeding_info.other.subtitle'
      },
      {
        name: 'noBreastfeeding',
        label: 'nutrition_management.feeding_info.mother_doesnt_feed',
        value: false,
        subtitle: 'nutrition_management.feeding_info.mother_doesnt_feed.tooltip'
      }
    ],
    [
      {
        name: 'noComplementary',
        label: 'nutrition_management.feeding_assessment.no_complementary',
        subtitle: 'nutrition_management.feeding_assessment.no_complementary.subtitle',
        value: false
      },
      {
        name: 'noTexture',
        label: 'nutrition_management.feeding_assessment.no_texture',
        subtitle: 'nutrition_management.feeding_assessment.no_texture.subtitle',
        value: false
      }
    ],
    [
      {
        name: 'other',
        label: 'nutrition_management.feeding_assessment.other_remarks',
        value: false
      },
      noProblem
    ]
  ],
  [NutritionType.ADOLESCENT]: [
    [notBiologicalMother, adultAdolescentDifficulty],
    [
      {
        name: 'otherComplications',
        label: 'nutrition_management.feeding_info.other',
        value: false,
        subtitle: 'nutrition_management.birthweight_feeding_info.other.subtitle'
      }
    ],
    [
      {
        name: 'noTexture',
        label: 'nutrition_management.feeding_assessment.no_texture.adolescent',
        subtitle: 'nutrition_management.feeding_assessment.no_texture.subtitle.adolescent',
        value: false
      }
    ],
    [
      {
        name: 'other',
        label: 'nutrition_management.feeding_assessment.other_remarks',
        value: false
      },
      noProblem
    ]
  ],
  [NutritionType.ADULT]: [
    [adultAdolescentDifficulty],
    [
      {
        name: 'otherComplications',
        label: 'nutrition_management.feeding_info.other',
        value: false,
        subtitle: 'nutrition_management.feeding_info.other.tooltip'
      }
    ],
    [noProblem]
  ]
};

export const anemiaManagementOptions: NutritionCheckboxItem[] = [
  {
    name: 'noManagementNeeded',
    label: 'nutrition_management.anemia_management.no_specific_management_needed',
    value: false,
    showFor: [NutritionType.INFANT, NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
  },
  {
    name: 'ironBMvmSupplement',
    label: 'nutrition_management.anemia_management.infant.provision_iron_supplements',
    subtitle: [
      'nutrition_management.anemia_management.infant.provision_iron_supplements.subtitle',
      'nutrition_management.anemia_management.infant.provision_iron_supplements.subtitle_2'
    ],
    value: false,
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'ironBSource',
    label: 'nutrition_management.anemia_management.infant.advice_iron_and_vitamins',
    subtitle: 'nutrition_management.anemia_management.infant.advice_iron_and_vitamins.subtitle',
    value: false,
    showFor: [NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
  },
  {
    name: 'ironBMvmSupplement',
    label: 'nutrition_management.anemia_management.infant.provision_multivitamins_and_minerals',
    subtitle: 'nutrition_management.anemia_management.infant.provision_multivitamins_and_minerals.subtitle',
    value: false,
    showFor: [NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
  },
  {
    name: 'foodFunds',
    label: 'nutrition_management.anemia_management.infant.financial_support',
    value: false,
    showFor: [NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
  },
  {
    name: 'referral',
    label: 'nutrition_management.anemia_management.infant.investigation_referral',
    value: false,
    showFor: [NutritionType.INFANT, NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
  },
  {
    name: 'other',
    label: 'nutrition_management.anemia_management.infant.other',
    value: false,
    showFor: [NutritionType.INFANT, NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
  },
  {
    name: 'none',
    label: 'nutrition_management.anemia_management.infant.none_of_the_above',
    value: false,
    showFor: [NutritionType.INFANT, NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
  }
];

export const nutritionalStatusOptions: NutritionCheckboxItem[] = [
  {
    name: 'good',
    label: 'nutrition_management.nutritional_status.good_nutritional_status',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.good_nutritional_status.subtitle',
    showFor: [NutritionType.INFANT, NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
  },
  {
    name: 'failingToThrive',
    label: 'nutrition_management.nutritional_status.failing_to_thrive_or_at_risk',
    value: false,
    subtitle: [
      'nutrition_management.nutritional_status.failing_to_thrive_or_at_risk.infant.subtitle',
      'nutrition_management.nutritional_status.failing_to_thrive_or_at_risk.infant.subtitle_2',
      'nutrition_management.nutritional_status.failing_to_thrive_or_at_risk.infant.subtitle_3'
    ],
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'failingToThrive',
    label: 'nutrition_management.nutritional_status.failing_to_thrive_or_at_risk',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.failing_to_thrive_or_at_risk.toddler.subtitle',
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'failingToThrive',
    label: 'nutrition_management.nutritional_status.failing_to_thrive_or_at_risk',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.failing_to_thrive_or_at_risk.adolescent.subtitle',
    showFor: [NutritionType.ADOLESCENT]
  },
  {
    name: 'wasted',
    label: 'nutrition_management.nutritional_status.wasted',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.wasted.infant.subtitle',
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'wasted',
    label: 'nutrition_management.nutritional_status.wasted',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.wasted.adolescent.subtitle',
    showFor: [NutritionType.ADOLESCENT]
  },
  {
    name: 'moderatelyWasted',
    label: 'nutrition_management.nutritional_status.moderately_wasted',
    value: false,
    subtitle: [
      'nutrition_management.nutritional_status.moderately_wasted.subtitle.new',
      'nutrition_management.nutritional_status.moderately_wasted.subtitle.new_2'
    ],
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'severelyWasted',
    label: 'nutrition_management.nutritional_status.severely_wasted',
    value: false,
    subtitle: [
      'nutrition_management.nutritional_status.severely_wasted.subtitle.new',
      'nutrition_management.nutritional_status.severely_wasted.subtitle.new_2'
    ],
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'stunted',
    label: 'nutrition_management.nutritional_status.stunted',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.stunted.infant.subtitle',
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'stunted',
    label: 'nutrition_management.nutritional_status.stunted',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.stunted.adolescent.subtitle',
    showFor: [NutritionType.ADOLESCENT]
  },
  {
    name: 'moderatelyStunted',
    label: 'nutrition_management.nutritional_status.moderately_stunted',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.moderately_stunted.subtitle',
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'severelyStunted',
    label: 'nutrition_management.nutritional_status.severely_stunted',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.severely_stunted.subtitle',
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'underweight',
    label: 'nutrition_management.nutritional_status.underweight',
    value: false,
    subtitle: [
      'nutrition_management.nutritional_status.underweight.infant.subtitle',
      'nutrition_management.nutritional_status.underweight.infant.subtitle_2'
    ],
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'underweight',
    label: 'nutrition_management.nutritional_status.underweight',
    value: false,
    subtitle: [
      'nutrition_management.nutritional_status.underweight.toddler.subtitle',
      'nutrition_management.nutritional_status.underweight.toddler.subtitle_2',
      'nutrition_management.nutritional_status.underweight.toddler.subtitle_3'
    ],
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'underweight',
    label: 'nutrition_management.nutritional_status.underweight',
    value: false,
    subtitle: [
      'nutrition_management.nutritional_status.underweight.adolescent.subtitle',
      'nutrition_management.nutritional_status.underweight.adolescent.subtitle_2',
      'nutrition_management.nutritional_status.underweight.adolescent.subtitle_3',
      'nutrition_management.nutritional_status.underweight.adolescent.subtitle_4'
    ],
    showFor: [NutritionType.ADOLESCENT]
  },
  {
    name: 'underweight',
    label: 'nutrition_management.nutritional_status.underweight',
    value: false,
    subtitle: [
      'nutrition_management.nutritional_status.underweight.adult.subtitle',
      'nutrition_management.nutritional_status.underweight.adult.subtitle_2'
    ],
    showFor: [NutritionType.ADULT]
  },
  {
    name: 'edema',
    label: 'nutrition_management.nutritional_status.edema',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.edema.infant.subtitle',
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'edema',
    label: 'nutrition_management.nutritional_status.edema',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.edema.toddler.subtitle',
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'edema',
    label: 'nutrition_management.nutritional_status.edema',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.edema.adolescent.subtitle',
    showFor: [NutritionType.ADOLESCENT]
  },
  {
    name: 'overweightRisk',
    label: 'nutrition_management.nutritional_status.at_risk_of_overweight',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.at_risk_of_overweight.infant.subtitle',
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'overweightRisk',
    label: 'nutrition_management.nutritional_status.at_risk_of_overweight',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.at_risk_of_overweight.toddler.subtitle',
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'overweightRisk',
    label: 'nutrition_management.nutritional_status.at_risk_of_overweight',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.at_risk_of_overweight.adolescent.subtitle',
    showFor: [NutritionType.ADOLESCENT]
  },
  {
    name: 'overweight',
    label: 'nutrition_management.nutritional_status.overweight',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.overweight.infant.subtitle',
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'overweight',
    label: 'nutrition_management.nutritional_status.overweight',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.overweight.toddler.subtitle',
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'overweight',
    label: 'nutrition_management.nutritional_status.overweight',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.overweight.adolescent.subtitle',
    showFor: [NutritionType.ADOLESCENT]
  },
  {
    name: 'overweight',
    label: 'nutrition_management.nutritional_status.overweight',
    value: false,
    subtitle: [
      'nutrition_management.nutritional_status.overweight.adult.subtitle',
      'nutrition_management.nutritional_status.overweight.adult.subtitle_2'
    ],
    showFor: [NutritionType.ADULT]
  },
  {
    name: 'obese',
    label: 'nutrition_management.nutritional_status.obese',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.obese.infant.subtitle',
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'obese',
    label: 'nutrition_management.nutritional_status.obese',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.obese.toddler.subtitle',
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'obese',
    label: 'nutrition_management.nutritional_status.obese',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.obese.adolescent.subtitle',
    showFor: [NutritionType.ADOLESCENT]
  },
  {
    name: 'obese',
    label: 'nutrition_management.nutritional_status.obese',
    value: false,
    subtitle: [
      'nutrition_management.nutritional_status.obese.adult.subtitle',
      'nutrition_management.nutritional_status.obese.adult.subtitle_2'
    ],
    showFor: [NutritionType.ADULT]
  },
  {
    name: 'anemic',
    label: 'nutrition_management.nutritional_status.anemic',
    value: false,
    subtitle: 'nutrition_management.nutritional_status.anemic.infant.subtitle',
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'anemic',
    label: 'nutrition_management.nutritional_status.anemic',
    value: false,
    subtitle: [
      'nutrition_management.nutritional_status.anemic.toddler.subtitle',
      'nutrition_management.nutritional_status.anemic.toddler.subtitle_2',
      'nutrition_management.nutritional_status.anemic.toddler.subtitle_3'
    ],
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'anemic',
    label: 'nutrition_management.nutritional_status.anemic',
    value: false,
    subtitle: [
      'nutrition_management.nutritional_status.anemic.adolescent.subtitle',
      'nutrition_management.nutritional_status.anemic.adolescent.subtitle_2',
      'nutrition_management.nutritional_status.anemic.adolescent.subtitle_3',
      'nutrition_management.nutritional_status.anemic.adolescent.subtitle_4',
      'nutrition_management.nutritional_status.anemic.adolescent.subtitle_5'
    ],
    showFor: [NutritionType.ADOLESCENT]
  },
  {
    name: 'anemic',
    label: 'nutrition_management.nutritional_status.anemic',
    value: false,
    subtitle: [
      'nutrition_management.nutritional_status.anemic.adult.subtitle',
      'nutrition_management.nutritional_status.anemic.adult.subtitle_2',
      'nutrition_management.nutritional_status.anemic.adult.subtitle_3',
      'nutrition_management.nutritional_status.anemic.adult.subtitle_4'
    ],
    showFor: [NutritionType.ADULT]
  },
  {
    name: 'other',
    label: 'nutrition_management.nutritional_status.other',
    value: false,
    showFor: [NutritionType.INFANT, NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
  }
];

export const nutritionCarePlanOptions: NutritionCheckboxItem[] = [
  {
    name: 'breastFeeding',
    label: 'nutrition_management.nutrition_care_plan.breastfeeding_assistance',
    value: false,
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'breastFeeding',
    label: 'nutrition_management.nutrition_care_plan.breastfeeding_assistance.toddler',
    value: false,
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'supportBreastmilkExpression',
    label: 'nutrition_management.nutrition_care_plan.breastmilk_expression',
    value: false,
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'adviceBreastmilkTechniques',
    label: 'nutrition_management.nutrition_care_plan.advice_tools_and_techniques',
    value: false,
    subtitle: 'nutrition_management.nutrition_care_plan.cleaning_and_sanitising_support',
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'adviceAnimalMilk',
    label: 'nutrition_management.nutrition_care_plan.advice_tools_and_techniques.toddler',
    value: false,
    subtitle: 'nutrition_management.nutrition_care_plan.cleaning_and_sanitising_support',
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'adviceSubstitutes',
    label: 'nutrition_management.nutrition_care_plan.advice_substitutes_and_tools',
    value: false,
    subtitle: 'nutrition_management.nutrition_care_plan.cleaning_and_sanitising_support',
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'provisionSubstitutes',
    label: 'nutrition_management.nutrition_care_plan.provision_substitutes',
    value: false,
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'provisionFeedingTools',
    label: 'nutrition_management.nutrition_care_plan.provision_feeding_tools',
    value: false,
    subtitle: 'nutrition_management.nutrition_care_plan.cleaning_and_sanitising_support',
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'provisionFeedingTools',
    label: 'nutrition_management.nutrition_care_plan.provision_feeding_tools.toddler',
    value: false,
    subtitle: 'nutrition_management.nutrition_care_plan.cleaning_and_sanitising_support',
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'adviceComplementaryFeeding',
    label: 'nutrition_management.nutrition_care_plan.advice_introduction',
    value: false,
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'foodPrep',
    label: 'nutrition_management.nutrition_care_plan.advice',
    value: false,
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'foodPrep',
    label: 'nutrition_management.nutrition_care_plan.food_commodities_advice',
    showFor: [NutritionType.TODDLER],
    value: false
  },
  {
    name: 'foodPrep',
    label: 'nutrition_management.nutrition_care_plan.food_commodities_advice.adolescent',
    showFor: [NutritionType.ADOLESCENT],
    value: false
  },
  {
    name: 'foodPrep',
    label: 'nutrition_management.nutrition_care_plan.food_commodities_advice.patient',
    showFor: [NutritionType.ADULT],
    value: false
  },
  {
    name: 'foodFunds',
    label: 'nutrition_management.nutrition_care_plan.food_commodities',
    showFor: [NutritionType.TODDLER],
    value: false,
    subtitle: 'nutrition_management.nutrition_care_plan.food_commodities.subtitle'
  },
  {
    name: 'foodFunds',
    label: 'nutrition_management.nutrition_care_plan.food_commodities.adolescent',
    value: false,
    showFor: [NutritionType.ADOLESCENT]
  },
  {
    name: 'foodFunds',
    label: 'nutrition_management.nutrition_care_plan.food_commodities.patient',
    value: false,
    showFor: [NutritionType.ADULT]
  },
  {
    name: 'foodRations',
    label: 'nutrition_management.nutrition_care_plan.food_provisions',
    value: false,
    showFor: [NutritionType.INFANT]
  },
  {
    name: 'foodRations',
    label: 'nutrition_management.nutrition_care_plan.food_provisions.toddler',
    subtitle: [
      'nutrition_management.nutrition_care_plan.food_provisions.toddler.tooltip',
      'nutrition_management.nutrition_care_plan.food_provisions.toddler.tooltip_2'
    ],
    value: false,
    showFor: [NutritionType.TODDLER]
  },
  {
    name: 'foodRations',
    label: 'nutrition_management.nutrition_care_plan.food_provisions.toddler',
    value: false,
    showFor: [NutritionType.ADOLESCENT, NutritionType.ADULT]
  },
  {
    name: 'rutf',
    label: 'nutrition_management.nutrition_care_plan.rutf',
    showFor: [NutritionType.TODDLER],
    value: false
  },
  {
    name: 'foodTherapeutic',
    label: 'nutrition_management.nutrition_care_plan.therapeutic_rehabilitation',
    subtitle: 'nutrition_management.nutrition_care_plan.therapeutic_rehabilitation.subtitle.adolescent',
    value: false,
    showFor: [NutritionType.ADOLESCENT]
  },
  {
    name: 'foodTherapeutic',
    label: 'nutrition_management.nutrition_care_plan.therapeutic_rehabilitation',
    subtitle: 'nutrition_management.nutrition_care_plan.therapeutic_rehabilitation.subtitle.adult',
    value: false,
    showFor: [NutritionType.ADULT]
  },
  {
    name: 'mvm',
    label: 'nutrition_management.nutrition_care_plan.multivitamin',
    value: false,
    showFor: [NutritionType.ADULT]
  },
  {
    name: 'mvm',
    label: 'nutrition_management.nutrition_care_plan.multivitamin.toddler',
    value: false,
    showFor: [NutritionType.TODDLER, NutritionType.ADOLESCENT]
  },
  {
    name: 'other',
    label: 'nutrition_management.nutrition_care_plan.other',
    value: false,
    showFor: [NutritionType.INFANT, NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
  },
  {
    name: 'none',
    label: 'nutrition_management.none',
    value: false,
    showFor: [NutritionType.INFANT, NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
  }
];

export const typesOfPatientCare: NutritionRadioItem[] = [
  {
    value: PatientCareType.OUTPATIENT,
    label: 'nutrition_management.initial_type_of_patient_care.outpatient_care',
    subtitle: 'nutrition_management.initial_type_of_patient_care.outpatient_care.subtitle',
    checkboxes: [
      {
        name: 'outpatientInChargeOfFollowup',
        label: 'nutrition_management.initial_type_of_patient_care.staff_member_followup',
        subtitle: 'nutrition_management.initial_type_of_patient_care.staff_member_followup.subtitle',
        value: false,
        showFor: [NutritionType.INFANT, NutritionType.TODDLER]
      },
      {
        name: 'outpatientInChargeOfFollowup',
        label: 'nutrition_management.initial_type_of_patient_care.staff_member_followup',
        value: false,
        showFor: [NutritionType.ADOLESCENT, NutritionType.ADULT]
      },
      {
        name: 'outpatientAskedToReturn',
        label: 'nutrition_management.initial_type_of_patient_care.caregiver_followup',
        value: false,
        showFor: [NutritionType.INFANT, NutritionType.TODDLER, NutritionType.ADOLESCENT]
      },
      {
        name: 'outpatientAskedToReturn',
        label: 'nutrition_management.initial_type_of_patient_care.patient_followup',
        value: false,
        showFor: [NutritionType.ADULT]
      },
      {
        name: 'outpatientFollowupNone',
        label: 'nutrition_management.none',
        value: false,
        showFor: [NutritionType.INFANT, NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
      }
    ]
  },
  {
    value: PatientCareType.INPATIENT,
    label: 'nutrition_management.initial_type_of_patient_care.inpatient_care',
    subtitle: 'nutrition_management.initial_type_of_patient_care.inpatient_care.subtitle'
  },
  {
    value: PatientCareType.REFERRAL,
    label: 'nutrition_management.initial_type_of_patient_care.another_service_refferal',
    subtitle: 'nutrition_management.initial_type_of_patient_care.refferal.subtitle',
    checkboxes: [
      {
        name: 'referralInChargeOfFollowup',
        label: 'nutrition_management.initial_type_of_patient_care.staff_member_followup',
        subtitle: 'nutrition_management.initial_type_of_patient_care.staff_member_followup.subtitle',
        value: false,
        showFor: [NutritionType.INFANT, NutritionType.TODDLER]
      },
      {
        name: 'referralInChargeOfFollowup',
        label: 'nutrition_management.initial_type_of_patient_care.staff_member_followup',
        value: false,
        showFor: [NutritionType.ADOLESCENT, NutritionType.ADULT]
      },
      {
        name: 'referralAskedToReturn',
        label: 'nutrition_management.initial_type_of_patient_care.caregiver_followup',
        value: false,
        showFor: [NutritionType.INFANT, NutritionType.TODDLER, NutritionType.ADOLESCENT]
      },
      {
        name: 'referralAskedToReturn',
        label: 'nutrition_management.initial_type_of_patient_care.patient_followup',
        value: false,
        showFor: [NutritionType.ADULT]
      },
      {
        name: 'referralFollowupNone',
        label: 'nutrition_management.none',
        value: false,
        showFor: [NutritionType.INFANT, NutritionType.TODDLER, NutritionType.ADOLESCENT, NutritionType.ADULT]
      }
    ]
  }
];

export const nutritionalStatusFields = [
  'good',
  'failingToThrive',
  'wasted',
  'moderatelyWasted',
  'severelyWasted',
  'stunted',
  'moderatelyStunted',
  'severelyStunted',
  'underweight',
  'edema',
  'overweightRisk',
  'overweight',
  'obese',
  'anemic',
  'other'
];

export const nutritionCarePlanFields = [
  'breastFeeding',
  'supportBreastmilkExpression',
  'adviceBreastmilkTechniques',
  'adviceSubstitutes',
  'provisionSubstitutes',
  'provisionFeedingTools',
  'adviceComplementaryFeeding',
  'foodPrep',
  'foodFunds',
  'foodRations',
  'rutf',
  'mvm',
  'foodTherapeutic',
  'none',
  'other',
  'adviceAnimalMilk'
];

export const anemiaManagementFields = [
  'noManagementNeeded',
  'ironSupplement',
  'referral',
  'none',
  'other',
  'otherMore',
  'ironBSource',
  'ironBMvmSupplement',
  'foodFunds'
];

export const feedingFields = [
  'notBiologicalMother',
  'difficultyBreast',
  'difficulty',
  'other',
  'otherComplications',
  'noComplementary',
  'noBreastfeeding',
  'noTexture',
  'noProblem'
];

export const patientCareFields = ['inChargeOfFollowup', 'askedToReturn', 'followupNone'];
export const outpatientPatientCareFields = ['outpatientInChargeOfFollowup', 'outpatientAskedToReturn', 'outpatientFollowupNone'];
export const referralPatientCareFields = ['referralInChargeOfFollowup', 'referralAskedToReturn', 'referralFollowupNone'];

export enum NutritionStage {
  ASSESSMENT = 'ASSESSMENT',
  FOLLOW_UP = 'FOLLOW_UP'
}

export enum NutritionSection {
  BIRTHWEIGHT,
  FEEDING_ASSESSMENT,
  CURRENT_FEEDING,
  TYPE_OF_PATIENT_CARE,
  NUTRITIONAL_STATUS,
  NUTRITION_CARE_PLAN,
  ANEMIA_MANAGEMENT_PLAN
}

const defaultSectionConfig: NutritionSection[] = [
  NutritionSection.FEEDING_ASSESSMENT,
  NutritionSection.NUTRITIONAL_STATUS,
  NutritionSection.TYPE_OF_PATIENT_CARE,
  NutritionSection.NUTRITION_CARE_PLAN,
  NutritionSection.ANEMIA_MANAGEMENT_PLAN
];

const infantSectionConfig: NutritionSection[] = [
  NutritionSection.BIRTHWEIGHT,
  NutritionSection.FEEDING_ASSESSMENT,
  NutritionSection.CURRENT_FEEDING,
  NutritionSection.NUTRITIONAL_STATUS,
  NutritionSection.TYPE_OF_PATIENT_CARE,
  NutritionSection.NUTRITION_CARE_PLAN,
  NutritionSection.ANEMIA_MANAGEMENT_PLAN
];

export function getSectionNumber(ageGroup: NutritionType, section: NutritionSection): number {
  if (ageGroup === NutritionType.INFANT) {
    return infantSectionConfig.indexOf(section) + 1;
  } else {
    return defaultSectionConfig.indexOf(section) + 1;
  }
}

export enum DecimalPlaces {
  ONE_DECIMAL_PLACE = 'ONE_DECIMAL_PLACE',
  TWO_DECIMAL_PLACES = 'TWO_DECIMAL_PLACES'
}

export const decimalPlacesToRegexPatternMap: Map<DecimalPlaces, RegExp> = new Map<DecimalPlaces, RegExp>([
  [DecimalPlaces.ONE_DECIMAL_PLACE, new RegExp('^(?:\\d+(\\.\\d)?|\\d+)$')],
  [DecimalPlaces.TWO_DECIMAL_PLACES, new RegExp('^(?!.*\\.\\.$)(\\d+(\\.\\d{1,2})?|\\d+\\.\\d{2})$')]
]);

export function patternInputMatchingValidator(inputPattern: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const currentValueOfInput: string = control.value;

    if (!currentValueOfInput) {
      return null;
    }

    const isInputMatchingDesiredPattern: boolean = inputPattern.test(currentValueOfInput);

    return !isInputMatchingDesiredPattern || isNaN(parseFloat(currentValueOfInput)) ? { pattern: true } : null;
  };
}
