<stx-data-block [header]="'menu.resources.education_research' | translate">
  <div class="data-spaced-column">
    <ul class="data-spaced-column">
      <li>
        <div class="resource-spaced-column">
          <a [routerLink]="weeklyAbstractsRoute">{{ 'cleft_publications.title' | translate }}</a>
        </div>
      </li>
    </ul>
    <hr class="form-subsection-separator" />
    <div>
      <stx-medical-meetings-list [items]="topMedicalMeeting$ | async"></stx-medical-meetings-list>
    </div>
    <div>
      <button data-testid="button-dashboard-resources" mat-stroked-button color="primary" [routerLink]="educationAndResearchRoute">
        {{ 'view_more' | translate }}
      </button>
    </div>
  </div>
</stx-data-block>
<stx-data-block [header]="'dashboard.saq_protocol.title' | translate">
  <div class="data-spaced-column">
    <stx-generic-resource-list [sections]="saqProtocolsResources"></stx-generic-resource-list>
  </div>
</stx-data-block>
