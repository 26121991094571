<label class="form-label display-block">
  <mat-icon *ngIf="print" class="m-r-10">videocam</mat-icon>
  {{ label | translate }}
  <stx-tooltip [ngClass]="{ 'hide-on-print': print }" *ngIf="tooltip" [content]="tooltip"></stx-tooltip>
</label>

<form [formGroup]="formGroup" [ngClass]="{ 'hide-on-print': print }">
  <stx-uppy-audio-video
    [formMode]="formMode"
    [parentOrderNameToSet]="parentOrderName"
    [formControlName]="controlName"
    [uuid]="uuid"
  ></stx-uppy-audio-video>
</form>

<mat-error *ngIf="showStandardError(formGroup, controlName)">{{ 'error.upload_video' | translate }}</mat-error>

<mat-error *ngIf="showEmptyAudioVideoError">{{ 'error.upload_video_empty' | translate }}</mat-error>
