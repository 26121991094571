import { isValidUrl, StxValidators } from '@/src/app/shared/validation/validators';
import { getCurrentYear } from '@/src/app/utils/date.utils';
import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PermissionEnum } from 'src/app/core/permissions/permission.enum';
import { EducationalResourcesService } from 'src/app/features/educational-resources/educational-resources.service';
import { CleftPublication } from 'src/app/features/educational-resources/models/cleft-publication.model';
import { GenericFormElementType } from 'src/app/shared/enums/generic-form-element-type';
import { GenericFormModel } from 'src/app/shared/models/generic-form-model.model';
import { FormMode } from 'src/app/utils/form.utils';
import { WsHelperService } from '@shared/services/ws-helper.service';

const YEARS_RANGE = 2;
const CURRENT_YEAR = getCurrentYear();
const PUBLISHER_MAX_LENGTH = 255;
const PUBLISHER_INFO_MAX_LENGTH = 255;
const URL_MAX_LENGTH = 255;

@Component({
  selector: 'stx-cleft-publications-form',
  templateUrl: './cleft-publications-form.component.html'
})
export class CleftPublicationsFormComponent {
  @Output() newPublicationAdded: EventEmitter<void> = new EventEmitter();
  readonly PermissionEnum = PermissionEnum;
  readonly cleftPublicationForm: UntypedFormGroup;

  constructor(
    private educationalResourcesService: EducationalResourcesService,
    private formBuilder: UntypedFormBuilder,
    private readonly wsHelperService: WsHelperService
  ) {
    this.cleftPublicationForm = this.createInitialForm();
  }

  dataForGenericFormCreation: GenericFormModel[] = [
    {
      nameToDisplay: 'cleft_publications.form_title',
      formName: 'title',
      typeOfElement: GenericFormElementType.Input,
      isValid: true,
      dataType: 'string',
      formMode: FormMode.EDIT
    },
    {
      nameToDisplay: 'cleft_publications.form_authors',
      formName: 'authors',
      typeOfElement: GenericFormElementType.Input,
      isValid: true,
      dataType: 'string',
      formMode: FormMode.EDIT
    },
    {
      nameToDisplay: 'cleft_publications.form_publisher',
      formName: 'publisher',
      typeOfElement: GenericFormElementType.Input,
      isValid: true,
      dataType: 'string',
      formMode: FormMode.EDIT
    },
    {
      nameToDisplay: 'cleft_publications.form_publisher_detailed_info',
      formName: 'publisherInfo',
      typeOfElement: GenericFormElementType.Input,
      isValid: true,
      dataType: 'string',
      formMode: FormMode.EDIT
    },
    {
      nameToDisplay: 'cleft_publications.form_year_published',
      formName: 'yearPublished',
      typeOfElement: GenericFormElementType.Select,
      options: this.range(CURRENT_YEAR - YEARS_RANGE, CURRENT_YEAR + YEARS_RANGE).map(x => ({
        id: x,
        name: x.toString()
      })),
      isValid: true,
      dataType: 'number',
      formMode: FormMode.EDIT
    },
    {
      nameToDisplay: 'cleft_publications.form_link_to_article',
      formName: 'linkPublished',
      typeOfElement: GenericFormElementType.Input,
      isValid: true,
      dataType: 'string',
      formMode: FormMode.EDIT
    }
  ];

  private range(start: number, end: number) {
    return Array.from({ length: end - start + 1 }, (v, k) => k + start);
  }

  savePublication: () => Observable<CleftPublication> = () => {
    const cleftPublication: CleftPublication = this.cleftPublicationForm.value;
    return this.wsHelperService.callWithSpinner(this.educationalResourcesService.saveCleftPublications(cleftPublication)).pipe(
      tap(() => {
        this.resetFormToInitialState(this.cleftPublicationForm);
        this.newPublicationAdded.emit();
      })
    );
  };

  private createInitialForm() {
    return this.formBuilder.group({
      title: [null, StxValidators.required],
      authors: [null, StxValidators.required],
      publisher: [null, [StxValidators.required, Validators.maxLength(PUBLISHER_MAX_LENGTH)]],
      publisherInfo: [null, [StxValidators.required, Validators.maxLength(PUBLISHER_INFO_MAX_LENGTH)]],
      yearPublished: [CURRENT_YEAR, StxValidators.required],
      linkPublished: [null, [StxValidators.required, isValidUrl, Validators.maxLength(URL_MAX_LENGTH)]]
    });
  }

  private resetFormToInitialState(formGroup: UntypedFormGroup) {
    formGroup.reset();
    formGroup.get('yearPublished').setValue(CURRENT_YEAR);
  }
}
