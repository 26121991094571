<div class="image" [ngClass]="{ 'show-alt-image': showAltImage }">
  <div class="container full-height full-width" #previewImageContainer>
    <img
      #previewImage
      [ngClass]="{ 'cursor-pointer': !!fullSizeUrl }"
      [src]="thumbnailUrl"
      [width]="250"
      [height]="250"
      [alt]="''"
      (click)="openImageViewer()"
      (keyup.enter)="openImageViewer()"
    />
  </div>
  <ng-container *ngIf="!shouldDisablePhotoControls">
    <div class="controls not-print" [class.display-none]="!thumbnailUrl">
      <stx-image-rotator
        [imageNativeElement]="previewImage"
        [imageContainerNativeElement]="previewImageContainer"
        [heightAuto]="false"
        (rotated)="onImageRotated($event)"
      ></stx-image-rotator>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="!shouldDisablePhotoControls">
  <a class="m-b-25 not-print" *ngIf="rawUrl" target="_blank" rel="noopener noreferrer" href="{{ rawUrl }}">{{
    'image_open' | translate
  }}</a>
</ng-container>
<ng-container *ngIf="!shouldDisablePhotoControls">
  <stx-metadata [mediaUrl]="metadata"></stx-metadata>
</ng-container>
