import { SearchModule } from '@/src/app/features/search/search.module';
import { ScopeTreeComponent } from '@/src/app/features/user/components/scope-tree/scope-tree.component';
import { SelectProfileComponent } from '@/src/app/features/user/components/select-profile/select-profile.component';
import { UserPersonalDataComponent } from '@/src/app/features/user/components/user-personal-data/user-personal-data.component';
import { UserProfileComponent } from '@/src/app/features/user/components/user-profile/user-profile.component';
import { UserSearchPageComponent } from '@/src/app/features/user/components/user-search-page/user-search-page.component';
import { UserSearchComponent } from '@/src/app/features/user/components/user-search/user-search.component';
import { UserPageComponent } from '@/src/app/features/user/components/user/user-page.component';
import { UsersRoutingModule } from '@/src/app/features/user/users-routing.module';
import { AutocompleteMultiSelectComponentModule } from '@/src/app/shared/components/autocomplete-multi-select/autocomplete.module';
import { FormModule } from '@/src/app/shared/components/form/form.module';
import { TableModule } from '@/src/app/shared/components/table/table.module';
import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { MaterialModule } from '@/src/app/shared/modules/material/material.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationModule } from '@src/app/features/authentication/authentication.module';

@NgModule({
  declarations: [
    UserSearchPageComponent,
    UserPersonalDataComponent,
    UserPageComponent,
    UserProfileComponent,
    ScopeTreeComponent,
    SelectProfileComponent,
    UserSearchComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    UsersRoutingModule,
    TranslateModule.forChild(),
    MaterialModule,
    TableModule,
    FlexLayoutModule,
    FormsModule,
    FormModule,
    SearchModule,
    AutocompleteMultiSelectComponentModule,
    GeneralCommonsModule,
    AuthenticationModule
  ],
  exports: []
})
export class UsersModule {}
