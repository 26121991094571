import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { User } from '@shared/models/user/user.model';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { AsyncButtonClickAction } from '@utils/button.utils';
import { tap } from 'rxjs/operators';
import { ConfigurationService } from '@shared/services/configuration.service';
import { AuthService } from '../../../authentication/services/auth.service';
import { Profile } from '../../models/user-data.model';
import { UserService } from '../../services/user.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

@Component({
  selector: 'stx-select-profile',
  templateUrl: './select-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectProfileComponent extends BaseComponent implements OnInit {
  profiles: Profile[];
  activeProfileId: number;

  constructor(
    private authService: AuthService,
    private currentUserService: CurrentUserService,
    private userService: UserService,
    private router: Router,
    private configurationService: ConfigurationService,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.subSink.sink = this.currentUserService
      .getUserSubject()
      .asObservable()
      .subscribe(user => {
        if (user) {
          this.profiles = user.profiles;
          if (user.activeProfile) {
            this.activeProfileId = user.activeProfile.id;
          }
        }
      });
  }

  selectProfile(profileId: number): AsyncButtonClickAction {
    return () => {
      return this.wsHelperService.callWithSpinner(this.userService.selectUserProfile(profileId), { redirectOn404StatusCode: true }).pipe(
        tap((user: User) => {
          this.configurationService.getCurrentInstanceArea().then(currentInstance => {
            if (user.activeProfile.designatedArea === currentInstance) {
              this.currentUserService.setCurrentUser(user);
              this.router.navigateByUrl('/');
            } else {
              this.authService.generateToken().subscribe(response => {
                this.authService.logout().subscribe();
                window.location.href = response.redirectUrl;
              });
            }
          });
        })
      );
    };
  }
}
