<stx-generic-page>
  <div *ngIf="patientDetailsBlockData !== null">
    <stx-patient-details-block
      [patient]="patientDetailsBlockData.patientListItem"
      [countries]="patientDetailsBlockData.countries"
      [mode]="patientDetailsBlockData.mode"
      [genders]="patientDetailsBlockData.genders"
      class="display-block m-b-20"
    ></stx-patient-details-block>
  </div>

  <router-outlet></router-outlet>
</stx-generic-page>
