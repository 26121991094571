import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionPromptConfig, ActionPromptResult } from './action-prompt.model';

@Component({
  selector: 'stx-action-prompt',
  templateUrl: './action-prompt.component.html',
  styleUrls: ['./action-prompt.component.scss']
})
export class ActionPromptComponent {
  constructor(private dialogRef: MatDialogRef<ActionPromptComponent>, @Inject(MAT_DIALOG_DATA) public data: ActionPromptConfig) {}

  get hasMultipleMessages(): boolean {
    return this.data.promptBody.length > 1;
  }

  get singleMessage(): string {
    return this.data.promptBody[0];
  }

  cancel() {
    this.dialogRef.close(ActionPromptResult.CANCEL);
  }

  submit() {
    this.dialogRef.close(ActionPromptResult.SUBMIT);
  }
}
