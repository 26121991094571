<div *ngFor="let enumToOptions of enumToDicMapping | keyvalue" class="m-l-15">
  <h4>{{ enumValToTranslationLabel.get(enumToOptions.key) | translate }}</h4>
  <stx-checkbox-group-filter
    class="display-block m-l-15"
    filterName="{{ filterName }}"
    [dictionaryOptions]="enumToOptions.value"
    [formGroupReference]="reportService.reportFiltersFormGroup.get(formGroupReferenceName)"
    (filterChange)="reportService.onFilterChange($event)"
  >
  </stx-checkbox-group-filter>
</div>
