import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { GeographyFilterComponent } from 'src/app/features/search/components/filters/geography-filter/geography-filter.component';
import { OrgTreeFilterComponent } from 'src/app/features/search/components/filters/org-tree-filter/org-tree-filter.component';
import { GeoEnum } from 'src/app/features/search/enums/geo.enum';
import { SearchService } from 'src/app/features/search/search.service';
import { SearchBaseComponent } from '../../../search/components/search-base/search-base.component';
import { SearchFilterContainerConfig } from '../../../search/components/search-filter-container/search-filter-container.component';
import { getIdsFromItemFormControl } from '../../../search/search.utils';
import { SearchFilterService } from '../../../search/services/search-filter.service';
import { UserSearchModel } from '../../models/user-search.model';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { AuthService } from '@src/app/features/authentication/services/auth.service';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'stx-user-search',
  templateUrl: './user-search.component.html'
})
export class UserSearchComponent extends SearchBaseComponent implements OnInit {
  readonly userSearchFilterContainerConfig: SearchFilterContainerConfig = {
    isCsvAvailable: true,
    routerLink: { icon: 'add_circle_outline', text: 'user.user_new', url: '/users/new' }
  };
  GeoEnum = GeoEnum;
  @ViewChild(GeographyFilterComponent, { static: true }) geoFilter: GeographyFilterComponent;
  @ViewChild(OrgTreeFilterComponent, { static: true }) orgTreeFilter: OrgTreeFilterComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private cd: ChangeDetectorRef,
    searchFilterService: SearchFilterService,
    private readonly wsHelperService: WsHelperService
  ) {
    super(searchFilterService);
  }

  ngOnInit() {
    this.initializeUserSearchForm();
    this.loadFilters();
  }

  protected getSearchFilters(): UserSearchModel {
    return Object.assign(
      {},
      this.searchFormGroup.value,
      this.getOrgTreeRequestParams(),
      this.getGeoographyTreeRequestParams(),
      this.getRolesRequestdata()
    );
  }

  private initializeUserSearchForm(): void {
    this.searchFormGroup = this.formBuilder.group({
      active: [false],
      inactive: [false],
      loggedInToday: [false],
      domainAccess: [false],
      roles: [[]],
      partners: [[]],
      treatmentCenters: [[]],
      countries: [[]],
      crmRegions: [[]]
    });
  }

  private loadFilters(): void {
    this.subSink.sink = this.wsHelperService.callWithSpinner(this.searchService.getOrgTreeFilters()).subscribe(filters => {
      this.orgTreeFiltersData = filters;
      this.cd.detectChanges();
    });
  }

  private getOrgTreeRequestParams(): Record<string, string[]> {
    const keysToMap = ['partners', 'treatmentCenters'];

    return getIdsFromItemFormControl(this.searchFormGroup, keysToMap);
  }

  private getGeoographyTreeRequestParams(): Record<string, string[]> {
    const keysToMap = ['crmRegions', 'countries'];

    return getIdsFromItemFormControl(this.searchFormGroup, keysToMap);
  }

  private getRolesRequestdata(): Record<string, string[]> {
    const keysToMap = ['roles'];

    return getIdsFromItemFormControl(this.searchFormGroup, keysToMap);
  }
}
