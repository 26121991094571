import { MediaUrl } from 'src/app/features/media/models/media-url.model';
import { ParentOrderName } from '../enums/parent-order-name.enum';

export interface MediaResource {
  parentOrderName: ParentOrderName;
  urlList: MediaUrl[];
  isTemplateUrl?: boolean;
}

export function findMediaUrl(data: { mediaResources?: MediaResource[] }, parentOrderName: ParentOrderName): MediaUrl {
  const mediaResource = data?.mediaResources?.find(media => media.parentOrderName === parentOrderName);
  return mediaResource?.urlList?.[0];
}
