<div [style.padding-bottom.px]="getBottomPaddingBelowIcon()">
  <fa-icon
    [icon]="treatmentStatusConfig?.iconName"
    [ngStyle]="{ color: print ? greyColor : '' }"
    [class]="defaultIconColor ? 'status-white' : treatmentStatusConfig?.className"
  ></fa-icon
  >&nbsp;
  <ng-container *ngIf="showLabel">
    {{ treatmentStatusLabel | translate }}
  </ng-container>
</div>
<ng-content></ng-content>
