<ul class="no-bullet">
  <ng-container *ngFor="let option of options">
    <li *stxPermitted="option.permission">
      <ng-container *ngFor="let route of option.routes; last as isLast">
        <a [routerLink]="route.route" [queryParams]="route.queryParams">{{ route.name | translate }}</a>
        <stx-value-separator *ngIf="!isLast"></stx-value-separator>
      </ng-container>
    </li>
  </ng-container>
</ul>
