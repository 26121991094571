import { CompactPatientHeaderComponent } from '@/src/app/features/reports/components/compact-patient-header/compact-patient-header.component';
import { TreatmentStatusIndicatorComponentModule } from '@/src/app/shared/components/treatment-status-indicator/treatment-status-indicator.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CompactPatientHeaderComponent],
  imports: [CommonModule, TreatmentStatusIndicatorComponentModule, TranslateModule.forChild()],
  exports: [CompactPatientHeaderComponent],
  providers: []
})
export class CompactPatientHeaderModule {}
