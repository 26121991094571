<ng-container *ngIf="useLegacySelect">
  <stx-select
    [controlName]="controlName"
    [dictionaryList]="options"
    [formGroup]="formGroupReference"
    [label]="labelTranslationNode"
    [noFormFieldSpacing]="true"
    [showChips]="showChips"
    [hasBlankOption]="true"
  >
  </stx-select>
</ng-container>
<ng-container *ngIf="!useLegacySelect">
  <div [formGroup]="formGroupReference">
    <label class="form-element-label"> {{ labelTranslationNode | translate }}</label>

    <stx-chips
      [class.display-none]="formGroupReference.get(controlName)?.value?.length === 0"
      [itemsList]="formGroupReference.get(controlName)?.value"
      [ariaLabel]="controlName | translate"
      [removable]="true"
      (removeCallback)="onRemoveChip($event, formGroupReference.get(controlName))"
    ></stx-chips>

    <stx-autocomplete-multi-select
      [autoSelectSingleItem]="false"
      [formControlName]="controlName"
      [itemList]="options"
      [id]="controlName"
    ></stx-autocomplete-multi-select>
  </div>
</ng-container>
