<div [formGroup]="recordGroupReference" class="form-element-group-column">
  <mat-radio-group class="form-element-group-column" [formControlName]="recordTypeControlName" [name]="recordTypeControlName">
    <ng-container *ngFor="let option of recordTypeList; first as first">
      <mat-radio-button data-testid="radio-record-type" [value]="option.value">
        {{ option.label | translate }}
      </mat-radio-button>
      <div class="indented-form-block form-element-group-column" *ngIf="!!recordTypeAdditionalLabels[option.value].length">
        <span class="form-element-secondary-label" *ngFor="let label of recordTypeAdditionalLabels[option.value]">
          {{ label | translate }}
        </span>
      </div>
    </ng-container>
  </mat-radio-group>
  <div class="form-element-group-column">
    <stx-date-filter
      [formGroupReference]="dateGroupReference"
      [reportType]="reportType"
      (filterChange)="onDatesChange($event)"
    ></stx-date-filter>
    <hr class="form-spaced-subsection-separator" />
    <div *ngIf="!isStatsReport">
      <mat-radio-group class="form-element-group-grid-2cols-wrap" [formControlName]="datePresetControlName">
        <ng-container *ngFor="let option of datePresets; last as last">
          <mat-radio-button data-testid="radio-date-presets" [value]="option.value">
            {{ option.label | translate }}
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
    </div>
  </div>
</div>
