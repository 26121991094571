<mat-chip-listbox [attr.aria-label]="ariaLabel" class="chips-list" [ngClass]="{ 'block m-b-10': itemsList && itemsList.length > 0 }">
  <mat-chip-option
    @chip
    class="chip"
    [ngClass]="{ ellipsis: ellipsis }"
    *ngFor="let item of itemsList"
    [selectable]="selectable"
    [removable]="removable"
    (removed)="onRemove(item)"
    (selectionChange)="onSelect(item)"
  >
    {{ item.name }}
    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
  </mat-chip-option>
</mat-chip-listbox>
