<div [ngClass]="{ 'p-t-30': print }" class="avoid-page-break">
  <div [formGroup]="this.formGroup.get('nutritionalStatus')">
    <div>
      <p class="form-subsection-heading">
        {{
          'nutrition_management.nutritional_status' | translate | sectionNumber : nutritionFormType : NutritionSection.NUTRITIONAL_STATUS
        }}
      </p>
      <stx-nutrition-status-subtitle [nutritionFormType]="nutritionFormType"></stx-nutrition-status-subtitle>
    </div>
    <div class="form-row">
      <stx-checkbox-group-with-text-field
        [layoutDirection]="'column'"
        [options]="nutritionStatusOptions"
        [formGroup]="this.formGroup.get('nutritionalStatus')"
        [formMode]="formMode"
        [print]="print"
        [formGroupErrorNames]="['nutritionalStatusFields']"
        [textFieldControlName]="'otherMore'"
        [textFieldParentControlName]="'other'"
      ></stx-checkbox-group-with-text-field>
    </div>
  </div>
</div>
