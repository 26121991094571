import { ChangeDetectorRef, Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ReportService } from '../../../../components/report-filters/services/report.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PatientLinkComponentModule } from '../../../../components/patient-link/patient-link.component';
import { TreatmentStatusIndicatorComponentModule } from '@shared/components/treatment-status-indicator/treatment-status-indicator.component';
import { ReportTableDataService } from 'src/app/features/reports/components/report-filters/services/report-table-data.service';
import { BaseReportTableComponent } from '../../../../components/base-report-table/base-report-table.component';
import { TreatmentDateComponentModule } from '../../../../components/treatment-date/treatment-date.component';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { SurgicalFollowUpVisitsReportRecord } from '../../surgical-follow-up-visits-report.model';
import { YesNoPipeModule } from '@shared/modules/readonly-commons/pipes/yes-no/yes-no.pipe';
import { surgeryFollowUpFull } from '@utils/routing.utils';
import { SimpleReportCellComponentModule } from '@src/app/features/reports/components/simple-report-cell/simple-report-cell.component';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'stx-surgical-follow-up-visits-report-table',
  templateUrl: './surgical-follow-up-visits-report-table.component.html'
})
export class SurgicalFollowUpVisitsReportTableComponent
  extends BaseReportTableComponent<SurgicalFollowUpVisitsReportRecord>
  implements OnInit, OnDestroy
{
  displayedColumns: string[] = ['treatmentCenter', 'patient', 'surgeryFollowUp'];
  surgeryFollowUpFull = surgeryFollowUpFull;

  constructor(
    public changeDetector: ChangeDetectorRef,
    public reportTableDataService: ReportTableDataService,
    public reportService: ReportService,
    public route: ActivatedRoute
  ) {
    super('SURGICAL_FOLLOW_UP_VISITS', changeDetector, reportTableDataService, reportService, route);
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }
}

@NgModule({
  declarations: [SurgicalFollowUpVisitsReportTableComponent],
  imports: [
    YesNoPipeModule,
    MatTableModule,
    MatSortModule,
    TranslateModule,
    CommonModule,
    MatIconModule,
    RouterModule,
    MatPaginatorModule,
    PatientLinkComponentModule,
    TreatmentStatusIndicatorComponentModule,
    TreatmentDateComponentModule,
    EmptyTableStateComponentModule,
    SimpleReportCellComponentModule,
    FlexModule
  ],
  exports: [SurgicalFollowUpVisitsReportTableComponent]
})
export class SurgicalFollowUpVisitsReportTableComponentModule {}
