import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-dashboard-grade-item',
  templateUrl: './dashboard-grade-item.component.html',
  styleUrls: ['./dashboard-grade-item.component.scss']
})
export class DashboardGradeItemComponent {
  @Input() grade: string;
  @Input() descriptionParagraph: string;
  @Input() bulletedList: string[];
  @Input() paragraphList: string[];
  @Input() boldParagraph: string;
  @Input() tagName: string;
}
