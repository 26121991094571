<div
  class="data-tile-container"
  [ngClass]="{
    'data-tile-container-blue': noticeType === 'info',
    'data-tile-container-green': noticeType === 'success',
    'data-tile-container-yellow': noticeType === 'warn',
    'data-tile-container-red': noticeType === 'error'
  }"
>
  <div class="data-tile-row">
    <div class="data-tile">
      <div class="data-tile-content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
