import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

export abstract class BaseHttpService {
  protected baseUrl: string;

  protected constructor(
    protected httpClient: HttpClient,
    options?: {
      baseUrl?: string;
    }
  ) {
    if (options && options.baseUrl) {
      this.baseUrl = `${environment.apiUrl}/${options.baseUrl}`;
    } else {
      this.baseUrl = environment.apiUrl;
    }
  }
}
