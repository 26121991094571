import { AnimationMetadata, state, style, transition, useAnimation } from '@angular/animations';
import { slideDown, slideUp } from '../core/animations';
import { TIMINGS } from '../core/animations/constant.animations';

export const expandAnimationMetadata: AnimationMetadata[] = [
  state(
    'open',
    style({
      display: '*'
    })
  ),
  state(
    'closed',
    style({
      height: '0',
      overflow: 'hidden'
    })
  ),
  transition('closed => open', [
    useAnimation(slideDown, {
      params: {
        timing: TIMINGS.SLOW
      }
    })
  ]),
  transition('open => closed', [
    useAnimation(slideUp, {
      params: {
        timing: TIMINGS.FAST
      }
    })
  ])
];

export const expandAndRevealAnimationMetadata: AnimationMetadata[] = [
  ...expandAnimationMetadata,
  state(
    'closed',
    style({
      height: '0',
      overflow: 'hidden',
      border: 0,
      visibility: 'hidden'
    })
  )
];
