import { EmailHelpModalComponent } from '@/src/app/features/authentication/components/help-modal/help-modal.component';
import { ResetPasswordModalComponent } from '@/src/app/features/authentication/components/reset-password-modal/reset-password-modal.component';
import { LoginCredentials } from '@/src/app/features/authentication/models/login-credentials.model';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TempLangService } from '@shared/services/localisation/temp-lang.service';
import { AngularEmbeddedValidators } from '@shared/validation/angular-embedded.validators';
import { of } from 'rxjs';
import { SpinnerService } from 'src/app/shared/components/spinner/service/spinner.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';
import { AuthService } from '@src/app/features/authentication/services/auth.service';
import { PasswordExpiryService } from '@src/app/features/authentication/services/password-expiry.service';

@Component({
  selector: 'stx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  hidePassword = true;
  showAuthError: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private currentUserService: CurrentUserService,
    private dialog: MatDialog,
    private router: Router,
    private cd: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private angularEmbeddedValidators: AngularEmbeddedValidators,
    private readonly tempLangService: TempLangService
  ) {}

  ngOnInit() {
    this.initializeLoginForm();
    this.currentUserService.getUserSubject().subscribe({
      next: user => {
        if (user) {
          this.router.navigateByUrl('/');
        } else {
          this.showAuthError = false;
        }
      }
    });
  }

  private initializeLoginForm(): void {
    this.loginForm = this.formBuilder.group({
      email: [null, (control: AbstractControl) => this.angularEmbeddedValidators.required(control)],
      password: [null, (control: AbstractControl) => this.angularEmbeddedValidators.required(control)]
    });
  }

  login: AsyncButtonClickAction = () => {
    if (!this.loginForm.valid) {
      return of(null);
    }
    this.tempLangService.clearTempLanguage();
    this.spinnerService.addTask();
    const credentials: LoginCredentials = {
      email: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value
    };

    return this.authService.loginWithDefaultCredentials(credentials, this.onLoginError.bind(this));
  };

  openResetPasswordModal(): void {
    this.dialog.open(ResetPasswordModalComponent, { autoFocus: false });
  }

  openHelpModal(): void {
    this.dialog.open(EmailHelpModalComponent, { autoFocus: false });
  }

  onLoginError(): void {
    this.showAuthError = true;
    this.spinnerService.removeTask();
    this.cd.detectChanges();
  }
}
