import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import {
  CommonReportDictionaryItem,
  LatestCasesChangePayload,
  ReportFilterChange,
  ReportFiltersParams
} from '../../models/report-filters.model';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

export const latestCasesShowAllValue = '4';

@Component({
  selector: 'stx-latest-cases-filter',
  templateUrl: './latest-cases-filter.component.html',
  styleUrls: ['./latest-cases-filter.component.scss']
})
export class LatestCasesFilterComponent extends BaseComponent implements OnInit {
  private static readonly DEFAULT_VALUE = '3';
  @Input() latestCasesOptions: CommonReportDictionaryItem[];
  @Input() formGroupReference: UntypedFormGroup;
  @Input() controlName: string;
  @Output() filterChange = new EventEmitter<ReportFilterChange<LatestCasesChangePayload>>();

  private latestCasesInitialParam: string;
  private control: AbstractControl;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.control = this.formGroupReference.get(this.controlName);
    this.watchQueryParamsChange();
    this.watchFilterChanges();
    this.setDefaultValue();
  }

  private emitFilterModel(value: string): void {
    this.filterChange.emit({
      filterName: 'latestCases',
      payload: {
        latestCases: [value]
      }
    });
  }

  private setDefaultValue() {
    if (this.latestCasesInitialParam) {
      this.setValueFromParams();
    } else {
      this.control.setValue(LatestCasesFilterComponent.DEFAULT_VALUE);
    }
  }

  private watchFilterChanges() {
    this.subSink.sink = this.control.valueChanges.subscribe(this.emitFilterModel.bind(this));
  }

  private watchQueryParamsChange() {
    this.subSink.sink = this.route.queryParams
      .pipe(filter((params: ReportFiltersParams) => !!params.latestCases))
      .subscribe((params: ReportFiltersParams) => {
        this.latestCasesInitialParam = params.latestCases;

        this.setValueFromParams();
      });
  }

  setValueFromParams() {
    this.control.setValue(this.latestCasesInitialParam);
  }
}

@NgModule({
  declarations: [LatestCasesFilterComponent],
  imports: [CommonModule, FlexLayoutModule, FormsModule, MatRadioModule, ReactiveFormsModule, TranslateModule],
  exports: [LatestCasesFilterComponent]
})
export class LatestCasesFilterComponentModule {}
