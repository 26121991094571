<mat-paginator
  *ngIf="topPagination"
  [length]="totalResults"
  [pageSizeOptions]="pageSizeOptions"
  (page)="changePage($event)"
></mat-paginator>

<div class="data-table-container">
  <div class="data-table-wrapper">
    <table
      matSort
      (matSortChange)="sortData($event)"
      mat-table
      [dataSource]="dataSource"
      class="sticky-first"
      aria-describedby="data-table-container"
    >
      <ng-container *ngFor="let column of columns" [matColumnDef]="column.name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.text }}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element[column.name] && (element[column.name].text || element[column.name].authors); else noLink">
            <div *ngIf="element[column.name].text; else publication">
              <a [class.data-table-inactive]="element[column.name].inactive" [routerLink]="element[column.name].link">
                {{ element[column.name].text }}
              </a>
            </div>
            <ng-template #publication>
              <a [href]="element[column.name].link.link" target="_blank"> {{ element[column.name].link.text }} </a>
              <div class="authors">{{ element[column.name].authors }}</div>
              <div class="publisher-info">{{ element[column.name].publisherInfo }}</div>
            </ng-template>
          </div>
          <ng-template #noLink>
            {{ element[column.name] }}
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>

<mat-paginator [length]="totalResults" [pageSizeOptions]="pageSizeOptions" (page)="changePage($event)"></mat-paginator>
