import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MediaModule } from '../../../features/media/media.module';
import { MediaComponentBaseComponent } from './components/media-component-base/media-component-base.component';
import { UppyAudioVideoComponent } from './components/uppy-audio-video/uppy-audio-video.component';
import { UppyWithPreviewComponent } from './components/uppy-with-preview/uppy-with-preview.component';
import { UppyComponent } from './components/uppy.component';

@NgModule({
  declarations: [UppyComponent, UppyWithPreviewComponent, UppyAudioVideoComponent, MediaComponentBaseComponent],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, MediaModule, MatIconModule, MatTooltipModule],
  exports: [UppyComponent, UppyWithPreviewComponent, UppyAudioVideoComponent]
})
export class UppyModule {}
