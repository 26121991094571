<div class="data-tile">
  <div class="data-tile-content">
    <div class="text-uppercase current-month-title">
      {{ currentMonthYear }}
    </div>
    <div class="current-month-text">
      <div>
        {{ 'dashboard.current_month_text_1' | translate }} {{ numberOfDaysLeftCurrentMonth }}
        {{ 'dashboard.current_month_text_2' | translate }}
      </div>
      <div>
        {{ 'dashboard.current_month_text_3' | translate }}
      </div>
    </div>
    <div class="current-month-links column-until-gt-md-container align-top align-spaced">
      <div>
        <a href="" class="records-link">
          <mat-icon class="m-r-10">done</mat-icon> {{ surgicalStats.surgicalTreatment.accepted.currentMonthCount }}
          {{ 'dashboard.accepted_records' | translate }}
        </a>
      </div>
      <div>
        <a href="" class="records-link">
          <mat-icon class="m-r-10">pause</mat-icon> {{ surgicalStats.surgicalTreatment.inProgress.currentMonthCount }}
          {{ 'dashboard.in_progress_records' | translate }}
        </a>
      </div>
      <div>
        <a href="" class="records-link">
          <mat-icon class="m-r-10">close</mat-icon> {{ surgicalStats.surgicalTreatment.rejected.currentMonthCount }}
          {{ 'dashboard.rejected_records' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
