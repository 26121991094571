import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MaintenanceService } from '@core/maintenance/maintenance.service';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { ErrorPageService } from '@shared/services/error-page.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import {
  httpStatusBadRequest,
  httpStatusConflict,
  httpStatusMethodNotAllowed,
  httpStatusNotAuthorized,
  httpStatusResourceNotFound
} from '@utils/http.utils';
import { SubSink } from 'subsink';
import { ErrorData } from '../components/error-handling/error-data.model';
import { LoggerService, unknownErrorMessage } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService extends ErrorHandler implements OnDestroy {
  private errQueue: ErrorData[] = [];
  private isErrorCurrentlyDisplayed = false;
  subSink = new SubSink();

  constructor(
    private loggerService: LoggerService,
    private readonly snackBarService: SnackBarService,
    private matDialog: MatDialog,
    private spinnerService: SpinnerService,
    private readonly ngZone: NgZone,
    private readonly errorPageService: ErrorPageService
  ) {
    super();
  }
  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  handleError(error: unknown) {
    if (this.shouldErrorBeIgnored(error)) {
      return;
    }
    if (!this.shouldErrorBeLogged(error)) {
      this.spinnerService.clearTasks();
      return;
    }
    this.loggerService.error(!!error ? error : Error(unknownErrorMessage));

    if (GlobalErrorHandlerService.isResourceNotFoundError(error)) {
      return;
    }

    this.openUnexpectedErrorDialog();
  }

  handleErrorAndInformUser(
    error: unknown,
    errorHandlingConfig: { redirectOn404StatusCode: boolean; useAlertDialog?: boolean } = {
      redirectOn404StatusCode: false,
      useAlertDialog: false
    },
    errorData?: ErrorData
  ) {
    if (this.shouldErrorBeIgnored(error)) {
      return;
    }

    if (errorHandlingConfig.redirectOn404StatusCode && GlobalErrorHandlerService.isResourceNotFoundError(error)) {
      this.errorPageService.redirectToNotFoundComponent();
      return;
    }

    if (this.shouldErrorBeLogged(error)) {
      this.loggerService.error(error);
    }

    if (this.isExpectedApiError(error)) {
      errorHandlingConfig.useAlertDialog = true;
    }

    this.errQueue.push(!!errorData ? errorData : this.getErrorData(error));

    if (!this.isErrorCurrentlyDisplayed) {
      this.showNext(errorHandlingConfig.useAlertDialog);
    }
  }

  handleErrorAndInformUserWithAlertDialog(error: unknown, errorData?: ErrorData) {
    this.handleErrorAndInformUser(error, { redirectOn404StatusCode: false, useAlertDialog: true }, errorData);
  }

  private onErrorDismissed() {
    this.isErrorCurrentlyDisplayed = false;
    this.showNext();
  }

  private showNext(useAlertDialog: boolean = false) {
    if (this.errQueue.length === 0) {
      return;
    }

    const errorData = this.errQueue.shift();
    this.isErrorCurrentlyDisplayed = true;

    if (useAlertDialog) {
      const alertDialogRef = this.matDialog.open(AlertComponent, { autoFocus: true, data: errorData });
      this.subSink.sink = alertDialogRef.afterClosed().subscribe(() => this.onErrorDismissed());
    } else {
      const snackBarRef = this.snackBarService.showError(errorData);
      this.subSink.sink = snackBarRef.afterDismissed().subscribe(() => this.onErrorDismissed());
    }
  }

  private openUnexpectedErrorDialog(): void {
    this.ngZone.run(() => this.matDialog.open(AlertComponent));
  }

  private getErrorData(error: any): ErrorData {
    const genericTitle = 'error.something_wrong_title';
    const genericMsg = 'error.something_wrong_message';
    const receivedMsg = error?.error?.translationKey ?? null;
    if (error?.status) {
      switch (error.status) {
        case 400:
          return { title: genericTitle, message: receivedMsg ?? genericMsg };
        case 401:
          return { title: 'error.unauthorized_title', message: receivedMsg ?? 'error.unauthorized_message' };
        case 405:
          return { title: 'error.treatment_action', message: receivedMsg ?? genericMsg };
        case 415:
          return { title: 'error.unsupported_media_title', message: receivedMsg ?? 'error.unsupported_media_message' };
        default:
          return { title: genericTitle, message: receivedMsg ?? genericMsg };
      }
    } else {
      return { title: genericTitle, message: receivedMsg ?? genericMsg };
    }
  }

  // the error will be logged in sentry
  private shouldErrorBeLogged(error: unknown) {
    return !this.isExpectedApiError(error);
  }

  // the error will not be logged anywhere and the user won't see an error message
  private shouldErrorBeIgnored(error: unknown) {
    return MaintenanceService.isStxMaintenanceMessage(error);
  }

  private isExpectedApiError(error: unknown): boolean {
    return (
      GlobalErrorHandlerService.isAuthorizationError(error) ||
      GlobalErrorHandlerService.isMethodNotAllowedError(error) ||
      GlobalErrorHandlerService.isBadRequestError(error) ||
      GlobalErrorHandlerService.isConflictError(error)
    );
  }

  private static isBadRequestError(error: unknown) {
    return error instanceof HttpErrorResponse && error?.status === httpStatusBadRequest;
  }

  private static isAuthorizationError(error: unknown) {
    return error instanceof HttpErrorResponse && error?.status === httpStatusNotAuthorized;
  }

  public static isMethodNotAllowedError(error: unknown) {
    return error instanceof HttpErrorResponse && error?.status === httpStatusMethodNotAllowed;
  }

  public static isResourceNotFoundError(error: unknown) {
    return error instanceof HttpErrorResponse && error?.status === httpStatusResourceNotFound;
  }

  private static isConflictError(error: unknown) {
    return error instanceof HttpErrorResponse && error?.status === httpStatusConflict;
  }
}
