import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionEnum } from 'src/app/core/permissions/permission.enum';
import { Subscription } from 'rxjs';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

@Directive({ selector: '[stxPermitted]' })
export class PermittedDirective implements OnDestroy {
  private hasView = false;
  currentUserSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private currentUserService: CurrentUserService
  ) {}
  ngOnDestroy(): void {
    if (this.currentUserSubscription !== undefined) {
      this.currentUserSubscription.unsubscribe();
    }
  }

  @Input() set stxPermitted(permission: PermissionEnum) {
    this.currentUserSubscription = this.currentUserService
      .getUserSubject()
      .asObservable()
      .subscribe(user => {
        if (user) {
          const userPermissions = user.permissions.map(x => x.name);
          const isAllowed = userPermissions.includes(permission);
          if (isAllowed && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
          } else if (!isAllowed && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
          }
        }
      });
  }
}
