<stx-modal>
  <ng-container>
    <div class="warning-container flex-row-container align-top align-middle">
      <mat-icon class="warning-icon">warning</mat-icon>
      <div class="flex-column-container align-top">
        <div>{{ 'info.password_expiry' | translate }}</div>
        <div>{{ 'info.password_expiry_contact_admin' | translate }}</div>
      </div>
    </div>
    <stx-action-buttons
      (primaryAction)="navigateToChangePassword()"
      [primaryText]="'settings.change_password' | translate"
      (secondaryAction)="closePasswordExpiryPopup()"
      [secondaryText]="'info.change_later' | translate"
    ></stx-action-buttons>
  </ng-container>
</stx-modal>
