import { DictionaryTranslation } from '../features/patient/models/patient.model';

export const nasalEmissionOptions: DictionaryTranslation[] = [
  {
    name: 'nasalEmissionNone',
    label: 'speech_assessment.nasal_emission_none',
    value: false,
    tooltipText: 'speech_assessment.tooltip.nasal_emission_none'
  },
  {
    name: 'nasalEmissionAmplified',
    label: 'speech_assessment.nasal_emission_audible',
    value: false,
    tooltipText: 'speech_assessment.tooltip.nasal_emission_amplified'
  },
  {
    name: 'nasalEmissionPhoneme',
    label: 'speech_assessment.nasal_emission_phoneme_articulation_errors',
    value: false,
    tooltipText: 'speech_assessment.tooltip.nasal_emission_phoneme'
  }
];

export const phonemeOptions: DictionaryTranslation[] = [
  { name: 'nasalEmissionPhonemeS', label: 'speech_assessment.nasal_emission_phoneme_articulation_errors_yes_s', value: false },
  { name: 'nasalEmissionPhonemeZ', label: 'speech_assessment.nasal_emission_phoneme_articulation_errors_yes_z', value: false },
  { name: 'nasalEmissionPhonemeSh', label: 'speech_assessment.nasal_emission_phoneme_articulation_errors_yes_sh', value: false },
  { name: 'nasalEmissionPhonemeZh', label: 'speech_assessment.nasal_emission_phoneme_articulation_errors_yes_zh', value: false },
  { name: 'nasalEmissionPhonemeCh', label: 'speech_assessment.nasal_emission_phoneme_articulation_errors_yes_ch', value: false },
  { name: 'nasalEmissionPhonemeJ', label: 'speech_assessment.nasal_emission_phoneme_articulation_errors_yes_j', value: false },
  { name: 'nasalEmissionPhonemeOther', label: 'other', value: false }
];

export const soundAssessmentOptions: DictionaryTranslation[] = [
  {
    name: 'obligatoryDistortionDentition',
    label: 'speech_assessment.obligatory_distortion_dentition',
    value: false,
    tooltipText: 'speech_assessment.tooltip.obligatory_distortion_dentition'
  },
  {
    name: 'obligatoryDistortionVpd',
    label: 'speech_assessment.obligatory_distortion_vpd',
    value: false
  }
];

export const vpdOptions: DictionaryTranslation[] = [
  {
    name: 'compensatoryGlottalStop',
    label: 'speech_instructions.assessment.sound.compensatory_1',
    value: false,
    tooltipText: 'speech_instructions.assessment.sound.compensatory_1_comment'
  },
  {
    name: 'compensatoryPharyngealStop',
    label: 'speech_instructions.assessment.sound.compensatory_2',
    value: false,
    tooltipText: 'speech_instructions.assessment.sound.compensatory_2_comment'
  },
  {
    name: 'compensatoryPharyngealFricative',
    label: 'speech_instructions.assessment.sound.compensatory_3',
    value: false,
    tooltipText: 'speech_instructions.assessment.sound.compensatory_3_comment'
  },
  {
    name: 'compensatoryNasalFricative',
    label: 'speech_instructions.assessment.sound.compensatory_4',
    value: false,
    tooltipText: 'speech_instructions.assessment.sound.compensatory_4_comment'
  },
  {
    name: 'compensatoryMidDorsumPalatalStop',
    label: 'speech_instructions.assessment.sound.compensatory_5',
    value: false,
    tooltipText: 'speech_instructions.assessment.sound.compensatory_5_comment'
  },
  {
    name: 'compensatoryGlottalFricative',
    label: 'speech_instructions.assessment.sound.compensatory_6',
    value: false,
    tooltipText: 'speech_instructions.assessment.sound.compensatory_6_comment'
  }
];

export const placementErrorsOptions: DictionaryTranslation[] = [
  { name: 'placementErrorPb', label: 'speech_assessment.placement_error_pb', value: false },
  { name: 'placementErrorTd', label: 'speech_assessment.placement_error_td', value: false },
  { name: 'placementErrorKg', label: 'speech_assessment.placement_error_kg', value: false },
  { name: 'placementErrorFv', label: 'speech_assessment.placement_error_fv', value: false },
  { name: 'placementErrorSz', label: 'speech_assessment.placement_error_sz', value: false },
  { name: 'placementErrorSh', label: 'speech_assessment.placement_error_sh', value: false },
  { name: 'placementErrorCh', label: 'speech_assessment.placement_error_ch', value: false }
];
