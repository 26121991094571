import { PersonalData } from '@/src/app/features/user/models/user-data.model';
import { StxValidators } from '@/src/app/shared/validation/validators';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectOption, SelectOptions } from '@shared/components/form/components/select/select.model';
import { FormMode } from '@utils/form.utils';
import { User } from 'src/app/shared/models/user/user.model';
import { getLanguageByShortcut } from 'src/app/utils/language.utils';

@Component({
  selector: 'stx-user-personal-data',
  templateUrl: './user-personal-data.component.html',
  styleUrls: ['./user-personal-data.component.scss']
})
export class UserPersonalDataComponent implements OnInit {
  readonly editFormMode = FormMode.EDIT;
  personalDataForm: UntypedFormGroup;
  languagesList: SelectOptions;
  activeInactiveList: SelectOptions;

  @Input()
  set userData(userData: User) {
    if (userData) {
      this.personalDataForm.controls.email.disable();
      this.personalDataForm.patchValue(userData);
      this.personalDataForm.controls.lang.setValue(getLanguageByShortcut(userData.lang).name);
    }
  }
  constructor(private formBuilder: UntypedFormBuilder, public translateService: TranslateService) {}

  get userEmail(): string | undefined {
    return this.personalDataForm?.get('email')?.value;
  }

  ngOnInit() {
    this.initializePersonalDataForm();
    this.initializeSelectOptions();
  }

  private initializeSelectOptions() {
    this.languagesList = this.translateService.getLangs().map(lang => {
      return { id: lang, name: lang } as SelectOption;
    });
    this.activeInactiveList = [
      {
        id: true,
        name: this.translateService.instant('active')
      } as SelectOption,
      {
        id: false,
        name: this.translateService.instant('inactive')
      } as SelectOption
    ];
  }

  public getValidPersonalData(): PersonalData {
    let personalData: PersonalData;
    if (this.personalDataForm.valid) {
      personalData = this.personalDataForm.value;
    } else {
      personalData = null;
      this.personalDataForm.markAllAsTouched();
    }
    return personalData;
  }

  private initializePersonalDataForm(): void {
    this.personalDataForm = this.formBuilder.group({
      firstName: [null, [StxValidators.required, Validators.maxLength(32)]],
      lastName: [null, [StxValidators.required, Validators.maxLength(32)]],
      middleName: [null, Validators.maxLength(32)],
      email: [null, [StxValidators.required, Validators.email, Validators.minLength(6), Validators.maxLength(255)]],
      lang: ['English', StxValidators.required],
      enabled: [true, StxValidators.required]
    });
  }
}
