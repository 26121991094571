<stx-ortho-shared-general-info
  #sharedGeneralInfo
  [tcList]="tcList"
  [orthoFormType]="orthoFormType"
  [orthoStage]="OrthoStage.TREATMENT"
  [minCareProvidedDate]="minCareProvidedDate"
  [maxCareProvidedDate]="maxCareProvidedDate"
  [minCareProvidedEndDateOverride]="minCareProvidedEndDate"
  [print]="print"
  [formMode]="formMode"
></stx-ortho-shared-general-info>
<ng-content></ng-content>

<hr class="form-subsection-separator" />

<div class="form-row">
  <stx-radio-group
    [label]="'presurgical.complication'"
    [layoutDirection]="'row'"
    [controlName]="'complication'"
    [formMode]="formMode"
    [options]="yesNo"
    [print]="print"
  ></stx-radio-group>
  <stx-textarea [label]="'if_yes_explain'" [controlName]="'complicationMore'" [formMode]="formMode"> </stx-textarea>
</div>
