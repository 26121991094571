import { AuthService } from '@/src/app/features/authentication/services/auth.service';
import { StxValidators } from '@/src/app/shared/validation/validators';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'stx-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModalComponent implements OnInit {
  emailForm: UntypedFormGroup;
  isEmailSet = false;

  constructor(private authService: AuthService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.initializeEmailForm();
  }

  initializeEmailForm(): void {
    this.emailForm = this.formBuilder.group({
      emailFormControl: [null, [StxValidators.required, Validators.email]]
    });
  }

  sendResetLink: AsyncButtonClickAction = () => {
    if (!this.emailForm.valid) {
      return of(null);
    }
    return this.authService.sendResetPasswordLink(this.emailForm.controls.emailFormControl.value).pipe(
      tap({
        next: () => {
          this.isEmailSet = true;
        }
      })
    );
  };
}
