import { DictionaryTranslation, Patient, patientIdAsNumber } from '@/src/app/features/patient/models/patient.model';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { SurgicalIntervention } from '@/src/app/features/surgical/models/surgical-intervention.model';
import { getSurgicalPhotos } from '@/src/app/features/surgical/utils/surgical.utils';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import {
  statusBarConfig,
  StatusConfigData
} from '@/src/app/shared/components/treatment/components/treatment-status-bar/treatment-status-bar.utils';
import { MediaSet } from '@/src/app/shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';
import { ReadonlyFormUtils } from '@/src/app/utils/form.utils';
import { intervention, patientHistoryPath, surgicalRoute } from '@/src/app/utils/routing.utils';
import { mapToStatusEnum } from '@/src/app/utils/treatment.utils';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { CountryName } from '@src/app/features/suspicious-records/models/suspicious-records-and-frauds.model';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Component({
  selector: 'stx-suspicious-record',
  templateUrl: './suspicious-record.component.html',
  styleUrls: ['./suspicious-record.component.scss']
})
export class SuspiciousRecordComponent extends BaseComponent implements OnInit {
  readonly ReadonlyFormUtils = ReadonlyFormUtils;
  readonly patientOptions: DictionaryTranslation[] = [
    {
      value: true,
      label: 'suspicious_records.same_patient'
    },
    {
      value: false,
      label: 'suspicious_records.not_the_same_patient'
    }
  ];

  countryName: WritableSignal<CountryName> = signal(null);

  @Input() index: number;
  @Input() patient: Patient;
  @Input() set surgery(surgery: SurgicalIntervention) {
    if (surgery) {
      this._surgery = surgery;
      this.onSurgeryUpdated(surgery);
    }
  }

  @Input() set country(countryName: CountryName) {
    if (countryName) {
      this.countryName.set(countryName);
    }
  }

  @Input() problems: DictionaryTranslation[];
  @Input() includesActions: boolean;
  @Input() isPotentialMatch: boolean;
  @Output() isRejected = new EventEmitter<boolean>();

  get surgery() {
    return this._surgery;
  }

  private _surgery: SurgicalIntervention | null;

  operationTypes: string[] = [];
  repairTypes: string[] = [];
  treatmentStatus: StatusConfigData;
  guardianRelationships: DictionaryTranslation[];
  genders: DictionaryTranslation[];
  actionFormGroup: UntypedFormGroup;
  lipNoseRevision: DictionaryTranslation[];
  primaryCleftPalate: DictionaryTranslation[];
  fistulaRepair: DictionaryTranslation[];
  secondaryCleftPalate: DictionaryTranslation[];
  velopharyngealDysfunction: DictionaryTranslation[];
  alveolarBoneGraft: DictionaryTranslation[];
  other: DictionaryTranslation[];
  primaryLipNoseUnilateral: DictionaryTranslation[];
  primaryLipNoseBilateral: DictionaryTranslation[];
  separator = '|';
  photos: MediaSet[];

  constructor(
    private cd: ChangeDetectorRef,
    private patientService: PatientService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.includesActions) {
      this.initializeForm();
    }
    this.rejectDuplicateSubscribe();
  }

  private onSurgeryUpdated(surgery: SurgicalIntervention) {
    this.updateStatus(surgery.status);
    this.getStaticDictionariesAndUpdateView(surgery);
    this.photos = getSurgicalPhotos(surgery);
    this.cd.markForCheck();
  }

  get printPatientRoute(): string {
    return patientHistoryPath(this.patient.id);
  }

  get problemLabels(): string[] {
    return this.problems?.map(problem => this.translateService.instant(problem.label));
  }

  private initializeForm(): void {
    this.actionFormGroup = this.formBuilder.group({
      isDuplicate: [false],
      isSamePatient: [null]
    });
  }

  private rejectDuplicateSubscribe(): void {
    this.subSink.sink = this.actionFormGroup?.get('isDuplicate').valueChanges.subscribe(value => {
      this.isRejected.emit(value);
    });
  }

  getDupId(): TreatmentId {
    if (this.actionFormGroup?.get('isDuplicate').value) {
      return this.surgery.id;
    }
  }

  getDupPatientId(): number {
    if (this.actionFormGroup?.get('isSamePatient') && this.actionFormGroup.get('isSamePatient').value)
      return patientIdAsNumber(this.patient.id); // TODO#46379
  }

  getNotDupPatientId(): number {
    if (this.actionFormGroup?.get('isSamePatient') && this.actionFormGroup.get('isSamePatient').value === false) return;
    patientIdAsNumber(this.patient.id); // TODO#46379
  }

  patientLocalNameIsDifferent(): boolean {
    return (
      (this.patient.firstName !== this.patient.firstNameLoc && !!this.patient.firstNameLoc) ||
      (this.patient.middleName !== this.patient.middleNameLoc && !!this.patient.middleNameLoc) ||
      (this.patient.lastName !== this.patient.lastNameLoc && !!this.patient.lastNameLoc)
    );
  }

  patientLocalRecordNoIsDifferent(): boolean {
    return this.patient.recordNumberId !== this.patient.recordNumberIdLoc && this.patient.recordNumberIdLoc?.length > 0;
  }

  tcLocalNameIsDifferent(): boolean {
    return this.surgery.treatmentCenter.name !== this.surgery.treatmentCenter.nameLoc && this.surgery.treatmentCenter.nameLoc?.length > 0;
  }

  private updateStatus(statusValue: number): void {
    const treatmentStatusEnum = mapToStatusEnum(statusValue);
    if (statusBarConfig.has(treatmentStatusEnum)) {
      this.treatmentStatus = statusBarConfig.get(treatmentStatusEnum).statusConfig;
    }
  }

  private getStaticDictionariesAndUpdateView(surgery: SurgicalIntervention): void {
    this.wsHelperService.call(this.patientService.getStaticDictionaries()).subscribe(dictionaries => {
      this.guardianRelationships = dictionaries.get('guardianRelationship');
      this.genders = dictionaries.get('gender');
      this.lipNoseRevision = dictionaries.get('lipNoseRevision');
      this.primaryCleftPalate = dictionaries.get('primaryCleftPalate');
      this.fistulaRepair = dictionaries.get('fistulaRepair');
      this.secondaryCleftPalate = dictionaries.get('secondaryCleftPalate');
      this.velopharyngealDysfunction = dictionaries.get('velopharyngealDysfunction');
      this.alveolarBoneGraft = dictionaries.get('alveolarBoneGraft');
      this.other = dictionaries.get('other');
      this.primaryLipNoseUnilateral = dictionaries.get('primaryLipNoseUnilateral');
      this.primaryLipNoseBilateral = dictionaries.get('primaryLipNoseBilateral');
      this.setOperationAndRepairTypes(surgery);
      this.cd.detectChanges();
    });
  }

  private setOperationAndRepairTypes(surgery: SurgicalIntervention): void {
    const operationTypes: string[] = [];
    const repairTypes: string[] = [];
    if (surgery.lipNoseRevision) {
      operationTypes.push('surgical_intervention.lip_nose_revision');
      repairTypes.push(ReadonlyFormUtils.getRadioBtnValue(this.lipNoseRevision, surgery.lipNoseRevisionRepairType));
    }
    if (surgery.primaryCleftPalate) {
      operationTypes.push('surgery.primary_cleft_palate');
      repairTypes.push(ReadonlyFormUtils.getRadioBtnValue(this.primaryCleftPalate, surgery.primaryCleftPalateRepairType));
    }
    if (surgery.alveolarBoneGraft) {
      operationTypes.push('surgical_intervention.alveolar_bone_graft');
      repairTypes.push(ReadonlyFormUtils.getRadioBtnValue(this.alveolarBoneGraft, surgery.alveolarBoneGraftRepairType));
    }
    if (surgery.primaryLipNoseBilateral) {
      operationTypes.push('surgery.primary_lip_nose_bilateral');
      repairTypes.push(ReadonlyFormUtils.getRadioBtnValue(this.primaryLipNoseBilateral, surgery.primaryLipNoseBilateralRepairType));
    }
    if (surgery.primaryLipNoseUnilateral) {
      operationTypes.push('surgery.primary_lip_nose_unilateral');
      repairTypes.push(ReadonlyFormUtils.getRadioBtnValue(this.primaryLipNoseUnilateral, surgery.primaryLipNoseUnilateralRepairType));
    }
    if (surgery.fistula) {
      operationTypes.push('surgical_intervention.fistula_repair');
      repairTypes.push(ReadonlyFormUtils.getRadioBtnValue(this.fistulaRepair, surgery.fistulaRepairType));
    }
    if (surgery.velopharyngealDisfunction) {
      operationTypes.push('surgical_intervention.velopharyngeal_disfunction');
      repairTypes.push(ReadonlyFormUtils.getRadioBtnValue(this.velopharyngealDysfunction, surgery.velopharyngealDisfunctionRepairType));
    }
    if (surgery.secondaryCleftPalate) {
      operationTypes.push('surgery.secondary_cleft_palate');
      repairTypes.push(ReadonlyFormUtils.getRadioBtnValue(this.secondaryCleftPalate, surgery.secondaryCleftPalateRepairType));
    }
    this.operationTypes = operationTypes;
    this.repairTypes = repairTypes;
  }

  surgeryRoute(treatmentId: TreatmentId): (string | number)[] {
    return ['/', surgicalRoute, intervention, treatmentId];
  }
}
