import { Injectable } from '@angular/core';

const tempLangLocalStorageKey = 'tempLang';

@Injectable({
  providedIn: 'root'
})
export class TempLangService {
  setTempLanguage(tempLang: string | null) {
    if (!tempLang) {
      this.clearTempLanguage();
    } else {
      localStorage.setItem(tempLangLocalStorageKey, tempLang);
    }
  }

  clearTempLanguage() {
    localStorage.removeItem(tempLangLocalStorageKey);
  }

  getTempLanguage(): string | null {
    return localStorage.getItem(tempLangLocalStorageKey);
  }
}
