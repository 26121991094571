<div class="base-input migrated" [ngClass]="theme === 'accent' ? 'theme-accent' : ''">
  <mat-form-field appearance="outline" class="full-width">
    <input
      data-testid="input-base"
      matInput
      [id]="inputId"
      [type]="inputType"
      [placeholder]="inputPlaceholder"
      [disabled]="inputDisabled"
      [value]="value"
      (paste)="pasteEvent.emit($event)"
      (input)="inputEvent.emit($event)"
      autocomplete="off"
      (blur)="blurEvent.emit($event)"
    />
    <span matSuffix>
      <ng-content select="[baseInputSuffix]"></ng-content>
    </span>
  </mat-form-field>
</div>
