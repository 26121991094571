<stx-generic-page>
  <ng-container [ngSwitch]="errType">
    <ng-template [ngSwitchCase]="errorTypeEnum.URL_NOT_FOUND">
      <h1>{{ 'error.url_not_found' | translate }}</h1>
    </ng-template>
    <ng-template [ngSwitchCase]="errorTypeEnum.RESOURCE_NOT_FOUND">
      <h1>{{ 'error.resource_not_found' | translate }}</h1>
    </ng-template>
  </ng-container>
</stx-generic-page>
