<div (dragover)="preventOpenForDragOver($event)" (drop)="onDrop($event)" class="media-wrapper">
  <img *ngIf="showPlaceholder" src="assets/images/placeholder_video_mandatory.png" alt="media placeholder" />

  <stx-video-player
    *ngIf="showVideoPlayer"
    [urlRaw]="rawUrl"
    [urlConverted]="convertedUrl"
    [newUploadPreviewUrl]="uploadedFileUrlSanitized"
    [showFileDownload]="showFileDownload"
  ></stx-video-player>

  <stx-audio-player
    *ngIf="showAudioPlayer"
    [urlRaw]="rawUrl"
    [urlConverted]="convertedUrl"
    [newUploadPreviewUrl]="uploadedFileUrlSanitized"
    [showFileDownload]="showFileDownload"
  ></stx-audio-player>
</div>

<div *ngIf="showUploadedFileName" class="bold m-t-10 m-b-10">{{ uploadedFileName }} [100%]</div>

<stx-uppy
  *ngIf="formMode !== FormMode.READONLY"
  class="uppy-image uppy-video-info"
  [height]="115"
  [types]="audioVideoAcceptedTypes"
  [hintNote]="'upload_box.video_hint' | translate"
  [uuid]="uuid"
  (uploadSuccessful)="uploadedFile($event)"
  (displayFile)="displayFile($event)"
  (touched)="touched()"
  (uploadCancel)="restoreOldPreview()"
  (uploadError)="uploadError()"
></stx-uppy>
