import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { Component, NgModule, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReportDownloaderComponentModule } from '../../components/report-downloader/report-downloader.component';
import { ReportConfig, ReportDownloadTypes } from '../../components/report-filters/models/report-filters.model';
import { ReportFiltersModule } from '../../components/report-filters/report-filters.component';
import { ReportTableDataService } from '../../components/report-filters/services/report-table-data.service';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import {
  SurgicalTreatmentReportTableComponent,
  SurgicalTreatmentReportTableComponentModule
} from './components/surgical-treatment-report-table/surgical-treatment-report-table.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';

@Component({
  selector: 'stx-surgical-treatments-report',
  templateUrl: './surgical-treatments-report.component.html'
})
export class SurgicalTreatmentsReportComponent {
  readonly reportConfig: ReportConfig = {
    type: 'SURGICAL_TREATMENTS',
    downloadOptions: {
      [ReportDownloadTypes.EXCEL]: true,
      [ReportDownloadTypes.EXCEL_ADMIN]: true,
      [ReportDownloadTypes.EXCEL_ADMIN_ID]: true
    },
    filters: {
      status: true,
      age: true,
      gender: true,
      geography: true,
      includePhotos: true,
      organizationsTree: true,
      recordDate: true,
      referringOrganization: true,
      reportedComplications: true,
      qualityAssurance: true,
      surgeons: true,
      surgicalIntervention: true,
      repairTypes: true,
      treatmentNotCompliant: true
    }
  };

  @ViewChild(SurgicalTreatmentReportTableComponent) surgicalTreatmentReportTableComponent: SurgicalTreatmentReportTableComponent;

  constructor(public reportService: ReportService, private reportTableDataService: ReportTableDataService) {}

  applyFilters(pagination: PaginationSortingModel) {
    this.surgicalTreatmentReportTableComponent.updateDisplayedColumns();
    this.reportTableDataService.prepareList('SURGICAL_TREATMENTS', this.reportService.model, pagination);
  }
}

@NgModule({
  declarations: [SurgicalTreatmentsReportComponent],
  imports: [
    ReportFiltersModule,
    TranslateModule,
    ReportDownloaderComponentModule,
    CommonModule,
    SurgicalTreatmentReportTableComponentModule,
    ReportHeaderComponentModule,
    GeneralCommonsModule
  ],
  exports: [SurgicalTreatmentsReportComponent]
})
export class SurgicalTreatmentsReportModule {}
