<div class="data-table-wrapper">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z2" aria-describedby="data-table-wrapper">
    <ng-container *ngFor="let column of columns; first as isFirst" [matColumnDef]="column.name" [sticky]="isFirst && !isMobileScreenSize">
      <th class="text-capitalize wrap-content" mat-header-cell *matHeaderCellDef>
        {{ column.text }}
        <ng-container *ngIf="column.nextLines">
          <ng-container *ngFor="let nextLine of column.nextLines">
            <ng-container *ngIf="column.text && nextLine"> &mdash; </ng-container> {{ nextLine.text | lowercase }}
          </ng-container>
        </ng-container>
      </th>
      <td mat-cell *matCellDef="let element">
        <div>
          <ng-container *ngIf="column.name === 'year' || column.name === 'month'; else formatNumber">
            {{ element[column.name] }}
          </ng-container>
          <ng-template #formatNumber>
            {{ element[column.name] | formatNumber }}
          </ng-template>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
