import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@shared/modules/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NoticeComponentModule } from '@shared/components/notice/notice.component';

@NgModule({
  declarations: [VideoPlayerComponent, AudioPlayerComponent],
  imports: [CommonModule, FlexLayoutModule, MaterialModule, TranslateModule.forChild(), NoticeComponentModule],
  exports: [VideoPlayerComponent, AudioPlayerComponent]
})
export class MediaModule {}
