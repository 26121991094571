import { OrthodonticInterventionTypes } from '@/src/app/features/reports/features/orthodontic-treatments-report/orthodontic-treatments-report.model';
import { UntypedFormGroup } from '@angular/forms';
import { OrthoStage } from '@src/app/features/ortho/utils/ortho-stage';

export const orthoTypeToTranslationKeyMap: Record<OrthodonticInterventionTypes, string> = {
  [OrthodonticInterventionTypes.mixedDentition]: 'reports.orthodontic.treatment.mixed_dentition',
  [OrthodonticInterventionTypes.permanentDentition]: 'reports.orthodontic.treatment.permanent_dentition',
  [OrthodonticInterventionTypes.presurgicalInfantOrthopedics]: 'reports.orthodontic.treatment.presurgical_orthopedics'
};

export const orthoStageToTranslationMap: Map<OrthoStage, string> = new Map<OrthoStage, string>()
  .set(OrthoStage.ASSESSMENT, 'ortho.assessment')
  .set(OrthoStage.MID_TREATMENT_ASSESSMENT, 'ortho.midway')
  .set(OrthoStage.TREATMENT, 'ortho.treatment');

export class OrthoFormUtils {
  static complicationsOccurredSelected(formGroup: UntypedFormGroup) {
    return formGroup.get('complication').value === 1;
  }

  static discontinuedSelected(formGroup: UntypedFormGroup) {
    return formGroup.get('isAbandoned').value === true;
  }
}
