import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionModule } from 'src/app/core/permissions/permission.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { PaperRecordsComponent } from './components/paper-records/paper-records.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { FormModule } from '@shared/components/form/form.module';
import { PatientLinkComponentModule } from './components/patient-link/patient-link.component';
import { ReportDownloaderComponentModule } from './components/report-downloader/report-downloader.component';
import { GroupByScopeComponentModule } from './components/report-filters/components/group-by-scope/group-by-scope.component';
import { ReportFiltersModule } from './components/report-filters/report-filters.component';
import { MonthEndReportComponentModule } from './features/month-end-report/month-end-report.component';
import { OrthodonticTreatmentCompactReportComponentModule } from './features/orthodontic-treatments-compact-report/orthodontic-treatments-compact-report.component';
import { OrthodonticTreatmentReportComponentModule } from './features/orthodontic-treatments-report/orthodontic-treatment-report.component';
import { OrthognathicTreatmentsReportModule } from './features/orthognathic-treatments-report/orthognathic-treatments-report.component';
import { PhotoReportComponentModule } from './features/photo-report/photo-report.component';
import { QaEvaluatorReviewsReportComponentModule } from './features/qa-evaluator-reviews-report/qa-evaluator-reviews-report.component';
import { QaOrthoCasesComponentModule } from './features/qa-ortho-cases/qa-ortho-cases.component';
import { QaReviewerReliabilityReportComponentModule } from './features/qa-reviewer-reliability-report/qa-reviewer-reliability-report.component';
import { SpeechStatsReportComponentModule } from './features/speech-stats-report/speech-stats-report.component';
import { SurgeryStatsReportComponentModule } from './features/surgery-stats/surgery-stats-report.component';
import { SurgicalFollowUpVisitsReportComponentModule } from './features/surgical-follow-up-visits-report/surgical-follow-up-visits-report.component';
import { SurgicalTreatmentsCompactReportModule } from './features/surgical-treatments-compact-report/surgical-treatments-compact-report.component';
import { SurgicalTreatmentsReportModule } from './features/surgical-treatments-report/surgical-treatments-report.component';
import { ReportsComponent } from './reports.component';
import { PartnerSurgeryCountsReportComponentModule } from './features/partner-surgery-counts-report/partner-surgery-counts-report.component';
import { ReportLinkListComponent } from './components/report-link-list/report-link-list.component';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { TreatmentViolationComponentModule } from './components/treatment-violation/treatment-violations.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CheckboxGroupFilterComponentModule } from '@src/app/features/reports/components/report-filters/components/checkbox-group-filter/checkbox-group-filter.component';
import { SearchModule } from '@src/app/features/search/search.module';
import { ReportBooleanFilterComponentModule } from '@src/app/features/reports/components/report-filters/components/report-boolean-filter/report-boolean-filter.component';
import { CheckboxTreeFilterComponentModule } from '@src/app/features/reports/components/report-filters/components/checkbox-tree-filter/checkbox-tree-filter.component';
import { SpeechTreatmentReportComponentModule } from './features/speech-treatments-report/speech-treatment-raport/speech-treatment-report.component';
import { SpeechAssessmentReportComponentModule } from './features/speech-treatments-report/speech-assessment-raport/speech-assessment-report.component';

@NgModule({
  declarations: [ReportsComponent, PaperRecordsComponent, ReportLinkListComponent],
  imports: [
    CommonModule,
    PermissionModule,
    TranslateModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule,
    ReportFiltersModule,
    FormModule,
    SurgicalTreatmentsCompactReportModule,
    SurgicalTreatmentsReportModule,
    SurgicalFollowUpVisitsReportComponentModule,
    SurgeryStatsReportComponentModule,
    GroupByScopeComponentModule,
    MonthEndReportComponentModule,
    OrthognathicTreatmentsReportModule,
    PatientLinkComponentModule,
    ReportDownloaderComponentModule,
    QaOrthoCasesComponentModule,
    SpeechStatsReportComponentModule,
    PhotoReportComponentModule,
    QaReviewerReliabilityReportComponentModule,
    OrthodonticTreatmentReportComponentModule,
    OrthodonticTreatmentCompactReportComponentModule,
    QaEvaluatorReviewsReportComponentModule,
    SpeechTreatmentReportComponentModule,
    SpeechAssessmentReportComponentModule,
    PartnerSurgeryCountsReportComponentModule,
    GeneralCommonsModule,
    FontAwesomeModule,
    TreatmentViolationComponentModule,
    CheckboxGroupFilterComponentModule,
    SearchModule,
    ReportBooleanFilterComponentModule,
    CheckboxTreeFilterComponentModule
  ],
  exports: [PaperRecordsComponent, ReportLinkListComponent]
})
export class ReportsModule {}
