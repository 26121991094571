import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsCommonsModule } from '@shared/modules/forms-commons/forms-commons.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { OrthognaticTreatmentReadonlyComponent } from '@src/app/features/orthognathic/components/orthognatic-treatment-readonly/orthognatic-treatment-readonly.component';
import { FormModule } from 'src/app/shared/components/form/form.module';
import { TreatmentModule } from 'src/app/shared/components/treatment/treatment.module';
import { UppyModule } from 'src/app/shared/components/uppy/uppy.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { OrthognathicTreatmentComponent } from './components/orthognathic-treatment/orthognathic-treatment.component';

@NgModule({
  declarations: [OrthognathicTreatmentComponent, OrthognaticTreatmentReadonlyComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormModule,
    FormsCommonsModule,
    MaterialModule,
    ReactiveFormsModule,
    ReadonlyCommonsModule,
    TranslateModule.forChild(),
    TreatmentModule,
    UppyModule,
    GeneralCommonsModule
  ],
  exports: [OrthognathicTreatmentComponent]
})
export class OrthognathicModule {}
