import { Component, Input } from '@angular/core';
import {
  MediaResourceWithLabels,
  MediaSet
} from '@shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';

type Row = MediaResourceWithLabels[];

@Component({
  selector: 'stx-readonly-media-group',
  templateUrl: './readonly-media-group.component.html'
})
export class ReadonlyMediaGroupComponent {
  private _mediaSets: MediaSet[] = [];
  mediaSetsAsRows: Row[] = [];

  @Input()
  columns: boolean = false;

  @Input()
  set mediaSets(mediaSets: MediaSet[]) {
    this._mediaSets = mediaSets.filter(mediaSet => mediaSet.mediaWithLabels.length > 0);
    this.mediaSetsAsRows = this.asRows(mediaSets);
  }

  get mediaSets() {
    return this._mediaSets;
  }

  private asRows(mediaSets: MediaSet[]): Row[] {
    const columns = mediaSets.map(mediaSet => mediaSet.mediaWithLabels);
    const maxLength = Math.max(...columns.map(column => column.length));
    const rows = [];
    for (let rowIndex = 0; rowIndex < maxLength; rowIndex++) {
      rows.push(columns.map(column => column[rowIndex]));
    }
    return rows;
  }
}
