import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreatmentCenterDetailsPageComponent } from './components/treatment-center-details-page/treatment-center-details-page.component';
import { TreatmentCenterDetailsComponent } from './components/treatment-center-details/treatment-center-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { TableModule } from 'src/app/shared/components/table/table.module';
import { PartnerModule } from '../partner/partner.module';
import { UsersModule } from '../user/users.module';
import { PermissionModule } from 'src/app/core/permissions/permission.module';
import { DetailsCommonsModule } from '@shared/modules/details-commons/details-commons.module';
import { StatisticsModule } from '@shared/modules/statistics/statistics.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';

@NgModule({
  declarations: [TreatmentCenterDetailsPageComponent, TreatmentCenterDetailsComponent],
  imports: [
    CommonModule,
    DetailsCommonsModule,
    FlexLayoutModule,
    FontAwesomeModule,
    GeneralCommonsModule,
    MaterialModule,
    PartnerModule,
    PermissionModule,
    StatisticsModule,
    TableModule,
    TranslateModule.forChild(),
    UsersModule
  ]
})
export class TreatmentCenterModule {}
