<ng-container *ngIf="!expandable">
  <stx-patient-forms-headers-container [formType]="formType"></stx-patient-forms-headers-container>
  <h1>
    <small>{{ 'permanent_dentition.presentation' | translate }}</small>
  </h1>
  <stx-ortho-treatment-guidelines-link *ngIf="!print"></stx-ortho-treatment-guidelines-link>
  <stx-ortho-treatment-status-bar
    [orthoTreatment]="orthoFormModel"
    (unlockClicked)="unlock()"
    (deleteClicked)="delete()"
  ></stx-ortho-treatment-status-bar>
</ng-container>

<div class="page-section-container" *ngIf="isReadonlyView">
  <stx-ortho-readonly-treatment [treatment]="orthoFormModel"></stx-ortho-readonly-treatment>
</div>
<ng-container *ngIf="!isReadonlyView">
  <form *ngIf="tcList && patient" [formGroup]="treatmentFormGroup">
    <stx-form-panel>
      <p class="form-section-heading">
        {{ 'ortho.general_patient_information' | translate }}
      </p>

      <stx-ortho-general-treatment-info
        #generalTreatmentInfo
        [orthoFormType]="OrthoFormType.PERMANENT_DENTITION"
        [tcList]="tcList"
        [minCareProvidedDate]="startDateMinValue"
        [maxCareProvidedDate]="startDateMaxValue"
        [minCareProvidedEndDate]="endDateMinValue"
        [formMode]="formMode"
        [print]="print"
      >
      </stx-ortho-general-treatment-info>
    </stx-form-panel>

    <stx-expandable-form-panel [expanded]="true">
      <ng-container slot="heading">
        {{ 'ortho.facial_photos' | translate }}
      </ng-container>
      <stx-image-group
        [formGroup]="treatmentFormGroup"
        [formMode]="formMode"
        [imageConfigs]="dentitionTreatmentFacialImagesConfigs"
        [print]="print"
      >
      </stx-image-group>
    </stx-expandable-form-panel>

    <div class="p-t-30"></div>

    <stx-expandable-form-panel [expanded]="true">
      <ng-container slot="heading">
        {{ 'ortho.intraoral_photos' | translate }}
      </ng-container>
      <stx-image-group
        [formGroup]="treatmentFormGroup"
        [formMode]="formMode"
        [imageConfigs]="dentitionTreatmentIntraOralImagesConfigs"
        [print]="print"
      >
      </stx-image-group>
    </stx-expandable-form-panel>

    <stx-expandable-form-panel [expanded]="true">
      <ng-container slot="heading">
        {{ 'ortho.xrays_photos' | translate }}
      </ng-container>
      <stx-image-group
        [formGroup]="treatmentFormGroup"
        [formMode]="formMode"
        [imageConfigs]="dentitionTreatmentXRayslImagesConfigs"
        [print]="print"
        columns="3"
      >
      </stx-image-group>
    </stx-expandable-form-panel>

    <stx-action-buttons
      *ngIf="!print"
      [asyncSecondaryAction]="onSaveButtonClicked()"
      [asyncPrimaryAction]="treatmentFormGroup.get('isAbandoned').value === true ? onDiscontinue() : onSubmitButtonClicked()"
    ></stx-action-buttons>
  </form>
</ng-container>
