<div [ngClass]="{ 'page-break-inside-avoid p-t-30': print }">
  <stx-form-panel>
    <div class="form-row">
      <p class="form-section-heading">
        {{ 'speech_assessment.recommendations' | translate }}
        <stx-tooltip class="hide-on-print" content="speech_instructions.assessment.recommendations_subtitle"></stx-tooltip>
      </p>
    </div>

    <ng-container *ngIf="showStandardError(formGroup, undefined, ['recommendationFields'], validatedRecommendationFields)">
      <div class="form-row">
        <mat-error class="stx-mat-error-align">{{ 'error.none_selected' | translate }}</mat-error>
      </div>
    </ng-container>

    <div class="form-columns-1-1-1" [ngClass]="{ 'flex-row-important': print }">
      <div>
        <div class="form-row">
          <p class="form-subsection-heading">{{ 'treatment.speech' | translate }}</p>
        </div>
        <div class="form-row">
          <stx-checkbox
            [name]="'speech_assessment.recommend_initiate_speech_therapy'"
            [controlName]="'recommendInitiateSpeechTherapy'"
            [formGroup]="formGroup"
            [formMode]="formMode"
            (updateCheckbox)="onDependentControlUpdated()"
          ></stx-checkbox>
        </div>
        <div class="form-row">
          <stx-checkbox
            [name]="'speech_assessment.recommend_parent_education'"
            [controlName]="'recommendParentEducation'"
            [formGroup]="formGroup"
            [formMode]="formMode"
            (updateCheckbox)="onDependentControlUpdated()"
          ></stx-checkbox>
        </div>
        <div class="form-row">
          <stx-checkbox
            [name]="'speech_assessment.recommend_vpd_assessment'"
            [controlName]="'recommendVpdAssessment'"
            [formGroup]="formGroup"
            [formMode]="formMode"
            (updateCheckbox)="onDependentControlUpdated()"
          ></stx-checkbox>
        </div>
        <div class="form-row">
          <stx-checkbox
            [name]="'speech_assessment.recommend_none'"
            [controlName]="'recommendNoSpeechServices'"
            [formGroup]="formGroup"
            [formMode]="formMode"
            (updateCheckbox)="onRecommendNoneUpdated()"
          ></stx-checkbox>
        </div>

        <div class="form-row">
          <div class="form-element-group-column">
            <stx-checkbox
              [name]="'speech_assessment.recommend_reevaluate_in'"
              [controlName]="'recommendReeval'"
              [formGroup]="formGroup"
              [formMode]="formMode"
              (updateCheckbox)="onRecommendNoneUpdated()"
            ></stx-checkbox>
            <stx-input
              class="months-input"
              [formMode]="formMode"
              [formGroup]="formGroup"
              [controlName]="'recommendReevalMonths'"
              [print]="print"
              [type]="'number'"
              [unit]="'speech_assessment.recommend_reevaluate_in_months'"
              (inputHasValue)="toggleReevalCheckbox($event)"
            ></stx-input>
          </div>
        </div>
      </div>
      <div>
        <div class="form-row">
          <p class="form-subsection-heading">{{ 'speech_assessment.recommendation_dental_prosthetics' | translate }}</p>
        </div>
        <div class="form-row" *ngFor="let option of dental">
          <stx-checkbox
            [name]="option.label"
            [controlName]="option.value"
            [formGroup]="formGroup"
            [formMode]="formMode"
            (updateCheckbox)="onDependentControlUpdated()"
          ></stx-checkbox>
        </div>
      </div>
      <div>
        <div class="form-row">
          <p class="form-subsection-heading">{{ 'treatment.surgery' | translate }}</p>
        </div>
        <div class="form-row" *ngFor="let option of surgery">
          <stx-checkbox
            [name]="option.label"
            [controlName]="option.value"
            [formGroup]="formGroup"
            [formMode]="formMode"
            (updateCheckbox)="onDependentControlUpdated()"
          ></stx-checkbox>
        </div>
        <div class="form-row">
          <div class="indented-form-block">
            <div class="form-row" *ngFor="let type of repairTypes">
              <stx-checkbox
                [name]="type.label"
                [controlName]="type.value"
                [formGroup]="formGroup"
                [formMode]="formMode"
                (updateCheckbox)="onDependentControlUpdated()"
              ></stx-checkbox>
            </div>
          </div>
        </div>
        <div class="form-row">
          <stx-textarea
            [label]="'other'"
            [controlName]="'recommendOther'"
            [formGroup]="formGroup"
            [formMode]="formMode"
            (textareaHasValue)="onDependentControlUpdated()"
          ></stx-textarea>
        </div>
      </div>
    </div>
  </stx-form-panel>
</div>
