import { InstanceArea, StxConfiguration } from '@shared/models/stxconfiguration.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private configuration: Promise<StxConfiguration | null>;

  constructor(private readonly httpClient: HttpClient) {}

  public loadConfiguration(): Promise<StxConfiguration> {
    this.configuration = this.httpClient
      .get<StxConfiguration>(environment.apiUrl + '/config')
      .toPromise()
      .catch(_ => null);

    return this.configuration;
  }

  public async getRedirectUrl(): Promise<string> {
    return this.configuration.then(config => config.redirectTo);
  }

  public async getCurrentInstanceArea(): Promise<InstanceArea> {
    return this.configuration.then(config => config.currentArea);
  }
}
