import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ExpandableFormPanelComponent } from '@shared/modules/forms-commons/components/expandable-form-panel/expandable-form-panel.component';
import { FormPanelComponent } from '@shared/modules/forms-commons/components/form-panel/form-panel.component';
import { ExpandableFormSectionComponent } from '@shared/modules/forms-commons/components/expandable-form-section/expandable-form-section.component';
import { EmptyFormElementComponent } from '@shared/modules/forms-commons/components/empty-form-element/empty-form-element.component';
import { TransposeColumnsToRowsPipe } from '@shared/modules/forms-commons/pipes/transpose-columns-to-rows.pipe';

@NgModule({
  declarations: [
    EmptyFormElementComponent,
    ExpandableFormPanelComponent,
    ExpandableFormSectionComponent,
    FormPanelComponent,
    TransposeColumnsToRowsPipe
  ],
  imports: [CommonModule, FlexLayoutModule, FlexModule, TranslateModule.forChild(), FontAwesomeModule],
  exports: [
    EmptyFormElementComponent,
    ExpandableFormPanelComponent,
    ExpandableFormSectionComponent,
    FormPanelComponent,
    TransposeColumnsToRowsPipe
  ]
})
export class FormsCommonsModule {}
