import { ReleaseInfo } from '@/src/app/features/dashboard/components/release-info/release-info.model';
import { BaseHttpService } from '@/src/app/shared/services/http/base-http.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InfoService extends BaseHttpService {
  constructor(protected readonly httpClient: HttpClient) {
    super(httpClient, { baseUrl: 'info' });
  }

  getUpcomingReleaseInfo(): Observable<ReleaseInfo> {
    return this.httpClient.get<ReleaseInfo | null>(`${this.baseUrl}/release`);
  }
}
