import { StxValidators } from '@/src/app/shared/validation/validators';
import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { ValidationError } from './validators.model';

const defaultMessage = 'error.field_empty';

/**
 * @deprecated Use Default Angular Validators or merge to StxValidators
 */
@Injectable({
  providedIn: 'root'
})
export class AngularEmbeddedValidators {
  required(control: AbstractControl, messageGetter?: () => string): ValidationError | null {
    const result = StxValidators.required(control);
    const message = messageGetter ? messageGetter() : defaultMessage;

    if (result !== null) {
      return {
        stxRequired: {
          message
        }
      };
    }

    return result;
  }

  requiredTrue(control: AbstractControl, messageGetter?: () => string): ValidationError | null {
    const result = Validators.requiredTrue(control);
    const message = messageGetter ? messageGetter() : defaultMessage;

    if (result !== null) {
      return {
        stxRequiredTrue: {
          message
        }
      };
    }

    return result;
  }

  email(control: AbstractControl, messageGetter?: () => string): ValidationError | null {
    const result = Validators.email(control);
    const message = messageGetter ? messageGetter() : defaultMessage;

    if (result !== null) {
      return {
        stxEmail: {
          message
        }
      };
    }

    return result;
  }

  pattern(pattern: string | RegExp, control: AbstractControl, messageGetter?: () => string): ValidationError | null {
    const result = Validators.pattern(pattern);
    const message = messageGetter ? messageGetter() : defaultMessage;

    if (result(control) !== null) {
      return {
        stxPattern: {
          message
        }
      };
    }

    return result(control);
  }

  min(minValue: number, control: AbstractControl, messageGetter?: () => string): ValidationError | null {
    const result = Validators.min(minValue);
    const message = messageGetter ? messageGetter() : defaultMessage;

    if (result(control) !== null) {
      return {
        stxMin: {
          message
        }
      };
    }

    return result(control);
  }

  max(maxValue: number, control: AbstractControl, messageGetter?: () => string): ValidationError | null {
    const result = Validators.max(maxValue);
    const message = messageGetter ? messageGetter() : defaultMessage;

    if (result(control) !== null) {
      return {
        stxMax: {
          message
        }
      };
    }

    return result(control);
  }

  minLength(minLength: number, control: AbstractControl, messageGetter?: () => string): ValidationError | null {
    const result = Validators.minLength(minLength);
    const message = messageGetter ? messageGetter() : defaultMessage;

    if (result(control) !== null) {
      return {
        stxMinLength: {
          message
        }
      };
    }

    return result(control);
  }

  maxLength(maxLength: number, control: AbstractControl, messageGetter?: () => string): ValidationError | null {
    const result = Validators.maxLength(maxLength);
    const message = messageGetter ? messageGetter() : defaultMessage;

    if (result(control) !== null) {
      return {
        stxMaxLength: {
          message
        }
      };
    }

    return result(control);
  }

  nullValidator(control: AbstractControl, messageGetter?: () => string): ValidationError | null {
    const result = Validators.nullValidator(control);
    const validationMessage = messageGetter ? messageGetter() : defaultMessage;

    if (result !== null) {
      return {
        stxNull: {
          message: validationMessage
        }
      };
    }

    return result;
  }
}
