<div (dragover)="preventOpenForDragOver($event)" (drop)="onDrop($event)" class="uppy-container" *ngIf="!(print && placeholderShown)">
  <div #previewImageContainer>
    <!-- TODO: #9036 -->
    <img
      [src]="showImage()"
      alt=""
      #previewImage
      [ngClass]="{ 'cursor-pointer': !!highResOrConverted && !uploadedFileUrl }"
      [matTooltipDisabled]="!highResOrConverted || !!uploadedFileUrl"
      [matTooltip]="'image_high_res' | translate"
      [matTooltipClass]="'tooltip'"
      (click)="openImageViewer()"
      (keyup.enter)="openImageViewer()"
    />
  </div>
  <a class="m-b-25" *ngIf="isReadonly && !print && rawImageUrl" target="_blank" rel="noopener noreferrer" [href]="rawImageUrl">{{
    'image_open' | translate
  }}</a>
  <stx-uppy
    *ngIf="!isReadonly"
    class="uppy-image"
    [height]="100"
    [types]="imageAcceptedTypes"
    [maxFileSizeInBytes]="maxPictureSize10MbInBytes"
    [uuid]="uuid"
    (displayFile)="displayFile($event)"
    (uploadSuccessful)="uploadedFile($event)"
    (touched)="touched()"
    (uploadCancel)="restoreOldPreview()"
  ></stx-uppy>
</div>
