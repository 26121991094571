import { DentitionTreatment } from '@/src/app/features/ortho/models/dentition-treatment.model';
import { OrthoTreatmentFormApi } from '@/src/app/features/ortho/shared/ortho-treatment-form-api';
import { permanentDentitionTreatmentApiRoute } from '@/src/app/features/ortho/utils/ortho-routes.consts';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermanentDentitionTreatmentService extends OrthoTreatmentFormApi<DentitionTreatment> {
  constructor(httpClient: HttpClient) {
    super(httpClient, permanentDentitionTreatmentApiRoute);
  }
}
