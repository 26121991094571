<div class="relative form-control-margin">
  <stx-form-label [labelFor]="inputId">
    <ng-content></ng-content>
  </stx-form-label>

  <stx-input-base
    [inputType]="inputType"
    [inputId]="inputId"
    [inputPlaceholder]="placeholder"
    [inputDisabled]="disabled"
    [value]="viewValue"
    (blurEvent)="onBlur($event)"
    (inputEvent)="onInput($event)"
  >
  </stx-input-base>
  <stx-error-message-wrapper>
    <stx-validation-message *ngIf="ngControl.touched && ngControl.errors" [errors]="ngControl.errors"></stx-validation-message>
  </stx-error-message-wrapper>
</div>
