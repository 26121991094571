import { PsioAssessment } from '@/src/app/features/ortho/models/psio-assessment.model';
import { OrthoAssessmentFormApi } from '@/src/app/features/ortho/shared/ortho-assessment-form-api';
import { psioAssessmentApiRoute } from '@/src/app/features/ortho/utils/ortho-routes.consts';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PsioAssessmentService extends OrthoAssessmentFormApi<PsioAssessment> {
  constructor(httpClient: HttpClient) {
    super(httpClient, psioAssessmentApiRoute);
  }
}
