<div class="rotator-icon-container">
  <ng-container *ngIf="usePng; else svg">
    <img
      src="/assets/icons/rotate_ccw.png"
      alt=""
      class="cursor-pointer user-select-none"
      tabindex="0"
      (click)="rotate()"
      (keyup.enter)="rotate()"
    />
  </ng-container>
  <ng-template #svg>
    <mat-icon class="cursor-pointer user-select-none" tabindex="0" (click)="rotate()" (keyup.enter)="rotate()"
      >rotate_90_degrees_ccw</mat-icon
    >
  </ng-template>
</div>
