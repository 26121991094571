<ng-container *ngIf="surgicalTreatment && patient">
  <stx-treatment-status-bar
    [internalSuspiciousComment]="surgicalTreatment?.internalSuspiciousComment"
    [treatment]="surgicalTreatment"
    (unlockClicked)="unlockClicked.emit()"
    (deleteClicked)="deleteClicked.emit()"
  >
    <ng-container slot="row1">
      <div class="status-bar-data-block" *stxPermitted="PermissionEnum.VIEW_STATUSBAR_TREATMENT_SOURCE">
        <span *ngIf="!surgicalTreatment.offlineAppUpload">{{ 'treatment.surgery.source_of_data.online.upload' | translate }}</span>
        <span *ngIf="surgicalTreatment.offlineAppUpload">
          {{ 'treatment.surgery.source_of_data.offline.upload' | translate }} {{ surgicalTreatment.offlineAppVersion }}
        </span>
        <stx-value-separator></stx-value-separator>
      </div>
      <div *ngIf="suspiciousRecordReviewer" class="status-bar-data-block">
        <span class="m-l-3"> {{ 'treatment.status_bar.reviewed_by' | translate }} {{ suspiciousRecordReviewer }} </span>
        <span class="text-nowrap">
          {{ surgicalTreatment.suspiciousRecordReviewed | date : 'yyyy-MM-dd' }}
        </span>
        <stx-value-separator></stx-value-separator>
      </div>
      <div class="status-bar-data-block">
        <ng-container *ngIf="reviewModel?.modifiedAfterReview">
          <div>
            {{ 'treatment.status_bar.modified_after_review' | translate }}
          </div>
          <stx-value-separator></stx-value-separator>
        </ng-container>

        <ng-container *ngIf="reviewModel">
          <div *ngIf="reviewModel.qaReviewerName" class="flex-row-container">
            <mat-icon svgIcon="pin" class="m-r-5"></mat-icon>
            <div *ngIf="reviewModel.qaPhotoQuality !== false; else inadequatePhotoQuality">
              {{ reviewModel.qaReviewerName }}
            </div>
            <ng-template #inadequatePhotoQuality>
              {{ 'treatment.status_bar.bad_photo_quality' | translate }}
            </ng-template>
          </div>
          <stx-value-separator></stx-value-separator>
        </ng-container>

        <ng-container *ngIf="reviewModel?.qaPost">
          <div>{{ reviewModel.qaPre }} - {{ reviewModel.qaPost }}</div>
          <stx-value-separator></stx-value-separator>
        </ng-container>

        <ng-container *ngIf="reviewModel?.qaNote">
          <div>
            {{ reviewModel.qaNote }}
          </div>
          <stx-value-separator></stx-value-separator>
        </ng-container>
      </div>
    </ng-container>
    <ng-container slot="row2">
      <ng-container *stxPermitted="PermissionEnum.SAVE_BORESCOPE">
        <ng-container *ngIf="addBorescopeAllowed && !surgicalTreatment.isReadonly">
          <div class="flex-row-container">
            <div class="flex-column-container add-treatment-container">
              <span class="status-bar-label">{{ 'treatment.status_bar.add_treatment' | translate }}</span>
              <a
                [queryParams]="{ patientId: patient.id, surgeryId: surgicalTreatment.id }"
                [routerLink]="['/surgery/borescope/new']"
                class="align-middle"
              >
                <mat-icon class="m-r-5">add_circle_outline</mat-icon>{{ 'treatment.borescope' | translate }}
              </a>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </stx-treatment-status-bar>
</ng-container>
