import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormThemes } from '../../form.model';
import { FormUtilsService } from '../../utils/form-utils.service';
import { FormLabelModule } from '../form-label/form-label.component';
import { InputBaseComponentModule } from '../input-base/input-base.component';
import { ValidationMessageModule } from '../validation-message/validation-message.component';
import { FormModule } from '@shared/components/form/form.module';

/**
 * @deprecated Use InputComponent
 */
@Component({
  selector: 'stx-input-password[formControlName]',
  templateUrl: './input-password.component.html'
})
export class InputPasswordComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() inputId?: string;
  @Input() placeholder? = '';
  @Input() theme: FormThemes = 'primary';

  disabled = false;
  viewValue = '';
  hidePassword = true;

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl,
    private formUtilsService: FormUtilsService
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (!this.disabled) {
      // enable only field which is not disable by default and permanently
      this.ngControl.control.enable({ onlySelf: true, emitEvent: false });
    }

    if (!this.inputId) {
      this.inputId = this.formUtilsService.getControlName(this.ngControl.control);
    }
  }

  ngOnDestroy(): void {
    // ngIf workaround
    if (this.ngControl?.control) {
      this.ngControl.control.disable({ onlySelf: true, emitEvent: false });
      this.ngControl.control.setValue('');
      this.ngControl.control.markAsPristine();
    }
  }

  onInput(event: Event) {
    const currentViewValue = (event.target as HTMLInputElement).value;

    if (this.ngControl.control.updateOn === 'change') {
      this.propagateChange(currentViewValue);
    }
  }

  onBlur(event: Event) {
    const currentViewValue = (event.target as HTMLInputElement).value;

    if (this.ngControl.control.updateOn === 'blur') {
      this.propagateChange(currentViewValue);
    }

    this.onTouched();
  }

  onTouched: any = () => {
    /* This is part of ControlValueAccessor base impl */
  };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // write value from outside of component
  writeValue(value: string): void {
    this.viewValue = value;
  }

  private propagateChange = (value: any) => {
    /* This is part of ControlValueAccessor base impl */
  };
}

/**
 * @deprecated Use InputComponent
 */
@NgModule({
  imports: [CommonModule, MatButtonModule, InputBaseComponentModule, FormLabelModule, ValidationMessageModule, MatIconModule, FormModule],
  declarations: [InputPasswordComponent],
  exports: [InputPasswordComponent]
})
export class InputPasswordComponentModule {}
