import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { BaseHttpService } from '@shared/services/http/base-http.service';
import { HttpUtils } from '@utils/http.utils';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { QaReviewerReliabilityRecord } from '@src/app/features/reports/components/report-filters/models/report-data.model';
import { ReportRequestService } from '@src/app/features/reports/components/report-filters/services/report-request.service';
import { ReportService } from '@src/app/features/reports/components/report-filters/services/report.service';
import { TableStructure } from '@/src/app/shared/components/table/models/table-structure.model';

@Injectable({
  providedIn: 'root'
})
export class QaReviewerReliabilityService extends BaseHttpService {
  private static api = {
    base: 'reports',
    records: '/qa/reviewer-reliability/records',
    summary: '/qa/reviewer-reliability/summary'
  };

  readonly records$: Observable<TableStructure<QaReviewerReliabilityRecord>>;
  readonly summary$: Observable<any>;

  private readonly recordsSource$ = new Subject<TableStructure<QaReviewerReliabilityRecord>>();
  private readonly summarySource$ = new Subject();

  constructor(httpClient: HttpClient, private reportService: ReportService, private reportRequestService: ReportRequestService) {
    super(httpClient, { baseUrl: QaReviewerReliabilityService.api.base });

    this.records$ = this.recordsSource$.asObservable();
    this.summary$ = this.summarySource$.asObservable();
  }

  fetchRecords(pagination: PaginationSortingModel) {
    const params = this.getRequestParams(pagination);
    const url = `${this.baseUrl}${QaReviewerReliabilityService.api.records}`;

    return this.httpClient.get<TableStructure<QaReviewerReliabilityRecord>>(url, { params }).pipe(
      catchError(error => {
        this.recordsSource$.next(this.getEmptyPage<QaReviewerReliabilityRecord>());
        throw new Error(error);
      }),
      tap(response => this.recordsSource$.next(response))
    );
  }

  fetchSummary(pagination: PaginationSortingModel) {
    const params = this.getRequestParams(pagination);
    const url = `${this.baseUrl}${QaReviewerReliabilityService.api.summary}`;

    return this.httpClient.get(url, { params }).pipe(
      catchError(error => {
        this.recordsSource$.next(this.getEmptyPage<QaReviewerReliabilityRecord>());
        throw Error(error);
      }),
      tap(response => this.summarySource$.next(response))
    );
  }

  private getRequestParams(pagination: PaginationSortingModel): HttpParams {
    const paginationParams = HttpUtils.pageSortToParams(pagination);
    const filterParams = this.reportRequestService.getRequestParams(this.reportService.model);

    return HttpUtils.mergeParams([paginationParams, filterParams]);
  }

  private getEmptyPage<T>(): TableStructure<T> {
    return {
      labels: [],
      data: {
        pageNumber: 0,
        pageSize: 0,
        sortDirection: '',
        sortProperty: '',
        page: {
          content: [],
          empty: true,
          first: false,
          last: false,
          number: 0,
          numberOfElements: 0,
          pageable: '',
          size: 0,
          sort: {
            empty: false,
            sorted: false,
            unsorted: false
          },
          totalElements: 0,
          totalPages: 0
        }
      }
    };
  }
}
