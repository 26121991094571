<mat-expansion-panel class="mobile-button-with-subitems m-l-10">
  <mat-expansion-panel-header class="text-uppercase text-color" (click)="$event.stopPropagation()">
    {{ buttonWithSubItems.text | translate }}
  </mat-expansion-panel-header>
  <ng-container *ngFor="let subItem of buttonWithSubItems.subItems">
    <mat-action-row>
      <button data-testid="button-mobile-with-subitems" mat-menu-item class="menu-item menu-button" routerLink="{{ subItem.href }}">
        {{ subItem.text | translate }}
      </button>
    </mat-action-row>
  </ng-container>
</mat-expansion-panel>
