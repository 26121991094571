<stx-resources-navigation selectedItemId="{{ NavigationSubTabEnum.RESOURCES_NUTRITION }}"></stx-resources-navigation>
<stx-generic-wide-page>
  <stx-page-header [name]="'menu.resources.nutrition' | translate"></stx-page-header>
  <div class="layout-columns-1-1-1">
    <div>
      <h2>{{ 'resources.anthropometry' | translate }}</h2>
      <div class="data-section">
        <stx-generic-resource-list [sections]="source.slice(0, 1)"></stx-generic-resource-list>
      </div>
      <hr class="form-subsection-separator" />
      <h2>{{ 'resources.who.title' | translate }}</h2>
      <ul class="data-spaced-column">
        <li>
          <a [routerLink]="['/', resourcesRoute, whoRoute]"> {{ 'resources.who.resources-link' | translate }} </a>
        </li>
      </ul>
    </div>
    <hr class="vertical-separator" />
    <div>
      <stx-generic-resource-list [sections]="source.slice(1, 2)"></stx-generic-resource-list>
    </div>
    <hr class="vertical-separator" />
    <div class="layout-columns-1-1">
      <div>
        <stx-generic-resource-list [sections]="source.slice(2, 4)"></stx-generic-resource-list>
      </div>
    </div>
  </div>
</stx-generic-wide-page>
