import { Component, OnChanges, OnInit } from '@angular/core';
import { BaseNutritionSectionComponent } from '@src/app/features/nutrition-management/shared/base-nutrition-section.component';
import { NutritionCheckboxItem } from '@src/app/features/nutrition-management/models/nutrition-item.model';
import { nutritionCarePlanOptions } from '@src/app/features/nutrition-management/utils/nutrition-management.utils';

@Component({
  selector: 'stx-nutrition-care-plan',
  templateUrl: './nutrition-care-plan.component.html'
})
export class NutritionCarePlanComponent extends BaseNutritionSectionComponent implements OnInit, OnChanges {
  nutritionCarePlanOptions: NutritionCheckboxItem[];

  ngOnInit(): void {
    this.updateOptions();
  }

  ngOnChanges(): void {
    this.updateOptions();
  }

  updateOptions(): void {
    this.nutritionCarePlanOptions = nutritionCarePlanOptions.filter(option => option.showFor.includes(this.nutritionFormType));
  }
}
