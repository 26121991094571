<ng-content></ng-content>
<button
  data-testid="button-chevron-line"
  *ngIf="showChevron"
  type="button"
  mat-icon-button
  class="fs-16 cursor-pointer"
  (click)="toggle.emit()"
>
  <mat-icon class="fs-24" [ngClass]="isExpanded ? 'is-expanded' : ''"> expand_more </mat-icon>
</button>
