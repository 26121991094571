import { Component, Input } from '@angular/core';
import { MediaUrl } from '@src/app/features/media/models/media-url.model';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { dateTimeFormat } from '@utils/date.utils';

@Component({
  selector: 'stx-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent {
  readonly PermissionEnum = PermissionEnum;
  readonly dateTimeFormat = dateTimeFormat;

  @Input() mediaUrl?: MediaUrl;
}
