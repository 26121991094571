<div class="data-tile-container">
  <div class="data-tile-row">
    <div class="data-tile">
      <div *ngIf="header" class="data-tile-header" [ngClass]="{ 'data-tile-header-light': light }">
        <div>
          {{ header }}
          <stx-up-down-chevron
            *ngIf="expandableHeader"
            [expanded]="headerExpanded"
            (toggleEvent)="headerExpanded = !headerExpanded"
          ></stx-up-down-chevron>
        </div>
        <div *ngIf="headerSubtitle">
          <small>{{ headerSubtitle }}</small>
        </div>
      </div>
      <div class="data-tile-content data-spaced-column" [@headerExpansion]="headerExpanded ? 'open' : 'closed'">
        <ng-content></ng-content>

        <ng-container *ngIf="expandableBody">
          <stx-up-down-chevron
            class="display-flex align-center"
            [expanded]="bodyExpanded"
            (toggleEvent)="bodyExpanded = !bodyExpanded"
          ></stx-up-down-chevron>
          <div [@bodyExpansion]="bodyExpanded ? 'open' : 'closed'">
            <ng-content select="[slot=expandedBody]"></ng-content>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
