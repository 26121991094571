import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReportConfig } from '../../components/report-filters/models/report-filters.model';
import { ReportFiltersModule } from '../../components/report-filters/report-filters.component';
import { ReportTableDataService } from '../../components/report-filters/services/report-table-data.service';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import { PhotoReportTableComponentModule } from './components/photo-report-table/photo-report-table.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'stx-photo-report',
  templateUrl: './photo-report.component.html'
})
export class PhotoReportComponent {
  reportConfig: ReportConfig = {
    type: 'PHOTO_REPORT',
    downloadOptions: {},
    filters: {
      age: true,
      gender: true,
      geography: true,
      organizationsTree: true,
      photoTypes: true,
      recordDate: true,
      // rejectedRecords: true, TODO#8912
      surgicalIntervention: true
    }
  };

  constructor(public reportService: ReportService, private reportTableDataService: ReportTableDataService) {}

  applyFilters(pagination: PaginationSortingModel) {
    this.reportTableDataService.prepareList(this.reportConfig.type, this.reportService.model, pagination);
  }
}

@NgModule({
  imports: [
    CommonModule,
    PhotoReportTableComponentModule,
    ReportFiltersModule,
    ReportHeaderComponentModule,
    TranslateModule,
    GeneralCommonsModule
  ],
  declarations: [PhotoReportComponent],
  exports: [PhotoReportComponent]
})
export class PhotoReportComponentModule {}
