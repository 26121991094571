import { Component } from '@angular/core';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { SpeechService } from '@src/app/features/speech/speech.service';
import { SpeechTreatment } from '@src/app/features/speech/models/speech-treatment.model';
import { dictionaryFromMapProvider } from '@shared/services/dictionary-provider';
import { BaseReadonlyViewComponent } from '@shared/modules/readonly-commons/utils/base-readonly-view.component';

interface Goal {
  number: number;
  goal: string;
  goalOther: string;
  status: number;
  start: moment.Moment;
  end: moment.Moment;
}

@Component({
  selector: 'stx-speech-treatment-readonly',
  templateUrl: './speech-treatment-readonly.component.html',
  providers: [dictionaryFromMapProvider(SpeechService, s => s.getSpeechTreatmentStaticDictionaries())]
})
export class SpeechTreatmentReadonlyComponent extends BaseReadonlyViewComponent<SpeechTreatment> {
  readonly parentOrderNames = ParentOrderName;

  goals: Goal[] = [];

  protected afterTreatmentSet(): void {
    this.goals = this.buildGoals();
  }

  get hasGoals(): boolean {
    return this.goals && this.goals.length > 0;
  }

  get hasTreatmentModel(): boolean {
    return !!this.treatment.serviceDaily || !!this.treatment.serviceWeekly || !!this.treatment.serviceMonthly;
  }

  get hasTreatmentOutcomes(): boolean {
    return !!this.treatment.improvement;
  }

  private buildGoals() {
    if (!this.treatment) {
      return [];
    }
    return [
      {
        number: 1,
        goal: this.treatment.goal1,
        goalOther: this.treatment.goal1Other,
        status: this.treatment.goal1Status,
        start: this.treatment.goal1Start,
        end: this.treatment.goal1End
      },
      {
        number: 2,
        goal: this.treatment.goal2,
        goalOther: this.treatment.goal2Other,
        status: this.treatment.goal2Status,
        start: this.treatment.goal2Start,
        end: this.treatment.goal2End
      },
      {
        number: 3,
        goal: this.treatment.goal3,
        goalOther: this.treatment.goal3Other,
        status: this.treatment.goal3Status,
        start: this.treatment.goal3Start,
        end: this.treatment.goal3End
      },
      {
        number: 4,
        goal: this.treatment.goal4,
        goalOther: this.treatment.goal4Other,
        status: this.treatment.goal4Status,
        start: this.treatment.goal4Start,
        end: this.treatment.goal4End
      }
    ].filter(value => value.goal || value.goalOther || value.status || value.start || value.end);
  }
}
