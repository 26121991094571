import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { getNextDay } from '@/src/app/utils/date.utils';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Item } from '@shared/models/item.model';
import { Practitioner } from '@shared/models/practitioner.model';
import { TreatmentCenterDictionary } from '@shared/models/treatment-center.model';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { FormMode } from '@utils/form.utils';
import { speechInstructionsPageRoute } from '@utils/routing.utils';
import * as moment from 'moment';
import { PractitionerType } from 'src/app/shared/enums/practitioner.enum';
import { TreatmentCenterService } from '../../treatment-center/treatment-center.service';

@Component({
  selector: 'stx-speech-basic-info',
  templateUrl: './speech-basic-info.component.html'
})
export class SpeechBasicInfoComponent extends BaseFormElementComponent implements OnInit {
  @Input() tcList: TreatmentCenterDictionary[];
  @Input() isTreatment: boolean;
  @Input() dateOfBirth: moment.Moment;

  speechPractitionerList: Practitioner[] = [];
  tcLanguages: Item[] = [];
  minDate: moment.Moment;

  constructor(
    private treatmentCenterService: TreatmentCenterService,
    private changeDetector: ChangeDetectorRef,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.onTcChange();

    this.minDate = getNextDay(this.dateOfBirth);
  }

  get instructionsRoute(): string {
    return `/${speechInstructionsPageRoute}`;
  }

  private onTcChange(): void {
    const tcControl = this.formGroup.get('treatmentCenterId');

    this.updatePractitionerAndLanguagePossibleSelections(tcControl.value);

    this.subSink.sink = tcControl.valueChanges.pipe().subscribe(tcId => {
      if (this.formMode === FormMode.READONLY) {
        return;
      }

      const isValueChangedByUser = !!tcControl.touched;
      if (isValueChangedByUser) {
        this.checkPractitionerAndLanguageValidity();
      }

      this.updatePractitionerAndLanguagePossibleSelections(tcId);
    });
  }

  private updatePractitionerAndLanguagePossibleSelections(tcId: any) {
    if (tcId && this.formMode !== FormMode.READONLY) {
      this.getSpeechPractitioners(tcId);
      this.getTcLanguages(tcId);
    }
  }

  public checkPractitionerAndLanguageValidity() {
    this.resetPractitionerId();
    this.resetCountryLanguageId();
  }

  private resetPractitionerId(): void {
    if (this.isSelectedPractitionerIdInvalid) {
      this.practitionerIdControl.setValue(null);
    }
    this.practitionerIdControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  private resetCountryLanguageId(): void {
    if (this.isSelectedCountryLanguageInvalid) {
      this.countryLanguageIdControl.setValue(null);
    }
    this.countryLanguageIdControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  get countryLanguageIdControl() {
    return this.formGroup.get('countryLanguageId');
  }

  get practitionerIdControl() {
    return this.formGroup.get('practitionerId');
  }

  get countryLanguageIdValue() {
    return this.countryLanguageIdControl.value;
  }

  get practitionerIdValue() {
    return this.practitionerIdControl.value;
  }

  public get isSelectedPractitionerIdInvalid(): boolean {
    return (
      !this.speechPractitionerList ||
      (!!this.practitionerIdValue && this.speechPractitionerList.find(practitioner => practitioner.id === this.practitionerIdValue) == null)
    );
  }

  public get isSelectedCountryLanguageInvalid(): boolean {
    return (
      !this.tcLanguages ||
      (!!this.countryLanguageIdValue && this.tcLanguages.find(language => language.id === this.countryLanguageIdValue) == null)
    );
  }

  private getSpeechPractitioners(tcId: number) {
    this.subSink.sink = this.wsHelperService
      .callWithSpinner(this.treatmentCenterService.getPractitionersByTreatmentCenterAndType(tcId, PractitionerType.SPEECH_PROVIDER))
      .subscribe(practitioners => {
        this.speechPractitionerList = practitioners;
        this.resetPractitionerId();
        this.changeDetector.markForCheck();
      });
  }

  private getTcLanguages(tcId: number) {
    this.subSink.sink = this.wsHelperService.callWithSpinner(this.treatmentCenterService.getLanguagesByTcId(tcId)).subscribe(
      tcLanguages => {
        this.tcLanguages = tcLanguages;
        this.resetCountryLanguageId();
      },
      () => {
        this.changeDetector.markForCheck();
      }
    );
  }
}
