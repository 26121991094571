export enum ValidatorNameEnum {
  MIN = 'min',
  MAX = 'max',
  REQUIRED = 'required',
  REQUIRED_TRUE = 'required',
  EMAIL = 'email',
  MIN_LENGTH = 'minlength',
  MAX_LENGTH = 'maxlength',
  PATTERN = 'pattern'
}
