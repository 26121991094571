import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AgeFilterModel, ReportFilterChange, StatsTypeChangePayload } from '../../models/report-filters.model';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ReportDictionariesService } from '../../services/report-dictionaries.service';
import { RadioGroupOptions } from '@shared/models/form.model';
import { FormModule } from '@shared/components/form/form.module';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-stats-type',
  templateUrl: './stats-type-filter.component.html'
})
export class StatsTypeFilterComponent extends BaseComponent implements OnInit {
  private static readonly DEFAULT_STAT_TYPE_VALUE = 0;

  @Input() formGroupReference: UntypedFormGroup;
  @Input() controlName: string;
  @Output() filterChange = new EventEmitter<ReportFilterChange<StatsTypeChangePayload>>();

  formControl: AbstractControl;
  statsTypes: RadioGroupOptions;

  constructor(private reportDictionariesService: ReportDictionariesService) {
    super();
  }

  ngOnInit() {
    this.statsTypes = this.reportDictionariesService.dictionaries.statsTypes;
    this.formControl = this.formGroupReference.get(this.controlName);

    this.watchFilterChanges();
    this.setDefaultValue();
  }

  private emitFilterModel(ageRange: AgeFilterModel): void {
    this.filterChange.emit({
      filterName: 'statsType',
      payload: {
        statsType: [this.formControl.value]
      }
    });
  }

  private setDefaultValue() {
    this.formControl.setValue(StatsTypeFilterComponent.DEFAULT_STAT_TYPE_VALUE);
  }

  private watchFilterChanges() {
    this.subSink.sink = this.formControl.valueChanges.subscribe(this.emitFilterModel.bind(this));
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, FormModule],
  declarations: [StatsTypeFilterComponent],
  exports: [StatsTypeFilterComponent]
})
export class StatsTypeFilterComponentModule {}
