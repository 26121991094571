import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { slideDown, slideUp } from '@core/animations';
import { TIMINGS } from '@core/animations/constant.animations';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'stx-qa-evaluator-reviews-section',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './qa-evaluator-reviews-section.component.html',
  styleUrls: ['./qa-evaluator-reviews-section.component.scss'],
  animations: [
    trigger('tableSection', [
      transition(
        'void => *',
        useAnimation(slideDown, {
          params: {
            timing: TIMINGS.VERY_FAST
          }
        })
      ),
      transition(
        '* => void',
        useAnimation(slideUp, {
          params: {
            timing: TIMINGS.VERY_FAST
          }
        })
      )
    ])
  ]
})
export class QaEvaluatorReviewsSectionComponent implements OnInit {
  @Input() headerText: string;
  @Input() initialyOpened: boolean;

  tableVisible = false;

  toggleTable() {
    this.tableVisible = !this.tableVisible;
  }

  ngOnInit(): void {
    if (this.initialyOpened) {
      this.tableVisible = true;
    }
  }
}

@NgModule({
  declarations: [QaEvaluatorReviewsSectionComponent],
  imports: [TranslateModule, MatIconModule, CommonModule],
  exports: [QaEvaluatorReviewsSectionComponent]
})
export class QaEvaluatorReviewsSectionComponentModule {}
