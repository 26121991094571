import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataPage, DataPageWithNames } from '@shared/modules/general-commons/components/data-table/data-table.model';
import { Observable } from 'rxjs';
import { PaginationSortingModel } from 'src/app/shared/components/table/models/pagination-sorting.model';
import { TableStructure } from 'src/app/shared/components/table/models/table-structure.model';
import { BaseHttpService } from 'src/app/shared/services/http/base-http.service';
import { HttpUtils } from 'src/app/utils/http.utils';
import { PractitionerSearchModel } from './models/practitioner-search.model';
import { PractitionerDetails, PractitionerNote } from './models/practitioner.model';

@Injectable({
  providedIn: 'root'
})
export class PractitionerService extends BaseHttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient, { baseUrl: 'orgtree/practitioners' });
  }

  getPractitioners(pageSort?: PaginationSortingModel, searchFilters?: PractitionerSearchModel): Observable<DataPageWithNames<any>> {
    if (searchFilters || pageSort) {
      const params = HttpUtils.convertToSearchParams(searchFilters, pageSort);
      return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}`, { params });
    }
    return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}`);
  }

  getPractitionerDetails(practitionerId: number): Observable<PractitionerDetails> {
    return this.httpClient.get<PractitionerDetails>(`${this.baseUrl}/${practitionerId}`);
  }

  getPractitionerTreatmentCenters(practitionerId: number, pageSort?: PaginationSortingModel): Observable<TableStructure> {
    if (pageSort) {
      const params = HttpUtils.pageSortToParams(pageSort);
      return this.httpClient.get<TableStructure>(`${this.baseUrl}/${practitionerId}/treatment-centers`, { params });
    }
    return this.httpClient.get<TableStructure>(`${this.baseUrl}/${practitionerId}/treatment-centers`);
  }

  getPractitionersCsvFile(searchFilters?: PractitionerSearchModel) {
    const headers = new HttpHeaders().set('Accept', 'text/csv');
    if (searchFilters) {
      const params = HttpUtils.convertToSearchParams(searchFilters);
      return this.httpClient.get(`${this.baseUrl}`, { responseType: 'blob', headers, params });
    }
    return this.httpClient.get(`${this.baseUrl}`, { responseType: 'blob', headers });
  }

  submitNote(practitionerId: number, note: string): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/${practitionerId}/notes`, note);
  }

  getNotes(practitionerId: number): Observable<DataPage<PractitionerNote>> {
    return this.httpClient.get<DataPage<PractitionerNote>>(`${this.baseUrl}/${practitionerId}/notes`);
  }
}
