import { EducationalResourcesService } from '@/src/app/features/educational-resources/educational-resources.service';
import { MedicalMeetingCategory } from '@/src/app/features/educational-resources/models/medical-meeting.model';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { RadioGroupOptions } from '@/src/app/shared/models/form.model';
import { registerUpdateValueAndValidityForAllControls } from '@/src/app/shared/validation/validation.utils';
import { isValidUrl, StxValidators } from '@/src/app/shared/validation/validators';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { getToday } from '@/src/app/utils/date.utils';
import { FormMode } from '@/src/app/utils/form.utils';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'stx-medical-meeting-form',
  templateUrl: './medical-meeting-form.component.html',
  styleUrls: ['./medical-meeting-form.component.scss']
})
export class MedicalMeetingFormComponent extends BaseComponent implements OnInit {
  readonly formMode = FormMode.EDIT;
  readonly categoryRadioOptions: RadioGroupOptions<MedicalMeetingCategory> = [
    {
      label: 'resources.education_and_research.conference',
      value: MedicalMeetingCategory.CONFERENCE
    },
    {
      label: 'resources.education_and_research.webinar',
      value: MedicalMeetingCategory.WEBINAR
    }
  ];

  formGroup: UntypedFormGroup;
  today = getToday();
  @Output() medicalMeetingSubmitted = new EventEmitter<void>();

  constructor(private formBuilder: UntypedFormBuilder, private resourcesService: EducationalResourcesService) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.initializeFormGroup();
    registerUpdateValueAndValidityForAllControls(this.formGroup, this.subSink);
  }

  initializeFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      category: [null, StxValidators.required],
      eventName: [null, StxValidators.required],
      eventLink: [null, isValidUrl],
      eventBlurb: [],
      eventStart: [null, StxValidators.required],
      eventEnd: [],
      eventTime: [],
      eventAbstractDue: [],
      eventLocation: []
    });
  }

  submitMedicalMeeting: AsyncButtonClickAction = () => {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      return this.resourcesService.saveMedicalMeeting(this.formGroup.value).pipe(
        tap(() => {
          this.formGroup.reset();
          this.medicalMeetingSubmitted.emit();
        })
      );
    } else {
      return of(null);
    }
  };
}
