<div class="form-section">
  <p class="form-section-heading">
    {{ 'speech_treatment.treatment_outcomes' | translate }}
  </p>
  <div class="form-columns-1-1">
    <div class="form-column">
      <div class="form-row">
        <stx-radio-group
          [label]="'speech_treatment.improvement'"
          [formGroup]="formGroup"
          [formMode]="formMode"
          [controlName]="'improvement'"
          [options]="staticDictionaries.get('speech_improved')"
          [layoutDirection]="'row'"
          [print]="print"
        ></stx-radio-group>
      </div>
    </div>
  </div>

  <hr class="form-section-separator" />
  <div [ngClass]="{ 'm-t-75': print }">
    <stx-speech-sample-video-recording
      [formType]="FormType.SPEECH_TREATMENT"
      [formMode]="formMode"
      [formGroup]="formGroup"
      [print]="print"
    ></stx-speech-sample-video-recording>
  </div>

  <hr class="form-section-separator" />

  <stx-treatment-recommendations [formMode]="formMode" [formGroup]="formGroup" [print]="print"></stx-treatment-recommendations>
</div>
