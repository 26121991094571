import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'stx-empty-table-state',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './empty-table-state.component.html',
  styleUrls: ['./empty-table-state.component.scss']
})
export class EmptyTableStateComponent {
  @Input() textTranslationPath = 'no_data';
}

@NgModule({
  declarations: [EmptyTableStateComponent],
  imports: [FlexModule, TranslateModule, MatIconModule],
  exports: [EmptyTableStateComponent]
})
export class EmptyTableStateComponentModule {}
