import { Injectable } from '@angular/core';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { AuthService } from '../../../../authentication/services/auth.service';
import { ReportConfig, ReportDownloadOptions, ReportDownloadTypes, ReportFilters, ReportType } from '../models/report-filters.model';

@Injectable({ providedIn: 'root' })
export class ReportPermissionsService {
  private readonly reportViewPermissions: Partial<Record<ReportType, keyof typeof PermissionEnum>> = {
    SURGICAL_TREATMENTS: 'GET_REPORT_SURGICAL_TREATMENTS',
    SURGICAL_TREATMENTS_COMPACT: 'GET_REPORT_SURGICAL_TREATMENTS_COMPACT',
    SURGICAL_FOLLOW_UP_VISITS: 'GET_REPORT_FOLLOWUP_TREATMENTS',
    ORTHOGNATHIC_TREATMENTS: 'GET_REPORT_ORTHOGNATHIC_TREATMENTS',
    SURGERY_STATS: 'GET_REPORT_SURGERY_STATS',
    ORTHODONTIC_STATS: 'GET_REPORT_ORTHODONTIC_STATS',
    SPEECH_STATS: 'GET_REPORT_SPEECH_STATS',
    YEAR_TO_DATE: 'GET_REPORT_YEAR_TO_DATE',
    PHOTO_REPORT: 'GET_REPORT_PHOTO',
    ORTHODONTIC_TREATMENTS: 'GET_REPORT_ORTHODONTIC_TREATMENTS',
    ORTHODONTIC_TREATMENTS_COMPACT: 'GET_REPORT_ORTHODONTIC_TREATMENTS_COMPACT',
    PARTNER_SURGERY_COUNTS: 'GET_REPORT_PARTNER_SURGERY_COUNTS',
    PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT: 'GET_REPORT_PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT',
    MONTH_END: 'GET_REPORT_MONTH_END',
    QA_EVALUATOR_REVIEWS_SURGERY: 'GET_REPORT_QA_EVALUATOR_REVIEWS_SURGERY',
    QA_EVALUATOR_REVIEWS_SPEECH: 'GET_REPORT_QA_EVALUATOR_REVIEWS_SPEECH',
    QA_EVALUATOR_REVIEWS_ORTHO: 'GET_REPORT_QA_EVALUATOR_REVIEWS_ORTHO'
  };
  private readonly reportDownloadPermissions: Partial<
    Record<ReportType, Partial<Record<ReportDownloadTypes, Partial<keyof typeof PermissionEnum>>>>
  > = {
    SURGICAL_TREATMENTS: {
      [ReportDownloadTypes.EXCEL_ADMIN_ID]: 'GET_ADMIN_REPORT_SURGICAL_TREATMENTS',
      [ReportDownloadTypes.EXCEL_ADMIN]: 'GET_ADMIN_REPORT_SURGICAL_TREATMENTS',
      [ReportDownloadTypes.EXCEL]: 'GET_REPORT_SURGICAL_TREATMENTS'
    },

    ORTHOGNATHIC_TREATMENTS: {
      [ReportDownloadTypes.EXCEL]: 'GET_REPORT_ORTHOGNATHIC_TREATMENTS',
      [ReportDownloadTypes.EXCEL_ADMIN]: 'GET_ADMIN_REPORT_ORTHOGNATHIC_TREATMENTS',
      [ReportDownloadTypes.EXCEL_ADMIN_ID]: 'GET_ADMIN_REPORT_ORTHOGNATHIC_TREATMENTS'
    },
    SURGERY_STATS: {
      [ReportDownloadTypes.EXCEL]: 'GET_REPORT_SURGERY_STATS'
    },
    SPEECH_TREATMENTS: {
      [ReportDownloadTypes.EXCEL_ADMIN_ID]: 'GET_ADMIN_REPORT_SPEECH_TREATMENTS',
      [ReportDownloadTypes.EXCEL_ADMIN]: 'GET_ADMIN_REPORT_SPEECH_TREATMENTS',
      [ReportDownloadTypes.EXCEL]: 'GET_REPORT_SPEECH_TREATMENTS'
    },
    ORTHODONTIC_TREATMENTS: {
      [ReportDownloadTypes.EXCEL_ADMIN_ID]: 'GET_ADMIN_REPORT_ORTHODONTIC_TREATMENTS',
      [ReportDownloadTypes.EXCEL_ADMIN]: 'GET_ADMIN_REPORT_ORTHODONTIC_TREATMENTS',
      [ReportDownloadTypes.EXCEL]: 'GET_REPORT_ORTHODONTIC_TREATMENTS'
    }
  };
  private readonly reportFilterPermissions: Partial<Record<keyof ReportFilters, keyof typeof PermissionEnum>> = {
    qualityAssurance: 'VIEW_REPORT_QA_FILTER',
    qaOnly: 'VIEW_REPORT_QA_FILTER',
    rejectedRecords: 'VIEW_REPORT_REJECTED_RECORDS_FILTER',
    deIdentification: 'VIEW_REPORT_DE_IDENTIFICATION_FILTER'
  };

  constructor(private authService: AuthService) {}

  public getAllowedReportConfig(config: ReportConfig): ReportConfig {
    const allowedReportFilters = this.getAllowedReportFilters(config.filters);
    const allowedDownloadOptions = this.getAllowedReportDownloadOptions(config.type, config.downloadOptions);

    return {
      type: config.type,
      downloadOptions: allowedDownloadOptions,
      filters: allowedReportFilters,
      additionalValidators: config.additionalValidators
    };
  }

  private getAllowedReportFilters(reportFilters: Partial<ReportFilters>): Partial<ReportFilters> {
    return Object.entries(reportFilters).reduce((acc: Partial<ReportFilters>, [key, value]) => {
      const filterPermission: PermissionEnum = this.reportFilterPermissions[key];

      if (filterPermission) {
        acc[key] = this.authService.checkUserPermission(filterPermission);
      } else {
        acc[key] = value;
      }

      return acc;
    }, {});
  }

  private getAllowedReportDownloadOptions(
    reportType: ReportType,
    downloadOptions: Partial<ReportDownloadOptions>
  ): Partial<ReportDownloadOptions> {
    const reportDownloadPermissions: Partial<Record<ReportDownloadTypes, Partial<keyof typeof PermissionEnum>>> =
      this.reportDownloadPermissions[reportType];

    const allowedDownloadOptions: Partial<ReportDownloadOptions> = Object.assign({}, downloadOptions);

    if (reportDownloadPermissions) {
      Object.entries(reportDownloadPermissions).forEach(([key, reportDownloadPermission]) => {
        if (downloadOptions[key]) {
          allowedDownloadOptions[key] = this.authService.checkUserPermission(reportDownloadPermission as PermissionEnum);
        }
      });
    }
    return allowedDownloadOptions;
  }
}
