import { PermissionModule } from '@/src/app/core/permissions/permission.module';
import { PartnerSurveysComponent } from '@/src/app/features/dashboard/partner-surveys/partner-surveys.component';
import { DataBlockModule } from '@/src/app/shared/components/data-block/data-block.module';
import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { StaticFilesModule } from '@/src/app/shared/modules/static-files/static-files.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PartnerSurveysComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    DataBlockModule,
    MatButtonModule,
    MatIconModule,
    PermissionModule,
    StaticFilesModule,
    FlexLayoutModule,
    GeneralCommonsModule
  ],
  exports: [PartnerSurveysComponent],
  providers: []
})
export class PartnerSurveysModule {}
