<stx-notice class="hide-on-print" *ngIf="treatment" [noticeType]="noticeType">
  <div class="flex-column-container">
    <div class="row-until-lt-md-container">
      <div class="flex-row-container status-container align-left layout-align-start custom-flex-10">
        <mat-icon [ngClass]="statusConfig?.cssStyleExt">{{ statusConfig?.icon }}</mat-icon>
        <span>{{ statusConfig?.label | translate }}</span>
      </div>
      <div class="flex-row-container wrap status-bar-data align-right" fxFlex="grow">
        <ng-container *ngTemplateOutlet="statusBarData"></ng-container>
        <ng-container *ngTemplateOutlet="statusBarActionIcons"></ng-container>
      </div>
    </div>
    <ng-content select="[slot=row2]"> </ng-content>
    <ng-container *ngIf="hasRejectionProblems">
      <div class="flex-row-container">
        <ng-container *ngTemplateOutlet="rejectionProblemsContainer"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="showNonEditableInfo">
      <ng-container *ngTemplateOutlet="nonEditableInfoContainer"></ng-container>
    </ng-container>
  </div>
</stx-notice>

<ng-template #statusBarData>
  <div class="status-bar-data-block">
    <stx-treatment-record-date [recordDate]="treatment.created" [dateType]="TreatmentDateType.CREATED"></stx-treatment-record-date>
    <stx-treatment-record-date
      *ngIf="showModificationDate"
      [recordDate]="treatment.modified"
      [dateType]="TreatmentDateType.MODIFIED"
    ></stx-treatment-record-date>
    <stx-treatment-record-date
      *ngIf="treatment.submitted"
      [recordDate]="treatment.submitted"
      [dateType]="TreatmentDateType.SUBMITTED"
    ></stx-treatment-record-date>
    <stx-value-separator></stx-value-separator>
  </div>
  <ng-content select="[slot=row1]"></ng-content>
</ng-template>

<ng-template #statusBarActionIcons>
  <div class="status-bar-data-block action-container not-print" *ngIf="treatment.unlockable && !treatment.isReadonly">
    <mat-icon
      [matTooltip]="'treatment.status_bar.unlock.tooltip' | translate"
      [matTooltipClass]="'tooltip'"
      matTooltipPosition="above"
      (click)="openUnlockDialog()"
      >lock_open</mat-icon
    >
  </div>
  <div class="status-bar-data-block action-container not-print" *ngIf="treatment.deletable && !treatment.isReadonly">
    <mat-icon [matTooltip]="'delete' | translate" [matTooltipClass]="'tooltip'" matTooltipPosition="above" (click)="openDeleteDialog()"
      >delete</mat-icon
    >
  </div>
</ng-template>

<ng-template #rejectionProblemsContainer>
  <div class="rejection-reasons-container">
    <div class="flex-column-container">
      <div class="status-bar-label">{{ 'suspicious_records.rejection_reasons_2' | translate }}</div>
      <ul>
        <li *ngFor="let problem of treatment.problems">
          <div *ngIf="problem.reason !== photoStandardsKey; else photoStandards">
            {{ problem.reason | translate }}
            <ng-container *ngIf="problem.reason === duplicateRecordKey">
              <a
                [routerLink]="[getDuplicateRecordLink(problem.duplicateTreatmentId)]"
                [queryParams]="{ patientId: problem.duplicatePatientId }"
              >
                {{ problem.duplicateRecordNumberId }}</a
              >
            </ng-container>
          </div>
          <ng-template #photoStandards>
            {{ 'problem_type_lookup.description.photos_do_not_meet' | translate }}
            <a [href]="photoStandardsLink" rel="noreferrer" target="_blank">{{
              'protocols_advisories_guidelines.photo_standards' | translate
            }}</a>
          </ng-template>
        </li>
      </ul>
    </div>
    <div *ngIf="rejectionComment || internalSuspiciousRejectionComment" class="flex-column-container">
      <div class="status-bar-label">
        {{ 'suspicious_records.comment' | translate }}
      </div>
      <p *ngIf="rejectionComment">{{ rejectionComment }}</p>
      <p *ngIf="internalSuspiciousRejectionComment">{{ internalSuspiciousRejectionComment }}</p>
    </div>
  </div>
</ng-template>

<ng-template #nonEditableInfoContainer>
  <span class="bold">{{ 'treatment.blocked.header' | translate }}</span>
  <ul class="m-t-0">
    <li *ngFor="let nonEditableReason of treatment.nonEditableReasonsTranslationKeys">{{ nonEditableReason | translate }}</li>
  </ul>
</ng-template>
