<div class="form-row"></div>
<div class="form-row">
  <ng-content></ng-content>
  <stx-select
    [formMode]="formMode"
    [formGroup]="formGroup"
    [controlName]="selectIdControlName"
    [label]="selectIdLabel"
    [dictionaryList]="selectDataSource"
  ></stx-select>
</div>
<div class="form-columns-1-1-1">
  <div class="form-row"></div>
  <div class="form-row"></div>
  <span>
    {{ registerAnesthesiologistLinkPrefixKey | translate }}
    <a [href]="registerAnesthesiologistLink" target="_blank">{{ registerAnesthesiologistLinkKey | translate }}</a>
  </span>
</div>
<div class="m-b-1 m-t-1">&nbsp;</div>
