<div class="data-table-container">
  <div class="data-table-wrapper">
    <table mat-table [dataSource]="dataSource" *ngIf="!hidden" multiTemplateDataRows aria-describedby="data-table-container">
      <!-- Cells -->
      <ng-container *ngFor="let column of columns; index as ix" [matColumnDef]="column.name" [sticky]="ix < 3">
        <th mat-header-cell [ngStyle]="getDynamicFlexStylingFor(ix)" *matHeaderCellDef>
          {{ column.text | translate }}
        </th>
        <td mat-cell [ngStyle]="getDynamicFlexStylingFor(ix)" *matCellDef="let rowGroup">
          <ng-container
            *ngTemplateOutlet="
              cellContent;
              context: {
                row: rowGroup.row,
                column: column,
                index: ix,
                expanded: isExpandedRowGroup(rowGroup)
              }
            "
          ></ng-container>
        </td>
      </ng-container>
      <!-- Expanded content -->
      <ng-container matColumnDef="expandedRows">
        <td mat-cell *matCellDef="let expansionContent" [attr.colspan]="displayedColumns.length">
          <div class="expansion-content">
            <table
              mat-table
              [dataSource]="expansionContent.rows"
              *ngIf="!hidden && isExpandedContent(expansionContent)"
              aria-describedby="expansion-content"
            >
              <!-- Expanded content - cells -->
              <ng-container *ngFor="let column of columns; index as ix" [matColumnDef]="column.name" [sticky]="ix < 3">
                <th mat-header-cell *matHeaderCellDef class="display-none">{{ column.text | translate }}</th>
                <td mat-cell [ngStyle]="getDynamicFlexStylingFor(ix)" *matCellDef="let row">
                  <ng-container
                    *ngTemplateOutlet="cellContent; context: { row: row, column: column, index: ix, hideArrow: true }"
                  ></ng-container>
                </td>
              </ng-container>
              <!-- Expanded content - rows -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns" class="display-none"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </td>
      </ng-container>
      <!-- Rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let rowGroup; columns: displayedColumns; when: isExpandableRowGroup"
        class="expandable-row-group"
        [class.expanded-row-group]="isExpandedRowGroup(rowGroup)"
        (click)="rowGroup.expansionContent.expanded = !rowGroup.expansionContent.expanded"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedRows']; when: isExpansionContentRow" class="expansion-content-wrapper"></tr>
    </table>
  </div>

  <!-- Cell -->
  <ng-template #cellContent let-row="row" let-ix="index" let-column="column" let-expanded="expanded" let-hideArrow="hideArrow">
    <div *ngIf="row?.hasOwnProperty(column.name)" class="flex-row-container wrap text-wrap">
      <ng-container *ngIf="!row[column.name]?.hasOwnProperty('actionIcons'); else cellWithAction">
        <div fxLayout="row">
          <mat-icon *ngIf="ix === 1" [class.visibility-hidden]="hideArrow">{{
            expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
          }}</mat-icon>
          <div class="flex-shrink">
            {{ row[column.name] | translate }}
          </div>
        </div>
      </ng-container>
      <ng-template #cellWithAction>
        <div class="flex-row-container">
          <div *ngIf="row[column.name].label" class="flex-80-px">
            {{ row[column.name].label | translate }}
          </div>
          <div class="flex-10-px"></div>
          <div class="flex-40 flex-row-container gap-10" *ngIf="row[column.name].actionIcons?.length > 0">
            <div *ngFor="let icon of row[column.name].actionIcons" class="flex-20-px">
              <mat-icon
                class="action-icon"
                svgIcon="{{ icon.customIcon ? icon.customIcon : '' }}"
                [matTooltip]="icon.iconTooltip | translate"
                [matTooltipClass]="'tooltip'"
                (click)="$event.stopPropagation(); icon.action(row)"
                >{{ icon.matIcon ? icon.matIcon : '' }}</mat-icon
              >
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
