import { Injectable } from '@angular/core';
import { BaseNutritionManagementService } from '@src/app/features/nutrition-management/services/base-nutrition-management.service';
import { NutritionFollowUp } from '@src/app/features/nutrition-management/models/nutrition-management.model';
import { HttpClient } from '@angular/common/http';
import { nutritionFollowUpPilotResourcesRoute } from '@utils/routing.utils';

@Injectable({
  providedIn: 'root'
})
export class NutritionFollowUpService extends BaseNutritionManagementService<NutritionFollowUp> {
  constructor(httpClient: HttpClient) {
    super(httpClient, nutritionFollowUpPilotResourcesRoute);
  }
}
