import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CommonReportDictionaryItem, ReportDictionaryTreeItem, ReportFilterChange, ReportType } from '../../models/report-filters.model';
import { CheckboxGroupFilterComponentModule } from '../checkbox-group-filter/checkbox-group-filter.component';
import { ReportFiltersHelperService } from '../../services/report-filters-helper.service';
import { ReportDictionariesService } from '../../services/report-dictionaries.service';
import { CommonModule } from '@angular/common';
import { CheckboxTreeFilterComponentModule } from '@src/app/features/reports/components/report-filters/components/checkbox-tree-filter/checkbox-tree-filter.component';
import { ReportFiltersFormGeneratorService } from '@src/app/features/reports/components/report-filters/services/report-filters-form-generator.service';
import { formStatusMap } from '@utils/form.utils';
import { FormStatus } from '@shared/enums/form-status.enum';

@Component({
  selector: 'stx-report-treatment-status-filter',
  templateUrl: './report-treatment-status-filter.component.html'
})
export class ReportTreatmentStatusFilterComponent implements OnInit {
  @Input() reportType: ReportType;
  @Input() formGroupReference: UntypedFormGroup;
  @Output() filterChange = new EventEmitter<ReportFilterChange>();

  readonly REIMBURSABLE_VALUE: string = '99';
  private readonly SUBMITTED_VALUE: string = formStatusMap.get(FormStatus.SUBMITTED).toString();
  private readonly IN_PROGRESS_VALUE: string = formStatusMap.get(FormStatus.IN_PROGRESS).toString();
  private readonly ACCEPTED_VALUE: string = formStatusMap.get(FormStatus.ACCEPTED).toString();
  private readonly REJECTED_VALUE: string = formStatusMap.get(FormStatus.REJECTED).toString();
  private readonly EXCEEDS_QUOTA_VALUE: string = formStatusMap.get(FormStatus.EXCEEDS_QUOTA).toString();
  private readonly SECONDARY_ONLY_VALUE: string = '97';
  private readonly COMBO_VALUE: string = '98';
  private readonly PATIENT_VALUE: string = '100';

  dictionaryOptions: CommonReportDictionaryItem[] | ReportDictionaryTreeItem[];
  inProgressToComboOptions: CommonReportDictionaryItem[];
  rejectedToSecondaryOptions: CommonReportDictionaryItem[];
  reimbursableOptions: ReportDictionaryTreeItem[] = [];

  constructor(
    private reportFiltersHelperService: ReportFiltersHelperService,
    private reportDictionariesService: ReportDictionariesService,
    private reportFiltersFormGeneratorService: ReportFiltersFormGeneratorService
  ) {}

  ngOnInit(): void {
    this.dictionaryOptions = this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(
      this.reportDictionariesService.dictionaries.treatmentStatuses
    );
    if (this.reportType === 'SURGERY_STATS') {
      this.configureSurgeryCategories();
    } else {
      this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(this.formGroupReference, this.dictionaryOptions);
    }
    this.setInitialValue();
  }

  private configureSurgeryCategories() {
    this.inProgressToComboOptions = this.getInProgressToComboOptions();
    this.rejectedToSecondaryOptions = this.getRejectedToSecondaryOptions();
    const reimbursableDictionaryOption = this.dictionaryOptions.find(option => option.value === this.REIMBURSABLE_VALUE);
    const reimbursableDictionarySubOptionPatient = this.dictionaryOptions.filter(option => option.value === this.PATIENT_VALUE);
    const reimbursableDictionarySubOptionsSurgery = this.dictionaryOptions.filter(
      option => Number(option.value) > Number(this.PATIENT_VALUE)
    );
    this.configureReimbursableOptions(
      reimbursableDictionaryOption,
      reimbursableDictionarySubOptionPatient,
      reimbursableDictionarySubOptionsSurgery
    );
    this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(this.formGroupReference, this.inProgressToComboOptions);
    this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(this.formGroupReference, this.rejectedToSecondaryOptions);
    this.reportFiltersFormGeneratorService.addCheckboxTreeGroup({
      rootGroup: this.formGroupReference,
      dictionaryTree: this.reimbursableOptions,
      groupName: this.REIMBURSABLE_VALUE,
      preselectedRootOptions: new Set([this.REIMBURSABLE_VALUE])
    });
  }

  private configureReimbursableOptions(
    reimbursableDictionaryOption: CommonReportDictionaryItem,
    reimbursableDictionarySubOptionPatient: CommonReportDictionaryItem[],
    reimbursableDictionarySubOptionsSurgery: CommonReportDictionaryItem[]
  ) {
    this.reimbursableOptions.push({
      value: reimbursableDictionaryOption.value,
      label: reimbursableDictionaryOption.label,
      subSections: [
        {
          name: 'patient',
          values: reimbursableDictionarySubOptionPatient
        },
        {
          name: 'surgicalInterventionTypes',
          values: reimbursableDictionarySubOptionsSurgery
        }
      ]
    });
  }

  private getRejectedToSecondaryOptions() {
    return this.dictionaryOptions.filter(
      option =>
        option.value === this.REJECTED_VALUE || option.value === this.EXCEEDS_QUOTA_VALUE || option.value === this.SECONDARY_ONLY_VALUE
    );
  }

  private getInProgressToComboOptions() {
    return this.dictionaryOptions.filter(
      option =>
        option.value === this.IN_PROGRESS_VALUE ||
        option.value === this.SUBMITTED_VALUE ||
        option.value === this.ACCEPTED_VALUE ||
        option.value === this.COMBO_VALUE
    );
  }

  onFilterChange($event: ReportFilterChange) {
    this.filterChange.emit($event);
  }

  private setInitialValue() {
    if (this.reportType === 'SURGERY_STATS') {
      const reimbursableFormControl = this.formGroupReference.get(this.REIMBURSABLE_VALUE);
      reimbursableFormControl.get('rootOptions').get(this.REIMBURSABLE_VALUE).setValue(true);
    }
  }
}

@NgModule({
  declarations: [ReportTreatmentStatusFilterComponent],
  imports: [CheckboxGroupFilterComponentModule, CommonModule, CheckboxTreeFilterComponentModule],
  exports: [ReportTreatmentStatusFilterComponent]
})
export class ReportTreatmentStatusFilterComponentModule {}
