import { STX_MOMENT_DATE_FORMATS } from '@/src/app/utils/date.utils';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Range } from './range';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  static API_FORMAT = STX_MOMENT_DATE_FORMATS.display.dateInput;

  range = new Range();

  toApiFormat(date: moment.Moment | string, inputFormat?: string): string {
    const dateNormalized = this.normalize(date, inputFormat);
    return moment(dateNormalized).format(DateService.API_FORMAT);
  }

  normalize(date: moment.Moment | string, inputFormat?: string): moment.Moment {
    return moment(date, inputFormat || DateService.API_FORMAT);
  }
}
