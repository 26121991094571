import { Component, Input, OnInit } from '@angular/core';
import { PatientTreatmentForReview } from '../../../models/patient-treatment.model';
import { ageMonthToYears } from 'src/app/utils/date.utils';

@Component({
  selector: 'stx-historic-treatment-detail-row',
  templateUrl: './historic-treatment-detail-row.component.html'
})
export class HistoricTreatmentDetailRowComponent implements OnInit {
  @Input() patientTreatmentForReview: PatientTreatmentForReview;
  patientAge: string;

  ngOnInit(): void {
    this.patientAge = ageMonthToYears(this.patientTreatmentForReview.patientAgeInMonths);
  }
}
