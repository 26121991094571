import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { PermissionModule } from '@core/permissions/permission.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../modules/material/material.module';
import { ReadonlyCommonsModule } from '../../modules/readonly-commons/readonly-commons.module';
import { IfEmptyPipeModule } from '../../pipes/if-empty.pipe';
import { FormModule } from '../form/form.module';
import { ModalModule } from '../modal/modal.module';
import { NoticeComponentModule } from '../notice/notice.component';
import { OrthoTreatmentStatusBarComponent } from './components/ortho-treatment-status-bar/ortho-treatment-status-bar.component';
import { SurgicalTreatmentStatusBarComponent } from './components/surgical-treatment-status-bar/surgical-treatment-status-bar.component';
import { TreatmentRecordDateComponent } from './components/treatment-record-date/treatment-record-date.component';
import { TreatmentModalComponent } from './components/treatment-status-bar/components/treatment-modal/treatment-modal.component';
import { TreatmentStatusBarComponent } from './components/treatment-status-bar/treatment-status-bar.component';

@NgModule({
  declarations: [
    TreatmentRecordDateComponent,
    TreatmentStatusBarComponent,
    TreatmentModalComponent,
    SurgicalTreatmentStatusBarComponent,
    OrthoTreatmentStatusBarComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule,
    TranslateModule,
    PermissionModule,
    FormModule,
    ModalModule,
    ReadonlyCommonsModule,
    NoticeComponentModule,
    IfEmptyPipeModule,
    GeneralCommonsModule
  ],
  exports: [
    TreatmentRecordDateComponent,
    TreatmentStatusBarComponent,
    SurgicalTreatmentStatusBarComponent,
    OrthoTreatmentStatusBarComponent
  ]
})
export class TreatmentModule {}
