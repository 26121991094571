import { FileExtension } from '@/src/app/utils/file.utils';
import { Component } from '@angular/core';
import { NavigationSubTabEnum } from '@shared/components/navigation/model/nav-item-enum.enum';
import { Resource, ResourceSection } from '../../models/resource.model';

@Component({
  templateUrl: './resources-for-families-page.component.html'
})
export class ResourcesForFamiliesPageComponent {
  readonly NavigationSubTabEnum = NavigationSubTabEnum;
  readonly source = resourcesForPatientsAndFamiliesSection;
}

export const feedingVideo: Resource = {
  label: 'resources_for_families.feeding_video',
  urlList: [
    {
      label: 'বাংলা',
      labelUrl: 'https://youtu.be/hvcGCITcbjM'
    },
    {
      label: 'English',
      labelUrl: 'https://youtu.be/xXGLhLx12mU'
    },
    {
      label: 'Español',
      labelUrl: 'https://youtu.be/mqnFrd529zQ'
    },
    {
      label: 'हिन्दी',
      labelUrl: 'https://youtu.be/j0nLErEIAyU'
    },
    {
      label: 'Português',
      labelUrl: 'https://youtu.be/F_gvM0biCjs'
    },
    {
      label: 'தமிழ்',
      labelUrl: 'https://youtu.be/0bMgBE4xQMs'
    }
  ]
};

export const feedingBrochure: Resource = {
  label: 'resources_for_families.feeding_brochure',
  resourceName: 'Smile_Train_Cleft_Lip_and_Palate_Feeding_Recommendations_',
  translationList: [
    [
      'am',
      'ar',
      'bg',
      'bn',
      'en',
      'es',
      'fr',
      'gu',
      'hi',
      'hr',
      'ht',
      'hy',
      'id',
      'km',
      'ne',
      'pa',
      'ps',
      'pt',
      'ru',
      'sn',
      'so',
      'sw',
      'th',
      'tr',
      'uk',
      'ur',
      'vi',
      'yo',
      'cn'
    ]
  ]
};
export const resourcesForPatientsAndFamiliesSection: readonly ResourceSection[] = [
  {
    sectionTitle: '⠀',
    resources: [
      {
        label: 'resources_for_families.about_cleft',
        resourceName: 'Smile_Train-About_Cleft_',
        translationList: [['en', 'es', 'fr']],
        isSeparated: true
      },
      {
        label: 'resources_for_families.comprehensive_cleft_care',
        resourceName: 'Smile_Train-Cleft-Care-Timeline_',
        translationList: [['en', 'es', 'fr']],
        isSeparated: true
      },
      {
        label: 'resources_for_families.cleft_durgery_information',
        resourceName: 'Smile_Train_Patient_Safety_Care_',
        translationList: [['en', 'ht', 'id', 'ps', 'pt', 'tl', 'ur', 'vi']],
        isSeparated: true
      }
    ]
  },
  {
    resources: [feedingVideo, feedingBrochure]
  },
  {
    sectionTitle: '⠀',
    resources: [
      {
        label: 'dashboard.patient_awareness',
        resourceName: 'Cleft_Awareness_Poster_',
        translationList: [['en', 'es_mx_ca', 'es_sa', 'fr', 'id']]
      }
    ],
    isSeparated: true
  },
  {
    resources: [
      {
        label: 'resources_for_partners.patient_awareness_flyer.label',
        resourceName: 'Patient_Awareness_Flyer_Template_',
        fileExtension: FileExtension.ZIP,
        showRegions: true,
        translationList: [
          ['am', 'en_af', 'fr', 'ha', 'sw', 'yo'],
          ['en_am', 'es', 'ht', 'pt'],
          ['hy', 'bg', 'hr', 'en_ot', 'ru', 'uk'],
          ['ar', 'en_me', 'tr'],
          ['en_na', 'cn'],
          ['bn', 'en_sa', 'gu', 'hi', 'ne', 'pa', 'te'],
          ['en_se', 'id', 'th', 'vi']
        ]
      }
    ],
    isSeparated: true
  },
  {
    resources: [
      {
        label: 'resources_for_partners.patient_registration_form.label',
        resourceName: 'Smile_Train_Patient_Registration_Form_',
        showRegions: true,
        fileExtension: FileExtension.ZIP,
        translationList: [
          ['am', 'en_af', 'fr', 'sw', 'yo'],
          ['en_am', 'es', 'pt', 'ht', 'en_ht'],
          ['hy', 'hr', 'en_ot', 'ru', 'uk'],
          ['ar', 'en_me', 'tr', 'ur'],
          ['en_na', 'cn'],
          ['bn', 'en_sa', 'gu', 'hi', 'pa', 'te'],
          ['en_se', 'fr_se', 'km', 'th', 'vi']
        ]
      }
    ],
    isSeparated: true
  },
  {
    sectionTitle: 'resources_for_partners.communications.header',
    resources: [
      {
        label: 'resources_for_partners.communications.hints',
        resourceName: 'Patient_Story_Collection_Guide_',
        translationList: [['ar', 'en', 'es', 'fr', 'id', 'pt', 'ru', 'vi', 'cn']],
        isSeparated: true
      },
      {
        label: 'resources_for_partners.communications.social_media',
        resourceName: 'Smile_Train_Social_Media',
        translationList: [['']]
      },
      {
        label: 'resources_for_partners.communications.brand_guidelines',
        resourceName: 'Smile_Train_Brand_Guidelines_for_Partners_',
        translationList: [['en', 'es', 'fr', 'pt']]
      }
    ]
  }
];
