<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="form-element-group-column" [formGroup]="formGroupReference">
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20">
    <div class="form-element-group-column">
      <div class="flex-row-container align-middle">
        <mat-checkbox
          data-testid="checkbox-treatment-typefilter-all-descedants"
          [checked]="areAllDescendantsSelected(node)"
          [indeterminate]="areDescendantsPartiallySelected(node)"
          (change)="toggleExpandableCheckboxNode(node)"
          [ngClass]="{ 'text-uppercase': node.level === 0 }"
        >
          {{ node.treatment.treatmentType.label | translate }}
        </mat-checkbox>
        <button data-testid="button-treatment-type-filter" mat-icon-button matTreeNodeToggle iconPositionEnd>
          <mat-icon>
            {{ treeControl.isExpanded(node) ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        </button>
      </div>
      <div *ngIf="node.treatment.treatmentStatuses">
        <stx-treatment-status-filter
          [statuses]="node.treatment.treatmentStatuses"
          [formControlName]="node.treatment.treatmentType.value"
          [node]="node"
          (statusChange)="onSurgeryInterventionStatusChange($event, node)"
        >
        </stx-treatment-status-filter>
      </div>
    </div>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="20">
    <div class="form-element-group-column terminal-node-section">
      <div>
        <mat-checkbox
          data-testid="checkbox-treatment-type-node"
          [checked]="checklistSelection.isSelected(node)"
          (change)="toggleCheckboxLeaf(node)"
        >
          {{ node.treatment.treatmentType.label | translate }}
        </mat-checkbox>
      </div>
      <div *ngIf="node.treatment.treatmentStatuses">
        <stx-treatment-status-filter
          [formControlName]="node.treatment.treatmentType.value"
          [statuses]="node.treatment.treatmentStatuses"
          [node]="node"
          (statusChange)="onLeafCheckboxStatusChange($event, node)"
        >
        </stx-treatment-status-filter>
      </div>
      <hr class="form-spaced-subsection-separator" *ngIf="node.treatment.isSeparated" />
    </div>
  </mat-tree-node>
</mat-tree>
