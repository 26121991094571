import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component } from '@angular/core';
import { nasalEmissionOptions, phonemeOptions, placementErrorsOptions, soundAssessmentOptions, vpdOptions } from '@utils/speech.utils';

@Component({
  selector: 'stx-speech-assessment-perceptual',
  templateUrl: './speech-assessment-perceptual.component.html',
  styleUrls: ['./speech-assessment-perceptual.component.scss']
})
export class SpeechAssessmentPerceptualComponent extends BaseFormElementComponent {
  nasalEmissionOptions = nasalEmissionOptions;
  phonemeOptions = phonemeOptions;
  soundAssessmentOptions = soundAssessmentOptions;
  vpdOptions = vpdOptions;
  placementErrorsOptions = placementErrorsOptions;
  constructor() {
    super();
  }
}
