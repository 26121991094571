<stx-readonly-view-section-header labelKey="ortho.general_patient_information"> </stx-readonly-view-section-header>
<stx-readonly-view-columns *ngIf="treatment">
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field label="ortho.mid_evaluation_date" [value]="treatment.evaluationDate"></stx-simple-readonly-field>
    <stx-simple-readonly-field label="age" [value]="treatment.ageMonth | ageYearMonth"></stx-simple-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-readonly-field label="treatment_center.treatment_center">
      <stx-tc-link [treatmentCenter]="treatment.treatmentCenter"></stx-tc-link>
    </stx-readonly-field>
    <stx-simple-readonly-field label="status" [value]="treatmentStatusLabel | translate"> </stx-simple-readonly-field>
    <stx-simple-readonly-field label="practitioner.orthodontist" [value]="treatment.practitioner?.name"></stx-simple-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field
      label="reports.orthodontic.oral_health"
      [value]="[treatment.oralHealth | yesNoOrNull, treatment.oralHealthMore]"
    >
    </stx-simple-readonly-field>
    <stx-simple-readonly-field label="presurgical.complication" [value]="[treatment.complication | yesNo, treatment.complicationMore]">
    </stx-simple-readonly-field>
  </ng-container>
</stx-readonly-view-columns>
<stx-readonly-media-group [mediaSets]="photoSets"></stx-readonly-media-group>
