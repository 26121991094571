<stx-details-page *ngIf="details" pageTitleKey="treatment_center.treatment_center" [name]="details.name" [localName]="details.nameLoc">
  <div class="data-section">
    <div class="layout-columns-1-1">
      <div class="data-tile-container">
        <div class="data-tile-row">
          <div class="data-tile">
            <div class="data-tile-content">
              <stx-simple-details-value [value]="details.contact"></stx-simple-details-value>
              <stx-simple-details-value [phone]="details.phone"></stx-simple-details-value>
              <stx-simple-details-value [email]="details.email"></stx-simple-details-value>
            </div>
          </div>
        </div>
        <hr class="data-tile-row-separator" />
        <div class="data-tile-row">
          <div class="data-tile">
            <div class="data-tile-content">
              <stx-simple-details-value [value]="details.street1"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.street1Loc"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.street2"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.street2Loc"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.city"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.province"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.district"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.country"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.zip"></stx-simple-details-value>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ng-container *stxPermitted="permissionEnum.GET_TREATMENT_CENTER_PARTNER_DETAILS">
          <div class="data-tile-container" *ngIf="partnerDetails">
            <div class="data-tile-row">
              <div class="data-tile">
                <div class="data-tile-content">
                  <stx-simple-details-value
                    labelKey="partner.partner"
                    [href]="'partners/' + partnerDetails.id"
                    [value]="partnerDetails.name"
                  ></stx-simple-details-value>
                  <stx-simple-details-value [value]="partnerDetails.vendorId"></stx-simple-details-value>
                  <stx-simple-details-value [value]="partnerDetails.contact"></stx-simple-details-value>
                  <stx-simple-details-value [phone]="partnerDetails.phone"></stx-simple-details-value>
                  <stx-simple-details-value [email]="partnerDetails.email"></stx-simple-details-value>
                </div>
              </div>
            </div>
            <hr class="data-tile-row-separator" />
            <div class="data-tile-row">
              <div class="data-tile">
                <div class="data-tile-content">
                  <stx-simple-details-value labelKey="geography.crm" [value]="partnerDetails.crmName"></stx-simple-details-value>
                  <stx-simple-details-value [email]="partnerDetails.crmEmail"></stx-simple-details-value>
                  <stx-simple-details-value labelKey="partner.partner_since" [value]="partnerDetails.dateSigned"></stx-simple-details-value>
                  <p>
                    <a class="grant-button" href="https://smiletrain.force.com/grantee/" target="_blank">
                      <button data-testid="button-treatment-center" mat-stroked-button color="primary" class="grant-button">
                        {{ 'partner.grant_account' | translate }}
                      </button>
                    </a>
                  </p>
                  <stx-simple-details-value labelKey="partner.paper_records" [value]="partnerDetails.paperCount"></stx-simple-details-value>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="data-tile-container">
          <ng-container *ngIf="details.grade">
            <div class="data-tile-row">
              <div class="data-tile">
                <div class="data-tile-content">
                  <stx-simple-details-value labelKey="grade" [value]="details.grade"></stx-simple-details-value>
                </div>
              </div>
            </div>
            <hr class="data-tile-row-separator" />
          </ng-container>
          <ng-container *ngIf="latestSubmissions.length > 0">
            <div class="data-tile-row">
              <div class="data-tile">
                <div class="data-tile-content">
                  <stx-details-value-list headerKey="common.latest_submissions" [elements]="latestSubmissions"></stx-details-value-list>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="data-tile-row" *ngIf="details.costSharePrimaryNotcovered || details.outreachCenter || details.doNotDisplay">
            <div class="data-tile">
              <div class="data-tile-content">
                <p *ngIf="details.costSharePrimaryNotcovered">
                  {{ 'treatment_center.china_cost_sharing_exemption' | translate }}
                </p>
                <p *ngIf="details.outreachCenter">{{ 'treatment_center.outreach' | translate }}</p>
                <p *ngIf="details.doNotDisplay">{{ 'treatment_center.dont_display' | translate }}</p>
              </div>
            </div>
          </div>
          <ng-container *ngIf="programs.length > 0">
            <div class="data-tile-row">
              <div class="data-tile">
                <div class="data-tile-content">
                  <stx-details-value-list [headerKey]="'treatment.programs'" [elements]="programs"></stx-details-value-list>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="data-section" *stxPermitted="permissionEnum.GET_TREATMENT_CENTER_PRACTITIONERS">
    <div class="data-section-heading">
      {{ 'practitioners' | translate }}
    </div>
    <stx-data-table [dataProvider]="practitionersTableDataProvider">
      <!--prettier-ignore-->
      <tr mat-row *matRowDef="let row; columns: []" [class.data-table-inactive]="!row.practitionerActive"></tr>
    </stx-data-table>
  </div>

  <div class="data-section" *stxPermitted="permissionEnum.GET_TREATMENT_CENTER_USERS">
    <div class="data-section-heading">
      {{ 'users' | translate }}
    </div>
    <stx-data-table [dataProvider]="usersTableDataProvider">
      <!--prettier-ignore-->
      <tr mat-row *matRowDef="let row; columns: []" [class.data-table-inactive]="!row.enabled"></tr>
    </stx-data-table>
  </div>
</stx-details-page>
