<div class="page-with-sidebar">
  <div class="page-body">
    <div class="page-content">
      <ng-content select="[slot=body]"></ng-content>
    </div>
  </div>
  <div class="sidebar-body">
    <div class="sidebar-content">
      <ng-content select="[slot=sidebar]"></ng-content>
    </div>
  </div>
</div>
