<form [formGroup]="profileForm">
  <div class="profile-container flex-row-container">
    <div *ngIf="roles" class="roles flex-column-container">
      <label for="rolePicker" class="text-uppercase m-b-20">{{ 'role' | translate }}</label>
      <mat-radio-group id="rolePicker" class="flex-column-container" formControlName="selectedRole">
        <mat-radio-button data-testid="radio-user-profile-role" *ngFor="let role of roles" [value]="role.id" class="m-b-20">
          {{ role.name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="profile-access-details flex-column-container">
      <label class="text-uppercase m-b-20">{{ 'scope' | translate }}</label>
      <div>
        <mat-checkbox data-testid="checkbox-user-profile" formControlName="isDomainAccess" [disabled]="isDesignatedAreaNotGlobal()">
          {{ 'user.domain_access' | translate }}</mat-checkbox
        >
      </div>
      <div class="scopes flex-row-container">
        <div class="geo-scope" *ngIf="geoScopes">
          <label for="geo" class="text-uppercase m-b-20">{{ 'geography' | translate }}</label>
          <stx-scope-tree
            id="geo"
            #geotree
            [treeData]="geoScopes"
            [selectedScope]="checkedGeo"
            [isDomainAccess]="isDomainAccess"
          ></stx-scope-tree>
        </div>
        <div class="org-scope" *ngIf="orgTreeScopes">
          <label for="org" class="text-uppercase m-b-20">{{ 'orgtree' | translate }}</label>
          <stx-scope-tree
            id="org"
            #orgtree
            [treeData]="orgTreeScopes"
            [selectedScope]="checkedOrg"
            [isDomainAccess]="isDomainAccess"
          ></stx-scope-tree>
        </div>
      </div>
      <div>
        <stx-select
          [formGroup]="profileForm"
          controlName="designatedArea"
          label="user.profile_designated_area"
          [dictionaryList]="instanceAreaList"
        ></stx-select>
      </div>
      <div class="flex-column-container">
        <label for="profileName" class="m-b-5">{{ 'user.profile_name' | translate }}</label>
        <mat-form-field appearance="outline" class="input-field-auth">
          <input
            data-testid="input-profile-name"
            type="text"
            matInput
            formControlName="profileNameFormControl"
            class="input-field-auth"
            id="profileName"
          />
          <mat-error class="stx-mat-error-align" *ngIf="isProfileNameLengthNotValid()">{{ 'error.profile_name' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
