import { LanguagePair } from '@/src/app/shared/components/language-switcher/language.model';
import { FileExtension } from '@/src/app/utils/file.utils';
import { alternativeMappings, languages } from '@/src/app/utils/language.utils';
import { getStaticFileUrl } from '@/src/app/utils/media.utils';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'stx-ortho-treatment-guidelines-link',
  templateUrl: './ortho-treatment-guidelines-link.component.html'
})
export class OrthoTreatmentGuidelinesLinkComponent {
  readonly guidelinesTitle = 'Partner_Guidelines_Orthodontic_Treatment_Grants';

  constructor(private translateService: TranslateService) {}

  get guidelinesLink(): string {
    return getStaticFileUrl(this.getTranslatedPDFName(this.guidelinesTitle, this.translateService.currentLang));
  }

  private getTranslatedPDFName(fileName: string, lang: string) {
    const langShortcutSearch = (lp: LanguagePair) => lp.name === lang;
    const languageShortcut = (alternativeMappings.find(langShortcutSearch) ?? languages.find(langShortcutSearch))?.shortcut ?? 'en';
    return `pdf/${fileName}_${languageShortcut}${FileExtension.PDF}`;
  }
}
