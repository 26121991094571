<div class="relative input-number form-control-margin">
  <stx-form-label [labelFor]="inputId">
    <ng-content></ng-content>
  </stx-form-label>

  <stx-input-base
    [inputType]="hidePassword ? 'password' : 'text'"
    [inputId]="inputId"
    [inputPlaceholder]="placeholder"
    [inputDisabled]="disabled"
    [value]="viewValue"
    [theme]="theme"
    (blurEvent)="onBlur($event)"
    (inputEvent)="onInput($event)"
  >
    <span baseInputSuffix>
      <button data-testid="button-input-password" type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
        <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
    </span>
  </stx-input-base>
  <stx-error-message-wrapper>
    <stx-validation-message *ngIf="ngControl.touched && ngControl.errors" [errors]="ngControl.errors"></stx-validation-message>
  </stx-error-message-wrapper>
</div>
