import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { UntypedFormGroup } from '@angular/forms';
import {
  CheckboxOption,
  ReportFilterChange,
  SpeechOptionsFilter
} from '@src/app/features/reports/components/report-filters/models/report-filters.model';
import { ReportDictionariesService } from '@src/app/features/reports/components/report-filters/services/report-dictionaries.service';
import { ReportFiltersHelperService } from '@src/app/features/reports/components/report-filters/services/report-filters-helper.service';
import { FormModule } from '@shared/components/form/form.module';

@Component({
  selector: 'stx-speech-options-filter',
  templateUrl: './speech-options-filter.component.html'
})
export class SpeechOptionsFilterComponent extends BaseComponent implements OnInit {
  @Input() filterName: SpeechOptionsFilter;
  @Input() formGroupReference: UntypedFormGroup;
  @Output() filterChange = new EventEmitter<ReportFilterChange>();
  speechOptions: CheckboxOption[];

  constructor(
    private reportDictionariesService: ReportDictionariesService,
    private reportFiltersHelperService: ReportFiltersHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.speechOptions = this.getCheckboxGroupOptions();
    this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(this.formGroupReference, this.speechOptions);
    this.subSink.sink = this.formGroupReference.valueChanges.subscribe(this.handleValueChanges.bind(this));
  }

  private handleValueChanges(change: Record<string, boolean>): void {
    const selectedSpeechOptions = this.reportFiltersHelperService.getSelectedCheckboxOptions(change);

    this.filterChange.emit({
      filterName: this.filterName,
      payload: {
        [this.filterName]: selectedSpeechOptions
      }
    });
  }

  private getCheckboxGroupOptions(): CheckboxOption[] {
    return this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(this.getFilterComponentDictionary());
  }

  private getFilterComponentDictionary() {
    switch (this.filterName) {
      case 'speechAssessmentStates':
        return this.reportDictionariesService.dictionaries.speechAssessmentStates;
      case 'speechTreatmentStates':
        return this.reportDictionariesService.dictionaries.speechTreatmentStates;
      case 'speechAssessmentRecommendations':
        return this.reportDictionariesService.dictionaries.speechAssessmentRecommendations;
    }
  }
}

@NgModule({
  declarations: [SpeechOptionsFilterComponent],
  imports: [FormModule],
  exports: [SpeechOptionsFilterComponent]
})
export class SpeechTypeModule {}
