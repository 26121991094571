import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { orthoQaRoute, speechQaRoute, surgicalQaRoute } from '@utils/routing.utils';
import { AuthGuard } from '../authentication/helpers/auth.guard';
import { PermissionGuard } from '../authentication/helpers/permission.guard';
import { DashboardPageComponent } from './components/dashboard-page/dashboard-page.component';
import { OrthoQaDashboardPageComponent } from './components/ortho-qa-dashboard-page/ortho-qa-dashboard-page.component';
import { SpeechQaDashboardPageComponent } from './components/speech-qa-dashboard-page/speech-qa-dashboard-page.component';
import { SurgicalQaDashboardPageComponent } from './components/surgical-qa-dashboard-page/surgical-qa-dashboard-page.component';
import { DashboardPathGuard } from './helpers/dashboard-path.guard';

const routes: Routes = [
  {
    path: '',
    component: DashboardPageComponent,
    canActivate: [AuthGuard, DashboardPathGuard, PermissionGuard],
    canLoad: [AuthGuard],
    data: { permission: PermissionEnum.VIEW_DASHBOARD_MAIN, newStyles: true }
  },
  {
    path: surgicalQaRoute,
    component: SurgicalQaDashboardPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    canLoad: [AuthGuard],
    data: { permission: PermissionEnum.VIEW_DASHBOARD_REVIEW_SURGICAL, newStyles: true }
  },
  {
    path: orthoQaRoute,
    component: OrthoQaDashboardPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    canLoad: [AuthGuard],
    data: { permission: PermissionEnum.VIEW_DASHBOARD_REVIEW_ORTHO, newStyles: true }
  },
  {
    path: speechQaRoute,
    component: SpeechQaDashboardPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    canLoad: [AuthGuard],
    data: { permission: PermissionEnum.VIEW_DASHBOARD_REVIEW_SPEECH, newStyles: true }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {}
