import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { ageMonthToYears } from '@utils/date.utils';

@Pipe({
  name: 'ageYearMonth'
})
export class AgeYearMonthPipe implements PipeTransform {
  transform(value: number): string {
    return ageMonthToYears(value);
  }
}

@NgModule({
  providers: [],
  declarations: [AgeYearMonthPipe],
  exports: [AgeYearMonthPipe]
})
export class AgeYearMonthPipeModule {}
