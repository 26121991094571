import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { GenericFormElementType } from 'src/app/shared/enums/generic-form-element-type';
import { GenericFormModel } from 'src/app/shared/models/generic-form-model.model';

@Component({
  selector: 'stx-generic-form',
  templateUrl: './generic-form.component.html'
})
export class GenericFormComponent {
  @Input() formConfigurationData: GenericFormModel[];
  @Input() form: UntypedFormGroup;
  @Input() submitGenericForm: AsyncButtonClickAction;

  readonly GenericFormElementType = GenericFormElementType;
  isFormValid = true;

  submitForm: AsyncButtonClickAction = () => {
    if (this.form.valid) {
      return this.submitGenericForm();
    } else {
      this.form.markAllAsTouched();
      this.isFormValid = false;
      return of(null);
    }
  };
}
