import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { MediaResource } from '@/src/app/shared/models/media-resource.model';
import { MediaResourceWithLabels } from '@/src/app/shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';

export type WithMedia = {
  mediaResources?: MediaResource[];
};

export class BaseReadonlyViewUtils {
  static findMedia<T extends WithMedia>(parentOrderName: ParentOrderName, treatment: T): MediaResource | null {
    return treatment?.mediaResources?.find(mediaResource => mediaResource.parentOrderName === parentOrderName);
  }

  static findMediaWithLabels<T extends WithMedia>(
    parentOrderName: ParentOrderName,
    labels: string[],
    treatment: T
  ): MediaResourceWithLabels | null {
    const media = this.findMedia(parentOrderName, treatment);
    return media ? { labelKeys: labels, ...media } : null;
  }
}
