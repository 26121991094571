import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { StxTranslateLoader } from '@core/i18n/stx-translate-loader';
import { StxTranslateParser } from '@core/i18n/stx-translate-parser';
import { TranslateLoader, TranslateModule, TranslateParser, TranslateService } from '@ngx-translate/core';
import { ErrorHandlingModule } from '@shared/components/error-handling/error-handling.module';
import { FormModule } from '@shared/components/form/form.module';
import { PatientFormsContainerModule } from '@shared/components/patient-forms-container/patient-forms-container.module';
import { SpinnerModule } from '@shared/components/spinner/spinner.module';
import { MaterialModule } from '@shared/modules/material/material.module';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './features/authentication/authentication.module';
import { AuthService } from './features/authentication/services/auth.service';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { EducationalResourcesModule } from './features/educational-resources/educational-resources.module';
import { MediaModule } from './features/media/media.module';
import { OrthoModule } from './features/ortho/ortho.module';
import { OrthognathicModule } from './features/orthognathic/orthognathic.module';
import { PartnerModule } from './features/partner/partner.module';
import { PatientModule } from './features/patient/patient.module';
import { PractitionerModule } from './features/practitioner/practitioner.module';
import { ReportsModule } from './features/reports/reports.module';
import { ReviewModule } from './features/review/review.module';
import { SpeechModule } from './features/speech/speech.module';
import { SurgicalModule } from './features/surgical/surgical.module';
import { SuspiciousRecordsReviewModule } from './features/suspicious-records/suspicious-records.module';
import { TreatmentCenterModule } from './features/treatment-center/treatment-center.module';
import { NgxMaskModule } from 'ngx-mask';
import { RouteStyleManager } from '@core/style-manager/route-style-manager';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18nService } from '@shared/services/localisation/paginator-i18n.service';
import { MOMENT_ADAPTER_PROVIDERS_ARRAY } from '@utils/date.utils';
import { NavigationComponentModule } from '@shared/components/navigation/navigation.component';
import { FooterComponentModule } from '@shared/components/footer/footer.component';
import { BidiModule } from '@angular/cdk/bidi';
import { initFeatureToggles } from '@src/app/app-initializer.factory';
import { initUserAndLocalisation, loadConfig } from '@src/app/app-initializer.factory';
import { ConfigurationService } from '@shared/services/configuration.service';
import { initSentryIfApplicable } from '@src/app/config/sentry.config';
import { FeatureToggleService } from '@core/feature-toggle/feature-toggle.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

export function createTranslateLoader(httpClient: HttpClient) {
  return new StxTranslateLoader(httpClient);
}

initSentryIfApplicable();

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    AuthenticationModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    DashboardModule,
    EducationalResourcesModule,
    ErrorHandlingModule,
    FlexLayoutModule,
    FormModule,
    MaterialModule,
    MediaModule,
    NgxMaskModule.forRoot(),
    OrthoModule,
    OrthognathicModule,
    PartnerModule,
    PatientFormsContainerModule,
    PatientModule,
    PractitionerModule,
    ReactiveFormsModule,
    ReportsModule,
    ReviewModule,
    SpeechModule,
    SpinnerModule,
    SurgicalModule,
    SuspiciousRecordsReviewModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: createTranslateLoader, deps: [HttpClient] },
      parser: { provide: TranslateParser, useClass: StxTranslateParser }
    }),
    TreatmentCenterModule,
    NavigationComponentModule,
    FooterComponentModule,
    BidiModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [ConfigurationService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initUserAndLocalisation,
      deps: [TranslateService, Injector, AuthService, CurrentUserService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initFeatureToggles,
      deps: [FeatureToggleService],
      multi: true
    },
    RouteStyleManager,
    {
      provide: MatPaginatorIntl,
      deps: [PaginatorI18nService],
      useFactory: (paginatorI18nService: PaginatorI18nService) => paginatorI18nService.getPaginatorIntl()
    },
    ...MOMENT_ADAPTER_PROVIDERS_ARRAY,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  constructor(routeStyleManager: RouteStyleManager) {}
}
