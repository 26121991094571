import { SuspiciousRecordReviewActionType } from '@/src/app/features/suspicious-records/enums/suspicious-records.enum';
import { ProblemType, SuspiciousRecordProblemType } from '../enums/problem-type.enum';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

export class SuspiciousRecordReviewResult {
  constructor(
    public actionType: SuspiciousRecordReviewActionType,
    public treatmentId: TreatmentId,
    public status: SuspiciousRecordProblemType,
    public suspiciousRecordComment?: string,
    public dupTreatmentIds?: number[],
    public problems?: ProblemType[],
    public problemComment?: string
  ) {}
}
