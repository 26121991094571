<div class="form-row">
  <p class="form-section-heading">
    {{ 'speech_assessment.intraoral_assessment' | translate }}
    <stx-tooltip class="hide-on-print" content="speech_instructions.intraoral.methods_subtitle"></stx-tooltip>
  </p>
</div>

<div class="form-columns-1-1">
  <div class="form-column">
    <div class="form-row">
      <stx-radio-group
        [tooltipContent]="'speech_instructions.intraoral.methods_3_comment'"
        [label]="'speech_assessment.fistula'"
        [controlName]="'intraoralFistula'"
        [layoutDirection]="'column'"
        [formGroup]="formGroup"
        [formMode]="formMode"
        [print]="print"
        [options]="staticDictionaries.get('intraoralFistula')"
      ></stx-radio-group>
    </div>
    <div class="form-row">
      <stx-textarea
        [label]="'comments'"
        [controlName]="'intraoralAssessmentComment'"
        [formGroup]="formGroup"
        [formMode]="formMode"
      ></stx-textarea>
    </div>
  </div>
</div>
