import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionEnum } from 'src/app/core/permissions/permission.enum';
import { PermissionGuard } from '../authentication/helpers/permission.guard';
import { PractitionerDetailsPageComponent } from './components/practitioner-details-page/practitioner-details-page.component';
import { PractitionerSearchPageComponent } from './components/practitioner-search-page/practitioner-search-page.component';
import { QaResultsPageComponent } from './components/qa-results/qa-results-page.component';

const routes: Routes = [
  { path: '', component: PractitionerSearchPageComponent, data: { newStyles: true } },
  {
    path: ':id/results',
    component: QaResultsPageComponent,
    canActivate: [PermissionGuard],
    data: { permission: PermissionEnum.GET_PRACTITIONER_QA_REVIEW_DETAILS, newStyles: true }
  },
  {
    path: ':id',
    component: PractitionerDetailsPageComponent,
    canActivate: [PermissionGuard],
    data: { permission: PermissionEnum.GET_PRACTITIONER_DETAILS, newStyles: true }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PractitionerRoutingModule {}
