<div *ngFor="let section of sections" class="data-spaced-column">
  <h2 *ngIf="section.sectionTitle">
    <ng-container *ngIf="section.isTitleTranslated">
      {{ section.sectionTitle }}
    </ng-container>
    <ng-container *ngIf="!section.isTitleTranslated">
      {{ section.sectionTitle | translate }}
    </ng-container>
  </h2>
  <div *ngIf="section.sectionDescription">{{ section.sectionDescription | translate }}</div>
  <ng-container *ngIf="!section.useDivs">
    <ul class="data-spaced-column">
      <ng-container *ngFor="let resource of section.resources">
        <li>
          <ng-container *ngTemplateOutlet="resourceBody; context: { resource: resource }"></ng-container>
        </li>
        <hr class="data-thin-section-separator" *ngIf="resource.isSeparated" />
      </ng-container>
    </ul>
  </ng-container>
  <ng-container *ngIf="section.useDivs">
    <div class="data-spaced-column">
      <ng-container *ngFor="let resource of section.resources">
        <div>
          <ng-container *ngTemplateOutlet="resourceBody; context: { resource: resource }"></ng-container>
        </div>
        <hr class="data-thin-section-separator" *ngIf="resource.isSeparated" />
      </ng-container>
    </div>
  </ng-container>
  <hr *ngIf="section.isSeparated" class="data-thin-section-separator" />
</div>

<ng-template #resourceBody let-resource="resource">
  <div class="resource-spaced-column">
    <ng-container *ngIf="resource.label">
      <div>
        <ng-container *ngIf="resource.labelUrl; else plainLabel">
          <a [href]="resource.labelUrl" target="_blank" rel="noreferrer">{{ resource.label | translate }}</a>
        </ng-container>
        <ng-template #plainLabel>
          <ng-container *ngIf="!!resource.labelSuffixIconName; else noSuffixIconLabel">
            <div class="flex-row-container align-middle">
              <span>{{ resource.label | translate }}</span>
              <ng-container *ngIf="!!resource.labelSuffixIconName">
                <mat-icon class="material-sufix-icon">
                  {{ resource.labelSuffixIconName }}
                </mat-icon>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #noSuffixIconLabel>
            <p>
              {{ resource.label | translate }}
            </p>
          </ng-template>
          <ng-container *ngIf="resource.labelSubtitle">
            <span class="display-block fs-12 text-dark-gray font-italic">{{ resource.labelSubtitle | translate }}</span>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>

    <ng-container *ngIf="resource.translationList?.length > 0">
      <div *ngFor="let translationRow of resource.translationList; let rowIndex = index">
        <p *ngIf="resource.showRegions">{{ regions[rowIndex] | translate }} —</p>
        <div class="flex-row-container wrap">
          <span *ngIf="!!resource.iconName && resource.iconName?.length > 0" class="material-icons">{{ resource.iconName }}</span>
          <ng-container *ngIf="resource.urlListPrefix">
            <span class="prefix-label">{{ resource.urlListPrefix | translate }}</span>
          </ng-container>
          <ng-container *ngFor="let shortcut of translationRow; let translationIndex = index">
            <span class="separated-item separated-item-margin">
              <a href="{{ getEducationalResourceUrl(resource, shortcut) }}" target="_blank" rel="noreferrer">{{ getMapping(shortcut) }}</a>
            </span>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="resource.urlList?.length > 0">
      <div class="{{ resource.useColumnViewForUrlList ? 'flex-column-container' : 'flex-row-container wrap' }}">
        <ng-container *ngIf="resource.urlListPrefix">
          <span class="prefix-label">{{ resource.urlListPrefix | translate }}</span>
        </ng-container>
        <ng-container *ngFor="let urlFromList of resource.urlList">
          <span [class.separated-item]="!resource.useColumnViewForUrlList" class="separated-item-margin">
            <a
              [href]="urlFromList.putBuiltUrlInLabel ? getEducationalResourceUrl(urlFromList) : urlFromList.labelUrl"
              target="_blank"
              rel="noreferrer"
              >{{ urlFromList.label | translate }}</a
            >
          </span>
        </ng-container>
      </div>
    </ng-container>
    <div *ngIf="resource.bottomNote">{{ resource.bottomNote | translate }}</div>
  </div>
</ng-template>
