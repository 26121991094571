import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@shared/services/http/base-http.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { shareReplayBufferSize } from '@utils/http.utils';
import { staticDictionariesUrl } from '@utils/http/http.const';
import { DictionaryTranslation } from '@src/app/features/patient/models/patient.model';

const orthoBaseUrl = 'ortho';

@Injectable({
  providedIn: 'root'
})
export class OrthoService extends BaseHttpService {
  private orthoDictionary$: Observable<Map<string, DictionaryTranslation[]>>;

  constructor(httpClient: HttpClient) {
    super(httpClient, { baseUrl: orthoBaseUrl });
  }

  getStaticDictionaries(): Observable<Map<string, DictionaryTranslation[]>> {
    if (!this.orthoDictionary$) {
      this.orthoDictionary$ = this.httpClient
        .get<Map<string, DictionaryTranslation[]>>(`${this.baseUrl}/${staticDictionariesUrl}`)
        .pipe(shareReplay(shareReplayBufferSize))
        .pipe(map(res => new Map(Object.entries(res))));
    }
    return this.orthoDictionary$;
  }
}
