import { Component, Input } from '@angular/core';
import { PartnerDetailsModel } from '../../models/partner-details.model';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { PartnerService } from '@src/app/features/partner/partner.service';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ListElement } from '@shared/modules/details-commons/components/details-value-list/details-value-list.component';
import {
  DataTableDataProvider,
  GenericDataTableDataProvider
} from '@shared/modules/general-commons/components/data-table/data-table-data-provider';
import { buildDetailsList, buildLatestSubmissionsList } from '@src/app/features/partner/org-tree.utils';
import { map } from 'rxjs/operators';
import { DataTableService } from '@shared/modules/general-commons/components/data-table/data-table.service';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';

@Component({
  selector: 'stx-partner-details',
  templateUrl: './partner-details.component.html'
})
export class PartnerDetailsComponent extends BaseComponent {
  readonly permissionEnum = PermissionEnum;

  private _partnerId: number;
  private _details: PartnerDetailsModel;
  readonly treatmentCentersTableDataProvider: DataTableDataProvider<any>;
  readonly usersTableDataProvider: DataTableDataProvider<any>;

  constructor(
    private readonly partnerService: PartnerService,
    private readonly dataTableService: DataTableService,
    private readonly wsHelperService: WsHelperService,
    private globalErrorHandlerService: GlobalErrorHandlerService
  ) {
    super();
    this.treatmentCentersTableDataProvider = new GenericDataTableDataProvider(
      {
        loadFunction: pageSort => wsHelperService.callWithSpinner(partnerService.getPartnerTreatmentCenters(this._partnerId, pageSort)),
        exportFunction: () => partnerService.getPartnersTreatmentCentersCsvFile(this._partnerId),
        exportFileName: 'STX-partner-treatment-centers.csv'
      },
      wsHelperService,
      globalErrorHandlerService
    );
    this.usersTableDataProvider = new GenericDataTableDataProvider(
      {
        loadFunction: pageSort =>
          this.wsHelperService.callWithSpinner(
            this.partnerService.getPartnerUsers(this._partnerId, pageSort).pipe(map(this.dataTableService.userDataTableTransformation))
          ),
        noResultsMessageKey: 'treatment_center.no_users'
      },
      wsHelperService,
      globalErrorHandlerService
    );
  }

  @Input()
  set partnerId(id: number) {
    if (this._partnerId !== id) {
      this._partnerId = id;
      this.loadDetails(id);
      this.treatmentCentersTableDataProvider.reload();
      this.usersTableDataProvider.reload();
    }
  }

  private loadDetails(id: number) {
    this.subSink.sink = this.wsHelperService
      .callWithSpinner(this.partnerService.getPartnerDetails(id), { redirectOn404StatusCode: true })
      .subscribe(details => {
        this._details = details;
      });
  }

  get details(): PartnerDetailsModel {
    return this._details;
  }

  get latestSubmissions(): ListElement[] {
    return buildLatestSubmissionsList(this.details);
  }

  get programs(): ListElement[] {
    return buildDetailsList(this.details);
  }

  get affiliatedPartners(): ListElement[] {
    return (this.details.affiliatedPartners || []).map(partner => ({ value: partner }));
  }
}
