import { DateService } from '@/src/app/shared/services/date/date.service';
import { DateRange } from '@/src/app/shared/services/date/range';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { FormModule } from '@shared/components/form/form.module';
import { getMoment, getToday } from 'src/app/utils/date.utils';
import { RecordDatePayload, ReportFilterChange, ReportType } from '../../models/report-filters.model';
import { distinct } from 'rxjs/operators';

@Component({
  selector: 'stx-date-filter',
  templateUrl: './date-filter.component.html'
})
export class DateFilterComponent extends BaseComponent implements OnInit {
  @Input() formGroupReference: UntypedFormGroup;
  @Input() reportType: ReportType;
  @Input() useDefaultDates = false;
  @Input() dateFromLabel = 'reports.filters.record_date.label_date_from';
  @Input() dateToLabel = 'reports.filters.record_date.label_date_to';

  @Output() filterChange = new EventEmitter<ReportFilterChange<RecordDatePayload>>();

  readonly today = getToday();

  @Input() dateFromControlName = 'dateFrom';
  @Input() dateToControlName = 'dateTo';

  dateFromControl: UntypedFormControl;
  dateToControl: UntypedFormControl;

  constructor(private dateService: DateService) {
    super();
  }

  ngOnInit() {
    this.setFormControlsReference();
    this.watchDatesChanges();
    this.setDefaultDates();
    this.emitFilterModel();
    this.ensureDateRangeErrorVisibility();
  }

  private setDefaultDates() {
    if (!this.useDefaultDates) {
      return;
    }

    let dateRange: DateRange;

    switch (this.reportType) {
      case 'SURGERY_STATS':
      case 'QA_ORTHO_CASES':
      case 'SPEECH_STATS':
        dateRange = { from: null, to: null };
        break;
      case 'PARTNER_SURGERY_COUNTS':
        dateRange = {
          from: getMoment('2001-01-01'),
          to: this.dateService.range.lastMonth().to
        };
        break;
      case 'SURGICAL_QA':
        dateRange = this.dateService.range.halfYearFromToday();
        break;
      default:
        dateRange = this.dateService.range.lastMonth();
        break;
    }

    this.dateFromControl.setValue(dateRange.from);
    this.dateToControl.setValue(dateRange.to);
  }

  private setFormControlsReference() {
    this.dateFromControl = this.formGroupReference.get(this.dateFromControlName) as UntypedFormControl;
    this.dateToControl = this.formGroupReference.get(this.dateToControlName) as UntypedFormControl;
  }

  private watchDatesChanges() {
    this.subSink.sink = this.formGroupReference.valueChanges.subscribe(() => {
      this.emitFilterModel();
    });
  }

  private emitFilterModel() {
    const dateFrom = this.dateFromControl.value;
    const dateTo = this.dateToControl.value;
    const payload: RecordDatePayload = {
      dateFrom: dateFrom ? [this.dateService.toApiFormat(dateFrom)] : [],
      dateTo: dateTo ? [this.dateService.toApiFormat(dateTo)] : []
    };

    this.filterChange.emit({
      filterName: 'date',
      payload
    });
  }

  private ensureDateRangeErrorVisibility() {
    this.subSink.sink = this.formGroupReference.statusChanges.pipe(distinct()).subscribe(() => {
      const dateRangeError = this.formGroupReference.errors?.dateRangeError;

      if (dateRangeError && this.dateFromControl.valid) {
        this.dateFromControl.markAsTouched();
        this.dateFromControl.markAsDirty();
        this.dateFromControl.setErrors({ dateRangeError });
      } else if (!dateRangeError && this.dateFromControl.value && this.dateFromControl.invalid) {
        this.dateFromControl.updateValueAndValidity();
      }
    });
  }
}

@NgModule({
  imports: [FlexLayoutModule, FormModule],
  declarations: [DateFilterComponent],
  exports: [DateFilterComponent]
})
export class DateFilterComponentModule {}
