import { TreatmentStatus } from '@/src/app/shared/models/form.model';
import { DictionaryTranslation } from '../../patient/models/patient.model';

export interface TreatmentFilter {
  treatmentType: DictionaryTranslation;
  treatmentStatuses?: TreatmentStatus[];
  children?: TreatmentFilter[];
  isSeparated?: boolean;
}

export class TreatmentFilterFlatNode {
  treatment: TreatmentFilter;
  level: number;
  expandable: boolean;
}
