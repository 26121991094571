import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl } from '@angular/forms';
import {
  defaultMilitaryTimeHourSelectionOptions,
  defaultMilitaryTimeMinuteSelectionOptions,
  TimeOption
} from '@shared/components/form/components/time-select/time-select.model';
import { StxValidators } from '@shared/validation/validators';
import moment from 'moment';

@Component({
  selector: 'stx-time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss']
})
export class TimeSelectComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() timeFormatSuffix: string;
  @Input() customErrorText: string;

  timeSelectionForm: UntypedFormGroup;

  hourOptions: TimeOption[];
  minuteOptions: TimeOption[];

  constructor(@Self() @Optional() public ngControl: NgControl, private formBuilder: UntypedFormBuilder) {
    this.initialiseForm();

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  get value(): string {
    return moment({
      hour: this.getTimeSelectionControl('hourControl')?.value ?? 0,
      minute: this.getTimeSelectionControl('minuteControl')?.value ?? 0
    }).format('HH:mm');
  }

  onChange = (_timeSelected: string) => {
    /* Custom Value Accessor method implementation is left empty */
  };

  onTouched = () => {
    /* Custom Value Accessor method implementation is left empty */
  };

  ngOnInit(): void {
    this.initialiseTimeSelectionOptions();
  }

  registerOnChange(fn: (_timeSelected: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(time: string): void {
    if (!!time) {
      const momentFromTimeString = moment(time, 'HH:mm');

      this.setTimeControlValueFromCombinedControlState(momentFromTimeString.hour(), 'hourControl', this.hourOptions);
      this.setTimeControlValueFromCombinedControlState(momentFromTimeString.minute(), 'minuteControl', this.minuteOptions);

      this.onChange(this.value);
    }
  }

  private initialiseTimeSelectionOptions(): void {
    this.hourOptions = defaultMilitaryTimeHourSelectionOptions;
    this.minuteOptions = defaultMilitaryTimeMinuteSelectionOptions;
  }

  private setTimeControlValueFromCombinedControlState(
    timeValueToSet: number,
    timeControlName: string,
    timeControlOptions: TimeOption[]
  ): void {
    this.getTimeSelectionControl(timeControlName).setValue(
      timeControlOptions.find(option => option.timeValue === timeValueToSet)?.timeValue
    );
  }

  private getTimeSelectionControl(controlName: string): AbstractControl {
    return this.timeSelectionForm?.get(controlName);
  }

  private initialiseForm() {
    this.timeSelectionForm = this.formBuilder.group({
      hourControl: [null, StxValidators.required],
      minuteControl: [null, StxValidators.required]
    });
  }

  shouldShowValidationError(): boolean {
    const shouldControlBeValidated = this.ngControl?.touched || this.ngControl?.dirty;
    return shouldControlBeValidated && this.ngControl?.invalid;
  }
}
