<div class="avoid-page-break" [ngStyle]="{ width: imageWidthPx }" *ngIf="!mediaAnonymized; else mediaNotAvailable">
  <div class="display-flex align-middle">
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <div class="not-print m-b-5" [class.display-none]="!lowResUrl || isPlaceholderImage(lowResUrl)">
      <stx-image-rotator
        [imageNativeElement]="previewImage"
        [imageContainerNativeElement]="previewImageContainer"
        [heightAuto]="heightAuto"
        [maxWidth]="imageWidth"
        (rotated)="onImageRotated($event)"
      ></stx-image-rotator>
    </div>
  </div>
  <div #previewImageContainer>
    <img
      #previewImage
      [ngClass]="{ 'cursor-pointer': !!highResUrl && !isPlaceholderImage(highResUrl), 'full-width': fullWidthImage }"
      [class]="imageClass"
      src="{{ lowResUrl }}"
      [matTooltipDisabled]="!highResUrl || isPlaceholderImage(highResUrl)"
      [matTooltip]="'image_high_res' | translate"
      [matTooltipClass]="'tooltip'"
      (click)="openImageViewer()"
      (keyup.enter)="openImageViewer()"
      [alt]="altText"
      [id]="id"
    />
  </div>
  <a class="m-b-25" *ngIf="showRawUrl && rawUrl" target="_blank" rel="noopener noreferrer" href="{{ rawUrl }}">{{
    'image_open' | translate
  }}</a>
</div>

<ng-template #mediaNotAvailable>
  <div *ngIf="mediaAnonymized" [ngStyle]="{ width: imageWidthPx }">
    <div class="display-flex align-middle">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
  <stx-notice>
    {{ 'media.not_available' | translate }}
  </stx-notice>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
