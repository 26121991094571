<div [ngSwitch]="nutritionFormType" class="form-element-secondary-label fs-14">
  <p *ngSwitchCase="NutritionType.INFANT">
    {{ 'nutrition_management.nutritional_status.infant.subtitle_1' | translate }}
    <strong>{{ 'nutrition_management.nutritional_status.subtitle.infant_growth' | translate }}</strong>
    ({{ 'nutrition_management.nutritional_status.subtitle.see' | translate }}
    <a (click)="navigateToResources()">{{ 'nutrition_management.nutritional_status.subtitle.resouces' | translate }}</a
    >)
    {{ 'nutrition_management.nutritional_status.infant.subtitle_2' | translate }}
    <strong>{{ 'nutrition_management.nutritional_status.subtitle.anthro_software' | translate }}</strong>
    ({{ 'nutrition_management.nutritional_status.subtitle.anthro_software_downloadable_from' | translate }}
    <a href="{{ whoAnthroSoftwareLink }}" target="_blank">{{
      'nutrition_management.nutritional_status.subtitle.anthro_software_downloadable_here' | translate
    }}</a
    >)
    {{ 'nutrition_management.nutritional_status.infant.subtitle_3' | translate }}
  </p>
  <p *ngSwitchCase="NutritionType.TODDLER">
    {{ 'nutrition_management.nutritional_status.toddler.subtitle_1' | translate }}
    <strong>{{ 'nutrition_management.nutritional_status.subtitle.infant_growth' | translate }}</strong>
    ({{ 'nutrition_management.nutritional_status.subtitle.see' | translate }}
    <a (click)="navigateToResources()">{{ 'nutrition_management.nutritional_status.subtitle.resouces' | translate }}</a
    >).
    {{ 'nutrition_management.nutritional_status.toddler.subtitle_2' | translate }}
    <strong>{{ 'nutrition_management.nutritional_status.subtitle.anthro_software' | translate }}</strong>
    ({{ 'nutrition_management.nutritional_status.subtitle.anthro_software_downloadable_from' | translate }}
    <a href="{{ whoAnthroSoftwareLink }}" target="_blank">{{
      'nutrition_management.nutritional_status.subtitle.anthro_software_downloadable_here' | translate
    }}</a
    >)
    {{ 'nutrition_management.nutritional_status.toddler.subtitle_3' | translate }}
  </p>
  <p *ngSwitchCase="NutritionType.ADOLESCENT">
    {{ 'nutrition_management.nutritional_status.adolescent.subtitle_1' | translate }}
    <strong>{{ 'nutrition_management.nutritional_status.subtitle.anthro_plus_software' | translate }}</strong>
    ({{ 'nutrition_management.nutritional_status.subtitle.anthro_software_downloadable_from' | translate }}
    <a href="{{ whoAnthroPlusSoftwareLink }}" target="_blank">{{
      'nutrition_management.nutritional_status.subtitle.anthro_software_downloadable_here' | translate
    }}</a
    >)
    {{ 'nutrition_management.nutritional_status.adolescent.subtitle_2' | translate }}
  </p>
  <p *ngSwitchCase="NutritionType.ADULT">
    {{ 'nutrition_management.nutritional_status.adult.subtitle' | translate }}
  </p>
</div>
