<div [formGroup]="formGroupReference">
  <ng-container *ngFor="let controlKey of geoSetup.keys()">
    <ng-container *ngIf="isShown(controlKey)">
      <div class="form-element-group-column">
        <label for="{{ controlKey }}" class="form-element-label">{{ geoSetup.get(controlKey).name | translate }}</label>
        <stx-chips
          [class.display-none]="formGroupReference.get(geoSetup.get(controlKey).formControlName)?.value?.length === 0"
          [itemsList]="formGroupReference.get(geoSetup.get(controlKey).formControlName)?.value"
          [ariaLabel]="geoSetup.get(controlKey).name | translate"
          [removable]="true"
          (removeCallback)="onRemoveChip($event, formGroupReference.get(geoSetup.get(controlKey).formControlName))"
        ></stx-chips>
        <stx-autocomplete-multi-select
          [autoSelectSingleItem]="false"
          [formControlName]="geoSetup.get(controlKey).formControlName"
          [itemList]="geoSetup.get(controlKey).data"
          [id]="controlKey"
        ></stx-autocomplete-multi-select>
      </div>
    </ng-container>
  </ng-container>
</div>
