import { ReadonlyMediaFieldComponent } from '@/src/app/shared/modules/readonly-commons/components/readonly-media-field/readonly-media-field.component';
import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ImageThumbnailComponentModule } from '@shared/components/image-thumbnail/image-thumbnail.component';

@Component({
  selector: 'stx-ortho-compact-report-image-pair',
  templateUrl: './ortho-compact-report-image-pair.component.html',
  styleUrls: ['./ortho-compact-report-image-pair.component.scss']
})
export class OrthoCompactReportImagePairComponent {
  @Input() labelPrefix: string;
  @Input() label: string;
  @Input() preTreatmentImagesUrl: string[];
  @Input() preTreatmentIds: number[];
  @Input() postTreatmentImagesUrl?: string[];
  @Input() postTreatmentIds: number[];
  @Input() showHeaders = false;
  @Input() prePhotoAnonymized = false;
  @Input() postPhotoAnonymized = false;

  get fullImageLabel() {
    return `${this.labelPrefix ?? ''}${this.labelPrefix ? ReadonlyMediaFieldComponent.mediaLabelSeparator : ''}${this.label}`;
  }
}

@NgModule({
  declarations: [OrthoCompactReportImagePairComponent],
  exports: [OrthoCompactReportImagePairComponent],
  imports: [FlexLayoutModule, CommonModule, ImageThumbnailComponentModule, TranslateModule]
})
export class OrthodonticTreatmentImageComponentModule {}
