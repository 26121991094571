import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { CanActivateFn } from '@angular/router';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

export const AuthGuard: CanActivateFn | CanMatchFn = (route, segments) => {
  const router = inject(Router);
  const currentUserService = inject(CurrentUserService);

  const isAuthenticated = !!currentUserService.getCurrentUser();

  if (!isAuthenticated) {
    router.navigateByUrl('/login');
    return false;
  }

  return true;
};
