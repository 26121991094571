import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetadataComponent } from './metadata.component';
import { MaterialModule } from '@shared/modules/material/material.module';
import { PermissionModule } from '@core/permissions/permission.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';

@NgModule({
  declarations: [MetadataComponent],
  exports: [MetadataComponent],
  imports: [CommonModule, MaterialModule, PermissionModule, FlexLayoutModule, GeneralCommonsModule]
})
export class MetadataModule {}
