<stx-resources-navigation selectedItemId="{{ NavigationSubTabEnum.RESOURCES_FOR_FAMILIES }}"></stx-resources-navigation>
<stx-generic-wide-page>
  <stx-page-header [name]="'resources_for_families.title' | translate"></stx-page-header>
  <div class="layout-columns-1-1-1">
    <div>
      <stx-generic-resource-list [sections]="source.slice(0, 2)"></stx-generic-resource-list>
    </div>
    <div>
      <stx-generic-resource-list [sections]="source.slice(2, 5)"></stx-generic-resource-list>
    </div>
    <div>
      <stx-generic-resource-list [sections]="source.slice(5, 6)"></stx-generic-resource-list>
    </div>
  </div>
</stx-generic-wide-page>
