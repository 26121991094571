import { ButtonDisablerService } from '@/src/app/shared/services/button-disabler/button-disabler.service';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../../base-component/base.component';

@Component({
  selector: 'stx-submit-button',
  templateUrl: './submit-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent extends BaseComponent implements OnInit {
  @Input() buttonDisabled = false;
  /**Does not handle button locking, use asyncAction input instead*/
  @Output() action = new EventEmitter<void>();
  @Input() asyncAction: AsyncButtonClickAction;
  actionInProgress = false;

  constructor(public buttonDisablerService: ButtonDisablerService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subSink.sink = this.buttonDisablerService.disabler$.subscribe(() => {
      this.cd.detectChanges();
    });
  }

  handleClick() {
    if (!this.asyncAction) {
      this.action.emit();
    }

    if (this.asyncAction && !this.actionInProgress) {
      this.actionInProgress = true;
      this.subSink.sink = this.asyncAction().subscribe({
        next: () => {
          this.actionInProgress = false;
          this.cd.detectChanges();
        },
        error: () => {
          this.actionInProgress = false;
          this.cd.detectChanges();
        }
      });
    }
  }
}
