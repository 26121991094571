import { Component } from '@angular/core';
import { BaseNutritionSectionComponent } from '@src/app/features/nutrition-management/shared/base-nutrition-section.component';
import { feedingAssessmentOptions, NutritionSection } from '@src/app/features/nutrition-management/utils/nutrition-management.utils';
import { CheckboxWithSubtitle } from '@src/app/features/nutrition-management/models/nutrition-item.model';

@Component({
  selector: 'stx-feeding-assessment',
  templateUrl: './nutrition-feeding-assessment.component.html'
})
export class NutritionFeedingAssessmentComponent extends BaseNutritionSectionComponent {
  readonly NutritionSection = NutritionSection;
  readonly formGroupErrorName = 'feedingFields';
  get options(): CheckboxWithSubtitle[][] {
    return feedingAssessmentOptions[this.nutritionFormType];
  }

  get showError(): boolean {
    return this.showStandardError(this.formGroup, null, [this.formGroupErrorName]);
  }
}
