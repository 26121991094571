import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UppyModule } from '@shared/components/uppy/uppy.module';
import { FormModule } from 'src/app/shared/components/form/form.module';
import { TreatmentModule } from 'src/app/shared/components/treatment/treatment.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { PatientDocumentUploadModule } from '@shared/components/patient-document-upload/patient-document-upload.module';
import { SpeechInstructionsComponent } from './instructions/speech-instructions.component';
import { InstrumentalAssessmentColumnComponent } from './instrumental-assessment/instrumental-assessment-column/instrumental-assessment-column.component';
import { InstrumentalAssessmentComponent } from './instrumental-assessment/instrumental-assessment.component';
import { IntraoralAssessmentComponent } from './intraoral-assessment/intraoral-assessment.component';
import { SpeechAssessmentPerceptualComponent } from './speech-assessment-perceptual/speech-assessment-perceptual.component';
import { SpeechAssessmentRecommendationsComponent } from './speech-assessment-recommendations/speech-assessment-recommendations.component';
import { SpeechAssessmentComponent } from './speech-assessment/speech-assessment.component';
import { SpeechBasicInfoComponent } from './speech-basic-info/speech-basic-info.component';
import { SpeechSampleVideoRecordingColumnComponent } from './speech-sample-video-recording/speech-sample-video-recording-column/speech-sample-video-recording-column.component';
import { SpeechSampleVideoRecordingComponent } from './speech-sample-video-recording/speech-sample-video-recording.component';
import { SpeechTreatmentComponent } from './speech-treatment/speech-treatment.component';
import { TreatmentGoalComponent } from './treatment-goal/treatment-goal.component';
import { TreatmentOutcomesComponent } from './treatment-outcomes/treatment-outcomes.component';
import { TreatmentRecommendationsComponent } from './treatment-recommendations/treatment-recommendations.component';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { SpeechAssessmentReadonlyComponent } from '@src/app/features/speech/components/speech-assessment-readonly/speech-assessment-readonly.component';
import { SpeechTreatmentReadonlyComponent } from '@src/app/features/speech/components/speech-treatment-readonly/speech-treatment-readonly.component';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { FormsCommonsModule } from '@shared/modules/forms-commons/forms-commons.module';
import { LocalNamePipeModule } from '@shared/pipes/local-name.pipe';

@NgModule({
  declarations: [
    InstrumentalAssessmentColumnComponent,
    InstrumentalAssessmentComponent,
    IntraoralAssessmentComponent,
    SpeechAssessmentComponent,
    SpeechAssessmentPerceptualComponent,
    SpeechAssessmentReadonlyComponent,
    SpeechAssessmentRecommendationsComponent,
    SpeechBasicInfoComponent,
    SpeechInstructionsComponent,
    SpeechSampleVideoRecordingColumnComponent,
    SpeechSampleVideoRecordingComponent,
    SpeechTreatmentComponent,
    SpeechTreatmentReadonlyComponent,
    TreatmentGoalComponent,
    TreatmentOutcomesComponent,
    TreatmentRecommendationsComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormModule,
    FormsCommonsModule,
    GeneralCommonsModule,
    MaterialModule,
    PatientDocumentUploadModule,
    ReactiveFormsModule,
    ReadonlyCommonsModule,
    RouterModule,
    TranslateModule.forChild(),
    TreatmentModule,
    UppyModule,
    LocalNamePipeModule
  ],
  exports: [SpeechAssessmentComponent, SpeechTreatmentComponent]
})
export class SpeechModule {}
