export class StringUtils {
  static isEmpty(str: string): boolean {
    return !str || str.length === 0;
  }

  static format(str: string, ...args: string[]) {
    return str.replace(/{(\d+)}/g, (match, index) => args[index] || '');
  }

  static isString(message: unknown): boolean {
    return typeof message === 'string' || message instanceof String;
  }
}
