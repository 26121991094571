import { Component, OnChanges, OnInit } from '@angular/core';
import { BaseNutritionSectionComponent } from '@src/app/features/nutrition-management/shared/base-nutrition-section.component';
import { NutritionCheckboxItem } from '@src/app/features/nutrition-management/models/nutrition-item.model';
import { anemiaManagementOptions } from '@src/app/features/nutrition-management/utils/nutrition-management.utils';

@Component({
  selector: 'stx-nutrition-anemia-management',
  templateUrl: './nutrition-anemia-management.component.html'
})
export class NutritionAnemiaManagementComponent extends BaseNutritionSectionComponent implements OnInit, OnChanges {
  anemiaManagementOptions: NutritionCheckboxItem[] = [];

  ngOnChanges(): void {
    this.updateOptions();
  }

  ngOnInit(): void {
    this.updateOptions();
  }

  updateOptions(): void {
    this.anemiaManagementOptions = anemiaManagementOptions.filter(option => option.showFor.includes(this.nutritionFormType));
  }
}
