<div [formGroup]="formGroup" class="flex-column-container" [ngClass]="{ 'no-form-field-spacing': noFormFieldSpacing }">
  <div class="stx-form-field flex-column-container">
    <label class="form-element-label"> {{ label | translate }}</label>
    <stx-chips
      *ngIf="showChips && chipValue"
      [itemsList]="[chipValue]"
      [ariaLabel]="chipValue.name"
      [removable]="true"
      (removeCallback)="formControl.reset()"
    ></stx-chips>

    <mat-form-field [ngClass]="{ 'text-rtl': isRtlLanguage }" appearance="outline" *ngIf="items">
      <mat-select [ngClass]="{ 'hide-on-print': print }" [formControlName]="controlName">
        <ng-container *ngIf="hasBlankOption">
          <mat-option [value]="null">{{ 'none_selected' | translate }}</mat-option>
        </ng-container>
        <mat-option *ngFor="let value of items" [disabled]="value.isActive === false" [value]="value.id">
          <ng-container *ngIf="shouldTranslateName; else notTranslatedName">
            {{ value.name | translate }}
          </ng-container>

          <ng-template #notTranslatedName>
            {{ value.name }}
          </ng-template>
          <ng-container *ngIf="value.nameLoc"> ({{ value.nameLoc }})</ng-container>
        </mat-option>
      </mat-select>
      <mat-error class="stx-mat-error-align" [ngClass]="{ 'hide-on-print': print }" *ngIf="showStandardError(formGroup, controlName)">
        <stx-error-message-wrapper>
          <ng-container *ngIf="!!customErrorText; else defaultErrorText"> {{ customErrorText }}</ng-container>
          <ng-template #defaultErrorText>
            {{ validationErrorPath | translate }}
          </ng-template>
        </stx-error-message-wrapper>
      </mat-error>
    </mat-form-field>
  </div>
</div>
