<div class="tree-container flex-column-container">
  <mat-form-field class="input-field-auth" appearance="outline">
    <input data-testid="input-search" matInput type="search" (input)="onSearchChange($event.target.value)" />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <mat-checkbox data-testid="checkbox-scope-tree-node-t1" (change)="toggleSelectAll()" [checked]="isSelectAll" [disabled]="isDomainAccess">
    {{ 'select_all' | translate }}
  </mat-checkbox>

  <div class="tree-overlay">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree-container">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button data-testid="button-scope-tree-node-t1" mat-icon-button disabled></button>
        <mat-checkbox
          data-testid="checkbox-scope-tree"
          class="checklist-leaf-node"
          [checked]="checklistSelection.isSelected(node) || isDomainAccess || isSelectAll"
          (change)="childSelectionToggle(node)"
          [disabled]="isDomainAccess"
          >{{ node.scope.value }}</mat-checkbox
        >
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button data-testid="button-scope-tree-node-t2" mat-icon-button matTreeNodeToggle>
          <mat-icon>
            {{ treeControl.isExpanded(node) ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        </button>
        <mat-checkbox
          data-testid="checkbox-scope-tree-node-t2"
          [checked]="descendantsAllSelected(node) || isDomainAccess || isSelectAll"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="parentSelectionToggle(node)"
          [disabled]="isDomainAccess"
          >{{ node.scope.value }}</mat-checkbox
        >
      </mat-tree-node>
    </mat-tree>
  </div>
</div>
