<div class="form-control-margin">
  <fieldset>
    <legend class="form-element-label">
      {{ 'reports.filters.quality_assurance.title' | translate }}
    </legend>
    <div class="form-control-margin">
      <stx-checkbox-group
        layoutDirection="column"
        [formGroup]="formGroupReference.get('surgicalIntervention')"
        [options]="qualityAssuranceOptions.surgicalIntervention"
      ></stx-checkbox-group>
    </div>
    <div class="form-control-margin">
      <stx-checkbox-group
        [formGroup]="formGroupReference.get('surgeryStatus')"
        [options]="qualityAssuranceOptions.surgeryStatus"
        [layoutDirection]="'row'"
      ></stx-checkbox-group>
    </div>
    <div class="form-control-margin">
      <stx-checkbox-group
        label="reports.filters.quality_assurance.pre_rank.title"
        [formGroup]="formGroupReference.get('preRank')"
        [options]="qualityAssuranceOptions.preRank"
        [layoutDirection]="'row'"
      ></stx-checkbox-group>
    </div>
    <div class="form-control-margin">
      <stx-checkbox-group
        label="reports.filters.quality_assurance.post_rank.title"
        [formGroup]="formGroupReference.get('postRank')"
        [options]="qualityAssuranceOptions.postRank"
        [layoutDirection]="'row'"
      ></stx-checkbox-group>
    </div>
  </fieldset>
</div>
