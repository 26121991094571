import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { registerUpdateValueAndValidityForAllControls } from '@/src/app/shared/validation/validation.utils';
import { Component, Input } from '@angular/core';
import { goalCorrectAllTimes, speechTreatmentGoals } from '../utils/speech-treatment.utils';
import { FormMode } from '@utils/form.utils';

@Component({
  selector: 'stx-treatment-goal',
  templateUrl: './treatment-goal.component.html',
  styleUrls: ['./treatment-goal.component.scss']
})
export class TreatmentGoalComponent extends BaseFormElementComponent {
  readonly goalCorrectAllTimes = goalCorrectAllTimes;

  @Input() goalNumber: number;
  @Input() minGoalStartedDate: moment.Moment;

  speechTreatmentGoals = speechTreatmentGoals;
  constructor() {
    super();
  }

  clearGoal(field: string) {
    this.formGroup.get(field).reset();
    registerUpdateValueAndValidityForAllControls(this.formGroup, this.subSink);
  }

  clearGoalSection(): void {
    const goalControlNames: Array<string> = Object.keys(this.formGroup.controls).filter(controlName =>
      controlName.includes(`goal${this.goalNumber}`)
    );

    goalControlNames.forEach(controlName => {
      this.formGroup.get(controlName).reset();
    });

    registerUpdateValueAndValidityForAllControls(this.formGroup, this.subSink);
  }

  get isGoalSectionExpanded(): boolean {
    return this.goalNumber === 1 || this.formMode === FormMode.READONLY || this.print;
  }
}
