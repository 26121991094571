<ng-container *ngIf="records.length">
  <mat-paginator [length]="10000" [pageSize]="pagination.pageSize" [hidePageSize]="true" (page)="goToPage($event)"></mat-paginator>

  <div class="table-container-common">
    <table [dataSource]="records" class="mat-elevation-z2" mat-table matSort aria-describedby="table-container-common">
      <ng-container [matColumnDef]="'partnerName'" [sticky]="true">
        <th mat-header-cell *matHeaderCellDef class="no-sticky-border">
          {{ headers.partnerName }}
        </th>
        <td mat-cell *matCellDef="let record" class="no-sticky-border">
          <span class="display-block full-width">
            <span class="fs-14">
              {{ record.partnerName }}
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="'patient'" [sticky]="true">
        <th mat-header-cell *matHeaderCellDef>
          <span class="fs-14">{{ headers.patient | translate }}</span>
        </th>
        <td mat-cell *matCellDef="let record">
          <stx-patient-link [patientId]="record.patientId">
            <span class="block full-width fs-14">
              {{ record.firstName }} {{ record.firstNameLoc }}<br />
              {{ record.middleName }} {{ record.middleNameLoc }}<br />
              {{ record.lastName }} {{ record.lastNameLoc }}<br />
              {{ record.patientId }}
            </span>
          </stx-patient-link>
        </td>
      </ng-container>

      <ng-container *ngFor="let columnName of columnsIterable" [matColumnDef]="columnName">
        <th mat-header-cell *matHeaderCellDef>
          {{ headers[columnName] }}
        </th>
        <td mat-cell *matCellDef="let record">
          <span class="fs-14">{{ record[columnName] }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsAll"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsAll" class="table-row"></tr>
    </table>
  </div>
  <mat-paginator [length]="totalResults" [hidePageSize]="true" (page)="goToPage($event)"></mat-paginator>
</ng-container>

<div *ngIf="reportTableDataService.dataReady && !records.length">
  <stx-empty-table-state></stx-empty-table-state>
</div>
