import { orthoTypeToTranslationKeyMap } from '@/src/app/features/ortho/utils/ortho.utils';
import { BaseReportTableComponent } from '@/src/app/features/reports/components/base-report-table/base-report-table.component';
import { PatientLinkComponentModule } from '@/src/app/features/reports/components/patient-link/patient-link.component';
import { ReportFiltersHelperService } from '@/src/app/features/reports/components/report-filters/services/report-filters-helper.service';
import { ReportService } from '@/src/app/features/reports/components/report-filters/services/report.service';
import { TreatmentDateComponentModule } from '@/src/app/features/reports/components/treatment-date/treatment-date.component';
import {
  OrthodonticInterventionTypes,
  OrthodonticTreatmentReportRecord,
  OrthoRoutes
} from '@/src/app/features/reports/features/orthodontic-treatments-report/orthodontic-treatments-report.model';
import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { ReadonlyCommonsModule } from '@/src/app/shared/modules/readonly-commons/readonly-commons.module';
import { IfEmptyPipeModule } from '@/src/app/shared/pipes/if-empty.pipe';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { ImageThumbnailComponentModule } from '@shared/components/image-thumbnail/image-thumbnail.component';
import { TreatmentStatusIndicatorComponentModule } from '@shared/components/treatment-status-indicator/treatment-status-indicator.component';
import { YesNoPipeModule } from '@shared/modules/readonly-commons/pipes/yes-no/yes-no.pipe';
import {
  mixedDentitionAssessmentAppRoute,
  mixedDentitionMidAppRoute,
  mixedDentitionTreatmentAppRoute,
  permanentDentitionAssessmentAppRoute,
  permanentDentitionMidAppRoute,
  permanentDentitionTreatmentAppRoute,
  psioAssessmentAppRoute,
  psioTreatmentAppRoute
} from '@src/app/features/ortho/utils/ortho-routes.consts';
import { SimpleReportCellComponentModule } from '@src/app/features/reports/components/simple-report-cell/simple-report-cell.component';
import { orthoRoute } from '@utils/routing.utils';
import { ReportTableDataService } from 'src/app/features/reports/components/report-filters/services/report-table-data.service';
import { BracketsPipeModule } from '@shared/pipes/brackets.pipe';

@Component({
  selector: 'stx-orthodontic-treatment-report-table',
  templateUrl: './orthodontic-treatment-report-table.component.html'
})
export class OrthodonticTreatmentReportTableComponent
  extends BaseReportTableComponent<OrthodonticTreatmentReportRecord>
  implements OnInit, OnDestroy
{
  orthoRoute = orthoRoute;
  reportColumns: string[] = [
    'orthoType',
    'treatmentCenter',
    'patient',
    'assessment',
    'treatment',
    'midAssessment',
    'applianceType',
    'other'
  ];
  orthoApplianceTypeFieldToTranslationKeyMap: Map<string, string> = new Map<string, string>([
    ['tapingAcrossLip', 'presurgical.tap_lip'],
    ['nasalStent', 'presurgical.nasal_stent'],
    ['maxillaryPlate', 'presurgical.max_appliance'],
    ['namAppliance', 'presurgical.nam'],
    ['fixedApp', 'mixed_dentition.fixed_app'],
    ['maxExp', 'mixed_dentition.max_exp'],
    ['faceMask', 'mixed_dentition.face_mask'],
    ['fullFixedApp', 'permanent_dentition.full_fixed_app'],
    ['splints', 'permanent_dentition.surgical_splints_short']
  ]);

  orthoRoutes: Record<OrthodonticInterventionTypes, OrthoRoutes> = {
    [OrthodonticInterventionTypes.permanentDentition]: {
      treatment: permanentDentitionTreatmentAppRoute,
      assessment: permanentDentitionAssessmentAppRoute,
      midAssessment: permanentDentitionMidAppRoute
    },
    [OrthodonticInterventionTypes.mixedDentition]: {
      treatment: mixedDentitionTreatmentAppRoute,
      assessment: mixedDentitionAssessmentAppRoute,
      midAssessment: mixedDentitionMidAppRoute
    },
    [OrthodonticInterventionTypes.presurgicalInfantOrthopedics]: {
      treatment: psioTreatmentAppRoute,
      assessment: psioAssessmentAppRoute
    }
  };
  orthoTypeToTranslationKeyMap = orthoTypeToTranslationKeyMap;

  constructor(
    public changeDetector: ChangeDetectorRef,
    public reportTableDataService: ReportTableDataService,
    public reportService: ReportService,
    public reportFiltersHelperService: ReportFiltersHelperService,
    public route: ActivatedRoute
  ) {
    super('ORTHODONTIC_TREATMENTS', changeDetector, reportTableDataService, reportService, route);
  }

  getOrthoApplianceTypes(recordData: OrthodonticTreatmentReportRecord): string[] {
    const applianceTypes = [];
    this.orthoApplianceTypeFieldToTranslationKeyMap.forEach((translationKey, fieldName) => {
      if (recordData[fieldName]) {
        applianceTypes.push(translationKey);
      }
    });
    return applianceTypes;
  }

  getMidDataToDisplay(recordData: OrthodonticTreatmentReportRecord): string[] {
    const dataToDisplay = [recordData.reimbursableMidEvaluationDate];
    if (recordData.reimbursableMidInScope) {
      dataToDisplay.push(recordData.reimbursableMidOrthodontistName);
    }
    return dataToDisplay;
  }

  ngOnInit(): void {
    super.onInit();

    this.watchTableStructure();
  }

  watchTableStructure() {
    this.subSink.sink = this.reportTableDataService.tableStructure$.subscribe(() => {
      if (this.reportService.reportFiltersFormGroup?.get('includePhotos')?.value && !this.reportColumns.includes('photos')) {
        this.reportColumns.push('photos');
      }

      if (!this.reportService.reportFiltersFormGroup.get('includePhotos')?.value && this.reportColumns.includes('photos')) {
        this.reportColumns = this.reportColumns.filter(columnName => columnName !== 'photos');
      }
    });
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }
}

@NgModule({
  declarations: [OrthodonticTreatmentReportTableComponent],
  imports: [
    MatTableModule,
    MatSortModule,
    TranslateModule,
    CommonModule,
    MatIconModule,
    RouterModule,
    ImageThumbnailComponentModule,
    YesNoPipeModule,
    MatPaginatorModule,
    PatientLinkComponentModule,
    TreatmentStatusIndicatorComponentModule,
    TreatmentDateComponentModule,
    EmptyTableStateComponentModule,
    ReadonlyCommonsModule,
    IfEmptyPipeModule,
    SimpleReportCellComponentModule,
    FlexModule,
    GeneralCommonsModule,
    BracketsPipeModule
  ],
  exports: [OrthodonticTreatmentReportTableComponent]
})
export class OrthodonticTreatmentReportTableComponentModule {}
