export enum FormStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  EXCEEDS_QUOTA = 'EXCEEDS_QUOTA',
  DISCONTINUED = 'DISCONTINUED',
  DELETED = 'DELETED'
}

export const TreatmentStatusLabel = new Map<FormStatus, string>([
  [FormStatus.IN_PROGRESS, 'treatment.status.in_progress'],
  [FormStatus.SUBMITTED, 'treatment.status.submitted'],
  [FormStatus.ACCEPTED, 'treatment.status.accepted'],
  [FormStatus.REJECTED, 'treatment.status.rejected'],
  [FormStatus.EXCEEDS_QUOTA, 'treatment.status.exceeds_quota'],
  [FormStatus.DISCONTINUED, 'treatment.status.discontinued'],
  [FormStatus.DELETED, 'treatment.status.deleted']
]);
