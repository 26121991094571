import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../base-component/base.component';
import { FormType } from '@shared/enums/form-type.enum';
import { AbstractControl, ControlContainer, UntypedFormGroup } from '@angular/forms';
import { MediaUrl } from '@src/app/features/media/models/media-url.model';
import { NoticeType } from '@shared/components/notice/notice.model';
import { findMediaUrl } from '@shared/models/media-resource.model';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { PatientDataService } from '@src/app/features/patient/services/patient-data.service';
import { FileInfo } from '@src/app/features/media/models/media.model';

@Component({
  selector: 'stx-patient-release-form-offline',
  templateUrl: './patient-release-form-offline.component.html',
  styleUrls: ['./patient-release-form-offline.component.scss']
})
export class PatientReleaseFormOfflineComponent extends BaseComponent implements OnInit {
  readonly formType = FormType.PATIENT;
  @Input() patientDataMatchesReleaseFormData: boolean | null;
  private _formControl: AbstractControl;
  existingOfflinePrf: FileInfo | null = null;
  existingPdf: MediaUrl | null = null;

  get noticeType(): NoticeType {
    if (!this.existingPdf && !this.existingOfflinePrf) {
      return 'notice';
    }
    if (this.patientDataMatchesReleaseFormData === null) {
      return 'notice';
    }
    return this.patientDataMatchesReleaseFormData ? 'success' : 'warn';
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly controlContainer: ControlContainer,
    private readonly patientDataService: PatientDataService
  ) {
    super();
  }

  ngOnInit(): void {
    const formGroup = this.controlContainer!.control as UntypedFormGroup;
    this._formControl = formGroup.get('offlineReleaseForm')!;
    this.watchExistingPdfChangeEvent();
    this.watchExistingOfflinePrfChangeEvent();
  }

  private watchExistingPdfChangeEvent() {
    this.subSink.sink = this.patientDataService.patient$.subscribe(patient => {
      this.existingPdf = findMediaUrl(patient, ParentOrderName.RELEASE_FORM);
      this.changeDetectorRef.markForCheck();
    });
  }

  private watchExistingOfflinePrfChangeEvent() {
    this.existingOfflinePrf = this._formControl.value;
    this.subSink.sink = this._formControl.valueChanges.subscribe(value => {
      this.existingOfflinePrf = value;
      this.changeDetectorRef.markForCheck();
    });
  }

  onNewFileUploaded(fileInfo: FileInfo) {
    this._formControl.setValue(fileInfo);
  }

  remove() {
    this._formControl.reset();
    this.existingPdf = null;
  }
}
