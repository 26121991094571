import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PartnerService } from 'src/app/features/partner/partner.service';
import { PaperRecord } from '../../models/paper-records.model';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  selector: 'stx-paper-records',
  templateUrl: './paper-records.component.html'
})
export class PaperRecordsComponent implements OnInit {
  paperRecordsData: PaperRecord[];
  paperRecordsTotal: number;
  readonly displayedColumns: string[] = ['partner', 'treatmentCenter', 'paperRecords'];
  constructor(private partnerService: PartnerService, private cd: ChangeDetectorRef, private readonly wsHelperService: WsHelperService) {}

  ngOnInit() {
    this.getPaperRecords();
  }

  private getPaperRecords(): void {
    this.wsHelperService.callWithSpinner(this.partnerService.getPaperRecords()).subscribe(records => {
      this.paperRecordsData = records.data;
      this.paperRecordsTotal = records.total;
      this.cd.detectChanges();
    });
  }
}
