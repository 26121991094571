<div [ngClass]="{ 'p-t-30 page-break-inside-avoid': print }">
  <div class="form-row">
    <p class="form-section-heading">
      {{ 'speech_assessment.perceptual_assessment' | translate }}
      <stx-tooltip class="hide-on-print" content="speech_assessment.tooltip.perceptual_assessment"></stx-tooltip>
    </p>
  </div>

  <div class="form-columns-1-1" [ngClass]="{ 'flex-row-important': print }">
    <div>
      <div class="form-row">
        <stx-radio-group
          [label]="'speech_assessment.resonance'"
          [layoutDirection]="'row'"
          [controlName]="'resonance'"
          [formGroup]="formGroup"
          [formMode]="formMode"
          [options]="staticDictionaries.get('resonance')"
          [print]="print"
        >
        </stx-radio-group>
      </div>

      <hr class="form-subsection-separator" />

      <div class="form-row" [ngClass]="{ 'p-t-15': print }">
        <p class="form-subsection-heading">
          {{ 'speech_assessment.nasal_emission' | translate }}
        </p>
      </div>

      <div class="form-row">
        <stx-checkbox-group
          [formGroup]="formGroup"
          [formMode]="formMode"
          [options]="nasalEmissionOptions"
          [layoutDirection]="'column'"
          [print]="print"
          [formGroupErrorNames]="['nasalRatingEmission']"
          [deselectTriggerControlName]="'nasalEmissionNone'"
          [nestedOptions]="phonemeOptions"
        ></stx-checkbox-group>
      </div>

      <div class="form-row indented-form-block">
        <stx-checkbox-group
          [formGroup]="formGroup"
          [formMode]="formMode"
          [options]="phonemeOptions"
          [label]="'speech_assessment.nasal_emission_phoneme_articulation_errors_yes'"
          [layoutDirection]="'row'"
          [print]="print"
          [formGroupErrorNames]="['nasalEmissionPhoneme']"
          [parentControlName]="'nasalEmissionPhoneme'"
        ></stx-checkbox-group>
      </div>
    </div>
    <div>
      <div class="form-row">
        <p class="form-subsection-heading">
          {{ 'speech_assessment.sound_assessment' | translate }}
        </p>
      </div>

      <div class="form-row">
        <div>
          <span class="form-element-label">
            {{ 'speech_assessment.compensatory_articulation_errors' | translate }}
            <stx-tooltip class="hide-on-print" content="speech_assessment.tooltip.compensatory_productions"></stx-tooltip>
          </span>
          <stx-checkbox-group
            [formGroup]="formGroup"
            [formMode]="formMode"
            [options]="vpdOptions"
            [layoutDirection]="'column'"
            [print]="print"
          ></stx-checkbox-group>
        </div>
      </div>

      <div class="form-row">
        <div>
          <span class="form-element-label">
            {{ 'speech_assessment.obligatory_distortions_passive' | translate }}
            <stx-tooltip class="hide-on-print" content="speech_assessment.tooltip.obligatory_distortions"></stx-tooltip>
          </span>
          <stx-checkbox-group
            [formGroup]="formGroup"
            [formMode]="formMode"
            [options]="soundAssessmentOptions"
            [layoutDirection]="'row'"
            [print]="print"
          ></stx-checkbox-group>
        </div>
      </div>

      <hr class="form-subsection-separator" />

      <div class="form-row">
        <div>
          <span class="form-element-label">
            {{ 'speech_assessment.placement_errors' | translate }}
            <stx-tooltip class="hide-on-print" content="speech_instructions.assessment.sound.errors_subtitle"></stx-tooltip>
          </span>
          <stx-checkbox-group
            [formGroup]="formGroup"
            [formMode]="formMode"
            [options]="placementErrorsOptions"
            [layoutDirection]="'row'"
            [print]="print"
          ></stx-checkbox-group>
        </div>
      </div>
      <div class="form-row">
        <stx-input
          [label]="'other'"
          [formGroup]="formGroup"
          [formMode]="formMode"
          [controlName]="'placementErrorOther'"
          [type]="'text'"
        ></stx-input>
      </div>
    </div>
  </div>
  <div class="form-row">
    <stx-textarea
      [label]="'comments'"
      [controlName]="'perceptualAssessmentComment'"
      [formGroup]="formGroup"
      [formMode]="formMode"
      [print]="print"
      [tooltip]="'speech_instructions.assessment.sound.comments_subtitle'"
    ></stx-textarea>
  </div>
</div>
