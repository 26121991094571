import { DictionaryTranslation, PatientId } from '@/src/app/features/patient/models/patient.model';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { SpeechTreatment } from '@/src/app/features/speech/models/speech-treatment.model';
import { SpeechBasicInfoComponent } from '@/src/app/features/speech/speech-basic-info/speech-basic-info.component';
import { SpeechService } from '@/src/app/features/speech/speech.service';
import {
  defaultTelehealthCountValue,
  frequencyFields,
  goalCorrectAllTimes,
  recommendationFields,
  speechTreatmentParentOrderNames,
  treatmentFrequencyOptions,
  treatmentModelFields,
  treatmentModelOptions
} from '@/src/app/features/speech/utils/speech-treatment.utils';
import { BaseTreatmentFormComponent } from '@/src/app/shared/components/base-treatment-form/base-treatment-form.component';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { atLeastOneRequired, requiredIfValidator, selectionInvalidIf, StxValidators } from '@/src/app/shared/validation/validators';
import { getNextDay } from '@/src/app/utils/date.utils';
import { ChangeDetectorRef, Component, ElementRef, inject, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { FormGuardService } from '@shared/services/form-guard.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { FormMediaUtils } from '@utils/form-media.utils';
import { FormMode, yesNoAnswers } from '@utils/form.utils';
import { editRoute, speechRoute, speechTreatmentRoute } from '@utils/routing.utils';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Component({
  selector: 'stx-speech-treatment',
  templateUrl: './speech-treatment.component.html',
  styleUrls: ['./speech-treatment.component.scss']
})
export class SpeechTreatmentComponent extends BaseTreatmentFormComponent<SpeechTreatment> implements OnInit {
  @Input() speechTreatment: SpeechTreatment;

  @ViewChild(SpeechBasicInfoComponent) speechBasicInfoComponent: SpeechBasicInfoComponent;
  readonly yesNo = yesNoAnswers;

  staticDictionaries: Map<string, DictionaryTranslation[]>;
  treatmentModelOptions = treatmentModelOptions;
  treatmentFrequencyOptions = treatmentFrequencyOptions;
  afterDateOfBirth: moment.Moment;

  protected formBuilder = inject(UntypedFormBuilder);
  protected speechService = inject(SpeechService);
  protected activatedRoute = inject(ActivatedRoute);
  protected changeDetector = inject(ChangeDetectorRef);
  protected formGuardService = inject(FormGuardService);
  protected wsHelper = inject(WsHelperService);

  constructor() {
    super(
      inject(ElementRef),
      inject(NgZone),
      inject(SnackBarService),
      inject(SpinnerService),
      inject(Router),
      inject(ActivatedRoute),
      inject(ChangeDetectorRef),
      inject(GlobalErrorHandlerService),
      inject(WsHelperService),
      inject(PatientService)
    );
    this.formType = FormType.SPEECH_TREATMENT;
    this.treatmentType = TreatmentType.SPEECH_TREATMENT;
  }

  get treatmentEndMinDate(): moment.Moment {
    return this.treatmentFormGroup.get('treatmentStart')?.value ?? this.afterDateOfBirth;
  }

  get treatmentEndMaxDate(): moment.Moment {
    return moment(this.treatmentFormGroup.get('treatmentStart')?.value)?.add(6, 'M');
  }

  ngOnInit() {
    this.configureForm(
      this.initSpeechTreatmentForm(),
      { controlNamesForFullValidation: ['reportDate', 'treatmentCenterId'] },
      {
        controlNamesForFullValidation: [
          'reportDate',
          'treatmentCenterId',
          'practitionerId',
          'countryLanguageId',
          'treatmentStart',
          'treatmentEnd',
          'isAbandoned',
          'abandonReasonMore'
        ]
      }
    );

    if (!this.isReadonlyView) {
      this.getPatientTreatmentCenters();
    }
    this.initStaticDictionaries();
    this.fillInEditMode();

    this.afterDateOfBirth = this.patient ? getNextDay(this.patient.dateOfBirth) : null;
    this.watchIfRecommendationsFieldsAreCheckedWhenContinueSpeechTherapyIsSelected();
  }

  initSpeechTreatmentForm(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        treatmentStart: [null, StxValidators.required],
        treatmentEnd: [null, StxValidators.required],
        reportDate: [null, StxValidators.required],
        treatmentCenterId: [null, StxValidators.required],
        practitionerId: [
          null,
          [StxValidators.required, selectionInvalidIf(() => !!this.speechBasicInfoComponent?.isSelectedPractitionerIdInvalid)]
        ],
        countryLanguageId: [
          null,
          [StxValidators.required, selectionInvalidIf(() => !!this.speechBasicInfoComponent?.isSelectedCountryLanguageInvalid)]
        ],
        serviceCount: [null, [StxValidators.required, Validators.min(0), Validators.max(100), StxValidators.isInteger]],
        telehealthCount: [
          defaultTelehealthCountValue,
          [Validators.min(defaultTelehealthCountValue), Validators.max(100), StxValidators.isInteger]
        ],
        serviceIndiv: [],
        serviceGroup: [],
        serviceCamp: [],
        isAbandoned: false,
        abandonReasonMore: [
          null,
          [requiredIfValidator(() => this.treatmentFormGroup.get('isAbandoned').value === true), Validators.maxLength(1000)]
        ],
        serviceDaily: [],
        serviceWeekly: [],
        serviceMonthly: [],
        serviceOther: [],
        goal1: [null, [requiredIfValidator(() => !this.treatmentFormGroup.get('goal1Other').value), Validators.maxLength(50)]],
        goal1Start: [null, StxValidators.required],
        goal1Status: [null, StxValidators.required],
        goal1End: [null, requiredIfValidator(() => this.treatmentFormGroup.get('goal1Status').value === goalCorrectAllTimes)],
        goal1Other: [null, Validators.maxLength(255)],
        goal2: [
          null,
          [
            Validators.maxLength(50),
            requiredIfValidator(() => this.atLeastOneControlForGoalIdIsFilled(2) && !this.treatmentFormGroup.get('goal2Other').value)
          ]
        ],
        goal2Start: [null, requiredIfValidator(() => this.atLeastOneControlForGoalIdIsFilled(2))],
        goal2Status: [null, requiredIfValidator(() => this.atLeastOneControlForGoalIdIsFilled(2))],
        goal2End: [null, requiredIfValidator(() => this.treatmentFormGroup.get('goal2Status').value === goalCorrectAllTimes)],
        goal2Other: [null, Validators.maxLength(255)],
        goal3: [
          null,
          [
            Validators.maxLength(50),
            requiredIfValidator(() => this.atLeastOneControlForGoalIdIsFilled(3) && !this.treatmentFormGroup.get('goal3Other').value)
          ]
        ],
        goal3Start: [null, requiredIfValidator(() => this.atLeastOneControlForGoalIdIsFilled(3))],
        goal3Status: [null, requiredIfValidator(() => this.atLeastOneControlForGoalIdIsFilled(3))],
        goal3End: [null, requiredIfValidator(() => this.treatmentFormGroup.get('goal3Status').value === goalCorrectAllTimes)],
        goal3Other: [null, Validators.maxLength(255)],
        goal4: [
          null,
          [
            Validators.maxLength(50),
            requiredIfValidator(() => this.atLeastOneControlForGoalIdIsFilled(4) && !this.treatmentFormGroup.get('goal4Other').value)
          ]
        ],
        goal4Start: [null, requiredIfValidator(() => this.atLeastOneControlForGoalIdIsFilled(4))],
        goal4Status: [null, requiredIfValidator(() => this.atLeastOneControlForGoalIdIsFilled(4))],
        goal4End: [null, requiredIfValidator(() => this.treatmentFormGroup.get('goal4Status').value === goalCorrectAllTimes)],
        goal4Other: [null, Validators.maxLength(255)],
        improvement: [null, StxValidators.required],
        recommendSpeechTherapy: [],
        recommendHomeTherapy: [],
        recommendVpdAssessment: [],
        recommendRelease: [],
        recommendComment: [],
        [ParentOrderName.SPEECH_TREATMENT_SAMPLE_SYLLABLE_REPETITION]: [null, StxValidators.required],
        [ParentOrderName.SPEECH_TREATMENT_SAMPLE_SENTENCE_REPETITION]: [null, StxValidators.required]
      },
      {
        validators: [
          atLeastOneRequired(treatmentModelFields, 'treatmentModelFields'),
          atLeastOneRequired(frequencyFields, 'frequencyFields'),
          atLeastOneRequired(recommendationFields, 'recommendationFields')
        ]
      }
    );
  }

  protected getTreatmentDataToSave(): SpeechTreatment {
    return {
      ...this.treatmentFormGroup.value,
      id: this.speechTreatment ? this.speechTreatment.id : null,
      patientId: this.patient.id,
      newFiles: FormMediaUtils.extractNewMediaFromFormGroup([this.treatmentFormGroup], speechTreatmentParentOrderNames)
    };
  }

  protected callDelete(id: number): Observable<void> {
    return this.speechService.deleteSpeechTreatment(id);
  }

  protected callSave(data: SpeechTreatment): Observable<SpeechTreatment> {
    return this.speechService.saveSpeechTreatment(data);
  }

  protected callDiscontinue(data: SpeechTreatment): Observable<SpeechTreatment> {
    return this.speechService.discontinueSpeechTreatment(data);
  }

  protected callSubmit(data: SpeechTreatment): Observable<SpeechTreatment> {
    return this.speechService.submitSpeechTreatment(data);
  }

  protected callUnlock(id: number): Observable<void> {
    return this.speechService.unlockSpeechTreatment(id);
  }

  protected getViewRoute(treatmentId: number): string {
    return `${speechRoute}/${speechTreatmentRoute}/${treatmentId}`;
  }

  protected getEditRoute(treatmentId: number): string {
    return `${speechRoute}/${speechTreatmentRoute}/${editRoute}/${this.speechTreatment.id}`;
  }

  protected getPatientId(): PatientId {
    return this.patient.id;
  }

  protected getTreatmentId(): TreatmentId {
    return this.speechTreatment.id;
  }

  protected setTreatmentData(data: SpeechTreatment): void {
    this.speechTreatment = data;
    this.treatmentFormGroup.patchValue(this.speechTreatment);
    this.treatmentFormGroup.patchValue(FormMediaUtils.getMediaForFormGroup(this.speechTreatment, speechTreatmentParentOrderNames));
  }

  private getRecommendReevalMonthsControl(): AbstractControl {
    return this.treatmentFormGroup.get('recommendReevalMonths');
  }

  private isReevalMonthsNumberProvided(): boolean {
    return !!this.getRecommendReevalMonthsControl().value;
  }

  private atLeastOneControlForGoalIdIsFilled(goalId: number): boolean {
    return (
      !!this.treatmentFormGroup.get(`goal${goalId}`).value ||
      !!this.treatmentFormGroup.get(`goal${goalId}Start`).value ||
      !!this.treatmentFormGroup.get(`goal${goalId}Status`).value ||
      !!this.treatmentFormGroup.get(`goal${goalId}Other`).value
    );
  }

  protected override beforeValidationSync(): void {
    this.speechBasicInfoComponent.checkPractitionerAndLanguageValidity();
    super.beforeValidationSync();
  }

  private fillInEditMode() {
    if (this.formMode !== FormMode.NEW) {
      if (!this.speechTreatment) {
        this.subSink.sink = this.activatedRoute.params.subscribe(params => {
          this.wsHelper
            .callWithSpinner(this.speechService.getSpeechTreatment(params.id), { redirectOn404StatusCode: true })
            .subscribe(speechTreatment => {
              this.formGuardService.redirectTreatmentFormToCorrectModeIfRequired(
                speechTreatment,
                this.formMode,
                this.activatedRoute.snapshot
              );
              this.setTreatmentData(speechTreatment);
            });
        });
      } else {
        this.setTreatmentData(this.speechTreatment);
      }
    }
  }

  private initStaticDictionaries() {
    this.wsHelper.callWithSpinner(this.speechService.getSpeechTreatmentStaticDictionaries()).subscribe(staticDictionaries => {
      this.staticDictionaries = staticDictionaries;
      this.changeDetector.markForCheck();
    });
  }

  private watchIfRecommendationsFieldsAreCheckedWhenContinueSpeechTherapyIsSelected(): void {
    const dischargeFromSpeechTherapyControl: AbstractControl = this.formGroup.get('recommendRelease');
    const continueSpeechTherapyControl: AbstractControl = this.formGroup.get('recommendSpeechTherapy');

    this.subSink.sink = dischargeFromSpeechTherapyControl.valueChanges.subscribe({
      next: value => {
        if (value) {
          continueSpeechTherapyControl.disable();
          continueSpeechTherapyControl.reset();
        } else {
          continueSpeechTherapyControl.enable({ emitEvent: false });
        }
      }
    });

    this.subSink.sink = continueSpeechTherapyControl.valueChanges.subscribe({
      next: value => {
        if (!!value) {
          dischargeFromSpeechTherapyControl.disable();
        } else {
          dischargeFromSpeechTherapyControl.enable({ emitEvent: false });
        }
      }
    });
  }
}
