import { Component, Input, NgModule } from '@angular/core';
import { NavItem } from '@shared/components/navigation/model/nav-item.model';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../modules/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'stx-mobile-button-with-subitems',
  templateUrl: './mobile-button-with-subitems.component.html',
  styleUrls: ['./mobile-button-with-subitems.component.scss']
})
export class MobileButtonWithSubitemsComponent {
  @Input() buttonWithSubItems: NavItem;
}

@NgModule({
  declarations: [MobileButtonWithSubitemsComponent],
  imports: [CommonModule, MaterialModule, TranslateModule, RouterModule],
  exports: [MobileButtonWithSubitemsComponent]
})
export class MobileButtonWithSubitemsComponentModule {}
