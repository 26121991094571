import { getStaticFileUrl } from '@/src/app/utils/media.utils';
import { LanguagePair } from '@shared/components/language-switcher/language.model';
import { FileExtension } from '@utils/file.utils';
import { Resource, ResourceSection } from '../models/resource.model';

export const OFFLINE_APP_DIR = 'offline_app/';

export function getLangShortcutMapping(shortcut: string) {
  return RESOURCE_MAPPINGS.find(lm => lm.shortcut === shortcut)?.name;
}
export function getEducationalResourceUrl(resource: Resource, langShortcut: string = '') {
  const extension = resource.fileExtension ?? FileExtension.PDF;
  const dir = resource.directory ?? 'pdf/';
  return getStaticFileUrl(`${dir}${resource.resourceName}${langShortcut}${extension}`);
}

export const REGIONS: readonly string[] = [
  'resources_for_partners.africa',
  'resources_for_partners.americas',
  'resources_for_partners.europe',
  'resources_for_partners.mideast',
  'resources_for_partners.north_asia',
  'resources_for_partners.south_asia',
  'resources_for_partners.southeast_asia'
];

export const RESOURCE_MAPPINGS: readonly LanguagePair[] = [
  { shortcut: 'am', name: 'አማርኛ' },
  { shortcut: 'ar', name: `العربية` },
  { shortcut: 'bg', name: 'Български' },
  { shortcut: 'bn', name: 'বাংলা' },
  { shortcut: 'cn', name: '中文' },
  { shortcut: '', name: 'English' },
  { shortcut: 'en', name: 'English' },
  { shortcut: 'en_af', name: 'English' },
  { shortcut: 'en_am', name: 'English' },
  { shortcut: 'en_ht', name: 'Haiti English' },
  { shortcut: 'en_me', name: 'English' },
  { shortcut: 'en_ot', name: 'English' },
  { shortcut: 'en_na', name: 'English' },
  { shortcut: 'en-ph', name: 'English (Philippines)' },
  { shortcut: 'en-th', name: 'English (Thailand)' },
  { shortcut: 'en_sa', name: 'English' },
  { shortcut: 'en_se', name: 'English' },
  { shortcut: 'es', name: 'Español' },
  { shortcut: 'es_mx_ca', name: 'Español Mexico & Central America' },
  { shortcut: 'es_sa', name: 'Español South America' },
  { shortcut: 'fr', name: 'Français' },
  { shortcut: 'fr_se', name: 'Français' },
  { shortcut: 'gu', name: 'ગુજરાત' },
  { shortcut: 'ha', name: 'هَرْشَن هَوْسَ‎' },
  { shortcut: 'hr', name: 'Hrvatski' },
  { shortcut: 'ht', name: 'Kreyòl' },
  { shortcut: 'hi', name: 'हिन्दी' },
  { shortcut: 'hy', name: 'հայերէն/հայերեն' },
  { shortcut: 'id', name: 'Bahasa Indonesia' },
  { shortcut: 'km', name: 'ភាសាខ្មែរ' },
  { shortcut: 'kn', name: 'ಕನ್ನಡ' },
  { shortcut: 'ml', name: 'മലയാളം' },
  { shortcut: 'mr', name: 'मराठी' },
  { shortcut: 'as', name: 'অসমীয়া' },
  { shortcut: 'my', name: 'မြန်မာဘာသာ' },
  { shortcut: 'ne', name: 'नेपाली भाषा' },
  { shortcut: 'or', name: 'ଓଡ଼ିଆ' },
  { shortcut: 'pa', name: `پنجابی • ਪੰਜਾਬੀ` },
  { shortcut: 'ps', name: 'پښتو' },
  { shortcut: 'pt', name: 'Português' },
  { shortcut: 'pt-br', name: 'Português (Brasil)' },
  { shortcut: 'pt-mz', name: 'Português (Moçambique)' },
  { shortcut: 'ru', name: 'Русский' },
  { shortcut: 'sn', name: 'Shona' },
  { shortcut: 'so', name: 'اف سومالى' },
  { shortcut: 'sw', name: 'Kiswahili' },
  { shortcut: 'ta', name: 'தமிழ்' },
  { shortcut: 'te', name: 'తెలుగు' },
  { shortcut: 'th', name: 'ภาษาไทย' },
  { shortcut: 'tl', name: 'Tagalog' },
  { shortcut: 'tr', name: 'Türkçe' },
  { shortcut: 'uk', name: 'Українська' },
  { shortcut: 'ur', name: `اُردُو‬` },
  { shortcut: 'vi', name: 'Tiếng Việt' },
  { shortcut: 'yo', name: 'Yorùbá' }
];

export const covidResources: ResourceSection[] = [
  {
    resources: [
      {
        label: 'dashboard.covid_19.safety_protocol',
        resourceName: 'COVID-19_Safety_Quality_Addendum_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'ru', 'ur', 'vi', 'cn']]
      },
      {
        label: 'dashboard.covid_19.surgical_patient_checklist',
        resourceName: 'COVID-19_Surgical_Patient_Checklist_',
        translationList: [['en', 'es', 'fr', 'hi']]
      }
    ]
  }
];

export const saqProtocolsResources: ResourceSection[] = [
  {
    resources: [
      {
        label: 'dashboard.saq_protocol.body',
        resourceName: 'Safety_and_Quality_Protocol_',
        translationList: [['ar', 'en', 'es', 'fr', 'id', 'pt', 'sw', 'ur', 'vi', 'cn']]
      }
    ]
  }
];
