import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-statistics-submitted-only',
  templateUrl: './statistics-submitted-only.component.html',
  styleUrls: ['./statistics-submitted-only.component.scss']
})
export class StatisticsSubmittedOnlyComponent {
  @Input() treatmentStats: number;
  tableTreatmentTitleLabel: string;

  @Input()
  set tableTreatmentTitle(title: string) {
    this.tableTreatmentTitleLabel = title;
  }
}
