import { OrthoGeneralTreatmentInfoComponent } from '@/src/app/features/ortho/components/shared/ortho-general-treatment-info/ortho-general-treatment-info.component';
import { OrthoSharedGeneralInfoComponent } from '@/src/app/features/ortho/components/shared/ortho-shared-general-info/ortho-shared-general-info.component';
import { PsioTreatment } from '@/src/app/features/ortho/models/psio-treatment.model';
import { PsioTreatmentService } from '@/src/app/features/ortho/services/psio-treatment.service';
import { BaseOrthoFormComponent } from '@/src/app/features/ortho/shared/base-ortho-form.component';
import { OrthoTreatmentFormApi } from '@/src/app/features/ortho/shared/ortho-treatment-form-api';
import { OrthoFormType } from '@/src/app/features/ortho/utils/ortho-form-type';
import { psioTreatmentAppRoute } from '@/src/app/features/ortho/utils/ortho-routes.consts';
import { OrthoStage } from '@/src/app/features/ortho/utils/ortho-stage';
import { OrthoFormUtils } from '@/src/app/features/ortho/utils/ortho.utils';
import {
  presurgicalOrthopedicsTreatmentImagesConfigs,
  psioTreatmentParentOrderNames
} from '@/src/app/features/ortho/utils/presurgical.utils';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { SpinnerService } from '@/src/app/shared/components/spinner/service/spinner.service';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { FormGuardService } from '@/src/app/shared/services/form-guard.service';
import { GlobalErrorHandlerService } from '@/src/app/shared/services/global-error-handler.service';
import { SnackBarService } from '@/src/app/shared/services/snack-bar.service';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { requiredIfValidator, selectionInvalidIf, StxValidators } from '@/src/app/shared/validation/validators';
import { FormMediaUtils } from '@/src/app/utils/form-media.utils';
import { FormMode, yesNoShort } from '@/src/app/utils/form.utils';
import { orthoRoute } from '@/src/app/utils/routing.utils';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';
import { PatientId } from '@src/app/features/patient/models/patient.model';

@Component({
  selector: 'stx-psio-treatment',
  templateUrl: './psio-treatment.component.html'
})
export class PsioTreatmentComponent extends BaseOrthoFormComponent<PsioTreatment> implements OnInit {
  readonly OrthoFormType = OrthoFormType;
  readonly yesNo = yesNoShort;
  readonly presurgicalOrthopedicsTreatmentImagesConfigs = presurgicalOrthopedicsTreatmentImagesConfigs;
  @ViewChild('generalTreatmentInfo') generalTreatmentInfo: OrthoGeneralTreatmentInfoComponent;
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly orthoFormService: PsioTreatmentService,
    private readonly formGuardService: FormGuardService,
    spinnerService: SpinnerService,
    changeDetector: ChangeDetectorRef,
    globalErrorHandlerService: GlobalErrorHandlerService,
    router: Router,
    activatedRoute: ActivatedRoute,
    snackBarService: SnackBarService,
    elementRef: ElementRef,
    zone: NgZone,
    wsHelper: WsHelperService,
    patientService: PatientService
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      changeDetector,
      globalErrorHandlerService,
      wsHelper,
      patientService,
      orthoFormService
    );
    this.formType = FormType.ORTHO_PSIO_TREATMENT;
    this.treatmentType = TreatmentType.ORTHO_PSIO_TREATMENT;
  }

  ngOnInit(): void {
    this.configureForm(
      this.createTreatmentFormGroup(),
      {
        controlNamesForFullValidation: [OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName]
      },
      {
        controlNamesForFullValidation: [
          OrthoSharedGeneralInfoComponent.careProvidedEndDate,
          OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName,
          OrthoSharedGeneralInfoComponent.practitionerIdControlName,
          'abandonReasonMore',
          'complicationMore'
        ]
      }
    );
    if (!this.isReadonlyView) {
      this.getPatientTreatmentCenters();
    }
    this.fillInEditMode();
  }

  protected createTreatmentFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [OrthoSharedGeneralInfoComponent.getOrthoCareProvidedDate(OrthoStage.TREATMENT)]: [],
      [OrthoSharedGeneralInfoComponent.careProvidedEndDate]: [null, [StxValidators.required]],
      [OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName]: [null, [StxValidators.required]],
      [OrthoSharedGeneralInfoComponent.practitionerIdControlName]: [
        null,
        [
          StxValidators.required,
          selectionInvalidIf(
            () =>
              !!this.generalTreatmentInfo?.sharedGeneralInfo &&
              !!this.generalTreatmentInfo.sharedGeneralInfo.isSelectedPractitionerIdInvalid
          )
        ]
      ],
      [OrthoSharedGeneralInfoComponent.surgeonIdControlName]: [
        null,
        [
          StxValidators.required,
          selectionInvalidIf(
            () => !!this.generalTreatmentInfo?.sharedGeneralInfo && !!this.generalTreatmentInfo.sharedGeneralInfo.isSelectedSurgeonIdInvalid
          )
        ]
      ],
      followup: [null, [StxValidators.required, Validators.min(1), Validators.max(20), StxValidators.isInShortTypeRange]],
      infoGiven: [null, StxValidators.required],
      infoGivenMore: [],
      complication: [null, StxValidators.required],
      complicationMore: [null, requiredIfValidator(() => OrthoFormUtils.complicationsOccurredSelected(this.treatmentFormGroup))],
      isAbandoned: false,
      abandonReasonMore: [null, requiredIfValidator(() => OrthoFormUtils.discontinuedSelected(this.treatmentFormGroup))],
      [ParentOrderName.ORTHO_FRONTAL_POST]: [null, StxValidators.required],
      [ParentOrderName.ORTHO_LEFT_POST]: [null, StxValidators.required],
      [ParentOrderName.ORTHO_RIGHT_POST]: [null, StxValidators.required],
      [ParentOrderName.ORTHO_BASAL_POST]: [null, StxValidators.required],
      [ParentOrderName.ORTHO_MAXILLARY_POST]: [null, StxValidators.required],
      [ParentOrderName.ORTHO_FRONTAL_SMILE_POST]: []
    });
  }

  private fillInEditMode() {
    if (this.formMode === FormMode.NEW) {
      this.callFetchAdditionalDataForSetUp();
      return;
    }

    if (this.orthoFormModel) {
      this.setTreatmentData(this.orthoFormModel);
      return;
    }

    this.subSink.sink = this.activatedRoute.params.subscribe(params => {
      this.wsHelper.callWithSpinner(this.orthoFormService.get(params.id), { redirectOn404StatusCode: true }).subscribe(psioTreatment => {
        this.formGuardService.redirectTreatmentFormToCorrectModeIfRequired(psioTreatment, this.formMode, this.activatedRoute.snapshot);
        this.setTreatmentData(psioTreatment);
      });
    });
  }

  protected getTreatmentDataToSave() {
    return {
      ...this.treatmentFormGroup.value,
      id: this.orthoFormModel ? this.orthoFormModel.id : null,
      patientId: this.patient.id,
      newFiles: FormMediaUtils.extractNewMediaFromFormGroup([this.treatmentFormGroup], psioTreatmentParentOrderNames)
    };
  }

  protected override beforeValidationSync(): void {
    this.generalTreatmentInfo.sharedGeneralInfo.checkPractitionerAndSurgeonValidity();
    super.beforeValidationSync();
  }
  protected callDelete(id: number): Observable<void> {
    return this.orthoFormService.delete(id);
  }

  protected callSave(data: PsioTreatment): Observable<PsioTreatment> {
    return this.orthoFormService.save(data);
  }

  protected callDiscontinue(data: PsioTreatment): Observable<PsioTreatment> {
    return (this.orthoFormService as OrthoTreatmentFormApi<PsioTreatment>).discontinue(data);
  }

  protected callSubmit(data: PsioTreatment): Observable<PsioTreatment> {
    return this.orthoFormService.submit(data);
  }

  protected callUnlock(id: number): Observable<void> {
    return this.orthoFormService.unlock(id);
  }
  protected getEditRoute(treatmentId: number): string {
    return `${orthoRoute}/${psioTreatmentAppRoute}/edit/${treatmentId}`;
  }

  protected getPatientId(): PatientId {
    return this.patient.id;
  }

  protected getTreatmentId(): TreatmentId {
    return this.orthoFormModel.id;
  }

  protected getViewRoute(treatmentId: number): string {
    return `${orthoRoute}/${psioTreatmentAppRoute}/${treatmentId}`;
  }
  protected setTreatmentData(data: PsioTreatment): void {
    this.orthoFormModel = data;
    this.treatmentFormGroup.patchValue(this.orthoFormModel);
    this.treatmentFormGroup.patchValue(FormMediaUtils.getMediaForFormGroup(this.orthoFormModel, psioTreatmentParentOrderNames));

    if (this.formMode !== FormMode.READONLY) {
      this.callFetchAdditionalDataForSetUp();
    }
  }
}
