import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormStatus, TreatmentStatusLabel } from '@shared/enums/form-status.enum';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBan, faCheck, faPause, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

type StatusColorClass = 'status-default' | 'status-warning' | 'status-disabled' | 'status-in-progress' | 'status-submitted';

interface StatusIndicatorConfig {
  className: StatusColorClass;
  iconName: string | IconDefinition;
}

@Component({
  selector: 'stx-treatment-status-indicator',
  templateUrl: './treatment-status-indicator.component.html',
  styleUrls: ['./treatment-status-indicator.component.scss']
})
export class TreatmentStatusIndicatorComponent implements OnInit {
  @Input() treatmentStatus: FormStatus | null;
  @Input() showLabel = true;
  @Input() defaultIconColor: boolean;
  @Input() addBottomPadding = false;
  readonly greyColor = '#8d8c8f';
  @Input() print: boolean;

  treatmentStatusLabel: string;
  treatmentStatusConfig: StatusIndicatorConfig;

  private statusIndicatorConfig: Record<FormStatus, StatusIndicatorConfig> = {
    // TODO#30209: change icon names and colour class
    SUBMITTED: { className: 'status-submitted', iconName: faCheckCircle },
    ACCEPTED: { className: 'status-default', iconName: faCheck },
    REJECTED: { className: 'status-warning', iconName: faTimes },
    EXCEEDS_QUOTA: { className: 'status-disabled', iconName: faTimes },
    DISCONTINUED: { className: 'status-default', iconName: faBan },
    IN_PROGRESS: { className: 'status-default', iconName: faPause },
    DELETED: { className: 'status-warning', iconName: faTimes }
  };

  ngOnInit(): void {
    if (!this.treatmentStatus) {
      this.treatmentStatus = FormStatus.SUBMITTED;
    }

    this.treatmentStatusLabel = TreatmentStatusLabel.get(this.treatmentStatus);
    this.treatmentStatusConfig = this.getStatusIndicatorConfig();
  }

  private getStatusIndicatorConfig(): StatusIndicatorConfig {
    return this.statusIndicatorConfig[this.treatmentStatus];
  }

  getBottomPaddingBelowIcon(): number {
    return this.addBottomPadding ? 5 : 0;
  }
}

@NgModule({
  declarations: [TreatmentStatusIndicatorComponent],
  imports: [CommonModule, MatIconModule, TranslateModule, FontAwesomeModule],
  exports: [TreatmentStatusIndicatorComponent]
})
export class TreatmentStatusIndicatorComponentModule {}
