import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FormModule } from '@shared/components/form/form.module';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { TIMINGS } from '@core/animations/constant.animations';
import { transition, trigger, useAnimation } from '@angular/animations';
import { slideDown, slideUp } from '@core/animations';
import { UntypedFormGroup } from '@angular/forms';
import { CheckboxOption, ReportFilterChange, ReportFilterName } from '../../models/report-filters.model';
import { ReportDictionariesService } from '../../services/report-dictionaries.service';
import { ReportFiltersHelperService } from '../../services/report-filters-helper.service';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { TranslateModule } from '@ngx-translate/core';

interface RepairTypeItem {
  label: string;
  description?: string;
  formGroup: UntypedFormGroup;
  options: CheckboxOption[];
}

@Component({
  selector: 'stx-repair-types',
  templateUrl: './repair-types.component.html',
  styleUrls: ['./repair-types.component.scss'],
  animations: [
    trigger('typesSection', [
      transition(
        'void => *',
        useAnimation(slideDown, {
          params: {
            timing: TIMINGS.VERY_FAST
          }
        })
      ),
      transition(
        '* => void',
        useAnimation(slideUp, {
          params: {
            timing: TIMINGS.VERY_FAST
          }
        })
      )
    ])
  ]
})
export class RepairTypesComponent extends BaseComponent implements OnInit {
  typesVisible = false;

  @Input() formGroupReference: UntypedFormGroup;
  @Output() filterChange = new EventEmitter<ReportFilterChange>();
  repairTypesList: RepairTypeItem[];

  constructor(
    private reportDictionariesService: ReportDictionariesService,
    private reportFiltersHelperService: ReportFiltersHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.repairTypesList = this.getRepairTypesList();
    this.watchFilterChanges();
  }

  private handleValueChanges(change: Record<string, Record<string, boolean>>): void {
    Object.keys(change).forEach(key => {
      const filterName = key;
      const selectedValues = this.reportFiltersHelperService.getSelectedCheckboxOptions(change[key]);

      this.filterChange.emit({
        filterName: `repairType.${filterName as ReportFilterName}` as ReportFilterName,
        payload: {
          [filterName]: selectedValues
        }
      });
    });
  }

  private watchFilterChanges() {
    this.subSink.sink = this.formGroupReference.valueChanges.subscribe(this.handleValueChanges.bind(this));
  }

  toggleSection() {
    this.typesVisible = !this.typesVisible;
  }

  private getRepairTypesList(): RepairTypeItem[] {
    const repairTypesList: RepairTypeItem[] = [];

    for (const [key, value] of Object.entries(this.reportDictionariesService.dictionaries.repairTypes)) {
      const currentText = textsMap[key];
      const options = this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(value);

      this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(this.formGroupReference.get(key) as UntypedFormGroup, value);
      repairTypesList.push({
        label: currentText.label,
        description: currentText.description,
        formGroup: this.formGroupReference.get(key) as UntypedFormGroup,
        options
      });
    }

    return repairTypesList;
  }
}

export const textsMap = {
  primaryLipNoseUnilateral: {
    label: 'surgery.primary_lip_nose_unilateral'
  },
  primaryLipNoseBilateral: {
    label: 'surgery.primary_lip_nose_bilateral'
  },
  lipNoseRevision: {
    label: 'surgical_intervention.lip_nose_revision'
  },
  primaryCleftPalate: {
    label: 'surgery.primary_cleft_palate'
  },
  fistulaRepair: {
    label: 'surgical_intervention.fistula_repair'
  },
  secondaryCleftPalate: {
    label: 'surgical_intervention.secondary_cleft_palate'
  },
  other: {
    label: ''
  },
  velopharyngealDysfunction: {
    label: 'surgical_intervention.velopharyngeal_disfunction'
  },
  alveolarBoneGraft: {
    label: 'surgical_intervention.alveolar_bone_graft'
  }
};

@NgModule({
  declarations: [RepairTypesComponent],
  imports: [FormModule, CommonModule, MatExpansionModule, MatIconModule, TranslateModule],
  exports: [RepairTypesComponent]
})
export class RepairTypesComponentModule {}
