import { Component } from '@angular/core';
import { speechTreatmentGoals } from '../utils/speech-treatment.utils';

@Component({
  selector: 'stx-speech-instructions',
  templateUrl: './speech-instructions.component.html'
})
export class SpeechInstructionsComponent {
  readonly oneToFiveArray = [1, 2, 3, 4, 5];
  readonly oneToFourArray = [1, 2, 3, 4];
  readonly oneToTwoArray = [1, 2];
  readonly twoToThreeArray = [2, 3];
  readonly oneToThreeArray = [1, 2, 3];
  readonly twoToFiveArray = [2, 3, 4, 5];
  readonly oneToSixArray = [1, 2, 3, 4, 5, 6];
  readonly goals = speechTreatmentGoals;
}
