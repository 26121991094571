import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { TreatmentType } from '@shared/components/treatment/treatment.enum';
import { FormType } from '@shared/enums/form-type.enum';
import { FormGuardService } from '@shared/services/form-guard.service';
import { AuthService } from '@src/app/features/authentication/services/auth.service';
import { ReviewModel } from '@src/app/features/surgical/models/review.model';
import { SurgicalService } from '@src/app/features/surgical/surgical.service';
import { UserService } from '@src/app/features/user/services/user.service';
import { FormMode } from '@utils/form.utils';
import { intervention, patientDetailsRoute, printRoute, surgicalRoute } from '@utils/routing.utils';
import { Observable } from 'rxjs';
import { Patient } from '../../../patient/models/patient.model';
import { SurgicalIntervention } from '../../models/surgical-intervention.model';

@Component({
  selector: 'stx-surgical-intervention',
  templateUrl: './surgical-intervention.component.html'
})
export class SurgicalInterventionComponent extends BaseComponent implements OnChanges, OnInit {
  readonly treatmentType: TreatmentType = TreatmentType.SURGERY;
  readonly formType: FormType = FormType.SURGICAL_INTERVENTION;

  @Input() formMode: FormMode;
  @Input() print: boolean;
  @Input() expandable: boolean;
  @Input() patient: Patient;
  @Input() surgicalIntervention: SurgicalIntervention;
  @Output() dataSaved = new EventEmitter<void>();

  qaReview: ReviewModel | null = null;
  suspiciousRecordReviewerName: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formGuardService: FormGuardService,
    private readonly router: Router,
    private readonly surgicalService: SurgicalService,
    private readonly userService: UserService,
    private readonly wsHelper: WsHelperService
  ) {
    super();
  }

  get isReadonlyView(): boolean {
    return this.formMode === FormMode.READONLY;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.surgicalIntervention) {
      this.treatmentDataChanged();
    }
  }

  ngOnInit(): void {
    this.loadTreatmentIfNeeded();
  }

  private loadTreatmentIfNeeded() {
    this.subSink.sink = this.activatedRoute.params.subscribe(params => {
      const id = params.id || params.patientId;
      const isOnPatientTreatmentHistory = (this.activatedRoute.snapshot.url || []).some(url => url.path === printRoute);

      if (this.surgicalIntervention?.id === id || isOnPatientTreatmentHistory) {
        return;
      }
      this.handleWsCall(this.surgicalService.getSurgicalIntervention(id), surgicalIntervention => {
        this.formGuardService.redirectTreatmentFormToCorrectModeIfRequired(
          surgicalIntervention,
          this.formMode,
          this.activatedRoute.snapshot
        );
        this.surgicalIntervention = surgicalIntervention;
        this.treatmentDataChanged();
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  private treatmentDataChanged() {
    // TODO#30153 All this data should be read with treatment, not here
    this.readAndSetSuspiciousRecordReviewInfo();
    this.readAndSetQaReviewInfo();
  }

  private readAndSetSuspiciousRecordReviewInfo() {
    const suspiciousRecordReviewedBy = this.surgicalIntervention?.suspiciousRecordReviewedBy;

    if (this.hasQaDataPermission() && suspiciousRecordReviewedBy) {
      this.handleWsCall(this.userService.getUserById(this.surgicalIntervention.suspiciousRecordReviewedBy), user => {
        this.suspiciousRecordReviewerName = `${user.firstName} ${user.lastName}`;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  private readAndSetQaReviewInfo() {
    const treatment = this.surgicalIntervention;
    if (!treatment) {
      return;
    }

    const qaReviewer = this.surgicalIntervention?.qaReviewer;

    if (this.hasQaDataPermission() && qaReviewer) {
      this.handleWsCall(this.userService.getUserById(this.surgicalIntervention.qaReviewer), user => {
        this.qaReview = {
          qaPre: treatment.qaPre,
          qaPost: treatment.qaPost,
          qaNote: treatment.qaNote,
          qaReviewerName: `${user.firstName} ${user.lastName}`,
          qaPhotoQuality: treatment.qaPhotoQuality,
          modifiedAfterReview: treatment.modifiedAfterReview
        };
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  private hasQaDataPermission(): boolean {
    return this.authService.checkUserPermission(PermissionEnum.GET_USER);
  }

  unlock(): void {
    const id = this.surgicalIntervention!.id;
    this.handleWsCall(this.surgicalService.unlockSurgicalIntervention(id!), () => {
      this.router.navigate([`${surgicalRoute}/${intervention}/edit/${id}`], {
        queryParams: { patientId: this.patient?.id }
      });
    });
  }

  delete(): void {
    this.handleWsCall(this.surgicalService.deleteSurgicalIntervention(this.surgicalIntervention!.id!), () => {
      this.router.navigate(patientDetailsRoute(this.patient?.id));
    });
  }

  private handleWsCall<T>(observable: Observable<T>, callback?: (response: T) => void): void {
    this.wsHelper.callWithSpinner(observable, { redirectOn404StatusCode: true }).subscribe(result => {
      if (callback) {
        callback(result);
      }
    });
  }
}
