import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { FormMode } from '@utils/form.utils';
import { editRoute, RoutingUtils } from '@utils/routing.utils';
import { BaseTreatment } from '../../features/surgical/models/base-treatment.model';
import { TreatmentBusinessRules } from '@src/app/business-rules/treatment.business-rules';

@Injectable({
  providedIn: 'root'
})
export class FormGuardService {
  constructor(private router: Router) {}

  canFallbackToReadonly(route: ActivatedRouteSnapshot, userPermissions: string[]): boolean {
    const requiredPermission = route.data.permission.toString();
    if (!requiredPermission.includes('SAVE_')) {
      return false;
    }
    const fallbackPermission = requiredPermission.replace('SAVE_', 'GET_');
    const absoluteUrl = RoutingUtils.getSerializedRootPath(route);
    return absoluteUrl.indexOf(editRoute) > -1 && userPermissions.includes(fallbackPermission);
  }

  getReadonlyPathFromEditPath(route: ActivatedRouteSnapshot): UrlTree {
    const absoluteUrl = RoutingUtils.getSerializedRootPath(route);
    const redirectUrl = absoluteUrl.replace('/' + editRoute, '');
    return this.router.parseUrl(redirectUrl);
  }

  getEditPathFromReadonlyPath(route: ActivatedRouteSnapshot): UrlTree {
    const absoluteUrl = RoutingUtils.getSerializedRootPath(route);
    const editSection = '/edit';
    if (absoluteUrl.indexOf(editSection) === -1) {
      const insertionIndex = absoluteUrl.lastIndexOf('/');
      const redirectUrl = `${absoluteUrl.substring(0, insertionIndex)}${editSection}${absoluteUrl.substr(insertionIndex)}`;
      return this.router.parseUrl(redirectUrl);
    }
    return this.router.parseUrl(absoluteUrl);
  }

  redirectTreatmentFormToCorrectModeIfRequired(
    treatment: BaseTreatment,
    formMode: FormMode,
    activatedRouteSnapshot: ActivatedRouteSnapshot
  ) {
    const isInProgress = TreatmentBusinessRules.treatmentInProgress(treatment);

    switch (formMode) {
      case FormMode.EDIT:
        if (
          !isInProgress ||
          !TreatmentBusinessRules.treatmentEditableBasedOnScope(treatment) ||
          !treatment.editable ||
          treatment.isReadonly
        ) {
          const readonlyUrl = this.getReadonlyPathFromEditPath(activatedRouteSnapshot);
          this.router.navigateByUrl(readonlyUrl);
        }
        return;
      case FormMode.READONLY:
        if (
          isInProgress &&
          TreatmentBusinessRules.treatmentEditableBasedOnScope(treatment) &&
          treatment.editable &&
          !treatment.isReadonly
        ) {
          this.router.navigateByUrl(this.getEditPathFromReadonlyPath(activatedRouteSnapshot));
        }
        return;
      default:
        return;
    }
  }
}
