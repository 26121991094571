import { AutocompleteSelectBaseComponent } from '@/src/app/shared/components/autocomplete-multi-select/autocomplete-select-base.component';
import { ChipsComponentModule } from '@/src/app/shared/components/chips/chips.component';
import { SelectOption, SelectOptionIdType } from '@/src/app/shared/components/form/components/select/select.model';
import { Item } from '@/src/app/shared/models/item.model';
import { MaterialModule } from '@/src/app/shared/modules/material/material.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewChild
} from '@angular/core';

/** Should be used for filters instead of SelectComponent. Needs improvements and testing to work in forms. */
@Component({
  selector: 'stx-autocomplete-single-select',
  templateUrl: './autocomplete-single-select.component.html',
  styleUrls: ['../autocomplete-multi-select/autocomplete-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteSingleSelectComponent
  extends AutocompleteSelectBaseComponent<SelectOption>
  implements OnInit, ControlValueAccessor
{
  @Input() showChips = true;
  @Input() labelKey: string;
  @ViewChild('textInput', { static: false }) textInput: ElementRef<HTMLInputElement>;
  @Input() hasBlankOption = true;
  selectedOptionAsChip: Item = null;

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl,
    formBuilder: UntypedFormBuilder,
    changeDetectorRef: ChangeDetectorRef,
    private readonly renderer: Renderer2
  ) {
    super(formBuilder, changeDetectorRef);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.subSink.sink = this.ngControl.valueChanges.subscribe(() => this.setSelectedOptionAsChip());
  }
  private setSelectedOptionAsChip() {
    if (this.showChips && this.ngControl?.value != null) {
      const selectedOption = this._itemList.find((selectOption: SelectOption) => selectOption.id === this.ngControl.value);

      this.selectedOptionAsChip = {
        id: 1,
        name: selectedOption.name
      };
    } else {
      this.selectedOptionAsChip = null;
    }
  }

  override selectOption(selectedItem: SelectOption) {
    this.resetSelection();
    this.changeDetectorRef.detectChanges();
    this.writeValue(selectedItem.id);
    this.onChanged(selectedItem.id);
  }

  resetSelection() {
    this.writeValue(null);
    this.onChanged(null);
  }

  /** CVA implementation */

  // Must be executed when the update in the template should propagate in response to user events
  onChanged = (x: SelectOptionIdType) => {
    /* This is part of ControlValueAccessor base impl */
  };
  // Must be executed when we consider our control "touched" (on blur)
  onTouched = (x: unknown) => {
    /* This is part of ControlValueAccessor base impl */
  };

  // Executed when the our model should update through the Forms API, setup logic
  writeValue(value: SelectOptionIdType): void {
    this.autocompleteInputControl.setValue(this._itemList.find(item => item.id === value)?.name);
    this.changeDetectorRef.markForCheck();
  }
  registerOnChange(fn: (X: unknown) => {}): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: (X: unknown) => {}): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (this.textInput?.nativeElement) {
      this.renderer.setProperty(this.textInput.nativeElement, 'disabled', isDisabled);
      this.changeDetectorRef.markForCheck();
    }
  }
}

import { NgModule } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NgControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, TranslateModule, ScrollingModule, ChipsComponentModule],
  exports: [AutocompleteSingleSelectComponent],
  declarations: [AutocompleteSingleSelectComponent]
})
export class AutocompleteSingleSelectComponentModule {}
