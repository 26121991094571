<h1 class="fs-28 flex-center">
  <ng-content></ng-content>

  <a>
    <span class="m-l-15 flex-center">
      <mat-icon tabindex="0" (click)="toggleFiltersVisibility()">tune</mat-icon>
    </span>
  </a>
  <a>
    <stx-report-downloader
      [reportDownloadOptions]="reportService.config.downloadOptions"
      [reportType]="reportService.config.type"
      [enabled]="reportDownloaderEnabled"
      class="m-l-15"
    ></stx-report-downloader>
  </a>
</h1>
