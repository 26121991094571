import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'stx-up-down-chevron',
  template: '<fa-icon class="cursor-pointer user-select-none" [icon]="expanded ? up : down" (click)="toggleEvent.emit()"></fa-icon>'
})
export class UpDownChevronComponent {
  readonly up = faChevronUp;
  readonly down = faChevronDown;
  @Input() expanded = false;
  @Output() toggleEvent = new EventEmitter<void>();
}
