import { DictionaryTranslation } from '@/src/app/features/patient/models/patient.model';
import { notNoneRecommendationControls, validatedRecommendationFields } from '@/src/app/features/speech/utils/speech-assessment.utils';
import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'stx-speech-assessment-recommendations',
  templateUrl: './speech-assessment-recommendations.component.html',
  styleUrls: ['./speech-assessment-recommendations.component.scss']
})
export class SpeechAssessmentRecommendationsComponent extends BaseFormElementComponent {
  readonly repairTypes: DictionaryTranslation[] = [
    { value: 'recommendVpdSecondaryFurlow', label: 'speech_assessment.recommend_secondary_furlow' },
    { value: 'recommendVpdPharyngealFlap', label: 'speech_assessment.recommend_pharyngeal_flap' },
    { value: 'recommendVpdIntravelarVeloplasty', label: 'speech_assessment.recommend_intravelar_veloplasty' },
    { value: 'recommendVpdSphincterPharyngoplasty', label: 'speech_assessment.recommend_sphincter_pharyngoplasty' },
    { value: 'recommendVpdBuccalFlap', label: 'speech_assessment.recommend_buccal_flap' }
  ];

  readonly dental: DictionaryTranslation[] = [
    { value: 'recommendDentalConsult', label: 'speech_assessment.recommend_consult_dental_issues' },
    { value: 'recommendDentalObturator', label: 'speech_assessment.recommend_palatal_obturator_lift' }
  ];

  readonly surgery: DictionaryTranslation[] = [
    { value: 'recommendTonsillectomy', label: 'speech_assessment.recommend_tonsillectomy_adenoidectomy' },
    { value: 'recommendFistulaRepair', label: 'speech_assessment.recommend_fistula_repair' },
    { value: 'recommendVpd', label: 'treatment.vpd_surgery' }
  ];

  readonly dependentControls = ['recommendInitiateSpeechTherapy'];
  readonly validatedRecommendationFields = validatedRecommendationFields;
  private readonly recommendNoneControlName = 'recommendNoSpeechServices';
  private readonly allControlNames = [...notNoneRecommendationControls, this.recommendNoneControlName];

  showSectionError = false;

  @Input() set formGroupDataUpdate$(observable$: Observable<void>) {
    this.subSink.sink = observable$.subscribe(() => this.init());
  }
  get isRecommendNoneSelected() {
    return this.formGroup.get(this.recommendNoneControlName).value;
  }

  constructor() {
    super();
  }

  private currentState: RecommendationSectionState = 'allEnabled';

  get anyDependentControlHasValue(): boolean {
    return this.dependentControls.some(name => !!this.formGroup.get(name).value);
  }

  // user event
  onRecommendNoneUpdated(): void {
    if (this.currentState === 'allEnabled' && this.isRecommendNoneSelected) {
      return this.setNewStateAndAdjustControls('dependentControlsDisabled');
    } else if (this.currentState === 'dependentControlsDisabled' && !this.isRecommendNoneSelected) {
      return this.setNewStateAndAdjustControls('allEnabled');
    }
  }

  onDependentControlUpdated() {
    if (this.currentState === 'dependentControlsEnabled' && !this.anyDependentControlHasValue) {
      this.setNewStateAndAdjustControls('allEnabled');
    } else if (this.currentState === 'allEnabled' && this.anyDependentControlHasValue) {
      this.setNewStateAndAdjustControls('dependentControlsEnabled');
    }
  }

  toggleReevalCheckbox(hasValueAfterUpdate: boolean) {
    const parentCheckbox = this.formGroup.get('recommendReeval');
    const shouldGuardAgainstRepetitiveUpdates = hasValueAfterUpdate && parentCheckbox.value;
    if (!shouldGuardAgainstRepetitiveUpdates) {
      parentCheckbox.setValue(hasValueAfterUpdate);
      this.onDependentControlUpdated();
    }
  }

  // internal
  private init(): void {
    const anyDependentControlHasValue = this.anyDependentControlHasValue;
    if (anyDependentControlHasValue) {
      this.setNewStateAndAdjustControls('dependentControlsEnabled');
    } else {
      if (this.isRecommendNoneSelected) {
        this.setNewStateAndAdjustControls('dependentControlsDisabled');
      }
    }
  }

  private setNewStateAndAdjustControls(newState: RecommendationSectionState) {
    switch (newState) {
      case 'allEnabled':
        this.currentState = newState;

        this.allControlNames.forEach(controlName => this.formGroup.get(controlName).enable());
        break;
      case 'dependentControlsEnabled':
        if (this.currentState === 'allEnabled') {
          this.currentState = newState;

          this.disableAndResetControl(this.recommendNoneControlName);
        }
        break;
      case 'dependentControlsDisabled':
        if (this.currentState === 'allEnabled') {
          this.currentState = newState;

          this.dependentControls.forEach(controlName => this.disableAndResetControl(controlName));
        }
        break;
      default:
        throw new Error('Invalid state');
    }
    this.showSectionError = this.isRecommendNoneSelected && this.anyDependentControlHasValue;
  }

  private disableAndResetControl(controlName: string) {
    const control = this.formGroup.get(controlName);
    control.reset();
    control.disable();
  }
}

type RecommendationSectionState = 'allEnabled' | 'dependentControlsDisabled' | 'dependentControlsEnabled';
