<mat-expansion-panel
  [expanded]="isExpanded"
  hideToggle
  (expandedChange)="isExpanded = $event"
  class="patient-details-block mat-elevation-z"
>
  <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto" class="migrated p-0">
    <div class="full-width">
      <div class="flex-row-container layout-align-stretch-row main-header bold" [ngClass]="{ draft: isDraft }">
        <div class="action-icon-container bordered-right">
          <mat-icon>{{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </div>
        <div class="custom-flex-100">
          <stx-patient-details-heading
            [patient]="patient"
            [genders]="genders"
            [countries]="countries"
            [hasDarkBackground]="true"
          ></stx-patient-details-heading>
        </div>
        <div class="flex-nogrow align-right">
          <div class="action-icon-container bordered-left">
            <a (click)="$event.stopPropagation()" [routerLink]="printPatientRoute" target="_blank" class="flex-center">
              <mat-icon [matTooltip]="'patient.patient' | translate" [matTooltipClass]="'tooltip'" class="material-icons-outlined"
                >contact_page</mat-icon
              >
            </a>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel-header>
  <div class="flex-row-container wrap align-center under-header-container">
    <div class="flex-column-container p-l-5 p-r-5 p-t-15 relative readonly-view-image-container">
      <stx-readonly-view-image *ngIf="!!patientPhotoUrl" [photo]="patientPhotoUrl" [showAltImage]="true"></stx-readonly-view-image>
      <mat-expansion-panel *ngIf="potentialMatches?.length > 0 && isExpanded" class="max-full-width">
        <mat-expansion-panel-header class="migrated">
          <mat-panel-title>{{ 'offline_app.potential_matches_found' | translate }} </mat-panel-title></mat-expansion-panel-header
        >
        <stx-patient-matching
          *ngIf="patient && potentialMatches"
          [mainPatientId]="patient.id"
          [potentialMatches]="potentialMatches"
          (refreshPotentialMatches)="getPotentialMatches(patient.id)"
        ></stx-patient-matching>
      </mat-expansion-panel>
    </div>
    <div class="form-element-75-70-100-container">
      <ng-container *ngFor="let treatment of patient.patientTreatments; even as isEven">
        <stx-patient-treatment [patientTreatment]="treatment" [isEven]="isEven" [patient]="patient"></stx-patient-treatment>
      </ng-container>
      <div *ngIf="shouldDisplayNewTreatmentBtn">
        <mat-expansion-panel
          (opened)="getAllowedTreatments()"
          class="actions-expansion-panel"
          *ngIf="!patient.isReadonly"
          hideToggle
          (expandedChange)="newTreatmentExpanded = $event"
        >
          <mat-expansion-panel-header
            *stxPermitted="PermissionEnum.ADD_NEW_TREATMENT"
            collapsedHeight="auto"
            expandedHeight="auto"
            class="button-expansion-panel migrated p-l-0 p-t-10 p-b-10 color-link new-treatment-expansion-header"
          >
            <div class="align-center display-flex">
              <mat-icon *stxPermitted="PermissionEnum.ADD_NEW_TREATMENT" class="m-r-5 material-icons-outlined">add_circle_outline</mat-icon>
              <span class="m-r-10 fs-14">{{ 'patient.new_treatment' | translate }}</span>
              <mat-icon>{{ newTreatmentExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
            </div>
          </mat-expansion-panel-header>
          <div class="m-l-15" *stxPermitted="PermissionEnum.CLONE_PATIENT">
            <ng-container *ngIf="isPatientCloneRequired">
              <div class="m-b-15">{{ 'patient.clone_info' | translate }}</div>
              <button data-testid="button-patient-detail-clone" mat-stroked-button color="primary" (click)="openDialogForClone()">
                {{ 'patient.clone' | translate }}
              </button>
            </ng-container>
          </div>
          <div class="m-l-15" *ngIf="!isPatientCloneRequired">
            <ng-container *ngIf="areAllowedTreatmentsLoaded && allowedTreatments.length > 0">
              <div class="gap-10" *ngFor="let allowedTreatmentGroup of allowedTreatments">
                <div class="flex-row-container wrap gap-20 treatment-types-container">
                  <div *ngFor="let allowedTreatment of allowedTreatmentGroup">
                    <div *stxPermitted="getPermissionToAdd(allowedTreatment)" class="layout-align-center display-flex">
                      <a
                        [queryParams]="{ patientId: patientIdsForNewTreatment[allowedTreatment] }"
                        [routerLink]="[getTreatmentTypeData(allowedTreatment).path + '/new']"
                        class="display-flex align-middle"
                      >
                        {{ getTreatmentTypeData(allowedTreatment).name | translate }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="areAllowedTreatmentsLoaded && allowedTreatments.length === 0">
              <div class="display-flex align-top">
                <stx-notice> {{ 'patient.no_new_treatments_available' | translate }}</stx-notice>
              </div>
            </ng-container>
            <div *ngIf="showRedCapEntLinks" class="external-treatments-container m-b-10">
              <div *ngFor="let entLink of redCapEntLinks">
                <div *stxPermitted="getPermissionToAdd(entLink.treatmentType)" class="external-treatment-item">
                  <a rel="noopener noreferrer" target="_blank" [href]="entLink.link" class="display-flex align-middle">
                    {{ externalTreatmentKeys[entLink.treatmentType] | translate }}
                  </a>
                </div>
              </div>
            </div>
            <div *ngIf="showRedCapOralHealthLinks" class="external-treatments-container">
              <div *ngFor="let oralHealthLink of redCapOralHealthLinks">
                <div *stxPermitted="getPermissionToAdd(oralHealthLink.treatmentType)" class="external-treatment-item">
                  <a rel="noopener noreferrer" target="_blank" [href]="oralHealthLink.link" class="display-flex align-middle">
                    {{ externalTreatmentKeys[oralHealthLink.treatmentType] | translate }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
      <div *ngIf="shouldShowNoPRFNotice && !patient.isReadonly">
        <stx-notice noticeType="warn">
          {{ 'patient.release_form_upload' | translate }}
        </stx-notice>
      </div>
      <div *ngIf="(mode === PatientDetailsBlockMode.FORM_ON_TOP && patient.patientIdForNewReleaseForm) || releaseForms?.length === 0">
        <ng-container *ngIf="!patient.isReadonly">
          <div *stxPermitted="PermissionEnum.ATTACH_RELEASE_FORM">
            <button data-testid="button-patient-detail-release" mat-button color="primary" class="p-l-0" (click)="showReleaseFormBlock()">
              <div class="flex-row-container layout-align-center">
                <mat-icon class="m-r-5 material-icons-outlined">add_circle_outline</mat-icon>
                {{ 'patient.release_form' | translate }}
                &nbsp;<stx-file-icon></stx-file-icon>
              </div>
            </button>
          </div>
        </ng-container>
        <div class="flex-row-container m-t-10 m-b-10 p-l-5 p-r-5">
          <ng-container *ngFor="let releaseForm of releaseForms; last as isLast">
            <ng-container *ngIf="!patient.isReadonly; else prfReadonly">
              <a href="{{ releaseForm.url }}" target="_blank">{{ releaseForm.createdAt | date : 'yyyy-MM-dd' }}</a>
            </ng-container>
            <ng-template #prfReadonly>
              <p>{{ releaseForm.createdAt | date : 'yyyy-MM-dd' }}</p>
            </ng-template>
            <div class="m-r-10 m-l-10" *ngIf="!isLast">
              <mat-divider [vertical]="true" class="full-height"></mat-divider>
            </div>
          </ng-container>
        </div>
        <div
          class="flex-column-container p-l-5 p-r-5"
          *ngIf="patient && showReleaseForm && !patient.isReadonly"
          [formGroup]="releaseFormFormGroup"
        >
          <stx-patient-release-form-block
            [stxFocusElement]="focusElementQuery"
            [focusWhen]="focusPrfBlockTrigger$"
            [patientDataMatchesOnlineReleaseFormData]="null"
            [patientDataMatchesOfflineReleaseFormData]="null"
            [patientDataProvider]="patientDataForReleaseForm"
          ></stx-patient-release-form-block>
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
