<div mat-dialog-content [ngClass]="{ 'assign-training-date-modal': !isInNewStageMode }">
  <div class="flex-column-container gap-30">
    <ng-container *ngIf="formGroup">
      <ng-container *ngIf="isInNewStageMode; else trainDateForm">
        <form [formGroup]="formGroup">
          <h3>{{ 'practitioner_qa.assign_new_review_stage.header' | translate }}</h3>
          <div class="qa-assign-form-container gap-15">
            <div class="custom-flex-50">
              <stx-radio-group
                class="p-l-5"
                [layoutDirection]="'column'"
                [controlName]="'nextStage'"
                [formGroup]="formGroup"
                [formMode]="formMode"
                [options]="nextStageOptions"
              ></stx-radio-group>
            </div>
            <div class="flex-column-container custom-flex-50">
              <div class="flex-column-container" [class.visibility-hidden]="isNewStagePeriodic">
                <stx-date
                  [label]="'practitioner_qa.assign_new_review_stage.date'"
                  [controlName]="'date'"
                  [formGroup]="formGroup"
                  [formMode]="formMode"
                  [maxDateToday]="false"
                ></stx-date>
                <stx-input
                  [class.visibility-hidden]="isNewStageTrain"
                  [label]="'practitioner_qa.assign_new_review_stage.cases_before_next'"
                  [formGroup]="formGroup"
                  [formMode]="formMode"
                  [controlName]="'monitorUntilCount'"
                  [type]="'number'"
                  [fullWidth]="true"
                ></stx-input>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </ng-container>
    <stx-action-buttons
      [primaryText]="'save' | translate"
      [asyncPrimaryAction]="save"
      [primaryDisabled]="submitClicked"
      [secondaryText]="'dialog.back' | translate"
      (secondaryAction)="back()"
    ></stx-action-buttons>
  </div>
</div>

<ng-template #trainDateForm>
  <h3>{{ 'practitioner_qa.assign_training_date.header' | translate }}</h3>
  <div class="flex-row-container">
    <div class="custom-form-container">
      <form [formGroup]="formGroup" class="flex-column-container custom-gap-container">
        <stx-date
          [label]="'practitioner_qa.assign_training_date.date'"
          [controlName]="'trainDate'"
          [formGroup]="formGroup"
          [formMode]="formMode"
          [maxDateToday]="false"
        ></stx-date>
      </form>
    </div>
  </div>
</ng-template>
