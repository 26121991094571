<div class="form-row" [ngClass]="{ 'flex-row-important': print }">
  <stx-date
    [label]="isTreatment ? 'speech_treatment.report_date' : 'speech_assessment.date_of_assessment'"
    [controlName]="isTreatment ? 'reportDate' : 'assessmentDate'"
    [formGroup]="formGroup"
    [minDate]="minDate"
    [maxDateToday]="true"
    [print]="print"
    [formMode]="formMode"
  ></stx-date>
  <stx-select
    [label]="'treatment_center.treatment_center'"
    [controlName]="'treatmentCenterId'"
    [dictionaryList]="tcList"
    [formGroup]="formGroup"
    [print]="print"
    [formMode]="formMode"
  ></stx-select>
  <stx-select
    *ngIf="speechPractitionerList"
    [label]="'practitioner.practitioner_speech'"
    [controlName]="'practitionerId'"
    [dictionaryList]="speechPractitionerList"
    [formGroup]="formGroup"
    [print]="print"
    [formMode]="formMode"
  ></stx-select>
  <stx-select
    [label]="'language'"
    [controlName]="'countryLanguageId'"
    [dictionaryList]="tcLanguages"
    [formGroup]="formGroup"
    [print]="print"
    [formMode]="formMode"
  ></stx-select>
</div>
