import { SuspiciousRecordProblemType } from './enums/problem-type.enum';
import { httpStatusConflict } from '@utils/http.utils';

export const rejectionReasonMap = new Map<number, string>([
  [0, 'duplicateRecord'],
  [1, 'other'],
  [2, 'missingPhotos'],
  [3, 'photosDontMeetStandards'],
  [4, 'unacceptableAge'],
  [5, 'surgeryTypeMislabeled'],
  [6, 'surgeryNotFundedByST'],
  [7, 'surgeryResultsNotEvident'],
  [8, 'exceedsQuota'],
  [9, 'duplicatePrimaryOperation'],
  [12, 'saqProtocolsViolated']
]);

export const problemSeverityMap = new Map<number, SuspiciousRecordProblemType>([
  [1, SuspiciousRecordProblemType.NOT_PROBLEMATIC],
  [8, SuspiciousRecordProblemType.PROBLEMATIC_SEVERE],
  [9, SuspiciousRecordProblemType.PROBLEMATIC_MODERATE],
  [10, SuspiciousRecordProblemType.PROBLEMATIC_MILD]
]);

export const problematicOptions = [
  {
    name: 'notProblematic',
    label: 'suspicious_records.not_problematic',
    value: SuspiciousRecordProblemType.NOT_PROBLEMATIC
  },
  {
    name: 'problematicMild',
    label: 'suspicious_records.problematic_mild',
    value: SuspiciousRecordProblemType.PROBLEMATIC_MILD
  },
  {
    name: 'problematicModerate',
    label: 'suspicious_records.problematic_moderate',
    value: SuspiciousRecordProblemType.PROBLEMATIC_MODERATE
  },
  {
    name: 'problematicSevere',
    label: 'suspicious_records.problematic_severe',
    value: SuspiciousRecordProblemType.PROBLEMATIC_SEVERE
  }
];

export const rejectionReasons = [
  'duplicatePrimaryOperation',
  'photosDontMeetStandards',
  'surgeryTypeMislabeled',
  'surgeryNotFundedByST',
  'surgeryResultsNotEvident',
  'saqProtocolsViolated'
];

export const TREATMENT_STATE_ERROR = httpStatusConflict;

export function execCallbackOnTreatmentStateError(err: any, callback: () => void) {
  if (err.status && err.status === TREATMENT_STATE_ERROR) {
    callback();
  }
}
