import { ParentOrderName } from '@shared/enums/parent-order-name.enum';

export class InstrumentalAssessmentColumnConfig {
  headerLabel: string;
  dateControlName: string;
  videoParentOrderName: ParentOrderName;
  atRestParentOrderName: ParentOrderName;
  duringPhonationParentOrderName: ParentOrderName;
  findingControlName: string;
}

export type NasometryInputConfiguration = {
  controlName: string;
  label: string;
};

export const nasopharyngoscopyConfig: InstrumentalAssessmentColumnConfig = {
  headerLabel: 'speech_assessment.nasopharyngoscopy',
  dateControlName: 'nasoscopyDate',
  videoParentOrderName: ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_VIDEO,
  atRestParentOrderName: ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_REST,
  duringPhonationParentOrderName: ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_PHONATION,
  findingControlName: 'nasoscopyFindings'
};

export const videofluoroscopyConfig: InstrumentalAssessmentColumnConfig = {
  headerLabel: 'speech_assessment.videofluoroscopy',
  dateControlName: 'videoscopyDate',
  videoParentOrderName: ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_VIDEO,
  atRestParentOrderName: ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_REST,
  duringPhonationParentOrderName: ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_PHONATION,
  findingControlName: 'videoscopyFindings'
};

export const nasometryInputConfiguration: Array<NasometryInputConfiguration> = [
  {
    controlName: 'nasalanceScorePb',
    label: 'speech_assessment.nasalance_score_pb'
  },
  {
    controlName: 'nasalanceScoreTd',
    label: 'speech_assessment.nasalance_score_td'
  },
  {
    controlName: 'nasalanceScoreKg',
    label: 'speech_assessment.nasalance_score_kg'
  },
  {
    controlName: 'nasalanceScoreSz',
    label: 'speech_assessment.nasalance_score_sz'
  },
  {
    controlName: 'nasalanceScoreNasals',
    label: 'speech_assessment.nasalance_score_nasals'
  }
];
