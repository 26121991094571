<form [formGroup]="formGroup">
  <stx-expandable-form-section [expanded]="true">
    <ng-container slot="heading">{{ 'surgical_intervention.intervention_information' | translate }}</ng-container>
    <stx-form-panel>
      <div class="form-row">
        <stx-date
          label="surgical_intervention.admission_date"
          controlName="admissionDate"
          [formGroup]="formGroup"
          [minDate]="maxOfDatesOrDayAfterDob()"
          [maxDate]="minOfDatesOrToday([formGroup.get('operationDate').value, today])"
          [formMode]="formMode"
        ></stx-date>
        <stx-date
          label="treatment.operation_date"
          controlName="operationDate"
          [formGroup]="formGroup"
          [minDate]="maxOfDatesOrDayAfterDob([formGroup.get('admissionDate').value])"
          [maxDate]="today"
          [formMode]="formMode"
        ></stx-date>
        <stx-date
          label="surgical_intervention.discharge_date"
          controlName="dischargeDate"
          [formGroup]="formGroup"
          [minDate]="maxOfDatesOrDayAfterDob([formGroup.get('admissionDate').value, formGroup.get('operationDate').value])"
          [maxDate]="formGroup.get('followVisitDate').value"
          [formMode]="formMode"
        ></stx-date>
        <stx-date
          label="surgical_intervention.follow_visit_date"
          controlName="followVisitDate"
          [formGroup]="formGroup"
          [minDate]="maxOfDatesOrDayAfterDob([formGroup.get('operationDate').value, formGroup.get('dischargeDate').value])"
          [formMode]="formMode"
        ></stx-date>
      </div>
      <hr class="form-subsection-separator" />
      <div class="form-columns-1-1">
        <div class="form-row">
          <stx-input
            [formMode]="formMode"
            [formGroup]="formGroup"
            controlName="weight"
            type="number"
            label="patient.weight"
            [subLabel]="('unit.kg' | translate) + ' (1.00 - 200.00)'"
          ></stx-input>
          <stx-input
            [formMode]="formMode"
            [formGroup]="formGroup"
            controlName="height"
            type="number"
            label="height"
            [subLabel]="('unit.cm' | translate) + ' (1.00 - 200.00)'"
          ></stx-input>
        </div>
      </div>
      <hr class="form-subsection-separator" />
      <div class="form-row">
        <stx-select
          [formMode]="formMode"
          [formGroup]="formGroup"
          controlName="treatmentCenterId"
          label="treatment_center.treatment_center"
          [dictionaryList]="tcList$ | async"
        ></stx-select>
        <stx-select
          [formMode]="formMode"
          [formGroup]="formGroup"
          controlName="practitionerId"
          label="surgical_intervention.surgeon_name"
          [dictionaryList]="surgeonList"
        ></stx-select>
      </div>
      <hr class="form-subsection-separator" />
      <stx-anesthesiologist-select class="flex-fill" [formMode]="formMode" [selectDataSource]="anesthesiologistList">
        <stx-radio-group
          [formMode]="formMode"
          [formGroup]="formGroup"
          controlName="anesthMethod"
          label="surgical_intervention.anesth_method"
          layoutDirection="row"
          [options]="anesthMethods"
        ></stx-radio-group>
      </stx-anesthesiologist-select>
      <stx-time-select
        [formControlName]="'operationTime'"
        label="surgical_intervention.operation_time"
        timeFormatSuffix="time_format"
      ></stx-time-select>
      <hr class="form-subsection-separator hide-on-print" />
      <div class="form-row" [ngClass]="{ 'p-t-30 page-break-inside-avoid': print }">
        <stx-file
          [shouldDisplayRemoveIcon]="shouldDisplayRemoveFileIcon"
          [parentOrderName]="ParentOrderName.ANESTHESIA_PRE_OPERATIVE"
          [label]="'order_name.anesthesia_pre_operative'"
          [acceptedTypes]="acceptedMediaTypes"
          [formGroup]="formGroup"
          [formControlName]="ParentOrderName.ANESTHESIA_PRE_OPERATIVE"
        >
        </stx-file>
        <stx-file
          [shouldDisplayRemoveIcon]="shouldDisplayRemoveFileIcon"
          [parentOrderName]="ParentOrderName.ANESTHESIA_INTRA_OPERATIVE"
          [label]="'order_name.anesthesia_intra_operative'"
          [acceptedTypes]="acceptedMediaTypes"
          [formControlName]="ParentOrderName.ANESTHESIA_INTRA_OPERATIVE"
          [formGroup]="formGroup"
          [isRequired]="true"
          [customErrorMessage]="'error.upload_file' | translate"
        >
        </stx-file>
        <stx-file
          [shouldDisplayRemoveIcon]="shouldDisplayRemoveFileIcon"
          [parentOrderName]="ParentOrderName.ANESTHESIA_POST_OPERATIVE"
          [label]="'order_name.anesthesia_post_operative'"
          [acceptedTypes]="acceptedMediaTypes"
          [formGroup]="formGroup"
          [formControlName]="ParentOrderName.ANESTHESIA_POST_OPERATIVE"
        >
        </stx-file>
      </div>
      <hr class="form-section-separator hide-on-print" />
      <div>
        <div class="form-row">
          <div>
            <p class="form-section-heading">
              {{ 'surgical_intervention.operation_type' | translate }}
            </p>
            <p>
              {{ 'surgical_intervention.operation_type_description' | translate }}
            </p>
          </div>
        </div>

        <div class="form-row" *ngIf="shouldDisplaySurgicalOperationError">
          <mat-error class="stx-mat-error-align">
            {{ 'error.surgical_intervention_operation_type' | translate }}
          </mat-error>
        </div>
        <div class="form-row">
          <stx-checkbox-section
            [label]="'surgery.primary_lip_nose_unilateral' | translate"
            controlName="primaryLipNoseUnilateral"
            [print]="print"
          >
            <div class="form-row">
              <stx-radio-group
                layoutDirection="2cols"
                controlName="primaryLipNoseUnilateralRepairType"
                [formGroup]="formGroup"
                [formMode]="formMode"
                [options]="staticDictionaries?.get('primaryLipNoseUnilateralType') | transposeColumnsToRows : 2"
                [print]="print"
              ></stx-radio-group>
            </div>
          </stx-checkbox-section>
        </div>
        <div class="form-row">
          <stx-checkbox-section
            [label]="'surgery.primary_lip_nose_bilateral' | translate"
            controlName="primaryLipNoseBilateral"
            [print]="print"
          >
            <div class="form-row">
              <stx-radio-group
                layoutDirection="2cols"
                controlName="primaryLipNoseBilateralRepairType"
                [formGroup]="formGroup"
                [formMode]="formMode"
                [options]="staticDictionaries?.get('primaryLipNoseBilateralType') | transposeColumnsToRows : 2"
                [print]="print"
              ></stx-radio-group>
            </div>
          </stx-checkbox-section>
        </div>
        <div class="form-row">
          <stx-checkbox-section [label]="'surgery.primary_cleft_palate' | translate" controlName="primaryCleftPalate" [print]="print">
            <div class="form-row">
              <stx-radio-group
                layoutDirection="2cols"
                controlName="primaryCleftPalateRepairType"
                [formGroup]="formGroup"
                [formMode]="formMode"
                [options]="staticDictionaries?.get('primaryCleftPalateType') | transposeColumnsToRows : 2"
                [print]="print"
              ></stx-radio-group>
            </div>
          </stx-checkbox-section>
        </div>
        <div class="form-row">
          <stx-checkbox-section [label]="'surgical_intervention.fistula_repair' | translate" controlName="fistula" [print]="print">
            <div class="form-row">
              <stx-radio-group
                layoutDirection="2cols"
                controlName="fistulaRepairType"
                [formGroup]="formGroup"
                [formMode]="formMode"
                [options]="staticDictionaries?.get('fistulaRepairType') | transposeColumnsToRows : 2"
                [print]="print"
              ></stx-radio-group>
            </div>
          </stx-checkbox-section>
        </div>
        <div class="form-row" *ngIf="form.displayVelopharyngealDisfunction">
          <stx-checkbox-section
            [label]="'surgical_intervention.velopharyngeal_disfunction' | translate"
            controlName="velopharyngealDisfunction"
            [print]="print"
          >
            <div class="form-row">
              <p>
                {{ 'surgical_intervention.velopharyngeal_disfunction_disclaimer' | translate }}
              </p>
            </div>
            <div class="form-row">
              <stx-radio-group
                layoutDirection="3cols"
                controlName="velopharyngealDisfunctionRepairType"
                [formGroup]="formGroup"
                [formMode]="formMode"
                [options]="staticDictionaries?.get('velopharyngealDisfunctionType')"
                [print]="print"
              ></stx-radio-group>
            </div>
          </stx-checkbox-section>
        </div>
        <div class="form-row">
          <stx-checkbox-section
            [label]="'surgical_intervention.lip_nose_revision' | translate"
            controlName="lipNoseRevision"
            [print]="print"
          >
            <div class="form-row">
              <stx-radio-group
                layoutDirection="2cols"
                controlName="lipNoseRevisionRepairType"
                [formGroup]="formGroup"
                [formMode]="formMode"
                [options]="staticDictionaries?.get('lipNoseRevisionType') | transposeColumnsToRows : 2"
                [print]="print"
              ></stx-radio-group>
            </div>
          </stx-checkbox-section>
        </div>
        <div class="form-row">
          <stx-checkbox-section
            [label]="'surgical_intervention.alveolar_bone_graft' | translate"
            controlName="alveolarBoneGraft"
            [print]="print"
          >
            <div class="form-row">
              <stx-radio-group
                layoutDirection="3cols"
                controlName="alveolarBoneGraftRepairType"
                [formGroup]="formGroup"
                [formMode]="formMode"
                [options]="staticDictionaries?.get('alveolarBoneGraftType')"
                [print]="print"
              ></stx-radio-group>
            </div>
          </stx-checkbox-section>
        </div>
      </div>
      <div class="form-row">
        <stx-input
          [formGroup]="formGroup"
          [formMode]="formMode"
          label="surgical_intervention.other_intervention"
          controlName="repairComment"
        ></stx-input>
      </div>

      <hr class="form-section-separator" />

      <div class="page-break-inside-avoid">
        <div class="form-row">
          <stx-radio-group
            [formMode]="formMode"
            [formGroup]="formGroup"
            label="surgical_intervention.complications_any"
            controlName="complicationAny"
            [options]="yesNo"
            layoutDirection="row"
          ></stx-radio-group>
        </div>
        <div class="form-row">
          <div>
            <span class="form-element-label">
              {{ 'surgical_intervention.complications_injury' | translate }}
            </span>
            <stx-radio-group
              [formMode]="formMode"
              [formGroup]="formGroup"
              label="surgical_intervention.complications_injury_yes"
              controlName="complicationInjury"
              [options]="yesNo"
              layoutDirection="row"
            ></stx-radio-group>
          </div>
        </div>
        <div class="form-row">
          <stx-checkbox-group
            label="surgical_intervention.complication_breathing_problems_yes"
            layoutDirection="row"
            [options]="complicationTypes"
            [formGroup]="formGroup"
            [formMode]="formMode"
            [print]="print"
          ></stx-checkbox-group>
        </div>
        <div class="form-row">
          <stx-textarea
            [formMode]="formMode"
            [formGroup]="formGroup"
            controlName="additionalComment"
            label="surgical_intervention.comment"
          ></stx-textarea>
        </div>
      </div>

      <hr class="form-section-separator hide-on-print" />

      <ng-container *ngIf="form.displayChinaSupportOptions">
        <div class="form-row">
          <stx-radio-group
            [formMode]="formMode"
            [formGroup]="formGroup"
            label="surgical_intervention.china_support"
            controlName="chinaSupport"
            [options]="yesNo"
            layoutDirection="row"
          ></stx-radio-group>
        </div>
        <div class="form-row">
          <stx-checkbox
            [formMode]="formMode"
            [formGroup]="formGroup"
            name="surgical_intervention.china_support_orphan"
            controlName="chinaSupportOrphan"
          ></stx-checkbox>
        </div>
        <div class="form-row">
          <stx-checkbox
            [formMode]="formMode"
            [formGroup]="formGroup"
            name="surgical_intervention.china_support_migrant"
            controlName="chinaSupportMigrant"
          ></stx-checkbox>
        </div>
        <div class="form-row">
          <stx-checkbox
            [formMode]="formMode"
            [formGroup]="formGroup"
            name="surgical_intervention.china_support_region_nofund"
            controlName="chinaSupportRegionNofund"
          ></stx-checkbox>
        </div>
        <div class="form-row" *ngIf="formGroup?.touched && formGroup?.hasError('chinaSupportFields')">
          <mat-error class="stx-mat-error-align">
            {{ 'error.none_selected' | translate }}
          </mat-error>
        </div>
      </ng-container>
      <div class="form-row">
        <ng-container *ngIf="form.isPhilippinesTcSelected">
          <stx-checkbox
            [formMode]="formMode"
            [formGroup]="formGroup"
            name="surgical_intervention.phil_health"
            controlName="philHealth"
          ></stx-checkbox>
        </ng-container>
      </div>
    </stx-form-panel>
  </stx-expandable-form-section>

  <stx-action-buttons *ngIf="!print" [showPrimary]="false" [asyncSecondaryAction]="onSaveButtonClicked()"></stx-action-buttons>

  <div [ngClass]="{ 'page-break-inside-avoid': print }">
    <stx-expandable-form-section [expanded]="true">
      <ng-container slot="heading">{{ 'photos' | translate }}</ng-container>
      <stx-form-panel>
        <stx-image-group [formGroup]="formGroup" [formMode]="formMode" [imageConfigs]="imageConfigs" [print]="print"></stx-image-group>
      </stx-form-panel>
    </stx-expandable-form-section>
  </div>

  <stx-expandable-form-section [expanded]="true" *ngIf="form.isIndianTcSelected">
    <ng-container slot="heading">{{ 'patient.declaration_form' | translate }}</ng-container>
    <stx-form-panel *ngIf="!print">
      <stx-patient-document-file-upload
        [formType]="formType"
        (newFileUploaded)="form.indiaDeclarationForm.setValue($event)"
      ></stx-patient-document-file-upload>
      <ng-container *ngIf="form.indiaDeclarationForm.touched && form.indiaDeclarationForm.errors">
        <mat-error class="stx-mat-error-align">{{ 'error.upload_document' | translate }}</mat-error>
      </ng-container>
    </stx-form-panel>
  </stx-expandable-form-section>

  <div class="form-row">
    <ng-container *ngIf="!print">
      <stx-action-buttons
        [asyncSecondaryAction]="onSaveButtonClicked()"
        [asyncPrimaryAction]="onSubmitButtonClicked()"
      ></stx-action-buttons>
      <stx-action-buttons
        *stxPermitted="PermissionEnum.OVERRIDE_SAQ_ON_SAVE_SURGERY"
        [asyncPrimaryAction]="onSubmitWithSaQOverrideButtonClicked()"
        [showSecondary]="false"
        [primaryText]="'saq.violation.override_submit' | translate"
      ></stx-action-buttons>
    </ng-container>
  </div>
</form>
