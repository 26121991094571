import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentFromHtmlFileDirective } from './content-from-html-file.directive';
import { ExpandableStaticFileComponent } from './expandable-static-file/expandable-static-file.component';
import { DataBlockModule } from '@shared/components/data-block/data-block.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ContentFromHtmlFileDirective, ExpandableStaticFileComponent],
  imports: [CommonModule, DataBlockModule, TranslateModule],
  exports: [ContentFromHtmlFileDirective, ExpandableStaticFileComponent]
})
export class StaticFilesModule {}
