import { DateFilterComponentModule } from '@/src/app/features/reports/components/report-filters/components/date-filter/date-filter.component';
import { DateService } from '@/src/app/shared/services/date/date.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { FormModule } from '@shared/components/form/form.module';
import {
  RecordDatePayload,
  RecordTypeChangePayload,
  ReportDictionaryTreeItem,
  ReportFilterChange,
  ReportFiltersParams,
  ReportType
} from '@src/app/features/reports/components/report-filters/models/report-filters.model';
import { ArrayUtils } from '@utils/array.utils';
import * as moment from 'moment';
import { merge } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { RadioGroupOption } from 'src/app/shared/models/form.model';

export type RecordDatePreset = 'ALL' | 'THIS_MONTH' | 'LAST_MONTH' | 'TODAY';

interface RecordDateRange {
  dateFrom: moment.Moment | string;
  dateTo: moment.Moment | string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-record-date-filter',
  templateUrl: './record-date-filter.component.html',
  styleUrls: ['./record-date-filter.component.scss']
})
export class RecordDateFilterComponent extends BaseComponent implements OnInit {
  recordTypes = {
    careProvided: '0',
    recordSubmitted: '1'
  };

  // initial datepickers value may vary depending on report type
  @Input() reportType: ReportType;
  @Input() recordGroupReference: UntypedFormGroup;
  @Input() dateGroupReference: UntypedFormGroup;
  @Input() recordTypeList: Array<RadioGroupOption | ReportDictionaryTreeItem>;
  @Input() isStatsReport: boolean;
  @Input() datePresetValue: RecordDatePreset;
  @Output() filterChange = new EventEmitter<ReportFilterChange<RecordTypeChangePayload | RecordDatePayload>>();

  recordTypeInitialParam: string;
  dateFromInitialParam: string;
  dateToInitialParam: string;

  recordTypeAdditionalLabels: Record<string, string[]> = {};

  readonly datePresets: Array<{ label: string; value: RecordDatePreset }> = [
    {
      label: 'reports.filters.record_type.date_presets.label_this_month',
      value: 'THIS_MONTH'
    },
    {
      label: 'reports.filters.record_type.date_presets.label_last_month',
      value: 'LAST_MONTH'
    },
    {
      label: 'reports.filters.record_type.date_presets.label_today',
      value: 'TODAY'
    },
    {
      label: 'reports.filters.record_type.date_presets.label_all',
      value: 'ALL'
    }
  ];

  readonly recordTypeControlName = 'recordType';
  readonly dateFromControlName = 'dateFrom';
  readonly dateToControlName = 'dateTo';
  readonly datePresetControlName = 'datePreset';

  private dateFromControl: AbstractControl;
  private dateToControl: AbstractControl;
  private datePresetControl: AbstractControl;

  constructor(private dateService: DateService, private route: ActivatedRoute, private changeDetection: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.dateFromControl = this.dateGroupReference.get(this.dateFromControlName);
    this.dateToControl = this.dateGroupReference.get(this.dateToControlName);
    this.datePresetControl = this.recordGroupReference.get(this.datePresetControlName);

    this.setRecordTypeAdditionalLabels();
    // this should be called first, so when default values are set (they may vary for different reports)
    // filters component will update its internal model
    this.watchRecordTypeChange();
    this.watchDateRangePresets();

    this.watchQueryParams();
    this.setDefaultRecordType();
    this.setDefaultDates();
  }

  // pass up date-filter change event
  onDatesChange(event: ReportFilterChange<RecordDatePayload>) {
    this.filterChange.emit(event);
  }

  private getPresetDateRange(preset: RecordDatePreset): RecordDateRange {
    switch (preset) {
      case 'THIS_MONTH':
        const thisMonth = this.dateService.range.thisMonthUpToNow();

        return {
          dateFrom: thisMonth.from,
          // user should not be able to set future date
          dateTo: thisMonth.to
        };
      case 'LAST_MONTH':
        const lastMonth = this.dateService.range.lastMonth();

        return {
          dateFrom: lastMonth.from,
          dateTo: lastMonth.to
        };

      case 'TODAY':
        const today = this.dateService.range.today();
        return {
          dateFrom: today.from,
          dateTo: today.to
        };
      default:
        return { dateFrom: '', dateTo: '' };
    }
  }

  private setDefaultDates() {
    if (this.dateToInitialParam || this.dateFromInitialParam) {
      this.setDatesFromParams();
    } else if (this.datePresetValue) {
      this.datePresetControl.setValue(this.datePresetValue);
    } else {
      this.datePresetControl.setValue('ALL');
    }
  }

  private setDefaultRecordType() {
    if (this.recordTypeInitialParam) {
      this.setRecordTypeFromParams();
    } else {
      const defaultValue =
        this.reportType === 'SPEECH_STATS' || this.reportType === 'ORTHODONTIC_STATS'
          ? this.recordTypes.careProvided
          : this.recordTypes.recordSubmitted;

      this.recordGroupReference.get('recordType').setValue(defaultValue);
    }
  }

  private setDatesByPreset(preset: RecordDatePreset) {
    const dateRange = this.getPresetDateRange(preset);

    this.dateFromControl.setValue(dateRange.dateFrom);
    this.dateToControl.setValue(dateRange.dateTo);
    this.dateFromControl.markAsTouched();
    this.dateToControl.markAsTouched();
    this.dateFromControl.updateValueAndValidity();
    this.dateToControl.updateValueAndValidity();
  }

  private setRecordTypeAdditionalLabels() {
    this.recordTypeList
      .map<[string, string[]]>((option: ReportDictionaryTreeItem) => {
        const infoItems = (option.subSections || []).map(subSection => {
          return subSection.values.map(subSectionValue => subSectionValue.label);
        });

        return [option.value, ArrayUtils.flat(infoItems)];
      })
      .forEach(([recordType, additionalLabels]) => {
        this.recordTypeAdditionalLabels[recordType] = additionalLabels;
      });
  }

  private watchDateRangePresets() {
    // User may select date with predefined date ranges, or with datepicker inputs.
    // When date is changed with datepicker, preset button is unset
    // This flag is required to avoid infinite loop, when unsetting preset value
    let presetSelected = false;

    this.subSink.sink = this.datePresetControl.valueChanges.pipe(filter(preset => !!preset)).subscribe((preset: RecordDatePreset) => {
      presetSelected = true;
      this.setDatesByPreset(preset);
      // at this point, dates controls valueChanges emmision is done
      // flag can unset so date presets will work as expected
      presetSelected = false;
    });

    merge(this.dateFromControl.valueChanges.pipe(distinctUntilChanged()), this.dateToControl.valueChanges.pipe(distinctUntilChanged()))
      .pipe(filter(() => !presetSelected))
      .subscribe(() => {
        this.datePresetControl.setValue('');
      });
  }

  private watchRecordTypeChange() {
    this.subSink.sink = this.recordGroupReference.get(this.recordTypeControlName).valueChanges.subscribe((recordType: number) => {
      const payload: RecordTypeChangePayload = {
        recordType: [recordType]
      };

      this.filterChange.emit({
        filterName: 'recordDate',
        payload
      });
    });
  }

  private watchQueryParams() {
    this.subSink.sink = this.route.queryParams
      .pipe(filter((params: ReportFiltersParams) => !!params.recordType || !!params.dateFrom || !!params.dateTo))
      .subscribe((params: ReportFiltersParams) => {
        if (params.recordType) {
          this.recordTypeInitialParam = params.recordType;
        }
        if (params.dateFrom) {
          this.dateFromInitialParam = params.dateFrom;
        }
        if (params.dateTo) {
          this.dateToInitialParam = params.dateTo;
        }

        this.setRecordTypeFromParams();
        this.setDatesFromParams();
      });
  }

  private setDatesFromParams() {
    if (this.dateFromInitialParam) {
      this.dateFromControl.setValue(this.dateFromInitialParam);
    }
    if (this.dateToInitialParam) {
      this.dateToControl.setValue(this.dateToInitialParam);
    }

    this.changeDetection.detectChanges();
  }

  private setRecordTypeFromParams() {
    this.recordGroupReference.get('recordType').setValue(this.recordTypeInitialParam);
  }
}

@NgModule({
  imports: [CommonModule, DateFilterComponentModule, FlexLayoutModule, FormModule, MatRadioModule, ReactiveFormsModule, TranslateModule],
  declarations: [RecordDateFilterComponent],
  exports: [RecordDateFilterComponent]
})
export class RecordDateFilterComponentModule {}
