import { MediaResource } from '@shared/models/media-resource.model';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { MediaUrl } from '../features/media/models/media-url.model';
import { FileUtils } from './file.utils';
import { UntypedFormGroup } from '@angular/forms';
import { FileInfo } from '../features/media/models/media.model';

export class FormMediaUtils {
  static getMediaForFormGroup(
    value: { mediaResources?: MediaResource[] },
    parentOrderNameToHandle: ParentOrderName[]
  ): { [key: string]: MediaUrl[] } {
    const filesFromApi: { [key: string]: MediaUrl[] } = {};
    if (!value || !value.mediaResources || value.mediaResources.length === 0) {
      return filesFromApi;
    }
    parentOrderNameToHandle.forEach(parentOrderName => {
      const fileFromApi = FileUtils.findAllByParentOrderName(value.mediaResources, parentOrderName);
      if (fileFromApi) {
        filesFromApi[parentOrderName] = fileFromApi;
      }
    });
    return filesFromApi;
  }

  static extractNewMediaFromFormGroup(formGroups: UntypedFormGroup[], parentOrderNameToHandle: ParentOrderName[]): FileInfo[] {
    return parentOrderNameToHandle
      .map(parentOrderName => {
        const formControl = formGroups.find(formGroup => formGroup.contains(parentOrderName))?.get(parentOrderName);
        if (formControl?.value && FileUtils.isTypeOfFileInfo(formControl.value)) {
          return formControl.value as FileInfo;
        }
        return null;
      })
      .filter(value => !!value);
  }
}
