import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { PlotLine } from '@shared/modules/statistics/components/statistics-plot/statistics-plot.model';
import { nutritionColors, orthoColors, speechColors, surgeryColors, transformToPlots } from '@shared/modules/statistics/statistics.util';
import { StatisticsPlot, StatisticsTable } from '../../statistics.model';
import { dateTimeFormat } from '@utils/date.utils';

@Component({
  selector: 'stx-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent {
  readonly dateTimeFormat = dateTimeFormat;

  @Input() isOnDashboard = false;
  @Input() statisticsTable: StatisticsTable;
  @Input() set statisticsPlots(statisticsPlot: StatisticsPlot) {
    this.surgeryPlots = new Array<PlotLine>();
    this.speechPlots = new Array<PlotLine>();
    this.nutritionManagementPlots = new Array<PlotLine>();
    this.nutritionFormsPlots = new Array<PlotLine>();
    this.orthoPdPlots = new Array<PlotLine>();
    this.orthoMdPlots = new Array<PlotLine>();
    this.orthoPsioPlots = new Array<PlotLine>();
    if (statisticsPlot) {
      this.storePlotData(statisticsPlot);
      this.changeDetector.markForCheck();
    }
  }

  surgeryPlots: Array<PlotLine>;
  speechPlots: Array<PlotLine>;
  nutritionManagementPlots: Array<PlotLine>;
  nutritionFormsPlots: Array<PlotLine>;
  orthoPsioPlots: Array<PlotLine>;
  orthoMdPlots: Array<PlotLine>;
  orthoPdPlots: Array<PlotLine>;

  constructor(private changeDetector: ChangeDetectorRef) {}

  showPlot(plot: PlotLine[]): boolean {
    return plot && plot.find(plotLine => plotLine.plotPoints.length > 0) != null;
  }

  private storePlotData(statistics: StatisticsPlot): void {
    if (statistics.surgicalTreatment) {
      this.surgeryPlots.push({
        legendKey: 'treatment.surgical_intervention',
        plotPoints: transformToPlots(statistics.surgicalTreatment),
        color: surgeryColors.primary
      });
    }

    if (statistics.speechAssessment) {
      this.speechPlots.push({
        legendKey: 'treatment.speech_assessment',
        plotPoints: transformToPlots(statistics.speechAssessment),
        color: speechColors.primary
      });
    }
    if (statistics.speechTreatment) {
      this.speechPlots.push({
        legendKey: 'treatment.speech_treatment',
        plotPoints: transformToPlots(statistics.speechTreatment),
        color: speechColors.secondary
      });
    }

    if (statistics.nutritionManagement) {
      this.nutritionManagementPlots.push({
        legendKey: 'treatment.nutrition_management',
        plotPoints: transformToPlots(statistics.nutritionManagement),
        color: nutritionColors.primary
      });
    }

    if (statistics.nutritionAssessment) {
      this.nutritionFormsPlots.push({
        legendKey: 'treatment.nutrition_management.assessment.shorter',
        plotPoints: transformToPlots(statistics.nutritionAssessment),
        color: nutritionColors.primary
      });
    }

    if (statistics.nutritionFollowUp) {
      this.nutritionFormsPlots.push({
        legendKey: 'treatment.nutrition_management.follow_up.shorter',
        plotPoints: transformToPlots(statistics.nutritionFollowUp),
        color: nutritionColors.secondary
      });
    }

    if (statistics.orthoPsioAssessment) {
      this.orthoPsioPlots.push({
        legendKey: 'treatment.ortho_psio_assessment',
        plotPoints: transformToPlots(statistics.orthoPsioAssessment),
        color: orthoColors.primary
      });
    }
    if (statistics.orthoPsioTreatment) {
      this.orthoPsioPlots.push({
        legendKey: 'treatment.ortho_psio_treatment',
        plotPoints: transformToPlots(statistics.orthoPsioTreatment),
        color: orthoColors.secondary
      });
    }

    if (statistics.orthoMdTreatment) {
      this.orthoMdPlots.push({
        legendKey: 'treatment.ortho_md_treatment',
        plotPoints: transformToPlots(statistics.orthoMdTreatment),
        color: orthoColors.primary
      });
    }
    if (statistics.orthoMdAssessment) {
      this.orthoMdPlots.push({
        legendKey: 'treatment.ortho_md_assessment',
        plotPoints: transformToPlots(statistics.orthoMdAssessment),
        color: orthoColors.secondary
      });
    }

    if (statistics.orthoPdTreatment) {
      this.orthoPdPlots.push({
        legendKey: 'treatment.ortho_pd_treatment',
        plotPoints: transformToPlots(statistics.orthoPdTreatment),
        color: orthoColors.primary
      });
    }
    if (statistics.orthoPdAssessment) {
      this.orthoPdPlots.push({
        legendKey: 'treatment.ortho_pd_assessment',
        plotPoints: transformToPlots(statistics.orthoPdAssessment),
        color: orthoColors.secondary
      });
    }
  }
}
