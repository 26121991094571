import { BaseObject } from 'src/app/shared/models/base-object.model';
import { ScopeType } from '../scope-type.enum';

export interface Scope extends BaseObject {
  type: ScopeType;
  parentId: number;
  valueId: number;
  value: string;
  childrenScopes?: Scope[];
}

export class ScopeFlatNode {
  scope: Scope;
  level: number;
  expandable: boolean;
}
