import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';

function routeUsesNewUi(route: ActivatedRouteSnapshot): boolean {
  return route.data.newStyles !== false;
}

@Injectable()
export class RouteStyleManager {
  constructor(router: Router, private readonly activatedRoute: ActivatedRoute) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.handleNavigationEnd(event);
      }
    });
  }

  private handleNavigationEnd(event: NavigationEnd) {
    const newUi = routeUsesNewUi(this.activatedRoute.snapshot);
    if (newUi) {
      document.body.classList.remove('old-ui');
      document.body.classList.add('new-ui');
    } else {
      document.body.classList.remove('new-ui');
      document.body.classList.add('old-ui');
    }
  }
}
