<ul class="data-spaced-column">
  <ng-container *ngFor="let medicalMeetingItem of items; last as isLast">
    <li>
      <div class="resource-spaced-column" *ngIf="medicalMeetingItem">
        <div>
          <a href="{{ medicalMeetingItem.eventLink }}" target="_blank" rel="noopener noreferrer">
            {{ medicalMeetingItem.eventName }}
          </a>
        </div>
        <div *ngIf="medicalMeetingItem.eventAbstractDue" class="indented-form-block">
          <fa-icon [icon]="faClock"></fa-icon>
          <span class="fs-12">
            {{ 'resources.education_and_research.abstract_due' | translate }}
            &nbsp;-&nbsp;
          </span>
          <span class="fs-12 text-nowrap">
            {{ medicalMeetingItem.eventAbstractDue | date : dateFormat }}
          </span>
        </div>

        <div class="fs-12" *ngIf="medicalMeetingItem.eventStart || medicalMeetingItem.eventEnd">
          <span class="text-nowrap">
            {{ medicalMeetingItem.eventStart | date : dateFormat }}
          </span>
          <ng-container *ngIf="!isRecordingsOfPastEvents(medicalMeetingItem)">
            &nbsp;–&nbsp;
            <span class="text-nowrap">
              {{ medicalMeetingItem.eventEnd | date : dateFormat }}
            </span>
          </ng-container>
        </div>
        <div class="fs-12" *ngIf="medicalMeetingItem.eventLocation">{{ medicalMeetingItem.eventLocation }}</div>
        <div class="fs-12 font-italic break-all" *ngIf="medicalMeetingItem.eventBlurb">
          {{ medicalMeetingItem.eventBlurb }}
        </div>
      </div>
    </li>
    <hr *ngIf="!isLast" class="data-thin-section-separator" />
  </ng-container>
</ul>
