<div class="form-row">
  <ng-container *ngIf="orthoStage === OrthoStage.TREATMENT">
    <stx-readonly-date-control [label]="careProvidedDateLabel" [date]="startDate"></stx-readonly-date-control>
    <stx-date
      [label]="careProvidedEndDateLabel"
      [controlName]="careProvidedEndDateControlName"
      [minDate]="minCareProvidedEndDate"
      [maxDateToday]="true"
      [formMode]="formMode"
    ></stx-date>
    <stx-discontinue-ortho [formMode]="formMode" [tooltipText]="'ortho.tooltip.discontinue'" [print]="print"></stx-discontinue-ortho>
  </ng-container>
  <ng-container *ngIf="orthoStage !== OrthoStage.TREATMENT">
    <stx-date
      [label]="careProvidedDateLabel"
      [controlName]="careProvidedDateControlName"
      [minDate]="minCareProvidedDate"
      [maxDateToday]="!maxCareProvidedDate"
      [maxDate]="maxCareProvidedDate"
      [formMode]="formMode"
    ></stx-date>
    <stx-empty-form-element></stx-empty-form-element>
    <stx-empty-form-element></stx-empty-form-element>
  </ng-container>
</div>

<hr class="form-subsection-separator" />

<div class="form-row">
  <stx-select
    [label]="'treatment_center.treatment_center'"
    [controlName]="treatmentCenterIdControlName"
    [dictionaryList]="tcList"
    [formMode]="formMode"
  ></stx-select>
  <stx-select
    *ngIf="practitionerList"
    [label]="'practitioner.orthodontist'"
    [controlName]="practitionerIdControlName"
    [dictionaryList]="practitionerList"
    [formMode]="formMode"
  ></stx-select>
  <stx-select
    *ngIf="orthoStage === OrthoStage.TREATMENT"
    [label]="'surgeon'"
    [controlName]="surgeonIdControlName"
    [dictionaryList]="surgeonList"
    [formMode]="formMode"
  ></stx-select>
</div>

<ng-container *ngIf="shouldShowNumberOfSessions">
  <hr class="form-subsection-separator" />
  <div class="form-row display-flex">
    <div class="third-width-container">
      <stx-input
        [formMode]="formMode"
        [formGroup]="formGroup"
        [controlName]="'numberOfSessions'"
        [type]="'number'"
        [label]="numberOfSessionsLabel"
        [print]="print"
      ></stx-input>
    </div></div
></ng-container>

<ng-container *ngIf="orthoFormType !== OrthoFormType.PRESURGICAL_ORTHOPEDICS && orthoStage !== OrthoStage.MID_TREATMENT_ASSESSMENT">
  <hr class="form-subsection-separator" />
  <div class="form-row">
    <stx-radio-group
      [label]="'ortho.fistula'"
      [layoutDirection]="'row'"
      [controlName]="fistulaControlName"
      [formGroup]="formGroup"
      [formMode]="formMode"
      [options]="yesNo"
      [print]="print"
    ></stx-radio-group>
    <stx-checkbox-group
      [label]="'if_yes_select'"
      [layoutDirection]="'column'"
      [options]="fistulaOptions"
      [formGroup]="formGroup"
      [formMode]="formMode"
      [print]="print"
      [formGroupErrorNames]="['fistulaFields']"
      (anyOptionSelected)="toggleFistulaControlIfAnyFistulaOptionChosen()"
    ></stx-checkbox-group>
  </div>
</ng-container>
<ng-container *ngIf="orthoFormType !== OrthoFormType.PRESURGICAL_ORTHOPEDICS">
  <hr class="form-subsection-separator" />
  <div class="form-row">
    <stx-radio-group
      [label]="oralHealthLabel"
      [layoutDirection]="'row'"
      [controlName]="'oralHealth'"
      [formGroup]="formGroup"
      [formMode]="formMode"
      [options]="yesNo"
      [print]="print"
    ></stx-radio-group>
    <stx-textarea [label]="'comments'" [controlName]="'oralHealthMore'" [formGroup]="formGroup" [formMode]="formMode"> </stx-textarea>
  </div>
</ng-container>
