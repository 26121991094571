<ng-container *ngIf="!expandable">
  <stx-patient-forms-headers-container [formType]="formType"></stx-patient-forms-headers-container>
  <h1>
    <small>{{ 'permanent_dentition.presentation' | translate }}</small>
  </h1>
  <stx-ortho-treatment-guidelines-link *ngIf="!print"></stx-ortho-treatment-guidelines-link>
  <stx-treatment-status-bar
    *ngIf="!expandable"
    [treatment]="orthoFormModel"
    (unlockClicked)="unlock()"
    (deleteClicked)="delete()"
  ></stx-treatment-status-bar>
</ng-container>

<div class="page-section-container" *ngIf="isReadonlyView">
  <stx-ortho-mid-assessment-readonly [treatment]="orthoFormModel"> </stx-ortho-mid-assessment-readonly>
</div>

<ng-container *ngIf="!isReadonlyView">
  <form *ngIf="tcList && patient" [formGroup]="treatmentFormGroup">
    <stx-ortho-general-mid-assessment-info
      #generalMidInfo
      [minEvaluationDate]="minEvaluationDate"
      [maxEvaluationDate]="maxEvaluationDate"
      [formGroup]="treatmentFormGroup"
      [orthoFormType]="OrthoFormType.PERMANENT_DENTITION"
      [tcList]="tcList"
    ></stx-ortho-general-mid-assessment-info>
    <stx-expandable-form-panel [expanded]="true">
      <ng-container slot="heading">{{ 'ortho.in_progress_photos' | translate }}</ng-container>
      <stx-image-group
        [formGroup]="treatmentFormGroup"
        [formMode]="formMode"
        [imageConfigs]="inProgressImagesConfigs"
        [print]="print"
        columns="3"
      ></stx-image-group>
    </stx-expandable-form-panel>
    <stx-action-buttons
      *ngIf="!print"
      [asyncSecondaryAction]="onSaveButtonClicked()"
      [asyncPrimaryAction]="onSubmitButtonClicked()"
    ></stx-action-buttons>
  </form>
</ng-container>
