<div class="flex-column-container potential-matches-container m-l-15">
  <div class="matching-container overflow-y-auto">
    <ng-container *ngFor="let patient of potentialMatches; let i = index">
      <div class="flex-column-container potential-match-container">
        <stx-readonly-image
          *ngIf="patientPhotoUrl(patient, MediaType.LOW_RES)"
          [highResUrl]="patientPhotoUrl(patient, MediaType.HIGH_RES)"
          [lowResUrl]="patientPhotoUrl(patient, MediaType.LOW_RES)"
          [rawUrl]="patientPhotoUrl(patient, MediaType.RAW)"
          [heightAuto]="true"
          [imageWidth]="undefinedImageWidth"
          [fullWidthImage]="true"
          (rotated)="adjustMargins($event, i)"
          [mediaId]="patientPhotoId(patient)"
        ></stx-readonly-image>
        <div class="flex-row-container">
          <div class="flex-column-container">
            <div>{{ 'patient' | translate }} - {{ patient.recordNumberId }} {{ patient.recordNumberIdLoc }}</div>
            <div>{{ patient.firstName }} {{ patient.middleName }} {{ patient.lastName }}</div>
            <div>{{ patient.firstNameLoc }} {{ patient.middleNameLoc }} {{ patient.lastNameLoc }}</div>
          </div>
          <div class="m-l-auto">
            <a (click)="$event.stopPropagation()" [routerLink]="printPatientRoute(patient.id)" target="_blank" class="patient-history-icon">
              <stx-patient-record-icon></stx-patient-record-icon>
            </a>
          </div>
        </div>
        <div #dynamicMarginElement>
          <stx-radio-group
            [label]="'patient.potential_matches'"
            [layoutDirection]="'column'"
            [controlName]="'matchResult' + i"
            [formGroup]="matchedPatientsFormGroup"
            [options]="patientMatchingOptions"
          >
          </stx-radio-group>
        </div>
      </div>
    </ng-container>
  </div>
  <stx-action-buttons
    class="m-l-5"
    [showSecondary]="false"
    (primaryAction)="refreshMatchedPatients()"
    [primaryText]="'refresh' | translate"
  ></stx-action-buttons>
</div>
