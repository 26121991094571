<div>
  <div class="flex-row-container m-b-15 align-justify layout-align-center">
    <div>
      <div class="flex-row-container wrap layout-align-center">
        <div class="m-r-15 flex-row-container wrap layout-align-center" *ngFor="let plotLine of plotLines">
          <span class="legend-dot m-r-5" [style]="'background-color:' + plotLine.color"> </span>
          <span class="fs-10">{{ plotLine.legendKey | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="chart-container m-t-25 relative">
    <div class="tooltip" #tooltip></div>
  </div>
</div>
