import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionEnum } from 'src/app/core/permissions/permission.enum';
import { PermissionGuard } from '../authentication/helpers/permission.guard';
import { UserSearchPageComponent } from './components/user-search-page/user-search-page.component';
import { UserPageComponent } from './components/user/user-page.component';

const routes: Routes = [
  { path: '', component: UserSearchPageComponent, data: { newStyles: true } },
  { path: 'new', component: UserPageComponent, canActivate: [PermissionGuard], data: { permission: PermissionEnum.NEW_USER } },
  { path: ':id', component: UserPageComponent, canActivate: [PermissionGuard], data: { permission: PermissionEnum.EDIT_USER } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {}
