<ng-container *ngIf="treatment">
  <stx-readonly-view-columns>
    <ng-container *stxReadonlyViewColumn>
      <stx-simple-readonly-field label="reports.assessment_date" [value]="treatment.assessmentDate"></stx-simple-readonly-field>
      <stx-simple-readonly-field label="age" [value]="treatment.ageMonth | ageYearMonth"></stx-simple-readonly-field>
      <stx-readonly-field label="treatment_center.treatment_center">
        <stx-tc-link [treatmentCenter]="treatment.treatmentCenter"></stx-tc-link>
      </stx-readonly-field>
      <stx-simple-readonly-field label="status" [value]="treatmentStatusLabel | translate"> </stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="treatment.telehealth_assessment"
        [value]="'yes' | if : treatment.telehealth | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech.practitioner"
        [value]="treatment.practitioner?.name | localName : treatment.practitioner?.nameLoc"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field label="language" [value]="treatment.countryLanguage?.name"></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech.stage"
        [value]="treatment.assessmentStage | dict : 'assessmentStageWithDescriptions' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field label="speech.form_version" [value]="treatment.formVersion"></stx-simple-readonly-field>
    </ng-container>
    <ng-container *stxReadonlyViewColumn>
      <stx-simple-readonly-field
        label="speech.assessment.surgery_improvement.view_label"
        [value]="treatment.cccStageHistorical | dict : 'cccStage' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech.assessment.cleft_repair_date.view_label"
        [value]="treatment.cleftRepairHistorical"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech.assessment.surgery_improvement.view_label"
        [value]="treatment.statsSinceSurgeryHistorical | dict : 'surgeryImprovement' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field label="surgical_qa_panel.comments" [value]="treatment.speechCommentHistorical"></stx-simple-readonly-field>
    </ng-container>
    <ng-container *stxReadonlyViewColumn>
      <stx-readonly-field label="speech_treatment.recommendations_readonly"> </stx-readonly-field>
      <stx-simple-readonly-field
        label="treatment.speech"
        [value]="[
          'speech_assessment.recommend_none' | if : treatment.recommendNoSpeechServices | translate,
          'speech_assessment.recommend_vpd_assessment' | if : treatment.recommendVpdAssessment | translate,
          'speech_assessment.recommend_initiate_speech_therapy' | if : treatment.recommendInitiateSpeechTherapy | translate,
          'speech_assessment.recommend_parent_education' | if : treatment.recommendParentEducation | translate,
          'speech_assessment.recommend_reevaluate_in' | if : treatment.recommendReeval | translate,
          treatment.recommendReevalMonths + ' ' + ('months' | translate) | if : treatment.recommendReevalMonths > 0
        ]"
      />
      <stx-simple-readonly-field
        label="speech_assessment.recommendation_dental_prosthetics"
        [value]="[
          'speech_assessment.recommend_consult_dental_issues' | if : treatment.recommendDentalConsult | translate,
          'speech_assessment.recommend_palatal_obturator_lift' | if : treatment.recommendDentalObturator | translate
        ]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech.recommend.surgery"
        [value]="[
          'speech_assessment.recommend_tonsillectomy_adenoidectomy' | if : treatment.recommendTonsillectomy | translate,
          'speech.recommend.fistula' | if : treatment.recommendFistulaRepair | translate,
          'speech.recommend.vpd' | if : treatment.recommendVpd | translate,
          'speech.recommend.secondary_furlow' | if : treatment.recommendVpdSecondaryFurlow | translate,
          'speech.recommend.pharyngeal_flap' | if : treatment.recommendVpdPharyngealFlap | translate,
          'speech.recommend.intravelar_veloplasty' | if : treatment.recommendVpdIntravelarVeloplasty | translate,
          'speech.recommend.sphincter_pharyngoplasty' | if : treatment.recommendVpdSphincterPharyngoplasty | translate,
          'speech.recommend.buccal_flap' | if : treatment.recommendVpdBuccalFlap | translate,
          treatment.recommendOther
        ]"
      ></stx-simple-readonly-field>
    </ng-container>
  </stx-readonly-view-columns>
  <hr />
  <stx-readonly-view-columns [wrap]="true">
    <ng-container *stxReadonlyViewColumn>
      <stx-readonly-view-section-header labelKey="speech_assessment.perceptual_assessment"></stx-readonly-view-section-header>
      <stx-simple-readonly-field
        label="speech_assessment.resonance"
        [value]="treatment.resonance | dict : 'resonance' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech_instructions.assessment.nasal_emission.title"
        [value]="[
          'speech_assessment.nasal_emission_none' | if : treatment.nasalEmissionNone | translate,
          'speech_assessment.nasal_emission_audible' | if : treatment.nasalEmissionAmplified | translate,
          'speech_assessment.nasal_emission_phoneme_articulation_errors' | if : treatment.nasalEmissionPhoneme | translate,
          'speech_assessment.nasal_emission_phoneme_articulation_errors_yes_s' | if : treatment.nasalEmissionPhonemeS | translate,
          'speech_assessment.nasal_emission_phoneme_articulation_errors_yes_z' | if : treatment.nasalEmissionPhonemeZ | translate,
          'speech_assessment.nasal_emission_phoneme_articulation_errors_yes_sh' | if : treatment.nasalEmissionPhonemeSh | translate,
          'speech_assessment.nasal_emission_phoneme_articulation_errors_yes_zh' | if : treatment.nasalEmissionPhonemeZh | translate,
          'speech_assessment.nasal_emission_phoneme_articulation_errors_yes_ch' | if : treatment.nasalEmissionPhonemeCh | translate,
          'speech_assessment.nasal_emission_phoneme_articulation_errors_yes_j' | if : treatment.nasalEmissionPhonemeJ | translate,
          'patient.guardian_relationship.other' | if : treatment.nasalEmissionPhonemeOther | translate
        ]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech_assessment.obligatory_distortions"
        [value]="[
          'speech_assessment.obligatory_distortion_dentition' | if : treatment.obligatoryDistortionDentition | translate,
          'speech_assessment.obligatory_distortion_vpd' | if : treatment.obligatoryDistortionVpd | translate
        ]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech_assessment.compensatory_productions"
        [value]="[
          'speech_instructions.assessment.sound.compensatory_1' | if : treatment.compensatoryGlottalStop | translate,
          'speech_instructions.assessment.sound.compensatory_2' | if : treatment.compensatoryPharyngealStop | translate,
          'speech_instructions.assessment.sound.compensatory_3' | if : treatment.compensatoryPharyngealFricative | translate,
          'speech_instructions.assessment.sound.compensatory_4' | if : treatment.compensatoryNasalFricative | translate,
          'speech_instructions.assessment.sound.compensatory_5' | if : treatment.compensatoryMidDorsumPalatalStop | translate,
          'speech_instructions.assessment.sound.compensatory_6' | if : treatment.compensatoryGlottalFricative | translate
        ]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech.placement_errors"
        [value]="[
          'speech_assessment.placement_error_pb' | if : treatment.placementErrorPb | translate,
          'speech_assessment.placement_error_td' | if : treatment.placementErrorTd | translate,
          'speech_assessment.placement_error_kg' | if : treatment.placementErrorKg | translate,
          'speech_assessment.placement_error_fv' | if : treatment.placementErrorFv | translate,
          'speech_assessment.placement_error_sz' | if : treatment.placementErrorSz | translate,
          'speech_assessment.placement_error_sh' | if : treatment.placementErrorSh | translate,
          'speech_assessment.placement_error_ch' | if : treatment.placementErrorCh | translate,
          treatment.placementErrorOther
        ]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="surgical_qa_panel.comments"
        [value]="treatment.perceptualAssessmentComment"
      ></stx-simple-readonly-field>
    </ng-container>
    <ng-container *stxReadonlyViewColumn>
      <stx-readonly-media-field
        [media]="findMediaWithLabels(parentOrderNames.SPEECH_ASSESSMENT_SAMPLE_SYLLABLE_REPETITION, ['speech.videos.syllables'])"
      ></stx-readonly-media-field>
    </ng-container>
    <ng-container *stxReadonlyViewColumn>
      <stx-readonly-media-field
        [media]="findMediaWithLabels(parentOrderNames.SPEECH_ASSESSMENT_SAMPLE_SENTENCE_REPETITION, ['speech.videos.sentences'])"
      ></stx-readonly-media-field>
    </ng-container>
  </stx-readonly-view-columns>
  <hr *ngIf="hasIntraoralAssessment" />
  <stx-readonly-view-columns>
    <<ng-container *ngIf="hasIntraoralAssessment">
      <ng-container *stxReadonlyViewColumn>
        <stx-readonly-view-section-header labelKey="speech_assessment.intraoral_assessment"></stx-readonly-view-section-header>
        <stx-simple-readonly-field
          label="speech_assessment.fistula"
          [value]="[treatment.intraoralFistula | dict : 'intraoralFistula' | async | translate, treatment.intraoralAssessmentComment]"
        ></stx-simple-readonly-field>
      </ng-container>
    </ng-container>
    <ng-container *stxReadonlyViewColumn>
      <stx-readonly-media-field
        [media]="findMediaWithLabels(parentOrderNames.SPEECH_ASSESSMENT_INTRAORAL_ASSESSMENT_PICTURE, ['photo'])"
      ></stx-readonly-media-field>
    </ng-container>
  </stx-readonly-view-columns>
  <hr *ngIf="hasInstrumentalAssessment || hasNasopharyngoscopy || hasVideofluoroscopy" />
  <stx-readonly-view-columns [wrap]="true">
    <ng-container *ngIf="hasInstrumentalAssessment">
      <ng-container *stxReadonlyViewColumn>
        <stx-readonly-view-section-header labelKey="speech_assessment.instrumental_assessment"></stx-readonly-view-section-header>
        <stx-simple-readonly-field
          label="speech_assessment.nasalance_score_pb"
          [value]="treatment.nasalanceScorePb"
        ></stx-simple-readonly-field>
        <stx-simple-readonly-field
          label="speech_assessment.nasalance_score_td"
          [value]="treatment.nasalanceScoreTd"
        ></stx-simple-readonly-field>
        <stx-simple-readonly-field
          label="speech_assessment.nasalance_score_kg"
          [value]="treatment.nasalanceScoreKg"
        ></stx-simple-readonly-field>
        <stx-simple-readonly-field
          label="speech_assessment.nasalance_score_sz"
          [value]="treatment.nasalanceScoreSz"
        ></stx-simple-readonly-field>
        <stx-simple-readonly-field
          label="speech_assessment.nasalance_score_nasals"
          [value]="treatment.nasalanceScoreNasals"
        ></stx-simple-readonly-field>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="hasNasopharyngoscopy">
      <ng-container *stxReadonlyViewColumn>
        <stx-readonly-view-section-header labelKey="speech_assessment.nasopharyngoscopy"></stx-readonly-view-section-header>
        <stx-simple-readonly-field
          label="speech_assessment.date_of_recording"
          [value]="treatment.nasoscopyDate"
        ></stx-simple-readonly-field>
        <stx-readonly-media-field
          [media]="findMediaWithLabels(parentOrderNames.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_VIDEO, ['video'])"
        ></stx-readonly-media-field>
        <stx-readonly-media-field
          [media]="
            findMediaWithLabels(parentOrderNames.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_REST, [
              'speech_assessment.picture_at_rest'
            ])
          "
        ></stx-readonly-media-field>
        <stx-readonly-media-field
          [media]="
            findMediaWithLabels(parentOrderNames.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_PHONATION, [
              'speech_assessment.picture_during_phonation'
            ])
          "
        ></stx-readonly-media-field>
        <stx-simple-readonly-field label="speech.findings" [value]="treatment.nasoscopyFindings"></stx-simple-readonly-field>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="hasVideofluoroscopy">
      <ng-container *stxReadonlyViewColumn>
        <stx-readonly-view-section-header labelKey="speech_assessment.videofluoroscopy"></stx-readonly-view-section-header>
        <stx-simple-readonly-field
          label="speech_assessment.date_of_recording"
          [value]="treatment.videoscopyDate"
        ></stx-simple-readonly-field>
        <stx-readonly-media-field
          [media]="findMediaWithLabels(parentOrderNames.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_VIDEO, ['video'])"
        ></stx-readonly-media-field>
        <stx-readonly-media-field
          [media]="
            findMediaWithLabels(parentOrderNames.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_REST, [
              'speech_assessment.picture_at_rest'
            ])
          "
        ></stx-readonly-media-field>
        <stx-readonly-media-field
          [media]="
            findMediaWithLabels(parentOrderNames.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_PHONATION, [
              'speech_assessment.picture_during_phonation'
            ])
          "
        ></stx-readonly-media-field>
        <stx-simple-readonly-field label="speech.findings" [value]="treatment.videoscopyFindings"></stx-simple-readonly-field>
      </ng-container>
    </ng-container>
  </stx-readonly-view-columns>
</ng-container>
