<stx-readonly-view-columns *ngIf="treatment">
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field label="age" [value]="treatment.ageMonth | ageYearMonth"></stx-simple-readonly-field>
    <stx-readonly-field label="treatment_center.treatment_center">
      <stx-tc-link [treatmentCenter]="treatment.treatmentCenter"></stx-tc-link>
    </stx-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-readonly-field label="treatment.orthognathic_treatment">
      <a [href]="fileUrl" target="_blank">{{ treatment.orthognOperationDate | date : 'yyyy-MM-dd' }}</a>
    </stx-readonly-field>
  </ng-container>
</stx-readonly-view-columns>
