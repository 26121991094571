import { ChangeDetectorRef, Component, NgModule, OnInit } from '@angular/core';
import { navItems } from '../nav.utils';
import { AuthService } from 'src/app/features/authentication/services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { BaseNavComponent } from '../base-nav.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/modules/material/material.module';
import { LanguageTempComponentModule } from '../../language-switcher/components/language-temp/language-temp.component';
import { MobileButtonWithSubitemsComponentModule } from '../../language-switcher/components/mobile-button-with-subitems/mobile-button-with-subitems.component';
import { PermissionModule } from '@core/permissions/permission.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

@Component({
  selector: 'stx-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent extends BaseNavComponent implements OnInit {
  navItems = navItems;
  hasActiveProfile: boolean;

  constructor(
    public authService: AuthService,
    public currentUserService: CurrentUserService,
    public router: Router,
    public cd: ChangeDetectorRef,
    public translateService: TranslateService,
    public wsHelperService: WsHelperService
  ) {
    super(authService, currentUserService, router, cd, translateService, wsHelperService);
  }

  ngOnInit() {
    this.subSink.sink = this.wsHelperService.callToSubject(this.currentUserService.getUserSubject()).subscribe(user => {
      if (user) {
        this.getNavItems();
      }
    });
  }
}

@NgModule({
  declarations: [MobileNavComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    RouterModule,
    LanguageTempComponentModule,
    MobileButtonWithSubitemsComponentModule,
    PermissionModule,
    FlexLayoutModule
  ],
  exports: [MobileNavComponent]
})
export class MobileNavBarComponentModule {}
