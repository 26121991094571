import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, RouterStateSnapshot } from '@angular/router';
import { ConfigurationService } from '@shared/services/configuration.service';
import { inject } from '@angular/core';

export const RegionalRedirectGuard: CanActivateFn | CanMatchFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const configService = inject(ConfigurationService);

  return configService
    .getRedirectUrl()
    .then(url => {
      if (url) {
        window.location.href = url;
      }
      return Promise.resolve(true);
    })
    .catch(() => Promise.resolve(false));
};
