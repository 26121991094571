import { RecordType } from '@/src/app/features/patient/patient.enum';
import { TreatmentFilter } from '@/src/app/features/search/models/treatment-filter.model';
import { getStatusLabel } from '@/src/app/shared/components/treatment/components/treatment-status-bar/treatment-status-bar.utils';
import { FormStatus } from '@/src/app/shared/enums/form-status.enum';
import { UntypedFormGroup } from '@angular/forms';
import { Item } from '@shared/models/item.model';
import { TreatmentStatus } from '@shared/models/form.model';
import { getOrthodonticFilters } from '@src/app/features/search/search-ortho.utils';

export const recordTypeMap = new Map<RecordType, number>([
  [RecordType.REPORT_CARE_PROVIDED, 0],
  [RecordType.REPORT_RECORD_SUBMITTED, 1],
  [RecordType.REPORT_RECORD_LAST_UPDATED, 2]
]);

export const formStatusToTreatmentStatusMap = new Map<FormStatus, TreatmentStatus>([
  [
    FormStatus.SUBMITTED,
    {
      status: FormStatus.SUBMITTED,
      icon: 'stop',
      label: getStatusLabel(FormStatus.SUBMITTED)
    }
  ],
  [
    FormStatus.ACCEPTED,
    {
      status: FormStatus.ACCEPTED,
      icon: 'check',
      label: getStatusLabel(FormStatus.ACCEPTED)
    }
  ],
  [
    FormStatus.DISCONTINUED,
    {
      status: FormStatus.DISCONTINUED,
      icon: 'block',
      label: getStatusLabel(FormStatus.DISCONTINUED)
    }
  ],
  [
    FormStatus.IN_PROGRESS,
    {
      status: FormStatus.IN_PROGRESS,
      icon: 'pause',
      label: getStatusLabel(FormStatus.IN_PROGRESS)
    }
  ],
  [
    FormStatus.REJECTED,
    {
      status: FormStatus.REJECTED,
      icon: 'clear',
      label: getStatusLabel(FormStatus.REJECTED)
    }
  ],
  [
    FormStatus.EXCEEDS_QUOTA,
    {
      status: FormStatus.EXCEEDS_QUOTA,
      icon: 'clear',
      label: getStatusLabel(FormStatus.EXCEEDS_QUOTA)
    }
  ]
]);

export function getIdsFromItemFormControl(formGroup: UntypedFormGroup, formControlNames: string[]): Record<string, string[]> {
  return formControlNames.reduce((accumulator, formControlName) => {
    accumulator[formControlName] = formGroup.value[formControlName]?.map((item: Item) => item.id);

    return accumulator;
  }, {});
}

export function mapToRecordTypeEnum(recordValue: number): RecordType {
  for (let [key, value] of recordTypeMap.entries()) {
    if (value === recordValue) return key;
  }
}

export function getTypeAndStatusFilters(surgicalInterventionTypes: TreatmentFilter[]): TreatmentFilter[] {
  return [
    {
      treatmentType: {
        value: 0,
        label: 'treatment.surgery'
      },
      children: [
        {
          treatmentType: {
            value: 'surgicalInterventionStates',
            label: 'treatment.surgical_intervention'
          },
          treatmentStatuses: [
            formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED),
            formStatusToTreatmentStatusMap.get(FormStatus.IN_PROGRESS),
            formStatusToTreatmentStatusMap.get(FormStatus.REJECTED),
            formStatusToTreatmentStatusMap.get(FormStatus.EXCEEDS_QUOTA),
            formStatusToTreatmentStatusMap.get(FormStatus.ACCEPTED)
          ],
          children: surgicalInterventionTypes
        },
        {
          treatmentType: {
            value: 'surgeryFollowUpStates',
            label: 'treatment.surgery_follow_up'
          },
          treatmentStatuses: [
            formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED),
            formStatusToTreatmentStatusMap.get(FormStatus.IN_PROGRESS),
            formStatusToTreatmentStatusMap.get(FormStatus.REJECTED)
          ]
        },
        {
          treatmentType: {
            value: 'orthognaticTreatmentStates',
            label: 'treatment.orthognathic_treatment'
          },
          treatmentStatuses: [formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED)]
        },
        {
          treatmentType: {
            value: 'borescopeStates',
            label: 'treatment.borescope'
          },
          treatmentStatuses: [
            formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED),
            formStatusToTreatmentStatusMap.get(FormStatus.IN_PROGRESS),
            formStatusToTreatmentStatusMap.get(FormStatus.REJECTED)
          ]
        }
      ]
    },
    {
      treatmentType: {
        value: 1,
        label: 'treatment.speech'
      },
      children: [
        {
          treatmentType: {
            value: 'speechAssessmentStates',
            label: 'treatment.speech_assessment'
          },
          treatmentStatuses: [
            formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED),
            formStatusToTreatmentStatusMap.get(FormStatus.IN_PROGRESS),
            formStatusToTreatmentStatusMap.get(FormStatus.REJECTED)
          ]
        },
        {
          treatmentType: {
            value: 'speechTreatmentStates',
            label: 'treatment.speech_treatment'
          },
          treatmentStatuses: [
            formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED),
            formStatusToTreatmentStatusMap.get(FormStatus.IN_PROGRESS),
            formStatusToTreatmentStatusMap.get(FormStatus.REJECTED),
            formStatusToTreatmentStatusMap.get(FormStatus.DISCONTINUED)
          ]
        }
      ]
    },
    {
      treatmentType: {
        value: 3,
        label: 'treatment.nutrition'
      },
      children: [
        {
          treatmentType: {
            value: 'nutritionAssessmentStates',
            label: 'treatment.nutrition_management.assessment.shorter'
          },
          treatmentStatuses: [
            formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED),
            formStatusToTreatmentStatusMap.get(FormStatus.IN_PROGRESS),
            formStatusToTreatmentStatusMap.get(FormStatus.REJECTED)
          ]
        },
        {
          treatmentType: {
            value: 'nutritionFollowupStates',
            label: 'treatment.nutrition_management.follow_up.shorter'
          },
          treatmentStatuses: [
            formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED),
            formStatusToTreatmentStatusMap.get(FormStatus.IN_PROGRESS),
            formStatusToTreatmentStatusMap.get(FormStatus.REJECTED)
          ]
        }
      ]
    },
    {
      treatmentType: {
        value: 4,
        label: 'treatment.orthodontics'
      },
      children: getOrthodonticFilters()
    }
  ];
}
