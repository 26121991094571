import { PsioTreatment } from '@/src/app/features/ortho/models/psio-treatment.model';
import { OrthoTreatmentFormApi } from '@/src/app/features/ortho/shared/ortho-treatment-form-api';
import { psioTreatmentApiRoute } from '@/src/app/features/ortho/utils/ortho-routes.consts';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PsioTreatmentService extends OrthoTreatmentFormApi<PsioTreatment> {
  constructor(httpClient: HttpClient) {
    super(httpClient, psioTreatmentApiRoute);
  }
}
