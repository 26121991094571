import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { TreatmentDateType } from './treatment-record-date-type.enum';

@Component({
  selector: 'stx-treatment-record-date',
  templateUrl: './treatment-record-date.component.html',
  styleUrls: ['./treatment-record-date.component.scss']
})
export class TreatmentRecordDateComponent {
  @Input()
  recordDate: moment.Moment;
  private _dateType: TreatmentDateType;
  typeImage: string;

  @Input()
  set dateType(value: TreatmentDateType) {
    this._dateType = value;
    this.setImageType(value);
  }

  get dateType() {
    return this._dateType;
  }

  private setImageType(dateType: TreatmentDateType): void {
    if (TreatmentDateType.CREATED === dateType) {
      this.typeImage = 'assets/images/treatment-date/create_date.svg';
    } else if (TreatmentDateType.MODIFIED === dateType) {
      this.typeImage = 'assets/images/treatment-date/last_modified.svg';
    } else if (TreatmentDateType.SUBMITTED === dateType) {
      this.typeImage = 'assets/images/treatment-date/submission_date.svg';
    }
  }
}
