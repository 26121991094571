<ng-container *ngIf="!expandable">
  <stx-patient-forms-headers-container [formType]="formType"></stx-patient-forms-headers-container>
  <h1>
    <small>{{ 'mixed_dentition.presentation' | translate }}</small>
  </h1>
  <stx-ortho-treatment-guidelines-link *ngIf="!print"></stx-ortho-treatment-guidelines-link>
  <stx-treatment-status-bar
    *ngIf="!expandable"
    [treatment]="orthoFormModel"
    (unlockClicked)="unlock()"
    (deleteClicked)="delete()"
  ></stx-treatment-status-bar>
</ng-container>

<div class="page-section-container" *ngIf="isReadonlyView">
  <stx-ortho-readonly-assessment
    [treatment]="orthoFormModel"
    [orthoFormType]="OrthoFormType.MIXED_DENTITION"
  ></stx-ortho-readonly-assessment>
</div>

<ng-container *ngIf="!isReadonlyView">
  <form *ngIf="tcList && patient" [formGroup]="treatmentFormGroup">
    <stx-form-panel>
      <p class="form-section-heading">
        {{ 'ortho.general_patient_information' | translate }}
      </p>
      <stx-ortho-general-assessment-info
        #generalAssessmentInfo
        [tcList]="tcList"
        [orthoFormType]="OrthoFormType.MIXED_DENTITION"
        [orthodonticApplianceOptions]="mixedDentitionApplianceTypes"
        [orthodonticApplianceErrorNames]="['orthodonticTypesFields']"
        [orthodonticApplianceLabel]="'ortho.orthodontic_options'"
        [minCareProvidedDate]="minDentitionAssessmentDate"
        [maxCareProvidedDate]="maxDentitionAssessmentDate"
        [formMode]="formMode"
        [formGroup]="treatmentFormGroup"
      ></stx-ortho-general-assessment-info>
    </stx-form-panel>

    <stx-expandable-form-panel [expanded]="true">
      <ng-container slot="heading">
        {{ 'ortho.facial_photos' | translate }}
      </ng-container>
      <stx-image-group
        [formGroup]="treatmentFormGroup"
        [formMode]="formMode"
        [imageConfigs]="dentitionAssessmentFacialImagesConfigs"
        [print]="print"
      >
      </stx-image-group>
    </stx-expandable-form-panel>

    <div class="p-t-30 page-break"></div>

    <stx-expandable-form-panel [expanded]="true">
      <ng-container slot="heading">
        {{ 'ortho.intraoral_photos' | translate }}
      </ng-container>
      <stx-image-group
        [formGroup]="treatmentFormGroup"
        [formMode]="formMode"
        [imageConfigs]="dentitionAssessmentIntraOralImagesConfigs"
        [print]="print"
      >
      </stx-image-group>
    </stx-expandable-form-panel>

    <stx-expandable-form-panel [expanded]="true">
      <ng-container slot="heading">
        {{ 'ortho.xrays_photos' | translate }}
      </ng-container>
      <stx-image-group
        [formGroup]="treatmentFormGroup"
        [formMode]="formMode"
        [imageConfigs]="dentitionAssessmentXRayslImagesConfigs"
        [print]="print"
        columns="3"
      >
      </stx-image-group>
    </stx-expandable-form-panel>

    <stx-action-buttons
      *ngIf="!print"
      [asyncSecondaryAction]="onSaveButtonClicked()"
      [asyncPrimaryAction]="onSubmitButtonClicked()"
    ></stx-action-buttons>
  </form>
</ng-container>
