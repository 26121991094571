<stx-readonly-view-columns *ngIf="treatment">
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field label="surgery_follow_up.follow_up_date" [value]="treatment.dateFollow"></stx-simple-readonly-field>
    <stx-readonly-field label="treatment_center.treatment_center">
      <stx-tc-link [treatmentCenter]="treatment.treatmentCenter"></stx-tc-link>
    </stx-readonly-field>
    <stx-simple-readonly-field label="status" [value]="treatmentStatusLabel | translate"> </stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="treatment.telehealth_assessment"
      [value]="'yes' | if : treatment.telehealth | translate"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field label="age" [value]="treatment.ageMonth | ageYearMonth"></stx-simple-readonly-field>
    <stx-simple-readonly-field label="patient.weight" [value]="treatment.weightFv | unit : 'unit.weight'"></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_follow_up.improv_reason.height"
      [value]="treatment.heightFv | unit : 'unit.height'"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_follow_up.improv_reason.hemogl"
      [value]="treatment.hemoglobinFv | unit : 'unit.hemoglobin'"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition.muac"
      [value]="treatment.muacFv | unit : 'unit.height'"
      separator=" "
    ></stx-simple-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field label="nutrition_follow_up.improv" [value]="treatment.improvementFv | yesNo"></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_follow_up.improv_reason"
      [value]="[
        'nutrition.reason.weight' | if : treatment.improvReasonWeight | translate,
        'nutrition.reason.height' | if : treatment.improvReasonHeight | translate,
        'nutrition.reason.hemoglobin' | if : treatment.improvReasonHemogl | translate,
        'nutrition_follow_up.improv_reason.reported' | if : treatment.improvReasonReported | translate,
        treatment.improvReasonOther
      ]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition.improvement_how.view_label"
      [value]="[
        'nutrition.improvement.formula.view_label' | if : treatment.improvFormula | translate,
        'nutrition_follow_up.improv.activities.supplements' | if : treatment.improvSupplements | translate,
        'nutrition_follow_up.improv.activities.vitamins' | if : treatment.improvVitamins | translate,
        'nutrition_follow_up.improv.activities.breastfeeding' | if : treatment.improvBreastfeeding | translate,
        treatment.improvementOther
      ]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field label="nutrition_follow_up.no_improv" [value]="treatment.improvementNone"></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_follow_up.instructions"
      [value]="[treatment.benefitInstructions | yesNo, treatment.benefitInstructionsMore]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_follow_up.difficulties"
      [value]="[treatment.difficultInstruct | yesNo, treatment.difficultInstructions]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_follow_up.missing_instructions"
      [value]="[treatment.missingInstructions | yesNo, treatment.missingInstructionsMore]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_follow_up.diet"
      [value]="[
        'nutrition_screening.food.breastmilk' | if : treatment.eatingNowBreastmilk | translate,
        'nutrition_screening.food.expressed' | if : treatment.eatingNowExprmilk | translate,
        'nutrition_screening.food.cowmilk' | if : treatment.eatingNowCowmilk | translate,
        'nutrition_screening.food.formula' | if : treatment.eatingNowFormula | translate,
        'nutrition_screening.food.mashed' | if : treatment.eatingNowMacerated | translate,
        'nutrition_screening.food.solid' | if : treatment.eatingNowSolids | translate,
        treatment.eatingNowOther
      ]"
    ></stx-simple-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field
      label="treatment.surgery"
      [value]="[treatment.surgeryReady | yesNo, treatment.surgeryMore]"
    ></stx-simple-readonly-field>
  </ng-container>
</stx-readonly-view-columns>
<stx-readonly-media-group [mediaSets]="photos"></stx-readonly-media-group>
