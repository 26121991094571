import { Component, Input } from '@angular/core';
import { PatientTreatmentForReview } from '../../models/patient-treatment.model';

@Component({
  selector: 'stx-historic-treatments-details',
  templateUrl: './historic-treatments-details.component.html'
})
export class HistoricTreatmentsDetailsComponent {
  @Input() patientTreatmentsForReview: PatientTreatmentForReview[];
}
