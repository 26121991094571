import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { ReportConfig, ReportDownloadTypes } from '../../components/report-filters/models/report-filters.model';
import { ReportFiltersModule } from '../../components/report-filters/report-filters.component';
import { ReportTableDataService } from '../../components/report-filters/services/report-table-data.service';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import { SurgeryStatsReportTableComponentModule } from './components/surgery-stats-report-table/surgery-stats-report-table.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';

@Component({
  selector: 'stx-surgery-stats-report',
  templateUrl: './surgery-stats-report.component.html'
})
export class SurgeryStatsReportComponent {
  readonly reportConfig: ReportConfig = {
    type: 'SURGERY_STATS',
    downloadOptions: {
      [ReportDownloadTypes.EXCEL]: true
    },
    filters: {
      groupByScope: true,
      recordDate: true,
      age: true,
      geography: true,
      periodType: true,
      periodPresets: true,
      organizationsTree: true,
      treatmentStatus: true,
      exclusions: true,
      mohGrades: true
    }
  };

  constructor(public reportService: ReportService, private reportTableDataService: ReportTableDataService) {}

  applyFilters(pagination: PaginationSortingModel) {
    this.reportTableDataService.prepareList('SURGERY_STATS', this.reportService.model, pagination);
  }
}

@NgModule({
  declarations: [SurgeryStatsReportComponent],
  imports: [
    ReportFiltersModule,
    EmptyTableStateComponentModule,
    ReportHeaderComponentModule,
    CommonModule,
    SurgeryStatsReportTableComponentModule,
    TranslateModule,
    GeneralCommonsModule
  ],
  exports: [SurgeryStatsReportComponent]
})
export class SurgeryStatsReportComponentModule {}
