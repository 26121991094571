import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../base-component/base.component';
import { getLanguageByShortcut, languages } from '@utils/language.utils';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { mainDashboardFullRoute } from '@utils/routing.utils';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/modules/material/material.module';
import { TempLangService } from '@shared/services/localisation/temp-lang.service';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

@Component({
  selector: 'stx-language-temp',
  templateUrl: './language-temp.component.html',
  styleUrls: ['./language-temp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageTempComponent extends BaseComponent implements OnInit {
  activeTempLang: string;
  showTempLangSwitcher = true;

  constructor(
    public readonly translateService: TranslateService,
    private readonly authService: CurrentUserService,
    private readonly router: Router,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly tempLangService: TempLangService,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.watchUrlChanges();
    this.activeTempLang = getLanguageByShortcut(this.authService.getCurrentUser()?.tempLang)?.name;
    this.changeDetector.markForCheck();
    this.subSink.sink = this.wsHelperService.callToSubject(this.authService.getUserSubject()).subscribe(user => {
      if (user) {
        if (!user.tempLang) {
          this.activeTempLang = null;
          this.translateService.use(getLanguageByShortcut(user.lang).name);
        } else if (user.tempLang) {
          this.translateService.use(getLanguageByShortcut(user.tempLang).name);
        }
      }
    });
  }

  private watchUrlChanges() {
    this.subSink.sink = this.router.events
      .pipe(filter((e: Event) => e instanceof NavigationEnd))
      .subscribe((navigationEnd: NavigationEnd) => {
        this.showTempLangSwitcher = navigationEnd.url === mainDashboardFullRoute;
        this.changeDetector.markForCheck();
      });
  }

  switchLanguage(lang: string): void {
    let newTempLang = null;
    if (this.activeTempLang === lang) {
      this.activeTempLang = null;
    } else {
      this.activeTempLang = lang;
      newTempLang = languages.find(language => language.name === lang)?.shortcut;
    }

    this.authService.setCurrentUser({
      ...this.authService.getCurrentUser(),
      tempLang: newTempLang
    });
    this.tempLangService.setTempLanguage(newTempLang);
  }
}

@NgModule({
  declarations: [LanguageTempComponent],
  imports: [CommonModule, MaterialModule, TranslateModule],
  exports: [LanguageTempComponent]
})
export class LanguageTempComponentModule {}
