import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../../../../../modal/modal.component';
import { DialogMode } from '../../treatment-status-bar.utils';

@Component({
  selector: 'stx-treatment-modal',
  templateUrl: './treatment-modal.component.html'
})
export class TreatmentModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ModalComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  dialogHeader: string;
  dialogConfirmation: string;

  ngOnInit() {
    switch (this.data.mode) {
      case DialogMode.UNLOCK:
        this.dialogHeader = 'unlock.header';
        this.dialogConfirmation = 'unlock.confirmation';
        break;
      case DialogMode.DELETE_TREATMENT:
        this.dialogHeader = 'delete.header';
        this.dialogConfirmation = 'delete.confirmation';
        break;
      case DialogMode.DELETE_PATIENT:
        this.dialogHeader = 'delete.patient.header';
        this.dialogConfirmation = 'delete.patient.confirmation';
        break;
      case DialogMode.CLONE:
        this.dialogHeader = 'clone.header';
        this.dialogConfirmation = 'clone.confirmation';
        break;
      case DialogMode.UNLINK:
        this.dialogHeader = 'unlink';
        this.dialogConfirmation = 'unlink.confirmation';
    }
  }

  confirm(): void {
    this.dialogRef.close({ confirm: true });
  }

  back(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  mode: DialogMode;
}
