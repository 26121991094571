import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { FormStatus } from '@/src/app/shared/enums/form-status.enum';
import { TreatmentStatus } from '@/src/app/shared/models/form.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TreatmentFilterFlatNode } from '../../../models/treatment-filter.model';

@Component({
  selector: 'stx-treatment-status-filter',
  templateUrl: './treatment-status-filter.component.html',
  styleUrls: ['./treatment-status-filter.component.scss']
})
export class TreatmentStatusFilterComponent extends BaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() statuses: TreatmentStatus[];
  @Input() node: TreatmentFilterFlatNode;
  @Output() statusChange = new EventEmitter<FormStatus[]>();

  statusSelection = new SelectionModel<FormStatus>(true);

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl
  ) {
    super();

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.ngControl.control.enable({ onlySelf: true, emitEvent: false });

    this.subSink.sink = this.statusSelection.changed.subscribe(() => {
      this.propagateChange(this.statusSelection.selected);
      this.statusChange.emit(this.statusSelection.selected);
    });
  }

  ngOnDestroy(): void {
    // ngIf workaround
    if (this.ngControl?.control) {
      this.ngControl.control.disable({ onlySelf: true, emitEvent: false });
      this.ngControl.control.setValue('');
      this.ngControl.control.markAsPristine();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  writeValue(values: FormStatus[]): void {
    if (values) {
      this.statusSelection.select(...values);
    } else {
      if (this.statusSelection.selected?.length > 0) {
        this.statusSelection.clear();
      }
    }
  }

  onTouched: any = () => {
    /* part of ControlValueAccessor default impl */
  };

  private propagateChange = (value: any) => {
    /* part of ControlValueAccessor default impl */
  };
}
