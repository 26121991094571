import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoticeType } from './notice.model';

@Component({
  selector: 'stx-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent {
  @Input() noticeType: NoticeType = 'info';
}

@NgModule({
  declarations: [NoticeComponent],
  exports: [NoticeComponent],
  imports: [CommonModule]
})
export class NoticeComponentModule {}
