import { MatchType } from '@/src/app/features/patient/patient.enum';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { SuspiciousRecordProblemType } from '@/src/app/features/suspicious-records/enums/problem-type.enum';
import { SuspiciousRecordReviewActionType } from '@/src/app/features/suspicious-records/enums/suspicious-records.enum';
import { TREATMENT_STATE_ERROR } from '@/src/app/features/suspicious-records/suspicious-records.util';
import { SpinnerService } from '@/src/app/shared/components/spinner/service/spinner.service';
import { GlobalErrorHandlerService } from '@/src/app/shared/services/global-error-handler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Injectable, signal, WritableSignal } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { BaseHttpService } from '@shared/services/http/base-http.service';
import { HttpUtils } from '@utils/http.utils';
import { rejectionReasonsRoute, suspiciousRecords, suspiciousRecordToReviewRoute } from '@utils/routing.utils';
import { DictionaryTranslation, patientIdAsNumber } from '../patient/models/patient.model';
import { SuspiciousRecordReviewResult } from './models/suspicious-record-review-result.model';
import { SuspiciousRecord, SuspiciousRecordDialog } from './models/suspicious-record.model';
import { ProblematicRecord, SuspiciousRecordAndFrauds } from './models/suspicious-records-and-frauds.model';
import { SuspiciousRecordsSearch } from './models/suspicious-records-search.model';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Injectable({
  providedIn: 'root'
})
export class SuspiciousRecordsService extends BaseHttpService {
  searchFilters: WritableSignal<SuspiciousRecordsSearch> = signal(null);

  //TODO Error handling in this service could be unified once #17816 is implemented
  constructor(readonly httpClient: HttpClient, readonly patientService: PatientService) {
    super(httpClient, { baseUrl: suspiciousRecords });
  }

  getSuspiciousRecord(searchFilters: SuspiciousRecordsSearch): Observable<SuspiciousRecordAndFrauds> {
    const params: HttpParams = HttpUtils.convertToSearchParams(searchFilters);
    return this.httpClient.get<SuspiciousRecordAndFrauds>(`api/${suspiciousRecordToReviewRoute}`, { params });
  }

  getSuspiciousRecordByTreatmentId(treatmentId: TreatmentId): Observable<SuspiciousRecordAndFrauds> {
    return this.httpClient.get<SuspiciousRecordAndFrauds>(`api/${suspiciousRecordToReviewRoute}/${treatmentId}`, {});
  }

  getPreviousProblematicRecords(recordId: TreatmentId): Observable<ProblematicRecord[]> {
    return this.httpClient.get<ProblematicRecord[]>(`${this.baseUrl}/${recordId}/problematic`);
  }

  getProblemTypes(): Observable<DictionaryTranslation[]> {
    return this.httpClient.get<DictionaryTranslation[]>(`api/${rejectionReasonsRoute}`);
  }

  resolveSuspiciousRecord(record: SuspiciousRecordReviewResult): Observable<SuspiciousRecord> {
    return this.httpClient.post<SuspiciousRecord>(`${this.baseUrl}`, record);
  }

  runSecondarySynchronousSuspiciousRecordReviewFor(recordId: TreatmentId): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/${recordId}`, {});
  }

  skipRecord(
    recordId: TreatmentId,
    spinnerService: SpinnerService,
    globalErrorHandler: GlobalErrorHandlerService,
    cd: ChangeDetectorRef
  ): Observable<SuspiciousRecord> {
    spinnerService.addTask();
    const skipResult: SuspiciousRecordReviewResult = {
      actionType: SuspiciousRecordReviewActionType.SKIP,
      treatmentId: recordId,
      status: SuspiciousRecordProblemType.SKIPPED
    };
    return this.resolveSuspiciousRecordAndNavigateToNext(skipResult, spinnerService, cd, globalErrorHandler);
  }

  saveSuspiciousRecordReviewResult(
    data: SuspiciousRecordDialog,
    suspiciousRecordReviewResult: SuspiciousRecordReviewResult,
    spinnerService: SpinnerService,
    globalErrorHandler: GlobalErrorHandlerService,
    cd: ChangeDetectorRef
  ): Observable<SuspiciousRecord> {
    const matchPatientsObservables$: Observable<void>[] = this.matchDupPatientsIfAnyExist(data).concat(
      this.matchNotDupPatientsIfAnyExist(data)
    );

    spinnerService.addTask();
    if (matchPatientsObservables$.length > 0) {
      return forkJoin(matchPatientsObservables$).pipe(
        mergeMap(_ => {
          return this.resolveSuspiciousRecordAndNavigateToNext(suspiciousRecordReviewResult, spinnerService, cd, globalErrorHandler);
        })
      );
    } else {
      return this.resolveSuspiciousRecordAndNavigateToNext(suspiciousRecordReviewResult, spinnerService, cd, globalErrorHandler);
    }
  }

  private resolveSuspiciousRecordAndNavigateToNext(
    suspiciousRecordReviewResult: SuspiciousRecordReviewResult,
    spinnerService: SpinnerService,
    cd: ChangeDetectorRef,
    globalErrorHandler: GlobalErrorHandlerService
  ): Observable<SuspiciousRecord> {
    return this.resolveSuspiciousRecord(suspiciousRecordReviewResult).pipe(
      tap({
        next: () => {
          spinnerService.removeTask();
          cd.detectChanges();
        },
        error: err => {
          spinnerService.removeTask();
          this.navigateOnTreatmentStateErrorAndInformUser(err, globalErrorHandler);
        }
      })
    );
  }

  refreshSuspiciousRecord(
    treatmentId: TreatmentId,
    spinnerService: SpinnerService,
    globalErrorHandler: GlobalErrorHandlerService,
    cd: ChangeDetectorRef
  ): Observable<SuspiciousRecordAndFrauds> {
    spinnerService.addTask();
    return this.runSecondarySynchronousSuspiciousRecordReviewFor(treatmentId).pipe(
      switchMap(() => this.getSuspiciousRecordByTreatmentId(treatmentId)),
      tap({
        next: () => {
          spinnerService.removeTask();
          cd.detectChanges();
        },
        error: err => {
          spinnerService.removeTask();
          this.navigateOnTreatmentStateErrorAndInformUser(err, globalErrorHandler);
        }
      })
    );
  }

  private matchDupPatientsIfAnyExist(data: SuspiciousRecordDialog): Observable<void>[] {
    return Array.from(data.dupPatientList).map(duplicatedPatientRecord =>
      this.patientService.matchPatients({
        patientId1: patientIdAsNumber(data.patientId),
        patientId2: duplicatedPatientRecord,
        matchType: MatchType.SAME
      })
    );
  }

  private matchNotDupPatientsIfAnyExist(data: SuspiciousRecordDialog): Observable<void>[] {
    return Array.from(data.notDupPatientList).map(notDuplicatedPatientRecord =>
      this.patientService.matchPatients({
        patientId1: patientIdAsNumber(data.patientId),
        patientId2: notDuplicatedPatientRecord,
        matchType: MatchType.NOT_SAME
      })
    );
  }

  navigateOnTreatmentStateErrorAndInformUser(err: any, globalErrorHandler: GlobalErrorHandlerService, silent = false): void {
    if (err.status && err.status === TREATMENT_STATE_ERROR) {
      if (!silent) {
        let errorData = {
          title: 'suspicious_records.error.illegal_treatment_state.title',
          message: 'suspicious_records.error.illegal_treatment_state.message'
        };

        if (err.error === 'Suspicious Record is not eligible for Suspicious Review') {
          errorData = {
            title: 'suspicious_records.error.illegal_suspicious_record_state.title',
            message: 'suspicious_records.error.illegal_suspicious_record_state.message'
          };
        }
        globalErrorHandler.handleErrorAndInformUser(err, { redirectOn404StatusCode: false }, errorData);
      }
    } else {
      globalErrorHandler.handleErrorAndInformUser(err);
    }
  }
}
