<div class="ortho-compact-pair-photo-container gap-50-grid">
  <div>
    <div class="fs-16 m-b-25 heading" *ngIf="showHeaders">
      {{ 'ortho.assessment' | translate }}
    </div>
    <div class="flex-column-container" *ngFor="let preSurgeryImageUrl of preTreatmentImagesUrl; index as i">
      <stx-image-thumbnail
        [mediaAnonymized]="prePhotoAnonymized"
        [thumbnailId]="'pre-pair-' + preTreatmentIds[i]"
        imageAlt="{{ label }}"
        [imageUrl]="preSurgeryImageUrl"
        [mediaId]="preTreatmentIds[i]"
      >
        {{ fullImageLabel }}
      </stx-image-thumbnail>
    </div>
  </div>
  <div *ngIf="postTreatmentImagesUrl.length">
    <div class="fs-16 m-b-25 heading" *ngIf="showHeaders">
      {{ 'ortho.treatment' | translate }}
    </div>
    <div class="flex-column-container" *ngFor="let postSurgeryImageUrl of postTreatmentImagesUrl; index as i">
      <stx-image-thumbnail
        [thumbnailId]="'post-pair-' + preTreatmentIds[i]"
        imageAlt="{{ label }}"
        [imageUrl]="postSurgeryImageUrl"
        [mediaId]="postTreatmentIds[i]"
        [mediaAnonymized]="postPhotoAnonymized"
      >
        {{ fullImageLabel }}
      </stx-image-thumbnail>
    </div>
  </div>
</div>
