import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PaginationSortingModel } from './models/pagination-sorting.model';
import { TableColumn, TableRow, TableStructure } from './models/table-structure.model';

/**
 * @deprecated use stx-data-table instead, unless custom column definition is required (stx-data-table doesn't support sorting for those yet)
 */
@Component({
  selector: 'stx-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
  @Input() data: TableStructure;
  @Input() pageSizeOptions: number[] = [25, 100];
  @Input() topPagination = true;
  @Output() sortingPagination = new EventEmitter<PaginationSortingModel>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<TableRow>;
  columns: TableColumn[];
  displayedColumns: string[];
  totalResults: number;

  ngOnInit() {
    this.columns = this.data.labels;
    this.displayedColumns = this.data.labels.map(a => a.name);
    this.dataSource = new MatTableDataSource(this.data.data.page.content);
    this.dataSource.sort = this.sort;
    this.totalResults = this.data.data.page.totalElements;
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.data.data.page.content);
    this.paginator.pageIndex = this.data.data.pageNumber;
    this.totalResults = this.data.data.page.totalElements;
  }

  changePage(event: PageEvent): void {
    const currentPageSorting: PaginationSortingModel = {
      pageNumber: event.pageIndex,
      pageSize: event.pageSize,
      sortedBy: this.data.data.sortProperty ? this.data.data.sortProperty : '',
      sortDirection: this.data.data.sortDirection ? this.data.data.sortDirection : ''
    };
    this.sortingPagination.emit(currentPageSorting);
  }

  sortData(event: Sort): void {
    const currentPageSorting: PaginationSortingModel = {
      pageNumber: this.data.data.pageNumber,
      pageSize: this.data.data.pageSize,
      sortedBy: event.active,
      sortDirection: event.direction
    };
    this.sortingPagination.emit(currentPageSorting);
  }
}
