import { Component, Input } from '@angular/core';
import { DiagnosisForReview } from '../../models/diagnosis.model';

@Component({
  selector: 'stx-historic-diagnoses-details',
  templateUrl: './historic-diagnoses-details.component.html'
})
export class HistoricDiagnosesDetailsComponent {
  @Input() diagnoses: DiagnosisForReview[];
}
