import { openImageViewer } from '@/src/app/features/image-viewer/image-viewer.utils';
import { MediaUrl } from '@/src/app/features/media/models/media-url.model';
import { MediaType } from '@/src/app/shared/enums/media.enum';
import { optionalPlaceholderImage, requiredPlaceholderImage } from '@/src/app/utils/media.utils';
import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { LoggerService } from '@shared/services/logger.service';
import { noop } from 'rxjs';
import { FileInfo } from 'src/app/features/media/models/media.model';
import { Mime } from 'src/app/utils/file.utils';
import { MediaComponentBaseComponent } from '../media-component-base/media-component-base.component';
import { UppyErrorService } from '@shared/components/uppy/uppy-error.service';

/**
 * This component is transitioning between template based form solution (deprecated properties) and reactive solution.
 * Please from now on use ImageComponent wrapper instead of this directly.
 */
@Component({
  selector: 'stx-uppy-with-preview',
  templateUrl: './uppy-with-preview.component.html',
  styleUrls: ['./uppy-with-preview.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UppyWithPreviewComponent),
      multi: true
    }
  ]
})
export class UppyWithPreviewComponent extends MediaComponentBaseComponent implements OnChanges, OnDestroy, ControlValueAccessor {
  filename: string;
  lowResUrl: string;
  highResUrl: string;
  placeholderShown: boolean;
  private mediaUrlId: string | null;

  @Input()
  parentOrderName: ParentOrderName;
  @Input() isRequired: boolean;
  @Input() isReadonly: boolean;
  @Input() print: boolean;
  @Input() label: string;

  @ViewChild('previewImage', { static: false }) previewImage: ElementRef<HTMLImageElement>;
  @ViewChild('previewImageContainer', { static: false }) previewImageContainer: ElementRef<HTMLElement>;

  imageAcceptedTypes = [Mime.GIF, Mime.JPEG, Mime.JPG, Mime.PNG];
  maxPictureSize10MbInBytes = 10_485_760;

  onTouchedFn: () => void = noop;
  onChangedFn: (_: FileInfo) => void = (_: FileInfo) => {
    /* This is part of ControlValueAccessor base impl */
  };

  constructor(
    public domSanitizer: DomSanitizer,
    loggerService: LoggerService,
    globalErrorHandlerService: GlobalErrorHandlerService,
    private cd: ChangeDetectorRef,
    protected uppyErrorService: UppyErrorService
  ) {
    super(loggerService, globalErrorHandlerService, uppyErrorService);
  }

  ngOnChanges(): void {
    this.showImage();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  get rawImageUrl(): string {
    return this.rawUrl;
  }

  get highResOrConverted(): string {
    return this.highResUrl ?? this.convertedImageUrl ?? this.rawImageUrl;
  }

  get convertedImageUrl(): string {
    return this.convertedUrl;
  }

  registerOnChange(fn: any): void {
    this.onChangedFn = fn;
  }

  writeValue(singleControlRawAndConvertedUrls: any): void {
    this.filename = null;
    this.setNewValue(singleControlRawAndConvertedUrls);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  uploadedFile(uploadedFileData: FileInfo) {
    this.filename = uploadedFileData.fileName;
    this.revokeUrlIfExists(this.previousUploadedFileUrl);
    this.previousUploadedFileUrl = this.uploadedFileUrl;
    this.uppyErrorService.resetBy(this.uuid);
    this.onChangedFn({ ...uploadedFileData, parentOrderName: this.parentOrderName });
  }

  showImage(): string | SafeUrl {
    if (this.uppyErrorService.isUploadedFileEmptyBy(this.uuid)) {
      this.placeholderShown = true;
      return this.getPlaceholderImage();
    }

    this.placeholderShown = false;
    if (this.uploadedFileUrl) {
      return this.domSanitizer.bypassSecurityTrustUrl(this.uploadedFileUrl);
    }
    if (this.lowResUrl) {
      return this.lowResUrl;
    }
    if (this.rawUrl) {
      return this.convertedUrl ? this.convertedUrl : this.rawUrl;
    }
    this.placeholderShown = true;
    return this.getPlaceholderImage();
  }

  private getPlaceholderImage(): string {
    return this.isRequired ? requiredPlaceholderImage : optionalPlaceholderImage;
  }

  touched() {
    this.onTouchedFn();
  }

  protected setApiData(existingMedia: MediaUrl[]) {
    super.setApiData(existingMedia);
    if (!existingMedia) {
      this.lowResUrl = null;
      this.highResUrl = null;
      this.mediaUrlId = null;
    }

    this.mediaUrlId = existingMedia[0]?.id;

    this.lowResUrl = existingMedia.find(mediaUrl => mediaUrl.urlType === MediaType.LOW_RES)?.url;

    this.highResUrl = existingMedia.find(mediaUrl => mediaUrl.urlType === MediaType.HIGH_RES)?.url;
  }

  openImageViewer(): void {
    // TODO: #9036
    if (!this.uploadedFileUrl) {
      openImageViewer(this.mediaUrlId);
    }
  }

  restoreOldPreview(): void {
    super.restoreOldPreview();
    this.cd.detectChanges();
  }
}
