import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataPageWithNames } from '@shared/modules/general-commons/components/data-table/data-table.model';
import { StatisticsPlot, StatisticsTable } from '@shared/modules/statistics/statistics.model';
import { Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { PaginationSortingModel } from 'src/app/shared/components/table/models/pagination-sorting.model';
import { BaseHttpService } from 'src/app/shared/services/http/base-http.service';
import { HttpUtils, shareReplayBufferSize } from 'src/app/utils/http.utils';
import { PaperRecords } from '../reports/models/paper-records.model';
import { PartnerDetailsModel } from './models/partner-details.model';
import { PartnerDictionary } from './models/partner-dictionary-model';
import { PartnerSearchModel } from './models/partner-search.model';

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends BaseHttpService {
  private refreshPartners$ = new Subject<void>();
  private partners$: Observable<Array<PartnerDictionary>>;

  constructor(httpClient: HttpClient) {
    super(httpClient, { baseUrl: 'orgtree' });
  }

  getPartners(pageSort?: PaginationSortingModel, searchFilters?: PartnerSearchModel): Observable<DataPageWithNames<any>> {
    if (searchFilters || pageSort) {
      const params = HttpUtils.convertToSearchParams(searchFilters, pageSort);
      return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}/partners`, { params });
    }
    return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}/partners`);
  }

  getPartnerDetails(partnerId: number): Observable<PartnerDetailsModel> {
    return this.httpClient.get<PartnerDetailsModel>(`${this.baseUrl}/partners/${partnerId}`);
  }

  getPartnerTreatmentCenters(partnerId: number, pageSort?: PaginationSortingModel): Observable<DataPageWithNames<any>> {
    if (pageSort) {
      const params = HttpUtils.pageSortToParams(pageSort);
      return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}/partners/${partnerId}/treatment-centers`, { params });
    }
    return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}/partners/${partnerId}/treatment-centers`);
  }

  getPartnerUsers(partnerId: number, pageSort?: PaginationSortingModel): Observable<DataPageWithNames<any>> {
    if (pageSort) {
      const params = HttpUtils.pageSortToParams(pageSort);
      return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}/partners/${partnerId}/users`, { params });
    }
    return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}/partners/${partnerId}/users`);
  }

  getUserPartners(): Observable<PartnerDictionary[]> {
    if (!this.partners$) {
      this.partners$ = this.httpClient
        .get<PartnerDictionary[]>(`${this.baseUrl}/user-partners`)
        .pipe(takeUntil(this.refreshPartners$), shareReplay(shareReplayBufferSize));
    }
    return this.partners$;
  }

  clearUserPartnersCache() {
    this.refreshPartners$.next();
    this.partners$ = null;
  }

  getPartnersCsvFile(searchFilters?: PartnerSearchModel): Observable<Blob> {
    const headers = new HttpHeaders().set('Accept', 'text/csv');
    if (searchFilters) {
      const params = HttpUtils.convertToSearchParams(searchFilters);
      return this.httpClient.get(`${this.baseUrl}/partners`, { responseType: 'blob', headers, params });
    }
    return this.httpClient.get(`${this.baseUrl}/partners`, { responseType: 'blob', headers });
  }

  getPartnersTreatmentCentersCsvFile(partnerId: number): Observable<Blob> {
    const headers = new HttpHeaders().set('Accept', 'text/csv');
    return this.httpClient.get(`${this.baseUrl}/partners/${partnerId}/treatment-centers`, {
      responseType: 'blob',
      headers
    });
  }

  getPaperRecords(): Observable<PaperRecords> {
    return this.httpClient.get<PaperRecords>(`${this.baseUrl}/paper-records`);
  }

  getPartnerStatistics(partnerId: number): Observable<StatisticsTable> {
    return this.httpClient.get<StatisticsTable>(`${this.baseUrl}/partners/${partnerId}/statistics/table`);
  }

  getPartnerStatisticsPlot(partnerId: number): Observable<StatisticsPlot> {
    return this.httpClient.get<StatisticsPlot>(`${this.baseUrl}/partners/${partnerId}/statistics/plot`);
  }

  public checkIfCurrentUserPartnerHasAffiliation(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.baseUrl}/partners/hasAffiliation`);
  }
}
