<div [ngClass]="{ 'p-t-30 page-break-inside-avoid': print }">
  <div>
    <p class="form-section-heading">
      {{
        'nutrition_management.nutrition_care_plan' | translate | sectionNumber : nutritionFormType : NutritionSection.NUTRITION_CARE_PLAN
      }}
    </p>
  </div>
  <div>
    <div class="form-row">
      <stx-checkbox-group-with-text-field
        [layoutDirection]="'column'"
        [options]="nutritionCarePlanOptions"
        [formGroup]="formGroup"
        [formMode]="formMode"
        [print]="print"
        [formGroupErrorNames]="['nutritionCarePlanFields']"
        [deselectTriggerControlName]="'none'"
        [textFieldParentControlName]="'other'"
        [textFieldControlName]="'otherMore'"
      ></stx-checkbox-group-with-text-field>
    </div>
  </div>
</div>
