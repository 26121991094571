import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormThemes } from '../../form.model';

export type InputControlType = 'text' | 'password' | 'number';

/**
 * @deprecated Use InputComponent
 */
@Component({
  selector: 'stx-input-base',
  templateUrl: './input-base.component.html',
  styleUrls: ['./input-base.component.scss']
})
export class InputBaseComponent {
  @Input() inputDisabled = false;
  @Input() inputId: string;
  @Input() inputPlaceholder = '';
  @Input() value = '';
  @Input() inputType?: InputControlType = 'text';
  @Input() theme: FormThemes = 'primary';

  @Output() blurEvent: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() pasteEvent: EventEmitter<ClipboardEvent> = new EventEmitter<ClipboardEvent>();
  @Output() inputEvent: EventEmitter<Event> = new EventEmitter<Event>();
}

@NgModule({
  imports: [CommonModule, MatInputModule, MatFormFieldModule, MatButtonModule, MatIconModule],
  declarations: [InputBaseComponent],
  exports: [InputBaseComponent]
})
export class InputBaseComponentModule {}
