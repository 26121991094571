import { showStandardError } from '@/src/app/shared/validation/error.utils';
import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { noop } from 'rxjs';
import { MediaUrl } from 'src/app/features/media/models/media-url.model';
import { FileInfo } from 'src/app/features/media/models/media.model';
import { MediaContentType } from 'src/app/shared/enums/media.enum';
import { ParentOrderName } from 'src/app/shared/enums/parent-order-name.enum';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { Mime } from 'src/app/utils/file.utils';
import { MediaComponentBaseComponent } from '../../../uppy/components/media-component-base/media-component-base.component';
import { updateValueAndValidityForControl } from '@shared/validation/validation.utils';
import { UppyErrorService } from '@shared/components/uppy/uppy-error.service';

@Component({
  selector: 'stx-file',
  templateUrl: './file.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileComponent),
      multi: true
    }
  ]
})
export class FileComponent extends MediaComponentBaseComponent implements ControlValueAccessor, OnInit {
  readonly showStandardError = showStandardError;
  @Input() label: string;
  @Input() acceptedTypes: Mime[];
  @Input() parentOrderName: ParentOrderName;
  @Input() isRequired: boolean;
  @Input() customErrorMessage: string;
  @Input() formGroup: UntypedFormGroup;

  /* TODO #36489 - this is temporary solution as we do not have a way to handle file deletion on backend.
  TODO #36489 The task will be fully completed in scope of ticket 36489. Right now deletion is only available for
  TODO #36489 new forms - for editing we do not have remove icon.
   */
  @Input() shouldDisplayRemoveIcon: boolean;

  uploadedFileName: string;
  mediaType: MediaContentType;

  showEmptyFileError: boolean = false;

  onTouchedFn: () => void = noop;
  onChangedFn: (_: FileInfo) => void = (_: FileInfo) => {
    /* This is part of ControlValueAccessor base impl */
  };

  get getFileName(): string {
    if (!!this.uploadedFileName) {
      return this.uploadedFileName;
    }

    return this.formGroup?.get(this.parentOrderName)?.value[0]?.createdAt + '.png';
  }

  constructor(
    loggerService: LoggerService,
    globalErrorHandlerService: GlobalErrorHandlerService,
    uppyErrorService: UppyErrorService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(loggerService, globalErrorHandlerService, uppyErrorService);
  }

  ngOnInit(): void {
    this.watchUppyCustomErrors();
  }

  watchUppyCustomErrors(): void {
    this.subSink.sink = this.uppyErrorService.fileIsEmpty$.subscribe(() => {
      this.showEmptyFileError = this.uppyErrorService.isUploadedFileEmptyBy(this.uuid);

      if (this.showEmptyFileError) {
        this.formGroup.get(this.parentOrderName).reset();
        this.formGroup.get(this.parentOrderName).updateValueAndValidity();
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  registerOnChange(fn: any): void {
    this.onChangedFn = fn;
  }

  writeValue(urls?: MediaUrl[]): void {
    this.uploadedFileName = null;
    this.mediaType = urls && urls.length > 0 ? urls[0].mediaType : null;
    this.setNewValue(urls);
  }
  setDisabledState(isDisabled: boolean): void {
    /* This is part of ControlValueAccessor base impl */
  }

  uploadedFile(fileInfo: FileInfo) {
    this.uppyErrorService.resetBy(this.uuid);
    this.uploadedFileName = fileInfo.fileName;
    this.onChangedFn({ ...fileInfo, parentOrderName: this.parentOrderName });
  }

  touched() {
    this.onTouchedFn();
  }

  resetFileControl() {
    this.formGroup.get(this.parentOrderName).setValue(null);
    updateValueAndValidityForControl(this.formGroup.get(this.parentOrderName));
  }

  get showUploadError(): boolean {
    return this.formGroup && showStandardError(this.formGroup, this.parentOrderName);
  }
}
