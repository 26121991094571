import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'stx-chevron-line',
  templateUrl: './chevron-line.component.html',
  styleUrls: ['./chevron-line.component.scss']
})
export class ChevronLineComponent {
  @Input() isExpanded: boolean;
  @Input() showChevron = true;
  @Output() toggle = new EventEmitter<void>();
}

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule],
  declarations: [ChevronLineComponent],
  exports: [ChevronLineComponent]
})
export class ChevronLineComponentModule {}
