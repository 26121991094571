import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component, Input } from '@angular/core';
import moment from 'moment';
import { assessmentInstrumentalSectionErrorName, assessmentStagePreVpdValue } from '../utils/speech-assessment.utils';
import { nasometryInputConfiguration, nasopharyngoscopyConfig, videofluoroscopyConfig } from './instrumental-assessment.util';

@Component({
  selector: 'stx-instrumental-assessment',
  templateUrl: './instrumental-assessment.component.html',
  styleUrls: ['./instrumental-assessment.component.scss']
})
export class InstrumentalAssessmentComponent extends BaseFormElementComponent {
  readonly videofluoroscopyConfig = videofluoroscopyConfig;
  readonly nasopharyngoscopyConfig = nasopharyngoscopyConfig;
  readonly assessmentInstrumentalSectionErrorName = assessmentInstrumentalSectionErrorName;
  readonly nasometryInputConfiguration = nasometryInputConfiguration;

  @Input() birthDate: moment.Moment;

  constructor() {
    super();
  }

  get isInstrumentalAssessmentSectionExpanded(): boolean {
    const currentSectionVpdControlValues = [assessmentStagePreVpdValue];
    const assessmentStageControl = this.formGroup.get('assessmentStage');
    const isVpdSectionSelected = !!assessmentStageControl?.value && currentSectionVpdControlValues.includes(assessmentStageControl.value);

    return isVpdSectionSelected || this.print;
  }
}
