import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { settingsRoute } from '@utils/routing.utils';
import { ModalComponent } from '@shared/components/modal/modal.component';

@Component({
  selector: 'stx-password-expiry-modal',
  templateUrl: './password-expiry-modal.component.html',
  styleUrls: ['./password-expiry-modal.component.scss']
})
export class PasswordExpiryModalComponent {
  private changePasswordNow: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {}, private router: Router, public dialogRef: MatDialogRef<ModalComponent>) {}

  navigateToChangePassword(): void {
    this.changePasswordNow = true;
    this.closePasswordExpiryPopup();
  }

  closePasswordExpiryPopup(): void {
    if (this.changePasswordNow) {
      this.router.navigate([`${settingsRoute}`]);
    }
    this.dialogRef.close();
  }
}
