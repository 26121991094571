<ng-container *ngIf="isReadonlyView">
  <stx-patient-forms-headers-container
    *ngIf="!expandable"
    [formType]="formType"
    cssClass="m-l-25 m-b-25"
  ></stx-patient-forms-headers-container>

  <stx-treatment-status-bar
    *ngIf="!expandable"
    [treatment]="borescope"
    (unlockClicked)="unlock()"
    (deleteClicked)="delete()"
  ></stx-treatment-status-bar>

  <div class="page-section-container"><stx-borescope-readonly [treatment]="borescope"></stx-borescope-readonly></div>
</ng-container>
<ng-container *ngIf="!isReadonlyView">
  <stx-patient-forms-headers-container *ngIf="!expandable" [formType]="formType"></stx-patient-forms-headers-container>
  <stx-treatment-status-bar
    *ngIf="!expandable"
    [treatment]="borescope"
    (unlockClicked)="unlock()"
    (deleteClicked)="delete()"
  ></stx-treatment-status-bar>
  <form [formGroup]="borescopeFormGroup">
    <stx-form-panel>
      <div class="form-row">
        <stx-textarea
          [label]="'borescope.notes'"
          [controlName]="'notes'"
          [formGroup]="borescopeFormGroup"
          [formMode]="formMode"
        ></stx-textarea>
      </div>
      <h3>{{ 'photos' | translate }}</h3>
      <div class="form-row">
        <stx-image
          label="borescope.identification"
          [formMode]="formMode"
          [parentOrderName]="ParentOrderName.BORESCOPE_IDENTIFICATION"
          [controlName]="ParentOrderName.BORESCOPE_IDENTIFICATION"
          [formGroup]="borescopeFormGroup"
          [isRequiredAlways]="true"
          [print]="print"
        ></stx-image>
        <stx-image
          label="borescope.unrepaired"
          [formMode]="formMode"
          [parentOrderName]="ParentOrderName.BORESCOPE_UNREPAIRED"
          [controlName]="ParentOrderName.BORESCOPE_UNREPAIRED"
          [formGroup]="borescopeFormGroup"
          [isRequiredAlways]="true"
          [print]="print"
        ></stx-image>
        <stx-image
          label="borescope.repaired"
          [formMode]="formMode"
          [parentOrderName]="ParentOrderName.BORESCOPE_REPAIRED"
          [controlName]="ParentOrderName.BORESCOPE_REPAIRED"
          [formGroup]="borescopeFormGroup"
          [isRequiredAlways]="true"
          [print]="print"
        ></stx-image>
      </div>
    </stx-form-panel>
    <stx-action-buttons *ngIf="!print" [showPrimary]="false" [asyncSecondaryAction]="onSaveButtonClicked()"></stx-action-buttons>
    <stx-form-panel>
      <h3>{{ 'video' | translate }}</h3>
      <div class="form-row">
        <stx-audio-video
          label="borescope.nasal_lining"
          [formGroup]="borescopeFormGroup"
          [formMode]="formMode"
          [parentOrderName]="ParentOrderName.BORESCOPE_NASAL_LINING"
          [controlName]="ParentOrderName.BORESCOPE_NASAL_LINING"
          [print]="print"
        ></stx-audio-video>
        <stx-audio-video
          label="borescope.levator_transposition"
          [formGroup]="borescopeFormGroup"
          [formMode]="formMode"
          [parentOrderName]="ParentOrderName.BORESCOPE_VIDEOS"
          [controlName]="ParentOrderName.BORESCOPE_VIDEOS"
          [print]="print"
        ></stx-audio-video>
        <stx-audio-video
          label="borescope.oral_lining"
          [formGroup]="borescopeFormGroup"
          [formMode]="formMode"
          [parentOrderName]="ParentOrderName.BORESCOPE_ORAL_LINING"
          [controlName]="ParentOrderName.BORESCOPE_ORAL_LINING"
          [print]="print"
        ></stx-audio-video>
      </div>
    </stx-form-panel>
    <stx-action-buttons
      *ngIf="!print"
      [asyncSecondaryAction]="onSaveButtonClicked()"
      [asyncPrimaryAction]="onSubmitButtonClicked()"
    ></stx-action-buttons>
  </form>
</ng-container>
