import { AuthService } from '@/src/app/features/authentication/services/auth.service';
import { PatientListItem, PatientTreatment, TreatmentData, TreatmentItemData } from '@/src/app/features/patient/models/patient.model';
import { PatientDetailsActionsComponentModule } from '@/src/app/shared/components/patient-details-actions/patient-details-actions.component';
import { PatientTreatmentDatesComponentModule } from '@/src/app/shared/components/patient-treatment-dates/patient-treatment-dates.component';
import { TreatmentStatusIndicatorComponentModule } from '@/src/app/shared/components/treatment-status-indicator/treatment-status-indicator.component';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormStatus } from '@/src/app/shared/enums/form-status.enum';
import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { ReadonlyCommonsModule } from '@/src/app/shared/modules/readonly-commons/readonly-commons.module';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { TranslateModule } from '@ngx-translate/core';
import { patientStatusDraftValue } from '@utils/patient.utils';
import { partnersRoute, patientDetailsPath, treatmentCentersRoute } from '@utils/routing.utils';
import { getTreatmentTypeData, mapToStatusEnum } from '@utils/treatment.utils';
import { ActionDate } from './patient-treatment.model';
import { nutritionTypeToShortLabelMap } from '@src/app/features/nutrition-management/utils/nutrition-management.utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-patient-treatment',
  templateUrl: './patient-treatment.component.html',
  styleUrls: ['./patient-treatment.component.scss']
})
export class PatientTreatmentComponent implements OnInit {
  @Input() patientTreatment: PatientTreatment;
  @Input() isEven: boolean;
  @Input() patient: PatientListItem;

  treatmentTypes: string[];
  nutritionLabel: string;
  actionDates: ActionDate[];
  hasTreatmentAccess: boolean;
  treatmentStatus: FormStatus;
  treatmentItemData: TreatmentItemData;

  readonly TreatmentType = TreatmentType;
  readonly partnersRoute = partnersRoute;
  readonly PermissionEnum = PermissionEnum;
  readonly treatmentCentersRoute = treatmentCentersRoute;

  get patientRoute(): string {
    return patientDetailsPath(this.patientTreatment.id);
  }

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.treatmentTypes = this.getTreatmentTypes();
    this.treatmentItemData = this.getTreatmentItemData(this.patientTreatment?.treatment);
    this.nutritionLabel = this.getNutritionLabel();
    this.actionDates = this.getActionDates();
    this.treatmentStatus = this.getTreatmentStatus();
  }

  canAccessPatient(): boolean {
    if (this.patientTreatment.status === patientStatusDraftValue) {
      return this.canFollowLink(this.PermissionEnum.GET_PATIENT);
    } else {
      return this.canFollowLink(this.PermissionEnum.SAVE_PATIENT);
    }
  }

  canFollowLink(permissionRequired: PermissionEnum): boolean {
    return this.authService.checkUserPermission(permissionRequired);
  }

  private getTreatmentStatus(): FormStatus | null {
    const treatmentData: TreatmentData = this.patientTreatment.treatment;

    if (treatmentData) {
      return mapToStatusEnum(treatmentData.status);
    }

    return null;
  }

  private getActionDates(): ActionDate[] {
    const actionDates: ActionDate[] = [];

    if (this.patientTreatment?.treatment) {
      const treatment = this.patientTreatment.treatment;

      if (this.patientTreatment.treatment.submitted) {
        actionDates.push({ icon: 'submission-date', date: treatment.submitted });
        if (treatment.modified >= treatment.submitted) {
          actionDates.push({ icon: 'modified-date', date: treatment.modified });
        }
      } else {
        if (treatment.created < treatment.modified) {
          actionDates.push({ icon: 'modified-date', date: treatment.modified });
          actionDates.push({ icon: 'creation-date', date: treatment.created });
        } else if (treatment.created === treatment.modified) {
          actionDates.push({ icon: 'creation-date', date: treatment.created });
        }
      }
    }

    return actionDates;
  }

  private getNutritionLabel(): string {
    if (this.patientTreatment.treatment) {
      return nutritionTypeToShortLabelMap.get(this.patientTreatment.treatment.nutritionManagementAgeGroup);
    }

    return '';
  }

  isNutritionTreatment(treatmentType: TreatmentType): boolean {
    return treatmentType === TreatmentType.NUTRITION_FOLLOW_UP || treatmentType === TreatmentType.NUTRITION_ASSESSMENT;
  }

  private getTreatmentTypes(): string[] {
    if (this.patientTreatment.treatment) {
      switch (this.patientTreatment.treatment.treatmentType) {
        case this.TreatmentType.SURGERY:
          return this.patientTreatment.treatment.surgeryOperationType?.map(type => `surgery.${type}`);

        case this.TreatmentType.SPEECH_ASSESSMENT:
          return [
            getTreatmentTypeData(this.patientTreatment.treatment.treatmentType).name,
            this.patientTreatment.treatment.speechAssessmentStage
          ];

        default:
          return [getTreatmentTypeData(this.patientTreatment?.treatment.treatmentType).name];
      }
    }

    return [];
  }

  private getTreatmentItemData(treatment: TreatmentData): TreatmentItemData {
    return { ...treatment, patientId: this.patientTreatment.id };
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatIconModule,
    TreatmentStatusIndicatorComponentModule,
    TranslateModule,
    PatientDetailsActionsComponentModule,
    PatientTreatmentDatesComponentModule,
    RouterModule,
    MatTooltipModule,
    ReadonlyCommonsModule,
    GeneralCommonsModule
  ],
  declarations: [PatientTreatmentComponent],
  exports: [PatientTreatmentComponent]
})
export class PatientTreatmentComponentModule {}
