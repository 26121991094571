import { Component } from '@angular/core';
import { NavigationSubTabEnum } from '@shared/components/navigation/model/nav-item-enum.enum';
import { PermissionEnum } from 'src/app/core/permissions/permission.enum';
import { videosSections } from '@utils/videos.utils';
import { VirtualSurgeriesModel } from '../../models/virtual-surgeries.model';

@Component({
  templateUrl: './videos-page.component.html',
  styleUrls: ['./videos-page.component.scss']
})
export class VideosPageComponent {
  readonly NavigationSubTabEnum = NavigationSubTabEnum;
  readonly PermissionEnum = PermissionEnum;
  readonly simulatorLink = 'cleftsim.org';
  readonly simulatorUrl = 'http://cleftsim.org';
  videosSections: VirtualSurgeriesModel[] = videosSections;
  headerText = 'surgery_videos.header';
  descriptionText = 'surgery_videos.description_no_link';
}
