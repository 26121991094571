<stx-inline-readonly-field>
  <stx-inline-readonly-field [labelKey]="'partner.partner'" [values]="[recordData.partner]"></stx-inline-readonly-field>
  <stx-value-separator></stx-value-separator>
  <stx-inline-readonly-field
    [labelKey]="'treatment_center.treatment_center'"
    [values]="[recordData.treatmentCenter]"
  ></stx-inline-readonly-field>
  <stx-value-separator></stx-value-separator>
  <stx-inline-readonly-field [labelKey]="'geography.country'" [values]="[recordData.country]"></stx-inline-readonly-field>
</stx-inline-readonly-field>
<stx-inline-readonly-field labelKey="patient">
  <stx-patient-link [patientId]="recordData.patientId">
    {{ recordData.patientId }} <stx-value-separator value="|"></stx-value-separator> {{ recordData.patientName }}
  </stx-patient-link>
</stx-inline-readonly-field>
<stx-inline-readonly-field labelKey="address" [values]="[recordData.patientTownVillageCity, recordData.patientProvince]">
</stx-inline-readonly-field>
