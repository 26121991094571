import { Component, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

@Component({ template: '' })
export abstract class BaseComponent implements OnDestroy {
  protected subSink = new SubSink();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
