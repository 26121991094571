import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../modules/material/material.module';
import { ExpandableRowTableComponent } from './expandable-row-table.component';

@NgModule({
  declarations: [ExpandableRowTableComponent],
  imports: [
    CommonModule,
    MatTableModule,
    TranslateModule,
    MatPaginatorModule,
    MatSortModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule
  ],
  exports: [ExpandableRowTableComponent]
})
export class ExpandableRowTableModule {}
