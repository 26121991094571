import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef
} from '@angular/core';

@Directive({
  selector: '[stxSearchFilterColumn]'
})
export class SearchFilterColumnDirective {
  constructor(public readonly template: TemplateRef<unknown>) {}
}
@Component({
  selector: 'stx-search-filter-columns',
  templateUrl: './search-filter-columns.component.html',
  styleUrls: ['./search-filter-columns.component.scss']
})
export class SearchFilterColumnsComponent extends BaseComponent implements AfterViewInit {
  columns: SearchFilterColumnDirective[] = [];
  @ContentChildren(SearchFilterColumnDirective) private columnsQueryList: QueryList<SearchFilterColumnDirective>;
  @Input() searchButtonText = 'search';
  @Input() searchButtonDisabled = false;
  @Input() showSearchButton = true;
  @Output() searchButtonClicked = new EventEmitter<void>();
  constructor(private cd: ChangeDetectorRef) {
    super();
  }
  ngAfterViewInit(): void {
    this.columns = this.columnsQueryList?.toArray() ?? [];
    this.cd.detectChanges();

    this.subSink.sink = this.columnsQueryList.changes.subscribe((changes: QueryList<SearchFilterColumnDirective>) => {
      this.columns = changes?.toArray() ?? [];
      this.cd.detectChanges();
    });
  }
}
