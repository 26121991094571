import { Component, Input, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ValidationError } from '@shared/validation/validators.model';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ValidatorNameEnum } from '@shared/validation/validator-name.enum';

/**
 * @deprecated To be removed once not used
 */
@Component({
  selector: 'stx-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss']
})
export class ValidationMessageComponent {
  private readonly standardValidationErrorsKeys: string[] = Object.values(ValidatorNameEnum);

  public stxErrorMessageKeys: string[] = [];
  standardErrorsKeys: string[] = [];

  @Input()
  set errors(errors: ValidationError) {
    if (errors !== null) {
      this.stxErrorMessageKeys = Object.values(errors)
        .filter(error => error.message)
        .map(error => error.message);
      this.standardErrorsKeys = Object.keys(errors).filter((errorKey: string) => this.standardValidationErrorsKeys.includes(errorKey));
    }
  }
}

/**
 * @deprecated To be removed once not used
 */
@NgModule({
  declarations: [ValidationMessageComponent],
  imports: [CommonModule, TranslateModule, MatFormFieldModule],
  exports: [ValidationMessageComponent]
})
export class ValidationMessageModule {}
