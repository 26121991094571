import { Injectable } from '@angular/core';
import PasswordValidator from 'password-validator';
import { ArrayUtils } from '@utils/array.utils';

export interface ViolatedRule {
  validation?: string;
  arguments?: number;
  message?: string;
}

export interface PasswordValidationResult {
  isValid: boolean;
  violatedRules: ViolatedRule[];
}

@Injectable({
  providedIn: 'root'
})
export class PasswordValidatorService {
  private validatorSchema: PasswordValidator;

  constructor() {
    this.validatorSchema = new PasswordValidator();
    this.validatorSchema
      .is()
      .min(12, 'error.password.length')
      .is()
      .max(64, 'error.password.length')
      .has()
      .uppercase(1, 'error.password.uppercase')
      .has()
      .lowercase(1, 'error.password.lowercase')
      .has()
      .digits(1, 'error.password.digit')
      .has()
      .symbols(1, 'error.password.special');
  }

  validatePasswordAndGetResult(password: string): PasswordValidationResult {
    const result: boolean | ViolatedRule[] = this.validatorSchema.validate(password, { details: true, list: true });

    const passwordValid = this.isPasswordValid(result);

    if (passwordValid) {
      return { isValid: passwordValid, violatedRules: [] };
    }

    return { isValid: passwordValid, violatedRules: result as ViolatedRule[] };
  }

  private isPasswordValid(result: ViolatedRule[] | boolean): boolean {
    return typeof result === 'boolean' ? result : ArrayUtils.isEmpty(result);
  }
}
