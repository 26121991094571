import { Component, Input } from '@angular/core';
import { dictionaryFromObjectProvider } from '@shared/services/dictionary-provider';
import { Patient } from '@src/app/features/patient/models/patient.model';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { PatientDictionariesService } from '@src/app/features/patient/services/patient-dictionaries.service';

function joinIfNotEmpty(parts: string[]) {
  if (parts.length > 0) {
    return parts.join(' ');
  } else {
    return null;
  }
}

@Component({
  selector: 'stx-patient-readonly',
  templateUrl: './patient-readonly.component.html',
  providers: [dictionaryFromObjectProvider(PatientDictionariesService, s => s.dictionaries$)]
})
export class PatientReadonlyComponent extends BaseComponent {
  @Input()
  patient: Patient;

  get name(): string | null {
    const parts = [this.patient?.firstName, this.patient?.middleName, this.patient?.lastName].filter(str => !!str);
    return joinIfNotEmpty(parts);
  }
  get nameLoc(): string | null {
    const parts = [this.patient?.firstNameLoc, this.patient?.middleNameLoc, this.patient?.lastNameLoc].filter(str => !!str);
    return joinIfNotEmpty(parts);
  }
  get guardianName(): string | null {
    const parts = [this.patient?.guardianFirstName, this.patient?.guardianLastName].filter(str => !!str);
    return joinIfNotEmpty(parts);
  }
  get addressItems(): string[] {
    return [
      this.patient?.address?.street1,
      this.patient?.address?.city,
      this.patient?.address?.zip,
      this.patient?.address?.district,
      this.patient?.address?.state,
      this.patient?.address?.country
    ].filter(str => !!str);
  }

  get pregnancyLength() {
    return this.patient?.diagnosis?.pregnancyLength?.toString() ?? 'dont_know';
  }
}
