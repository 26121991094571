import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  Input,
  QueryList,
  TemplateRef
} from '@angular/core';
import { BaseComponent } from '@shared/components/base-component/base.component';

@Directive({
  selector: '[stxReadonlyViewColumn]'
})
export class ReadonlyViewColumnDirective {
  constructor(public readonly template: TemplateRef<any>) {}
}

@Component({
  selector: 'stx-readonly-view-columns',
  templateUrl: './readonly-view-columns.component.html',
  styleUrls: ['./readonly-view-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadonlyViewColumnsComponent extends BaseComponent implements AfterViewInit {
  @Input() wrap: boolean = false;
  @ContentChildren(ReadonlyViewColumnDirective) columnComponents: QueryList<ReadonlyViewColumnDirective>;

  columns: ReadonlyViewColumnDirective[] = [];

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  get getFlexMaxWidthForNoWrappedContainer(): string {
    const maxWidthOfColumnInPercent: number = this.columns.length > 0 ? 100 / this.columns.length : 100;

    return `0 1 ${maxWidthOfColumnInPercent}%`;
  }

  ngAfterViewInit(): void {
    this.subSink.sink = this.columnComponents.changes.subscribe(changes => {
      this.columns = changes.toArray();
      this.changeDetectorRef.detectChanges();
    });
    this.columnComponents.notifyOnChanges();
  }
}
