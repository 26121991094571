import { TIMINGS } from './constant.animations';
import { animate, animateChild, animation, group, query, style } from '@angular/animations';

export const slideDown = animation(
  [
    group([
      style({ height: 0, opacity: 0, overflow: 'hidden' }),
      query('@*', [animateChild()], { optional: true }),
      animate('{{timing}}', style({ height: '*', opacity: 1, overflow: 'hidden' }))
    ])
  ],
  {
    params: {
      timing: TIMINGS.FAST
    }
  }
);
export const slideUp = animation(
  [
    group([
      style({
        height: '*',
        opacity: 1,
        overflow: 'hidden'
      }),
      query('@*', [animateChild()], { optional: true }),
      animate('{{timing}}', style({ height: 0, opacity: 0, overflow: 'hidden' }))
    ])
  ],
  {
    params: {
      timing: TIMINGS.FAST
    }
  }
);
