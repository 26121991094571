import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { TableStructure } from '@shared/components/table/models/table-structure.model';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ReportService } from '../report-filters/services/report.service';
import { ReportTableParams, ReportType } from '../report-filters/models/report-filters.model';
import { AllowedRecordType, ReportTableDataService } from '../report-filters/services/report-table-data.service';

@Component({
  template: ''
})
export abstract class BaseReportTableComponent<RecordType extends AllowedRecordType> {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<RecordType>;
  tableStructure: TableStructure<RecordType>;
  totalResults: number;
  reportType: ReportType;
  pageSizeOptions: number[];
  pageSize: number;
  currentPageIndex: number;

  private readonly PAGINATION_INDEX_OFFSET = 1;

  protected subSink = new SubSink();

  protected constructor(
    @Inject(String) reportType: ReportType,
    public changeDetector: ChangeDetectorRef,
    public reportTableDataService: ReportTableDataService,
    public reportService: ReportService,
    public route: ActivatedRoute
  ) {
    this.reportType = reportType;
  }

  onInit(): void {
    this.watchQueryParamsChange();
    this.watchTableStructureChanges();
    this.watchPageSizeOptions();
    this.watchCurrentPageSorting();
    this.reportService.showFilters();
  }

  calcAbsoluteRowIndex(relativeIndex: number) {
    return (
      relativeIndex +
      this.PAGINATION_INDEX_OFFSET +
      this.reportTableDataService.currentPageSorting.pageNumber * this.reportTableDataService.currentPageSorting.pageSize
    );
  }

  watchPageSizeOptions() {
    this.subSink.sink = this.reportTableDataService.pageSizeOptions$.subscribe((pageSizeOptions: number[]) => {
      this.pageSizeOptions = pageSizeOptions;
    });
  }

  watchCurrentPageSorting() {
    this.subSink.sink = this.reportTableDataService.currentPageSorting$.subscribe((sortingModel: PaginationSortingModel) => {
      this.pageSize = sortingModel.pageSize;
      this.currentPageIndex = sortingModel.currentPageIndex;
    });
  }

  onDestroy(): void {
    this.subSink.unsubscribe();
    this.reportTableDataService.flush();
  }

  changePage(event: PageEvent): void {
    if (this.tableStructure) {
      this.changeCurrentPageSorting(event);
    }
  }

  private changeCurrentPageSorting(event: PageEvent): void {
    let currentPageSorting: PaginationSortingModel;
    if (event) {
      currentPageSorting = {
        pageNumber: event.pageIndex,
        pageSize: event.pageSize,
        sortedBy: this.tableStructure.data.sortProperty ? this.tableStructure.data.sortProperty : '',
        sortDirection: this.tableStructure.data.sortDirection ? this.tableStructure.data.sortDirection : '',
        currentPageIndex: event.pageIndex
      };
    } else {
      currentPageSorting = {
        pageNumber: 0,
        pageSize: this.pageSize,
        sortedBy: this.tableStructure.data.sortProperty ? this.tableStructure.data.sortProperty : '',
        sortDirection: this.tableStructure.data.sortDirection ? this.tableStructure.data.sortDirection : '',
        currentPageIndex: 0
      };
    }

    this.reportTableDataService.prepareList(this.reportType, this.reportService.model, currentPageSorting);
  }

  watchTableStructureChanges(): void {
    this.subSink.sink = this.reportTableDataService.tableStructure$.subscribe((tableStructure: TableStructure<RecordType>) => {
      const tableData = tableStructure.data;

      this.tableStructure = tableStructure;
      this.dataSource = new MatTableDataSource(tableData?.page?.content);
      this.dataSource.sort = this.sort;
      this.totalResults = tableData?.page?.totalElements;
      this.changeDetector.detectChanges();
    });
  }

  private watchQueryParamsChange() {
    this.subSink.sink = this.route.queryParams
      .pipe(filter((params: ReportTableParams) => params.autoTableDisplay))
      .subscribe((params: ReportTableParams) => {
        this.reportTableDataService.prepareList(this.reportType, this.reportService.model);
      });
  }
}
