import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component, Input, OnInit, Optional } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormGroup } from '@angular/forms';

@Component({ template: '' })
export abstract class BaseFormControlComponent extends BaseFormElementComponent implements OnInit {
  formControl: AbstractControl;

  @Input() controlName: string;
  @Input() isOptional = false;

  constructor(@Optional() private readonly controlContainer: ControlContainer) {
    super();
  }

  ngOnInit(): void {
    if (!this.controlName) {
      throw new Error(`No form control name`);
    }
    if (!this.formGroup) {
      this.formGroup = this.controlContainer.control as UntypedFormGroup;
    }
    if (!this.formGroup) {
      throw new Error(`No form group for control ${this.controlName}`);
    }
    this.formControl = this.formGroup.get(this.controlName);
    if (!this.formControl) {
      throw new Error(`No form control named ${this.controlName}`);
    }
    this.configureFromFormControl(this.formControl);
  }

  protected configureFromFormControl(formControl: AbstractControl) {
    // method for overriding in inheritors classes
  }
}
