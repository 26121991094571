<ng-container *ngIf="records.length">
  <div class="table-container-common">
    <table [dataSource]="records" class="mat-elevation-z2" mat-table aria-describedby="table-container-common">
      <ng-container *ngFor="let columnName of columns" [matColumnDef]="columnName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ headers[columnName] }}</th>
        <td mat-cell *matCellDef="let record" class="align-middle">
          <span class="display-block full-width">
            {{ record[columnName] }}
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns" class="table-row"></tr>
    </table>
  </div>
</ng-container>

<stx-empty-table-state *ngIf="!records.length"></stx-empty-table-state>
