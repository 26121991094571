import {
  getEducationalResourceUrl,
  getLangShortcutMapping,
  REGIONS
} from '@/src/app/features/educational-resources/utils/educational-resources.utils';
import { Component, Input } from '@angular/core';
import { Resource, ResourceSection } from '../../models/resource.model';

@Component({
  selector: 'stx-generic-resource-list',
  templateUrl: './generic-resource-list.component.html',
  styleUrls: ['./generic-resource-list.component.scss']
})
export class GenericResourceListComponent {
  @Input() sections: ResourceSection[];
  readonly regions = REGIONS;

  getMapping(shortcut: string): string {
    return getLangShortcutMapping(shortcut);
  }

  getEducationalResourceUrl(resource: Resource, langShortcut: string = '') {
    return getEducationalResourceUrl(resource, langShortcut);
  }
}
