import { MediaService } from '@/src/app/features/media/services/media.service';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fromEventPattern } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BaseComponent } from '../../shared/components/base-component/base.component';
import { ImageRotatedEvent } from '../../shared/components/image-rotator/image-rotator.component';
import { WsHelperService } from '@shared/services/ws-helper.service';

export const imageViewerQueryParam = 'id';
@Component({
  selector: 'stx-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent extends BaseComponent implements OnInit {
  imageId: number | null;
  imageUrl = '';
  containerHeight: string = '100vh';
  containerWidth: string = '100vw';
  overlayHeight: string = '100vh';
  overlayWidth: string = '100wv';
  isFlipped: boolean;
  @ViewChild('image', { static: true }) imageRef: ElementRef<HTMLImageElement>;
  @ViewChild('overlay', { static: true }) overlay: ElementRef<HTMLDivElement>;
  constructor(
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private mediaService: MediaService,
    private readonly wsHelper: WsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.registerDimensionsUpdateOnImageLoad();
    this.subSink.sink = this.route.queryParams
      .pipe(
        filter(params => !!params),
        map(params => params[imageViewerQueryParam])
      )
      .subscribe(mediaId => {
        this.subSink.sink = this.wsHelper.call(this.mediaService.getHighResMediaUrl(mediaId)).subscribe(mediaUrl => {
          if (mediaUrl && mediaUrl.url) {
            this.imageUrl = mediaUrl.url;
            this.cd.detectChanges();
          }
        });
      });
  }

  private registerDimensionsUpdateOnImageLoad() {
    let loadHandler = () => {
      this.containerHeight = `${this.imageRef.nativeElement.height}px`;
      this.containerWidth = `${this.imageRef.nativeElement.width}px`;
      this.overlayHeight = `${this.imageRef.nativeElement.height * 2}px`;
      this.overlayWidth = `${this.imageRef.nativeElement.width * 2}px`;
      this.cd.detectChanges();
    };

    let addLoadHandler = (handler: (this: HTMLImageElement, ev: Event) => any) => {
      this.imageRef.nativeElement.addEventListener('load', handler);
    };

    let removeLoadHandler = (handler: (this: HTMLImageElement, ev: Event) => any) => {
      this.imageRef.nativeElement.removeEventListener('load', handler);
    };

    this.subSink.sink = fromEventPattern(addLoadHandler, removeLoadHandler).subscribe(loadHandler);
  }

  private adjustOverlayMargins(margin: number) {
    this.overlay.nativeElement.style.marginTop = `-${margin}px`;
    this.overlay.nativeElement.style.marginBottom = `-${margin}px`;
  }

  private flipDimensions() {
    const newHeight = this.containerWidth;
    const newWidth = this.containerHeight;
    this.containerHeight = newHeight;
    this.containerWidth = newWidth;
  }

  rotate(event: ImageRotatedEvent) {
    this.flipDimensions();
    this.adjustOverlayMargins(event.containerMargin);
  }

  close() {
    window.close();
  }

  flipImage() {
    this.isFlipped = !this.isFlipped;
  }
}
