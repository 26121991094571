import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { BaseComponent } from '@shared/components/base-component/base.component';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { PatientLinkComponentModule } from 'src/app/features/reports/components/patient-link/patient-link.component';
import { QaOrthoCasesRecord, QaOrthoCasesTransformToWordProps, QaOrthoCasesViewRecord } from './qa-ortho-cases-report-table.model';
import { ReportService } from 'src/app/features/reports/components/report-filters/services/report.service';
import { ReportTableDataService } from 'src/app/features/reports/components/report-filters/services/report-table-data.service';
import { ReportUtilsService } from 'src/app/features/reports/components/report-filters/services/report-utils.service';
import { TableColumn, TableStructure } from '@shared/components/table/models/table-structure.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-qa-ortho-cases-report-table',
  templateUrl: './qa-ortho-cases-report-table.component.html'
})
export class QaOrthoCasesReportTableComponent extends BaseComponent implements OnInit, OnDestroy {
  private static readonly booleanColumns: QaOrthoCasesTransformToWordProps[] = [
    'considerOcclusalPlane',
    'goodCaseExample',
    'internalComments',
    'invalidPhotoFiles',
    'invalidXrayFiles',
    'mislabeledDentition',
    'needsDifferentAppliances',
    'needsHandsOnTrainingAsap',
    'needsImmediateAttention',
    'needsMoreWorkToElongateColumella',
    'needsMoreWorkToImproveTip',
    'needsToImproveTaping',
    'needsToWorkOnApplianceDesign',
    'needsTrainingAsap',
    'overcomplicatedCare',
    'patientNeedsOralHealthEducationAndAttention',
    'poorDocumentation',
    'poorPatientSelection',
    'segmentsOverCorrection',
    'skipped',
    'technicalComments'
  ];

  private static nonIterableColumns = new Set<keyof QaOrthoCasesRecord>([
    'firstName',
    'firstNameLoc',
    'lastName',
    'lastNameLoc',
    'middleName',
    'middleNameLoc',
    'partnerName',
    'patientId'
  ]);

  headers: Record<string, string> = {
    // patient cell includes values from multiple record properties
    // this header is not returned in api response, all others are
    // set based on reponse.labels content
    patient: 'patient.patient'
  };

  columnsIterable: string[] = [];

  columnsAll: string[] = [];

  pagination: PaginationSortingModel = {
    pageNumber: 0,
    pageSize: 25,
    sortedBy: '',
    sortDirection: ''
  };

  records: QaOrthoCasesViewRecord[] = [];
  totalResults = 0;

  private readonly columnsStatic = ['partnerName', 'patient'];

  constructor(
    public reportTableDataService: ReportTableDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private reportService: ReportService,
    private reportUtilsService: ReportUtilsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.watchTableData();
    this.reportService.showFilters();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.reportTableDataService.flush();
  }

  goToPage(page: PageEvent) {
    this.pagination.pageNumber = page.pageIndex;
    this.pagination.pageSize = page.pageSize;

    this.fetchTableData();
  }

  private fetchTableData() {
    this.reportTableDataService.prepareList(this.reportService.config.type, this.reportService.model, this.pagination);
  }

  private setColumnNames(columns: TableColumn[]) {
    this.columnsIterable = this.reportUtilsService.getColumnsList(columns, QaOrthoCasesReportTableComponent.nonIterableColumns);
    this.columnsAll = [...this.columnsStatic, ...this.columnsIterable];
  }

  private setHeadersTexts(columns: TableColumn[]) {
    this.headers = this.reportUtilsService.getPageHeaders({
      columns,
      columnsToIgnore: QaOrthoCasesReportTableComponent.nonIterableColumns,
      mergeWith: this.headers
    });
  }

  private watchTableData() {
    this.subSink.sink = this.reportTableDataService.tableStructure$.subscribe((tableData: TableStructure<QaOrthoCasesRecord>) => {
      const columns = tableData?.labels || [];

      this.setColumnNames(columns);
      this.setHeadersTexts(columns);

      this.records = this.normalizeRecords(tableData.data.page.content);
      this.totalResults = tableData.data.page.totalElements;
      this.changeDetectorRef.detectChanges();
    });
  }

  private normalizeRecords(records: QaOrthoCasesRecord[]): QaOrthoCasesViewRecord[] {
    return records.map(record => {
      return this.reportUtilsService.translateRecordBooleanColumns<QaOrthoCasesViewRecord>(
        record,
        QaOrthoCasesReportTableComponent.booleanColumns
      );
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    EmptyTableStateComponentModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    PatientLinkComponentModule,
    TranslateModule
  ],
  declarations: [QaOrthoCasesReportTableComponent],
  exports: [QaOrthoCasesReportTableComponent]
})
export class QaOrthoCasesReportTableComponentModule {}
