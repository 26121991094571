<stx-generic-wide-page>
  <stx-report-header>
    {{ title | translate }}
  </stx-report-header>

  <stx-notice noticeType="success" [@successNotice]="successNoticeVisible ? 'visible' : 'hidden'">
    {{ 'report.file_export_success_message' | translate }}
  </stx-notice>

  <stx-report-filters [leftColumnInfoTemplate]="info" [reportConfig]="reportConfig" (applyFilters)="downloadReport()"></stx-report-filters>

  <ng-template #info>
    {{ 'reports.info.treatment_number_may_differ' | translate }}
  </ng-template>
</stx-generic-wide-page>
