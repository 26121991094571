<stx-authentication-container>
  <stx-release-info></stx-release-info>
  <div *ngIf="showAuthError" class="login-error p-10 m-b-10 fs-14 flex-row-container flex-center align-top">
    <mat-icon class="warning-icon">warning</mat-icon>
    <div class="black-text m-l-10">{{ 'error.login' | translate }}</div>
  </div>
  <form [formGroup]="loginForm" class="login-form flex-column-container">
    <stx-input-control formControlName="email" inputId="email">
      <div class="flex-row-container align-justify">
        <span>{{ 'login.user_name_or_email' | translate }}</span>
        <a (click)="openHelpModal()"> {{ 'login.help_button' | translate }}</a>
      </div>
    </stx-input-control>
    <stx-input-password formControlName="password" inputId="password">
      <div class="flex-row-container align-justify">
        <span>{{ 'password' | translate }}</span>
        <a (click)="openResetPasswordModal()" class="text-right"> {{ 'authentication.reset_password' | translate }}</a>
      </div>
    </stx-input-password>
    <stx-action-buttons
      [showSecondary]="false"
      [asyncPrimaryAction]="login"
      [primaryDisabled]="loginForm.invalid"
      [primaryText]="'login' | translate"
    ></stx-action-buttons>
  </form>
</stx-authentication-container>
