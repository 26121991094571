<div>
  <div *ngIf="label" class="form-element-label">{{ label | translate }}</div>
  <div
    [class.form-element-group-column]="layoutDirection === 'column'"
    [class.form-element-group-row]="layoutDirection === 'row'"
    [class.form-element-group-grid-2cols]="layoutDirection === '2cols'"
    [class.form-element-group-grid-3cols]="layoutDirection === '3cols'"
    [class.form-element-group-grid-4cols]="layoutDirection === '4cols'"
  >
    <div *ngFor="let option of options">
      <stx-checkbox
        *ngIf="option !== null && option !== undefined"
        [formGroup]="formGroup"
        [formMode]="formMode"
        [name]="option.label"
        [controlName]="option.name ? option.name : option.value"
        [tooltip]="option.tooltipText"
        [print]="print"
        [subtitle]="option.subtitle"
        (updateCheckbox)="emitCheckboxChanges($event)"
      ></stx-checkbox>
      <ng-container *ngTemplateOutlet="templateRef; context: { $implicit: option }"></ng-container>
    </div>
  </div>

  <mat-error *ngIf="showError && !print" class="checkbox-group-error stx-mat-error-align">
    {{ 'error.none_selected_above' | translate }}
  </mat-error>
</div>
