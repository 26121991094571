import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TreatmentItemData } from '../../../features/patient/models/patient.model';
import { AuthService } from '../../../features/authentication/services/auth.service';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { editRoute } from '@utils/routing.utils';
import { treatmentPaths } from '@utils/treatment.utils';
import { VisibilityScope } from '../../models/visibility-scope.model';
import { AgeYearMonthPipeModule } from '../../modules/readonly-commons/pipes/age-year-month/age-year-month.pipe';
import { FormStatus } from '@shared/enums/form-status.enum';
import { formStatusMap } from '@utils/form.utils';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { isUsingBusinessId } from '@shared/components/treatment/treatment.enum';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-patient-treatment-dates',
  templateUrl: './patient-treatment-dates.component.html',
  styleUrls: ['./patient-treatment-dates.component.scss']
})
export class PatientTreatmentDatesComponent implements OnInit {
  @Input() treatment: TreatmentItemData;
  @Input() hideAgeMonth = false;

  hasTreatmentAccess: boolean;
  patientTreatmentPath: string;
  readonly PermissionEnum = PermissionEnum;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.hasTreatmentAccess = this.canAccessTreatment();
    this.patientTreatmentPath = this.getPatientTreatmentRoutingPath();
  }

  canFollowLink(permissionRequired: PermissionEnum): boolean {
    return this.authService.checkUserPermission(permissionRequired);
  }

  private getPatientTreatmentRoutingPath() {
    const treatmentPathPart: string = this.getTreatmentPathPart();

    const id = isUsingBusinessId(this.treatment.treatmentType) ? this.treatment.businessId : this.treatment.id;

    return `${treatmentPathPart}/${id}`;
  }

  private getTreatmentPathPart(): string {
    let pathToTreatment = treatmentPaths.get(this.treatment?.treatmentType)?.path;

    if (
      this.treatment?.status === formStatusMap.get(FormStatus.IN_PROGRESS) &&
      this.treatment.visibilityScope === VisibilityScope.USER_SCOPE &&
      !this.treatment.isReadonly &&
      this.canFollowLink(PermissionEnum[`SAVE_${this.treatment.treatmentType}`])
    ) {
      pathToTreatment = `${pathToTreatment}/${editRoute}`;
    }

    return pathToTreatment;
  }

  private canAccessTreatment(): boolean {
    if (this.treatment?.id === null) {
      return false;
    }

    const treatmentType = this.treatment?.treatmentType;

    if (this.treatment.status === 9) {
      return this.canFollowLink(PermissionEnum[`SAVE_${treatmentType}`]) || this.canFollowLink(PermissionEnum[`GET_${treatmentType}`]);
    }

    return this.canFollowLink(PermissionEnum[`GET_${treatmentType}`]);
  }

  get noTreatmentDatesProvided() {
    return this.treatment.created && !this.treatment.treatmentDate && !this.treatment.startDate && !this.treatment.endDate;
  }

  get shouldHaveEndDatePlaceholder() {
    return (
      (this.treatment.startDate || (this.treatment.created && !this.treatment.startDate && !this.treatment.treatmentDate)) &&
      !this.treatment.endDate
    );
  }
}

@NgModule({
  imports: [CommonModule, FlexLayoutModule, RouterModule, AgeYearMonthPipeModule, GeneralCommonsModule],
  declarations: [PatientTreatmentDatesComponent],
  exports: [PatientTreatmentDatesComponent]
})
export class PatientTreatmentDatesComponentModule {}
