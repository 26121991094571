import { Component, Input } from '@angular/core';
import { DiagnosisForReview } from '../../../models/diagnosis.model';

@Component({
  selector: 'stx-historic-diagnose-detail-row',
  templateUrl: './historic-diagnose-detail-row.component.html',
  styleUrls: ['./historic-diagnose-detail-row.component.scss']
})
export class HistoricDiagnoseDetailRowComponent {
  readonly dashSpacer = ' — ';
  @Input() diagnosis: DiagnosisForReview;
}
