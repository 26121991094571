import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { SpinnerService } from '@/src/app/shared/components/spinner/service/spinner.service';
import { CountryDictionary } from '@/src/app/shared/models/geo.model';
import { GeoService } from '@/src/app/shared/services/geo.service';
import { GlobalErrorHandlerService } from '@/src/app/shared/services/global-error-handler.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { TreatmentType } from '@shared/components/treatment/treatment.enum';
import { FormType } from '@shared/enums/form-type.enum';
import { FormMode, formStatusMap } from '@utils/form.utils';
import { DictionaryTranslation, Patient, TreatmentData, TreatmentItemData } from '@src/app/features/patient/models/patient.model';
import { TreatmentHistoryItem } from '@src/app/features/patient/models/patient-treatment-history.model';
import { PatientService } from '@src/app/features/patient/patient.service';
import { FormStatus } from '@shared/enums/form-status.enum';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  templateUrl: './patient-treatment-history-page.component.html',
  styleUrls: ['./patient-treatment-history-page.component.scss']
})
export class PatientTreatmentHistoryPageComponent extends BaseComponent implements OnInit {
  patient: Patient;
  treatments: TreatmentHistoryItem[] = [];
  countries: CountryDictionary[] = [];
  genders: DictionaryTranslation[] = [];
  countryDictionaries$: Observable<CountryDictionary[]> = this.geoService.getCountryDictionaries;
  print: boolean;

  formMode = FormMode.READONLY;

  readonly TreatmentType = TreatmentType;
  readonly FormType = FormType;
  readonly FormMode = FormMode;

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private spinnerService: SpinnerService,
    private geoService: GeoService,
    private globalErrorHandler: GlobalErrorHandlerService,
    private wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initStaticDictionaries();
    this.loadPatient();
  }

  @HostListener('window:beforeprint')
  onBeforePrint() {
    this.print = true;
  }
  @HostListener('window:afterprint')
  onAfterPrint() {
    this.print = false;
  }

  get isDataLoaded(): boolean {
    return this.countries?.length > 0 && this.genders?.length > 0 && !!this.patient;
  }

  loadPatient() {
    this.subSink.sink = this.route.params.subscribe(params => {
      const patientId = params['patientId'];
      if (patientId) {
        this.wsHelperService
          .callWithSpinner(this.patientService.getPatientTreatmentHistory(patientId))
          .subscribe(patientTreatmentHistory => {
            this.patient = patientTreatmentHistory.patient;
            this.treatments = this.filterDeliveredCareTreatments(patientTreatmentHistory.treatments);
          });
      }
    });
  }

  getTreatmentItemData(header: TreatmentData, treatment: any): TreatmentItemData {
    return { ...header, patientId: treatment ? treatment.patientId : null };
  }

  private filterDeliveredCareTreatments(treatments: TreatmentHistoryItem[]) {
    const excludedStatuses = [formStatusMap.get(FormStatus.REJECTED), formStatusMap.get(FormStatus.EXCEEDS_QUOTA)];

    return treatments.filter((treatment: TreatmentHistoryItem) => !excludedStatuses.includes(treatment.header.status));
  }

  private initStaticDictionaries() {
    this.wsHelperService.callWithSpinner(this.patientService.getStaticDictionaries()).subscribe(dictionary => {
      this.genders = dictionary.get('gender').map(value => value);
    });
    this.wsHelperService.callWithSpinner(this.countryDictionaries$).subscribe(dictionary => {
      this.countries = dictionary;
    });
  }
}
