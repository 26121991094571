import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import {
  DataTableDataProvider,
  GenericDataTableDataProvider
} from '@/src/app/shared/modules/general-commons/components/data-table/data-table-data-provider';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FileUtils } from 'src/app/utils/file.utils';
import { SearchFilterService } from '../../../search/services/search-filter.service';
import { UserSearchModel } from '../../models/user-search.model';
import { UserService } from '../../services/user.service';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { RoleEnum } from '@src/app/features/authentication/helpers/role.enum';
import { PasswordExpiryService } from '@src/app/features/authentication/services/password-expiry.service';
import { AuthService } from '@src/app/features/authentication/services/auth.service';

@Component({
  templateUrl: './user-search-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SearchFilterService]
})
export class UserSearchPageComponent extends BaseComponent implements OnInit {
  private searchFilters: UserSearchModel;
  searchResultsDataProvider: DataTableDataProvider<any>;
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private searchFilterService: SearchFilterService,
    private wsHelperService: WsHelperService,
    globalErrorHandlerService: GlobalErrorHandlerService,
    private passwordExpiryService: PasswordExpiryService
  ) {
    super();
    this.searchResultsDataProvider = new GenericDataTableDataProvider(
      {
        loadFunction: pageSort => this.wsHelperService.callWithSpinner(userService.getUsers(pageSort, this.searchFilters))
      },
      wsHelperService,
      globalErrorHandlerService
    );
  }
  ngOnInit(): void {
    this.subSink.sink = this.searchFilterService.searchEvent$.subscribe((searchFilters: UserSearchModel) => {
      this.searchFilters = searchFilters;
      this.searchResultsDataProvider.reload();
    });
    this.subSink.sink = this.searchFilterService.csvDownloadEvent$.subscribe((searchFilters: UserSearchModel) => {
      this.userService.getUsersCsvFile(searchFilters).subscribe(res => {
        FileUtils.downloadFile(res, 'STX-users.csv');
      });
    });
    this.passwordExpiryService.showPasswordExpiryPopup();
  }

  isViewDashboardForUser(): boolean {
    return this.authService.checkUserRole(RoleEnum.STX_SUPER_USER);
  }
}
