<stx-modal>
  <stx-notice [noticeType]="data.noticeType">
    <ng-template [ngIf]="singleMessage" [ngIfElse]="multipleMessages">{{ data.translatedMessages[0] }}</ng-template>

    <ng-template #multipleMessages>
      <ul>
        <li *ngFor="let message of data.translatedMessages">
          {{ message }}
        </li>
      </ul>
    </ng-template>
  </stx-notice>
</stx-modal>
