<stx-readonly-view-section-header labelKey="ortho.general_patient_information"></stx-readonly-view-section-header>

<stx-readonly-view-columns *ngIf="treatment">
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field
      *ngIf="treatment.isAbandoned"
      label="ortho.treatment_completed"
      [value]="['patient.pregnancy_compilation.no' | translate, treatment.abandonReasonMore]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field label="ortho.service_start_date" [value]="treatment.startDate"></stx-simple-readonly-field>
    <stx-simple-readonly-field label="ortho.service_end_date" [value]="treatment.endDate"></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="age"
      [value]="[treatment.ageMonth | ageYearMonth, treatment.ageMonthEnd | ageYearMonth]"
    ></stx-simple-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-readonly-field label="treatment_center.treatment_center">
      <stx-tc-link [treatmentCenter]="treatment.treatmentCenter"></stx-tc-link>
    </stx-readonly-field>
    <stx-simple-readonly-field label="practitioner.orthodontist" [value]="treatment.practitioner?.name"></stx-simple-readonly-field>
    <stx-simple-readonly-field label="practitioner.surgeon" [value]="treatment.surgeon?.name"></stx-simple-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field *ngIf="!!treatment.followup" label="reports.orthodontic.follow_up" [value]="[treatment.followup]">
    </stx-simple-readonly-field>
    <ng-container *ngIf="!!treatment?.numberOfSessions">
      <stx-simple-readonly-field label="reports.orthodontic.follow_up" [value]="treatment?.numberOfSessions"></stx-simple-readonly-field>
    </ng-container>
    <stx-simple-readonly-field
      label="speech_assessment.fistula"
      [value]="[
        treatment.fistula | yesNoOrNull,
        'ortho.fistula.soft_palate' | if : treatment.fistulaSoftPalate | translate,
        'ortho.fistula.hard_palate' | if : treatment.fistulaHardPalate | translate,
        'ortho.fistula_alveolar_ridge' | if : treatment.fistulaAlveolarRidge | translate,
        'ortho.fistula_pre_surgery' | if : treatment.fistulaPreSurgery | translate
      ]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="reports.orthodontic.oral_health"
      [value]="[treatment.oralHealth | yesNoOrNull, treatment.oralHealthMore]"
    >
    </stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="reports.orthodontic.info_given"
      [value]="[treatment.infoGiven | dict : 'yesNoNa' | async | translate, treatment.infoGivenMore]"
    >
    </stx-simple-readonly-field>
    <stx-simple-readonly-field label="complications" [value]="[treatment.complication | yesNo, treatment.complicationMore]">
    </stx-simple-readonly-field>
  </ng-container>
</stx-readonly-view-columns>
<stx-readonly-media-group [mediaSets]="photoSets"></stx-readonly-media-group>
