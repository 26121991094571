import { getFirstErrorMessage } from '@/src/app/shared/validation/error.utils';
import { Component, Input, OnInit } from '@angular/core';
import { BaseFormControlComponent } from '@shared/components/form/components/base-form-control/base-form-control.component';
import { Item } from '@shared/models/item.model';
import { SelectOption, SelectOptions } from './select.model';

/** @deprecated Use AutocompleteSingleSelectComponent instead for filters. */
@Component({
  selector: 'stx-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent extends BaseFormControlComponent implements OnInit {
  @Input() label: string;
  @Input() noFormFieldSpacing? = false;
  @Input() showChips = false;
  @Input() isRtlLanguage: boolean;
  @Input() customErrorText: string;
  @Input() autoSelectSingleOption = true;
  @Input() hasBlankOption = false;
  @Input() shouldTranslateName: boolean = false;

  // Please check the scss styling file
  readonly matOptionHeight = 30;

  chipValue: Item;

  items: SelectOptions = [];

  @Input() set dictionaryList(items: SelectOptions) {
    this.items = items || [];
    this.resetAndAutoselect();
  }

  ngOnInit() {
    super.ngOnInit();
    this.watchSelectModel();
    this.resetAndAutoselect();
  }

  get initialized(): boolean {
    return this.items != null && this.items.length > 0 && !!this.formControl;
  }

  private resetAndAutoselect(): void {
    if (this.initialized) {
      this.resetValueIfNoOptions();
      this.resetValueIfNotAvailable();
      if (this.autoSelectSingleOption) {
        this.selectActiveFirstOptionIfThereIsOnlyOne();
      }
    }
  }

  private watchSelectModel() {
    this.subSink.sink = this.formControl.valueChanges.subscribe(() => {
      this.setChipsValue();
    });
  }

  private setChipsValue() {
    if (this.showChips && this.formControl.value) {
      const selectedOption = this.items.find((selectOption: SelectOption) => selectOption.id === this.formControl.value);

      this.chipValue = {
        id: 1,
        name: selectedOption.name
      };
    } else {
      this.chipValue = null;
    }
  }

  private resetValueIfNoOptions() {
    if (this.initialized && this.items.length === 0) {
      this.formControl?.reset();
    }
  }

  private resetValueIfNotAvailable() {
    const hasItems = this.items.length !== 0;
    const hasValue = this.formControl && !!this.formControl.value;
    if (hasItems && hasValue) {
      const matchingItem = this.items.find(item => item.id === this.formControl.value);
      if (!matchingItem) {
        this.formControl.reset();
      }
    }
  }

  private selectActiveFirstOptionIfThereIsOnlyOne() {
    if (this.items.length === 1) {
      const item = this.items[0];
      if (item.isActive) {
        this.formControl?.setValue(item.id);
      }
    }
  }

  get validationErrorPath(): string {
    return getFirstErrorMessage(this.formControl.errors) || 'error.select';
  }
}
