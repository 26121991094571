import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component, Input } from '@angular/core';
import { FormType } from '@shared/enums/form-type.enum';
import { sentenceRepetitionTexts, syllableRepetitionTexts } from './speech-recording.utils';

@Component({
  selector: 'stx-speech-sample-video-recording',
  templateUrl: './speech-sample-video-recording.component.html'
})
export class SpeechSampleVideoRecordingComponent extends BaseFormElementComponent {
  readonly syllableRepetitionTexts = syllableRepetitionTexts;
  readonly sentenceRepetitionTexts = sentenceRepetitionTexts;

  @Input() formType: FormType;

  constructor() {
    super();
  }
}
