import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { PatientDataService } from '@src/app/features/patient/services/patient-data.service';
import { VisibilityScope } from '@shared/models/visibility-scope.model';
import { patientStatusDraftValue } from '@utils/patient.utils';
import { Patient } from '@src/app/features/patient/models/patient.model';
import { ActivatedRoute } from '@angular/router';
import { PatientManagementService } from '@src/app/features/patient/services/patient-management.service';
import { AuthService } from '@src/app/features/authentication/services/auth.service';
import { PermissionEnum } from '@core/permissions/permission.enum';

@Component({
  templateUrl: './patient-details-page.component.html'
})
export class PatientDetailsPageComponent extends BaseComponent implements OnInit {
  showForm: boolean = false;
  showReadonlyView: boolean = false;
  patient: Patient | null = null;

  constructor(
    private patientDataService: PatientDataService,
    private patientManagementService: PatientManagementService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.subSink.sink = this.patientDataService.patient$.subscribe(patient => {
      this.patient = patient;
      const isNew = patient === null;
      const isDraft = patient?.status === patientStatusDraftValue;
      const isInUserScope = patient?.visibilityScope === VisibilityScope.USER_SCOPE;
      const isReadonly = patient?.isReadonly;
      const userHasSavePermission = this.authService.checkUserPermission(PermissionEnum.SAVE_PATIENT);
      const canEdit = (isNew && userHasSavePermission) || (isDraft && isInUserScope && userHasSavePermission);
      this.showForm = canEdit;
      this.showReadonlyView = !canEdit;
    });

    this.subSink.sink = this.route.queryParams.subscribe(params => {
      const patientIdToClone = params['patientIdToClone'];
      if (patientIdToClone) {
        this.patientManagementService.loadPatientForClone(patientIdToClone).subscribe(patientData => (this.patient = patientData));
      }
    });
  }

  refreshPatient(): void {
    this.patientDataService.refreshLoadedPatient();
  }
}
