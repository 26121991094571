import { OrthoTreatmentQAData } from '@/src/app/features/ortho/base-orthodontic-treatment.model';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { UserService } from '@/src/app/features/user/services/user.service';
import { StatusBarBaseComponent } from '@/src/app/shared/components/treatment/components/status-bar-base/status-bar-base.component';
import { User } from '@/src/app/shared/models/user/user.model';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@src/app/features/authentication/services/auth.service';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  selector: 'stx-ortho-treatment-status-bar',
  templateUrl: './ortho-treatment-status-bar.component.html',
  styleUrls: ['./../treatment-status-bar/treatment-status-bar.component.scss']
})
export class OrthoTreatmentStatusBarComponent extends StatusBarBaseComponent {
  _orthoTreatment: OrthoTreatmentQAData;
  qaReviewerName: string;
  get orthoTreatment() {
    return this._orthoTreatment;
  }
  get shouldDisplayQaData() {
    return (
      this.authService.checkUserPermission(PermissionEnum.GET_USERS) &&
      (this.qaReviewerName || this._orthoTreatment.qaPreRank || this._orthoTreatment.qaPostRank)
    );
  }
  @Input() set orthoTreatment(value: OrthoTreatmentQAData) {
    if (value) {
      this._orthoTreatment = value;
      this.updateQaReviewerName();
    }
  }

  constructor(
    dialog: MatDialog,
    translateService: TranslateService,
    cd: ChangeDetectorRef,
    patientService: PatientService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly wsHelperService: WsHelperService
  ) {
    super(dialog, translateService, cd, patientService);
  }

  getRankText(rankNumber: string): string {
    return rankNumber == '9' ? 'reports.filters.cannot_access_photo' : rankNumber;
  }

  private updateQaReviewerName() {
    if (this._orthoTreatment.qaReviewBy && this.authService.checkUserPermission(PermissionEnum.GET_USERS)) {
      this.subSink.sink = this.wsHelperService
        .call(this.userService.getUserById(this._orthoTreatment.qaReviewBy))
        .subscribe((user: User) => {
          this.qaReviewerName = `${user.firstName} ${user.lastName}`;
          this.cd.detectChanges();
        });
    }
  }
}
