import { BaseTreatment } from '@src/app/features/surgical/models/base-treatment.model';
import { formStatusMap } from '@utils/form.utils';
import { FormStatus } from '@shared/enums/form-status.enum';
import { VisibilityScope } from '@shared/models/visibility-scope.model';

export class TreatmentBusinessRules {
  static treatmentInProgress(treatment: BaseTreatment): boolean {
    return treatment.status === formStatusMap.get(FormStatus.IN_PROGRESS);
  }

  static treatmentEditableBasedOnScope(treatment: BaseTreatment): boolean {
    return treatment.visibilityScope === VisibilityScope.USER_SCOPE;
  }
}
