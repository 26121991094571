import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'stx-reset-password-confirmation-modal',
  templateUrl: './reset-password-confirmation-modal.component.html',
  styleUrls: ['./reset-password-confirmation-modal.component.scss']
})
export class ResetPasswordConfirmationModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { isSuccess: boolean },
    private router: Router,
    public dialogRef: MatDialogRef<ModalComponent>
  ) {}

  navigateToLogin(): void {
    this.router.navigateByUrl('/login');
    this.dialogRef.close();
  }
}
