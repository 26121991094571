import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatepickerI18nService } from '@shared/services/localisation/datepicker-i18n.service';
import * as moment from 'moment';
export const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
export const dateFormat = 'yyyy-MM-dd';
export function ageMonthToYears(ageMonth: number): string {
  const years = Math.floor(ageMonth / 12);
  if (years) {
    return `${years}y ${ageMonth % 12}m`;
  } else {
    return `${ageMonth % 12}m`;
  }
}

export function getCurrentMonthString(): string {
  return new Date().toLocaleDateString(undefined, { month: 'long' });
}

export const STX_MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: [
      'YYYY-MM-DD',
      'YYYY-M-D',
      'YYYY-MM-D',
      'YYYY-M-DD',
      'DD/MM/YYYY',
      'D/M/YYYY',
      'DD/M/YYYY',
      'D/MM/YYYY',
      'DD.MM.YYYY',
      'D.M.YYYY',
      'DD.M.YYYY',
      'D.MM.YYYY'
    ]
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'YYYY-MMM-DD',
    monthYearA11yLabel: 'MMMM YYY'
  }
};

export function getNextDay(startDate: moment.Moment): moment.Moment {
  return moment(startDate).add(1, 'd');
}

export function setDateSixMonthsBack(date: moment.Moment): string {
  return moment(date).subtract(6, 'M').format(STX_MOMENT_DATE_FORMATS.display.dateInput);
}

export function isSameDay(firstDate: moment.Moment, secondDate: moment.Moment) {
  return firstDate && secondDate && moment(firstDate).isSame(secondDate, 'day');
}

export function isAfterDay(firstDate: moment.Moment, secondDate: moment.Moment) {
  return firstDate && secondDate && moment(firstDate).isAfter(secondDate, 'day');
}

export const MOMENT_ADAPTER_PROVIDERS_ARRAY = [
  { provide: MAT_DATE_FORMATS, useValue: STX_MOMENT_DATE_FORMATS },
  {
    provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    // We should have a custom adapter and use EST as the global timezone. TODO#10557
    useValue: { strict: true, useUtc: true }
  },
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_DATE_FORMATS]
  },
  {
    provide: DatepickerI18nService
  }
];

/**
 * @returns Today in UTC mode with timezone offset applied. All getters/fomatters on the Moment instance will return real local time as if it were UTC time.
 */
export function getToday(): moment.Moment {
  return moment().utc(true);
}

export function getMoment(dateString: string): moment.Moment {
  return moment(dateString).utc(true);
}

export function getCurrentYear(): number {
  return getToday().year();
}

export function getCurrentMonth(): number {
  return getToday().month() + 1;
}

export function formatDateTimeInLocalTimeZone(dateTime: moment.Moment): string {
  return moment(dateTime).local(true).format('yyyy-MM-DD HH:mm');
}
