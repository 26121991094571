import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FormModule } from '@shared/components/form/form.module';
import { ReportFilterChange, ReportFiltersParams } from '../../models/report-filters.model';
import { ReportDictionariesService } from '../../services/report-dictionaries.service';
import { SearchModule } from '../../../../../search/search.module';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { OrgTreeFilterModel } from '../../../../../search/models/org-tree-filter.model';
import { ReportFiltersHelperService } from '../../services/report-filters-helper.service';
import { Item } from '@shared/models/item.model';

@Component({
  selector: 'stx-report-geography-filter',
  templateUrl: './report-geography-filter.component.html'
})
export class ReportGeographyFilterComponent extends BaseComponent implements OnInit {
  @Input() geographyFilterData: OrgTreeFilterModel;
  @Input() formGroupReference: UntypedFormGroup;
  @Output() filterChange = new EventEmitter<ReportFilterChange>();

  constructor(
    public reportDictionariesService: ReportDictionariesService,
    private route: ActivatedRoute,
    private reportHelperService: ReportFiltersHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.subSink.sink = this.formGroupReference.valueChanges.subscribe(this.handleValueChanges.bind(this));
    this.watchQueryParamsChange();
  }

  private handleValueChanges(change: Record<string, Array<Item>>): void {
    this.reportHelperService.mapAndEmitMultiselectChanges(change, this.filterChange);
  }

  private watchQueryParamsChange() {
    this.subSink.sink = this.route.queryParams
      .pipe(
        first(
          params =>
            !!params.geographyCountries ||
            !!params.geographyCrmRegions ||
            !!params.geographyCrms ||
            !!params.geographyProvinces ||
            !!params.geographyZoneCountries ||
            !!params.geographyZoneProvinces
        )
      )
      .subscribe((params: ReportFiltersParams) => {
        if (params.countries) {
          const countriesParams = this.getIntegerIds(params.countries);

          this.setValuesFromParams('countries', countriesParams);
        }
        if (params.crmRegions) {
          const crmRegionsParams = this.getIntegerIds(params.crmRegions);

          this.setValuesFromParams('crmRegions', crmRegionsParams);
        }
        if (params.crms) {
          const crmsParams = this.getIntegerIds(params.crms);

          this.setValuesFromParams('crms', crmsParams);
        }
        if (params.provinces) {
          const provincesParams = this.getIntegerIds(params.provinces);

          this.setValuesFromParams('provinces', provincesParams);
        }
        if (params.zoneCountries) {
          const zoneCountriesParams = this.getIntegerIds(params.zoneCountries);

          this.setValuesFromParams('zoneCountries', zoneCountriesParams);
        }
        if (params.zoneProvinces) {
          const zoneProvincesParams = this.getIntegerIds(params.zoneProvinces);

          this.setValuesFromParams('zoneProvinces', zoneProvincesParams);
        }
      });
  }

  private getIntegerIds(values: string[]) {
    return [].concat(values).map((value: string) => parseInt(value, 10));
  }

  private setValuesFromParams(formControlName: string, values: number[]) {
    this.formGroupReference.get(formControlName).patchValue(values);
  }
}

@NgModule({
  declarations: [ReportGeographyFilterComponent],
  imports: [FormModule, SearchModule, CommonModule],
  exports: [ReportGeographyFilterComponent]
})
export class ReportGeographyFilterModule {}
