import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-readonly-view-section-header',
  templateUrl: './readonly-view-section-header.component.html',
  styleUrls: ['./readonly-view-section-header.component.scss']
})
export class ReadonlyViewSectionHeaderComponent {
  @Input()
  labelKey: string;
}
