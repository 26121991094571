import { Component, Input } from '@angular/core';
import { OperationDescriptionForReview } from '../../models/operation-description.model';

@Component({
  selector: 'stx-surgery-details',
  templateUrl: './surgery-details.component.html'
})
export class SurgeryDetailsComponent {
  @Input() operationDescription: OperationDescriptionForReview;
  @Input() patientAge: string;
}
