import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { orthoQaRoute, speechQaRoute, surgicalQaRoute } from '@/src/app/utils/routing.utils';
import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router } from '@angular/router';
import { AuthService } from '../../authentication/services/auth.service';

export const DashboardPathGuard: CanMatchFn | CanActivateFn = (route, segments) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  if (!authService.checkUserPermission(PermissionEnum.VIEW_DASHBOARD_MAIN)) {
    if (authService.checkUserPermission(PermissionEnum.VIEW_DASHBOARD_REVIEW_SURGICAL)) {
      return router.parseUrl(`/${surgicalQaRoute}`);
    }
    if (authService.checkUserPermission(PermissionEnum.VIEW_DASHBOARD_REVIEW_ORTHO)) {
      return router.parseUrl(`/${orthoQaRoute}`);
    }
    if (authService.checkUserPermission(PermissionEnum.VIEW_DASHBOARD_REVIEW_SPEECH)) {
      return router.parseUrl(`/${speechQaRoute}`);
    }
  }
  return true;
};
