import {
  CheckboxOption,
  CommonReportDictionaryItem,
  ReportFilterChange,
  ReportFilterName
} from '@/src/app/features/reports/components/report-filters/models/report-filters.model';
import { EventEmitter, Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectOption, SelectOptions } from '@shared/components/form/components/select/select.model';
import { Item } from '@shared/models/item.model';

@Injectable({
  providedIn: 'root'
})
export class ReportFiltersHelperService {
  constructor(private translateService: TranslateService) {}

  getCheckboxGroupOptionsFromDictionary(dictionary: CommonReportDictionaryItem[]): CheckboxOption[] {
    return dictionary?.map((dictionaryItem: CommonReportDictionaryItem) => {
      const { label, value } = dictionaryItem;
      return {
        label,
        value,
        name: value.toString()
      };
    });
  }

  addCheckboxGroupOptionsToFormGroup(formGroup: UntypedFormGroup, options: CheckboxOption[]): void {
    options?.map((option: CheckboxOption) => {
      const optionFormControl = new UntypedFormControl(false, {
        updateOn: 'change'
      });

      formGroup.addControl(option.value, optionFormControl);
    });
  }

  getSelectedCheckboxesFromGroup(group: UntypedFormGroup): string[] {
    return Object.entries(group.controls)
      .filter(([, control]) => control.value)
      .map(([controlName]) => controlName);
  }

  getAllCheckboxControlNamesFromGroup(formGroup: unknown): string[] {
    return Object.entries(formGroup ?? {}).map(([controlName]) => controlName);
  }

  getSelectedCheckboxOptions(change: Record<string, boolean>) {
    return Object.entries(change)
      .filter(([, isSelected]) => isSelected)
      .map(([option]) => option);
  }

  mapDictionaryToSelectOptions(dictionary: CommonReportDictionaryItem[] = [], autoTranslateName: boolean = true): SelectOptions {
    return dictionary?.map(dictionaryItem => {
      const name = autoTranslateName ? this.translateService.instant(dictionaryItem.label) : dictionaryItem.label;

      return {
        id: dictionaryItem.value,
        name
      };
    });
  }

  mapDictionaryToItems(dictionary: CommonReportDictionaryItem[] = [], autoTranslateName: boolean = true): Item[] {
    return dictionary?.map(dictionaryItem => {
      const name = autoTranslateName ? this.translateService.instant(dictionaryItem.label) : dictionaryItem.label;

      return {
        id: Number.parseInt(dictionaryItem.value, 10),
        name: name
      };
    });
  }

  setAllCheckboxesValue(group: UntypedFormGroup, value: boolean) {
    Object.values(group.controls).forEach(control => {
      control.setValue(value);
    });
  }

  setCheckboxFormGroupValues(formGroupReference: UntypedFormGroup, checkboxValues: string[] = []) {
    formGroupReference.reset();

    // workaround because when user refresh page, checkbox values are strings instead of array of strings
    [].concat(checkboxValues).forEach(checkboxValue => {
      const formControl: AbstractControl = formGroupReference.get(checkboxValue);

      if (formControl) {
        formControl.setValue(true);
      }
    });
  }

  mapAndEmitMultiselectChanges(change: Record<string, Array<Item>>, filterChange: EventEmitter<ReportFilterChange>) {
    Object.keys(change).forEach(key => {
      const filterName = `${key}`;

      filterChange.emit({
        filterName: filterName as ReportFilterName,
        payload: {
          [filterName]: change[key]?.map((item: Item) => item.id) || []
        }
      });
    });
  }
}
