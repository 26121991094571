import { Component } from '@angular/core';
import { BaseReadonlyViewComponent } from '@shared/modules/readonly-commons/utils/base-readonly-view.component';
import { dictionaryFromMapProvider } from '@shared/services/dictionary-provider';
import { orthoStaticDictionariesProvider } from '@src/app/features/ortho/components/ortho-readonly-assessment/ortho-readonly-assessment.component';
import { MediaSet } from '@shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { MidAssessment } from '@src/app/features/ortho/models/mid-assessment.model';
import { OrthoService } from '@src/app/features/ortho/services/ortho.service';

const inProgressPhotoLabelsInOrder = [
  { labels: [], parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_1 },
  { labels: [], parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_2 },
  { labels: [], parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_3 },
  { labels: [], parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_4 },
  { labels: [], parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_5 },
  { labels: [], parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_6 },
  { labels: [], parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_7 },
  { labels: [], parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_8 },
  { labels: [], parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_9 }
];

@Component({
  selector: 'stx-ortho-mid-assessment-readonly',
  templateUrl: './ortho-mid-assessment-readonly.component.html',
  providers: [dictionaryFromMapProvider(OrthoService, orthoStaticDictionariesProvider)]
})
export class OrthoMidAssessmentReadonlyComponent extends BaseReadonlyViewComponent<MidAssessment> {
  photoSets: MediaSet[] = [];

  protected afterTreatmentSet(): void {
    this.photoSets = [
      {
        sectionLabelKey: 'ortho.in_progress_photos',
        mediaWithLabels: this.getPhotosWithLabels(inProgressPhotoLabelsInOrder)
      }
    ];
  }
}
