<div [class]="wrapperClass">
  <div [ngClass]="{ 'relative-to-parent': relativeToParent }" class="overlay" *ngIf="showSpinner">
    <div [ngClass]="{ 'unset-top-margin': relativeToParent }" class="spinner-wrapper">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>
  <div [ngClass]="showSpinner ? 'withBlur' : 'withoutBlur'">
    <div class="loaded-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
