import { PatientService } from '@/src/app/features/patient/patient.service';
import { Borescope } from '@/src/app/features/surgical/models/borescope.model';
import { SurgicalService } from '@/src/app/features/surgical/surgical.service';
import { BaseTreatmentFormComponent } from '@/src/app/shared/components/base-treatment-form/base-treatment-form.component';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { resetFormGroupValidationState } from '@/src/app/shared/validation/validation.utils';
import { StxValidators } from '@/src/app/shared/validation/validators';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGuardService } from '@shared/services/form-guard.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { SpinnerService } from 'src/app/shared/components/spinner/service/spinner.service';
import { ParentOrderName } from 'src/app/shared/enums/parent-order-name.enum';
import { GlobalErrorHandlerService } from 'src/app/shared/services/global-error-handler.service';
import { FormMediaUtils } from 'src/app/utils/form-media.utils';
import { FormMode } from 'src/app/utils/form.utils';
import { borescopeRoute, surgicalRoute } from 'src/app/utils/routing.utils';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Component({
  selector: 'stx-borescope',
  templateUrl: './borescope.component.html',
  styleUrls: ['./borescope.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BorescopeComponent extends BaseTreatmentFormComponent<Borescope> implements OnInit {
  readonly formType = FormType.BORESCOPE;
  readonly parentOrderNamesToHandle = [
    ParentOrderName.BORESCOPE_IDENTIFICATION,
    ParentOrderName.BORESCOPE_UNREPAIRED,
    ParentOrderName.BORESCOPE_REPAIRED,
    ParentOrderName.BORESCOPE_VIDEOS,
    ParentOrderName.BORESCOPE_NASAL_LINING,
    ParentOrderName.BORESCOPE_ORAL_LINING
  ];

  borescopeFormGroup: UntypedFormGroup;
  patientId: number;

  @Input() borescope: Borescope;

  get scanDate(): moment.Moment {
    return this.borescope?.scanDate;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    spinnerService: SpinnerService,
    private surgicalService: SurgicalService,
    activatedRoute: ActivatedRoute,
    router: Router,
    globalErrorHandlerService: GlobalErrorHandlerService,
    private formGuardService: FormGuardService,
    changeDetector: ChangeDetectorRef,
    snackBarService: SnackBarService,
    elementRef: ElementRef,
    zone: NgZone,
    wsHelper: WsHelperService,
    patientService: PatientService
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      changeDetector,
      globalErrorHandlerService,
      wsHelper,
      patientService
    );
    this.formType = FormType.BORESCOPE;
    this.treatmentType = TreatmentType.BORESCOPE;
  }

  ngOnInit() {
    this.initBorescopeForm();
    this.getQueryParams();
    this.fillInEditMode();
  }

  private initBorescopeForm(): void {
    this.borescopeFormGroup = this.formBuilder.group({
      surgeryId: [null, StxValidators.required],
      notes: [],
      [ParentOrderName.BORESCOPE_IDENTIFICATION]: [null, StxValidators.required],
      [ParentOrderName.BORESCOPE_UNREPAIRED]: [null, StxValidators.required],
      [ParentOrderName.BORESCOPE_REPAIRED]: [null, StxValidators.required],
      [ParentOrderName.BORESCOPE_VIDEOS]: [null, StxValidators.required],
      [ParentOrderName.BORESCOPE_NASAL_LINING]: [null, StxValidators.required],
      [ParentOrderName.BORESCOPE_ORAL_LINING]: [null, StxValidators.required]
    });
  }

  onSaveButtonClicked(): AsyncButtonClickAction {
    return () => {
      resetFormGroupValidationState(this.borescopeFormGroup);
      return this.onSaveValidationSuccess();
    };
  }
  onSubmitButtonClicked(): AsyncButtonClickAction {
    return () => {
      resetFormGroupValidationState(this.borescopeFormGroup);
      this.borescopeFormGroup.markAllAsTouched();
      if (this.borescopeFormGroup.valid) {
        return this.onSubmitValidationSuccess();
      } else {
        return of(null);
      }
    };
  }

  private getQueryParams(): void {
    this.subSink.sink = this.activatedRoute.queryParams.subscribe(params => {
      this.patientId = params.patientId;
      if (params.surgeryId) {
        this.borescopeFormGroup.get('surgeryId').setValue(params.surgeryId);
      }
    });
  }

  protected getTreatmentDataToSave(): Borescope {
    return {
      ...this.borescopeFormGroup.value,
      id: this.borescope ? this.borescope.id : null,
      patientId: this.patientId,
      newFiles: FormMediaUtils.extractNewMediaFromFormGroup([this.borescopeFormGroup], this.parentOrderNamesToHandle)
    };
  }

  private fillInEditMode() {
    if (this.formMode !== FormMode.NEW) {
      if (!this.borescope) {
        this.subSink.sink = this.activatedRoute.params.subscribe(params => {
          this.wsHelper
            .callWithSpinner(this.surgicalService.getBorescope(params.id), { redirectOn404StatusCode: true })
            .subscribe(borescope => {
              this.formGuardService.redirectTreatmentFormToCorrectModeIfRequired(borescope, this.formMode, this.activatedRoute.snapshot);
              this.setTreatmentData(borescope);
            });
        });
      } else {
        this.setTreatmentData(this.borescope);
      }
    }
  }
  protected callDelete(id: number): Observable<void> {
    return this.surgicalService.deleteBorescope(id);
  }

  protected callSave(data: Borescope): Observable<Borescope> {
    return this.surgicalService.saveBorescope(data);
  }

  protected callSubmit(data: Borescope): Observable<Borescope> {
    return this.surgicalService.submitBorescope(data);
  }

  protected callUnlock(id: number): Observable<void> {
    return this.surgicalService.unlockBorescope(id);
  }

  protected getEditRoute(treatmentId: number): string {
    return `${surgicalRoute}/${borescopeRoute}/edit/${this.borescope.id}`;
  }

  protected getPatientId(): number {
    return this.patientId;
  }

  protected getTreatmentId(): TreatmentId {
    return this.borescope.id;
  }

  protected getViewRoute(treatmentId: number): string {
    return `${surgicalRoute}/${borescopeRoute}/${treatmentId}`;
  }

  protected setTreatmentData(data: Borescope): void {
    this.borescope = data;
    this.borescopeFormGroup.patchValue(data);
    this.borescopeFormGroup.patchValue(FormMediaUtils.getMediaForFormGroup(this.borescope, this.parentOrderNamesToHandle));
  }
}
