import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { expandableStaticFileRegistry } from '@/src/app/shared/modules/static-files/static-files.utils';
import { Component } from '@angular/core';
import { ResourceSection } from '@src/app/features/educational-resources/models/resource.model';
import { feedingBrochure } from '@src/app/features/educational-resources/components/resources-for-families-page/resources-for-families-page.component';
import { nutritionResourcesFullRoute } from '@utils/routing.utils';

@Component({
  selector: 'stx-dashboard-announcements-panel',
  templateUrl: './dashboard-announcements-panel.component.html',
  styleUrls: ['../dashboard-page/dashboard-page.component.scss']
})
export class DashboardAnnouncementsPanelComponent {
  readonly partnerSurveysDisplayPermission = PermissionEnum.GET_PARTNER_SURVEYS;
  readonly expandableFilesRegistry = expandableStaticFileRegistry;
  readonly nutritionResourcesRoute = nutritionResourcesFullRoute;
}
