<mat-toolbar class="toolbar">
  <mat-toolbar-row class="full-height">
    <a [routerLink]="routerLink" class="m-r-24 flex-center align-center align-middle">
      <img src="../../../../../assets/images/smiletrain-logo-text.png" class="st-logo" alt="Smile Train Logo" />
    </a>
    <ng-container *ngIf="hasActiveProfile">
      <div class="full-height flex-row-container left-section-container">
        <a
          *ngFor="let navItem of navItems"
          mat-menu-item
          class="menu-button nav-item p-l-10 p-r-10"
          routerLinkActive="active"
          routerLink="{{ navItem.href }}"
        >
          {{ navItem.text | translate }}
        </a>
      </div>
      <div class="flex-row-container right-section-container">
        <div>
          <button
            data-testid="button-save-patient"
            mat-flat-button
            class="action-button"
            *stxPermitted="PermissionEnum.SAVE_PATIENT"
            [matTooltip]="'menu.register_new_patient' | translate"
            [matTooltipDisabled]="isToolTipDisabled"
            [routerLink]="newPatientRoute"
            (mouseover)="updateToolTipVisibility()"
          >
            <span class="flex-center">
              <mat-icon class="m-r-5 color-link">person_add</mat-icon>
              <span class="tooltip-span-element color-link" #tooltipSpanElement>{{ 'menu.register_new_patient' | translate }}</span>
            </span>
          </button>
          <button
            data-testid="button-upload-offline-record"
            mat-flat-button
            class="action-button"
            *stxPermitted="PermissionEnum.UPLOAD_OFFLINE_APP_FILE"
            [matTooltip]="'menu.upload_offline_record' | translate"
            [matTooltipDisabled]="isToolTipDisabled"
            [routerLink]="uploadOfflineRecordRoute"
            (mouseover)="updateToolTipVisibility()"
          >
            <span class="flex-center">
              <mat-icon class="m-r-5 material-icons-outlined color-link">file_upload</mat-icon>
              <span class="tooltip-span-element color-link" #tooltipSpanElement>{{ 'menu.upload_offline_record' | translate }}</span>
            </span>
          </button>
        </div>
        <div class="profile-menu">
          <button
            data-testid="button-nav"
            mat-flat-button
            class="profile-menu-button"
            [matMenuTriggerFor]="menu"
            (menuOpened)="isAccountMenuOpened = true"
            (menuClosed)="isAccountMenuOpened = false"
          >
            <mat-menu #menu="matMenu">
              <label mat-menu-item class="menu-item menu-label text-lowercase">
                {{ userEmail }}
              </label>
              <label mat-menu-item class="menu-item menu-label">
                {{ profileName }}
              </label>
              <mat-divider class="mat-divider-margin"></mat-divider>
              <ng-container *ngIf="canSeeEditSettingsRoute">
                <button
                  data-testid="button-edit-settings"
                  mat-menu-item
                  [routerLink]="'settings'"
                  class="menu-item menu-button text-uppercase"
                >
                  {{ 'menu.edit_settings' | translate }}
                </button>
              </ng-container>
              <ng-container *ngIf="canChangeProfile">
                <button
                  data-testid="button-switch-profile"
                  mat-menu-item
                  [routerLink]="'select-profile'"
                  class="menu-item menu-button text-uppercase"
                >
                  {{ 'menu.switch_profile' | translate }}
                </button>
              </ng-container>
              <div *stxPermitted="PermissionEnum.GET_TEMP_LANG_CHANGE">
                <stx-language-temp></stx-language-temp>
              </div>
              <button data-testid="button-logout" mat-menu-item (click)="logout()" class="menu-item menu-button text-uppercase">
                {{ 'logout' | translate }}
              </button>
            </mat-menu>
            {{ 'menu.your_account' | translate }}
            <mat-icon inline="true" class="arrow-down">{{ isAccountMenuOpened ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>
