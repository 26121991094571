import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReportDownloaderComponentModule } from '../../components/report-downloader/report-downloader.component';
import { ReportConfig, ReportDownloadTypes } from '../../components/report-filters/models/report-filters.model';
import { ReportFiltersModule } from '../../components/report-filters/report-filters.component';
import { ReportTableDataService } from '../../components/report-filters/services/report-table-data.service';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import { OrthognathicTreatmentReportTableComponentModule } from './components/orthognathic-treatment-report-table/orthognathic-treatment-report-table.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';

@Component({
  selector: 'stx-orthognathic-treatments-report',
  templateUrl: './orthognathic-treatments-report.component.html'
})
export class OrthognathicTreatmentsReportComponent {
  readonly reportConfig: ReportConfig = {
    type: 'ORTHOGNATHIC_TREATMENTS',
    downloadOptions: {
      [ReportDownloadTypes.EXCEL]: true
    },
    filters: {
      age: true,
      gender: true,
      geography: true,
      organizationsTree: true,
      recordDate: true
    }
  };

  constructor(public reportService: ReportService, public reportTableDataService: ReportTableDataService) {}

  applyFilters(pagination: PaginationSortingModel) {
    this.reportTableDataService.prepareList('ORTHOGNATHIC_TREATMENTS', this.reportService.model, pagination);
  }
}

@NgModule({
  declarations: [OrthognathicTreatmentsReportComponent],
  imports: [
    ReportFiltersModule,
    OrthognathicTreatmentReportTableComponentModule,
    CommonModule,
    TranslateModule,
    ReportDownloaderComponentModule,
    ReportHeaderComponentModule,
    GeneralCommonsModule
  ],
  exports: [OrthognathicTreatmentsReportComponent]
})
export class OrthognathicTreatmentsReportModule {}
