import { Component, Input, NgModule } from '@angular/core';

/**
 * @deprecated To be removed once not used
 */
@Component({
  selector: 'stx-form-label',
  templateUrl: './form-label.component.html',
  styleUrls: ['./form-label.component.scss']
})
export class FormLabelComponent {
  @Input() labelFor: string;
}

/**
 * @deprecated To be removed once not used
 */
@NgModule({
  declarations: [FormLabelComponent],
  exports: [FormLabelComponent]
})
export class FormLabelModule {}
