import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from 'src/app/shared/services/http/base-http.service';
import { Observable } from 'rxjs';
import { OrgTreeFilterModel } from './models/org-tree-filter.model';
import { shareReplay } from 'rxjs/operators';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';
import { SubSink } from 'subsink';
import { User } from '@shared/models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService extends BaseHttpService implements OnDestroy {
  private orgTreeFiltersWithAffiliatedPartners: Observable<OrgTreeFilterModel>;
  private orgTreeFiltersWithoutAffiliatedPartners: Observable<OrgTreeFilterModel>;
  private subSink = new SubSink();

  constructor(httpClient: HttpClient, private readonly currentUserService: CurrentUserService) {
    super(httpClient, { baseUrl: 'orgtree' });
    this.subSink.sink = this.currentUserService.getUserSubject().asObservable().subscribe(this.reloadOnLoggedOutUser());
  }

  private reloadOnLoggedOutUser(): (user: User) => void {
    return (user: User) => {
      if (!!user && !!user.activeProfile) this.reload();
    };
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  getOrgTreeFilters(includeAffiliated?: boolean): Observable<OrgTreeFilterModel> {
    return includeAffiliated ? this.getFiltersWithAffiliatedPartners() : this.getFiltersWithoutAffiliatedPartners();
  }

  reload(): void {
    this.orgTreeFiltersWithAffiliatedPartners = null;
    this.orgTreeFiltersWithoutAffiliatedPartners = null;
    this.getFiltersWithoutAffiliatedPartners().subscribe(); // invokes preloading
  }

  private getFiltersWithAffiliatedPartners(): Observable<OrgTreeFilterModel> {
    if (this.orgTreeFiltersWithAffiliatedPartners == null) {
      this.orgTreeFiltersWithAffiliatedPartners = this.queryFilters(true);
    }
    return this.orgTreeFiltersWithAffiliatedPartners;
  }

  private getFiltersWithoutAffiliatedPartners(): Observable<OrgTreeFilterModel> {
    if (this.orgTreeFiltersWithoutAffiliatedPartners == null) {
      this.orgTreeFiltersWithoutAffiliatedPartners = this.queryFilters(false);
    }
    return this.orgTreeFiltersWithoutAffiliatedPartners;
  }

  private queryFilters(affiliatedPartners: boolean): Observable<OrgTreeFilterModel> {
    const params = { affiliatedPartners: affiliatedPartners ? 'true' : 'false' };
    return this.httpClient.get<OrgTreeFilterModel>(`${this.baseUrl}/filters`, { params }).pipe(shareReplay(1));
  }
}
