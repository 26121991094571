<div [formGroup]="itemForm" class="stx-form-field">
  <label class="form-element-label"> {{ labelKey | translate }}</label>
  <stx-chips
    *ngIf="showChips && selectedOptionAsChip"
    [itemsList]="[selectedOptionAsChip]"
    [ariaLabel]="selectedOptionAsChip.name"
    [removable]="true"
    (removeCallback)="resetSelection()"
  ></stx-chips>
  <mat-form-field appearance="outline" class="full-width form-element-label" *ngIf="isShown">
    <input
      data-testid="input-autocomplete-single-select"
      #textInput
      type="text"
      formControlName="autocompleteInput"
      matInput
      [matAutocomplete]="auto"
      (blur)="onTouched($event.target.value)"
    />
    <mat-icon matSuffix>expand_more</mat-icon>
  </mat-form-field>

  <!-- Duplicated from autocomplete-multi-select -->
  <mat-autocomplete #auto="matAutocomplete" (opened)="autocompleteOpened()">
    <cdk-virtual-scroll-viewport
      itemSize="{{ matOptionHeight }}"
      minBufferPx="{{ matOptionHeight * 10 }}"
      maxBufferPx="{{ matOptionHeight * 12 }}"
      class="virtual-scroll"
    >
      <ng-container *ngIf="hasBlankOption">
        <mat-option [value]="null" (click)="resetSelection()">{{ 'none_selected' | translate }}</mat-option>
      </ng-container>
      <mat-option *cdkVirtualFor="let item of filteredItems; trackBy: trackById" [value]="item.id" (click)="selectOption(item)">
        {{ item.name }}
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </mat-autocomplete>
</div>
