import { openImageViewer } from '@/src/app/features/image-viewer/image-viewer.utils';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageRotatedEvent } from '@shared/components/image-rotator/image-rotator.component';
import { MediaType } from '@shared/enums/media.enum';
import { MediaResource } from '@shared/models/media-resource.model';
import { MediaUrl } from '@src/app/features/media/models/media-url.model';

@Component({
  selector: 'stx-readonly-view-image',
  templateUrl: './readonly-view-image.component.html',
  styleUrls: ['./readonly-view-image.component.scss']
})
export class ReadonlyViewImageComponent {
  @Output() rotated: EventEmitter<any> = new EventEmitter();
  @Input() photo: MediaResource;
  @Input() showAltImage = false;
  @Input() altImageSize?: number;
  @Input() shouldDisablePhotoControls: boolean;

  private getUrl(mediaType: MediaType) {
    return this.getMediaUrl(mediaType)?.url;
  }

  private getMediaUrl(mediaType: MediaType): MediaUrl | null {
    return (this.photo?.urlList || []).find(url => url.urlType === mediaType && url.mediaFileAvailable);
  }

  get metadata(): MediaUrl {
    return this.photo?.urlList?.length > 0 ? this.photo.urlList[0] : null;
  }

  get rawUrl(): string {
    return this.getUrl(MediaType.RAW);
  }

  get thumbnailUrl(): string {
    return this.getUrl(MediaType.LOW_RES) || this.rawUrl;
  }

  get fullSizeUrl(): string {
    return this.getUrl(MediaType.HIGH_RES) || this.rawUrl;
  }

  openImageViewer(): void {
    openImageViewer(this.photo.urlList[0]?.id);
  }

  onImageRotated(event: ImageRotatedEvent) {
    this.rotated.emit(event);
  }
}
