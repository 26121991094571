<form [formGroup]="formGroup">
  <div class="medical-form-container gap-30">
    <div class="custom-flex-50">
      <div class="form-row">
        <stx-radio-group
          controlName="category"
          label="resources.education_and_research.event_type"
          layoutDirection="row"
          [options]="categoryRadioOptions"
        ></stx-radio-group>
      </div>
      <div class="form-row">
        <stx-input controlName="eventName" type="text" label="resources.education_and_research.event_name"></stx-input>
      </div>
      <div class="form-row">
        <stx-input controlName="eventLink" type="text" label="resources.education_and_research.event_link"></stx-input>
      </div>
      <div class="form-row">
        <stx-textarea controlName="eventBlurb" label="resources.education_and_research.event_description" [isOptional]="true">
        </stx-textarea>
      </div>
      <stx-submit-button [asyncAction]="submitMedicalMeeting">{{ 'submit' | translate }}</stx-submit-button>
    </div>
    <div class="custom-flex-30">
      <div class="form-row">
        <stx-date label="start_date" controlName="eventStart"></stx-date>
      </div>
      <div class="form-row">
        <stx-date
          label="end_date"
          controlName="eventEnd"
          [isOptional]="true"
          [minDate]="formGroup?.get('eventStart')?.value ? formGroup.get('eventStart').value : today"
        ></stx-date>
      </div>
      <div class="form-row">
        <stx-input controlName="eventTime" type="text" label="resources.education_and_research.event_time" [isOptional]="true"></stx-input>
      </div>
      <div class="form-row">
        <stx-date
          label="resources.education_and_research.abstract_due_form_label"
          controlName="eventAbstractDue"
          [isOptional]="true"
          [minDate]="today"
        ></stx-date>
      </div>
      <div class="form-row">
        <stx-input
          controlName="eventLocation"
          type="text"
          label="resources.education_and_research.event_location"
          [isOptional]="true"
        ></stx-input>
      </div>
    </div>
  </div>
</form>
