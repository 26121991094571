<div class="p-15 fs-15 normal patient-details-container flex-row-container gap-5" [ngClass]="{ 'has-dark-background': hasDarkBackground }">
  <div class="break-word wrap-container">
    <div>
      {{ patient.recordNumberId }}
    </div>

    <div>{{ patient.recordNumberIdLoc }}</div>
  </div>
  <div class="wrap-container">
    <div class="patient-information relative gap-5">
      <a (click)="$event.stopPropagation()" routerLink="{{ patientRoute }}" target="_blank">
        {{ patient.firstName }} {{ patient.middleName }} {{ patient.lastName }}
      </a>
      <fa-icon [icon]="faExternalLinkAlt" class="post-icon" size="sm"></fa-icon>
    </div>

    <div *ngIf="patient.lastNameLoc && patient.firstNameLoc">
      <a (click)="$event.stopPropagation()" routerLink="{{ patientRoute }}" target="_blank">
        {{ patient.lastNameLoc }} {{ patient.middleNameLoc }} {{ patient.firstNameLoc }}
      </a>
      <fa-icon [icon]="faExternalLinkAlt" class="post-icon" size="sm"></fa-icon>
    </div>
  </div>
  <div class="wrap-container">
    <div class="flex-row-container wrap patient-birth-container">
      <div class="m-r-20">{{ ReadonlyFormUtils.getRadioBtnValue(genders, patient.gender) | translate }}</div>
      <div>{{ patient.dateOfBirth | date : 'yyyy-MM-dd' }}</div>
    </div>
  </div>
  <div class="wrap-container" *ngIf="partnerRoute">
    <a [routerLink]="partnerRoute" target="_blank" (click)="$event.stopPropagation()">
      {{ partnerName }}
      <br />
      {{ partnerLocalName }}
    </a>
  </div>
  <div *ngIf="!partnerRoute" class="wrap-container">
    <div>{{ partnerName }}</div>

    <div>{{ partnerLocalName }}</div>
  </div>
  <div class="wrap-container">
    {{ treatmentCenterCountryName }}
  </div>
</div>
