import { BaseTreatmentFormComponent } from '@/src/app/shared/components/base-treatment-form/base-treatment-form.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormActionFlowControl } from '@shared/components/base-treatment-form/form-action-flow-control.models';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { FormType } from '@shared/enums/form-type.enum';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { FormGuardService } from '@shared/services/form-guard.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { atLeastOneRequired, requiredIfValidator, StxValidators } from '@shared/validation/validators';
import { NutritionType } from '@src/app/features/nutrition-management/enums/nutrition-type.enum';
import { PatientCareType } from '@src/app/features/nutrition-management/enums/patient-care-type.enum';
import { NutritionCheckboxItem, NutritionRadioItem } from '@src/app/features/nutrition-management/models/nutrition-item.model';
import {
  BaseNutritionManagement,
  InitialTypeOfPatientCare,
  InitialTypeOfPatientCareFormStructure,
  NutritionAssessment,
  NutritionFollowUp
} from '@src/app/features/nutrition-management/models/nutrition-management.model';
import { NutritionStageValidationData } from '@src/app/features/nutrition-management/models/nutrition-stage-validation-data.model';
import { BaseNutritionManagementService } from '@src/app/features/nutrition-management/services/base-nutrition-management.service';
import { NutritionDictionariesService } from '@src/app/features/nutrition-management/services/nutrition-dictionaries-service';
import {
  anemiaManagementFields,
  anemiaManagementOptions,
  DecimalPlaces,
  decimalPlacesToRegexPatternMap,
  feedingAssessmentOptions,
  feedingFields,
  nutritionalStatusFields,
  nutritionalStatusOptions,
  nutritionCarePlanFields,
  nutritionCarePlanOptions,
  NutritionStage,
  outpatientPatientCareFields,
  patternInputMatchingValidator,
  referralPatientCareFields
} from '@src/app/features/nutrition-management/utils/nutrition-management.utils';
import { PatientService } from '@src/app/features/patient/patient.service';
import { getNextDay } from '@utils/date.utils';
import { FormMediaUtils } from '@utils/form-media.utils';
import { FormMode } from '@utils/form.utils';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';
import { PatientId } from '@src/app/features/patient/models/patient.model';

export type NutritionFormModel = NutritionAssessment | NutritionFollowUp;

@Component({ template: '' })
export abstract class BaseNutritionFormComponent<T extends NutritionFormModel> extends BaseTreatmentFormComponent<T> {
  protected static nutritionFormTypeControlName = 'ageGroup';
  protected datesForValidation: NutritionStageValidationData | null = null;
  nutritionAgeGroupHeader: string;
  initialNutritionAgeGroup: NutritionType;
  newbornFeedingStatusOptions: NutritionRadioItem[];
  infantBirthweightOptions: NutritionRadioItem[];
  readonly ageGroups = NutritionType;

  @Input() nutritionManagement: BaseNutritionManagement;

  protected constructor(
    private readonly formService: BaseNutritionManagementService<T>,
    private readonly nutritionDictionariesService: NutritionDictionariesService,
    readonly nutritionStage: NutritionStage,
    protected readonly formBuilder: UntypedFormBuilder,
    protected formGuardService: FormGuardService,
    elementRef: ElementRef,
    zone: NgZone,
    snackBarService: SnackBarService,
    spinnerService: SpinnerService,
    router: Router,
    activatedRoute: ActivatedRoute,
    cd: ChangeDetectorRef,
    globalErrorHandlerService: GlobalErrorHandlerService,
    wsHelper: WsHelperService,
    patientService: PatientService
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      cd,
      globalErrorHandlerService,
      wsHelper,
      patientService
    );
  }

  get nutritionFormType(): NutritionType {
    return this.treatmentFormGroup.get(BaseNutritionFormComponent.nutritionFormTypeControlName).value;
  }

  protected initializeForm(): UntypedFormGroup {
    return this.formBuilder.group({
      treatmentCenterId: [null, StxValidators.required],
      practitionerId: [null, StxValidators.required],
      nutritionInfo: this.formBuilder.group({
        evaluationDate: [null, StxValidators.required],
        weight: [
          null,
          [
            StxValidators.required,
            Validators.min(0.01),
            Validators.max(200),
            patternInputMatchingValidator(decimalPlacesToRegexPatternMap.get(DecimalPlaces.TWO_DECIMAL_PLACES))
          ]
        ],
        height: [
          null,
          [
            StxValidators.required,
            Validators.min(0.1),
            Validators.max(200),
            patternInputMatchingValidator(decimalPlacesToRegexPatternMap.get(DecimalPlaces.ONE_DECIMAL_PLACE))
          ]
        ],
        hemoglobin: [
          null,
          [
            Validators.min(0.1),
            Validators.max(100),
            patternInputMatchingValidator(decimalPlacesToRegexPatternMap.get(DecimalPlaces.ONE_DECIMAL_PLACE))
          ]
        ],
        muac: [
          null,
          [
            Validators.min(0.1),
            Validators.max(100),
            patternInputMatchingValidator(decimalPlacesToRegexPatternMap.get(DecimalPlaces.ONE_DECIMAL_PLACE))
          ]
        ],
        headCircumference: [
          null,
          [
            Validators.min(0.1),
            Validators.max(100),
            patternInputMatchingValidator(decimalPlacesToRegexPatternMap.get(DecimalPlaces.ONE_DECIMAL_PLACE))
          ]
        ],
        tricepsSkinfold: [
          null,
          [
            Validators.min(0.1),
            Validators.max(100),
            patternInputMatchingValidator(decimalPlacesToRegexPatternMap.get(DecimalPlaces.ONE_DECIMAL_PLACE))
          ]
        ],
        subscapularSkinfold: [
          null,
          [
            Validators.min(0.1),
            Validators.max(100),
            patternInputMatchingValidator(decimalPlacesToRegexPatternMap.get(DecimalPlaces.ONE_DECIMAL_PLACE))
          ]
        ],
        [ParentOrderName.NUTRITION_MANAGEMENT.toString()]: []
      }),
      anemiaManagement: this.formBuilder.group(
        {
          noManagementNeeded: [],
          ironSupplement: [],
          ironBSource: [],
          ironBMvmSupplement: [],
          foodFunds: [],
          referral: [],
          other: [],
          otherMore: [
            null,
            [requiredIfValidator(() => this.formGroup.get('anemiaManagement').get('other').value), Validators.maxLength(2000)]
          ],
          none: []
        },
        {
          validators: [atLeastOneRequired(anemiaManagementFields, 'anemiaManagementFields')]
        }
      ),
      [BaseNutritionFormComponent.nutritionFormTypeControlName]: [],
      birthWeightAndFeedingInformation: this.formBuilder.group(
        {
          birthWeightStatus: [null, requiredIfValidator(() => this.nutritionFormType === NutritionType.INFANT)],
          newbornFeedingStatus: [null, requiredIfValidator(() => this.nutritionFormType === NutritionType.INFANT)],
          difficulty: [],
          otherComplications: [],
          difficultyBreast: [],
          noProblem: [],
          noBreastfeeding: [],
          notBiologicalMother: [],
          noComplementary: [],
          noTexture: [],
          other: [],
          otherMore: [
            null,
            [
              requiredIfValidator(() => this.formGroup.get('birthWeightAndFeedingInformation').get('other').value),
              Validators.maxLength(2000)
            ]
          ]
        },
        {
          validators: [atLeastOneRequired(feedingFields, 'feedingFields')]
        }
      ),
      initialTypeOfPatientCare: this.formBuilder.group(
        {
          patientCareType: [null, StxValidators.required],
          outpatientInChargeOfFollowup: [],
          outpatientAskedToReturn: [],
          outpatientFollowupNone: [],
          referralInChargeOfFollowup: [],
          referralAskedToReturn: [],
          referralFollowupNone: []
        },
        {
          validators: [
            atLeastOneRequired(outpatientPatientCareFields, 'outpatientPatientCareFields', 'patientCareType', PatientCareType.OUTPATIENT),
            atLeastOneRequired(referralPatientCareFields, 'referralPatientCareFields', 'patientCareType', PatientCareType.REFERRAL)
          ]
        }
      ),
      nutritionalStatus: this.formBuilder.group(
        {
          good: [],
          failingToThrive: [],
          wasted: [],
          moderatelyWasted: [],
          severelyWasted: [],
          stunted: [],
          moderatelyStunted: [],
          severelyStunted: [],
          underweight: [],
          edema: [],
          overweightRisk: [],
          overweight: [],
          obese: [],
          anemic: [],
          other: [],
          otherMore: [
            null,
            [requiredIfValidator(() => this.formGroup.get('nutritionalStatus').get('other').value), Validators.maxLength(2000)]
          ]
        },
        {
          validators: [atLeastOneRequired(nutritionalStatusFields, 'nutritionalStatusFields')]
        }
      ),
      nutritionCarePlan: this.formBuilder.group(
        {
          breastFeeding: [],
          adviceAnimalMilk: [],
          supportBreastmilkExpression: [],
          adviceBreastmilkTechniques: [],
          adviceSubstitutes: [],
          provisionSubstitutes: [],
          provisionFeedingTools: [],
          adviceComplementaryFeeding: [],
          foodRations: [],
          foodFunds: [],
          foodPrep: [],
          rutf: [],
          mvm: [],
          foodTherapeutic: [],
          other: [],
          otherMore: [
            null,
            [requiredIfValidator(() => this.formGroup.get('nutritionCarePlan').get('other').value), Validators.maxLength(2000)]
          ],
          none: []
        },
        {
          validators: [atLeastOneRequired(nutritionCarePlanFields, 'nutritionCarePlanFields')]
        }
      )
    });
  }

  protected setTreatmentData(data: T): void {
    this.nutritionManagement = data;
    this.nutritionManagement.generalNutritionInformation.height = this.parseFloatOrNull(
      this.nutritionManagement.generalNutritionInformation.height
    );
    this.nutritionManagement.generalNutritionInformation.weight = this.parseFloatOrNull(
      this.nutritionManagement.generalNutritionInformation.weight
    );

    if (!this.isReadonlyView) {
      this.treatmentFormGroup.patchValue({
        [BaseNutritionFormComponent.nutritionFormTypeControlName]: this.nutritionManagement.ageGroup,
        treatmentCenterId: this.nutritionManagement.treatmentCenterId,
        practitionerId: this.nutritionManagement.practitionerId,
        anemiaManagement: this.nutritionManagement.anemiaManagement ? this.nutritionManagement.anemiaManagement : {},
        birthWeightAndFeedingInformation: this.nutritionManagement.feedingInformation ? this.nutritionManagement.feedingInformation : {},
        initialTypeOfPatientCare: this.mapInitialTypeOfPatientCareToFormControlStructure(this.nutritionManagement.initialTypeOfPatientCare),
        nutritionalStatus: this.nutritionManagement.nutritionalStatus ? this.nutritionManagement.nutritionalStatus : {},
        nutritionCarePlan: this.nutritionManagement.nutritionCarePlan ? this.nutritionManagement.nutritionCarePlan : {},
        nutritionInfo: this.nutritionManagement.generalNutritionInformation
      });
      this.initialNutritionAgeGroup = this.nutritionManagement.ageGroup;
      this.treatmentFormGroup
        .get('nutritionInfo')
        .patchValue(FormMediaUtils.getMediaForFormGroup(this.nutritionManagement, [ParentOrderName.NUTRITION_MANAGEMENT]));
    }
  }

  private mapInitialTypeOfPatientCareToFormControlStructure(
    initialTypeOfPatientCare: InitialTypeOfPatientCare
  ): InitialTypeOfPatientCareFormStructure {
    if (!initialTypeOfPatientCare) {
      return {};
    } else {
      switch (initialTypeOfPatientCare.patientCareType) {
        case PatientCareType.OUTPATIENT:
          return {
            patientCareType: initialTypeOfPatientCare.patientCareType,
            outpatientInChargeOfFollowup: initialTypeOfPatientCare.inChargeOfFollowup,
            outpatientAskedToReturn: initialTypeOfPatientCare.askedToReturn,
            outpatientFollowupNone: initialTypeOfPatientCare.followupNone
          };
        case PatientCareType.REFERRAL:
          return {
            patientCareType: initialTypeOfPatientCare.patientCareType,
            referralInChargeOfFollowup: initialTypeOfPatientCare.inChargeOfFollowup,
            referralAskedToReturn: initialTypeOfPatientCare.askedToReturn,
            referralFollowupNone: initialTypeOfPatientCare.followupNone
          };
        case PatientCareType.INPATIENT:
          return {
            patientCareType: initialTypeOfPatientCare.patientCareType
          };
      }
    }
  }

  protected getTreatmentId(): TreatmentId {
    return this.nutritionManagement.id;
  }

  protected getPatientId(): PatientId {
    return this.patient.id;
  }

  protected getTreatmentDataToSave(): T {
    return {
      ...this.treatmentFormGroup.value,
      generalNutritionInformation: this.treatmentFormGroup.get('nutritionInfo').value,
      id: this.nutritionManagement ? this.nutritionManagement.id : null,
      feedingInformation: this.treatmentFormGroup.get('birthWeightAndFeedingInformation').value,
      initialTypeOfPatientCare: this.extractTypeOfCareControlValues(),
      patientId: this.patient.id,
      newFiles: FormMediaUtils.extractNewMediaFromFormGroup(
        [this.treatmentFormGroup.get('nutritionInfo') as UntypedFormGroup],
        [ParentOrderName.NUTRITION_MANAGEMENT]
      )
    };
  }

  private extractTypeOfCareControlValues(): InitialTypeOfPatientCare {
    const initialTypeOfPatientCareGroup = this.treatmentFormGroup.get('initialTypeOfPatientCare');
    const patientCareType = initialTypeOfPatientCareGroup.get('patientCareType').value;
    if (patientCareType == PatientCareType.OUTPATIENT) {
      return {
        patientCareType: patientCareType,
        inChargeOfFollowup: initialTypeOfPatientCareGroup.get('outpatientInChargeOfFollowup').value,
        askedToReturn: initialTypeOfPatientCareGroup.get('outpatientAskedToReturn').value,
        followupNone: initialTypeOfPatientCareGroup.get('outpatientFollowupNone').value
      };
    } else if (patientCareType == PatientCareType.REFERRAL) {
      return {
        patientCareType: patientCareType,
        inChargeOfFollowup: initialTypeOfPatientCareGroup.get('referralInChargeOfFollowup').value,
        askedToReturn: initialTypeOfPatientCareGroup.get('referralAskedToReturn').value,
        followupNone: initialTypeOfPatientCareGroup.get('referralFollowupNone').value
      };
    }
    return { askedToReturn: false, followupNone: false, inChargeOfFollowup: false, patientCareType: patientCareType };
  }

  private updateHeader(): void {
    if (!!this.nutritionFormType) {
      this.nutritionAgeGroupHeader = 'nutrition_management.' + this.nutritionFormType.toLowerCase();
    }
  }

  protected fillInEditMode(): void {
    if (this.formMode !== FormMode.NEW) {
      if (!this.nutritionManagement) {
        this.subSink.sink = this.activatedRoute.params
          .pipe(
            switchMap(params =>
              this.wsHelper.callWithSpinner(this.formService.getNutritionManagement(params.id), { redirectOn404StatusCode: true })
            )
          )
          .subscribe(nutritionManagement => {
            this.formGuardService.redirectTreatmentFormToCorrectModeIfRequired(
              nutritionManagement,
              this.formMode,
              this.activatedRoute.snapshot
            );
            this.setTreatmentData(nutritionManagement);
          });
      }
    }
    if (this.formMode !== FormMode.READONLY) {
      this.callFetchAdditionalDataForSetUp();
    }
  }

  updateFormType(newFormType: NutritionType): void {
    if (this.isReadonlyView) {
      return;
    }
    const nutritionFormTypeControl = this.treatmentFormGroup.get(BaseNutritionFormComponent.nutritionFormTypeControlName);
    if (nutritionFormTypeControl.value !== newFormType) {
      this.cleanDataForNewAgeGroup(newFormType);
    }
    nutritionFormTypeControl.setValue(newFormType);
    this.updateHeader();
    if (newFormType === NutritionType.INFANT) this.loadStaticDictionaries();
    this.changeDetector.detectChanges();
  }

  private cleanDataForNewAgeGroup(newAgeGroup: NutritionType) {
    this.resetInvisibleFields(anemiaManagementOptions, 'anemiaManagement', newAgeGroup);
    this.resetInvisibleFields(nutritionalStatusOptions, 'nutritionalStatus', newAgeGroup);
    this.resetInvisibleFields(nutritionCarePlanOptions, 'nutritionCarePlan', newAgeGroup);
    this.resetBirthWeightStatusAndNewbornFeedingStatusData(newAgeGroup);
    this.resetFeedingAssessment(newAgeGroup);
  }

  private resetBirthWeightStatusAndNewbornFeedingStatusData(newAgeGroup: NutritionType) {
    if (newAgeGroup != NutritionType.INFANT) {
      this.treatmentFormGroup.get('birthWeightAndFeedingInformation').get('birthWeightStatus').reset();
      this.treatmentFormGroup.get('birthWeightAndFeedingInformation').get('newbornFeedingStatus').reset();
    }
  }

  private resetInvisibleFields(checkboxGroup: NutritionCheckboxItem[], subFormName: string, newAgeGroup: NutritionType) {
    checkboxGroup.forEach(checkbox => {
      const isVisible =
        checkboxGroup
          .filter(checkboxItem => checkboxItem.name === checkbox.name)
          .filter(checkboxItem => checkboxItem.showFor.includes(newAgeGroup)).length !== 0;
      if (!isVisible) {
        this.treatmentFormGroup.get(subFormName).get(checkbox.name).reset();
      }
    });
  }

  private resetFeedingAssessment(newAgeGroup: NutritionType) {
    const optionsToShow = feedingAssessmentOptions[newAgeGroup].reduce((accumulator, current) => {
      current.forEach(item => accumulator.push(item.name));
      return accumulator;
    }, new Array<string>());

    feedingFields.forEach(field => {
      if (!optionsToShow.includes(field)) {
        this.treatmentFormGroup.get('birthWeightAndFeedingInformation').get(field).reset();
      }
    });
  }

  private loadStaticDictionaries(): void {
    this.subSink.sink = this.wsHelper.callWithSpinner(this.nutritionDictionariesService.getStaticDictionaries()).subscribe(dictionaries => {
      this.newbornFeedingStatusOptions = dictionaries.get('newbornFeedingStatus');
      this.infantBirthweightOptions = dictionaries.get('birthWeightStatus');
      this.changeDetector.detectChanges();
    });
  }

  protected callDelete(id: number): Observable<void> {
    return this.formService.deleteNutritionManagement(id);
  }

  protected callSave(data: T): Observable<T> {
    return this.formService.saveNutritionManagement(data);
  }

  protected callSubmit(data: T): Observable<T> {
    return this.formService.submitNutritionManagement(data);
  }

  protected callUnlock(id: number): Observable<void> {
    return this.formService.unlockNutritionManagement(id);
  }

  protected beforeValidationAsync(): Observable<FormActionFlowControl> {
    return this.fetchAdditionalDataForSetUp();
  }

  get assessmentMinDate(): moment.Moment {
    return this.patient.dateOfBirth;
  }

  get assessmentMaxDate(): moment.Moment {
    return this.datesForValidation?.earliestFollowUpDate ?? this.today;
  }

  get followUpMinDate(): moment.Moment {
    return getNextDay(this.datesForValidation?.assessmentMinDate ?? this.patient.dateOfBirth);
  }

  private callFetchAdditionalDataForSetUp(): void {
    this.subSink.sink = this.wsHelper
      .call(this.fetchAdditionalDataForSetUp(), {
        handleErrorCallback: BaseNutritionFormComponent.handleGetValidationDataError
      })
      .subscribe();
  }

  private fetchAdditionalDataForSetUp(): Observable<FormActionFlowControl> {
    if (this.formType == FormType.NUTRITION_ASSESSMENT && !this.nutritionManagement?.id) {
      return of({ proceed: true });
    }
    return this.formService.getValidationData(this.patient.id).pipe(
      tap(validationData => {
        if (validationData) {
          this.datesForValidation = validationData;
          this.changeDetector.markForCheck();
        }
      }),
      map(validationData => (!!validationData ? { proceed: true } : { proceed: false }))
    );
  }

  private static handleGetValidationDataError = (error: HttpErrorResponse, errorHandlerService: GlobalErrorHandlerService) => {
    if (GlobalErrorHandlerService.isResourceNotFoundError(error)) {
      errorHandlerService.handleErrorAndInformUserWithAlertDialog(error, {
        title: 'error.treatment_action',
        message: error?.error?.translationKey
      });
    } else {
      errorHandlerService.handleErrorAndInformUser(error);
    }
  };

  private parseFloatOrNull(value: number) {
    return value ? parseFloat(String(value)) : null;
  }
}
