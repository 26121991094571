import { ElementRef, NgZone } from '@angular/core';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import { getFirstInvalidField } from '@shared/validation/error.utils';

export function scrollToTop(): void {
  const element = document.querySelector('body');
  if (element) {
    elementScrollIntoView(element, { behavior: 'smooth', block: 'start' });
  }
}

export function scrollToElement(selector: string): void {
  const element = document.querySelector(selector);
  if (element) {
    elementScrollIntoView(element, { behavior: 'smooth', block: 'start' });
  }
}

export function scrollToFirstInvalidField(el: ElementRef, zone: NgZone): void {
  zone.runOutsideAngular(() => {
    setTimeout(() => {
      const firstInvalidField = getFirstInvalidField(el.nativeElement);
      if (firstInvalidField) {
        elementScrollIntoView(firstInvalidField, {
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      } else {
        scrollToTop();
      }
    });
  });
}
