export enum QaEvaluatorReviewTypes {
  SURGICAL = 'surgical',
  ORTHODONTIC = 'orthodontic',
  SURGICAL_RELIABILITY = 'surgicalReliability'
}

export type QaEvaluatorReviewType = (typeof QaEvaluatorReviewTypes)[keyof typeof QaEvaluatorReviewTypes];

export interface SurgicalQaEvaluatorReviewsRecord {
  assigned: number;
  month: number;
  pending: number;
  reviewed: number;
  reviewerName: string;
  skipped: number;
  year: number;
}

export interface OrthodonticQaEvaluatorReviewsRecord {
  mixedReviewed: number;
  mixedSkipped: number;
  month: number;
  permanentReviewed: number;
  permanentSkipped: number;
  pisoReviewed: number;
  pisoSkipped: number;
  reviewed: number;
  reviewerName: string;
  skipped: number;
  year: number;
}

export interface SurgicalQaEvaluatorReliabilityReviewsRecord {
  assigned: number;
  month: number;
  originalReviewerName: string;
  pending: number;
  reviewed: number;
  secondReviewerName: string;
  skipped: number;
  year: number;
}

export type QaEvaluatorReviewsRecord =
  | SurgicalQaEvaluatorReviewsRecord
  | SurgicalQaEvaluatorReliabilityReviewsRecord
  | OrthodonticQaEvaluatorReviewsRecord;
