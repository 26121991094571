import { getCurrentMonth, getCurrentYear, getToday } from '@/src/app/utils/date.utils';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ReportPeriod, ReportPeriodGroup, ReportPeriodQuarter, ReportPeriodQuarterGroup } from '../models/report-filters.model';

@Injectable({
  providedIn: 'root'
})
export class ReportPeriodsService {
  private static YEARS_NUMBER_DEFAULT = 5;
  private static QUARTERS_IN_YEAR = 4;
  private static MONTHS_IN_YEAR = 12;
  private static API_QUARTER_SYMBOL = 'Q';
  private static API_MONTH_SYMBOL = 'M';
  private static MONTH_OF_JULY = 7;

  addToFormGroup(group: UntypedFormGroup, periods: ReportPeriod[]) {
    periods.forEach(period => {
      group.addControl(period.controlName, new UntypedFormControl(period.selected, []));
    });
  }

  getCalendarYears() {
    return this.generateYears(getCurrentYear());
  }

  getFiscalYears() {
    const currentYear = getCurrentYear();
    if (this.newFiscalYearAlreadyStarted()) {
      return this.generateYears(getCurrentYear() + 1);
    }
    return this.generateYears(currentYear);
  }

  newFiscalYearAlreadyStarted(): boolean {
    return getCurrentMonth() >= ReportPeriodsService.MONTH_OF_JULY;
  }

  getMonths(): ReportPeriod[] {
    const monthIndexOffset = 1;
    const numberOfMonths =
      ReportPeriodsService.YEARS_NUMBER_DEFAULT * ReportPeriodsService.MONTHS_IN_YEAR + ReportPeriodsService.MONTHS_IN_YEAR;
    const startDate = getToday().endOf('year');

    return Array.from(Array(numberOfMonths)).map((item, index) => {
      const date = startDate.clone().subtract(index, 'month');
      const month = date.month() + monthIndexOffset;
      const year = date.year().toString();
      const value = `${year}${ReportPeriodsService.API_MONTH_SYMBOL}${month}`;

      return {
        controlName: value,
        label: `date.month_names.short.${month}`,
        selected: false,
        unitValue: `${month}`,
        value,
        year
      };
    });
  }

  getQuarters(): ReportPeriod[] {
    const numberOfQuarters = ReportPeriodsService.YEARS_NUMBER_DEFAULT * ReportPeriodsService.QUARTERS_IN_YEAR;
    const currentYear = getCurrentYear();
    const startDate: moment.Moment = moment({
      year: currentYear,
      month: 11,
      day: 31
    });

    return Array.from(Array(numberOfQuarters)).map((item: void, index) => {
      const date = startDate.clone().subtract(index, 'quarters');
      const quarter = date.quarter();
      const year = date.year().toString();
      const value = `${year}${ReportPeriodsService.API_QUARTER_SYMBOL}${quarter}`;

      return {
        controlName: value,
        label: 'dates.quarter_symbol',
        selected: false,
        unitValue: `${quarter}`,
        value,
        year
      };
    });
  }

  groupByYear(periodsList: ReportPeriod[]): ReportPeriodGroup[] {
    const groupedByYears = periodsList.reduce((groupped, period) => {
      const periods = groupped.get(period.year) || [];

      groupped.set(period.year, [...periods, period]);

      return groupped;
    }, new Map<string, ReportPeriod[]>());

    return Array.from(groupedByYears.entries()).reduce((groupes, [year, periods]) => {
      groupes.push({
        year,
        periods
      });
      return groupes;
    }, []);
  }

  private generateYears(startFromYear: number): ReportPeriod[] {
    const numberOfYears = ReportPeriodsService.YEARS_NUMBER_DEFAULT;

    return Array.from(Array(numberOfYears)).map((item, index) => {
      const year = `${startFromYear - index}`;

      return {
        controlName: year,
        label: year.substring(2),
        selected: false,
        unitValue: year,
        value: year,
        year
      };
    });
  }

  groupMonthsByQuarter(months: ReportPeriodGroup[]): ReportPeriodQuarterGroup[] {
    return months.map(month => {
      const quarterOne: ReportPeriodQuarter = { quarterNumber: 1, months: [] };
      const quarterTwo: ReportPeriodQuarter = { quarterNumber: 2, months: [] };
      const quarterThree: ReportPeriodQuarter = { quarterNumber: 3, months: [] };
      const quarterFour: ReportPeriodQuarter = { quarterNumber: 4, months: [] };

      for (const period of month.periods) {
        const periodUnitValue = parseInt(period.unitValue, 10);
        if (periodUnitValue <= 3) {
          quarterOne.months.push(period);
        }
        if (periodUnitValue > 3 && periodUnitValue <= 6) {
          quarterTwo.months.push(period);
        }
        if (periodUnitValue > 6 && periodUnitValue <= 9) {
          quarterThree.months.push(period);
        }
        if (periodUnitValue > 9 && periodUnitValue <= 12) {
          quarterFour.months.push(period);
        }
      }

      return {
        year: month.year,
        quarters: [quarterOne, quarterTwo, quarterThree, quarterFour]
      } as ReportPeriodQuarterGroup;
    });
  }
}
