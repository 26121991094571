import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { BaseReportTableComponent } from '@src/app/features/reports/components/base-report-table/base-report-table.component';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { PatientLinkComponentModule } from '@src/app/features/reports/components/patient-link/patient-link.component';
import { ReportService } from '@src/app/features/reports/components/report-filters/services/report.service';
import { ReportTableDataService } from 'src/app/features/reports/components/report-filters/services/report-table-data.service';
import { ReportUtilsService } from '@src/app/features/reports/components/report-filters/services/report-utils.service';
import {
  SpeechTreatmentReportAvailableColumns,
  SpeechTreatmentReportHeaders,
  SpeechTreatmentReportViewColumns,
  SpeechTreatmentsReportRecord
} from '../../speech-treatments-report.model';
import { TableStructure } from '@shared/components/table/models/table-structure.model';
import { TreatmentDateComponentModule } from '@src/app/features/reports/components/treatment-date/treatment-date.component';
import { treatmentPaths } from '@utils/treatment.utils';
import { TreatmentStatusIndicatorComponentModule } from '@src/app/shared/components/treatment-status-indicator/treatment-status-indicator.component';
import { TreatmentType } from '@shared/components/treatment/treatment.enum';
import { SimpleReportCellComponentModule } from '@src/app/features/reports/components/simple-report-cell/simple-report-cell.component';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { FlexModule } from '@angular/flex-layout';
import { speechTreatmentReportRoute, speechTreatmentsReportRoute } from '@utils/routing.utils';
import { ReportType } from '@src/app/features/reports/components/report-filters/models/report-filters.model';
import { LocalNamePipeModule } from '@shared/pipes/local-name.pipe';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-speech-treatments-report-table',
  templateUrl: './speech-treatments-report-table.component.html'
})
export class SpeechTreatmentsReportTableComponent
  extends BaseReportTableComponent<SpeechTreatmentsReportRecord>
  implements OnInit, OnDestroy
{
  columns: SpeechTreatmentReportViewColumns = {};
  columnsList: SpeechTreatmentReportAvailableColumns[] = [];
  headers: Partial<SpeechTreatmentReportHeaders> = {};
  records: SpeechTreatmentsReportRecord[] = [];

  readonly speechTreatmentBaseUrl = `${treatmentPaths.get(TreatmentType.SPEECH_TREATMENT).path}/`;
  readonly speechAssessmentBaseUrl = `${treatmentPaths.get(TreatmentType.SPEECH_ASSESSMENT).path}/`;

  constructor(
    public changeDetector: ChangeDetectorRef,
    public reportTableDataService: ReportTableDataService,
    public reportService: ReportService,
    public route: ActivatedRoute,
    private reportUtilsService: ReportUtilsService,
    private router: Router
  ) {
    const currentReportRouteUrl: string = router.url;
    const speechReportType: ReportType = currentReportRouteUrl.includes(speechTreatmentReportRoute)
      ? 'SPEECH_TREATMENTS'
      : 'SPEECH_ASSESSMENTS';

    super(speechReportType, changeDetector, reportTableDataService, reportService, route);
  }

  ngOnInit(): void {
    super.onInit();

    this.watchTableStructure();
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  isReevaluateIn(recommendation: string): boolean {
    return recommendation.endsWith('recommend_reevaluate_in') || recommendation.endsWith('recommend_reeval');
  }

  private watchTableStructure() {
    this.subSink.sink = this.reportTableDataService.tableStructure$.subscribe(
      (tableStructure: TableStructure<SpeechTreatmentsReportRecord>) => {
        this.columnsList = this.reportUtilsService.getColumnsList(tableStructure.labels);
        this.columns = this.reportUtilsService.getColumnsNames(tableStructure.labels);
        this.headers = this.reportUtilsService.getPageHeaders({
          columns: tableStructure.labels
        });
        this.records = tableStructure.data.page.content || [];
        this.changeDetector.detectChanges();
      }
    );
  }
}

@NgModule({
  declarations: [SpeechTreatmentsReportTableComponent],
  imports: [
    MatTableModule,
    MatSortModule,
    TranslateModule,
    CommonModule,
    MatIconModule,
    MatPaginatorModule,
    PatientLinkComponentModule,
    RouterModule,
    TreatmentStatusIndicatorComponentModule,
    TreatmentDateComponentModule,
    EmptyTableStateComponentModule,
    SimpleReportCellComponentModule,
    GeneralCommonsModule,
    FlexModule,
    LocalNamePipeModule
  ],
  exports: [SpeechTreatmentsReportTableComponent]
})
export class SpeechTreatmentsReportTableComponentModule {}
