<div class="layout-columns-1-1-1">
  <p>
    <ng-container *ngIf="patientTreatmentForReview.treatmentDate; else unknownTreatmentDate">
      {{ patientTreatmentForReview.treatmentDate }}
    </ng-container>
    <ng-template #unknownTreatmentDate>{{ 'surgical_qa_panel.unknown_treatment_date' | translate }}</ng-template>
  </p>
  <p>
    <ng-container *ngIf="patientAge; else patientAgeUnknown">
      {{ patientAge }}
    </ng-container>
    <ng-template #patientAgeUnknown>
      {{ 'surgical_qa_panel.patient_age_is_unknown' | translate }}
    </ng-template>
  </p>
  <p>
    <ng-template [ngIf]="patientTreatmentForReview.surgicalInterventionTypes?.length" [ngIfElse]="unknownInterventionType">
      <span *ngFor="let surgicalInterventionType of patientTreatmentForReview.surgicalInterventionTypes">
        {{ surgicalInterventionType | translate }}
      </span>
    </ng-template>
    <ng-template #unknownInterventionType>
      {{ 'surgical_qa_panel.unknown_intervention_type' | translate }}
    </ng-template>
  </p>
</div>
