<stx-generic-page>
  <div class="flex-row-container float-right gap-20">
    <a [routerLink]="'.'" [queryParams]="{ range: QaReviewsReportRange.LAST_YEAR }">{{ 'reports.qa_reviewers.last_year' | translate }}</a>
    <a [routerLink]="'.'" [queryParams]="{ range: QaReviewsReportRange.LAST_TWO_YEARS }">{{
      'reports.qa_reviewers.last_two_years' | translate
    }}</a>
    <a [routerLink]="'.'" [queryParams]="{ range: QaReviewsReportRange.ALL }">{{ 'all' | translate }}</a>
  </div>
  <h1 tabindex="0" class="fs-28 m-b-50 flex-center">
    {{ 'reports.qa_reviewers.header' | translate }}
  </h1>
  <dl>
    <stx-qa-evaluator-reviews-section
      *stxPermitted="PermissionEnum.GET_REPORT_QA_EVALUATOR_REVIEWS_ORTHO"
      [headerText]="'reports.qa_reviewers.orthodontic' | translate"
      [initialyOpened]="reviewType === QaEvaluatorReviewTypes.ORTHODONTIC"
    >
      <stx-qa-evaluator-review-report-table
        [type]="QaEvaluatorReviewTypes.ORTHODONTIC"
        [tableStructure]="qaEvaluatorReviewsReportService.orthodonticReviewList$ | async"
        [dataReady]="qaEvaluatorReviewsReportService.orthodonticReviewDataReady"
      ></stx-qa-evaluator-review-report-table>
    </stx-qa-evaluator-reviews-section>

    <stx-qa-evaluator-reviews-section
      *stxPermitted="PermissionEnum.GET_REPORT_QA_EVALUATOR_REVIEWS_SURGERY"
      [headerText]="'reports.qa_reviewers.surgical' | translate"
      [initialyOpened]="reviewType === QaEvaluatorReviewTypes.SURGICAL"
    >
      <div class="disclaimer-container">{{ 'reports.qa_reviewers.surgical_disclosure' | translate }}</div>
      <stx-qa-evaluator-review-report-table
        [type]="QaEvaluatorReviewTypes.SURGICAL"
        [tableStructure]="qaEvaluatorReviewsReportService.surgicalReviewList$ | async"
        [dataReady]="qaEvaluatorReviewsReportService.surgicalReviewDataReady"
      ></stx-qa-evaluator-review-report-table>
      <ng-container *stxPermitted="PermissionEnum.GET_QA_SPEECH_REVIEWERS_EVALUATION_OLD_DATA">
        <a class="download-link" [href]="oldStatsUrl"
          >{{ 'reports.qa_reviewers.download_stats' | translate }} <mat-icon class="m-l-10" matSuffix>save_alt</mat-icon></a
        >
      </ng-container>
      <p class="fs-14 bold m-t-20 text-uppercase">{{ 'reports.qa_reviewers.surgical_reliability' | translate }}</p>
      <div class="disclaimer-container">{{ 'reports.qa_reviewers.surgical_trial_disclosure' | translate }}</div>
      <stx-qa-evaluator-review-report-table
        [type]="QaEvaluatorReviewTypes.SURGICAL_RELIABILITY"
        [tableStructure]="qaEvaluatorReviewsReportService.surgicalReliabilityReviewList$ | async"
        [dataReady]="qaEvaluatorReviewsReportService.surgicalReliabilityDataReady"
      ></stx-qa-evaluator-review-report-table>
      <div class="disclaimer-container">
        <div>{{ 'reports.qa_reviewers.surgical_trial_appendix_1' | translate }}</div>
        <div>{{ 'reports.qa_reviewers.surgical_trial_appendix_2' | translate }}</div>
      </div>
    </stx-qa-evaluator-reviews-section>
  </dl>
</stx-generic-page>
