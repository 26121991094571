import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { MediaType } from '@shared/enums/media.enum';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { DictionaryTranslation, MatchedPatient, Patient, PatientListItem } from '@src/app/features/patient/models/patient.model';
import { MatchType } from '@src/app/features/patient/patient.enum';
import { PatientService } from '@src/app/features/patient/patient.service';
import { getImageUrl } from '@utils/media.utils';
import { patientHistoryPath } from '@utils/routing.utils';
import { ImageRotatedEvent } from '../image-rotator/image-rotator.component';
import { PatientActionType } from '@shared/components/patient-details-actions/patient-action-type.enum';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  selector: 'stx-patient-matching',
  templateUrl: './patient-matching.component.html',
  styleUrls: ['./patient-matching.component.scss']
})
export class PatientMatchingComponent extends BaseComponent implements OnInit {
  readonly patientMatchingOptions: DictionaryTranslation[] = [
    { label: 'yes', value: MatchType.SAME },
    { label: 'no', value: MatchType.NOT_SAME },
    { label: 'dont_know', value: MatchType.DONT_KNOW }
  ];
  readonly MediaType = MediaType;
  @Input() mainPatientId: number;
  @Input() potentialMatches: PatientListItem[];
  @Output() refreshPotentialMatches = new EventEmitter();

  @ViewChildren('dynamicMarginElement') dynamicMarginElementQueryList: QueryList<ElementRef<HTMLDivElement>>;

  matchedPatientsFormGroup: UntypedFormGroup;
  potentialMatchPatients: Patient[];
  undefinedImageWidth: number = null;

  constructor(
    private fb: UntypedFormBuilder,
    private patientService: PatientService,
    private snackBarService: SnackBarService,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initPatientMatchingFormGroup();
  }

  printPatientRoute(patientId: number): string {
    return patientHistoryPath(patientId);
  }

  patientPhotoUrl(patient: PatientListItem, mediaType: MediaType): string {
    return getImageUrl(patient.mediaResources, ParentOrderName.PATIENT_PHOTO, mediaType);
  }

  patientPhotoId(patient: PatientListItem): string | null {
    return patient.mediaResources.find(mediaResource => mediaResource.parentOrderName === ParentOrderName.PATIENT_PHOTO)?.urlList[0]?.id;
  }

  initPatientMatchingFormGroup(): void {
    this.matchedPatientsFormGroup = this.fb.group({
      matchResult0: [],
      matchResult1: [],
      matchResult2: []
    });

    for (let index = 0; index < 3; ++index) {
      const control = this.matchedPatientsFormGroup.get('matchResult' + index) as UntypedFormControl;
      this.subSink.sink = control.valueChanges.subscribe(value => {
        this.matchPatients(value, control, index);
      });
    }
  }

  private matchPatients(value: MatchType, control: UntypedFormControl, index: number): void {
    if (value === null || control.disabled) {
      return;
    }
    const matchedPatient = {
      patientId1: this.mainPatientId,
      patientId2: this.potentialMatches[index].id,
      matchType: value
    } as MatchedPatient;
    this.wsHelperService.callWithSpinner(this.patientService.matchPatients(matchedPatient)).subscribe(() => {
      if (MatchType.SAME === value) {
        this.patientService.emitUpdatePatients(PatientActionType.MATCH);
      }
      this.snackBarService.notify('saved_successfully');
      control.disable();
    });
  }

  refreshMatchedPatients(): void {
    this.matchedPatientsFormGroup.reset(
      { matchResult0: null, matchResult1: null, matchResult2: null },
      { emitEvent: false, onlySelf: false }
    );
    this.matchedPatientsFormGroup.enable({ onlySelf: false, emitEvent: false });
    this.refreshPotentialMatches.emit();
  }

  adjustMargins(event: ImageRotatedEvent, index: number) {
    if (event?.originalHorizontal) {
      let newMargin = '';
      const elementToAdjust = this.dynamicMarginElementQueryList?.find((_, ix) => ix === index);
      if (elementToAdjust) {
        if (event.rotationCounter === 1 || event.rotationCounter === 3) {
          newMargin = `${event.containerMargin * 2}px`;
        }
        elementToAdjust.nativeElement.style.marginTop = newMargin;
      }
    }
  }
}
