import { OrthoGeneralAssessmentInfoComponent } from '@/src/app/features/ortho/components/shared/ortho-general-assessment-info/ortho-general-assessment-info.component';
import { OrthoSharedGeneralInfoComponent } from '@/src/app/features/ortho/components/shared/ortho-shared-general-info/ortho-shared-general-info.component';
import { PsioAssessment } from '@/src/app/features/ortho/models/psio-assessment.model';
import { PsioAssessmentService } from '@/src/app/features/ortho/services/psio-assessment.service';
import { BaseOrthoFormComponent } from '@/src/app/features/ortho/shared/base-ortho-form.component';
import { OrthoFormType } from '@/src/app/features/ortho/utils/ortho-form-type';
import { psioAssessmentAppRoute } from '@/src/app/features/ortho/utils/ortho-routes.consts';
import { OrthoStage } from '@/src/app/features/ortho/utils/ortho-stage';
import {
  presurgicalOrthopedicsAssessmentImagesConfigs,
  presurgicalOrthopedicsTypesFields,
  psioApplianceTypes,
  psioAssessmentParentOrderNames
} from '@/src/app/features/ortho/utils/presurgical.utils';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { SpinnerService } from '@/src/app/shared/components/spinner/service/spinner.service';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormSection } from '@/src/app/shared/enums/form-section.enum';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { FormGuardService } from '@/src/app/shared/services/form-guard.service';
import { GlobalErrorHandlerService } from '@/src/app/shared/services/global-error-handler.service';
import { SnackBarService } from '@/src/app/shared/services/snack-bar.service';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { atLeastOneRequired, selectionInvalidIf, StxValidators } from '@/src/app/shared/validation/validators';
import { FormMediaUtils } from '@/src/app/utils/form-media.utils';
import { FormMode, yesNoShort } from '@/src/app/utils/form.utils';
import { orthoRoute } from '@/src/app/utils/routing.utils';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';
import { PatientId } from '@src/app/features/patient/models/patient.model';

@Component({
  selector: 'stx-psio-assessment',
  templateUrl: './psio-assessment.component.html'
})
export class PsioAssessmentComponent extends BaseOrthoFormComponent<PsioAssessment> implements OnInit {
  readonly OrthoFormType = OrthoFormType;
  readonly FormSection = FormSection;
  readonly yesNo = yesNoShort;
  readonly psioApplianceTypes = psioApplianceTypes;
  readonly presurgicalOrthopedicsAssessmentImagesConfigs = presurgicalOrthopedicsAssessmentImagesConfigs;

  @ViewChild('generalAssessmentInfo') generalAssessmentInfo: OrthoGeneralAssessmentInfoComponent;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly orthoFormService: PsioAssessmentService,
    private readonly formGuardService: FormGuardService,
    spinnerService: SpinnerService,
    changeDetector: ChangeDetectorRef,
    globalErrorHandlerService: GlobalErrorHandlerService,
    router: Router,
    activatedRoute: ActivatedRoute,
    snackBarService: SnackBarService,
    elementRef: ElementRef,
    zone: NgZone,
    wsHelper: WsHelperService,
    patientService: PatientService
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      changeDetector,
      globalErrorHandlerService,
      wsHelper,
      patientService,
      orthoFormService
    );
    this.formType = FormType.ORTHO_PSIO_ASSESSMENT;
    this.treatmentType = TreatmentType.ORTHO_PSIO_ASSESSMENT;
  }

  ngOnInit(): void {
    this.configureForm(this.createTreatmentFormGroup(), {
      controlNamesForFullValidation: [
        OrthoSharedGeneralInfoComponent.getOrthoCareProvidedDate(OrthoStage.ASSESSMENT),
        OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName
      ]
    });
    if (!this.isReadonlyView) {
      this.getPatientTreatmentCenters();
    }
    this.fillInEditMode();
  }
  protected createTreatmentFormGroup(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        [OrthoSharedGeneralInfoComponent.getOrthoCareProvidedDate(OrthoStage.ASSESSMENT)]: [null, StxValidators.required],
        [OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName]: [null, StxValidators.required],
        [OrthoSharedGeneralInfoComponent.practitionerIdControlName]: [
          null,
          [
            StxValidators.required,
            selectionInvalidIf(
              () =>
                !!this.generalAssessmentInfo?.sharedGeneralInfo &&
                !!this.generalAssessmentInfo.sharedGeneralInfo.isSelectedPractitionerIdInvalid
            )
          ]
        ],
        infoGiven: [null, StxValidators.required],
        infoGivenMore: [],
        tapLip: [],
        tapStent: [],
        maxAppliance: [],
        nam: [],
        otherDevice: [],
        [ParentOrderName.ORTHO_FRONTAL_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_LEFT_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_RIGHT_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_BASAL_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_MAXILLARY_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_APPLIANCE_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_APPLIANCE_BABY_PRE]: [null, StxValidators.required]
      },
      {
        validators: [atLeastOneRequired(presurgicalOrthopedicsTypesFields, 'presurgicalOrthopedicsTypes')]
      }
    );
  }

  private fillInEditMode() {
    if (this.formMode === FormMode.NEW) {
      return;
    }

    if (this.orthoFormModel) {
      this.setTreatmentData(this.orthoFormModel);
      return;
    }

    this.subSink.sink = this.activatedRoute.params.subscribe(params => {
      this.wsHelper.callWithSpinner(this.orthoFormService.get(params.id), { redirectOn404StatusCode: true }).subscribe(psioAssessment => {
        this.formGuardService.redirectTreatmentFormToCorrectModeIfRequired(psioAssessment, this.formMode, this.activatedRoute.snapshot);
        this.setTreatmentData(psioAssessment);
      });
    });
  }

  protected getTreatmentDataToSave() {
    return {
      ...this.treatmentFormGroup.value,
      id: this.orthoFormModel ? this.orthoFormModel.id : null,
      patientId: this.patient.id,
      newFiles: FormMediaUtils.extractNewMediaFromFormGroup([this.treatmentFormGroup], psioAssessmentParentOrderNames)
    };
  }

  protected override beforeValidationSync(): void {
    this.generalAssessmentInfo.sharedGeneralInfo.checkPractitionerAndSurgeonValidity();
    super.beforeValidationSync();
  }

  protected callDelete(id: number): Observable<void> {
    return this.orthoFormService.delete(id);
  }

  protected callSave(data: PsioAssessment): Observable<PsioAssessment> {
    return this.orthoFormService.save(data);
  }

  protected callSubmit(data: PsioAssessment): Observable<PsioAssessment> {
    return this.orthoFormService.submit(data);
  }

  protected callUnlock(id: number): Observable<void> {
    return this.orthoFormService.unlock(id);
  }

  protected getEditRoute(treatmentId: number): string {
    return `${orthoRoute}/${psioAssessmentAppRoute}/edit/${treatmentId}`;
  }

  protected getPatientId(): PatientId {
    return this.patient.id;
  }

  protected getTreatmentId(): TreatmentId {
    return this.orthoFormModel.id;
  }

  protected getViewRoute(treatmentId: number): string {
    return `${orthoRoute}/${psioAssessmentAppRoute}/${treatmentId}`;
  }

  protected setTreatmentData(data: PsioAssessment): void {
    this.orthoFormModel = data;
    this.treatmentFormGroup.patchValue(this.orthoFormModel);
    this.treatmentFormGroup.patchValue(FormMediaUtils.getMediaForFormGroup(this.orthoFormModel, psioAssessmentParentOrderNames));
    if (this.formMode !== FormMode.READONLY) {
      this.callFetchAdditionalDataForSetUp();
    }
  }
}
