import { Component, Input } from '@angular/core';
import { NutritionStage } from '@src/app/features/nutrition-management/utils/nutrition-management.utils';
import { RadioGroupOptions } from '@shared/models/form.model';
import { BaseComponent } from '@shared/components/base-component/base.component';

@Component({
  selector: 'stx-nutrition-stage-indicator',
  templateUrl: './nutrition-stage-indicator.component.html',
  styleUrls: ['./nutrition-stage-indicator.component.scss']
})
export class NutritionStageIndicatorComponent extends BaseComponent {
  @Input() stage: NutritionStage;
  @Input() print: boolean;

  readonly stageOptions: RadioGroupOptions<NutritionStage> = [
    {
      label: 'nutrition_management.stage.assessment',
      value: NutritionStage.ASSESSMENT
    },
    {
      label: 'nutrition_management.stage.follow-up',
      value: NutritionStage.FOLLOW_UP
    }
  ];
}
