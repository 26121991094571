import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localName'
})
export class LocalNamePipe implements PipeTransform {
  transform(name: string, nameLoc: string | null): string {
    return nameLoc ? `${name} (${nameLoc})` : name;
  }
}

@NgModule({
  declarations: [LocalNamePipe],
  exports: [LocalNamePipe]
})
export class LocalNamePipeModule {}
