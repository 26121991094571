import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FormModule } from '@shared/components/form/form.module';
import { UntypedFormGroup } from '@angular/forms';
import { CheckboxOption, ReportFilterChange, ReportFilterName, ReportFiltersParams } from '../../models/report-filters.model';
import { ReportFiltersHelperService } from '../../services/report-filters-helper.service';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ReportDictionariesService } from '../../services/report-dictionaries.service';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

interface QualityAssuranceOptions {
  surgicalIntervention: CheckboxOption[];
  surgeryStatus: CheckboxOption[];
  preRank: CheckboxOption[];
  postRank: CheckboxOption[];
}

@Component({
  selector: 'stx-quality-assurance-filter',
  templateUrl: './quality-assurance-filter.component.html'
})
export class QualityAssuranceFilterComponent extends BaseComponent implements OnInit {
  @Input() formGroupReference: UntypedFormGroup;
  @Output() filterChange = new EventEmitter<ReportFilterChange>();
  qualityAssuranceOptions: QualityAssuranceOptions;

  preRankInitialParams: string[] = [];
  postRankInitialParams: string[] = [];

  constructor(
    private reportDictionariesService: ReportDictionariesService,
    private reportFiltersHelperService: ReportFiltersHelperService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.qualityAssuranceOptions = this.getQualityAssuranceOptions();
    this.addCheckboxOptionsToFormGroup();
    this.observeValueChanges();
    this.watchQueryParamsChange();
  }

  private handleValueChanges(change: Record<string, boolean>, filterName: ReportFilterName): void {
    const selectedOptions = this.reportFiltersHelperService.getSelectedCheckboxOptions(change);

    this.filterChange.emit({
      filterName,
      payload: {
        [filterName]: selectedOptions
      }
    });
  }

  private observeValueChanges(): void {
    this.subSink.sink = this.formGroupReference
      .get('surgicalIntervention')
      .valueChanges.subscribe((change: Record<string, boolean>) => this.handleValueChanges(change, 'qaSurgicalInterventions'));
    this.subSink.sink = this.formGroupReference
      .get('surgeryStatus')
      .valueChanges.subscribe((change: Record<string, boolean>) => this.handleValueChanges(change, 'qaSurgeryStatuses'));
    this.subSink.sink = this.formGroupReference
      .get('preRank')
      .valueChanges.subscribe((change: Record<string, boolean>) => this.handleValueChanges(change, 'qaPreRanks'));
    this.subSink.sink = this.formGroupReference
      .get('postRank')
      .valueChanges.subscribe((change: Record<string, boolean>) => this.handleValueChanges(change, 'qaPostRanks'));
  }

  private addCheckboxOptionsToFormGroup(): void {
    this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(
      this.formGroupReference.get('surgicalIntervention') as UntypedFormGroup,
      this.qualityAssuranceOptions.surgicalIntervention
    );
    this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(
      this.formGroupReference.get('surgeryStatus') as UntypedFormGroup,
      this.qualityAssuranceOptions.surgeryStatus
    );
    this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(
      this.formGroupReference.get('preRank') as UntypedFormGroup,
      this.qualityAssuranceOptions.preRank
    );
    this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(
      this.formGroupReference.get('postRank') as UntypedFormGroup,
      this.qualityAssuranceOptions.postRank
    );
  }

  private getQualityAssuranceOptions(): QualityAssuranceOptions {
    return Object.assign(
      {},
      {
        surgicalIntervention: this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(
          this.reportDictionariesService.dictionaries.qualityAssuranceSurgicalInterventions
        ),
        surgeryStatus: this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(
          this.reportDictionariesService.dictionaries.surgeryStatus
        ),
        preRank: this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(
          this.reportDictionariesService.dictionaries.preRanks
        ),
        postRank: this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(
          this.reportDictionariesService.dictionaries.postRanks
        )
      }
    );
  }

  private watchQueryParamsChange() {
    this.subSink.sink = this.route.queryParams
      .pipe(first(params => !!params.qaSurgicalIntervention || !!params.qaSurgeryStatus || !!params.qaPostRank || !!params.qaPreRank))
      .subscribe((params: ReportFiltersParams) => {
        if (params.qaSurgicalIntervention) {
          this.reportFiltersHelperService.setCheckboxFormGroupValues(
            this.formGroupReference.get('surgicalIntervention') as UntypedFormGroup,
            params.qaSurgicalIntervention
          );
        }
        if (params.qaSurgeryStatus) {
          this.reportFiltersHelperService.setCheckboxFormGroupValues(
            this.formGroupReference.get('surgeryStatus') as UntypedFormGroup,
            params.qaSurgeryStatus
          );
        }
        if (params.qaPostRank) {
          this.reportFiltersHelperService.setCheckboxFormGroupValues(
            this.formGroupReference.get('postRank') as UntypedFormGroup,
            params.qaPostRank
          );
        }
        if (params.qaPreRank) {
          this.reportFiltersHelperService.setCheckboxFormGroupValues(
            this.formGroupReference.get('preRank') as UntypedFormGroup,
            params.qaPreRank
          );
        }
      });
  }
}

@NgModule({
  declarations: [QualityAssuranceFilterComponent],
  imports: [FormModule, FlexModule, TranslateModule],
  exports: [QualityAssuranceFilterComponent]
})
export class QualityAssuranceFilterModule {}
