import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DictionaryTranslation, PatientListItem } from '../../../features/patient/models/patient.model';
import { partnersRoute, patientDetailsPath } from '@utils/routing.utils';
import { ReadonlyFormUtils } from '@utils/form.utils';
import { CountryDictionary } from '../../models/geo.model';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-patient-details-heading',
  templateUrl: './patient-details-heading.component.html',
  styleUrls: ['./patient-details-heading.component.scss']
})
export class PatientDetailsHeadingComponent implements OnInit {
  @Input() patient: PatientListItem;
  @Input() genders: DictionaryTranslation[];
  @Input() countries: Array<CountryDictionary>;

  // should be true when background have dark color
  @Input() hasDarkBackground = false;

  readonly ReadonlyFormUtils = ReadonlyFormUtils;

  readonly faExternalLinkAlt = faExternalLinkAlt;

  treatmentCenterCountryName: string;

  get partnerRoute(): string {
    if (this.patient.partnerId) {
      return `/${partnersRoute}/${this.patient.partnerId}`;
    }

    return '';
  }

  get partnerName(): string {
    return this.patient?.partner?.name;
  }

  get partnerLocalName(): string {
    return this.patient?.partner?.nameLoc;
  }

  get patientRoute(): string {
    return patientDetailsPath(this.patient.id);
  }

  ngOnInit(): void {
    this.treatmentCenterCountryName = this.getTreatmentCenterCountryName();
  }

  private getTreatmentCenterCountryName(): string {
    let treatmentCenterCountryName = null;

    if (this.patient?.treatmentCenter.countryId) {
      const country = this.countries.find(countrySearch => countrySearch.id === this.patient.treatmentCenter.countryId);

      treatmentCenterCountryName = country?.name;
    }

    return treatmentCenterCountryName;
  }
}

@NgModule({
  imports: [CommonModule, FlexLayoutModule, TranslateModule, RouterModule, GeneralCommonsModule, FontAwesomeModule],
  declarations: [PatientDetailsHeadingComponent],
  exports: [PatientDetailsHeadingComponent]
})
export class PatientDetailsHeadingComponentModule {}
