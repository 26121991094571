import { NavigationSubTabEnum } from '@shared/components/navigation/model/nav-item-enum.enum';
import { Component } from '@angular/core';
import { ResourceSection } from '@src/app/features/educational-resources/models/resource.model';
import { FileExtension, Mime } from '@utils/file.utils';

@Component({
  selector: 'stx-who-reference-data',
  templateUrl: './who-reference-data.component.html'
})
export class WhoReferenceDataComponent {
  readonly NavigationSubTabEnum = NavigationSubTabEnum;
  bmiSection = whoBmiForAgeSection;
  zipSection = whoGrowthZipSection;
  weightForHeightSection = whoWeightForHeightSection;
  growthSection = whoGrowthSection;
  heightForAgeSection = whoHeightForAgeSection;
}

export const whoGrowthZipSection: ResourceSection = {
  resources: [
    {
      label: 'resources.who_growth.all',
      resourceName: 'Growth-curves-and-tables_',
      fileExtension: FileExtension.ZIP,
      mime: Mime.ZIP,
      translationList: [['en', 'es', 'fr']],
      isSeparated: true
    }
  ]
};

export const whoBmiForAgeSection: ResourceSection = {
  sectionTitle: 'resources.who.bmi_for_age',
  resources: [
    {
      label: 'resources.who_growth.BMI_for_age.BMIAZ_boys_5-19y',
      resourceName: 'BMIAZ_boys_5-19y_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.BMI_for_age.BMIAZ_girls_5-19y',
      resourceName: 'BMIAZ_girls_5-19y_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.BMI_for_age.BMIAZ_boys_5-19y-Table',
      resourceName: 'BMIAZ_boys_5-19y-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.BMI_for_age.BMIAZ_girls_5-19y-Table',
      resourceName: 'BMIAZ_girls_5-19y-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    }
  ]
};

export const whoGrowthSection: ResourceSection = {
  sectionTitle: 'resources.who.section_title',
  resources: [
    {
      label: 'resources.who_growth.weight_for_age.WAZ_boys_0-6m',
      resourceName: 'WAZ_boys_0-6m_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_girls_0-6m',
      resourceName: 'WAZ_girls_0-6m_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_boys_6m-2y',
      resourceName: 'WAZ_boys_6m-2y_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_girls_6m-2y',
      resourceName: 'WAZ_girls_6m-2y_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_boys_2-5y',
      resourceName: 'WAZ_boys_2-5y_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_girls_2-5y',
      resourceName: 'WAZ_girls_2-5y_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_boys_5-10y',
      resourceName: 'WAZ_boys_5-10y_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_girls_5-10y',
      resourceName: 'WAZ_girls_5-10y_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_boys_0-13w-Table',
      resourceName: 'WAZ_boys_0-13w-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_girls_0-13w-Table',
      resourceName: 'WAZ_girls_0-13w-Table_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_boys_0-5y-Table',
      resourceName: 'WAZ_boys_0-5y-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_girls_0-5y-Table',
      resourceName: 'WAZ_girls_0-5y-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart',
      isSeparated: true
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_boys_5-10y-Table',
      resourceName: 'WAZ_boys_5-10y-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.weight_for_age.WAZ_girls_5-10y-Table',
      resourceName: 'WAZ_girls_5-10y-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    }
  ]
};

export const whoWeightForHeightSection: ResourceSection = {
  sectionTitle: 'resources.who.height_for_age',
  resources: [
    {
      label: 'resources.who_growth.height_for_age.LAZ_boys_0-6m',
      resourceName: 'LAZ_boys_0-6m_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.LAZ_girls_0-6m',
      resourceName: 'LAZ_girls_0-6m_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.LAZ_boys_6m-2y',
      resourceName: 'LAZ_boys_6m-2y_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.LAZ_girls_6m-2y',
      resourceName: 'LAZ_girls_6m-2y_',
      isSeparated: true,
      translationList: [['en', 'es', 'fr']],
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.HAZ_boys_2-5y',
      resourceName: 'HAZ_boys_2-5y_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.HAZ_girls_2-5y',
      resourceName: 'HAZ_girls_2-5y_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.HAZ_boys_5-19y',
      resourceName: 'HAZ_boys_5-19y_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.HAZ_girls_5-19y',
      resourceName: 'HAZ_girls_5-19y_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.LAZ_boys_0-13w-Table',
      resourceName: 'LAZ_boys_0-13w-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.LAZ_girls_0-13w-Table',
      resourceName: 'LAZ_girls_0-13w-Table_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.LAZ_boys_0-2y-Table',
      resourceName: 'LAZ_boys_0-2y-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.LAZ_girls_0-2y-Table',
      resourceName: 'LAZ_girls_0-2y-Table_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.HAZ_boys_2-5y-Table',
      resourceName: 'HAZ_boys_2-5y-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.HAZ_girls_2-5y-Table',
      resourceName: 'HAZ_girls_2-5y-Table_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.HAZ_boys_5-19y-Simplified-Table',
      resourceName: 'HAZ_boys_5-19y-Simplified-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.height_for_age.HAZ_girls_5-19y-Simplified-Table',
      resourceName: 'HAZ_girls_5-19y-Simplified-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    }
  ]
};

export const whoHeightForAgeSection: ResourceSection = {
  sectionTitle: 'resources.who.weight_for_height',
  resources: [
    {
      label: 'resources.who_growth.weight_for_height.WLZ_boys_0-2y',
      resourceName: 'WLZ_boys_0-2y_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.weight_for_height.WLZ_girls_0-2y',
      resourceName: 'WLZ_girls_0-2y_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.weight_for_height.WHZ_boys_2-5y',
      resourceName: 'WHZ_boys_2-5y_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.weight_for_height.WHZ_girls_2-5y',
      resourceName: 'WHZ_girls_2-5y_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'show_chart'
    },
    {
      label: 'resources.who_growth.weight_for_height.WLZ_boys_0-2y-Table',
      resourceName: 'WLZ_boys_0-2y-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.weight_for_height.WLZ_girls_0-2y-Table',
      resourceName: 'WLZ_girls_0-2y-Table_',
      translationList: [['en', 'es', 'fr']],
      isSeparated: true,
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.weight_for_height.WHZ_boys_2-5y-Table',
      resourceName: 'WHZ_boys_2-5y-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    },
    {
      label: 'resources.who_growth.weight_for_height.WHZ_girls_2-5y-Table',
      resourceName: 'WHZ_girls_2-5y-Table_',
      translationList: [['en', 'es', 'fr']],
      iconName: 'table_chart'
    }
  ]
};
