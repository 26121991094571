import { Component } from '@angular/core';
import { SurgeryFollowUp } from '../../models/surgery-follow-up.model';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { dictionaryFromMapProvider } from '@shared/services/dictionary-provider';
import { SurgicalService } from '@src/app/features/surgical/surgical.service';
import { BaseReadonlyViewComponent } from '@shared/modules/readonly-commons/utils/base-readonly-view.component';
import { MediaSet } from '@shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';

@Component({
  selector: 'stx-surgical-follow-up-readonly',
  templateUrl: './surgical-follow-up-readonly.component.html',
  providers: [dictionaryFromMapProvider(SurgicalService, s => s.getFollowUpStaticDictionaries())]
})
export class SurgicalFollowUpReadonlyComponent extends BaseReadonlyViewComponent<SurgeryFollowUp> {
  photos: MediaSet[] = [];

  protected afterTreatmentSet(): void {
    this.photos = [
      {
        mediaWithLabels: [
          this.findMediaWithLabels(ParentOrderName.FOLLOW_UP_FRONT, ['order_name.frontal_smiling']),
          this.findMediaWithLabels(ParentOrderName.FOLLOW_UP_FRONT_LIP, ['order_name.frontal_lip']),
          this.findMediaWithLabels(ParentOrderName.FOLLOW_UP_PALATE, ['order_name.palate']),
          this.findMediaWithLabels(ParentOrderName.FOLLOW_UP_OTHER_1, ['order_name.other']),
          this.findMediaWithLabels(ParentOrderName.FOLLOW_UP_OTHER_2, ['order_name.other'])
        ]
      }
    ];
  }
}
