import { getLanguageShortcut } from '@/src/app/utils/language.utils';
import { Directive, Input, OnDestroy, Renderer2, SecurityContext, TemplateRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { StaticFilesService } from './static-files.service';
import { StaticFileInfo } from './static-files.utils';

@Directive({
  selector: '[stxContentFromHtmlFile]'
})
export class ContentFromHtmlFileDirective implements OnDestroy {
  private subSink = new SubSink();
  private langChangeSubscription: Subscription;
  private currentFile: StaticFileInfo;
  @Input() set stxContentFromHtmlFile(nextFile: StaticFileInfo | undefined) {
    if (!nextFile || this.newFileSameAsDisplayed(nextFile)) {
      return;
    }
    this.currentFile = nextFile;
    this.langChangeSubscription?.unsubscribe();
    if (nextFile.translated) {
      this.langChangeSubscription = this.watchTranslations(nextFile);
      this.fetchAndEmbedTranslatedHtmlString(nextFile);
    } else {
      this.fetchAndEmbedHtmlString(nextFile);
    }
  }

  constructor(
    private renderer: Renderer2,
    private tempalteRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private staticFilesService: StaticFilesService,
    private translateService: TranslateService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnDestroy(): void {
    this.langChangeSubscription?.unsubscribe();
    this.subSink.unsubscribe();
  }

  private newFileSameAsDisplayed(fileInfo: StaticFileInfo): boolean {
    return this.currentFile && fileInfo.fileDir === this.currentFile.fileDir;
  }

  private sanitizer = (isTrusted: boolean) => (htmlStringResponse: string) =>
    isTrusted ? htmlStringResponse : this.domSanitizer.sanitize(SecurityContext.HTML, htmlStringResponse);

  private watchTranslations(fileInfo: StaticFileInfo) {
    return this.translateService.onLangChange.subscribe(() => {
      this.fetchAndEmbedTranslatedHtmlString(fileInfo);
    });
  }

  private embedHtmlString(htmlString: string) {
    this.viewContainer.remove();
    const embeddedViewRef = this.viewContainer.createEmbeddedView(this.tempalteRef);
    this.renderer.setProperty(embeddedViewRef.rootNodes[0], 'innerHTML', htmlString);
  }

  private fetchAndEmbedTranslatedHtmlString(fileInfo: StaticFileInfo) {
    this.subSink.sink = this.staticFilesService
      .getStaticHTMLFromAssets(fileInfo.fileDir, getLanguageShortcut(this.translateService.currentLang))
      .pipe(map(this.sanitizer(true)))
      .subscribe(htmlStringResponse => this.embedHtmlString(htmlStringResponse));
  }

  private fetchAndEmbedHtmlString(fileInfo: StaticFileInfo) {
    this.subSink.sink = this.staticFilesService
      .getStaticHTMLFromAssets(fileInfo.fileDir, undefined)
      .pipe(map(this.sanitizer(true)))
      .subscribe(htmlStringResponse => this.embedHtmlString(htmlStringResponse));
  }
}
