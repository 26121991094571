import { MedicalMeeting, MedicalMeetingCategory } from '@/src/app/features/educational-resources/models/medical-meeting.model';
import { dateFormat } from '@/src/app/utils/date.utils';
import { Component, Input } from '@angular/core';
import { faClock } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'stx-medical-meetings-list',
  templateUrl: './medical-meetings-list.component.html',
  styleUrls: ['./medical-meetings-list.component.scss']
})
export class MedicalMeetingsListComponent {
  readonly dateFormat = dateFormat;
  readonly faClock = faClock;
  @Input() items: MedicalMeeting[];

  isRecordingsOfPastEvents(medicalMeeting: MedicalMeeting): boolean {
    return medicalMeeting.category === MedicalMeetingCategory.PAST_EVENT_RECORDING;
  }
}
