import { Component, Input } from '@angular/core';

import { ExpandableFileInfo, StaticFileInfo } from '@shared/modules/static-files/static-files.utils';
import { BaseComponent } from '@shared/components/base-component/base.component';

@Component({
  selector: 'stx-expandable-static-file',
  templateUrl: './expandable-static-file.component.html'
})
export class ExpandableStaticFileComponent extends BaseComponent {
  @Input() fileInfo: ExpandableFileInfo;

  constructor() {
    super();
  }

  get hiddenBodyInfo(): StaticFileInfo {
    return {
      translated: this.fileInfo.translated,
      fileDir: this.fileInfo.hiddenDir
    };
  }
  get visibleBodyInfo(): StaticFileInfo {
    return {
      translated: this.fileInfo.translated,
      fileDir: this.fileInfo.visibleDir
    };
  }

  get isExpandableBody(): boolean {
    return !!this.fileInfo.hiddenDir;
  }
}
