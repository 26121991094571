export enum MediaType {
  CONVERTED = 'CONVERTED',
  RAW = 'RAW',
  LOW_RES = 'LOW_RES',
  HIGH_RES = 'HIGH_RES'
}

export enum MediaContentType {
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  OTHER = 'OTHER'
}
