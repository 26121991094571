<stx-readonly-view-columns *ngIf="treatment">
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field label="surgery_follow_up.follow_up_date" [value]="treatment.dateVisit"></stx-simple-readonly-field>
    <stx-simple-readonly-field label="age" [value]="treatment.ageMonth | ageYearMonth"></stx-simple-readonly-field>
    <stx-simple-readonly-field label="follow_up.proper_weight" [value]="treatment.properWeight | yesNo"> </stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="followup.wieght_improved"
      [value]="treatment.properWeightImprove | dict : 'yesNoNa' | async | translate"
    >
    </stx-simple-readonly-field>
    <stx-readonly-field label="treatment_center.treatment_center">
      <stx-tc-link [treatmentCenter]="treatment.treatmentCenter"></stx-tc-link>
    </stx-readonly-field>
    <stx-simple-readonly-field label="treatment.telehealth_assessment" [value]="'yes' | if : treatment.telehealth | translate">
    </stx-simple-readonly-field>
    <stx-simple-readonly-field label="status" [value]="treatmentStatusLabel | translate"> </stx-simple-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field label="followup.healed" [value]="treatment.healWell | yesNo"> </stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="followup.complications"
      [value]="[treatment.healComplication | yesNo, treatment.healComplicationComment]"
    >
    </stx-simple-readonly-field>
    <stx-simple-readonly-field label="follow_up.need_surgery" [value]="[treatment.needSurgery | yesNo, treatment.needSurgeryComment]">
    </stx-simple-readonly-field>
    <stx-simple-readonly-field label="reports.followup.need_speech" [value]="[treatment.needSpeech | yesNo, treatment.needSpeechComment]">
    </stx-simple-readonly-field>
    <stx-simple-readonly-field label="reports.followup.need_ortho" [value]="[treatment.needOrtho | yesNo, treatment.needOrthoComment]">
    </stx-simple-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field
      label="reports.followup.attend_school"
      [value]="[treatment.attendSchool | dict : 'yesNoNa' | async | translate, treatment.attendSchoolImprove]"
    >
    </stx-simple-readonly-field>
    <stx-simple-readonly-field label="followup.overall_outcome" [value]="treatment.outcomeSentiment"></stx-simple-readonly-field>
  </ng-container>
</stx-readonly-view-columns>
<stx-readonly-media-group [mediaSets]="photos"></stx-readonly-media-group>
