<div class="data-section">
  <div class="data-section-heading">&nbsp;</div>
  <div class="data-section-content flex-column-container gap-10">
    <div class="flex-row-container gap-40">
      <div class="custom-flex-30">
        <strong>{{ 'age' | translate }}</strong>
      </div>
      <div class="custom-flex-70">
        <p>
          <ng-container *ngIf="patientAge; else patientAgeUnknown">
            {{ patientAge }}
          </ng-container>
          <ng-template #patientAgeUnknown>
            {{ 'surgical_qa_panel.patient_age_is_unknown' | translate }}
          </ng-template>
        </p>
      </div>
    </div>

    <div class="flex-row-container gap-40">
      <div class="custom-flex-30">
        <strong>{{ 'operations' | translate }}</strong>
      </div>
      <div class="custom-flex-70 flex-column-container">
        <ng-container *ngIf="operationDescription.surgicalInterventionTypes; else unknownInterventionType">
          <p *ngFor="let type of operationDescription.surgicalInterventionTypes">
            {{ type | translate }}
          </p>
        </ng-container>
        <ng-template #unknownInterventionType>
          <p>
            {{ 'surgical_qa_panel.unknown_intervention_type' | translate }}
          </p>
        </ng-template>
      </div>
    </div>

    <div class="flex-row-container gap-40">
      <div class="custom-flex-30">
        <strong>{{ 'surgical_qa_panel.type_of_repairs' | translate }}</strong>
      </div>
      <div class="flex-column-container custom-flex-70">
        <ng-container *ngIf="operationDescription.repairTypes; else unknownTypesOfRepairs">
          <p *ngFor="let repairType of operationDescription.repairTypes">
            {{ repairType | translate }}
          </p>
        </ng-container>
        <ng-template #unknownTypesOfRepairs>
          <p>
            {{ 'surgical_qa_panel.unknown_types_of_repairs' | translate }}
          </p>
        </ng-template>
      </div>
    </div>

    <div class="flex-row-container gap-40 custom-flex-70" *ngIf="operationDescription.comment">
      <strong>{{ 'surgical_qa_panel.comments' | translate }}</strong>
      <p>
        {{ operationDescription.comment }}
      </p>
    </div>
  </div>
</div>
