import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ImageRotatorModule } from '../image-rotator/image-rotator.module';
import { ReadonlyImageComponent } from './readonly-image.component';
import { NoticeComponentModule } from '@shared/components/notice/notice.component';

@NgModule({
  declarations: [ReadonlyImageComponent],
  imports: [CommonModule, ImageRotatorModule, TranslateModule, MatTooltipModule, NoticeComponentModule],
  exports: [ReadonlyImageComponent]
})
export class ReadonlyImageModule {}
