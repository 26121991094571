import { PermanentDentitionAssessmentComponent } from '@/src/app/features/ortho/components/permanent-dentition-assessment/permanent-dentition-assessment.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '@shared/components/form/form.module';
import { TreatmentModule } from '@shared/components/treatment/treatment.module';
import { UppyModule } from '@shared/components/uppy/uppy.module';
import { FormsCommonsModule } from '@shared/modules/forms-commons/forms-commons.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { MaterialModule } from '@shared/modules/material/material.module';
import { YesNoOrNullPipeModule } from '@shared/modules/readonly-commons/pipes/yes-no/yes-no-or-null.pipe';
import { YesNoPipeModule } from '@shared/modules/readonly-commons/pipes/yes-no/yes-no.pipe';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { OrthoMidAssessmentReadonlyComponent } from '@src/app/features/ortho/components/ortho-mid-assessment-readonly/ortho-mid-assessment-readonly.component';
import { OrthoGeneralMidAssessmentInfoComponent } from '@src/app/features/ortho/components/shared/ortho-general-mid-assessment-info/ortho-general-mid-assessment-info.component';
import { DiscontinueOrthoComponent } from './components/discontinue/discontinue-ortho.component';
import { MixedDentitionAssessmentComponent } from './components/mixed-dentition-assessment/mixed-dentition-assessment.component';
import { MixedDentitionMidAssessmentComponent } from './components/mixed-dentition-mid-assessment/mixed-dentition-mid-assessment.component';
import { MixedDentitionTreatmentComponent } from './components/mixed-dentition-treatment/mixed-dentition-treatment.component';
import { OrthoReadonlyAssessmentComponent } from './components/ortho-readonly-assessment/ortho-readonly-assessment.component';
import { OrthoReadonlyTreatmentComponent } from './components/ortho-readonly-treatment/ortho-readonly-treatment.component';
import { OrthoTreatmentGuidelinesLinkComponent } from './components/ortho-treatment-guidelines-link/ortho-treatment-guidelines-link.component';
import { PermanentDentitionMidAssessmentComponent } from './components/permanent-dentition-mid-assessment/permanent-dentition-mid-assessment.component';
import { LocalNamePipeModule } from '@shared/pipes/local-name.pipe';
import { PermanentDentitionTreatmentComponent } from './components/permanent-dentition-treatment/permanent-dentition-treatment.component';
import { PsioAssessmentComponent } from './components/psio-assessment/psio-assessment.component';
import { PsioTreatmentComponent } from './components/psio-treatment/psio-treatment.component';
import { OrthoGeneralAssessmentInfoComponent } from './components/shared/ortho-general-assessment-info/ortho-general-assessment-info.component';
import { OrthoGeneralTreatmentInfoComponent } from './components/shared/ortho-general-treatment-info/ortho-general-treatment-info.component';
import { OrthoSharedGeneralInfoComponent } from './components/shared/ortho-shared-general-info/ortho-shared-general-info.component';

@NgModule({
  declarations: [
    DiscontinueOrthoComponent,
    OrthoTreatmentGuidelinesLinkComponent,
    PsioAssessmentComponent,
    OrthoGeneralAssessmentInfoComponent,
    OrthoGeneralMidAssessmentInfoComponent,
    OrthoGeneralTreatmentInfoComponent,
    OrthoSharedGeneralInfoComponent,
    OrthoReadonlyAssessmentComponent,
    OrthoReadonlyTreatmentComponent,
    PsioTreatmentComponent,
    MixedDentitionAssessmentComponent,
    PermanentDentitionAssessmentComponent,
    MixedDentitionTreatmentComponent,
    PermanentDentitionTreatmentComponent,
    MixedDentitionMidAssessmentComponent,
    PermanentDentitionMidAssessmentComponent,
    OrthoMidAssessmentReadonlyComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormModule,
    FormsCommonsModule,
    GeneralCommonsModule,
    MaterialModule,
    ReactiveFormsModule,
    ReadonlyCommonsModule,
    TranslateModule.forChild(),
    TreatmentModule,
    UppyModule,
    YesNoPipeModule,
    YesNoOrNullPipeModule,
    LocalNamePipeModule
  ],
  exports: [
    DiscontinueOrthoComponent,
    PsioAssessmentComponent,
    PsioTreatmentComponent,
    MixedDentitionAssessmentComponent,
    PermanentDentitionAssessmentComponent,
    MixedDentitionTreatmentComponent,
    PermanentDentitionTreatmentComponent,
    PermanentDentitionMidAssessmentComponent,
    MixedDentitionMidAssessmentComponent
  ]
})
export class OrthoModule {}
