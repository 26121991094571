export enum NavigationSubTabEnum {
  RESOURCES_WHO_REFERENCE_DATA,
  RESOURCES_CLEFT_PUBLICATIONS,
  RESOURCES_VIDEOS,
  RESOURCES_PROTOCOLS_ADVISORIES_GUIDELINES,
  RESOURCES_MEDICAL_FORMS,
  RESOURCES_FOR_PARTNERS,
  RESOURCES_FOR_FAMILIES,
  RESOURCES_USER_GUIDE,
  RESOURCES_NUTRITION,
  RESOURCES_EDUCATION_AND_RESEARCH,
  RESOURCES_SPEECH
}
