import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { FormModule } from '@shared/components/form/form.module';
import {
  CheckboxOption,
  CommonReportDictionaryItem,
  ReportCheckboxesFilters,
  ReportCheckboxesPayload,
  ReportFilterChange
} from '../../models/report-filters.model';
import { ReportFiltersHelperService } from '../../services/report-filters-helper.service';

export type CheckboxGroupFilterLayout = 'shrink' | 'rows';

@Component({
  selector: 'stx-checkbox-group-filter',
  templateUrl: './checkbox-group-filter.component.html',
  styleUrls: ['./checkbox-group-filter.component.scss']
})
export class CheckboxGroupFilterComponent extends BaseComponent implements OnInit {
  @Input() filterName: ReportCheckboxesFilters;
  @Input() legendTranslationNode: string;
  @Input() formGroupReference: UntypedFormGroup;
  @Input() dictionaryOptions: CommonReportDictionaryItem[];
  @Input() useLayout: CheckboxGroupFilterLayout = 'rows';

  @Output() filterChange = new EventEmitter<ReportFilterChange<ReportCheckboxesPayload, ReportCheckboxesFilters>>();

  options: CheckboxOption[] = [];

  // default layout for rows
  layout = {
    flex: '100%',
    padding: ''
  };

  constructor(private reportFiltersHelperService: ReportFiltersHelperService) {
    super();
  }

  ngOnInit(): void {
    this.options = this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(this.dictionaryOptions);
    this.setLayout();
    this.watchFilterModel();
    this.emitSelectedOptions();
  }

  private setLayout() {
    if (this.useLayout === 'shrink') {
      this.layout.flex = '0 1 auto';
    }
  }

  private watchFilterModel() {
    this.subSink.sink = this.formGroupReference.valueChanges.subscribe(() => {
      this.emitSelectedOptions();
    });
  }

  private emitSelectedOptions() {
    const selectedOptions = this.reportFiltersHelperService.getSelectedCheckboxesFromGroup(this.formGroupReference);

    this.filterChange.emit({
      filterName: this.filterName,
      payload: {
        [this.filterName]: selectedOptions
      }
    });
  }
}

@NgModule({
  imports: [CommonModule, FlexLayoutModule, FormModule, TranslateModule],
  declarations: [CheckboxGroupFilterComponent],
  exports: [CheckboxGroupFilterComponent]
})
export class CheckboxGroupFilterComponentModule {}
