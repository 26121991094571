import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { combineLatest, of } from 'rxjs';
import { ErrorSnackBarComponent } from '@shared/components/error-handling/error-snack-bar/error-snack-bar.component';
import { ErrorData } from '@shared/components/error-handling/error-data.model';

const notificationTimeMs = 5000;

@Injectable({
  providedIn: 'root'
})
export class SnackBarService extends BaseComponent {
  constructor(private readonly snackBar: MatSnackBar, private readonly translateService: TranslateService) {
    super();
  }

  notify(message: string, action?: string, config?: MatSnackBarConfig): void {
    const snackbarConfiguration: MatSnackBarConfig = !!config ? config : { duration: notificationTimeMs };
    this.subSink.sink = combineLatest([
      this.translateService.get(message),
      action ? this.translateService.get(action) : of(undefined)
    ]).subscribe(([translatedMessage, translatedAction]) => this.snackBar.open(translatedMessage, translatedAction, snackbarConfiguration));
  }

  showError(errorDate: ErrorData): MatSnackBarRef<ErrorSnackBarComponent> {
    return this.snackBar.openFromComponent(ErrorSnackBarComponent, {
      data: errorDate,
      duration: 8000,
      panelClass: 'dark-grey-background'
    });
  }
}
