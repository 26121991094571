<div [formGroup]="formGroup" class="flex-column-container">
  <div class="stx-form-field flex-column-container">
    <label class="form-element-label">
      {{ label | translate }} {{ labelSuffix | translate }} {{ 'optional' | translate | if : isOptional }}
    </label>
    <mat-form-field appearance="outline">
      <input
        data-testid="input-date"
        [ngClass]="{ 'hide-on-print': print }"
        matInput
        [matDatepicker]="datePicker"
        [formControlName]="controlName"
        [id]="controlName"
        [max]="maxDate"
        [min]="minDate"
      />
      <mat-datepicker-toggle matSuffix [for]="datePicker">
        <mat-icon class="calculator-date-icon calculator-icon" matDatepickerToggleIcon>calendar_today</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
      <mat-error class="stx-mat-error-align" *ngIf="showStandardError(formGroup, controlName) && print !== true">
        <stx-error-message-wrapper>
          {{ validationMessagePath | translate }}
        </stx-error-message-wrapper>
      </mat-error>
    </mat-form-field>
  </div>
</div>
