export interface TimeOption {
  timeValue: number;
  timeValueLabel: string;
}

export interface TimeOptionConfig {
  minimumValue: number;
  maximumValue: number;
  step: number;
}

const defaultMilitaryHourOptionConfig = {
  minimumValue: 0,
  maximumValue: 23,
  step: 1
};

const defaultMilitaryMinutesOptionConfig = {
  minimumValue: 0,
  maximumValue: 55,
  step: 5
};

export function createTimeOptionsListFromConfig(timeOptionConfig: TimeOptionConfig): TimeOption[] {
  const timeSelectionOptions: TimeOption[] = [];

  for (
    let timeValueFromStep = timeOptionConfig.minimumValue;
    timeValueFromStep <= timeOptionConfig.maximumValue;
    timeValueFromStep += timeOptionConfig.step
  ) {
    timeSelectionOptions.push({ timeValue: timeValueFromStep, timeValueLabel: getTimeValueAsPaddedStringFromNumber(timeValueFromStep) });
  }

  return timeSelectionOptions;
}

function getTimeValueAsPaddedStringFromNumber(valueAsNumber: number, padding = 2): string {
  const precedingCharacterToPadWith = '0';
  return valueAsNumber.toString().padStart(padding, precedingCharacterToPadWith);
}

export const defaultMilitaryTimeHourSelectionOptions = createTimeOptionsListFromConfig(defaultMilitaryHourOptionConfig);

export const defaultMilitaryTimeMinuteSelectionOptions = createTimeOptionsListFromConfig(defaultMilitaryMinutesOptionConfig);
