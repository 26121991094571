<div class="form-element-group-grid-2cols-wrap">
  <div>
    <stx-date
      [label]="dateFromLabel"
      [controlName]="dateFromControlName"
      [formGroup]="formGroupReference"
      [preserveValue]="true"
      [maxDate]="dateToControl.value ? dateToControl.value : today"
    ></stx-date>
  </div>
  <div>
    <stx-date
      [label]="dateToLabel"
      [controlName]="dateToControlName"
      [formGroup]="formGroupReference"
      [preserveValue]="true"
      [minDate]="dateFromControl.value ? dateFromControl.value : null"
      [maxDateToday]="true"
    ></stx-date>
  </div>
</div>
