export const FIRST_ITEM = 0;

export class ArrayUtils {
  static flat<T = any[]>(array: any[]): T {
    return array.reduce((flattened, item) => {
      if (Array.isArray(item)) {
        return [...flattened, ...item];
      }
      return [...flattened, item];
    }, []);
  }

  static addOrReplace<T>(array: Array<T>, item: T, predicate: (value: T) => boolean) {
    const mediaIndex = array.findIndex(predicate);
    if (mediaIndex > -1) {
      array[mediaIndex] = item;
    } else {
      array.push(item);
    }
  }

  static sortAscByValue<T>(array: Array<T>, valueSupplier: (value: T) => any) {
    return array
      ? array.sort((a, b) => {
          if (valueSupplier(a) === null) {
            return 1;
          } else if (valueSupplier(b) === null) {
            return -1;
          }
          return valueSupplier(a) - valueSupplier(b);
        })
      : array;
  }

  static contains<T>(array: T[], element: T): boolean {
    if (!array) {
      return false;
    }
    return array.findIndex(arrayElement => arrayElement === element) > -1;
  }

  static intersectionNotEmpty<T>(array: T[], elements: T[]): boolean {
    if (!array) {
      return false;
    }

    return elements.some(el => this.contains(array, el));
  }

  static isEmpty<T>(array?: Array<T>): boolean {
    return (array?.length ?? 0) === 0;
  }
}
