import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SearchFiltersBase } from '../models/search-filters-base.model';

@Injectable({
  providedIn: 'root'
})
export class SearchFilterService {
  private readonly searchEventSubject$ = new Subject<SearchFiltersBase>();
  private readonly csvDownloadEventSubject$ = new Subject<SearchFiltersBase>();
  public readonly config: SearchFilterServiceConfig<SearchFiltersBase> = new SearchFilterServiceConfig();
  initiateSearch() {
    const searchTerm = this.config?.searchTermGetter();
    const filters = this.config?.searchFiltersGetter();
    this.searchEventSubject$.next({ searchTerm, ...filters });
  }

  initiateCsvDownload() {
    const searchTerm = this.config?.searchTermGetter();
    const filters = this.config?.searchFiltersGetter();
    this.csvDownloadEventSubject$.next({ searchTerm, ...filters });
  }

  get searchFilters(): SearchFiltersBase | undefined {
    return this.config?.searchFiltersGetter();
  }

  get searchTerm(): string | undefined {
    return this.config?.searchTermGetter();
  }

  get searchEvent$(): Observable<SearchFiltersBase> {
    return this.searchEventSubject$.asObservable();
  }

  get csvDownloadEvent$(): Observable<SearchFiltersBase> {
    return this.csvDownloadEventSubject$.asObservable();
  }
}

export class SearchFilterServiceConfig<SearchFiltersModel> {
  searchTermGetter: () => string | undefined;
  searchFiltersGetter: () => SearchFiltersModel | undefined;
}
