import { MaintenanceService } from '@/src/app/core/maintenance/maintenance.service';
import { SpinnerService } from '@/src/app/shared/components/spinner/service/spinner.service';
import { maintenancePageRoute } from '@/src/app/utils/routing.utils';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { noop, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  maintenancePageFullRoute = `/${maintenancePageRoute}`;
  constructor(
    private readonly router: Router,
    private readonly spinnerService: SpinnerService,
    private readonly maintenanceService: MaintenanceService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        next: noop,
        error: errorResponse => {
          if (MaintenanceService.isStxMaintenanceMessage(errorResponse)) {
            this.maintenanceService.setMessage(errorResponse);
            if (!window.location.pathname.startsWith(this.maintenancePageFullRoute)) {
              this.spinnerService.clearTasks();
              this.router.navigateByUrl(this.maintenancePageFullRoute);
            }
          }
        }
      })
    );
  }
}
