import { Injectable } from '@angular/core';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { TableStructure } from '@shared/components/table/models/table-structure.model';
import { SpeechTreatmentsReportRecord } from '@src/app/features/reports/features/speech-treatments-report/speech-treatments-report.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { OrthodonticStatsReportRecord } from '../../../features/orthodontic-stats-report/orthodontic-stats-report.model';
import { OrthodonticTreatmentCompactReportRecord } from '../../../features/orthodontic-treatments-compact-report/orthodontic-treatments-compact-report.model';
import { OrthodonticTreatmentReportRecord } from '../../../features/orthodontic-treatments-report/orthodontic-treatments-report.model';
import { OrthognathicTreatmentReportRecord } from '../../../features/orthognathic-treatments-report/orthognathic-treatments-report.model';
import { PhotoReportRecord } from '../../../features/photo-report/components/photo-report.model';
import { QaOrthoCasesRecord } from '../../../features/qa-ortho-cases/components/qa-ortho-cases-report-table/qa-ortho-cases-report-table.model';
import { SurgeryStatsReportRecord } from '../../../features/surgery-stats/surgery-stats-report.model';
import { SurgicalFollowUpVisitsReportRecord } from '../../../features/surgical-follow-up-visits-report/surgical-follow-up-visits-report.model';
import { SurgicalTreatmentReportRecord } from '../../../features/surgical-treatments-report/surgical-treatments-report.model';
import { SpeechStatsReportRecord } from '../models/report-data.model';
import { ReportFiltersModel, ReportType } from '../models/report-filters.model';
import { ReportRequestService } from './report-request.service';
import { ReportService } from './report.service';
import { scrollToTop } from '@utils/scroll.utils';
import { WsHelperService } from '@shared/services/ws-helper.service';

export type AllowedRecordType =
  | OrthognathicTreatmentReportRecord
  | OrthodonticTreatmentReportRecord
  | OrthodonticTreatmentCompactReportRecord
  | QaOrthoCasesRecord
  | SurgicalTreatmentReportRecord
  | SpeechStatsReportRecord
  | PhotoReportRecord
  | SpeechTreatmentsReportRecord
  | SurgicalFollowUpVisitsReportRecord
  | SurgeryStatsReportRecord
  | OrthodonticStatsReportRecord;

@Injectable({
  providedIn: 'root'
})
export class ReportTableDataService {
  public readonly tableStructure$: Observable<TableStructure<AllowedRecordType>>;
  public readonly currentPageSorting$: Observable<PaginationSortingModel>;
  public readonly pageSizeOptions$: Observable<number[]>;

  public pageSizeOptions: number[] = [25, 100];
  public currentPageSorting: PaginationSortingModel = {
    pageNumber: 0,
    pageSize: this.pageSizeOptions[0],
    sortedBy: '',
    sortDirection: ''
  };
  dataReady = false;

  private tableStructureSubject: BehaviorSubject<TableStructure<AllowedRecordType>> = new BehaviorSubject(
    ReportTableDataService.getEmptyPage()
  );
  private pageSizeOptionsSubject: BehaviorSubject<number[]> = new BehaviorSubject(this.pageSizeOptions);
  private currentPageSortingSubject: BehaviorSubject<PaginationSortingModel> = new BehaviorSubject(this.currentPageSorting);

  static getEmptyPage(): TableStructure<any> {
    return {
      labels: [],
      data: {
        pageNumber: 0,
        pageSize: 0,
        sortDirection: '',
        sortProperty: '',
        page: {
          content: [],
          empty: true,
          first: false,
          last: false,
          number: 0,
          numberOfElements: 0,
          pageable: '',
          size: 0,
          sort: {
            sorted: false,
            unsorted: false,
            empty: false
          },
          totalElements: 0,
          totalPages: 0
        }
      }
    };
  }

  constructor(
    private reportRequestService: ReportRequestService,
    private reportService: ReportService,
    private wsHelperService: WsHelperService
  ) {
    this.tableStructure$ = this.tableStructureSubject.asObservable();
    this.pageSizeOptions$ = this.pageSizeOptionsSubject.asObservable();
    this.currentPageSorting$ = this.currentPageSortingSubject.asObservable();
  }

  flush(): void {
    this.currentPageSorting = {
      pageNumber: 0,
      pageSize: this.pageSizeOptions[0],
      sortedBy: '',
      sortDirection: ''
    };
    this.currentPageSortingSubject.next(this.currentPageSorting);
    this.tableStructureSubject.next(ReportTableDataService.getEmptyPage());
    this.dataReady = false;
  }

  prepareList(reportType: ReportType, filterModel?: ReportFiltersModel, paginationSortingModel?: PaginationSortingModel): void {
    if (paginationSortingModel) {
      this.currentPageSorting = paginationSortingModel;
      this.currentPageSortingSubject.next(this.currentPageSorting);
    }

    if (this.reportService.reportFiltersFormGroup.status === 'VALID') {
      this.wsHelperService
        .callWithSpinner(this.reportRequestService.getReportsListData(reportType, filterModel, this.currentPageSorting).pipe(first()))
        .subscribe({
          next: (tableStructure: TableStructure) => {
            this.dataReady = true;
            this.tableStructureSubject.next(tableStructure);
            this.reportService.hideFilters();
            scrollToTop();
          },
          error: () => {
            this.reportService.hideFilters();
          }
        });
    }
  }

  updateColumnByName(filterSelected: boolean, columnName: string, displayedColumns: string[]): string[] {
    if (filterSelected) {
      displayedColumns = this.pushColumnIfNotVisible(displayedColumns, columnName);
    } else {
      displayedColumns = displayedColumns.filter(x => x !== columnName);
    }

    return displayedColumns;
  }

  private pushColumnIfNotVisible(displayedColumns: string[], columnName: string): string[] {
    let displayedColumnsCopy = displayedColumns;
    if (!displayedColumns.find(column => column === columnName)) {
      displayedColumnsCopy = displayedColumns.concat(columnName);
    }
    return displayedColumnsCopy;
  }
}
