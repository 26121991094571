import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiConfig } from '../form-components.utils';
import { BaseFormControlComponent } from '@shared/components/form/components/base-form-control/base-form-control.component';

@Component({
  selector: 'stx-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent extends BaseFormControlComponent {
  private readonly blacklistedNonNumericChars = 'e+-';
  private readonly blacklistedNonNumericCharsRegex = /[e+-]/;

  @Input() label: string;
  @Input() type: 'text' | 'number' | 'time' = 'text';
  @Input() options: any[];
  @Input() styling: UiConfig;
  @Input() unit: string = '';
  @Input() minNumberValue: number;
  @Input() maxNumberValue: number;
  @Input() subLabel: string = '';
  @Input() fullWidth: boolean;
  @Input() isRtlLanguage: boolean;
  @Input() customErrorText: string;
  @Input() suffix: string;

  @Output() inputHasValue = new EventEmitter();

  inputChanged(): void {
    if (this.formGroup.get(this.controlName).value) {
      this.inputHasValue.emit(true);
    } else {
      this.inputHasValue.emit(false);
    }
  }

  keyDownPreventNonNumericChar(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    if (this.blacklistedNonNumericCharsRegex.test(pastedText)) {
      event.preventDefault();
    }
  }

  pastePreventNonNumeric(event) {
    if (this.blacklistedNonNumericChars.includes(event.key)) {
      event.preventDefault();
    }
  }
}
