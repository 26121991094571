<div [formGroup]="formGroup">
  <label *ngIf="label" for="{{ controlName }}" class="form-element-label">
    {{ label | translate }} {{ labelSuffix }}
    <stx-tooltip *ngIf="tooltipContent" [ngClass]="{ 'hide-on-print': print }" [content]="tooltipContent"></stx-tooltip>
  </label>

  <mat-radio-group
    name="{{ radioGroupId !== null && radioGroupId !== undefined ? radioGroupId : controlName }}"
    [formControlName]="controlName"
    id="{{ radioGroupId !== null && radioGroupId !== undefined ? radioGroupId : controlName }}"
    [class.form-element-group-column]="layoutDirection === 'column'"
    [class.form-element-group-row]="layoutDirection === 'row'"
    [class.form-element-group-grid-2cols]="layoutDirection === '2cols'"
    [class.form-element-group-grid-3cols]="layoutDirection === '3cols'"
  >
    <div *ngFor="let option of options; last as lastOption">
      <label *ngIf="lastOptionSeparateLabel && lastOption" class="form-element-label">{{ lastOptionSeparateLabel | translate }}</label>
      <mat-radio-button data-testid="radio-group-component" [value]="option.value">
        <ng-container *ngIf="!print; else printed">
          {{ option.label | translate }}
        </ng-container>
        <ng-template #printed>
          <span [ngStyle]="{ 'font-size': '1.25rem' }">{{ option.label | translate }}</span>
        </ng-template>
        <stx-tooltip [ngClass]="{ 'hide-on-print': print }" *ngIf="option.tooltipText" [content]="option.tooltipText"></stx-tooltip>
      </mat-radio-button>
      <div *ngIf="option.subtitle" class="form-element-secondary-label fs-13 p-l-25">{{ option.subtitle | translate }}</div>
    </div>
  </mat-radio-group>
  <ng-container *ngIf="!disableWarning">
    <mat-error
      class="stx-mat-error-align"
      [ngClass]="{ 'hide-on-print': print }"
      *ngIf="formGroup.get(controlName)?.invalid && formGroup.get(controlName)?.touched"
    >
      {{ 'error.select' | translate }}
    </mat-error>
  </ng-container>
</div>
