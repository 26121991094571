import { Component, Input } from '@angular/core';
import { InputComponent } from '@shared/components/form/components/input/input.component';

@Component({
  selector: 'stx-nutrition-decimal-numbers-input',
  templateUrl: './nutrition-decimal-numbers-input.component.html'
})
export class NutritionDecimalNumbersInputComponent extends InputComponent {
  @Input() type: 'text' = 'text';
}
