import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '@shared/models/user/user.model';

//TODO#9896 - Notice for refactor
@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  private currentUser$: BehaviorSubject<User | null> = new BehaviorSubject(null);
  private readonly currentUserObservable = this.currentUser$.asObservable();

  constructor() {}

  public getCurrentUser(): User | null {
    return this.currentUser$.value;
  }

  public getUserSubject(): BehaviorSubject<User | null> {
    return this.currentUser$;
  }

  public setCurrentUser(user?: User): void {
    this.currentUser$.next(user);
  }

  /**
   * For testing - to allow jasmin to mock the observable returned.
   */
  get currentUserObs() {
    return this.currentUserObservable;
  }
}
