import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipTemplate } from '@shared/modules/general-commons/components/tooltip/tooltip.model';
import { BaseFormControlComponent } from '@shared/components/form/components/base-form-control/base-form-control.component';

@Component({
  selector: 'stx-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends BaseFormControlComponent {
  @Input() name: string;
  @Input() tooltip: string | TooltipTemplate;
  @Input() direction = 'ltr';
  @Output() updateCheckbox = new EventEmitter();

  subtitles: string[];

  @Input()
  set subtitle(input: string | string[]) {
    if (typeof input === 'string') {
      this.subtitles = [input as string];
    } else {
      this.subtitles = input as string[];
    }
  }

  checkboxChanged(isSelected: boolean): void {
    this.updateCheckbox.emit(isSelected);
  }
}
