<div *ngFor="let line of rowInputs">
  {{ line }}
</div>
<div *ngFor="let line of rowInputsToTranslate">
  {{ line | translate }}
</div>
<div>
  <ng-container *ngFor="let entry of inlineInputsToTranslate"> {{ entry | translate }} &nbsp; </ng-container>
  <ng-container *ngFor="let entry of inlineInputs"> {{ entry | translate }} &nbsp; </ng-container>
</div>
