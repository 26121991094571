<stx-modal>
  <ng-container>
    <div class="text-important m-b-25">
      {{ 'login.email.help' | translate }}
    </div>
    <stx-action-buttons
      [showSecondary]="false"
      [primaryDisabled]="false"
      [asyncPrimaryAction]="closeModal"
      [primaryText]="'common.ok' | translate"
    ></stx-action-buttons>
  </ng-container>
</stx-modal>
