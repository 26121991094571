import { MixedDentitionAssessment } from '@/src/app/features/ortho/models/mixed-dentition-assessment.model';
import { OrthoAssessmentFormApi } from '@/src/app/features/ortho/shared/ortho-assessment-form-api';
import { mixedDentitionAssessmentApiRoute } from '@/src/app/features/ortho/utils/ortho-routes.consts';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MixedDentitionAssessmentService extends OrthoAssessmentFormApi<MixedDentitionAssessment> {
  constructor(httpClient: HttpClient) {
    super(httpClient, mixedDentitionAssessmentApiRoute);
  }
}
