<div [formGroup]="rootFormGroup" class="form-element-group-column">
  <ng-container *ngFor="let rootOption of dictionariesTree; index as i">
    <div class="align-middle flex-row-container">
      <mat-checkbox data-testid="checkbox-tree-filter" [formControlName]="rootOption.value" class="display-block">
        {{ rootOption.label | translate }}
      </mat-checkbox>
      <ng-container *ngIf="makeSecondSubSectionExpand || makeSubSectionExpand">
        <mat-icon matSuffix class="cursor-pointer" (click)="toggleVisibility(i)">expand_more</mat-icon>
      </ng-container>
    </div>
    <ng-container *ngIf="!makeSubSectionExpand || subSectionsVisibilityState[i]">
      <ng-container *ngIf="rootOption.subSections">
        <div *ngFor="let subSection of rootOption.subSections; last as last" class="indented-form-block force-vt-spacing">
          <ng-container *ngIf="showSubsection(rootOption, subSection)">
            <stx-checkbox-group-filter
              [filterName]="rootOption.value + subSection.name"
              [formGroupReference]="childrenFormGroup.get(subSection.name)"
              [dictionaryOptions]="subSection.values"
            ></stx-checkbox-group-filter>
            <hr *ngIf="!last" class="form-subsection-separator" />
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
