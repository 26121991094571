import { MaintenanceInterceptor } from '@/src/app/core/maintenance/maintenance.interceptor';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TempLangInterceptor } from '../features/authentication/helpers/temp-lang-interceptor';
import { PermissionModule } from './permissions/permission.module';
import { AlertModule } from '@shared/components/alert/alert.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, PermissionModule, AlertModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TempLangInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MaintenanceInterceptor, multi: true }
  ],
  exports: []
})
export class CoreModule {}
