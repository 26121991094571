<div class="form-row" [ngClass]="{ 'page-break-inside-avoid p-t-30': print }">
  <p class="form-section-heading">
    {{ 'speech.sample_recording' | translate }}
    <stx-tooltip [ngClass]="{ 'hide-on-print': print }" content="speech_assessment.tooltip.sample_recording"></stx-tooltip>
  </p>
</div>
<div class="form-columns-1-1" [ngClass]="{ 'flex-row-important': print }">
  <div class="form-column">
    <stx-speech-sample-video-recording-column
      [parentOrderNameToSet]="
        formType === FormType.SPEECH_ASSESSMENT
          ? ParentOrderName.SPEECH_ASSESSMENT_SAMPLE_SYLLABLE_REPETITION
          : ParentOrderName.SPEECH_TREATMENT_SAMPLE_SYLLABLE_REPETITION
      "
      [formMode]="formMode"
      [formGroup]="formGroup"
      [repetitionTexts]="syllableRepetitionTexts"
      [tooltip]="'speech_assessment.tooltip.repetition_1' | translate"
      [print]="print"
      label="speech.repetition_1"
    ></stx-speech-sample-video-recording-column>
  </div>
  <div class="form-column">
    <stx-speech-sample-video-recording-column
      [parentOrderNameToSet]="
        formType === FormType.SPEECH_ASSESSMENT
          ? ParentOrderName.SPEECH_ASSESSMENT_SAMPLE_SENTENCE_REPETITION
          : ParentOrderName.SPEECH_TREATMENT_SAMPLE_SENTENCE_REPETITION
      "
      [formMode]="formMode"
      [formGroup]="formGroup"
      [repetitionTexts]="sentenceRepetitionTexts"
      [tooltip]="'speech_assessment.tooltip.repetition_2' | translate"
      [print]="print"
      [displayLanguageChoice]="true"
      label="speech.repetition_2"
    ></stx-speech-sample-video-recording-column>
  </div>
</div>
