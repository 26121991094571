<stx-form-panel [class.not-expanded]="!expanded">
  <div class="form-row">
    <p class="form-section-heading">
      <ng-content select="[slot=heading]"></ng-content>&nbsp;<fa-icon [icon]="icon" (click)="toggle()"></fa-icon>
    </p>
  </div>
  <div class="expandable-panel-content">
    <ng-content></ng-content>
  </div>
</stx-form-panel>
