import { emptyImageCell } from '@shared/components/form/components/image-group/image-group.model';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';

export const psioApplianceTypes = [
  {
    label: 'presurgical.tap_lip',
    name: 'tapLip',
    value: false
  },
  {
    label: 'presurgical.tap_stent',
    name: 'tapStent',
    value: false
  },
  {
    label: 'presurgical.max_appliance',
    name: 'maxAppliance',
    value: false
  },
  {
    label: 'presurgical.nam',
    name: 'nam',
    value: false
  }
];

export const psioAssessmentParentOrderNames = [
  ParentOrderName.ORTHO_FRONTAL_PRE,
  ParentOrderName.ORTHO_LEFT_PRE,
  ParentOrderName.ORTHO_RIGHT_PRE,
  ParentOrderName.ORTHO_BASAL_PRE,
  ParentOrderName.ORTHO_MAXILLARY_PRE,
  ParentOrderName.ORTHO_APPLIANCE_PRE,
  ParentOrderName.ORTHO_APPLIANCE_BABY_PRE
];

export const psioTreatmentParentOrderNames = [
  ParentOrderName.ORTHO_FRONTAL_POST,
  ParentOrderName.ORTHO_LEFT_POST,
  ParentOrderName.ORTHO_RIGHT_POST,
  ParentOrderName.ORTHO_BASAL_POST,
  ParentOrderName.ORTHO_MAXILLARY_POST,
  ParentOrderName.ORTHO_FRONTAL_SMILE_POST
];

export const presurgicalOrthopedicsAssessmentImagesConfigs = [
  {
    parentOrderName: ParentOrderName.ORTHO_FRONTAL_PRE,
    label: 'order_name.frontal',
    isRequired: true
  },
  emptyImageCell,
  {
    parentOrderName: ParentOrderName.ORTHO_LEFT_PRE,
    label: 'order_name.left',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_RIGHT_PRE,
    label: 'order_name.right',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_BASAL_PRE,
    label: 'order_name.basal',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_MAXILLARY_PRE,
    label: 'order_name.maxillary',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_APPLIANCE_PRE,
    label: 'order_name.appliance',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_APPLIANCE_BABY_PRE,
    label: 'order_name.appliance_baby',
    isRequired: true
  }
];

export const presurgicalOrthopedicsTreatmentImagesConfigs = [
  {
    parentOrderName: ParentOrderName.ORTHO_FRONTAL_POST,
    label: 'order_name.frontal',
    isRequired: true
  },
  emptyImageCell,
  {
    parentOrderName: ParentOrderName.ORTHO_LEFT_POST,
    label: 'order_name.left',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_RIGHT_POST,
    label: 'order_name.right',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_BASAL_POST,
    label: 'order_name.basal',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_MAXILLARY_POST,
    label: 'order_name.maxillary',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_FRONTAL_SMILE_POST,
    label: 'order_name.frontal_smile',
    isRequired: false
  }
];

export const presurgicalOrthopedicsTypesFields = ['tapLip', 'tapStent', 'maxAppliance', 'nam', 'otherDevice'];
