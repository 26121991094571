import { DictionaryTranslation } from '@/src/app/features/patient/models/patient.model';
import { FormStatus } from '@/src/app/shared/enums/form-status.enum';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

export interface HorizontalFormElementSetup {
  name: string;
  nameSuffix?: string;
  err?: string;
  hidePassword?: boolean;
  type?: FormFieldType;
  dataSrc?: () => Array<any>;
  subFormGroup?: Map<string, HorizontalFormElementSetup>;
  title?: string;
  checked?: boolean;
  formControlName?: string;
  subLabel?: string;
  subLabelRange?: string;
}

export enum FormFieldType {
  SELECT = 'select',
  RADIO = 'radio',
  DATE = 'date',
  INPUT = 'input',
  BOOLEAN = 'boolean',
  TIME = 'time',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  TEXTAREA = 'textarea'
}

export class ReadonlyFormUtils {
  static getRadioBtnValueFromControl(dataSrc: DictionaryTranslation[], control: AbstractControl): string {
    if (!control) return '';
    return this.getRadioBtnValue(dataSrc, control.value);
  }
  static getRadioBtnValue(dataSrc: DictionaryTranslation[], value: string | number): string {
    const result = dataSrc?.find(entry => entry.value === value)?.label;
    return !!result ? result : '';
  }
}

export enum FormMode {
  NEW,
  EDIT,
  READONLY
}

export const yesNoAnswers: DictionaryTranslation[] = [
  { label: 'yes', value: true },
  { label: 'no', value: false }
];

export const yesNoShort: DictionaryTranslation[] = [
  { label: 'yes', value: 1 },
  { label: 'no', value: 0 }
];

export const yesNoDontKnow: DictionaryTranslation[] = [
  { label: 'yes', value: 1 },
  { label: 'no', value: 0 },
  { label: 'dont_know', value: 2 }
];

export const formStatusMap = new Map<FormStatus, number>([
  [FormStatus.SUBMITTED, 0],
  [FormStatus.ACCEPTED, 1],

  [FormStatus.REJECTED, 2],
  [FormStatus.EXCEEDS_QUOTA, 3],

  [FormStatus.DISCONTINUED, 8],
  [FormStatus.IN_PROGRESS, 9]
]);

export function findElementFromListById<T extends { id: any }>(idControl: UntypedFormControl, list$: Observable<T[] | null>) {
  return findElementFromList(idControl, list$, element => element.id);
}

export function findElementFromList<T>(idControl: UntypedFormControl, list$: Observable<T[] | null>, idFunc: (element: T) => any) {
  const id$ = idControl.valueChanges.pipe(distinctUntilChanged());
  return combineLatest([list$, id$]).pipe(
    map(([list, idValue]) => {
      return list?.find(tc => idFunc(tc) === idValue);
    })
  );
}

export function toggleControlsStateBasedOnControlValue(
  formGroup: UntypedFormGroup,
  mainControlName: string,
  dependentControlsNames: Array<string>,
  subSink: SubSink
) {
  subSink.sink = formGroup?.get(mainControlName)?.valueChanges.subscribe(value => {
    if (value === 0) {
      dependentControlsNames.forEach(x => {
        formGroup?.get(x).reset(null, { emitEvent: false });
        formGroup?.get(x).disable({ emitEvent: false });
      });
    } else {
      dependentControlsNames.forEach(x => {
        formGroup?.get(x).enable({ emitEvent: false });
      });
    }
  });
}
export function toggleMainFieldStateByDependantFields(
  formGroup: UntypedFormGroup,
  mainField: BehaviorSubject<number>,
  mainFieldName: string,
  fieldNames: string[],
  subSink: SubSink
): void {
  subSink.sink = mainField.subscribe(val => formGroup.get(mainFieldName)?.setValue(val, { emitEvent: val === 0 }));
  fieldNames.forEach(field => {
    formGroup.get(field)?.valueChanges.subscribe(() => {
      let anySelected: boolean;
      fieldNames.forEach(innerField => {
        if (formGroup.get(innerField)?.value === true) {
          mainField.next(1);
          anySelected = true;
        }
      });
      if (!anySelected && formGroup.touched) {
        mainField.next(0);
      }
    });
  });
}

export function validValue$(control: AbstractControl): Observable<any> {
  return control.valueChanges.pipe(filter(() => control.valid));
}

export function validWithInitialValue$(control: AbstractControl, subSink: SubSink): Observable<any> {
  const valueChange$ = control.valueChanges.pipe(filter(() => control.valid));
  const valueChangesWithInitialValue$ = new BehaviorSubject(control.value);
  subSink.sink = valueChange$.subscribe(next => valueChangesWithInitialValue$.next(next));
  return valueChangesWithInitialValue$;
}
