import { Component } from '@angular/core';
import { faFile } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'stx-file-icon',
  template: '<fa-icon [icon]="faFile"></fa-icon>'
})
export class FileIconComponent {
  readonly faFile = faFile;
}
