<ng-container *ngIf="!columns">
  <ng-container *ngFor="let photoSet of mediaSets">
    <h3 *ngIf="photoSet.sectionLabelKey">{{ photoSet.sectionLabelKey | translate }}</h3>
    <stx-readonly-view-columns [wrap]="true">
      <ng-container *ngFor="let media of photoSet.mediaWithLabels">
        <ng-container *ngIf="media">
          <ng-container *stxReadonlyViewColumn>
            <stx-readonly-media-field [media]="media"></stx-readonly-media-field>
          </ng-container>
        </ng-container>
      </ng-container>
    </stx-readonly-view-columns>
  </ng-container>
</ng-container>
<ng-container *ngIf="columns">
  <ng-container *ngFor="let row of mediaSetsAsRows">
    <stx-readonly-view-columns>
      <ng-container *ngFor="let media of row">
        <ng-container *stxReadonlyViewColumn>
          <stx-readonly-media-field [media]="media"></stx-readonly-media-field>
        </ng-container>
      </ng-container>
    </stx-readonly-view-columns>
  </ng-container>
</ng-container>
