export enum SurgicalInterventionType {
  ALVEOLAR_BONE_GRAFT = 'ALVEOLAR_BONE_GRAFT',
  FISTULA_REPAIR = 'FISTULA_REPAIR',
  LIP_NOSE_REVISION = 'LIP_NOSE_REVISION',
  PRIMARY_CLEFT_PALATE = 'PRIMARY_CLEFT_PALATE',
  PRIMARY_LIP_NOSE_BILATERAL = 'PRIMARY_LIP_NOSE_BILATERAL',
  PRIMARY_LIP_NOSE_UNILATERAL = 'PRIMARY_LIP_NOSE_UNILATERAL',
  SECONDARY_CLEFT_PALATE = 'SECONDARY_CLEFT_PALATE',
  VELOPHARYNGEAL_DISFUNCTION = 'VELOPHARYNGEAL_DISFUNCTION',
  OTHER = 'OTHER'
}

export enum FistulaRepairType {
  PALATAL = 1,
  BUCCAL,
  FAMM,
  TONGUE,
  ALLOPLASTIC,
  OTHER
}
export enum LipNoseRevisionRepairType {
  PRIMARY = 1,
  OPEN,
  CLOSED,
  LATE,
  OTHER
}
export enum PrimaryCleftPalateRepairType {
  LAGENBECK = 1,
  PUSHBACK,
  IVVP,
  VW_KILNER,
  VW_V_Y,
  FURLOWS,
  DELAIRE,
  TWO_FLAP_PALATOPLASTY,
  OTHER
}
export enum PrimaryLipNoseBilateralRepairType {
  STRAIGHT_LINE = 1,
  ROSE_THOMPSON,
  MILLARD,
  MULLIKEN,
  TWO_STAGE,
  OTHER
}

export enum PrimaryLipNoseUnilateralRepairType {
  MILLARD = 1,
  STRAIGHT_LINE,
  FISHER,
  ROSE_THOMPSON,
  TRIANGULAR,
  QUADRILATERAL,
  OTHER
}
