import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { ageMonthToYears } from '@/src/app/utils/date.utils';
import { FormMode, yesNoAnswers } from '@/src/app/utils/form.utils';
import { patientDetailsRoute } from '@/src/app/utils/routing.utils';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from '../../../patient/models/patient.model';
import { NutritionScreening } from '../../models/nutrition-screening.model';
import { NutritionScreeningHistoricalService } from '../../services/nutrition-screening-historical.service';
import { screeningGeneralInfoFields } from '../../utils/screening-nutirtion.utils';
import { FormMediaUtils } from '@utils/form-media.utils';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

//This is a legacy code we should not use it in future
@Component({
  selector: 'stx-nutrition-screening',
  templateUrl: './nutrition-screening-historical.component.html'
})
export class NutritionScreeningHistoricalComponent extends BaseComponent implements OnInit {
  readonly yesNo = yesNoAnswers;
  readonly formType = FormType.NUTRITION_SCREENING_HISTORICAL;
  readonly formMode = FormMode.READONLY;
  readonly parentOrderName = ParentOrderName.NUTRITION_SCREENING_HISTORICAL;
  readonly treatmentType = TreatmentType.NUTRITION_SCREENING_HISTORICAL;

  nutritionScreeningFormGroup: UntypedFormGroup;

  @Input() nutritionScreening: NutritionScreening;
  @Input() print: boolean;
  @Input() patient: Patient;
  @Input() expandable: boolean;

  constructor(
    private nutritionScreeningService: NutritionScreeningHistoricalService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    if (!this.nutritionScreening) {
      this.subSink.sink = this.route.params.subscribe(params => {
        if (params.id) {
          this.getNutritionScreening(params.id);
        }
      });
    } else {
      this.patchForm(this.nutritionScreening);
    }
  }

  private initForm() {
    this.nutritionScreeningFormGroup = this.formBuilder.group({
      dateScreen: [],
      treatmentCenterId: [],
      ageMonth: [],
      weightIs: [],
      heightIs: [],
      hemoglobinIs: [],
      muacIs: [],
      providerHealthWorker: [],
      providerNurse: [],
      providerNutritionist: [],
      providerOther: [],
      providerSocialworker: [],
      providerSurgeon: [],
      providerTherapist: [],
      [ParentOrderName.NUTRITION_SCREENING_HISTORICAL]: [],
      breastfeeding: [],
      breastfeeingNomilk: [],
      breastfeeingNolatch: [],
      breastfeeingPain: [],
      breastfeeingTooold: [],
      breastfeeingAgainst: [],
      breastfeeingBabycries: [],
      breastfeeingOther: [],
      feedBreastmilk: [],
      feedExprmilk: [],
      feedCowmilk: [],
      feedFormula: [],
      feedMacerated: [],
      feedSolids: [],
      feedOther: [],
      feedhowBreast: [],
      feedhowBottle: [],
      feedhowSpoon: [],
      feedhowCup: [],
      feedhowHand: [],
      feedhowSyringe: [],
      feedhowIv: [],
      feedhowOther: [],
      probfeedTire: [],
      probfeedNose: [],
      probfeedChoke: [],
      probfeedRefuse: [],
      probfeedOther: [],
      feedGuidance: [],
      feedCounsel: [],
      givenInfoFeed: [],
      givenInfoImportance: [],
      givenInfoStrategies: [],
      givenInfoHandson: [],
      givenInfoExpressmilk: [],
      givenInfoOther: [],
      givenFood: [],
      givenFoodMore: [],
      givenInfoPrep: [],
      givenInfoTreatment: [],
      givenSupport: [],
      givenSupportMore: []
    });
  }

  private getNutritionScreening(id: TreatmentId) {
    this.wsHelperService.callWithSpinner(this.nutritionScreeningService.getNutritionScreening(id)).subscribe(result => {
      this.nutritionScreening = result;
      this.patchForm(result);
    });
  }

  private patchForm(nutritionScreening: NutritionScreening) {
    this.nutritionScreeningFormGroup.patchValue(nutritionScreening);
    const ageControl = this.nutritionScreeningFormGroup.get(screeningGeneralInfoFields.get('age').formControlName);
    ageControl.setValue(ageMonthToYears(ageControl.value));
    this.nutritionScreeningFormGroup.patchValue(
      FormMediaUtils.getMediaForFormGroup(this.nutritionScreening, [ParentOrderName.NUTRITION_SCREENING_HISTORICAL])
    );
    this.cd.detectChanges();
  }

  delete() {
    this.wsHelperService
      .callWithSpinner(this.nutritionScreeningService.deleteNutritionScreening(this.nutritionScreening.businessId))
      .subscribe(() => {
        this.router.navigate(patientDetailsRoute(this.patient.id));
      });
  }
}
