import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { InputControlComponentModule } from '@shared/components/form/components/input-control/input-control.component';
import { InputPasswordComponentModule } from '@shared/components/form/components/input-password/input-password.component';
import { FormModule } from '@shared/components/form/form.module';
import { LanguageSwitcherComponentModule } from '@shared/components/language-switcher/components/language-switcher/language-switcher.component';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { SpinnerModule } from 'src/app/shared/components/spinner/spinner.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { AuthenticationContainerComponent } from './authentication-container/authentication-container.component';
import { EmailHelpModalComponent } from './components/help-modal/help-modal.component';
import { TokenComponent } from '@src/app/features/authentication/components/token/token.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordConfirmationModalComponent } from './components/reset-password-confirmation-modal/reset-password-confirmation-modal.component';
import { ResetPasswordModalComponent } from './components/reset-password-modal/reset-password-modal.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AuthInterceptor } from './helpers/auth-interceptor';
import { EditSettingsComponentModule } from '@src/app/features/edit-settings/edit-settings.component';
import { PasswordExpiryModalComponent } from '@src/app/features/authentication/components/password-expiry-modal/password-expiry-modal.component';
import { PasswordExpiryComponent } from '@src/app/features/authentication/components/password-expiry/password-expiry.component';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { ReleaseInfoModule } from '@src/app/features/dashboard/components/release-info/release-info.module';

@NgModule({
  declarations: [
    LoginComponent,
    AuthenticationContainerComponent,
    ResetPasswordComponent,
    ResetPasswordModalComponent,
    ResetPasswordConfirmationModalComponent,
    EmailHelpModalComponent,
    TokenComponent,
    PasswordExpiryModalComponent,
    EmailHelpModalComponent,
    PasswordExpiryComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    InputControlComponentModule,
    InputPasswordComponentModule,
    FormModule,
    RouterModule,
    ModalModule,
    SpinnerModule,
    LanguageSwitcherComponentModule,
    EditSettingsComponentModule,
    ReadonlyCommonsModule,
    ReleaseInfoModule
  ],
  exports: [AuthenticationContainerComponent, PasswordExpiryComponent],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }]
})
export class AuthenticationModule {}
