import { DictionaryTranslation } from '@/src/app/features/patient/models/patient.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-inline-readonly-field',
  templateUrl: './inline-readonly-field.component.html'
})
export class InlineReadonlyFieldComponent {
  @Input() labelKey: string;
  @Input() values: string[];
  @Input() dictionaryValues: DictionaryTranslation[];
  @Input() translationKeys: string[];
}
