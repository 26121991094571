import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageDialogData } from '@shared/components/message-dialog/message-dialog-data.model';

@Component({
  selector: 'stx-message-dialog',
  templateUrl: './message-dialog.component.html'
})
export class MessageDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageDialogData) {}

  get singleMessage(): boolean {
    return this.data.translatedMessages?.length === 1;
  }
}
