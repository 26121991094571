<label class="form-label m-t-0 display-block" *ngIf="label">{{ label | translate }}</label>

<div
  *ngIf="uploadedFileName || !!formGroup?.get(parentOrderName)?.value; else placeholderSpacingForFileName"
  class="bold m-t-10 m-b-10 flex align-justify"
>
  {{ getFileName }} [100%]

  <stx-remove-icon (click)="resetFileControl()" [shouldDisplayIcon]="shouldDisplayRemoveIcon"></stx-remove-icon>
</div>
<ng-template #placeholderSpacingForFileName>
  <div class="bold m-t-10 m-b-10">&nbsp;</div>
</ng-template>

<stx-uppy
  class="uppy-image uppy-video-info"
  [height]="115"
  [types]="acceptedTypes"
  [uuid]="uuid"
  (uploadSuccessful)="uploadedFile($event)"
  (touched)="touched()"
></stx-uppy>

<div class="form-row">
  <mat-error class="stx-mat-error-align" *ngIf="showEmptyFileError">{{ 'error.upload_file_empty' | translate }}</mat-error>
  <mat-error class="stx-mat-error-align" *ngIf="showUploadError && !showEmptyFileError">{{ 'error.upload_file' | translate }}</mat-error>
  <ng-container *ngIf="!showEmptyFileError && !showUploadError">
    <div [style]="{ 'min-height': '16px' }"></div>
  </ng-container>
</div>
