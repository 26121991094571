import { getCurrentMonth } from '@/src/app/utils/date.utils';
import { Component, Input } from '@angular/core';
import { StatusStatistics } from '../../statistics.model';

@Component({
  selector: 'stx-statistics-table',
  templateUrl: './statistics-table.component.html',
  styleUrls: ['./statistics-table.component.scss']
})
export class StatisticsTableComponent {
  @Input() treatmentStats: StatusStatistics;
  @Input() tableTreatmentTitle: string;
  @Input() patientCount: number;
  @Input() isOnDashboard = false;
  readonly currentMonth = getCurrentMonth();
  get currentMonthLabel(): string {
    return 'date.month_names.short.' + this.currentMonth;
  }
}
