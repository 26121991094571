import { permanentDentitionMidApiRoute } from '@/src/app/features/ortho/utils/ortho-routes.consts';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrthoMidAssessmentFormApi } from '@src/app/features/ortho/shared/ortho-mid-assessment-form-api';

@Injectable({
  providedIn: 'root'
})
export class PermanentDentitionMidAssessmentService extends OrthoMidAssessmentFormApi {
  constructor(httpClient: HttpClient) {
    super(httpClient, permanentDentitionMidApiRoute);
  }
}
