<ng-container *ngIf="treatment">
  <stx-readonly-view-columns [wrap]="true">
    <ng-container *stxReadonlyViewColumn>
      <stx-simple-readonly-field label="speech_treatment.report_date" [value]="treatment.reportDate"></stx-simple-readonly-field>
      <stx-readonly-field label="treatment_center.treatment_center">
        <stx-tc-link [treatmentCenter]="treatment.treatmentCenter"></stx-tc-link>
      </stx-readonly-field>
      <stx-simple-readonly-field label="status" [value]="treatmentStatusLabel | translate"> </stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech.practitioner"
        [value]="treatment.practitioner?.name | localName : treatment.practitioner?.nameLoc"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field label="language" [value]="treatment.countryLanguage?.name"></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="age"
        [value]="[treatment.ageMonth | ageYearMonth, treatment.ageMonthEnd | ageYearMonth]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech_instructions.treatment.period"
        [value]="[treatment.treatmentStart, treatment.treatmentEnd]"
        separator="&nbsp;&mdash;&nbsp;"
      >
      </stx-simple-readonly-field>
      <stx-simple-readonly-field
        *ngIf="treatment.isAbandoned !== null && treatment.isAbandoned !== undefined"
        [value]="[
          'reports.speech_discontinue' | if : treatment.isAbandoned | translate,
          'speech.patient_discharged' | if : !treatment.isAbandoned | translate,
          treatment.abandonReasonMore
        ]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field label="speech.form_version" [value]="treatment.formVersion"></stx-simple-readonly-field>
    </ng-container>
    <ng-container *stxReadonlyViewColumn>
      <stx-readonly-view-section-header
        *ngIf="hasTreatmentModel"
        labelKey="speech_treatment.treatment_model"
      ></stx-readonly-view-section-header>
      <stx-simple-readonly-field label="speech_treatment.service_count" [value]="treatment.serviceCount"></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="treatment.telehealth_session_number"
        [value]="treatment.telehealthCount"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech_treatment.treatment_model"
        [value]="[
          'speech_treatment.service_indiv' | if : treatment.serviceIndiv | translate,
          'speech_treatment.service_group' | if : treatment.serviceGroup | translate,
          'speech_treatment.service_camp' | if : treatment.serviceCamp | translate
        ]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="speech_treatment.treatment_frequency"
        [value]="[
          'speech_treatment.service_daily' | if : treatment.serviceDaily | translate,
          'speech_treatment.service_weekly' | if : treatment.serviceWeekly | translate,
          'speech_treatment.service_monthly' | if : treatment.serviceMonthly | translate,
          treatment.serviceOther
        ]"
      ></stx-simple-readonly-field>
      <stx-readonly-view-section-header
        *ngIf="hasTreatmentOutcomes"
        labelKey="speech_treatment.treatment_outcomes"
      ></stx-readonly-view-section-header>
      <stx-simple-readonly-field
        label="speech_treatment.improvement"
        [value]="treatment.improvement | dict : 'speech_improved' | async | translate"
      ></stx-simple-readonly-field>
    </ng-container>
    <ng-container *stxReadonlyViewColumn>
      <stx-simple-readonly-field
        label="speech_treatment.recommendations_readonly"
        [value]="[
          'speech_treatment.recommend_speech_therapy' | if : treatment.recommendSpeechTherapy | translate,
          'speech_treatment.recommend_home_therapy' | if : treatment.recommendHomeTherapy | translate,
          'speech_treatment.recommend_further_assessment_for_vpd' | if : treatment.recommendVpdAssessment | translate,
          'speech_treatment.recommend_release' | if : treatment.recommendRelease | translate,
          treatment.recommendComment
        ]"
      ></stx-simple-readonly-field>
    </ng-container>
  </stx-readonly-view-columns>
  <hr *ngIf="hasGoals" />
  <stx-readonly-view-section-header *ngIf="hasGoals" labelKey="speech_instructions.treatment.goals"></stx-readonly-view-section-header>
  <ng-container *ngFor="let goal of goals">
    <stx-readonly-view-columns [wrap]="true">
      <ng-container *stxReadonlyViewColumn>
        <stx-simple-readonly-field
          [label]="('goal' | translate) + ' ' + goal.number"
          [value]="[goal.goal, goal.goalOther]"
        ></stx-simple-readonly-field>
      </ng-container>
      <ng-container *stxReadonlyViewColumn>
        <stx-simple-readonly-field
          label="speech.goal_status"
          [value]="goal.status | dict : 'goal_status' | async | translate"
        ></stx-simple-readonly-field>
      </ng-container>
      <ng-container *stxReadonlyViewColumn>
        <stx-simple-readonly-field
          label="speech_instructions.treatment.period"
          [value]="[goal.start, goal.end]"
          separator="&nbsp;&mdash;&nbsp;"
        ></stx-simple-readonly-field>
      </ng-container>
    </stx-readonly-view-columns>
  </ng-container>
  <hr *ngIf="hasGoals" />
  <stx-readonly-view-columns>
    <ng-container *stxReadonlyViewColumn>
      <stx-readonly-media-field
        [media]="findMediaWithLabels(parentOrderNames.SPEECH_TREATMENT_SAMPLE_SYLLABLE_REPETITION, ['speech.videos.syllables'])"
      ></stx-readonly-media-field>
    </ng-container>
    <ng-container *stxReadonlyViewColumn>
      <stx-readonly-media-field
        [media]="findMediaWithLabels(parentOrderNames.SPEECH_TREATMENT_SAMPLE_SENTENCE_REPETITION, ['speech.videos.sentences'])"
      ></stx-readonly-media-field>
    </ng-container>
  </stx-readonly-view-columns>
</ng-container>
