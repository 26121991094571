import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../modules/material/material.module';
import { AutocompleteMultiSelectComponent } from './autocomplete-multi-select.component';

@NgModule({
  declarations: [AutocompleteMultiSelectComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, TranslateModule, ScrollingModule],
  exports: [AutocompleteMultiSelectComponent]
})
export class AutocompleteMultiSelectComponentModule {}
