import { getLanguageByShortcut } from '@utils/language.utils';
import { supportLanguages } from './app.component';
import { TranslateService } from '@ngx-translate/core';
import { Injector } from '@angular/core';
import { AuthService } from './features/authentication/services/auth.service';
import { LOCATION_INITIALIZED } from '@angular/common';
import { FeatureToggleService } from '@core/feature-toggle/feature-toggle.service';
import { FeatureEnum } from '@core/feature-toggle/feature.enum';
import { ConfigurationService } from '@shared/services/configuration.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

export function initUserAndLocalisation(
  translateService: TranslateService,
  injector: Injector,
  authService: AuthService,
  currentUserService: CurrentUserService
) {
  return () =>
    authService
      .isAuthenticated()
      .toPromise()
      .then(isAuthenticated => {
        return setupLocalisation(translateService, injector, currentUserService).then(() => Promise.resolve(isAuthenticated));
      });
}

export function loadConfig(configService: ConfigurationService) {
  return () => configService.loadConfiguration();
}

function setLangAndResolve(translateService: TranslateService, userLang: string, resolve: any) {
  translateService.setDefaultLang(userLang);
  translateService.use(userLang).subscribe({
    complete: () => resolve(null)
  });
}

export function setupLocalisation(translateService: TranslateService, injector: Injector, currentUserService: CurrentUserService) {
  return new Promise<any>((resolve: any) => {
    let locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translateService.addLangs(supportLanguages);
      const defaultLang = getLanguageByShortcut(translateService.getBrowserLang());
      const currentUser = currentUserService.getCurrentUser();
      if (currentUser) {
        const userLang = getLanguageByShortcut(currentUser.tempLang || currentUser.lang).name;
        setLangAndResolve(translateService, userLang, resolve);
      } else if (defaultLang && supportLanguages.includes(defaultLang.name)) {
        setLangAndResolve(translateService, defaultLang.name, resolve);
      } else {
        setLangAndResolve(translateService, 'English', resolve);
      }
    });
  });
}
export function initFeatureToggles(featureToggleService: FeatureToggleService): () => Promise<FeatureEnum[]> {
  return () => featureToggleService.initFeatureToggles();
}
