import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { CountryDictionary, ProvinceDictionary } from '@/src/app/shared/models/geo.model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { showStandardError } from '@src/app/shared/validation/error.utils';
import {
  OnlinePatientReleaseForm,
  PatientDataForReleaseForm,
  ReleaseFormModalParameters
} from '@shared/components/patient-document-upload/patient-release-form.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { Address } from '@shared/models/address.model';
import { PatientReleaseFormOnlineForm } from '@shared/components/patient-document-upload/patient-release-form-online-dialog/patient-release-form-online-dialog.model';
import { scrollToFirstInvalidField } from '@utils/scroll.utils';

function join(values: string[]): string {
  return values.filter(value => !!value).join(' ');
}

class OnlineReleaseFormData {
  readonly patientName: string;
  readonly patientNameLoc: string;
  readonly guardianName: string;
  readonly addressItems: string[];

  constructor(public readonly patient: PatientDataForReleaseForm, countries: CountryDictionary[]) {
    this.patientName = join([this.patient.firstName, this.patient.middleName, this.patient.lastName]);
    this.patientNameLoc = join([this.patient.firstNameLoc, this.patient.middleNameLoc, this.patient.lastNameLoc]);
    const patientCountry = countries.find(country => country.id === this.patient.address.countryId);
    const patientProvince = patientCountry?.provinces.find(province => province.id === this.patient.address.stateId);
    this.addressItems = [this.patient.address.street1, this.patient.address.zip, patientProvince?.name, patientCountry.name].filter(
      str => !!str
    );
    this.guardianName = join([this.patient.guardianFirstName, this.patient.guardianLastName]);
  }
}

@Component({
  templateUrl: './patient-release-form-online-dialog.component.html',
  styleUrls: ['./patient-release-form-online-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientReleaseFormOnlineDialogComponent extends BaseComponent {
  readonly showStandardError = showStandardError;
  readonly signatureControlName = 'base64EncodedSignatureImage';
  readonly releaseFormData: OnlineReleaseFormData;
  readonly translationMap: Map<string, string>;
  readonly countryList$: Subject<CountryDictionary[]>;
  readonly provinceList$: Subject<ProvinceDictionary[]>;
  readonly form: PatientReleaseFormOnlineForm;
  private readonly language: string;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly dialogRef: MatDialogRef<PatientReleaseFormOnlineDialogComponent>,
    private readonly elementRef: ElementRef,
    private readonly zone: NgZone,
    @Inject(MAT_DIALOG_DATA) parameters: ReleaseFormModalParameters
  ) {
    super();
    this.releaseFormData = new OnlineReleaseFormData(parameters.patient, parameters.countries);
    this.language = parameters.lang;
    this.translationMap = parameters.translations;
    this.countryList$ = new BehaviorSubject<CountryDictionary[]>(parameters.countries);
    this.provinceList$ = new BehaviorSubject<ProvinceDictionary[]>([]);
    this.form = new PatientReleaseFormOnlineForm(this.countryList$, this.provinceList$);
    this.subSink.sink = this.form.country$.subscribe(country => {
      this.provinceList$.next(country?.provinces || []);
    });
  }

  get isRtlLanguage(): boolean {
    return this.language === 'ar' || this.language === 'ur';
  }

  submit() {
    if (!this.form.formGroup.valid) {
      this.form.formGroup.markAllAsTouched();
      scrollToFirstInvalidField(this.elementRef, this.zone);
      return;
    }
    const guardianAddress: Address = this.form.mainControls.guardianAddressSameAsPatient.value
      ? this.releaseFormData.patient.address
      : this.form.addressFormGroup.value;
    const prf: OnlinePatientReleaseForm = {
      releaseFormLang: this.language,
      patientData: this.releaseFormData.patient,
      guardianAddress,
      base64EncodedSignatureImage: this.form.mainControls.base64EncodedSignatureImage.value
    };
    this.dialogRef.close(prf);
  }

  back() {
    this.dialogRef.close();
  }
}
