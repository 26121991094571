import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '@shared/components/form/form.module';
import { TreatmentModule } from '@shared/components/treatment/treatment.module';
import { MaterialModule } from '@shared/modules/material/material.module';
import { NutritionFollowUpHistoricalComponent } from './components/nutrition-follow-up-pilot/nutrition-follow-up-historical.component';
import { NutritionScreeningHistoricalComponent } from './components/nutrition-screening/nutrition-screening-historical.component';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { NutritionFollowUpPilotReadonlyComponent } from '@src/app/features/nutrition/components/nutrition-follow-up-pilot-readonly/nutrition-follow-up-pilot-readonly.component';
import { NutritionScreeningReadonlyComponent } from '@src/app/features/nutrition/components/nutrition-screening-readonly/nutrition-screening-readonly.component';
import { FormsCommonsModule } from '@shared/modules/forms-commons/forms-commons.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { LocalNamePipeModule } from '@shared/pipes/local-name.pipe';
import { EducationalResourcesModule } from '@src/app/features/educational-resources/educational-resources.module';
import { GenericResourceListModule } from '@src/app/features/educational-resources/shared/generic-resource-list.module';

//This is a legacy code we should not use it in future
@NgModule({
  declarations: [
    NutritionFollowUpHistoricalComponent,
    NutritionFollowUpPilotReadonlyComponent,
    NutritionScreeningHistoricalComponent,
    NutritionScreeningReadonlyComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormModule,
    FormsCommonsModule,
    GeneralCommonsModule,
    MaterialModule,
    ReactiveFormsModule,
    ReadonlyCommonsModule,
    RouterModule,
    TranslateModule.forChild(),
    TreatmentModule,
    EducationalResourcesModule,
    GenericResourceListModule,
    LocalNamePipeModule
  ],
  exports: [NutritionScreeningHistoricalComponent, NutritionFollowUpHistoricalComponent]
})
export class NutritionModule {}
