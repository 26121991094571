import { Component } from '@angular/core';
import { NavigationSubTabEnum } from '@shared/components/navigation/model/nav-item-enum.enum';
import { ResourceSection } from '../../models/resource.model';

@Component({
  templateUrl: './protocols-advisories-guidelines-page.component.html'
})
export class ProtocolsAdvisoriesGuidelinesPageComponent {
  readonly NavigationSubTabEnum = NavigationSubTabEnum;
  readonly source = protocolsAdvisoriesGuidelinesSection;
}

export const protocolsAdvisoriesGuidelinesSection: readonly ResourceSection[] = [
  {
    sectionTitle: 'protocols_advisories_guidelines.medical_protocols',
    resources: [
      {
        label: 'protocols_advisories_guidelines.safety_and_quality_protocol',
        resourceName: 'Safety_and_Quality_Protocol_',
        translationList: [['ar', 'en', 'es', 'fr', 'id', 'pt', 'ru', 'ur', 'vi', 'cn']]
      },
      {
        label: 'protocols_advisories_guidelines.safety_and_quality_protocol.covid',
        resourceName: 'COVID-19_Safety_Quality_Addendum_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'ru', 'ur', 'vi', 'cn']],
        isSeparated: true
      },
      {
        label: 'protocols_advisories_guidelines.safety_checklist',
        resourceName: 'Surgical_Safety_Checklist_for_CLCP_',
        translationList: [['ar', 'en', 'es', 'fr', 'id', 'pt', 'ru', 'vi', 'cn']]
      },
      {
        label: 'protocols_advisories_guidelines.who_safety_checklist',
        resourceName: 'WHO_Surgical_Safety_Checklist_',
        translationList: [['ar', 'en', 'es', 'fr', 'ru', 'cn']]
      },
      {
        label: 'protocols_advisories_guidelines.covid_surgical_patient_checklist',
        resourceName: 'COVID-19_Surgical_Patient_Checklist_',
        translationList: [['en', 'es', 'fr', 'hi']]
      },
      {
        label: 'resources_for_partners.post_operative_discharge.label',
        resourceName: 'Post-op_Discharge_Checklist_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']],
        isSeparated: true
      },
      {
        label: 'protocols_advisories_guidelines.sentinel_event_report_form',
        resourceName: 'Sentinel_Event_Report_Form_',
        translationList: [['en', 'es', 'fr', 'id', 'cn']],
        isSeparated: true
      },
      {
        label: 'protocols_advisories_guidelines.policy_on_multiple_interventions',
        urlList: [
          {
            label: 'English',
            resourceName: 'Multiple_Interventions_en',
            putBuiltUrlInLabel: true
          },
          {
            label: 'Español',
            resourceName: 'Advisory_2019_07_secondary_palate_other_vpd_es',
            putBuiltUrlInLabel: true
          },
          {
            label: 'Français',
            resourceName: 'Advisory_2019_07_secondary_palate_other_vpd_fr',
            putBuiltUrlInLabel: true
          },
          {
            label: 'Bahasa Indonesia',
            resourceName: 'Advisory_2019_07_secondary_palate_other_vpd_id',
            putBuiltUrlInLabel: true
          },
          {
            label: 'Português',
            resourceName: 'Advisory_2019_07_secondary_palate_other_vpd_pt',
            putBuiltUrlInLabel: true
          },
          {
            label: 'Tiếng Việt',
            resourceName: 'Advisory_2019_07_secondary_palate_other_vpd_vi',
            putBuiltUrlInLabel: true
          },
          {
            label: '中文',
            resourceName: 'Advisory_2019_07_secondary_palate_other_vpd_cn',
            putBuiltUrlInLabel: true
          }
        ]
      },
      {
        label: 'protocols_advisories_guidelines.photo_standards',
        resourceName: 'Photo_Standards_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']],
        isSeparated: true
      },
      {
        label: 'protocols_advisories_guidelines.documentation_guide.ortho',
        resourceName: 'Documentation_Guide_Orthopedic_Orthodontic_Treatment_Records_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
      },
      {
        label: 'protocols_advisories_guidelines.ortho_record_checklist',
        resourceName: 'Orthopedic_Orthodontic_Record_Checklist_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
      }
    ]
  },
  {
    sectionTitle: 'protocols_advisories_guidelines.safeguarding_policy',
    resources: [
      {
        label: 'protocols_advisories_guidelines.safeguarding_policy.label',
        resourceName: 'Smile_Train_Global_Safeguarding_Policy_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
      }
    ],
    isSeparated: true
  },
  {
    sectionTitle: 'protocols_advisories_guidelines.guidelines',
    resources: [
      {
        label: 'protocols_advisories_guidelines.speech_assessment_instructions',
        resourceName: 'Speech_Assessment_and_Treatment_Instructions_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']],
        isSeparated: true
      },
      {
        label: 'protocols_advisories_guidelines.velopharyngeal_disfunction_guidelines',
        resourceName: 'Guidelines_for_Velopharyngeal_Dysfunction_Surgery_2019_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi']],
        isSeparated: true
      },
      {
        label: 'protocols_advisories_guidelines.ortho_treatment_grants_guidelines',
        resourceName: 'Partner_Guidelines_Orthodontic_Treatment_Grants_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
      },
      {
        label: 'protocols_advisories_guidelines.ortho_treatment_grants_guidelines.attachment_1',
        resourceName: 'Global_Guidelines_for_Orthodontic_Treatment_Grants_Attachment_1_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']],
        isSeparated: true
      },
      {
        label: 'protocols_advisories_guidelines.pilot_treatment_grants.ear_nose_throat_ent',
        resourceName: 'Smile_Train_Guidelines_ENT_Pilot',
        translationList: [['']],
        isSeparated: true
      },
      {
        label: 'protocols_advisories_guidelines.oral_health_in_cleft_care',
        resourceName: 'PRGM20-039-Oral_health_in_CCC_v5_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'ru', 'vi', 'cn']]
      }
    ]
  },
  {
    sectionTitle: 'protocols_advisories_guidelines.comprehensive_cleft_care',
    resources: [
      {
        label: 'protocols_advisories_guidelines.comprehensive_cleft_care.text',
        resourceName: 'Smile_Train-CCC_timeline-text_',
        translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']],
        isSeparated: true
      },
      {
        label: 'protocols_advisories_guidelines.comprehensive_cleft_care.graphic',
        resourceName: 'Smile_Train-CCC_timeline-graphic_',
        translationList: [['en', 'es', 'fr', 'id', 'vi', 'cn']],
        isSeparated: true
      }
    ]
  }
];
