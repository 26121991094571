import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-value-separator',
  template: '<span class="value-separator">{{ value }}</span>',
  styleUrls: ['./value-separator.component.scss']
})
export class ValueSeparatorComponent {
  @Input()
  value: string = ' | ';
}
