import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component, Input } from '@angular/core';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';

@Component({
  selector: 'stx-speech-sample-video-recording-column',
  templateUrl: './speech-sample-video-recording-column.component.html',
  styleUrls: ['./speech-sample-video-recording-column.component.scss']
})
export class SpeechSampleVideoRecordingColumnComponent extends BaseFormElementComponent {
  @Input() parentOrderNameToSet: ParentOrderName;
  @Input() displayLanguageChoice: boolean = false;
  @Input() label: string;
  @Input() tooltip: string;
  @Input() set repetitionTexts(repetitionTexts: Map<string, string>) {
    this._repetitionTexts = repetitionTexts;
    this.languages = Array.from(this._repetitionTexts || [], v => v[0]);
  }
  get repetitionTexts() {
    return this._repetitionTexts;
  }

  private _repetitionTexts: Map<string, string>;
  languages: string[];
  selectedLanguage: string = 'English';

  constructor() {
    super();
  }

  get repetitionText(): string {
    return this._repetitionTexts.get(this.selectedLanguage);
  }

  selectLanguage(id: string) {
    this.selectedLanguage = id;
  }
}
