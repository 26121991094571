import { SpeechService } from '@/src/app/features/speech/speech.service';
import { BaseTreatmentFormComponent } from '@/src/app/shared/components/base-treatment-form/base-treatment-form.component';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { atLeastOneRequired, requiredIfValidator, StxValidators } from '@/src/app/shared/validation/validators';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormType } from '@shared/enums/form-type.enum';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { FormGuardService } from '@shared/services/form-guard.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { FormMediaUtils } from '@utils/form-media.utils';
import { speechAssessmentRoute, speechRoute } from '@utils/routing.utils';
import { Observable, Subject } from 'rxjs';
import { SpinnerService } from 'src/app/shared/components/spinner/service/spinner.service';
import { FormMode } from 'src/app/utils/form.utils';
import { DictionaryTranslation, PatientId } from '../../patient/models/patient.model';
import { PatientService } from '../../patient/patient.service';
import { InstrumentalAssessmentComponent } from '../instrumental-assessment/instrumental-assessment.component';
import { SpeechAssessment } from '../models/speech-assessment.model';
import { SpeechBasicInfoComponent } from '../speech-basic-info/speech-basic-info.component';
import { SpeechSampleVideoRecordingComponent } from '../speech-sample-video-recording/speech-sample-video-recording.component';
import {
  assessmentInstrumentalSectionValidator,
  assessmentStagePostVpdValue,
  assessmentStagePreVpdValue,
  emissionPhonemeFields,
  emissionRatingFields,
  speechAssessmentParentOrderNames,
  validatedRecommendationFields
} from '../utils/speech-assessment.utils';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Component({
  selector: 'stx-speech-assessment',
  templateUrl: './speech-assessment.component.html',
  styleUrls: ['./speech-assessment.component.scss']
})
export class SpeechAssessmentComponent extends BaseTreatmentFormComponent<SpeechAssessment> implements OnInit, AfterViewInit {
  @Input() speechAssessment: SpeechAssessment;

  @ViewChild(SpeechSampleVideoRecordingComponent)
  speechSampleVideoRecordingComponent: SpeechSampleVideoRecordingComponent;
  @ViewChild(InstrumentalAssessmentComponent)
  instrumentalAssessmentComponent: InstrumentalAssessmentComponent;
  @ViewChild(SpeechBasicInfoComponent)
  speechBasicInfoComponent: SpeechBasicInfoComponent;

  staticDictionaries: Map<string, DictionaryTranslation[]>;
  private formGroupDataReadySubject$ = new Subject<void>();
  formGroupDataReadyEvent$ = this.formGroupDataReadySubject$.asObservable();

  constructor(
    patientService: PatientService,
    spinnerService: SpinnerService,
    private formBuilder: UntypedFormBuilder,
    private speechService: SpeechService,
    router: Router,
    activatedRoute: ActivatedRoute,
    changeDetector: ChangeDetectorRef,
    globalErrorHandlerService: GlobalErrorHandlerService,
    private formGuardService: FormGuardService,
    snackBarService: SnackBarService,
    elementRef: ElementRef,
    zone: NgZone,
    wsHelper: WsHelperService
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      changeDetector,
      globalErrorHandlerService,
      wsHelper,
      patientService
    );
    this.formType = FormType.SPEECH_ASSESSMENT;
    this.treatmentType = TreatmentType.SPEECH_ASSESSMENT;
  }

  ngOnInit(): void {
    this.configureForm(this.initSpeechAssessmentForm(), { controlNamesForFullValidation: ['assessmentDate', 'treatmentCenterId'] });

    if (!this.isReadonlyView) {
      this.getPatientTreatmentCenters();
    }

    this.initStaticDictionaries();
  }

  ngAfterViewInit(): void {
    this.fillInEditMode();
  }

  private initSpeechAssessmentForm(): UntypedFormGroup {
    const isInstrumentalPhotoMandatory = () => assessmentInstrumentalSectionValidator(this.treatmentFormGroup) !== null;
    return this.formBuilder.group(
      {
        assessmentDate: [null, StxValidators.required],
        treatmentCenterId: [null, StxValidators.required],
        practitionerId: [null, StxValidators.required],
        countryLanguageId: [null, StxValidators.required],
        telehealth: false,
        assessmentStage: [null, StxValidators.required],
        // Perceptual Assessment
        resonance: [null, StxValidators.required],
        nasalEmissionNone: [],
        nasalEmissionAmplified: [],
        nasalEmissionPhoneme: [],
        nasalEmissionPhonemeS: [],
        nasalEmissionPhonemeZ: [],
        nasalEmissionPhonemeSh: [],
        nasalEmissionPhonemeZh: [],
        nasalEmissionPhonemeCh: [],
        nasalEmissionPhonemeJ: [],
        nasalEmissionPhonemeOther: [],
        obligatoryDistortionDentition: [],
        obligatoryDistortionVpd: [],
        compensatoryGlottalStop: [],
        compensatoryPharyngealStop: [],
        compensatoryPharyngealFricative: [],
        compensatoryNasalFricative: [],
        compensatoryMidDorsumPalatalStop: [],
        compensatoryGlottalFricative: [],
        placementErrorPb: [],
        placementErrorTd: [],
        placementErrorKg: [],
        placementErrorFv: [],
        placementErrorSz: [],
        placementErrorSh: [],
        placementErrorCh: [],
        placementErrorOther: [],
        perceptualAssessmentComment: [],
        // Intraoral Assessment
        intraoralFistula: [],
        intraoralAssessmentComment: [],
        // Instrumental Assessment
        nasoscopyDate: [null, requiredIfValidator(isInstrumentalPhotoMandatory)],
        nasoscopyFindings: [],
        videoscopyDate: [null, requiredIfValidator(isInstrumentalPhotoMandatory)],
        videoscopyFindings: [],
        nasalanceScoreNasals: [null, [Validators.min(0)]],
        nasalanceScorePb: [null, [Validators.min(0)]],
        nasalanceScoreTd: [null, [Validators.min(0)]],
        nasalanceScoreKg: [null, [Validators.min(0)]],
        nasalanceScoreSz: [null, [Validators.min(0)]],
        // Recommendations
        recommendNoSpeechServices: [],
        recommendVpdAssessment: [],
        recommendInitiateSpeechTherapy: [],
        recommendParentEducation: [],
        recommendReeval: [],
        recommendReevalMonths: [
          null,
          [
            Validators.min(1),
            StxValidators.isInShortTypeRange,
            requiredIfValidator(() => this.treatmentFormGroup.get('recommendReeval').value)
          ]
        ],
        recommendDentalConsult: [],
        recommendDentalObturator: [],
        recommendTonsillectomy: [],
        recommendFistulaRepair: [],
        recommendVpd: [],
        recommendVpdSecondaryFurlow: [],
        recommendVpdPharyngealFlap: [],
        recommendVpdIntravelarVeloplasty: [],
        recommendVpdSphincterPharyngoplasty: [],
        recommendVpdBuccalFlap: [],
        recommendOther: [],
        // Media
        [ParentOrderName.SPEECH_ASSESSMENT_SAMPLE_SYLLABLE_REPETITION.toString()]: [null, StxValidators.required],
        [ParentOrderName.SPEECH_ASSESSMENT_SAMPLE_SENTENCE_REPETITION.toString()]: [null, StxValidators.required],
        [ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_VIDEO.toString()]: [
          null,
          requiredIfValidator(isInstrumentalPhotoMandatory)
        ],
        [ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_REST.toString()]: [
          null,
          requiredIfValidator(isInstrumentalPhotoMandatory)
        ],
        [ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_PHONATION.toString()]: [
          null,
          requiredIfValidator(isInstrumentalPhotoMandatory)
        ],
        [ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_VIDEO.toString()]: [
          null,
          requiredIfValidator(isInstrumentalPhotoMandatory)
        ],
        [ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_REST.toString()]: [
          null,
          requiredIfValidator(isInstrumentalPhotoMandatory)
        ],
        [ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_PHONATION.toString()]: [
          null,
          requiredIfValidator(isInstrumentalPhotoMandatory)
        ]
      },
      {
        validators: [
          assessmentInstrumentalSectionValidator,
          atLeastOneRequired(emissionRatingFields, 'nasalRatingEmission'),
          atLeastOneRequired(emissionPhonemeFields, 'nasalEmissionPhoneme', 'nasalEmissionPhoneme', true),
          atLeastOneRequired(validatedRecommendationFields, 'recommendationFields')
        ]
      }
    );
  }

  private fillInEditMode(): void {
    if (this.formMode === FormMode.NEW) {
      return;
    }

    if (!!this.speechAssessment) {
      this.setTreatmentData(this.speechAssessment);
      return;
    }

    this.subSink.sink = this.activatedRoute.params.subscribe(params => {
      this.wsHelper
        .callWithSpinner(this.speechService.getSpeechAssessment(params.id), { redirectOn404StatusCode: true })
        .subscribe(speechAssessment => {
          this.formGuardService.redirectTreatmentFormToCorrectModeIfRequired(speechAssessment, this.formMode, this.activatedRoute.snapshot);
          this.setTreatmentData(speechAssessment);
        });
    });
  }

  getAvailableAssessmentStages(): DictionaryTranslation[] {
    if (this.print) {
      return this.staticDictionaries.get('assessmentStageWithDescriptions');
    }

    return this.allowVpd(this.treatmentFormGroup.get('treatmentCenterId').value) || this.formMode === FormMode.READONLY
      ? this.staticDictionaries.get('assessmentStageWithDescriptions')
      : this.staticDictionaries
          .get('assessmentStageWithDescriptions')
          .filter(
            dictionaryTranslation => ![assessmentStagePreVpdValue, assessmentStagePostVpdValue].includes(dictionaryTranslation.value)
          );
  }

  private allowVpd(treatmentCenterId?: number): boolean {
    if (!treatmentCenterId) {
      return false;
    }
    const selectedTc = this.tcList ? this.tcList.find(treatmentCenter => treatmentCenter.id === treatmentCenterId) : null;
    return selectedTc && selectedTc.allowVelo;
  }

  private initStaticDictionaries(): void {
    this.subSink.sink = this.wsHelper
      .callWithSpinner(this.speechService.getSpeechAssessmentStaticDictionaries())
      .subscribe(staticDictionaries => {
        this.staticDictionaries = staticDictionaries;
        this.changeDetector.markForCheck();
      });
  }

  protected override beforeValidationSync(): void {
    this.checkVpdStageValidity();
    this.speechBasicInfoComponent.checkPractitionerAndLanguageValidity();
    super.beforeValidationSync();
  }

  protected callDelete(id: number): Observable<void> {
    return this.speechService.deleteSpeechAssessment(id);
  }

  protected callSave(data: any): Observable<any> {
    return this.speechService.saveSpeechAssessment(data);
  }

  protected callUnlock(id: number): Observable<void> {
    return this.speechService.unlockSpeechAssessment(id);
  }

  protected getEditRoute(treatmentId: number): string {
    return `${speechRoute}/${speechAssessmentRoute}/edit/${treatmentId}`;
  }

  protected getPatientId(): PatientId {
    return this.patient.id;
  }

  protected getTreatmentId(): TreatmentId {
    return this.speechAssessment.id;
  }

  protected setTreatmentData(data: any): void {
    this.speechAssessment = data;
    this.treatmentFormGroup.patchValue(this.speechAssessment);
    this.treatmentFormGroup.patchValue(FormMediaUtils.getMediaForFormGroup(this.speechAssessment, speechAssessmentParentOrderNames));
    this.formGroupDataReadySubject$.next();
  }

  protected getTreatmentDataToSave(): SpeechAssessment {
    return {
      ...this.treatmentFormGroup.value,
      id: this.speechAssessment ? this.speechAssessment.id : null,
      patientId: this.patient.id,
      newFiles: FormMediaUtils.extractNewMediaFromFormGroup([this.treatmentFormGroup], speechAssessmentParentOrderNames)
    };
  }

  private checkVpdStageValidity() {
    const selectedTreatmentCenterId = this.treatmentFormGroup.get('treatmentCenterId').value;
    if (
      [assessmentStagePreVpdValue, assessmentStagePostVpdValue].includes(this.treatmentFormGroup.get('assessmentStage').value) &&
      !this.allowVpd(selectedTreatmentCenterId)
    ) {
      this.treatmentFormGroup.get('assessmentStage').reset();
    }
  }

  protected callSubmit(data: SpeechAssessment): Observable<SpeechAssessment> {
    return this.speechService.submitSpeechAssessment(data);
  }

  protected getViewRoute(treatmentId: number): string {
    return `${speechRoute}/${speechAssessmentRoute}/${treatmentId}`;
  }
}
