<stx-radio-group
  [controlName]="periodTypeControlName"
  [defaultOptionValue]="sinceInceptionValue"
  [formGroup]="formGroupReference"
  [options]="periodTypes"
  layoutDirection="column"
>
</stx-radio-group>
<hr class="form-subsection-separator visibility-hidden" />
<div *ngIf="periodPresetsVisible" @periodRanges class="form-element-group-column">
  <fieldset>
    <div class="display-flex align-left layout-align-baseline row-wrap-until-gt-xs">
      <legend class="form-element-label legend-inner">
        {{ 'report.filters.period.calendar_year.symbol' | translate }}
      </legend>
      <div class="checkbox-container flex-row-container">
        <stx-checkbox *ngFor="let year of calendarYears" [formGroup]="calendarYearsGroup" [controlName]="year.controlName" class="flex-25">
          {{ year.label }}
        </stx-checkbox>
      </div>
    </div>
    <hr class="form-spaced-subsection-separator" />
  </fieldset>
  <fieldset>
    <div class="align-left align-baseline row-wrap-until-gt-xs">
      <legend class="form-element-label legend-inner">
        {{ 'report.filters.period.fiscal_year.symbol' | translate }}
      </legend>
      <div class="legend-checkbox-container flex-row-container">
        <stx-checkbox *ngFor="let year of fiscalYears" [formGroup]="fiscalYearsGroup" [controlName]="year.controlName" class="flex-25">
          {{ year.label }}
        </stx-checkbox>
      </div>
    </div>
    <hr class="form-spaced-subsection-separator" />
  </fieldset>
  <div class="form-element-group-column">
    <fieldset *ngFor="let yearQuarters of quarters" class="force-hz-spacing">
      <div class="row-wrap-until-gt-xs align-left layout-align-baseline display-flex">
        <legend class="form-element-label legend-inner">
          {{ yearQuarters.year }}
        </legend>
        <div class="checkbox-container flex-row-container form-element-group-grid-4cols">
          <stx-checkbox
            *ngFor="let quarter of yearQuarters.periods"
            [formGroup]="quartersGroup"
            [controlName]="quarter.controlName"
            class="display-block force-hz-spacing"
          >
            {{ 'date.quarter_symbol' | translate }}{{ quarter.unitValue }}
          </stx-checkbox>
        </div>
      </div>
      <hr class="form-subsection-separator visibility-hidden" />
    </fieldset>
    <hr class="form-spaced-subsection-separator" />
  </div>

  <div class="form-element-group-column">
    <fieldset *ngFor="let yearMonths of monthsByQuarter; last as isLast" class="force-hz-spacing">
      <div class="flex-column-container">
        <legend class="form-element-label custom-flex-100" [style.margin-bottom]="'5px'">
          {{ yearMonths.year }}
        </legend>
        <ng-container *ngFor="let monthQuarter of yearMonths.quarters.slice().reverse()">
          <div
            *ngIf="monthQuarter.months?.length > 0"
            class="flex-row-container custom-flex-100 align-left layout-align-baseline"
            [style.margin-bottom]="'5px'"
          >
            <div
              class="form-element-group-grid-3cols"
              *ngFor="let month of monthQuarter.months"
              [style.flex]="monthQuarter.months.length === 2 && month.unitValue === ('5' || '8' || '11') ? '50%' : '100%'"
            >
              <stx-checkbox [formGroup]="monthsGroup" [controlName]="month.controlName" class="force-hz-spacing">
                {{ 'date.month_names.short.' + month.unitValue | translate }}
              </stx-checkbox>
            </div>
          </div>
        </ng-container>
      </div>
      <hr class="form-subsection-separator visibility-hidden" />
    </fieldset>
  </div>
</div>
