import { indiaCountryId, indiaCountryName } from '@utils/constants.utils';

export const defaultPeriodGroupValue = {
  periodType: 'SI'
};

export const orthoIndiaStatsFormDefaults = {
  groupByScope: 'partner',
  orthoCasesTypes: {
    PSIO_ASSESSMENT_PATIENT: true,
    PSIO_ASSESSMENT_SUBMITTED: true,
    PSIO_TREATMENT_PATIENT: true,
    PSIO_TREATMENT_SUBMITTED: true,
    PSIO_TREATMENT_DISCONTINUED: true,
    MD_ASSESSMENT_PATIENT: true,
    MD_ASSESSMENT_SUBMITTED: true,
    MD_MID_REIMBURSABLE: true,
    MD_TREATMENT_PATIENT: true,
    MD_TREATMENT_SUBMITTED: true,
    MD_TREATMENT_DISCONTINUED: true,
    PD_ASSESSMENT_PATIENT: true,
    PD_ASSESSMENT_SUBMITTED: true,
    PD_MID_REIMBURSABLE: true,
    PD_TREATMENT_PATIENT: true,
    PD_TREATMENT_SUBMITTED: true,
    PD_TREATMENT_DISCONTINUED: true
  },
  period: defaultPeriodGroupValue,
  orthoRecordDates: {
    rootOption: '1'
  },
  geographyTree: {
    countries: [{ id: indiaCountryId, name: indiaCountryName }]
  }
};

export const orthoStatsFormDefaults = {
  orthoCasesTypes: {
    PSIO_ASSESSMENT_PATIENT: true,
    PSIO_ASSESSMENT_SUBMITTED: true,
    PSIO_TREATMENT_PATIENT: true,
    PSIO_TREATMENT_SUBMITTED: true,
    MD_ASSESSMENT_PATIENT: true,
    MD_ASSESSMENT_SUBMITTED: true,
    MD_MID_REIMBURSABLE: true,
    MD_TREATMENT_PATIENT: true,
    MD_TREATMENT_SUBMITTED: true,
    PD_ASSESSMENT_PATIENT: true,
    PD_ASSESSMENT_SUBMITTED: true,
    PD_MID_REIMBURSABLE: true,
    PD_TREATMENT_PATIENT: true,
    PD_TREATMENT_SUBMITTED: true
  },
  period: defaultPeriodGroupValue,
  orthoRecordDates: {
    rootOption: '1'
  }
};

export const nutritionIndiaStatsFormDefaults = {
  groupByScope: 'partner',
  period: defaultPeriodGroupValue,
  recordDate: {
    recordType: '1'
  },
  ageGroupsWithTreatmentStatuses: {
    rootOptions: {
      INFANT: true,
      TODDLER: true,
      ADOLESCENT: true,
      ADULT: true
    },
    childrenOptions: {
      infantAgeGroupTreatmentType: {
        INFANT_SUBMITTED: true,
        INFANT_PATIENT: true
      },
      toddlerAgeGroupTreatmentType: {
        TODDLER_SUBMITTED: true,
        TODDLER_PATIENT: true
      },
      adolescentAgeGroupTreatmentType: {
        ADOLESCENT_SUBMITTED: true,
        ADOLESCENT_PATIENT: true
      },
      adultAgeGroupTreatmentType: {
        ADULT_SUBMITTED: true,
        ADULT_PATIENT: true
      }
    }
  },
  geographyTree: {
    countries: [{ id: indiaCountryId, name: indiaCountryName }]
  }
};

export const nutritionStatsFormDefaults = {
  period: defaultPeriodGroupValue,
  recordDate: {
    recordType: '1'
  },
  ageGroupsWithTreatmentStatuses: {
    rootOptions: {
      INFANT: true,
      TODDLER: true,
      ADOLESCENT: true,
      ADULT: true
    },
    childrenOptions: {
      infantAgeGroupTreatmentType: {
        INFANT_SUBMITTED: true,
        INFANT_PATIENT: true
      },
      toddlerAgeGroupTreatmentType: {
        TODDLER_SUBMITTED: true,
        TODDLER_PATIENT: true
      },
      adolescentAgeGroupTreatmentType: {
        ADOLESCENT_SUBMITTED: true,
        ADOLESCENT_PATIENT: true
      },
      adultAgeGroupTreatmentType: {
        ADULT_SUBMITTED: true,
        ADULT_PATIENT: true
      }
    }
  }
};

export const speechIndiaStatsFormDefaults = {
  groupByScope: 'partner',
  period: defaultPeriodGroupValue,
  recordDate: {
    recordType: '1'
  },
  speechAssessmentStatuses: {
    rootOptions: {
      0: true,
      9: false
    },
    childrenOptions: {
      speechAssessmentAggType: {
        1: false,
        2: false
      },
      speechAssessmentStage: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true
      }
    }
  },
  speechTreatmentStatuses: {
    rootOptions: {
      0: true,
      8: true,
      9: false
    },
    childrenOptions: {
      speechTreatmentAggType: {
        1: false,
        2: true,
        3: true
      },
      speechTreatmentRecomm: {
        sp: true,
        hp: true,
        re: true,
        rev: true,
        vpd: true
      }
    }
  },
  geographyTree: {
    countries: [{ id: indiaCountryId, name: indiaCountryName }]
  }
};

export const speechStatsFormDefaults = {
  period: defaultPeriodGroupValue,
  recordDate: {
    recordType: '1'
  },
  speechAssessmentStatuses: {
    rootOptions: {
      0: true,
      9: false
    },
    childrenOptions: {
      speechAssessmentAggType: {
        1: false,
        2: false
      },
      speechAssessmentStage: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true
      }
    }
  },
  speechTreatmentStatuses: {
    rootOptions: {
      0: true,
      8: true,
      9: false
    },
    childrenOptions: {
      speechTreatmentAggType: {
        1: false,
        2: true,
        3: true
      },
      speechTreatmentRecomm: {
        sp: true,
        hp: true,
        re: true,
        rev: true,
        vpd: true
      }
    }
  }
};
