export enum PartnerSurgeryCountsReportTypes {
  FOUNDATION_CONNECT = 'foundation-connect'
}

export type AvailablePartnerSurgeryCountsReportTypes =
  (typeof PartnerSurgeryCountsReportTypes)[keyof typeof PartnerSurgeryCountsReportTypes];

export interface PartnerSurgeryCountsReportParameters {
  type: AvailablePartnerSurgeryCountsReportTypes;
}
