import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatColumnDef, MatRowDef, MatTable } from '@angular/material/table';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { DataTableData, DataTableDataProvider } from '@shared/modules/general-commons/components/data-table/data-table-data-provider';
import { ReplaySubject, Subject } from 'rxjs';
import { SubSink } from 'subsink';
import { Label } from './data-table.model';

@Component({
  selector: 'stx-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent<DataType> extends BaseComponent implements AfterContentInit {
  readonly pageSizeOptions = [25, 100];

  readonly rows$: Subject<DataType[]> = new ReplaySubject();
  private readonly _dataSubscriptionSink = new SubSink();
  columnNames = [];
  isEmpty: boolean = true;
  isInitialized: boolean = false;
  genericColumns: Label<DataType>[];

  @Input() dataProvider: DataTableDataProvider<DataType>;
  @Input() config: Partial<{ topPaginator: boolean; hideUninitialized: boolean; stickyColumnClass: string; colWidthConfig: number[] }>;
  @Output() paginationEvent: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @ViewChild(MatTable, { static: true })
  table: MatTable<DataType>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('topPaginator', { static: true }) topPaginator: MatPaginator;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  @ContentChildren(MatColumnDef)
  userDefinedColumnDefs: QueryList<MatColumnDef>;
  @ContentChildren(MatRowDef)
  userDefinedRowDefs: QueryList<MatRowDef<DataType>>;

  getColWidth(colIndex: number): string {
    return this.config && this.config.colWidthConfig && this.config.colWidthConfig[colIndex]
      ? `${this.config.colWidthConfig[colIndex]}%`
      : '';
  }

  ngAfterContentInit(): void {
    this._dataSubscriptionSink.unsubscribe();
    const userDefinedColumnDefs = this.userDefinedColumnDefs?.toArray() || [];
    const userDefinedRowDefs = this.userDefinedRowDefs.toArray();
    userDefinedRowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    this._dataSubscriptionSink.sink = this.dataProvider.data$.subscribe(data => {
      this.rows$.next(data.page.content);
      this.isEmpty = data.page.content.length === 0;
      this.isInitialized = true;
      this.updatePaginator(data, this.paginator);
      this.updatePaginator(data, this.topPaginator);
      this.setColumns(userDefinedColumnDefs, data);
    });
  }

  private setColumns(userDefinedColumnDefs: MatColumnDef[], data: DataTableData<DataType>) {
    userDefinedColumnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
    this.genericColumns = (data.columnLabels || []).filter(
      label => userDefinedColumnDefs.find(columnDef => columnDef.name === label.name) === undefined
    );
    const userDefinedColumnNames = userDefinedColumnDefs.concat().map(columnDef => columnDef.name);
    const genericColumnNames = this.genericColumns.map(label => label.name);
    const columnNames = [].concat(userDefinedColumnNames, genericColumnNames);
    this.columnNames = columnNames;
    this.userDefinedRowDefs.toArray().forEach(rowDef => (rowDef.columns = columnNames));
  }

  trackByIndex = (index: number): number => index;

  onSortingChanged(): void {
    this.paginator.firstPage();
    this.topPaginator?.firstPage();
    this.reloadPage(this.paginator);
  }

  onPageChanged(fromTopPaginator: boolean = false, $event: PageEvent): void {
    this.paginationEvent.emit({
      pageSize: $event.pageSize,
      pageIndex: $event.pageIndex,
      length: $event.length
    });

    if (fromTopPaginator) {
      this.reloadPage(this.topPaginator);
    } else {
      this.reloadPage(this.paginator, this.topPaginator);
    }
  }

  private reloadPage(firstPaginator: MatPaginator, secondPaginator?: MatPaginator): void {
    const pageable = {
      pageNumber: firstPaginator.pageIndex || 0,
      pageSize: firstPaginator.pageSize || secondPaginator.pageSize || this.pageSizeOptions[0],
      sortedBy: this.sort.active,
      sortDirection: this.sort.direction
    };
    this.dataProvider.reload(pageable);
  }

  private updatePaginator(data: DataTableData<DataType>, paginator: MatPaginator) {
    if (paginator) {
      paginator.pageSize = data.page.pageable.pageSize;
      paginator.pageIndex = data.page.pageable.pageNumber;
      paginator.length = data.page.totalElements;
    }
  }

  get isTopPaginatorHidden(): boolean {
    return !this.config?.topPaginator || this.isEmpty;
  }

  get isContentHidden(): boolean {
    return this.config?.hideUninitialized && !this.isInitialized;
  }
}
