<div [ngClass]="{ 'p-t-30 page-break-inside-avoid': print }" *ngIf="nutritionFormType === NutritionType.INFANT">
  <div>
    <p class="form-subsection-heading">
      {{ 'nutrition_management.current_feeding' | translate | sectionNumber : nutritionFormType : NutritionSection.CURRENT_FEEDING }}
    </p>
  </div>
  <div class="form-row">
    <stx-radio-group
      *ngIf="newbornFeedingStatusOptions"
      [layoutDirection]="'column'"
      [controlName]="'newbornFeedingStatus'"
      [formGroup]="formGroup"
      [formMode]="formMode"
      [options]="newbornFeedingStatusOptions"
      [print]="print"
    ></stx-radio-group>
  </div>
</div>
