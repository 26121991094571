import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'stx-base64-img',
  template: '<img *ngIf="src" [src]="src" />',
  styleUrls: ['./base64-img.component.scss']
})
export class Base64ImgComponent {
  src = null;

  @Input()
  set base64(base64: string) {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(base64);
  }

  constructor(private sanitizer: DomSanitizer) {}
}
