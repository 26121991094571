export interface ActionPromptConfig {
  promptHeader: string;
  promptBody: string[];
  submitButtonText: string;
  cancelButtonText: string;
}

export enum ActionPromptResult {
  CANCEL,
  SUBMIT
}

export function isPromptConfirmed(result: ActionPromptResult | null): boolean {
  return result !== null && result === ActionPromptResult.SUBMIT;
}
