import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CommonReportDictionaryItem, ReportFilterChange } from '../../models/report-filters.model';
import { CheckboxGroupFilterComponentModule } from '../checkbox-group-filter/checkbox-group-filter.component';
import { ReportFiltersHelperService } from '../../services/report-filters-helper.service';
import { ReportDictionariesService } from '../../services/report-dictionaries.service';

@Component({
  selector: 'stx-report-exclusions-filter',
  templateUrl: './report-exclusions-filter.component.html'
})
export class ReportExclusionsFilterComponent implements OnInit {
  @Input() formGroupReference: UntypedFormGroup;
  @Output() filterChange = new EventEmitter<ReportFilterChange>();

  dictionaryOptions: CommonReportDictionaryItem[];
  constructor(
    private reportFiltersHelperService: ReportFiltersHelperService,
    private reportDictionariesService: ReportDictionariesService
  ) {}

  ngOnInit(): void {
    this.dictionaryOptions = this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(
      this.reportDictionariesService.dictionaries.exclusions
    );

    this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(this.formGroupReference, this.dictionaryOptions);
  }

  onFilterChange($event: ReportFilterChange) {
    this.filterChange.emit($event);
  }
}

@NgModule({
  declarations: [ReportExclusionsFilterComponent],
  imports: [CheckboxGroupFilterComponentModule],
  exports: [ReportExclusionsFilterComponent]
})
export class ReportExclusionsFilterComponentModule {}
