<stx-generic-page>
  <stx-page-header [name]="'tools.suspicious_records' | translate"></stx-page-header>
  <form [formGroup]="suspiciousRecordsSearchForm">
    <stx-form-panel>
      <stx-search-filter-columns [showSearchButton]="false">
        <ng-container *stxSearchFilterColumn>
          <stx-record-date-filter
            [recordGroupReference]="suspiciousRecordsSearchForm"
            [dateGroupReference]="suspiciousRecordsSearchForm"
            *ngIf="recordTypes"
            [recordTypeList]="recordTypes"
            [datePresetValue]="datePreset"
          ></stx-record-date-filter>
          <stx-action-buttons
            [asyncSecondaryAction]="review(true)"
            [asyncPrimaryAction]="review()"
            [primaryText]="'suspicious_records.review' | translate"
            [secondaryText]="'suspicious_records.review_skipped' | translate"
          ></stx-action-buttons>
        </ng-container>
        <ng-container *stxSearchFilterColumn>
          <stx-checkbox-group
            class="half-width-responsive"
            [label]="''"
            [layoutDirection]="'column'"
            [options]="repairTypes"
            [formGroup]="suspiciousRecordsSearchForm"
          ></stx-checkbox-group>
        </ng-container>
        <ng-container *stxSearchFilterColumn>
          <div *stxPermitted="PermissionEnum.GET_ORG_TREE_FILTERS">
            <stx-org-tree-filter
              [orgTreeFilters]="orgTreeFiltersData"
              [formGroupReference]="suspiciousRecordsSearchForm"
            ></stx-org-tree-filter>
            <mat-divider class="mat-divider-horizontal-margin"></mat-divider>
            <stx-geography-filter
              [orgTreeFilters]="orgTreeFiltersData"
              [formGroupReference]="suspiciousRecordsSearchForm"
            ></stx-geography-filter>
          </div>
        </ng-container>
      </stx-search-filter-columns>
    </stx-form-panel>
  </form>
</stx-generic-page>
