<stx-search-filter-container [config]="{ isCsvAvailable: true }">
  <form [formGroup]="searchFormGroup">
    <stx-search-filter-columns (searchButtonClicked)="initiateSearch()">
      <ng-container *stxSearchFilterColumn>
        <div class="form-row">
          <div class="form-element-group-column">
            <mat-checkbox data-testid="checkbox-practitioner-search-active" formControlName="active">
              {{ 'active' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-practitioner-search-inactive" formControlName="inactive">
              {{ 'inactive' | translate }}</mat-checkbox
            >
          </div>
        </div>
        <hr class="form-spaced-subsection-separator" />
        <div class="form-row">
          <div class="form-element-group-column">
            <mat-checkbox data-testid="checkbox-practitioner-surgeon" formControlName="surgeon"> {{ 'surgeon' | translate }}</mat-checkbox>
            <mat-checkbox data-testid="checkbox-practitioner-orthodontist" formControlName="orthodontist">
              {{ 'practitioner.orthodontist' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-practitioner-speech-provider" formControlName="speechProvider">
              {{ 'practitioner.speech_provider' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-practitioner-nutritionist" formControlName="nutritionist">
              {{ 'practitioner.nutritionist' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-practitioner-anesthesiologist" formControlName="anesthesiologist">
              {{ 'practitioner.anesthesiologist' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-practitioner-ent-provider" formControlName="entProvider">
              {{ 'practitioner.ent_provider' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-practitioner-dental-provider" formControlName="dentalProvider">
              {{ 'practitioner.dental_provider' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-practitioner-hearing-specialist" formControlName="hearingSpecialist">
              {{ 'practitioner.hearing_specialist' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-practitioner-psychosocial-provider" formControlName="psychosocialProvider">
              {{ 'practitioner.psychosocial_provider' | translate }}</mat-checkbox
            >
          </div>
        </div>
      </ng-container>
      <ng-container *stxSearchFilterColumn>
        <div class="form-row">
          <div>
            <stx-org-tree-filter
              [orgTreeFilters]="orgTreeFiltersData"
              [formGroupReference]="searchFormGroup"
              [showPartial]="OrgTreeEnum.PARTNERS"
            ></stx-org-tree-filter>
            <stx-geography-filter
              [orgTreeFilters]="orgTreeFiltersData"
              [formGroupReference]="searchFormGroup"
              [showPartial]="GeoEnum.ONLY_COUNTRY"
            ></stx-geography-filter>
          </div>
        </div>
      </ng-container>
      <ng-container *stxSearchFilterColumn>
        <div class="form-row">
          <stx-geography-filter
            [orgTreeFilters]="orgTreeFiltersData"
            [formGroupReference]="searchFormGroup"
            [showPartial]="GeoEnum.CRM"
          ></stx-geography-filter>
        </div>
      </ng-container>
    </stx-search-filter-columns>
  </form>
</stx-search-filter-container>
