import { ButtonDisablerService } from '@/src/app/shared/services/button-disabler/button-disabler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WsHelperService {
  constructor(
    private readonly spinnerService: SpinnerService,
    private readonly globalErrorHandlerService: GlobalErrorHandlerService,
    private readonly buttonDisablerService: ButtonDisablerService
  ) {}

  callWithSpinner<T>(
    observable: Observable<T>,
    config: { redirectOn404StatusCode: boolean } = { redirectOn404StatusCode: false }
  ): Observable<T> {
    return this.call(observable, {
      redirectOn404StatusCode: config?.redirectOn404StatusCode ?? false,
      withSpinner: true
    });
  }

  /* Only meant to be used on http calls. Observables that do not complete or emit multiple values will cause errors. */
  call<T>(
    observable: Observable<T>,
    config: {
      redirectOn404StatusCode?: boolean;
      withSpinner?: boolean;
      handleErrorCallback?: (httpsErrorResponse: HttpErrorResponse, globalErrorHandlerService: GlobalErrorHandlerService) => void;
    } = { redirectOn404StatusCode: false, withSpinner: false, handleErrorCallback: null }
  ): Observable<T> {
    return of(null).pipe(
      tap({
        next: () => {
          if (config?.withSpinner) {
            this.spinnerService.addTask();
          }
          this.buttonDisablerService.addTask();
        }
      }),
      mergeMap(() => observable),
      catchError((error: any) => {
        if (!!config?.handleErrorCallback) {
          config.handleErrorCallback(error, this.globalErrorHandlerService);
          return of(null);
        }

        this.globalErrorHandlerService.handleErrorAndInformUser(error, { redirectOn404StatusCode: config.redirectOn404StatusCode });

        return throwError(() => error);
      }),
      finalize(() => {
        if (config?.withSpinner) {
          this.spinnerService.removeTask();
        }
        this.buttonDisablerService.removeTask();
      })
    );
  }

  callToSubject<T>(subject: Subject<T>): Observable<T> {
    return of(null).pipe(
      mergeMap(() => subject),
      catchError(error => {
        this.globalErrorHandlerService.handleErrorAndInformUser(error);
        return throwError(error);
      })
    );
  }

  pipeToSubject<T>(
    observable: Observable<T>,
    subject: Subject<T>,
    errorHandlingConfig: { redirectOn404StatusCode: boolean } = {
      redirectOn404StatusCode: false
    }
  ): Subscription {
    return this.callWithSpinner(observable, { redirectOn404StatusCode: errorHandlingConfig.redirectOn404StatusCode }).subscribe({
      next: value => {
        subject.next(value);
      },
      error: error => {
        if (
          !errorHandlingConfig.redirectOn404StatusCode ||
          (errorHandlingConfig.redirectOn404StatusCode && !GlobalErrorHandlerService.isResourceNotFoundError(error))
        ) {
          subject.error(error);
        }
      }
    });
  }
}
