export const psioAssessmentApiRoute = `psio/assessment`;
export const psioAssessmentAppRoute = `${psioAssessmentApiRoute}`;
export const psioTreatmentApiRoute = `psio/treatment`;
export const psioTreatmentAppRoute = `${psioTreatmentApiRoute}`;

export const mixedDentitionAssessmentApiRoute = `mixed-dentition/assessment`;
export const mixedDentitionAssessmentAppRoute = `${mixedDentitionAssessmentApiRoute}`;
export const permanentDentitionAssessmentApiRoute = `permanent-dentition/assessment`;
export const permanentDentitionAssessmentAppRoute = `${permanentDentitionAssessmentApiRoute}`;

export const mixedDentitionTreatmentApiRoute = `mixed-dentition/treatment`;
export const mixedDentitionTreatmentAppRoute = `${mixedDentitionTreatmentApiRoute}`;
export const permanentDentitionTreatmentApiRoute = `permanent-dentition/treatment`;
export const permanentDentitionTreatmentAppRoute = `${permanentDentitionTreatmentApiRoute}`;

export const permanentDentitionMidApiRoute = `permanent-dentition/mid`;
export const permanentDentitionMidAppRoute = `${permanentDentitionMidApiRoute}`;
export const mixedDentitionMidApiRoute = `mixed-dentition/mid`;
export const mixedDentitionMidAppRoute = `${mixedDentitionMidApiRoute}`;
