<stx-resources-navigation selectedItemId="{{ NavigationSubTabEnum.RESOURCES_EDUCATION_AND_RESEARCH }}"></stx-resources-navigation>
<stx-generic-wide-page>
  <stx-page-header [name]="'menu.resources.education_research' | translate"></stx-page-header>
  <div class="flex-column-container gap-30">
    <div class="layout-columns-1-1-1">
      <div>
        <h2>
          {{ 'resources.conferences' | translate }}
        </h2>
        <stx-medical-meetings-list [items]="conferences()"></stx-medical-meetings-list>
        <stx-medical-meetings-list [items]="webinars()"></stx-medical-meetings-list>
      </div>
      <div>
        <h2>
          {{ 'cleft_publications.title_short' | translate }}
        </h2>
        <div class="data-spaced-column">
          <div *stxContentFromHtmlFile="staticFilesRegistry.cleft_publications_education_research"></div>
        </div>
      </div>
      <div>
        <h2>{{ 'resources_for_partners.education_and_training.header' | translate }}</h2>
        <div>
          <ul class="data-spaced-column">
            <li>
              <div class="resource-spaced-column">
                <a [routerLink]="['/', resourcesRoute, weeklyAbstractsRoute]">
                  {{ 'cleft_publications.title' | translate }}
                </a>
              </div>
            </li>
            <hr class="data-thin-section-separator" />
          </ul>
        </div>
        <div>
          <ul class="data-spaced-column">
            <li>
              <div class="resource-spaced-column">
                <a [routerLink]="['/', resourcesRoute, videosRoute]">
                  {{ 'surgery_videos.header' | translate }}
                </a>
              </div>
            </li>
            <hr class="data-thin-section-separator" />
          </ul>
        </div>
        <stx-generic-resource-list [sections]="source"></stx-generic-resource-list>
      </div>
    </div>
    <hr class="data-thin-section-separator" />
    <div *stxPermitted="PermissionEnum.SAVE_MEDICAL_MEETING">
      <stx-medical-meeting-form (medicalMeetingSubmitted)="fetchAndPopulateMedicalMeetings(true)"> </stx-medical-meeting-form>
    </div>
  </div>
</stx-generic-wide-page>
