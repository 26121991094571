<div mat-dialog>
  <div class="p-r-25 p-l-25 p-b-25 p-t-15" [ngClass]="{ 'rtl-direction': isRtlLanguage }">
    <img src="assets/images/SmileTrain_CMYK_Primary_logo_fullcolor.svg" class="release-form-st-logo" alt="Smile Train Logo" />
    <h1>{{ translationMap.get('release_form.title') }}</h1>
    <form [formGroup]="form.formGroup">
      <p class="form-section-heading">{{ translationMap.get('release_form.patient_section_label') }}</p>
      <stx-form-panel>
        <div class="align-justify flex-responsive-container">
          <stx-simple-readonly-field
            class="m-r-25"
            [label]="translationMap.get('release_form.patient_name_label')"
            [value]="[releaseFormData.patientName, releaseFormData.patientNameLoc]"
          ></stx-simple-readonly-field>
          <stx-readonly-field
            class="m-r-25"
            [label]="translationMap.get('release_form.patient_street_label')"
            *ngIf="releaseFormData.addressItems.length > 0"
          >
            <ng-container *ngFor="let item of releaseFormData.addressItems; last as isLast">
              <span>{{ item }}</span> <stx-value-separator *ngIf="!isLast"></stx-value-separator>
            </ng-container>
          </stx-readonly-field>
          <stx-simple-readonly-field
            class="m-r-25"
            [label]="translationMap.get('release_form.patient_phone_label')"
            [value]="[releaseFormData.patient.address.phone, releaseFormData.patient.address.mobile]"
          ></stx-simple-readonly-field>
        </div>
      </stx-form-panel>

      <stx-form-panel>
        <p class="form-section-heading">{{ translationMap.get('release_form.guardian_section_label') }}</p>
        <div class="page-section-container">
          <div class="form-row">
            <div>
              <div class="form-element-label">{{ translationMap.get('release_form.guardian_name_label') }}</div>
              {{ releaseFormData.guardianName }}
            </div>
            <stx-checkbox
              [direction]="isRtlLanguage ? 'rtl' : 'ltr'"
              [name]="translationMap.get('patient.online_release_form.same_guardian_address')"
              controlName="guardianAddressSameAsPatient"
            ></stx-checkbox>
          </div>
          <ng-container *ngIf="form.addressFormGroup.enabled" formGroupName="guardianAddress">
            <div class="form-row" [ngClass]="{ 'margin-rtl': isRtlLanguage }">
              <stx-input
                controlName="street1"
                [label]="translationMap.get('release_form.guardian_street_label')"
                [isRtlLanguage]="isRtlLanguage"
                [customErrorText]="translationMap.get('error.field_empty')"
              ></stx-input>
              <stx-input
                controlName="city"
                [label]="translationMap.get('release_form.guardian_city_label')"
                [isRtlLanguage]="isRtlLanguage"
                [customErrorText]="translationMap.get('error.field_empty')"
              ></stx-input>
              <stx-input
                controlName="zip"
                [label]="translationMap.get('release_form.guardian_zip_label')"
                [isRtlLanguage]="isRtlLanguage"
                [customErrorText]="translationMap.get('error.field_empty')"
              ></stx-input>
            </div>
            <div class="form-row" [ngClass]="{ 'margin-rtl': isRtlLanguage }">
              <stx-select
                controlName="countryId"
                [label]="translationMap.get('release_form.guardian_country_label')"
                [dictionaryList]="countryList$ | async"
                [isRtlLanguage]="isRtlLanguage"
                [customErrorText]="translationMap.get('error.select')"
              ></stx-select>
              <stx-select
                controlName="stateId"
                [label]="translationMap.get('release_form.guardian_province_label')"
                [dictionaryList]="provinceList$ | async"
                [isRtlLanguage]="isRtlLanguage"
                [customErrorText]="translationMap.get('error.select')"
              ></stx-select>
            </div>
            <div class="form-row">
              <stx-input
                controlName="phone"
                [label]="translationMap.get('release_form.guardian_phone_label')"
                [isRtlLanguage]="isRtlLanguage"
                [customErrorText]="
                  !this.form.addressFormGroup.get('phone') ? translationMap.get('error.field_empty') : translationMap.get('error.pattern')
                "
              ></stx-input>
              <div></div>
              <div></div>
            </div>
          </ng-container>
        </div>
      </stx-form-panel>

      <div class="m-b-30 signature-pad-container">
        <p class="p-10">
          {{ translationMap.get('release_form.legal_consent_text_prefix') }}
          <span class="text-decoration-underline">
            <strong>{{ releaseFormData.patientName }}</strong>
          </span>
          {{ translationMap.get('release_form.legal_consent_text') }}
        </p>
      </div>
      <div class="m-b-30">
        <stx-signature-pad
          [formControlName]="signatureControlName"
          [label]="translationMap.get('release_form.signature_label')"
        ></stx-signature-pad>
        <mat-error class="stx-mat-error-align" *ngIf="showStandardError(form.formGroup, signatureControlName, ['error.signature'])">
          {{ 'error.signature' | translate }}
        </mat-error>
      </div>

      <stx-action-buttons
        [isRtlLanguage]="isRtlLanguage"
        [primaryText]="translationMap.get('submit')"
        (secondaryAction)="back()"
        [secondaryText]="'X'"
        (primaryAction)="submit()"
      ></stx-action-buttons>
    </form>
  </div>
</div>
