import { BaseHttpService } from '@/src/app/shared/services/http/base-http.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NutritionScreening } from '../models/nutrition-screening.model';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

//This is a legacy code we should not use it in future
@Injectable({
  providedIn: 'root'
})
export class NutritionScreeningHistoricalService extends BaseHttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient, { baseUrl: 'nutrition-screening' });
  }

  getNutritionScreening(id: TreatmentId): Observable<NutritionScreening> {
    return this.httpClient.get<NutritionScreening>(`${this.baseUrl}/${id}`);
  }

  deleteNutritionScreening(id: TreatmentId): Observable<any> {
    return this.httpClient.delete<any>(`${this.baseUrl}/${id}`);
  }
}
