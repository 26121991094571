import { Injectable } from '@angular/core';
import { TableColumns } from '@shared/components/table/models/table-structure.model';
import { TranslateService } from '@ngx-translate/core';

export interface GetPageHeadersOptions {
  columns: TableColumns;
  columnsToIgnore?: Set<string>;
  mergeWith?: Record<string, string>;
}

export interface FormatRecordNumberOptions {
  record: Record<string, any>;
  columns: string[];
  precision?: number;
  postfix?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReportUtilsService {
  constructor(private translateService: TranslateService) {}

  getPageHeaders(options: GetPageHeadersOptions): Record<string, string> {
    const initialHeaders = Object.assign({}, options.mergeWith);
    const columnsToIgnore = options.columnsToIgnore || new Set<string>();

    return options.columns.reduce((headers, column) => {
      if (!columnsToIgnore.has(column.name)) {
        headers[column.name] = column.text;
      }
      return headers;
    }, initialHeaders);
  }

  getColumnsList<T = string>(columns: TableColumns, columnsToIgnore = new Set<string>()): T[] {
    return columns.filter(column => !columnsToIgnore.has(column.name)).map(column => column.name as unknown as T);
  }

  getColumnsNames<T = string>(columns: TableColumns): Record<string, T> {
    return columns.reduce((columnsNames, column) => {
      const { name } = column;

      columnsNames[name] = name;

      return columnsNames;
    }, {});
  }

  translateRecordBooleanColumns<R>(record: Record<string, any>, columns: string[]): R {
    const truthyValueWord = this.translateService.instant('yes');
    const falsyValueWord = '';

    const translatedRecord = columns.reduce((translated, columnName) => {
      translated[columnName] = record[columnName] ? truthyValueWord : falsyValueWord;

      return translated;
    }, {});

    return Object.assign({}, record, translatedRecord) as R;
  }

  formatRecordNumericColumns<R>(options: FormatRecordNumberOptions): R {
    const formatted = {};
    const precision = options.precision || 0;
    const postfix = options.postfix || '';

    options.columns.forEach(columnName => {
      const value = options.record[columnName];

      if (!isNaN(value)) {
        formatted[columnName] = '';
      }
      formatted[columnName] = `${value.toFixed(precision)}${postfix}`;
    });

    return Object.assign({}, options.record, formatted) as R;
  }
}
