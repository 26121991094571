import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { noop, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true
    });
    return next.handle(request).pipe(
      tap({
        next: noop,
        error: error => {
          if (
            error.status === 401 &&
            !window.location.pathname.startsWith('/reset-password') &&
            request.url !== '/api/reset-password' &&
            !window.location.pathname.startsWith('/token')
          ) {
            this.authService.cleanUpLogoutUser();
            this.router.navigateByUrl('/login');
          }
        }
      })
    );
  }
}
