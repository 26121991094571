import { Pipe, PipeTransform } from '@angular/core';
import { NutritionType } from '@src/app/features/nutrition-management/enums/nutrition-type.enum';
import { getSectionNumber, NutritionSection } from '@src/app/features/nutrition-management/utils/nutrition-management.utils';

@Pipe({
  name: 'sectionNumber'
})
export class SectionNumberPipe implements PipeTransform {
  transform(value: string, ageGroup: NutritionType, section: NutritionSection): unknown {
    return getSectionNumber(ageGroup, section) + ' - ' + value;
  }
}
