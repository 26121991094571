import * as moment from 'moment';

export interface MedicalMeeting {
  id: number;
  category: MedicalMeetingCategory;
  eventName: string;
  eventLink?: string;
  eventStart: moment.Moment;
  eventEnd?: moment.Moment;
  eventTime?: string;
  eventAbstractDue?: moment.Moment;
  eventLocation?: string;
  eventBlurb?: string;
}

export enum MedicalMeetingCategory {
  CONFERENCE = 'CONFERENCE',
  WEBINAR = 'WEBINAR',
  PAST_EVENT_RECORDING = 'PAST_EVENT_RECORDING'
}
