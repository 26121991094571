import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { PractitionerType } from '@shared/enums/practitioner.enum';
import { Practitioner } from '@shared/models/practitioner.model';
import { TreatmentCenterDictionary } from '@shared/models/treatment-center.model';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { FormMode } from '@utils/form.utils';
import * as moment from 'moment';

import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { NutritionType } from '@src/app/features/nutrition-management/enums/nutrition-type.enum';
import { TreatmentCenterService } from '@src/app/features/treatment-center/treatment-center.service';
import { DecimalPlaces } from '../../utils/nutrition-management.utils';

@Component({
  selector: 'stx-nutrition-general-info',
  styleUrls: ['./nutrition-general-info.component.scss'],
  templateUrl: './nutrition-general-info.component.html'
})
export class NutritionGeneralInfoComponent extends BaseFormElementComponent implements OnInit {
  readonly DecimalPlaces = DecimalPlaces;
  readonly ParentOrderName = ParentOrderName;
  readonly infantMaxAgeInMonths = 6;
  readonly toddlerMaxAgeInMonths = 60;
  readonly adolescentMaxAgeInMonths = 216;

  @Input() tcList: TreatmentCenterDictionary[];
  @Input() nutritionManagementFormGroup: UntypedFormGroup;
  @Input() dateOfBirth: moment.Moment;
  @Input() nutritionFormType: NutritionType;
  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;
  @Output() nutritionTypeChange = new EventEmitter<NutritionType>();

  practitionerList: Practitioner[] = [];
  constructor(private treatmentCenterService: TreatmentCenterService, private readonly wsHelperService: WsHelperService) {
    super();
  }

  get nutritionInfo(): UntypedFormGroup {
    return this.nutritionManagementFormGroup.get('nutritionInfo') as UntypedFormGroup;
  }
  get weightMeasureInfoLabel(): string | void {
    if (this.nutritionFormType && this.nutritionFormType !== NutritionType.ADULT) {
      return 'nutrition_management.measuring_weight.' + this.nutritionFormType.toLowerCase();
    }
  }

  get isReadonlyView(): boolean {
    return this.formMode === FormMode.READONLY;
  }

  ngOnInit(): void {
    this.onTcChangeResetPractitionerSelection();
    this.changeNutritionFormType();
  }

  private onTcChangeResetPractitionerSelection(): void {
    this.subSink.sink = this.nutritionManagementFormGroup.get('treatmentCenterId').valueChanges.subscribe(tcId => {
      if (this.formMode !== FormMode.READONLY && !!tcId) {
        this.getPractitioners(tcId);
      }
    });
  }

  private changeNutritionFormType(): void {
    this.subSink.sink = this.nutritionInfo.get('evaluationDate').valueChanges.subscribe(evalDate => {
      if (evalDate) {
        const monthDiff = moment(evalDate).diff(moment(this.dateOfBirth), 'months', true);
        if (monthDiff < this.infantMaxAgeInMonths) {
          this.nutritionTypeChange.emit(NutritionType.INFANT);
        } else if (monthDiff < this.toddlerMaxAgeInMonths) {
          this.nutritionTypeChange.emit(NutritionType.TODDLER);
        } else if (monthDiff < this.adolescentMaxAgeInMonths) {
          this.nutritionTypeChange.emit(NutritionType.ADOLESCENT);
        } else {
          this.nutritionTypeChange.emit(NutritionType.ADULT);
        }
      }
    });
  }

  private getPractitioners(tcId: number) {
    this.subSink.sink = this.wsHelperService
      .callWithSpinner(this.treatmentCenterService.getPractitionersByTreatmentCenterAndType(tcId, PractitionerType.NUTRITIONIST))
      .subscribe(practitioners => {
        this.practitionerList = practitioners;
      });
  }
}
