<div>
  <p class="form-section-heading">
    <strong>{{ gradingTitle | translate }}</strong>
  </p>
  <div>
    <form [formGroup]="gradingFormGroup">
      <div class="form-row">
        <div class="form-columns-1-1" *ngIf="!isPalateTreatment; else palateGrades">
          <stx-radio-group
            class="half-width-responsive"
            [layoutDirection]="'column'"
            [controlName]="'preGrade'"
            [formGroup]="gradingFormGroup"
            [formMode]="formMode"
            [options]="preGrades"
          ></stx-radio-group>

          <stx-radio-group
            class="half-width-responsive"
            [layoutDirection]="'column'"
            [controlName]="'postGrade'"
            [formGroup]="gradingFormGroup"
            [formMode]="formMode"
            [options]="postGrades"
          ></stx-radio-group>
        </div>

        <ng-template #palateGrades>
          <stx-radio-group
            class="half-width-responsive"
            [layoutDirection]="'column'"
            [controlName]="'postGrade'"
            [formGroup]="gradingFormGroup"
            [formMode]="formMode"
            [options]="failPassGrades"
          >
          </stx-radio-group>
        </ng-template>
      </div>

      <hr class="form-subsection-separator" />
      <div class="form-row">
        <stx-radio-group
          [label]="'surgical_qa_panel.quality_of_photos_adequate'"
          [layoutDirection]="'row'"
          [controlName]="'qaPhotoQuality'"
          [formGroup]="gradingFormGroup"
          [formMode]="formMode"
          [options]="yesNo"
          [defaultOptionValue]="1"
        >
        </stx-radio-group>
      </div>
      <div class="form-row">
        <stx-checkbox
          [name]="'surgical_qa_panel.alert_smile_train'"
          [controlName]="'alertSmileTrain'"
          [formGroup]="gradingFormGroup"
          [formMode]="formMode"
        ></stx-checkbox>
      </div>
      <div class="form-row">
        <stx-textarea
          [label]="'surgical_qa_panel.comments_to_share_with_st'"
          [isOptional]="!isAlertSmileTrainSelected"
          [formGroup]="gradingFormGroup"
          [formMode]="gradingFormGroup.value.alertSmileTrain ? formMode.EDIT : formMode.READONLY"
          [controlName]="'note'"
        ></stx-textarea>
      </div>
      <div class="form-row flex-row-container align-justify layout-align-center">
        <stx-action-buttons
          [noMargin]="true"
          [showSecondary]="false"
          [asyncPrimaryAction]="onSurgicalReviewSubmitted"
          [primaryText]="'save' | translate"
        ></stx-action-buttons>
        <div>{{ numberOfPendingCasesForReviewer }} {{ 'surgical_qa_panel.pending_cases' | translate }}</div>
      </div>
    </form>
  </div>
  <div *ngIf="displayValidationError" class="form-row grading-form-error">
    <span>
      {{ 'surgical_qa_panel.form_not_valid' | translate }}
    </span>
  </div>
</div>
