import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { BaseComponent } from '@shared/components/base-component/base.component';
import { FormModule } from '@shared/components/form/form.module';
import {
  BooleanChangePayload,
  ReportBooleanFields,
  ReportFilterChange,
  ReportFilterName
} from 'src/app/features/reports/components/report-filters/models/report-filters.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-report-boolean-filter',
  templateUrl: './report-boolean-filter.component.html'
})
export class ReportBooleanFilterComponent extends BaseComponent implements OnInit {
  @Input() filterName: ReportFilterName;
  @Input() formGroupReference: UntypedFormGroup;
  @Input() controlName: ReportBooleanFields;

  @Output() filterChange = new EventEmitter<ReportFilterChange<BooleanChangePayload>>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.watchModelChanges();
  }

  private watchModelChanges() {
    this.subSink.sink = this.formGroupReference.get(this.controlName).valueChanges.subscribe(isChecked => {
      this.filterChange.emit({
        filterName: this.filterName,
        payload: {
          [this.controlName]: [isChecked]
        }
      });
    });
  }
}

@NgModule({
  declarations: [ReportBooleanFilterComponent],
  exports: [ReportBooleanFilterComponent],
  imports: [FormModule]
})
export class ReportBooleanFilterComponentModule {}
