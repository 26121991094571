import { AuthService } from '@/src/app/features/authentication/services/auth.service';
import { mainDashboardFullRoute } from '@/src/app/utils/routing.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  private static readonly stxMaintenanceStatusCode = 503;
  private static readonly stxMaintenanceMessageKey = 'stxMaintenanceMessage';
  private readonly pollingInterval = 5000;
  private readonly defaultMessageTranslationKey = 'maintenance_message';

  private readonly messageSubject$ = new BehaviorSubject<string>(this.defaultMessageTranslationKey);

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private globalErrorHandlerService: GlobalErrorHandlerService
  ) {}

  get message() {
    return this.messageSubject$.value;
  }

  get hasTranslationKey() {
    return this.message === this.defaultMessageTranslationKey;
  }

  setMessage(errorResponse: HttpErrorResponse) {
    const message = errorResponse.error[MaintenanceService.stxMaintenanceMessageKey];
    this.messageSubject$.next(message ?? this.defaultMessageTranslationKey);
  }

  pollCurrentUserAndNavigateOnSuccess(subSink: SubSink): number {
    return window.setInterval(() => {
      subSink.sink = this.authService.getUserAndManageTempLang().subscribe({
        next: _ => this.router.navigateByUrl(mainDashboardFullRoute),
        error: error => {
          if (!MaintenanceService.isStxMaintenanceMessage(error)) {
            this.globalErrorHandlerService.handleErrorAndInformUser(error);
          }
        }
      });
    }, this.pollingInterval);
  }

  static isStxMaintenanceMessage(errorResponse: unknown): boolean {
    return (
      errorResponse instanceof HttpErrorResponse &&
      errorResponse.status === MaintenanceService.stxMaintenanceStatusCode &&
      errorResponse.error.hasOwnProperty(MaintenanceService.stxMaintenanceMessageKey)
    );
  }
}
