import { Injectable } from '@angular/core';
import { BaseHttpService } from '@shared/services/http/base-http.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { shareReplayBufferSize } from '@utils/http.utils';
import { NutritionRadioItem } from '@src/app/features/nutrition-management/models/nutrition-item.model';

@Injectable({
  providedIn: 'root'
})
export class NutritionDictionariesService extends BaseHttpService {
  private staticDictionary$: Observable<Map<string, NutritionRadioItem[]>>;

  constructor(httpClient: HttpClient) {
    super(httpClient, { baseUrl: 'nutrition' });
  }

  getStaticDictionaries(): Observable<Map<string, NutritionRadioItem[]>> {
    if (!this.staticDictionary$) {
      this.staticDictionary$ = this.httpClient
        .get<Map<string, NutritionRadioItem[]>>(`${this.baseUrl}/static-dictionaries`)
        .pipe(shareReplay(shareReplayBufferSize))
        .pipe(map(res => new Map(Object.entries(res))));
    }
    return this.staticDictionary$;
  }
}
