import { StxValidators } from '@/src/app/shared/validation/validators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Address } from '@shared/models/address.model';
import { CountryDictionary, ProvinceDictionary } from '@shared/models/geo.model';
import { PatientAddress } from '@src/app/features/patient/models/patient.model';
import { findElementFromListById } from '@utils/form.utils';
import { Observable } from 'rxjs';

interface MainControls {
  guardianAddressSameAsPatient: UntypedFormControl;
  base64EncodedSignatureImage: UntypedFormControl;
}

type AddressControls = { [P in keyof PatientAddress]: UntypedFormControl };

export class PatientReleaseFormOnlineForm {
  readonly mainControls: Readonly<MainControls>;
  readonly addressControls: Readonly<AddressControls>;
  readonly country$: Observable<CountryDictionary>;
  readonly province$: Observable<ProvinceDictionary>;
  readonly formGroup: UntypedFormGroup;
  readonly addressFormGroup: UntypedFormGroup;

  constructor(countries: Observable<CountryDictionary[]>, provinces: Observable<ProvinceDictionary[]>) {
    this.mainControls = {
      guardianAddressSameAsPatient: new UntypedFormControl(),
      base64EncodedSignatureImage: new UntypedFormControl(null, StxValidators.required)
    };

    this.addressControls = {
      street1: new UntypedFormControl(null, StxValidators.required),
      zip: new UntypedFormControl(null, StxValidators.required),
      city: new UntypedFormControl(null, StxValidators.required),
      countryId: new UntypedFormControl(null, StxValidators.required),
      stateId: new UntypedFormControl(null, StxValidators.required),
      phone: new UntypedFormControl(null, Validators.compose([StxValidators.required, Validators.maxLength(30)]))
    };

    this.country$ = findElementFromListById(this.addressControls.countryId, countries);
    this.province$ = findElementFromListById(this.addressControls.stateId, provinces);
    this.mainControls.guardianAddressSameAsPatient.valueChanges.subscribe(guardianAddressSameAsPatient => {
      if (guardianAddressSameAsPatient) {
        this.addressFormGroup.disable();
      } else {
        this.addressFormGroup.enable();
      }
    });
    this.addressFormGroup = new UntypedFormGroup({
      ...this.addressControls
    });
    this.formGroup = new UntypedFormGroup(
      {
        ...this.mainControls,
        guardianAddress: this.addressFormGroup
      },
      {
        validators: []
      }
    );
    this.mainControls.guardianAddressSameAsPatient.setValue(true);
  }

  public get data(): Record<keyof MainControls, any> & { guardianAddress: Address } {
    return {
      ...this.formGroup.value
    };
  }
}
