<form [formGroup]="formGroup" class="stx-form-field flex-column-container" [ngClass]="{ 'full-width': fullWidth }">
  <label *ngIf="label" [for]="controlName" class="form-element-label">
    {{ label | translate }} {{ 'optional' | translate | if : isOptional }} {{ suffix | translate }}</label
  >
  <label *ngIf="subLabel" [for]="controlName" class="form-element-secondary-label"> {{ subLabel | translate }}</label>
  <mat-form-field [ngClass]="{ 'text-rtl': isRtlLanguage }" [appearance]="'outline'" class="full-width">
    <ng-container [ngSwitch]="type">
      <div *ngSwitchCase="'number'" class="flex-row-container">
        <input
          data-testid="input-number"
          class="input-size"
          [ngClass]="{ 'hide-on-print': print }"
          matInput
          [type]="type"
          [formControlName]="controlName"
          [id]="controlName"
          [min]="!!minNumberValue ? minNumberValue : 0"
          [max]="!!maxNumberValue ? maxNumberValue : null"
          (keydown)="pastePreventNonNumeric($event)"
          (paste)="keyDownPreventNonNumericChar($event)"
          (input)="inputChanged()"
        />
      </div>
      <div *ngSwitchCase="'time'" class="flex-row-container">
        <input
          data-testid="input-time"
          [readonly]="false"
          matInput
          [formControlName]="controlName"
          mask="Hh:m0"
          [leadZeroDateTime]="true"
          [dropSpecialCharacters]="false"
          [id]="controlName"
          (input)="inputChanged()"
        />
      </div>
      <div *ngSwitchDefault class="flex-row-container">
        <input
          data-testid="input-component"
          matInput
          [formControlName]="controlName"
          [id]="controlName"
          [type]="type"
          (input)="inputChanged()"
        />
      </div>
    </ng-container>
    <span *ngIf="unit" matTextSuffix>{{ unit | translate }}</span>
    <mat-error
      class="stx-mat-error-align"
      [ngClass]="{ 'hide-on-print': print }"
      *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)"
    >
      <stx-error-message-wrapper>
        <ng-container *ngIf="!!customErrorText; else defaultErrorText">
          {{ customErrorText }}
        </ng-container>
        <ng-template #defaultErrorText>
          <stx-validation-message [errors]="formControl.errors"></stx-validation-message>
        </ng-template>
      </stx-error-message-wrapper>
    </mat-error>
  </mat-form-field>
</form>
