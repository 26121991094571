import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../modules/material/material.module';
import { ActionPromptComponent } from './action-prompt.component';
import { FormModule } from '../form/form.module';

@NgModule({
  declarations: [ActionPromptComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, TranslateModule, FormModule],
  exports: [ActionPromptComponent]
})
export class ActionPromptModule {}
