import { Component, Input, NgModule } from '@angular/core';
import { ReportDownloaderComponentModule } from '../report-downloader/report-downloader.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReportService } from '../report-filters/services/report.service';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'stx-report-header',
  templateUrl: './report-header.component.html'
})
export class ReportHeaderComponent {
  @Input()
  reportDownloaderEnabled = true;

  constructor(public reportService: ReportService) {}

  toggleFiltersVisibility() {
    this.reportService.toggleFiltersVisibility();
  }
}

@NgModule({
  declarations: [ReportHeaderComponent],
  imports: [TranslateModule, ReportDownloaderComponentModule, MatIconModule, CommonModule],
  exports: [ReportHeaderComponent]
})
export class ReportHeaderComponentModule {}
