<ng-container *ngIf="orthoTreatment">
  <stx-treatment-status-bar [treatment]="orthoTreatment" (unlockClicked)="unlockClicked.emit()" (deleteClicked)="deleteClicked.emit()">
    <ng-container slot="row1" *ngIf="shouldDisplayQaData">
      <div class="status-bar-data-block" *ngIf="qaReviewerName || orthoTreatment.qaPreRank || orthoTreatment.qaPostRank">
        <ng-container *ngIf="qaReviewerName">
          <div class="flex-row-container">
            <mat-icon svgIcon="pin" class="m-r-5"></mat-icon>
            <span>{{ 'treatment.status_bar.reviewed_by' | translate }}</span>
          </div>
          <span>{{ qaReviewerName }}</span>
        </ng-container>
        <ng-container *ngIf="orthoTreatment.qaReviewSkip">
          <span>-</span>
          <span>{{ 'reports.qa_reviewers.skipped' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="!orthoTreatment.qaReviewSkip">
          <span *ngIf="orthoTreatment.qaPreRank">{{ getRankText(orthoTreatment.qaPreRank) | translate }}</span>
          <span *ngIf="orthoTreatment.qaPreRank && orthoTreatment.qaPostRank">-</span>
          <span *ngIf="orthoTreatment.qaPostRank">{{ getRankText(orthoTreatment.qaPostRank) | translate }}</span>
        </ng-container>
        <stx-value-separator></stx-value-separator>
      </div>
      <div class="status-bar-data-block">
        <ng-container *ngFor="let clinicalNote of orthoTreatment.clinicalNotes">
          <span>{{ clinicalNote | translate }}</span>
          <stx-value-separator></stx-value-separator>
        </ng-container>
        <ng-container *ngIf="orthoTreatment.qaReviewerNotes">
          <span>{{ orthoTreatment.qaReviewerNotes }}</span>
          <stx-value-separator></stx-value-separator>
        </ng-container>
      </div>
      <div class="status-bar-data-block">
        <ng-container *ngFor="let technicalNote of orthoTreatment.technicalNotes">
          <span>{{ technicalNote | translate }}</span>
          <stx-value-separator></stx-value-separator>
        </ng-container>
        <ng-container *ngIf="orthoTreatment.qaReviewerTechNotes">
          <span>{{ orthoTreatment.qaReviewerTechNotes }}</span>
          <stx-value-separator></stx-value-separator>
        </ng-container>
      </div>
      <div class="status-bar-data-block">
        <ng-container *ngFor="let internalNote of orthoTreatment.internalNotes">
          <span>{{ internalNote | translate }}</span>
          <stx-value-separator></stx-value-separator>
        </ng-container>
        <ng-container *ngIf="orthoTreatment.qaReviewerStNotes">
          <span>{{ orthoTreatment.qaReviewerStNotes }}</span>
          <stx-value-separator></stx-value-separator>
        </ng-container>
      </div>
    </ng-container>
  </stx-treatment-status-bar>
</ng-container>
