import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { patientDetailsPath, patientHistoryPath } from 'src/app/utils/routing.utils';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'stx-patient-link',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './patient-link.component.html'
})
export class PatientLinkComponent {
  @Input() patientId: string;
  @Input() patientHistoryRoute: boolean;
  @Input() showIcon = true;

  get patientDetailsRoutePath(): string {
    return patientDetailsPath(this.patientId);
  }

  get patientHistoryRoutePath() {
    return patientHistoryPath(this.patientId);
  }
}

@NgModule({
  declarations: [PatientLinkComponent],
  imports: [RouterModule, MatIconModule, GeneralCommonsModule, CommonModule],
  exports: [PatientLinkComponent]
})
export class PatientLinkComponentModule {}
