import { Component, Input, NgModule } from '@angular/core';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  SurgicalTreatmentReportRecord,
  TreatmentViolation
} from '@src/app/features/reports/features/surgical-treatments-report/surgical-treatments-report.model';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'stx-treatment-violations',
  templateUrl: './treatment-violations.component.html'
})
export class TreatmentViolationsComponent {
  readonly bellIcon = faBell;
  @Input() recordData: SurgicalTreatmentReportRecord;

  get violations(): TreatmentViolation[] {
    let violations: TreatmentViolation[] = [];
    if (this.recordData.ageViolation) {
      violations.push({ name: 'age', value: this.recordData.ageMonth, unit: 'm' });
    }
    if (this.recordData.weightViolation) {
      violations.push({ name: 'patient.weight', value: this.recordData.weight, unit: 'kg' });
    }
    if (this.recordData.inductionTimeViolation) {
      violations.push({ name: 'reports.time_of_induction', value: this.recordData.timeOfInduction });
    }
    return violations;
  }
}

@NgModule({
  declarations: [TreatmentViolationsComponent],
  imports: [RouterModule, MatIconModule, GeneralCommonsModule, FontAwesomeModule, CommonModule, FlexModule, TranslateModule],
  exports: [TreatmentViolationsComponent]
})
export class TreatmentViolationComponentModule {}
