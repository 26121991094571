import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { Item } from '@/src/app/shared/models/item.model';
import { defaultDebounceTime } from '@/src/app/utils/constants.utils';
import { CdkFixedSizeVirtualScroll } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({ template: '', changeDetection: ChangeDetectionStrategy.OnPush })
export abstract class AutocompleteSelectBaseComponent<ITEM_TYPE extends { name: string }> extends BaseComponent implements OnInit {
  readonly matOptionHeight = 30;

  @ViewChild(CdkFixedSizeVirtualScroll)
  cdkFixedSizeVirtualScroll: CdkFixedSizeVirtualScroll;

  /**  When true, the first and only item will be selected and the select control will be hidden and uneditable */
  @Input() autoSelectSingleItem = true;

  @Input() set itemList(itemList: ITEM_TYPE[]) {
    this._itemList = itemList;
    this.filter(this.itemForm?.controls?.autocompleteInput?.value);
    this.adjustSelectionsToNewItems();
  }
  get itemList() {
    return this._itemList;
  }
  _itemList: ITEM_TYPE[];
  filteredItems: ITEM_TYPE[];
  itemForm: UntypedFormGroup;
  get isShown(): boolean {
    return this.autoSelectSingleItem ? this._itemList?.length > 1 : this._itemList?.length > 0;
  }

  constructor(protected readonly formBuilder: UntypedFormBuilder, protected readonly changeDetectorRef: ChangeDetectorRef) {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.subSink.sink = this.itemForm.controls.autocompleteInput.valueChanges
      .pipe(debounceTime(defaultDebounceTime))
      .subscribe(text => this.filter(text));
  }
  trackById(index: number, item: Item): number {
    return item.id;
  }

  autocompleteOpened() {
    this.cdkFixedSizeVirtualScroll.ngOnChanges();
  }

  abstract selectOption(item: ITEM_TYPE): void;
  protected selectOneAndOnlyOptionIfNeeded() {
    if (this._itemList.length === 1) {
      this.selectOption(this._itemList[0]);
    }
  }
  protected filter(text: string): void {
    if (typeof text === 'string' && text.length) {
      this.filteredItems = this._itemList.filter(option => option.name.toLowerCase().indexOf(text.toLowerCase()) >= 0);
    } else {
      this.filteredItems = this._itemList;
    }
    this.changeDetectorRef.markForCheck();
  }
  protected adjustSelectionsToNewItems(): void {
    // method does not have base implementation - needs to be overridden in inheritors
  }
  protected initForm() {
    this.itemForm = this.formBuilder.group({
      autocompleteInput: [null]
    });
  }
  protected resetSearchInput() {
    this.autocompleteInputControl.setValue([]);
  }

  get autocompleteInputControl() {
    return this.itemForm.controls.autocompleteInput;
  }
}
