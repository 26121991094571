<div [formGroup]="formGroup">
  <ng-container [ngSwitch]="formElementSetup.type">
    <ng-template [ngSwitchCase]="FormDataTypes.DATE">
      <stx-date
        [label]="formElementSetup.name"
        [controlName]="key"
        [formGroup]="formGroup"
        [minDate]="minDate"
        [maxDateToday]="true"
        [formMode]="formMode"
      ></stx-date>
    </ng-template>

    <ng-template [ngSwitchCase]="FormDataTypes.TEXTAREA">
      <stx-textarea [label]="formElementSetup.name" [controlName]="key" [formGroup]="formGroup" [formMode]="formMode"></stx-textarea>
    </ng-template>

    <ng-template [ngSwitchCase]="FormDataTypes.SELECT">
      <stx-select
        [label]="formElementSetup.name"
        [controlName]="key"
        [dictionaryList]="formElementSetup.dataSrc()"
        [formGroup]="formGroup"
        [formMode]="formMode"
      ></stx-select>
    </ng-template>

    <ng-template [ngSwitchCase]="FormDataTypes.RADIO">
      <stx-radio-group
        [label]="formElementSetup.name"
        layoutDirection="row"
        [controlName]="key"
        [formGroup]="formGroup"
        [formMode]="formMode"
        [options]="formElementSetup.dataSrc()"
      ></stx-radio-group>
    </ng-template>

    <ng-template [ngSwitchCase]="FormDataTypes.BOOLEAN">
      <stx-radio-group
        [label]="formElementSetup.name"
        layoutDirection="row"
        [controlName]="key"
        [formGroup]="formGroup"
        [formMode]="formMode"
        [options]="yesNo"
      ></stx-radio-group>
    </ng-template>

    <ng-template [ngSwitchCase]="FormDataTypes.CHECKBOX">
      <stx-checkbox [name]="formElementSetup.name" [controlName]="key" [formGroup]="formGroup" [formMode]="formMode"></stx-checkbox>
    </ng-template>
  </ng-container>
</div>
