import { MixedDentitionAssessment } from '@/src/app/features/ortho/models/mixed-dentition-assessment.model';
import { PermanentDentitionAssessment } from '@/src/app/features/ortho/models/permanent-dentition-assessment.model';
import { PsioAssessment } from '@/src/app/features/ortho/models/psio-assessment.model';
import { OrthoFormType } from '@/src/app/features/ortho/utils/ortho-form-type';
import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { MediaSet } from '@/src/app/shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';
import { BaseReadonlyViewComponent } from '@/src/app/shared/modules/readonly-commons/utils/base-readonly-view.component';
import { dictionaryFromMapProvider } from '@/src/app/shared/services/dictionary-provider';
import { Component, Input } from '@angular/core';
import { OrthoService } from '@src/app/features/ortho/services/ortho.service';
import { DictionaryTranslation } from '@src/app/features/patient/models/patient.model';
import { Observable } from 'rxjs';

const facialPhotoLabelsInOrder = [
  { labels: ['ortho.photo.facial', 'order_name.frontal'], parentOrderName: ParentOrderName.ORTHO_FRONTAL_PRE },
  { labels: ['ortho.photo.facial', 'order_name.left'], parentOrderName: ParentOrderName.ORTHO_LEFT_PRE },
  { labels: ['ortho.photo.facial', 'order_name.right'], parentOrderName: ParentOrderName.ORTHO_RIGHT_PRE },
  { labels: ['ortho.photo.facial', 'order_name.basal'], parentOrderName: ParentOrderName.ORTHO_BASAL_PRE },
  { labels: ['order_name.frontal_smile'], parentOrderName: ParentOrderName.ORTHO_FRONTAL_SMILE_PRE },
  { labels: ['order_name.maxillary'], parentOrderName: ParentOrderName.ORTHO_MAXILLARY_PRE }
];

const intraoralPhotoLabelsInOrder = [
  { labels: ['ortho.photo.intraoral', 'order_name.appliance'], parentOrderName: ParentOrderName.ORTHO_APPLIANCE_PRE },
  {
    labels: ['ortho.photo.intraoral', 'order_name.frontal'],
    parentOrderName: ParentOrderName.ORTHO_INTRA_ORAL_FRONTAL_PRE
  },
  {
    labels: ['ortho.photo.intraoral', 'ortho.photo.left_occlusion'],
    parentOrderName: ParentOrderName.ORTHO_LEFT_OCCLUSION_PRE
  },
  {
    labels: ['ortho.photo.intraoral', 'ortho.photo.right_occlusion'],
    parentOrderName: ParentOrderName.ORTHO_RIGHT_OCCLUSION_PRE
  },
  {
    labels: ['ortho.photo.intraoral', 'order_name.appliance_baby'],
    parentOrderName: ParentOrderName.ORTHO_APPLIANCE_BABY_PRE
  },
  {
    labels: ['ortho.photo.intraoral', 'order_name.maxillary'],
    parentOrderName: ParentOrderName.ORTHO_MAXILLARY_ARCH_PRE
  },
  {
    labels: ['ortho.photo.intraoral', 'ortho.photo.mandibular_arch'],
    parentOrderName: ParentOrderName.ORTHO_MANDIBULAR_ARCH_PRE
  }
];

const xrayPhotoLabelsInOrder = [
  {
    labels: ['order_name.cephalometric_radiograph'],
    parentOrderName: ParentOrderName.ORTHO_CEPHALOMETRIC_RADIOGRAPH_PRE
  },
  { labels: ['order_name.panoramic_radiograph'], parentOrderName: ParentOrderName.ORTHO_PANORAMIC_RADIOGRAPH_PRE },
  {
    labels: ['order_name.cleft_site_occlusal_or_periapical_radiograph'],
    parentOrderName: ParentOrderName.ORTHO_CLEFT_SITE_OCCLUSAL_OR_PERIAPICAL_RADIOGRAPH_PRE
  }
];

export function orthoStaticDictionariesProvider(s: OrthoService): Observable<Map<string, DictionaryTranslation[]>> {
  return s.getStaticDictionaries();
}

export type OrthoAssessment = PsioAssessment & MixedDentitionAssessment & PermanentDentitionAssessment;

@Component({
  selector: 'stx-ortho-readonly-assessment',
  templateUrl: './ortho-readonly-assessment.component.html',
  providers: [dictionaryFromMapProvider(OrthoService, orthoStaticDictionariesProvider)]
})
export class OrthoReadonlyAssessmentComponent extends BaseReadonlyViewComponent<OrthoAssessment> {
  readonly OrthoFormType = OrthoFormType;
  @Input() orthoFormType: OrthoFormType;
  photoSets: MediaSet[] = [];

  protected afterTreatmentSet(): void {
    this.photoSets = [
      {
        sectionLabelKey: 'ortho.facial_photos',
        mediaWithLabels: this.getPhotosWithLabels(facialPhotoLabelsInOrder)
      },
      {
        sectionLabelKey: 'ortho.intraoral_photos',
        mediaWithLabels: this.getPhotosWithLabels(intraoralPhotoLabelsInOrder)
      },
      { sectionLabelKey: 'ortho.xrays_photos', mediaWithLabels: this.getPhotosWithLabels(xrayPhotoLabelsInOrder) }
    ];
  }
}
