import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

const doNotKeepOffset = false;

@Pipe({
  name: 'date'
})
export class DateCustomPipe implements PipeTransform {
  transform(value: moment.Moment | Date | string | number, format?: string, timezone?: string, locale?: string): string | null {
    const datePipe = new DatePipe('en-US');
    if (moment.isMoment(value)) {
      return datePipe.transform(value.toISOString(doNotKeepOffset), format, timezone, locale);
    } else {
      return datePipe.transform(value as Date | string | number, format, timezone, locale);
    }
  }
}
