import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FormModule } from '@shared/components/form/form.module';
import { UntypedFormGroup } from '@angular/forms';
import { CheckboxOption, ReportFilterChange, ReportFiltersParams } from '../../models/report-filters.model';
import { ReportFiltersHelperService } from '../../services/report-filters-helper.service';
import { ReportDictionariesService } from '../../services/report-dictionaries.service';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'stx-surgical-intervention-filter',
  templateUrl: './surgical-intervention-filter.component.html'
})
export class SurgicalInterventionFilterComponent extends BaseComponent implements OnInit {
  @Input() formGroupReference: UntypedFormGroup;
  @Output() filterChange = new EventEmitter<ReportFilterChange>();
  surgicalInterventionOptions: CheckboxOption[];
  surgicalInterventionInitialParams: string[] = [];

  constructor(
    private reportDictionariesService: ReportDictionariesService,
    private reportFiltersHelperService: ReportFiltersHelperService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.surgicalInterventionOptions = this.getCheckboxGroupOptions();
    this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(this.formGroupReference, this.surgicalInterventionOptions);
    this.subSink.sink = this.formGroupReference.valueChanges.subscribe(this.handleValueChanges.bind(this));
    this.watchQueryParamsChange();
  }

  private handleValueChanges(change: Record<string, boolean>): void {
    const selectedSurgicalInterventionOptions = this.reportFiltersHelperService.getSelectedCheckboxOptions(change);

    this.filterChange.emit({
      filterName: 'surgicalInterventions',
      payload: {
        surgicalInterventions: selectedSurgicalInterventionOptions
      }
    });
  }

  private getCheckboxGroupOptions(): CheckboxOption[] {
    return this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(
      this.reportDictionariesService.dictionaries.surgicalInterventions
    );
  }

  private watchQueryParamsChange() {
    this.subSink.sink = this.route.queryParams
      .pipe(filter((params: ReportFiltersParams) => !!params.surgicalInterventions))
      .subscribe((params: ReportFiltersParams) => {
        this.reportFiltersHelperService.setCheckboxFormGroupValues(this.formGroupReference, params.surgicalInterventions);
      });
  }
}

@NgModule({
  declarations: [SurgicalInterventionFilterComponent],
  imports: [FormModule],
  exports: [SurgicalInterventionFilterComponent]
})
export class SurgeryTypeModule {}
