import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonReportDictionaryItem, ReportFilterChange, ReportMultiSelectionFilters } from '../../models/report-filters.model';

import { AutocompleteSingleSelectComponentModule } from '@/src/app/shared/components/autocomplete-single-select/autocomplete-single-select.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ChipsComponentModule } from '@shared/components/chips/chips.component';

import { FormModule } from 'src/app/shared/components/form/form.module';
import { ReportFiltersHelperService } from '../../services/report-filters-helper.service';
import { AutocompleteMultiSelectComponentModule } from '@shared/components/autocomplete-multi-select/autocomplete.module';
import { TranslateModule } from '@ngx-translate/core';
import { SelectOption } from '@shared/components/form/components/select/select.model';
import { Item } from '@shared/models/item.model';

@Component({
  selector: 'stx-report-multi-selection-filter',
  templateUrl: './report-multi-selection-filter.component.html'
})
export class ReportMultiSelectionFilterComponent extends BaseComponent implements OnInit {
  @Input() useLegacySelect = false;
  @Input() controlName: ReportMultiSelectionFilters;
  @Input() dictionaryList: CommonReportDictionaryItem[];
  @Input() formGroupReference: UntypedFormGroup;
  @Input() labelTranslationNode: string;
  @Input() autoTranslateName = false;
  @Input() showChips = false;
  @Output() filterChange = new EventEmitter<ReportFilterChange>();

  options: Item[] = [];

  constructor(private reportFiltersHelperService: ReportFiltersHelperService, private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.options = this.reportFiltersHelperService.mapDictionaryToItems(this.dictionaryList, this.autoTranslateName);
    this.subSink.sink = this.formGroupReference.get(this.controlName).valueChanges.subscribe(this.handleValueChanges.bind(this));
  }

  private handleValueChanges(change: Item[]): void {
    this.reportFiltersHelperService.mapAndEmitMultiselectChanges({ [this.controlName]: change }, this.filterChange);
  }

  onRemoveChip($event: SelectOption, control: AbstractControl): void {
    const newValues = (control.value as Array<SelectOption>).filter((value: SelectOption) => value.id !== $event.id);

    control.setValue(newValues);
  }
}

@NgModule({
  declarations: [ReportMultiSelectionFilterComponent],
  exports: [ReportMultiSelectionFilterComponent],
  imports: [
    CommonModule,
    FormModule,
    ChipsComponentModule,
    AutocompleteSingleSelectComponentModule,
    ReactiveFormsModule,
    AutocompleteMultiSelectComponentModule,
    TranslateModule
  ]
})
export class ReportSingleSelectionFilterComponentModule {}
