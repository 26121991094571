<div class="flex-center icon-cursor" (click)="toggleSection()">
  {{ 'report.filters.repair_types.header' | translate }}
  <mat-icon inline="true" class="chevron m-l-15">{{ !typesVisible ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
</div>
<section *ngIf="typesVisible" @typesSection class="m-l-10">
  <dl *ngFor="let repairType of repairTypesList">
    <dt class="form-element-label">
      {{ repairType.label | translate }}
    </dt>
    <dd>
      <p class="font-italic" *ngIf="repairType.description">
        {{ repairType.description | translate }}
      </p>
      <stx-checkbox-group layoutDirection="column" [formGroup]="repairType.formGroup" [options]="repairType.options"></stx-checkbox-group>
    </dd>
  </dl>
</section>
