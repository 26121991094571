import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WsHelperService } from '../../services/ws-helper.service';
import { staticFilesPath } from './static-files.utils';

@Injectable({
  providedIn: 'root'
})
export class StaticFilesService {
  constructor(private httpClient: HttpClient, private wsHelper: WsHelperService) {}

  private static buildHtmlUrl(fileDir: string, lang: string): string {
    return `${staticFilesPath}/${fileDir}/${lang}.html`;
  }

  getStaticHTMLFromAssets(fileDir: string, lang: string | undefined): Observable<string> {
    return this.wsHelper.callWithSpinner(
      this.httpClient.get(StaticFilesService.buildHtmlUrl(fileDir, !!lang ? lang : 'en'), { responseType: 'text' })
    );
  }
}
