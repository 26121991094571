import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component, Input } from '@angular/core';
import { getNextDay } from '@utils/date.utils';
import moment from 'moment';
import { InstrumentalAssessmentColumnConfig } from '../instrumental-assessment.util';

@Component({
  selector: 'stx-instrumental-assessment-column',
  templateUrl: './instrumental-assessment-column.component.html',
  styleUrls: ['./instrumental-assessment-column.component.scss']
})
export class InstrumentalAssessmentColumnComponent extends BaseFormElementComponent {
  @Input() instrumentalAssessmentColumnConfig: InstrumentalAssessmentColumnConfig;
  @Input() birthDate: moment.Moment;

  get minDate(): moment.Moment {
    return getNextDay(this.birthDate);
  }
  constructor() {
    super();
  }
}
