import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormGroup } from '@angular/forms';
import { BaseFormElementComponent } from '../../../base-treatment-form/base-form-element.component';
import { UiConfig } from '../form-components.utils';

export type LayoutDirection = 'row' | 'column' | '2cols' | '3cols' | '4cols';

@Component({
  selector: 'stx-checkbox-group',
  templateUrl: './checkbox-group.component.html'
})
export class CheckboxGroupComponent extends BaseFormElementComponent implements OnInit {
  @Input() label: string;
  @Input() name: string;
  @Input() controlName: string;
  @Input() options: any[];
  @Input() layoutDirection: LayoutDirection;
  @Input() addStyling: UiConfig;
  @Input() formGroupErrorNames?: Array<string>;
  @Output() anyOptionSelected = new EventEmitter<any>();

  @Input() deselectTriggerControlName: string;
  @Input() nestedOptions: any[] = [];
  @Input() parentControlName: string;
  @Input() templateRef: TemplateRef<any>;

  private parentControl: AbstractControl;
  private deselectTriggerControl: AbstractControl;

  get optionsWithoutDeselectTrigger() {
    return this.options.filter(option => option.name !== this.deselectTriggerControlName);
  }
  get isAnyDependentOptionSelected() {
    // Assuming all nestedOptions have parentControl that is also selected,
    // so we can skip them
    return this.optionsWithoutDeselectTrigger.map(o => o.name).some(n => !!this.formGroup.get(n).value);
  }

  get deselectTriggerDependentControls() {
    return [...this.optionsWithoutDeselectTrigger, ...this.nestedOptions];
  }

  get showError(): boolean {
    return (
      this.formMode !== this.FormMode.READONLY &&
      this.formGroupErrorNames &&
      this.showStandardError(
        this.formGroup,
        null,
        this.formGroupErrorNames,
        this.options.map(option => option.name)
      )
    );
  }

  constructor(private readonly controlContainer: ControlContainer) {
    super();
  }

  ngOnInit() {
    if (!this.formGroup) {
      this.formGroup = this.controlContainer.control as UntypedFormGroup;
    }
    this.parentControl = this.parentControlName ? this.formGroup.get(this.parentControlName) : null;
    this.deselectTriggerControl = this.deselectTriggerControlName ? this.formGroup.get(this.deselectTriggerControlName) : null;
    this.subscribeOnParentChanges();
    this.subscribeOnDeselectTriggerChanges();
    this.subscribeOnAnyOptionSelectedWithDeselectTrigger();
  }
  emitCheckboxChanges(value: any) {
    this.anyOptionSelected.emit(value);

    if (this.parentControl && value) {
      this.parentControl.setValue(true, { emitEvent: true });
    }
  }
  private subscribeOnParentChanges() {
    if (this.parentControl) {
      this.subSink.sink = this.parentControl.valueChanges.subscribe(value => {
        if (!value) {
          this.deselectAll();
        }
      });
    }
  }

  private subscribeOnDeselectTriggerChanges() {
    if (this.deselectTriggerControl) {
      this.subSink.sink = this.deselectTriggerControl.valueChanges.subscribe(value => {
        if (value) {
          this.deselectAndDisableAllDependentControls();
        } else {
          this.enableAllDependentControls();
        }
      });
    }
  }

  private subscribeOnAnyOptionSelectedWithDeselectTrigger() {
    if (this.deselectTriggerControl) {
      this.optionsWithoutDeselectTrigger.forEach(option => {
        this.subSink.sink = this.formGroup.get(option.name).valueChanges.subscribe(value => {
          if (value && this.deselectTriggerControl.enabled) {
            this.deselectOption(this.deselectTriggerControlName, true);
          } else if (!value) {
            if (!this.isAnyDependentOptionSelected) {
              this.enableOption(this.deselectTriggerControlName);
            }
          }
        });
      });
    }
  }

  private deselectAll(disable = false) {
    this.optionsWithoutDeselectTrigger.forEach(option => {
      this.deselectOption(option.name, disable);
    });
  }

  private deselectAndDisableAllDependentControls(disable = true) {
    this.deselectTriggerDependentControls.forEach(option => {
      this.deselectOption(option.name, true);
    });
  }

  private deselectOption(optionName: string, disable = false) {
    const optionControl = this.formGroup.get(optionName);
    if (optionControl) {
      optionControl.setValue(false, { emitEvent: false });
      if (disable) {
        optionControl.disable({ emitEvent: false });
      }
    }
  }

  private enableAllDependentControls() {
    this.deselectTriggerDependentControls.forEach(option => {
      this.enableOption(option.name);
    });
  }

  private enableOption(optionName: string) {
    const optionControl = this.formGroup.get(optionName);
    if (optionControl) {
      optionControl.enable({ emitEvent: false });
    }
  }
}
