<ng-container *ngIf="dataSource?.data.length">
  <mat-paginator
    [length]="totalResults"
    [pageSize]="pageSize"
    [pageIndex]="currentPageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="changePage($event)"
  >
  </mat-paginator>

  <div class="data-table-container">
    <div class="data-table-wrapper">
      <ul class="no-bullet records-list flex-row-container wrap">
        <li *ngFor="let record of dataSource.data; index as index" class="inline-block record">
          <stx-readonly-image
            [highResUrl]="record.photoUrl"
            [lowResUrl]="record.photoUrl"
            [rawUrl]="record.photoUrl"
            [imageWidth]="250"
            [heightAuto]="true"
            [fullWidthImage]="true"
            [mediaId]="record.photoId"
          ></stx-readonly-image>

          <stx-treatment-status-indicator
            [showLabel]="false"
            [treatmentStatus]="mapToStatusEnum(record.treatmentStatus)"
          ></stx-treatment-status-indicator>
          <span class="display-block full-width fs-14 record-text">{{ record.firstName }} {{ record.firstNameLoc }}</span>
          <span class="display-block full-width fs-14 record-text">{{ record.middleName }} {{ record.middleNameLoc }}</span>
          <stx-patient-link [patientId]="record.patientId">
            <span class="display-block full-width fs-14 record-text"> {{ record.lastName }} {{ record.lastNameLoc }} </span>
          </stx-patient-link>
        </li>
      </ul>
    </div>
  </div>

  <mat-paginator
    [length]="totalResults"
    [pageSize]="pageSize"
    [pageIndex]="currentPageIndex"
    (page)="changePage($event)"
    [pageSizeOptions]="pageSizeOptions"
  >
  </mat-paginator>
</ng-container>

<div *ngIf="reportTableDataService.dataReady && !dataSource?.data.length">
  <stx-empty-table-state></stx-empty-table-state>
</div>
