import { FormStatus } from '@shared/enums/form-status.enum';
import { OrthoFormType } from '@src/app/features/ortho/utils/ortho-form-type';
import { OrthoStage } from '@src/app/features/ortho/utils/ortho-stage';
import { DictionaryTranslation } from '@src/app/features/patient/models/patient.model';
import { TreatmentFilter } from '@src/app/features/search/models/treatment-filter.model';
import { formStatusToTreatmentStatusMap } from '@src/app/features/search/search.utils';

const mixedDentitionOrthoStageToTreatmentType = new Map<OrthoStage, DictionaryTranslation>([
  [
    OrthoStage.ASSESSMENT,
    {
      value: 'mdAssessmentStates',
      label: 'treatment.ortho_md_assessment'
    }
  ],
  [
    OrthoStage.TREATMENT,
    {
      value: 'mdTreatmentStates',
      label: 'treatment.ortho_md_treatment'
    }
  ],
  [
    OrthoStage.MID_TREATMENT_ASSESSMENT,
    {
      value: 'mdMidAssessmentStates',
      label: 'treatment.ortho_md_mid_assessment'
    }
  ]
]);
const permanentDentitionOrthoStageToTreatmentType = new Map<OrthoStage, DictionaryTranslation>([
  [
    OrthoStage.ASSESSMENT,
    {
      value: 'pdAssessmentStates',
      label: 'treatment.ortho_pd_assessment'
    }
  ],
  [
    OrthoStage.TREATMENT,
    {
      value: 'pdTreatmentStates',
      label: 'treatment.ortho_pd_treatment'
    }
  ],
  [
    OrthoStage.MID_TREATMENT_ASSESSMENT,
    {
      value: 'pdMidAssessmentStates',
      label: 'treatment.ortho_pd_mid_assessment'
    }
  ]
]);
const psioOrthoStageToTreatmentType = new Map<OrthoStage, DictionaryTranslation>([
  [
    OrthoStage.ASSESSMENT,
    {
      value: 'psioAssessmentStates',
      label: 'treatment.ortho_psio_assessment'
    }
  ],
  [
    OrthoStage.TREATMENT,
    {
      value: 'psioTreatmentStates',
      label: 'treatment.ortho_psio_treatment'
    }
  ]
]);
const orthoFormTypeToTreatmentTypeMap = new Map<OrthoFormType, Map<OrthoStage, DictionaryTranslation>>([
  [OrthoFormType.MIXED_DENTITION, mixedDentitionOrthoStageToTreatmentType],
  [OrthoFormType.PERMANENT_DENTITION, permanentDentitionOrthoStageToTreatmentType],
  [OrthoFormType.PRESURGICAL_ORTHOPEDICS, psioOrthoStageToTreatmentType]
]);

function getSingleOrthodonticFilters(
  orthoFormType: OrthoFormType,
  orthoFilterStatusesMode: OrthoFilterStatusesMode,
  isSeparated: boolean
): TreatmentFilter[] {
  const treatmentInfo: Map<OrthoStage, DictionaryTranslation> = orthoFormTypeToTreatmentTypeMap.get(orthoFormType);
  const filters: TreatmentFilter[] = [
    {
      treatmentType: treatmentInfo.get(OrthoStage.ASSESSMENT),
      treatmentStatuses:
        orthoFilterStatusesMode !== OrthoFilterStatusesMode.NO_STATUSES
          ? [
              formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED),
              formStatusToTreatmentStatusMap.get(FormStatus.IN_PROGRESS),
              formStatusToTreatmentStatusMap.get(FormStatus.REJECTED)
            ]
          : []
    },
    {
      treatmentType: treatmentInfo.get(OrthoStage.TREATMENT),
      treatmentStatuses:
        orthoFilterStatusesMode !== OrthoFilterStatusesMode.NO_STATUSES
          ? [
              formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED),
              formStatusToTreatmentStatusMap.get(FormStatus.IN_PROGRESS),
              formStatusToTreatmentStatusMap.get(FormStatus.REJECTED),
              formStatusToTreatmentStatusMap.get(FormStatus.DISCONTINUED)
            ]
          : [],
      isSeparated: isSeparated && orthoFormType === OrthoFormType.PRESURGICAL_ORTHOPEDICS
    }
  ];
  if (orthoFormType !== OrthoFormType.PRESURGICAL_ORTHOPEDICS) {
    filters.push({
      treatmentType: treatmentInfo.get(OrthoStage.MID_TREATMENT_ASSESSMENT),
      treatmentStatuses:
        orthoFilterStatusesMode === OrthoFilterStatusesMode.ALL_STATUSES
          ? [
              formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED),
              formStatusToTreatmentStatusMap.get(FormStatus.IN_PROGRESS),
              formStatusToTreatmentStatusMap.get(FormStatus.REJECTED)
            ]
          : [],
      isSeparated: isSeparated
    });
  }
  return filters;
}

export function getOrthodonticFilters(
  groupByOrthoForm: boolean = false,
  orthoFilterStatusesMode: OrthoFilterStatusesMode = OrthoFilterStatusesMode.ALL_STATUSES
): TreatmentFilter[] {
  if (!groupByOrthoForm) {
    return [
      ...getSingleOrthodonticFilters(OrthoFormType.PRESURGICAL_ORTHOPEDICS, orthoFilterStatusesMode, true),
      ...getSingleOrthodonticFilters(OrthoFormType.MIXED_DENTITION, orthoFilterStatusesMode, true),
      ...getSingleOrthodonticFilters(OrthoFormType.PERMANENT_DENTITION, orthoFilterStatusesMode, false)
    ];
  }
  return [
    {
      treatmentType: {
        value: 0,
        label: 'treatment.presurgical_orthopedics'
      },
      children: getSingleOrthodonticFilters(OrthoFormType.PRESURGICAL_ORTHOPEDICS, orthoFilterStatusesMode, true)
    },
    {
      treatmentType: {
        value: 1,
        label: 'treatment.mixed_dentition'
      },
      children: getSingleOrthodonticFilters(OrthoFormType.MIXED_DENTITION, orthoFilterStatusesMode, true)
    },
    {
      treatmentType: {
        value: 2,
        label: 'treatment.permanent_dentition'
      },
      children: getSingleOrthodonticFilters(OrthoFormType.PERMANENT_DENTITION, orthoFilterStatusesMode, false)
    }
  ];
}

export enum OrthoFilterStatusesMode {
  NO_STATUSES,
  WITHOUT_INTERMEDIATE_STATUSES,
  ALL_STATUSES
}
