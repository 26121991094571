import { TranslateService } from '@ngx-translate/core';
import { LanguagePair } from '@shared/components/language-switcher/language.model';
import UppyEnglishLocale from '@uppy/locales/lib/en_US';
import UppySpanishLocale from '@uppy/locales/lib/es_ES';
import UppyFrenchLocale from '@uppy/locales/lib/fr_FR';
import UppyIndonesianLocale from '@uppy/locales/lib/id_ID';
import UppyPortugueseLocale from '@uppy/locales/lib/pt_PT';
import UppyVietnameseLocale from '@uppy/locales/lib/vi_VN';
import UppyChineseLocale from '@uppy/locales/lib/zh_CN';

export const formLanguages: Array<LanguagePair> = [
  { shortcut: 'ar', name: `العربية` },
  { shortcut: 'as', name: 'অসমীয়া' },
  { shortcut: 'bn', name: 'বাংলা' },
  { shortcut: 'my', name: 'မြန်မာဘာသာ' },
  { shortcut: 'en', name: 'English' },
  { shortcut: 'en-ph', name: 'English (Philippines)' },
  { shortcut: 'en-th', name: 'English (Thailand)' },
  { shortcut: 'es', name: 'Español' },
  { shortcut: 'fr', name: 'Français' },
  { shortcut: 'gu', name: 'ગુજરાત' },
  { shortcut: 'ht', name: 'Kreyòl' },
  { shortcut: 'hi', name: 'हिन्दी' },
  { shortcut: 'id', name: 'Bahasa Indonesia' },
  { shortcut: 'kn', name: 'ಕನ್ನಡ' },
  { shortcut: 'ml', name: 'മലയാളം' },
  { shortcut: 'mr', name: 'मराठी' },
  { shortcut: 'or', name: 'ଓଡ଼ିଆ' },
  { shortcut: 'pt-br', name: 'Português (Brasil)' },
  { shortcut: 'pt-mz', name: 'Português (Moçambique)' },
  { shortcut: 'pa', name: `پنجابی • ਪੰਜਾਬੀ` },
  { shortcut: 'ru', name: 'Русский' },
  { shortcut: 'tl', name: 'Tagalog' },
  { shortcut: 'ta', name: 'தமிழ்' },
  { shortcut: 'te', name: 'తెలుగు' },
  { shortcut: 'th', name: 'ภาษาไทย' },
  { shortcut: 'ur', name: `اُردُو‬` },
  { shortcut: 'vi', name: 'Tiếng Việt' },
  { shortcut: 'yo', name: 'Yorùbá' },
  { shortcut: 'cn', name: '中文' }
];

export const indiaDeclarationFormLanguages: Array<LanguagePair> = [
  { shortcut: 'as', name: 'অসমীয়া' },
  { shortcut: 'bn', name: 'বাংলা' },
  { shortcut: 'en', name: 'English' },
  { shortcut: 'gu', name: 'ગુજરાત' },
  { shortcut: 'hi', name: 'हिन्दी' },
  { shortcut: 'kn', name: 'ಕನ್ನಡ' },
  { shortcut: 'ml', name: 'മലയാളം' },
  { shortcut: 'mr', name: 'मराठी' },
  { shortcut: 'or', name: 'ଓଡ଼ିଆ' },
  { shortcut: 'pa', name: `پنجابی • ਪੰਜਾਬੀ` },
  { shortcut: 'ta', name: 'தமிழ்' },
  { shortcut: 'te', name: 'తెలుగు' },
  { shortcut: 'ur', name: `اُردُو‬` }
];

export const languages: LanguagePair[] = [
  { shortcut: 'en', name: 'English' },
  { shortcut: 'es', name: 'Español' },
  { shortcut: 'fr', name: 'Français' },
  { shortcut: 'pt_PT', name: 'Português' },
  { shortcut: 'id', name: 'Indonesia' },
  { shortcut: 'vi', name: 'Tiếng Việt' },
  { shortcut: 'zh', name: '中文' }
];

export const langToMomentLocaleMap = new Map<string, string>([
  ['English', 'en-gb'],
  ['Español', 'es'],
  ['Français', 'fr'],
  ['Português', 'pt'],
  ['Indonesia', 'id'],
  ['Tiếng Việt', 'vi'],
  ['中文', 'zh-cn']
]);

export const langToUppyLocaleMap = new Map<string, any>([
  ['English', UppyEnglishLocale],
  ['Español', UppySpanishLocale],
  ['Français', UppyFrenchLocale],
  ['Português', UppyPortugueseLocale],
  ['Indonesia', UppyIndonesianLocale],
  ['Tiếng Việt', UppyVietnameseLocale],
  ['中文', UppyChineseLocale]
]);

export const alternativeMappings: LanguagePair[] = [
  { shortcut: 'cn', name: '中文' },
  { shortcut: 'pt', name: 'Português' }
];

export function getLanguageByShortcut(shortcut: string): LanguagePair {
  let selectedLanguage = languages.find(language => language.shortcut === shortcut);
  if (!selectedLanguage) {
    selectedLanguage = alternativeMappings.find(language => language.shortcut === shortcut);
  }
  return selectedLanguage;
}

export function getLanguageShortcut(languageName: string): string {
  const languageList = [...languages, ...alternativeMappings];

  return languageList.find(languagePair => languagePair.name === languageName)?.shortcut;
}

export function getLanguageExtensionForUserLanguage(translateService: TranslateService): string {
  const lang = translateService.currentLang;
  const langMappings = [].concat(alternativeMappings, languages);
  return langMappings.find(lp => lp.name === lang)?.shortcut || 'en';
}
