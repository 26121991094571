<ng-container *ngIf="dataSource?.data.length">
  <mat-paginator
    [length]="totalResults"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [pageIndex]="currentPageIndex"
    (page)="changePage($event)"
  ></mat-paginator>
  <div class="data-table-container">
    <div class="data-table-wrapper">
      <table mat-table [dataSource]="dataSource" aria-describedby="data-table-container">
        <ng-container matColumnDef="treatmentCenter">
          <th mat-header-cell *matHeaderCellDef>{{ 'treatment_center.treatment_center' | translate }}</th>
          <td class="wrap-content" mat-cell *matCellDef="let recordData">
            <stx-simple-report-cell
              [rowInputs]="[recordData.treatmentCenter, recordData.country, recordData.province]"
            ></stx-simple-report-cell>
          </td>
        </ng-container>
        <ng-container matColumnDef="patient">
          <th mat-header-cell *matHeaderCellDef>{{ 'patient.patient' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <div class="align-middle flex-row-container">
              <stx-patient-link [showIcon]="false" [patientId]="recordData.patientId">
                <stx-simple-report-cell [rowInputs]="[recordData.hospitalRecordId]"></stx-simple-report-cell>
              </stx-patient-link>
              <stx-patient-link [patientHistoryRoute]="true" [patientId]="recordData.patientId"> </stx-patient-link>
            </div>
            <stx-simple-report-cell
              [rowInputs]="[recordData.hospitalRecordIdLoc, recordData.patientName, recordData.patientLocalName, recordData.patientId]"
              [inlineInputsToTranslate]="[recordData.gender]"
              [inlineInputs]="[recordData.dateOfBirth]"
            ></stx-simple-report-cell>
            <ng-container *ngIf="referringOrganization">
              <stx-simple-report-cell [rowInputs]="[recordData.referringOrganization]"></stx-simple-report-cell>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="operations">
          <th mat-header-cell *matHeaderCellDef>{{ 'operations' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <div class="flex-row-container">
              <stx-treatment-status-indicator
                [addBottomPadding]="true"
                [showLabel]="false"
                [treatmentStatus]="recordData.status"
              ></stx-treatment-status-indicator>
              <div class="flex-column-container">
                <ng-container *ngFor="let operation of recordData.operations; last as isLast">
                  <a [routerLink]="[surgeryInterventionLink, recordData.id]" [queryParams]="{ patientId: recordData.patientId }"
                    >{{ operation | translate }}
                  </a>
                </ng-container>
              </div>
            </div>
            <stx-treatment-date
              class="bold"
              [operationDate]="recordData.operationDate"
              [submitDate]="recordData.submissionDate"
            ></stx-treatment-date>
            <stx-treatment-violations *ngIf="treatmentViolations" [recordData]="recordData"></stx-treatment-violations>
          </td>
        </ng-container>
        <ng-container matColumnDef="surgeon">
          <th mat-header-cell *matHeaderCellDef>{{ 'surgeon' | translate }}</th>
          <td class="wrap-content" mat-cell *matCellDef="let recordData">
            <stx-simple-report-cell [rowInputs]="[recordData.surgeons | localName : recordData.surgeonsLocal]"></stx-simple-report-cell>
          </td>
        </ng-container>
        <ng-container matColumnDef="anesthesiologist">
          <th mat-header-cell *matHeaderCellDef>{{ 'practitioner.anesthesiologist' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            {{ recordData.nameOfAnesthesiologist }}
            <br />
            {{ recordData.nameOfAnesthesiologistLocalLanguage }}
          </td>
        </ng-container>
        <ng-container matColumnDef="complications">
          <th mat-header-cell *matHeaderCellDef>{{ 'complications' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <div *ngFor="let complication of recordData.complications">
              {{ complication.label | translate }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="photos">
          <th mat-header-cell *matHeaderCellDef>{{ 'photos' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <div class="flex-row-container gap-10" *ngFor="let photo of recordData.photos">
              <div class="wrap flex-row-container gap-50-grid" *ngFor="let preSurgeryPhoto of photo.preTreatment; index as index">
                <stx-image-thumbnail
                  thumbnailId="{{ 'pre-treatment' + index }}"
                  imageAlt="{{ 'surgical_intervention.pre_surgery' | translate }}"
                  [imageUrl]="preSurgeryPhoto"
                  [mediaId]="photo.preTreatmentId"
                >
                  {{ 'surgical_intervention.pre_surgery' | translate }}
                </stx-image-thumbnail>
              </div>
              <div class="wrap flex-row-container gap-50-grid" *ngFor="let postSurgeryPhoto of photo.postTreatment; index as index">
                <stx-image-thumbnail
                  thumbnailId="{{ 'post-treatment' + index }}"
                  imageAlt="{{ 'surgical_intervention.post_surgery' | translate }}"
                  [imageUrl]="postSurgeryPhoto"
                  [mediaId]="photo.postTreatmentId"
                >
                  {{ 'surgical_intervention.post_surgery' | translate }}
                </stx-image-thumbnail>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="qaStages">
          <th mat-header-cell *matHeaderCellDef>{{ 'reports.filters.quality_assurance.title' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <ng-container *ngIf="recordData.qaPreRank && recordData.qaPostRank">
              {{ recordData.qaPreRank }} | {{ recordData.qaPostRank }} = {{ recordData.qaPreRank * recordData.qaPostRank }}
            </ng-container>
            <div *ngIf="recordData.qaReviewerName">{{ recordData.qaReviewerName }}</div>
            <div *ngIf="qaNotes">{{ recordData.qaNotes }}</div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="sticky-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <mat-paginator
      [length]="totalResults"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="changePage($event)"
    ></mat-paginator>
  </div>
</ng-container>
<div *ngIf="reportTableDataService.dataReady && dataSource?.data.length === 0">
  <stx-empty-table-state></stx-empty-table-state>
</div>
