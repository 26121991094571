<ng-container *ngIf="!expandable">
  <stx-patient-forms-headers-container [formType]="formType"></stx-patient-forms-headers-container>
  <h1>
    <small>{{ 'presurgical.presentation' | translate }}</small>
  </h1>
  <stx-ortho-treatment-guidelines-link *ngIf="!print"></stx-ortho-treatment-guidelines-link>
  <stx-treatment-status-bar
    *ngIf="!expandable"
    [treatment]="orthoFormModel"
    (unlockClicked)="unlock()"
    (deleteClicked)="delete()"
  ></stx-treatment-status-bar>
</ng-container>

<div class="page-section-container" *ngIf="isReadonlyView">
  <stx-ortho-readonly-assessment
    [treatment]="orthoFormModel"
    [orthoFormType]="OrthoFormType.PRESURGICAL_ORTHOPEDICS"
  ></stx-ortho-readonly-assessment>
</div>

<ng-container *ngIf="!isReadonlyView">
  <form *ngIf="tcList && patient" [formGroup]="treatmentFormGroup">
    <stx-form-panel>
      <p class="form-section-heading">
        {{ 'ortho.general_patient_information' | translate }}
      </p>
      <stx-ortho-general-assessment-info
        #generalAssessmentInfo
        [orthoFormType]="OrthoFormType.PRESURGICAL_ORTHOPEDICS"
        [tcList]="tcList"
        [orthodonticApplianceOptions]="psioApplianceTypes"
        [orthodonticApplianceErrorNames]="['presurgicalOrthopedicsTypes']"
        [orthodonticApplianceLabel]="'presurgical.orthopedics_options'"
        [minCareProvidedDate]="minPsioAssessmentDate"
        [maxCareProvidedDate]="maxPsioAssessmentDate"
        [formMode]="formMode"
      ></stx-ortho-general-assessment-info>
    </stx-form-panel>

    <stx-expandable-form-panel [expanded]="true">
      <ng-container slot="heading">
        {{ 'photos' | translate }}
      </ng-container>
      <div>
        <p>{{ 'ortho.piso.photo.info.mirror' | translate }}</p>
        <p>{{ 'ortho.piso.photo.info.no_piso' | translate }}</p>
        <p>
          {{ 'ortho.piso.photo.info.resources.pre_link' | translate }}
          <stx-static-file-link file="pdf/Orthopedic_Orthodontic_Record_Checklist_{lang}.pdf">
            {{ 'ortho.piso.photo.info.resources.link' | translate }}
          </stx-static-file-link>
          {{ 'ortho.piso.photo.info.resources.post_link' | translate }}
        </p>
      </div>
      <stx-image-group
        [formGroup]="treatmentFormGroup"
        [formMode]="formMode"
        [imageConfigs]="presurgicalOrthopedicsAssessmentImagesConfigs"
        [print]="print"
      >
      </stx-image-group>
    </stx-expandable-form-panel>

    <stx-action-buttons
      *ngIf="!print"
      [asyncSecondaryAction]="onSaveButtonClicked()"
      [asyncPrimaryAction]="onSubmitButtonClicked()"
    ></stx-action-buttons>
  </form>
</ng-container>
