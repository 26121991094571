import { AutocompleteSelectBaseComponent } from '@/src/app/shared/components/autocomplete-multi-select/autocomplete-select-base.component';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NgControl } from '@angular/forms';
import { ArrayUtils } from '@utils/array.utils';
import { noop } from 'rxjs';
import { Item } from '../../models/item.model';

@Component({
  selector: 'stx-autocomplete-multi-select',
  templateUrl: './autocomplete-multi-select.component.html',
  styleUrls: ['./autocomplete-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteMultiSelectComponent extends AutocompleteSelectBaseComponent<Item> implements OnInit, ControlValueAccessor {
  valuesSelection = new SelectionModel<Item>(true);
  inputPlaceholder = '';
  initialValues: Item[] = [];
  onChanged: any = (x: Item[]) => {
    /* This is part of ControlValueAccessor base impl */
  };

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl,
    formBuilder: UntypedFormBuilder,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(formBuilder, changeDetectorRef);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(items: Item[]): void {
    if (items) {
      // filteredItems collection is initialized and available just after init phase
      // for other cases values set from the outside of component are kept on initialValues
      if (this.filteredItems?.length) {
        this.setOptions(items);
      } else {
        this.initialValues = items;
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    noop();
  }

  override ngOnInit() {
    super.ngOnInit();

    if (this.initialValues.length) {
      this.setOptions(this.initialValues);
    }

    if (this.autoSelectSingleItem) {
      this.selectOneAndOnlyOptionIfNeeded();
    }
  }

  toggleOption(item: Item) {
    this.valuesSelection.toggle(item);
    this.onOptionSelect();
  }

  override selectOption(item: Item) {
    this.valuesSelection.select(item);
    this.onOptionSelect();
  }

  onOptionSelect() {
    this.onChanged(this.getSelectedOptions());
    this.assignPlaceholder();
    this.resetSearchInput();
  }

  private setOptions(options: Item[]) {
    this.valuesSelection.clear();

    options.forEach((option: Item) => {
      this.valuesSelection.select(option);
    });

    this.assignPlaceholder();
    this.changeDetectorRef.detectChanges();
  }
  private assignPlaceholder() {
    if (this.valuesSelection.selected.length > 0) {
      this.inputPlaceholder = `Selected (${this.valuesSelection.selected.length})`;
    } else {
      this.inputPlaceholder = '';
    }
  }

  private getSelectedOptions(): any {
    if (this.valuesSelection.selected.length > 0) {
      return this.valuesSelection.selected;
    }
    return undefined;
  }

  protected override adjustSelectionsToNewItems(): void {
    const alreadySelectedIds = this.valuesSelection.selected.map(selectedItem => selectedItem.id);
    const newItemsToSelect = this._itemList.filter(newItem => ArrayUtils.contains(alreadySelectedIds, newItem.id));
    this.valuesSelection.clear();
    this.valuesSelection.select(...newItemsToSelect);
    if (alreadySelectedIds.length !== newItemsToSelect.length) {
      this.onOptionSelect();
    }
  }
}
