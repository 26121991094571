import { OverrideQaStageMode, QaStageType } from '@/src/app/features/practitioner/models/qa-results.enum';
import { OverrideQaStageParams, QaStageModalConfig } from '@/src/app/features/practitioner/models/qa-results.model';
import { newReviewStageOptions } from '@/src/app/features/practitioner/utils/qa-results.utils';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { registerUpdateValueAndValidityForAllControls } from '@/src/app/shared/validation/validation.utils';
import { requiredIfValidator, StxValidators } from '@/src/app/shared/validation/validators';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { FormMode } from '@/src/app/utils/form.utils';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  selector: 'stx-override-qa-stage-modal',
  templateUrl: './override-qa-stage-modal.component.html',
  styleUrls: ['./override-qa-stage-modal.component.scss']
})
export class OverrideQaStageModalComponent extends BaseComponent implements OnInit {
  readonly nextStageOptions = newReviewStageOptions;
  readonly formMode = FormMode.NEW;
  formGroup: UntypedFormGroup;
  isInNewStageMode: boolean;
  submitClicked: boolean;
  constructor(
    private dialogRef: MatDialogRef<OverrideQaStageModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: QaStageModalConfig<OverrideQaStageParams>,
    private formBuilder: UntypedFormBuilder,
    private wsHelperService: WsHelperService
  ) {
    super();
  }

  get nextStageValue() {
    return this.isInNewStageMode && this.formGroup?.get('nextStage')?.value;
  }

  get isNewStageMonitor() {
    return (
      this.isInNewStageMode && (this.nextStageValue === QaStageType.MONITOR || this.nextStageValue === QaStageType.MONITOR_POST_TRAINING)
    );
  }

  get isNewStagePeriodic() {
    return this.isInNewStageMode && this.nextStageValue === QaStageType.PERIODIC;
  }
  get isNewStageTrain() {
    return this.isInNewStageMode && this.nextStageValue === QaStageType.TRAIN;
  }

  ngOnInit(): void {
    this.isInNewStageMode = this.data.mode === OverrideQaStageMode.NEW_REVIEW_STAGE;

    if (this.isInNewStageMode) {
      this.initNewStageForm();
    } else {
      this.initAssignTrainingDateForm();
    }
  }

  private initNewStageForm() {
    this.formGroup = this.formBuilder.group({
      nextStage: [QaStageType.MONITOR, StxValidators.required],
      date: [
        null,
        requiredIfValidator(() => this.isNewStageTrain || (this.isNewStageMonitor && !this.formGroup.get('monitorUntilCount').value))
      ],
      monitorUntilCount: [null, [Validators.min(0), requiredIfValidator(() => this.isNewStageMonitor && !this.formGroup.get('date').value)]]
    });
    registerUpdateValueAndValidityForAllControls(this.formGroup, this.subSink);
  }

  private initAssignTrainingDateForm() {
    this.formGroup = this.formBuilder.group({
      nextStage: [QaStageType.TRAIN],
      trainDate: [null, StxValidators.required]
    });
  }

  private getDataForSave(): OverrideQaStageParams {
    if (this.isInNewStageMode) {
      return this.getNewStageDataForSave();
    } else {
      return { ...this.formGroup.value };
    }
  }

  private getNewStageDataForSave(): OverrideQaStageParams {
    const form = this.formGroup.value;
    const base = { nextStage: form.nextStage };

    if (this.isNewStageMonitor) {
      return { ...base, monitorUntilDate: form.date, monitorUntilCount: form.monitorUntilCount };
    } else if (this.isNewStageTrain) {
      return { ...base, trainDate: form.date };
    } else {
      return { ...base };
    }
  }

  back(): void {
    this.dialogRef.close();
  }

  save: AsyncButtonClickAction = () => {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      return of(null);
    }
    this.submitClicked = true;
    return this.wsHelperService.callWithSpinner(this.data.saveAction(this.getDataForSave())).pipe(
      tap({
        next: () => {
          this.data.refresh();
          this.back();
        },
        error: () => {
          this.submitClicked = false;
        }
      })
    );
  };
}
