<div class="patient-treatment p-t-15 p-b-15 p-l-5 p-r-5 fs-13" [ngClass]="{ odd: !isEven }">
  <div class="flex-row-container flex-flow-wrap">
    <div class="form-element-20-lt-lg-100">
      <div class="column-lt-lg-space-between">
        <div *ngIf="canAccessPatient() && patientTreatment.id; else readonlyPatient" class="can-access-patient-container">
          <a routerLink="{{ patientRoute }}">{{ patientTreatment.firstName }} {{ patientTreatment.lastName }}</a>
        </div>
        <ng-template #readonlyPatient>
          <div class="custom-half-flex-component">{{ patientTreatment.firstName }} {{ patientTreatment.lastName }}</div>
        </ng-template>

        <div class="custom-half-flex-component">
          <div *ngIf="patientTreatment.middleName">{{ patientTreatment.middleName }}</div>
          <div *ngIf="patientTreatment.middleNameLoc">{{ patientTreatment.middleNameLoc }}</div>
        </div>

        <div *ngIf="patientTreatment.lastNameLoc && patientTreatment.firstNameLoc" class="can-access-patient-container">
          {{ patientTreatment.firstNameLoc }} {{ patientTreatment.lastNameLoc }}
        </div>
      </div>
    </div>
    <div class="form-element-20-lt-lg-100">
      <div class="column-lt-lg-space-between">
        <div class="break-word form-element-basis-100-lt-lg-50-lt-sm-100">{{ patientTreatment.recordNumberId }}</div>
        <div class="break-word form-element-basis-100-lt-lg-50-lt-sm-100" *ngIf="patientTreatment.recordNumberIdLoc">
          {{ patientTreatment.recordNumberIdLoc }}
        </div>
      </div>
    </div>
    <div class="main-container gap-5 align-justify">
      <div class="break-all flex-50-lt-sm-100">
        <div
          *ngIf="
            patientTreatment?.treatment?.treatmentCenter &&
              patientTreatment.treatment.treatmentCenter.id &&
              canFollowLink(PermissionEnum.GET_TREATMENT_CENTER_DETAILS);
            else readonlyTC
          "
          class="flex-column-container"
        >
          <a
            [routerLink]="'/' + treatmentCentersRoute + '/' + patientTreatment.treatment.treatmentCenter.id"
            target="_blank"
            (click)="$event.stopPropagation()"
          >
            {{ patientTreatment.treatment.treatmentCenter.name }}
          </a>

          <a
            *ngIf="patientTreatment.treatment.treatmentCenter.nameLoc"
            [routerLink]="'/' + treatmentCentersRoute + '/' + patientTreatment.treatment.treatmentCenter.id"
            target="_blank"
            (click)="$event.stopPropagation()"
          >
            {{ patientTreatment.treatment.treatmentCenter.nameLoc }}
          </a>
        </div>
        <ng-template #readonlyTC>
          <div *ngIf="patientTreatment?.treatment?.treatmentCenter.name; else otherTC">
            {{ patientTreatment.treatment.treatmentCenter.name }}
            <ng-template [ngIf]="patientTreatment.treatment.treatmentCenter.nameLoc">
              {{ patientTreatment.treatment.treatmentCenter.nameLoc }}
            </ng-template>
          </div>
          <ng-template #otherTC>
            <ng-container *ngIf="patientTreatment.treatment">{{ 'treatment_center.other_st_tc' | translate }}</ng-container>
          </ng-template>
        </ng-template>
      </div>
      <div class="break-all main-container">
        <ng-container
          *ngIf="
            patientTreatment.partner && patientTreatment.partner.id && canFollowLink(PermissionEnum.GET_PARTNER_DETAILS);
            else readonlyPartner
          "
        >
          <a [routerLink]="'/' + partnersRoute + '/' + patientTreatment.partner.id" target="_blank" (click)="$event.stopPropagation()">
            {{ patientTreatment.partner.name }}
          </a>

          <a
            *ngIf="patientTreatment.partner.nameLoc"
            [routerLink]="'/' + partnersRoute + '/' + patientTreatment.partner.id"
            target="_blank"
            (click)="$event.stopPropagation()"
          >
            {{ patientTreatment.partner.nameLoc }}
          </a>
        </ng-container>
        <ng-template #readonlyPartner>
          <div *ngIf="patientTreatment?.partner?.name; else otherPartner">
            <div>{{ patientTreatment.partner.name }}</div>
            <div *ngIf="patientTreatment?.partner?.nameLoc">{{ patientTreatment?.partner?.nameLoc }}</div>
          </div>
          <ng-template #otherPartner>
            <ng-container *ngIf="patientTreatment.treatment">{{ 'partner.other_st_partner' | translate }}</ng-container>
          </ng-template>
        </ng-template>
      </div>
      <div class="patient-details-action">
        <ng-container *ngIf="!patient?.isReadonly">
          <stx-patient-details-actions [patientTreatment]="patientTreatment"></stx-patient-details-actions>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="m-t-10 bold" *ngIf="patientTreatment.treatment">
    <div class="patient-treatment-container gap-5">
      <div class="patient-treatment-status align-top form-element-20-50-100">
        <stx-treatment-status-indicator [treatmentStatus]="treatmentStatus"></stx-treatment-status-indicator>
      </div>
      <div class="form-element-20-50-100">
        <stx-patient-treatment-dates [treatment]="treatmentItemData"></stx-patient-treatment-dates>
      </div>
      <div class="form-element-20-50-100">
        <div *ngFor="let treatment of treatmentTypes">
          <div *ngIf="isNutritionTreatment(patientTreatment.treatment.treatmentType)">
            {{ treatment | translate }} - {{ nutritionLabel | translate }}
          </div>
          <div *ngIf="!isNutritionTreatment(patientTreatment.treatment.treatmentType)">{{ treatment | translate }}&nbsp;</div>
        </div>
      </div>
      <div class="form-element-20-25-100">
        <div class="align-middle" *ngFor="let actionDate of actionDates">
          <mat-icon *ngIf="actionDate.date" [svgIcon]="actionDate.icon" class="m-r-10 action-date-icon"></mat-icon>
          {{ actionDate.date | date : 'yyyy-MM-dd' }}
        </div>
      </div>
    </div>
  </div>
</div>
