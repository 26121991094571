<stx-resources-navigation selectedItemId="{{ NavigationSubTabEnum.RESOURCES_WHO_REFERENCE_DATA }}"></stx-resources-navigation>
<stx-generic-wide-page>
  <stx-page-header [name]="'resources.who.page-title' | translate"></stx-page-header>
  <stx-generic-resource-list [sections]="[zipSection]"></stx-generic-resource-list>
  <div class="layout-columns-1-1-1">
    <div>
      <stx-generic-resource-list [sections]="[growthSection]"></stx-generic-resource-list>
    </div>
    <hr class="vertical-separator" />
    <div>
      <stx-generic-resource-list [sections]="[weightForHeightSection]"></stx-generic-resource-list>
    </div>
    <hr class="vertical-separator" />
    <div>
      <stx-generic-resource-list [sections]="[heightForAgeSection]"></stx-generic-resource-list>
    </div>
    <hr class="vertical-separator" />
    <div>
      <stx-generic-resource-list [sections]="[bmiSection]"></stx-generic-resource-list>
    </div>
  </div>
</stx-generic-wide-page>
