import { Component, Input } from '@angular/core';
import { MediaType } from '@shared/enums/media.enum';
import { MediaResource } from '@shared/models/media-resource.model';

@Component({
  selector: 'stx-readonly-view-video',
  templateUrl: './readonly-view-video.component.html'
})
export class ReadonlyViewVideoComponent {
  @Input() video: MediaResource;

  private getUrl(mediaType: MediaType) {
    return (this.video?.urlList || []).find(url => url.urlType === mediaType && url.mediaFileAvailable)?.url;
  }
  get rawUrl(): string {
    return this.getUrl(MediaType.RAW);
  }

  get convertedUrl(): string {
    return this.getUrl(MediaType.CONVERTED);
  }
}
