<stx-generic-page>
  <stx-report-header>
    {{ 'reports.qa_reliability.report_title' | translate }}
  </stx-report-header>
  <stx-report-filters
    (applyFilters)="applyFilters()"
    [leftColumnInfoTemplate]="leftColumnInfo"
    [reportConfig]="reportConfig"
  ></stx-report-filters>

  <ng-template #leftColumnInfo>
    <p class="m-t-0 m-b-10">
      {{ 'report.qa_reliability.filter_info.line_1' | translate }}
    </p>
    <p class="m-0">
      {{ 'report.qa_reliability.filter_info.line_2' | translate }}
    </p>
  </ng-template>

  <ng-container *ngIf="tablesReady">
    <section>
      <h2 class="bold fs-22 text-uppercase">{{ 'report.qa_reliability.section_title.summary' | translate }}</h2>
      <stx-qa-reviewer-reliability-report-summary [summaryPage]="summaryPage"></stx-qa-reviewer-reliability-report-summary>
    </section>

    <section class="p-t-30" *ngIf="tablesReady">
      <h2 class="bold fs-22 text-uppercase">{{ 'report.qa_reliability.section_title.records' | translate }}</h2>
      <stx-qa-reviewer-reliability-records
        (pageChange)="changePage($event)"
        [recordsPage]="recordsPage"
      ></stx-qa-reviewer-reliability-records>
    </section>
  </ng-container>
</stx-generic-page>
