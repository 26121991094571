import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { StatisticsTable } from '@shared/modules/statistics/statistics.model';
import { PermissionEnum } from 'src/app/core/permissions/permission.enum';
import { UserService } from '../../../user/services/user.service';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { PasswordExpiryService } from '@src/app/features/authentication/services/password-expiry.service';

@Component({
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent extends BaseComponent implements OnInit {
  readonly PermissionEnum = PermissionEnum;
  statisticsTable: StatisticsTable;
  taskCounter = 0;

  constructor(
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private wsHelperService: WsHelperService,
    private passwordExpiryService: PasswordExpiryService
  ) {
    super();
  }

  ngOnInit() {
    this.getStatisticsTables();
    this.passwordExpiryService.showPasswordExpiryPopup();
  }

  addTask(): void {
    this.taskCounter++;
  }

  removeTask(): void {
    --this.taskCounter;
  }

  get hasActiveTasks(): boolean {
    return this.taskCounter > 0;
  }

  getStatisticsTables() {
    this.addTask();
    this.subSink.sink = this.wsHelperService.call(this.userService.getDashboardStatisticsTables()).subscribe({
      next: statistics => {
        this.statisticsTable = statistics;
        this.removeTask();
        this.cd.detectChanges();
      },
      error: () => {
        this.removeTask();
        this.cd.detectChanges();
      }
    });
  }
}
