import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { SimpleReportTableColumn } from '@src/app/features/reports/components/report-filters/models/report-data.model';

@Component({
  selector: 'stx-simple-report-table',
  templateUrl: './simple-report-table.component.html'
})
export class SimpleReportTableComponent {
  @Input() columns: SimpleReportTableColumn[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() dataSource: MatTableDataSource<unknown>;
  @Output() sortData: EventEmitter<Sort> = new EventEmitter<Sort>();

  get isMobileScreenSize(): boolean {
    const mobileBreakPointSize: number = 768;
    return window.innerWidth < mobileBreakPointSize;
  }
}

@NgModule({
  declarations: [SimpleReportTableComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    TranslateModule,
    MatIconModule,
    FlexModule,
    ReadonlyCommonsModule,
    GeneralCommonsModule
  ],
  exports: [SimpleReportTableComponent]
})
export class SimpleReportTableComponentModule {}
