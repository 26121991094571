<div class="flex-row-container full-height">
  <div class="flex-grow-shrink">
    <a routerLink="/"><img src="../../../../../assets/images/st-logo.png" class="st-logo-mobile" alt="Smile Train Logo" /></a>
  </div>
  <ng-container *ngIf="hasActiveProfile">
    <div class="flex-grow-shrink m-r-15 align-right layout-align-center">
      <button
        data-testid="button-save-patient-mobile"
        mat-flat-button
        class="m-r-10 color-link"
        *stxPermitted="PermissionEnum.SAVE_PATIENT"
        [routerLink]="newPatientRoute"
        [matTooltip]="'menu.register_new_patient' | translate"
      >
        <mat-icon class="m-r-5">person_add</mat-icon>
      </button>
      <button
        data-testid="button-upload-offline-record-mobile"
        mat-flat-button
        class="color-link"
        *stxPermitted="PermissionEnum.UPLOAD_OFFLINE_APP_FILE"
        [routerLink]="uploadOfflineRecordRoute"
        [matTooltip]="'menu.upload_offline_record' | translate"
      >
        <mat-icon class="m-r-5 material-icons-outlined">file_upload</mat-icon>
      </button>

      <button data-testid="button-menu" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>

      <mat-menu #menu class="menu-screen">
        <div *ngFor="let navItem of navItems">
          <button
            data-testid="button-nav-mobile"
            *ngIf="!navItem.subItems; else buttonWithSubitems"
            mat-menu-item
            routerLinkActive="active"
            [routerLink]="navItem.href"
            class="menu-item menu-button text-uppercase"
          >
            {{ navItem.text | translate }}
          </button>
          <ng-template #buttonWithSubitems>
            <stx-mobile-button-with-subitems [buttonWithSubItems]="navItem"></stx-mobile-button-with-subitems>
          </ng-template>
        </div>
        <mat-divider class="mat-divider-margin"></mat-divider>
        <label mat-menu-item class="menu-item menu-label">
          {{ 'menu.your_account' | translate }}
        </label>
        <button
          data-testid="button-edit-settings-mobile"
          mat-menu-item
          [routerLink]="'settings'"
          class="menu-item menu-button text-uppercase"
        >
          {{ 'menu.edit_settings' | translate }}
        </button>
        <ng-container *ngIf="canChangeProfile">
          <button
            data-testid="button-switch-profile-mobile"
            mat-menu-item
            [routerLink]="'select-profile'"
            class="menu-item menu-button text-uppercase"
          >
            {{ 'menu.switch_profile' | translate }}
          </button>
        </ng-container>
        <div *stxPermitted="PermissionEnum.GET_TEMP_LANG_CHANGE">
          <stx-language-temp></stx-language-temp>
        </div>
        <button data-testid="button-logout-mobile" mat-menu-item (click)="logout()" class="menu-item menu-button text-uppercase">
          {{ 'logout' | translate }}
        </button>
      </mat-menu>
    </div>
  </ng-container>
</div>
