import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionEnum } from 'src/app/core/permissions/permission.enum';
import { PermissionGuard } from '../authentication/helpers/permission.guard';
import { PartnerDetailsPageComponent } from './components/partner-details-page/partner-details-page.component';
import { PartnerSearchPageComponent } from './components/partner-search-page/partner-search-page.component';

const routes: Routes = [
  { path: '', component: PartnerSearchPageComponent, data: { newStyles: true } },
  {
    path: ':id',
    component: PartnerDetailsPageComponent,
    canActivate: [PermissionGuard],
    data: { permission: PermissionEnum.GET_PARTNER_DETAILS, newStyles: true }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PartnersRoutingModule {}
