import { Injectable } from '@angular/core';
import { LoggerService } from '@shared/services/logger.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ButtonDisablerService {
  private disablerObject = new Subject<void>();
  disabler$ = this.disablerObject.asObservable();
  taskCounter = 0;

  constructor(private readonly loggerService: LoggerService) {}

  addTask(): void {
    this.taskCounter++;
    this.disablerObject.next();
  }

  removeTask(): void {
    --this.taskCounter;
    if (this.taskCounter < 0) {
      this.loggerService.error('ButtonDisablerService task counter is below 0', new Error().stack);
      this.taskCounter = 0;
    }
    this.disablerObject.next();
  }

  get hasActiveTasks(): boolean {
    return this.taskCounter > 0;
  }
}
