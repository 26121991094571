<stx-spinner [showSpinner]="showSpinner">
  <ng-container *ngIf="!hideNavigation; else noNavigation">
    <div class="content-all">
      <stx-navigation class="not-print" *ngIf="isLoggedIn"></stx-navigation>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
      <stx-footer class="not-print" *ngIf="isLoggedIn"></stx-footer>
    </div>
  </ng-container>
  <ng-template #noNavigation>
    <router-outlet></router-outlet>
  </ng-template>
</stx-spinner>
