import { ReportDates } from '@/src/app/features/reports/models/reports.model';
import { defaultPeriodGroupValue } from '@/src/app/features/reports/stats-reports.utils';
import { getOrthodonticFilters, OrthoFilterStatusesMode } from '@/src/app/features/search/search-ortho.utils';
import { FormsCommonsModule } from '@/src/app/shared/modules/forms-commons/forms-commons.module';
import { MaterialModule } from '@/src/app/shared/modules/material/material.module';
import { dateRangeToBigErrorName } from '@/src/app/shared/validation/validation.constants';
import { expandAnimationMetadata } from '@/src/app/utils/animation.utils';
import { trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { NoticeComponentModule } from '@shared/components/notice/notice.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { FormStatus } from '@shared/enums/form-status.enum';
import { getEducationalResourceUrl } from '@src/app/features/educational-resources/utils/educational-resources.utils';
import { orthoStageToTranslationMap } from '@src/app/features/ortho/utils/ortho.utils';
import { AgeGroupsFilterComponentModule } from '@src/app/features/reports/components/report-filters/components/age-groups-filter/age-groups-filter.component';
import { SectionedCheckboxGroupFilterModule } from '@src/app/features/reports/components/report-filters/components/sectioned-checkbox-group-filter/sectioned-checkbox-group-filter.component';
import { ReportFiltersFormGeneratorService } from '@src/app/features/reports/components/report-filters/services/report-filters-form-generator.service';
import { TreatmentTypeFilterComponent } from '@src/app/features/search/components/filters/treatment-type-filter/treatment-type-filter.component';
import { TreatmentFilter } from '@src/app/features/search/models/treatment-filter.model';
import { getLanguageShortcut } from '@utils/language.utils';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { FormModule } from 'src/app/shared/components/form/form.module';
import { SearchModule } from '../../../search/search.module';
import { AgeFilterComponentModule } from './components/age-filter/age-filter.component';
import { CheckboxGroupFilterComponentModule } from './components/checkbox-group-filter/checkbox-group-filter.component';
import { CheckboxTreeFilterComponentModule } from './components/checkbox-tree-filter/checkbox-tree-filter.component';
import { DateFilterComponentModule } from './components/date-filter/date-filter.component';
import { GenderPickerModule } from './components/gender-filter/gender-filter.component';
import { GroupByScopeComponentModule } from './components/group-by-scope/group-by-scope.component';
import { LatestCasesFilterComponentModule } from './components/latest-cases-filter/latest-cases-filter.component';
import { PeriodFilterComponentModule } from './components/period-filter/period-filter.component';
import { QualityAssuranceFilterModule } from './components/quality-assurance-filter/quality-assurance-filter.component';
import { RadioTreeComponentModule } from './components/radio-tree-filter/radio-tree-filter.component';
import { RecordDateFilterComponentModule } from './components/record-date-filter/record-date-filter.component';
import { RepairTypesComponentModule } from './components/repair-types/repair-types.component';
import { ReportBooleanFilterComponentModule } from './components/report-boolean-filter/report-boolean-filter.component';
import { ReportExclusionsFilterComponentModule } from './components/report-exclusions-filter/report-exclusions-filter.component';
import { ReportGeographyFilterModule } from './components/report-geography-filter/report-geography-filter.component';
import { ReportOrganizationsTreeFilterModule } from './components/report-organizations-tree-filter/report-organizations-tree-filter.component';
import { ReportSingleSelectionFilterComponentModule } from '@src/app/features/reports/components/report-filters/components/report-multi-selection-filter/report-multi-selection-filter.component';
import { ReportTreatmentStatusFilterComponentModule } from './components/report-treatment-status-filter/report-treatment-status-filter.component';
import { StatsTypeFilterComponentModule } from './components/stats-type-filter/stats-type-filter.component';
import { SurgeryTypeModule } from './components/surgical-intervention/surgical-intervention-filter.component';
import {
  AllReportTypes,
  NutritionStatusesChangePayload,
  OrthoStatusesChangePayload,
  ReportConfig,
  ReportFilterChangePayload,
  ReportFilterName
} from './models/report-filters.model';
import { ReportDictionariesService } from './services/report-dictionaries.service';
import { ReportService } from './services/report.service';
import { getNutritionFilters } from '@src/app/features/search/search-nutrition.utils';
import { SpeechTypeModule } from '@src/app/features/reports/components/report-filters/components/speech-options-filter/speech-options-filter.component';

@Component({
  selector: 'stx-report-filters',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./report-filters.component.scss'],
  templateUrl: './report-filters.component.html',
  animations: [trigger('filtersSection', expandAnimationMetadata)]
})
export class ReportFiltersComponent extends BaseComponent implements OnInit {
  readonly dateRangeToBigErrorName = dateRangeToBigErrorName;

  @Input() reportConfig: ReportConfig;
  @Input() leftColumnInfoTemplate?: TemplateRef<any>;
  @Output() applyFilters = new EventEmitter<PaginationSortingModel>();
  @ViewChild(TreatmentTypeFilterComponent) orthoTypeAndStatusFilterComponent: TreatmentTypeFilterComponent;

  pagination: PaginationSortingModel = {
    pageNumber: 0,
    pageSize: 25,
    sortedBy: '',
    sortDirection: ''
  };
  orthoTypeAndStatusFilters: TreatmentFilter[];
  readonly formStatus = FormStatus;
  readonly orthoStageToTranslationMap = orthoStageToTranslationMap;
  nutritionTypeAndStatusFilters: TreatmentFilter[];

  constructor(
    public reportService: ReportService,
    public reportDictionariesService: ReportDictionariesService,
    private translateService: TranslateService,
    private readonly cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.reportService.showFilters();
    this.reportService.prepare(this.reportConfig);
    this.detectChangesOnFormStateUpdate();
    this.watchFilterStatusesChangesIfApplicable();
    this.orthoTypeAndStatusFilters = getOrthodonticFilters(
      true,
      this.reportConfig.type === 'ORTHODONTIC_TREATMENTS_COMPACT'
        ? OrthoFilterStatusesMode.NO_STATUSES
        : OrthoFilterStatusesMode.WITHOUT_INTERMEDIATE_STATUSES
    );
    this.nutritionTypeAndStatusFilters = getNutritionFilters();
    this.managePeriodsFilterIfExist();
  }

  private detectChangesOnFormStateUpdate() {
    this.subSink.sink = this.reportService.filtersReady$
      .pipe(
        switchMap(filtersReady => (filtersReady ? (this.reportService.reportFiltersFormGroup.statusChanges as Observable<string>) : EMPTY))
      )
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.cd.detectChanges();
      });
  }

  get isStatsReport(): boolean {
    return (
      this.reportConfig.type === 'SURGERY_STATS' ||
      this.reportConfig.type === 'ORTHODONTIC_STATS' ||
      this.reportConfig.type === 'NUTRITION_STATS' ||
      this.reportConfig.type === 'SPEECH_STATS'
    );
  }

  hasFormError(groupName: string, errorName: string): boolean {
    return !!this.reportService.reportFiltersFormGroup?.get(groupName)?.getError(errorName);
  }

  getSafetyAndQualityProtocolHref(): string {
    const language = getLanguageShortcut(this.translateService.currentLang);

    return getEducationalResourceUrl(
      {
        resourceName: 'Safety_and_Quality_Protocol_'
      },
      language
    );
  }

  private watchFilterStatusesChangesIfApplicable() {
    if (this.reportConfig.filters.orthoTypes) {
      this.subSink.sink = this.reportService.filtersReady$
        .pipe(filter(filtersReady => filtersReady))
        .subscribe(() => this.listenForStatusesChangesAndUpdateModel<OrthoStatusesChangePayload>(AllReportTypes.ORTHODONTIC_STATS));
    }

    if (this.reportConfig.filters.nutritionTypes) {
      this.subSink.sink = this.reportService.filtersReady$
        .pipe(filter(filtersReady => filtersReady))
        .subscribe(() => this.listenForStatusesChangesAndUpdateModel<NutritionStatusesChangePayload>(AllReportTypes.NUTRITION_STATS));
    }
  }

  private listenForStatusesChangesAndUpdateModel<T extends ReportFilterChangePayload>(reportType: string) {
    const statusesChangesObservables = this.getStatusObservableArrayForTreatmentType(reportType);

    this.subSink.sink = combineLatest(statusesChangesObservables).subscribe({
      next: (partialFiltersValues: Array<Partial<T>>) => {
        const combineStatuses = {} as T;
        partialFiltersValues.forEach(partialFilterValue => Object.assign(combineStatuses, partialFilterValue));
        this.reportService.onFilterChange({ filterName: this.getFilterNameForTreatmentType(reportType), payload: combineStatuses });
      }
    });
  }

  private getFilterNameForTreatmentType(reportType: string): ReportFilterName {
    switch (reportType) {
      case AllReportTypes.ORTHODONTIC_STATS: {
        return 'orthoStatuses';
      }

      case AllReportTypes.NUTRITION_STATS: {
        return 'nutritionStatuses';
      }
    }
  }

  private getStatusObservableArrayForTreatmentType(reportType: string) {
    switch (reportType) {
      case AllReportTypes.ORTHODONTIC_STATS: {
        return ReportFiltersFormGeneratorService.orthoStatusesFilterNames.map((controlName: keyof OrthoStatusesChangePayload) =>
          this.getStatusObservableForControlName<OrthoStatusesChangePayload>(controlName)
        );
      }
      case AllReportTypes.NUTRITION_STATS: {
        return ReportFiltersFormGeneratorService.nutritionFilterNames.map((controlName: keyof NutritionStatusesChangePayload) =>
          this.getStatusObservableForControlName<NutritionStatusesChangePayload>(controlName)
        );
      }
    }
  }

  private getStatusObservableForControlName<T extends ReportFilterChangePayload>(
    controlName: keyof T
  ): Observable<Partial<{ [name: string]: any }>> {
    return this.reportService.reportFiltersFormGroup.get(controlName as string).valueChanges.pipe(
      distinctUntilChanged(),
      map(statuses => {
        return { [controlName]: statuses ? statuses : [] };
      })
    );
  }

  private managePeriodsFilterIfExist(): void {
    if (this.reportConfig.filters.periodPresets) {
      this.subSink.sink = this.reportService.filtersReady$.subscribe(filtersReady => {
        if (filtersReady) {
          this.managePeriodFiltersDependingOnDates();
        }
      });
    }
  }

  private managePeriodFiltersDependingOnDates(): void {
    this.subSink.sink = this.reportService.reportFiltersFormGroup
      .get('dates')
      .valueChanges.pipe(
        map((reportDates: ReportDates) => ReportFiltersComponent.isDateFilterEmpty(reportDates)),
        distinctUntilChanged()
      )
      .subscribe(resetAndDisablePeriodsFilter => {
        const periodFormGroup = this.reportService.reportFiltersFormGroup.get('period');
        if (resetAndDisablePeriodsFilter) {
          periodFormGroup.reset(defaultPeriodGroupValue);
          periodFormGroup.disable({ onlySelf: false });
        } else {
          periodFormGroup.enable({ onlySelf: false });
        }
      });
  }

  private static isDateFilterEmpty = (reportDates: ReportDates): boolean => !!reportDates.dateFrom || !!reportDates.dateTo;
}

@NgModule({
  declarations: [ReportFiltersComponent],
  imports: [
    CommonModule,
    FormModule,
    FlexLayoutModule,
    MaterialModule,
    SearchModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    AgeFilterComponentModule,
    AgeGroupsFilterComponentModule,
    GenderPickerModule,
    SurgeryTypeModule,
    SpeechTypeModule,
    MatDividerModule,
    MatIconModule,
    QualityAssuranceFilterModule,
    ReportGeographyFilterModule,
    ReportOrganizationsTreeFilterModule,
    LatestCasesFilterComponentModule,
    ReportSingleSelectionFilterComponentModule,
    RepairTypesComponentModule,
    PeriodFilterComponentModule,
    ReportBooleanFilterComponentModule,
    GroupByScopeComponentModule,
    NoticeComponentModule,
    CheckboxGroupFilterComponentModule,
    SectionedCheckboxGroupFilterModule,
    DateFilterComponentModule,
    RecordDateFilterComponentModule,
    ReportTreatmentStatusFilterComponentModule,
    ReportExclusionsFilterComponentModule,
    RadioTreeComponentModule,
    StatsTypeFilterComponentModule,
    CheckboxTreeFilterComponentModule,
    FormsCommonsModule
  ],
  exports: [ReportFiltersComponent]
})
export class ReportFiltersModule {}
