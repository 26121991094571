export enum RoleEnum {
  STX_SUPER_USER = 'STX Super User',
  ST_PROGRAM = 'ST Program',
  ST_STAFF = 'ST Staff',
  CRM = 'CRM',
  PARTNER_STAFF = 'Partner Staff',
  PARTNER_STAFF_READONLY = 'Partner Staff - Read only',
  QA_REVIEWER_SURGICAL = 'QA Reviewer - surgical',
  QA_REVIEWER_ORTHO = 'QA Reviewer - ortho',
  QA_REVIEWER_SPEECH = 'QA Reviewer - speech',
  ST_PROGRAMS_CONSULTANT = 'ST Programs Consultant',
  PARTNER_STAFF_SPEECH_THERAPIST = 'Partner Staff - Speech Therapist',
  PARTNER_STAFF_ORTHODONTIST = 'Partner Staff - Orthodontist',
  PARTNER_STAFF_NUTRIONIST = 'Partner Staff - Nutritionist',
  AUTOMATIC_TASK = 'Automatic Task'
}
