import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { patientDetailsRoute } from '@/src/app/utils/routing.utils';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from '../../../patient/models/patient.model';
import { NutritionFollowUpPilot } from '../../models/nutrition-follow-up-pilot.model';
import { NutritionFollowUpPilotService } from '../../services/nutrition-follow-up-pilot.service';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

//This is a legacy code we should not use it in future
@Component({
  selector: 'stx-nutrition-follow-up-historical',
  templateUrl: './nutrition-follow-up-historical.component.html'
})
export class NutritionFollowUpHistoricalComponent extends BaseComponent implements OnInit {
  readonly formType = FormType.NUTRITION_FOLLOW_UP_HISTORICAL;
  readonly treatmentType = TreatmentType.NUTRITION_FOLLOW_UP_HISTORICAL;

  @Input() nutritionFollowUpPilot: NutritionFollowUpPilot;
  @Input() print: boolean;
  @Input() patient: Patient;
  @Input() expandable: boolean;

  constructor(
    private nutritionFollowUpPilotService: NutritionFollowUpPilotService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.nutritionFollowUpPilot) {
      this.subSink.sink = this.route.params.subscribe(params => {
        if (params.id) {
          this.getNutritionFollowUp(params.id);
        }
      });
    }
  }

  private getNutritionFollowUp(id: TreatmentId) {
    this.wsHelperService.callWithSpinner(this.nutritionFollowUpPilotService.getNutritionFollowUpPilot(id)).subscribe(result => {
      this.nutritionFollowUpPilot = result;
    });
  }

  delete() {
    this.wsHelperService
      .callWithSpinner(this.nutritionFollowUpPilotService.deleteNutritionFollowUpPilot(this.nutritionFollowUpPilot.businessId))
      .subscribe(() => {
        this.router.navigate(patientDetailsRoute(this.patient.id));
      });
  }
}
