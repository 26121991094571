<div class="treatment-date-cell">
  <div class="flex-{{ direction }}-container gap-{{ direction === 'column' ? '7' : '15' }}">
    <ng-container *ngIf="operationDate">
      <div class="flex-center gap-5">
        <a *ngIf="operationDateLink" [routerLink]="operationDateLink.route" [queryParams]="operationDateLink.params">
          <span>
            {{ operationDate }}
          </span>
        </a>
        <span *ngIf="!operationDateLink"> {{ operationDate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="createDate">
      <div class="gap-5">
        <mat-icon svgIcon="creation-date"></mat-icon>
        <a *ngIf="createDateLink" [routerLink]="createDateLink.route" [queryParams]="createDateLink.params">
          <span>
            {{ createDate }}
          </span>
        </a>
        <span *ngIf="!createDateLink"> {{ createDate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="submitDate">
      <div class="gap-5">
        <mat-icon svgIcon="submission-date"></mat-icon>
        <span>
          {{ submitDate }}
        </span>
      </div>
    </ng-container>
  </div>
</div>
