export enum Gender {
  M = 'M',
  F = 'F'
}

export enum MatchType {
  SAME = 'SAME',
  NOT_SAME = 'NOT_SAME',
  DONT_KNOW = 'DONT_KNOW'
}

export enum RecordType {
  REPORT_CARE_PROVIDED = 'REPORT_CARE_PROVIDED',
  REPORT_RECORD_SUBMITTED = 'REPORT_RECORD_SUBMITTED',
  REPORT_RECORD_LAST_UPDATED = 'REPORT_RECORD_LAST_UPDATED'
}
