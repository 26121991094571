import { Component, OnChanges, OnInit } from '@angular/core';
import { NutritionCheckboxItem } from '@src/app/features/nutrition-management/models/nutrition-item.model';
import { nutritionalStatusOptions } from '@src/app/features/nutrition-management/utils/nutrition-management.utils';
import { BaseNutritionSectionComponent } from '@src/app/features/nutrition-management/shared/base-nutrition-section.component';

@Component({
  selector: 'stx-nutritional-status',
  templateUrl: './nutritional-status.component.html'
})
export class NutritionalStatusComponent extends BaseNutritionSectionComponent implements OnInit, OnChanges {
  nutritionStatusOptions: NutritionCheckboxItem[] = [];

  ngOnChanges(): void {
    this.updateOptions();
  }

  ngOnInit(): void {
    this.updateOptions();
  }

  updateOptions(): void {
    this.nutritionStatusOptions = nutritionalStatusOptions.filter(option => option.showFor.includes(this.nutritionFormType));
  }
}
