import { Injectable } from '@angular/core';
import { BaseHttpService } from '@shared/services/http/base-http.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SpeechAssessment } from './models/speech-assessment.model';
import { DictionaryTranslation } from '../patient/models/patient.model';
import { map, shareReplay } from 'rxjs/operators';
import { shareReplayBufferSize } from '@utils/http.utils';
import { SpeechTreatment } from './models/speech-treatment.model';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

const speechAssessmentUrl = 'speech-assessments';
const speechTreatmentUrl = 'speech-treatments';

@Injectable({
  providedIn: 'root'
})
export class SpeechService extends BaseHttpService {
  private speechAssessmentDictionary$: Observable<Map<string, DictionaryTranslation[]>>;
  private speechTreatmentDictionary$: Observable<Map<string, DictionaryTranslation[]>>;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getSpeechAssessmentStaticDictionaries(): Observable<Map<string, DictionaryTranslation[]>> {
    if (!this.speechAssessmentDictionary$) {
      this.speechAssessmentDictionary$ = this.httpClient
        .get<Map<string, DictionaryTranslation[]>>(`${this.baseUrl}/${speechAssessmentUrl}/static-dictionaries`)
        .pipe(shareReplay(shareReplayBufferSize))
        .pipe(map(res => new Map(Object.entries(res))));
    }
    return this.speechAssessmentDictionary$;
  }
  public saveSpeechAssessment(speechAssessment: SpeechAssessment): Observable<SpeechAssessment> {
    return this.httpClient.post<SpeechAssessment>(`${this.baseUrl}/${speechAssessmentUrl}/save`, speechAssessment);
  }

  public submitSpeechAssessment(speechAssessment: SpeechAssessment): Observable<SpeechAssessment> {
    return this.httpClient.post<SpeechAssessment>(`${this.baseUrl}/${speechAssessmentUrl}/submit`, speechAssessment);
  }

  getSpeechAssessment(speechAssessmentId: TreatmentId): Observable<SpeechAssessment> {
    return this.httpClient.get<SpeechAssessment>(`${this.baseUrl}/${speechAssessmentUrl}/${speechAssessmentId}`);
  }

  public unlockSpeechAssessment(speechAssessmentId: TreatmentId): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}/${speechAssessmentUrl}/unlock/${speechAssessmentId}`, null);
  }

  public deleteSpeechAssessment(speechAssessmentId: TreatmentId): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${speechAssessmentUrl}/${speechAssessmentId}`);
  }

  public getSpeechTreatmentStaticDictionaries(): Observable<Map<string, DictionaryTranslation[]>> {
    if (!this.speechTreatmentDictionary$) {
      this.speechTreatmentDictionary$ = this.httpClient
        .get<Map<string, DictionaryTranslation[]>>(`${this.baseUrl}/${speechTreatmentUrl}/static-dictionaries`)
        .pipe(shareReplay(shareReplayBufferSize))
        .pipe(map(res => new Map(Object.entries(res))));
    }
    return this.speechTreatmentDictionary$;
  }

  public saveSpeechTreatment(speechTreatment: SpeechTreatment): Observable<SpeechTreatment> {
    return this.httpClient.post<SpeechTreatment>(`${this.baseUrl}/${speechTreatmentUrl}/save`, speechTreatment);
  }

  public submitSpeechTreatment(speechTreatment: SpeechTreatment): Observable<SpeechTreatment> {
    return this.httpClient.post<SpeechTreatment>(`${this.baseUrl}/${speechTreatmentUrl}/submit`, speechTreatment);
  }

  public getSpeechTreatment(speechTreatmentId: TreatmentId): Observable<SpeechTreatment> {
    return this.httpClient.get<SpeechTreatment>(`${this.baseUrl}/${speechTreatmentUrl}/${speechTreatmentId}`);
  }

  public discontinueSpeechTreatment(speechTreatment: SpeechTreatment): Observable<SpeechTreatment> {
    return this.httpClient.post<SpeechTreatment>(`${this.baseUrl}/${speechTreatmentUrl}/discontinue`, speechTreatment);
  }

  public unlockSpeechTreatment(speechTreatmentId: TreatmentId): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}/${speechTreatmentUrl}/unlock/${speechTreatmentId}`, null);
  }

  public deleteSpeechTreatment(speechTreatmentId: TreatmentId): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${speechTreatmentUrl}/${speechTreatmentId}`);
  }
}
