<div class="data-table-container">
  <div class="data-table-wrapper">
    <table aria-describedby="data-table-container">
      <thead>
        <tr>
          <th>{{ tableTreatmentTitleLabel | translate }} [{{ 'pilot_program' | translate }}]</th>
          <th>{{ 'all' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="treatmentStats">
          <td>{{ 'treatment.status.submitted' | translate }}</td>
          <td>{{ treatmentStats }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
