<div [ngClass]="{ 'page-break-inside-avoid p-t-20': print }">
  <stx-expandable-form-panel [expanded]="isInstrumentalAssessmentSectionExpanded">
    <ng-container slot="heading">
      {{ 'speech_assessment.instrumental_assessment' | translate }}
      <ng-container *ngIf="!isInstrumentalAssessmentSectionExpanded">
        {{ 'optional' | translate }}
      </ng-container>
    </ng-container>
    <div class="form-row">
      <div>
        <p class="font-italic">{{ 'speech_assessment.instrumental_assessment_information_1' | translate }}</p>
        <p class="font-italic">{{ 'speech_assessment.instrumental_assessment_information_2' | translate }}</p>
      </div>
    </div>

    <div class="form-columns-1-1" [ngClass]="{ 'flex-row-important': print }">
      <div class="form-column">
        <stx-instrumental-assessment-column
          [formGroup]="formGroup"
          [formMode]="formMode"
          [instrumentalAssessmentColumnConfig]="nasopharyngoscopyConfig"
          [print]="print"
          [birthDate]="birthDate"
        ></stx-instrumental-assessment-column>
      </div>
      <div class="form-column">
        <stx-instrumental-assessment-column
          [formGroup]="formGroup"
          [formMode]="formMode"
          [instrumentalAssessmentColumnConfig]="videofluoroscopyConfig"
          [print]="print"
          [birthDate]="birthDate"
        ></stx-instrumental-assessment-column>
      </div>
    </div>

    <div class="form-row" *ngIf="showStandardError(formGroup, null, [assessmentInstrumentalSectionErrorName])">
      <mat-error class="stx-mat-error-align">
        {{ 'speech_assessment.instrumental_assessment_error' | translate }}
      </mat-error>
    </div>

    <hr class="form-subsection-separator" />

    <div class="form-row">
      <p class="form-subsection-heading">{{ 'speech_assessment.nasometry' | translate }} {{ 'optional' | translate }}</p>
    </div>
    <div class="form-columns-1-1">
      <div class="form-row" [ngClass]="{ 'flex-row-important': print }">
        <ng-container *ngFor="let nasometryInput of nasometryInputConfiguration">
          <div>
            <stx-input
              [label]="nasometryInput.label"
              [formMode]="formMode"
              [formGroup]="formGroup"
              [controlName]="nasometryInput.controlName"
              [type]="'number'"
            ></stx-input>
          </div>
        </ng-container>
      </div>
    </div>
  </stx-expandable-form-panel>
</div>
