import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/shared/services/http/base-http.service';
import { TempMediaUploadInfo } from '../../../features/media/models/media.model';

@Injectable({
  providedIn: 'root'
})
export class UppyService extends BaseHttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient, { baseUrl: 'media' });
  }

  getAWSUploadLink(fileName: string): Observable<TempMediaUploadInfo> {
    const params = new HttpParams({ fromObject: { fileName } });
    return this.httpClient.get<TempMediaUploadInfo>(this.baseUrl, { params });
  }
}
