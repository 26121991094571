import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { ValidationErrors } from '../../models/validation-errors.model';

export interface CompareDatesValidatorOptions {
  // pass if date to compare is a static value
  compareToDate?: moment.Moment;

  // pass if date should be read from other control in a group
  compareToControlPath?: string[];
  errorMessagePath?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DateValidatorsService {
  notGreaterThan(options: CompareDatesValidatorOptions) {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateToCompare: moment.Moment | string = options.compareToDate || control.root.get(options.compareToControlPath)?.value;

      if (!control.value || !dateToCompare) {
        return null;
      }

      const controlDay = moment(control.value).startOf('day');
      const compareToDay = moment(dateToCompare).startOf('day');

      if (controlDay.isSameOrBefore(compareToDay)) {
        return null;
      }

      return {
        dateValidatorsNotGreaterThan: {
          message: options.errorMessagePath || 'error.date_max'
        }
      };
    };
  }
}
