import { PermissionModule } from '@/src/app/core/permissions/permission.module';
import { GeographyFilterComponent } from '@/src/app/features/search/components/filters/geography-filter/geography-filter.component';
import { OrgTreeFilterComponent } from '@/src/app/features/search/components/filters/org-tree-filter/org-tree-filter.component';
import { TreatmentStatusFilterComponent } from '@/src/app/features/search/components/filters/treatment-status-filter/treatment-status-filter.component';
import { TreatmentTypeFilterComponent } from '@/src/app/features/search/components/filters/treatment-type-filter/treatment-type-filter.component';
import { UserRoleFilterComponent } from '@/src/app/features/search/components/filters/user-role-filter/user-role-filter.component';
import {
  SearchFilterColumnDirective,
  SearchFilterColumnsComponent
} from '@/src/app/features/search/components/search-filter-columns/search-filter-columns.component';
import { SearchFilterContainerComponent } from '@/src/app/features/search/components/search-filter-container/search-filter-container.component';
import { FormsCommonsModule } from '@/src/app/shared/modules/forms-commons/forms-commons.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ChipsComponentModule } from '@shared/components/chips/chips.component';
import { FormModule } from '@shared/components/form/form.module';
import { AutocompleteMultiSelectComponentModule } from 'src/app/shared/components/autocomplete-multi-select/autocomplete.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';

@NgModule({
  declarations: [
    SearchFilterContainerComponent,
    GeographyFilterComponent,
    OrgTreeFilterComponent,
    TreatmentTypeFilterComponent,
    TreatmentStatusFilterComponent,
    SearchFilterColumnsComponent,
    SearchFilterColumnDirective,
    UserRoleFilterComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ChipsComponentModule,
    FormModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    AutocompleteMultiSelectComponentModule,
    FormsCommonsModule,
    PermissionModule,
    RouterModule
  ],
  exports: [
    GeographyFilterComponent,
    SearchFilterContainerComponent,
    OrgTreeFilterComponent,
    TreatmentTypeFilterComponent,
    TreatmentStatusFilterComponent,
    SearchFilterColumnsComponent,
    SearchFilterColumnDirective,
    UserRoleFilterComponent
  ]
})
export class SearchModule {}
