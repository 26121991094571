import { getToday } from '@/src/app/utils/date.utils';
import { formLanguages } from '@/src/app/utils/language.utils';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PatientReleaseFormOnlineDialogComponent } from '@shared/components/patient-document-upload/patient-release-form-online-dialog/patient-release-form-online-dialog.component';
import {
  OnlinePatientReleaseForm,
  PatientDataForReleaseForm,
  ReleaseFormModalParameters
} from '@shared/components/patient-document-upload/patient-release-form.model';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { findMediaUrl } from '@shared/models/media-resource.model';
import { GeoService } from '@shared/services/geo.service';
import { MediaUrl } from '@src/app/features/media/models/media-url.model';
import { PatientService } from '@src/app/features/patient/patient.service';
import { PatientDataService } from '@src/app/features/patient/services/patient-data.service';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseComponent } from '../../base-component/base.component';
import { LanguagePair } from '@shared/components/language-switcher/language.model';
import { ArrayUtils } from '@utils/array.utils';

function keyValuePairsToMap(translations: { first: string; second: string }[]) {
  return translations.reduce((mapAccumulator, translationPair) => {
    mapAccumulator.set(translationPair.first, translationPair.second);
    return mapAccumulator;
  }, new Map<string, string>());
}

@Component({
  selector: 'stx-patient-release-form-online',
  templateUrl: './patient-release-form-online.component.html',
  styleUrls: ['./patient-release-form-online.component.scss']
})
export class PatientReleaseFormOnlineComponent extends BaseComponent implements OnInit {
  readonly formLanguages;
  @Input() patientDataProvider: () => Observable<PatientDataForReleaseForm> | null;
  @Input() patientDataMatchesReleaseFormData: boolean | null;

  private _formControl: AbstractControl;
  existingOnlinePrf: OnlinePatientReleaseForm | null = null;
  existingPdf: MediaUrl | null = null;

  get now(): moment.Moment {
    return getToday();
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly controlContainer: ControlContainer,
    private readonly dialog: MatDialog,
    private readonly geoService: GeoService,
    patientDataService: PatientDataService,
    private readonly patientService: PatientService
  ) {
    super();
    this.subSink.sink = patientDataService.patient$.subscribe(patient => {
      this.existingPdf = findMediaUrl(patient, ParentOrderName.ELECTRONICALLY_SIGNED_RELEASE_FORM);
      changeDetectorRef.markForCheck();
    });
    this.formLanguages = PatientReleaseFormOnlineComponent.getAvailableLanguagesForOnlinePrf();
  }

  ngOnInit(): void {
    const formGroup = this.controlContainer!.control as UntypedFormGroup;
    this._formControl = formGroup.get('onlineReleaseForm')!;
    this.existingOnlinePrf = this._formControl.value;
    this.subSink.sink = this._formControl.valueChanges.subscribe(value => {
      this.existingOnlinePrf = value;
      this.changeDetectorRef.markForCheck();
    });
  }

  openDialog(selectedLanguage: string) {
    const patient$ = this.patientDataProvider();
    if (!patient$) {
      return;
    }
    this.subSink.sink = combineLatest([
      this.geoService.getCountryDictionaries,
      this.patientService.getReleaseFormTranslations(selectedLanguage),
      patient$
    ])
      .pipe(take(1))
      .subscribe(([countries, translations, patient]) => {
        const data: ReleaseFormModalParameters = {
          lang: selectedLanguage,
          translations: keyValuePairsToMap(translations),
          countries,
          patient
        };
        const dialogRef = this.dialog.open(PatientReleaseFormOnlineDialogComponent, {
          data,
          autoFocus: false
        });
        this.subSink.sink = dialogRef.afterClosed().subscribe((patientReleaseForm?: OnlinePatientReleaseForm) => {
          if (patientReleaseForm) {
            this._formControl.setValue(patientReleaseForm);
          }
        });
      });
  }

  remove() {
    this._formControl.reset();
  }

  private static getAvailableLanguagesForOnlinePrf(): LanguagePair[] {
    // Remove exclusion when languages need to be enabled back:
    const excludedLanguages = [
      'ml', //TODO#13205
      'bn', //TODO#13205
      'kn', //TODO#14119
      'as' //TODO#22708
    ];

    return formLanguages.filter(language => !ArrayUtils.contains(excludedLanguages, language.shortcut));
  }
}
