import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-details-page',
  templateUrl: './details-page.component.html',
  styleUrls: ['./details-page.component.scss']
})
export class DetailsPageComponent {
  @Input()
  pageTitleKey: string;
  @Input()
  name: string;
  @Input()
  localName: string;
}
