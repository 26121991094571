<stx-readonly-view-columns *ngIf="treatment">
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field
      label="reports.assessment_date"
      [value]="treatment.generalNutritionInformation?.evaluationDate"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="age"
      [value]="[treatment.ageMonth | ageYearMonth, ageGroupKey | translate]"
    ></stx-simple-readonly-field>
    <stx-readonly-field label="treatment_center.treatment_center">
      <stx-tc-link [treatmentCenter]="treatment.treatmentCenter"></stx-tc-link>
    </stx-readonly-field>
    <stx-simple-readonly-field label="status" [value]="treatmentStatusLabel | translate"> </stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="practitioner.nutritionist"
      [value]="treatment.practitioner?.name | localName : treatment.practitioner?.nameLoc"
    ></stx-simple-readonly-field>
    <stx-readonly-field *ngFor="let element of generalNutritionInformation">
      {{ element.labelKey | translate }} &ndash; {{ element.value }} {{ element.unitKey | translate }}
    </stx-readonly-field>
    <stx-readonly-view-image *ngIf="photo" [photo]="photo"></stx-readonly-view-image>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-readonly-field *ngIf="treatment.feedingInformation?.birthWeightStatus" label="nutrition_management.birthweight">
      <ul>
        <li>
          {{ treatment.feedingInformation?.birthWeightStatus | dict : 'birthWeightStatus' | async | translate }}
        </li>
      </ul>
    </stx-readonly-field>
    <stx-readonly-field *ngIf="feedingInfo.length > 0" label="nutrition_management.feeding_assessment">
      <ul>
        <li *ngFor="let element of feedingInfo">
          {{ element.labelKey | translate }}
          <ul *ngIf="treatment.feedingInformation?.otherMore && isElementOtherOption(element)">
            <li>
              {{ treatment.feedingInformation?.otherMore }}
            </li>
          </ul>
        </li>
      </ul>
    </stx-readonly-field>
    <stx-readonly-field *ngIf="treatment.feedingInformation?.newbornFeedingStatus" label="nutrition_management.current_feeding">
      <ul>
        <li>
          {{ treatment.feedingInformation?.newbornFeedingStatus | dict : 'newbornFeedingStatus' | async | translate }}
        </li>
      </ul>
    </stx-readonly-field>
    <stx-readonly-field label="nutrition_management.type_of_patient_care">
      <ul>
        <li *ngIf="treatment.initialTypeOfPatientCare?.patientCareType">
          {{
            'nutrition_management.initial_type_of_patient_care.outpatient_care'
              | if : treatment.initialTypeOfPatientCare.patientCareType === PatientCareType.OUTPATIENT
              | translate
          }}
          {{
            'nutrition_management.initial_type_of_patient_care.inpatient_care'
              | if : treatment.initialTypeOfPatientCare.patientCareType === PatientCareType.INPATIENT
              | translate
          }}
          {{
            'nutrition_management.initial_type_of_patient_care.another_service_refferal'
              | if : treatment.initialTypeOfPatientCare.patientCareType === PatientCareType.REFERRAL
              | translate
          }}
          <ul>
            <li *ngIf="treatment?.initialTypeOfPatientCare?.inChargeOfFollowup">
              {{ 'nutrition_management.initial_type_of_patient_care.staff_member_followup' | translate }}
            </li>
            <li *ngIf="treatment?.initialTypeOfPatientCare?.askedToReturn">
              {{
                (treatment.ageGroup === NutritionType.ADULT
                  ? 'nutrition_management.initial_type_of_patient_care.patient_followup'
                  : 'nutrition_management.initial_type_of_patient_care.caregiver_followup'
                ) | translate
              }}
            </li>
          </ul>
        </li>
      </ul>
    </stx-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-readonly-field *ngIf="nutritionalStatus.length > 0" label="nutrition_management.nutritional_status">
      <ul>
        <li *ngFor="let element of nutritionalStatus">
          {{ element.labelKey | translate }}
          <ul *ngIf="treatment.nutritionalStatus?.otherMore && isElementOtherOption(element)">
            <li>
              {{ treatment.nutritionalStatus?.otherMore }}
            </li>
          </ul>
        </li>
      </ul>
    </stx-readonly-field>
    <stx-readonly-field *ngIf="nutritionCarePlan.length > 0" label="nutrition_management.nutrition_care_plan">
      <ul>
        <li *ngFor="let element of nutritionCarePlan">
          {{ element.labelKey | translate }}
          <ul *ngIf="treatment.nutritionCarePlan?.otherMore && isElementOtherOption(element)">
            <li>
              {{ treatment.nutritionCarePlan?.otherMore }}
            </li>
          </ul>
        </li>
      </ul>
    </stx-readonly-field>
    <stx-readonly-field *ngIf="anemiaManagement.length > 0" label="nutrition_management.anemia_management_plan">
      <ul>
        <li *ngFor="let element of anemiaManagement">
          {{ element.labelKey | translate }}
          <ul *ngIf="treatment.anemiaManagement?.otherMore && isElementOtherOption(element)">
            <li>
              {{ treatment.anemiaManagement?.otherMore }}
            </li>
          </ul>
        </li>
      </ul>
    </stx-readonly-field>
  </ng-container>
</stx-readonly-view-columns>
