import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { BaseFormElementComponent } from '../../../base-treatment-form/base-form-element.component';
import { UppyWithPreviewComponent } from '../../../uppy/components/uppy-with-preview/uppy-with-preview.component';
import { StxValidators } from '@shared/validation/validators';
import { interventionTypeControlNames } from '@src/app/features/surgical/components/surgical-intervention-form/surgical-intervention-form.model';
import { UppyErrorService } from '@shared/components/uppy/uppy-error.service';
import { v4 } from 'uuid';

@Component({
  selector: 'stx-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent extends BaseFormElementComponent implements OnInit {
  @Input() label: string;
  @Input() controlName: string;
  @Input() parentOrderName: ParentOrderName;
  @Input() isRequiredAlways: boolean;

  uuid: string = v4();

  protected showError: boolean = false;

  @ViewChild(UppyWithPreviewComponent, { static: false }) uppyWithPreview: UppyWithPreviewComponent;
  constructor(protected uppyErrorService: UppyErrorService, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.watchUppyCustomErrors();
  }

  watchUppyCustomErrors(): void {
    this.subSink.sink = this.uppyErrorService.fileIsEmpty$.subscribe(() => {
      this.showError = this.uppyErrorService.isUploadedFileEmptyBy(this.uuid);

      if (this.showError) {
        this.formGroup.get(this.controlName).reset();
        this.formGroup.get(this.controlName).updateValueAndValidity();
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  private checkIfPhotoIsMandatory(): boolean {
    const lipControlNames = interventionTypeControlNames.filter(intervention => intervention.toLowerCase().includes('lip'));
    const operationTypesRequiringIntraOral = interventionTypeControlNames.filter(intervention => !lipControlNames.includes(intervention));

    if (this.controlName === ParentOrderName.OPERATION_POST_FRONTAL || this.controlName === ParentOrderName.OPERATION_PRE_FRONTAL) {
      return this.checkIfControlsHasAnyValues(lipControlNames);
    }

    if (this.controlName === ParentOrderName.OPERATION_PRE_INTRA_ORAL || this.controlName === ParentOrderName.OPERATION_POST_INTRA_ORAL) {
      return this.checkIfControlsHasAnyValues(operationTypesRequiringIntraOral);
    }
  }

  private checkIfControlsHasAnyValues(controlNames): boolean {
    return controlNames.find(lipControlName => !!this.formGroup.controls[lipControlName]?.value) !== undefined;
  }

  get isRequired(): boolean {
    const hasValidator = this.formGroup.get(this.controlName)?.hasValidator(StxValidators.required);
    const errorExists = this.formGroup.get(this.controlName)?.getError('required');

    return hasValidator || errorExists || this.checkIfPhotoIsMandatory();
  }
}
