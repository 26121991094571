import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { ImageThumbnailComponentModule } from '@shared/components/image-thumbnail/image-thumbnail.component';
import { TreatmentStatusIndicatorComponentModule } from '@shared/components/treatment-status-indicator/treatment-status-indicator.component';
import { SurgicalTreatmentReportRecord } from '../../surgical-treatments-report.model';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { ReportFiltersModel } from '@src/app/features/reports/components/report-filters/models/report-filters.model';
import { SimpleReportCellComponentModule } from '@src/app/features/reports/components/simple-report-cell/simple-report-cell.component';
import { TreatmentViolationComponentModule } from '@src/app/features/reports/components/treatment-violation/treatment-violations.component';
import { intervention, surgicalRoute } from '@utils/routing.utils';
import { ReportTableDataService } from 'src/app/features/reports/components/report-filters/services/report-table-data.service';
import { BaseReportTableComponent } from '../../../../components/base-report-table/base-report-table.component';
import { PatientLinkComponentModule } from '../../../../components/patient-link/patient-link.component';
import { ReportService } from '../../../../components/report-filters/services/report.service';
import { TreatmentDateComponentModule } from '../../../../components/treatment-date/treatment-date.component';
import { LocalNamePipeModule } from '@shared/pipes/local-name.pipe';

@Component({
  selector: 'stx-surgical-treatment-report-table',
  templateUrl: './surgical-treatment-report-table.component.html'
})
export class SurgicalTreatmentReportTableComponent
  extends BaseReportTableComponent<SurgicalTreatmentReportRecord>
  implements OnInit, OnDestroy
{
  readonly surgeryInterventionLink = `/${surgicalRoute}/${intervention}/`;

  @Input() reportConfig: ReportFiltersModel;

  displayedColumns = ['treatmentCenter', 'patient', 'operations', 'surgeon', 'anesthesiologist'];

  get reportedComplications(): boolean {
    return !!this.reportConfig.get('reportedComplications')?.reportedComplications[0];
  }

  get referringOrganization(): boolean {
    return !!this.reportConfig.get('referringOrganization')?.referringOrganization[0];
  }

  get includePhotos(): boolean {
    return !!this.reportConfig.get('includePhotos')?.includePhotos[0];
  }

  get qaStages(): boolean {
    return (
      !!this.reportConfig.get('qaSurgicalInterventions')?.qaSurgicalInterventions[0] ||
      !!this.reportConfig.get('qaPreRanks')?.qaPreRanks[0] ||
      !!this.reportConfig.get('qaPostRanks')?.qaPostRanks[0] ||
      !!this.reportConfig.get('qaSurgeryStatuses')?.qaSurgeryStatuses[0]
    );
  }

  get qaNotes(): boolean {
    return this.reportConfig.get('qaSurgeryStatuses')?.qaSurgeryStatuses.includes('flagged');
  }

  get treatmentViolations(): boolean {
    return !!this.reportConfig.get('treatmentNotCompliant')?.treatmentNotCompliant[0];
  }

  updateDisplayedColumns(): void {
    this.displayedColumns = this.reportTableDataService.updateColumnByName(
      this.reportedComplications,
      'complications',
      this.displayedColumns
    );
    this.displayedColumns = this.reportTableDataService.updateColumnByName(this.includePhotos, 'photos', this.displayedColumns);
    this.displayedColumns = this.reportTableDataService.updateColumnByName(this.qaStages, 'qaStages', this.displayedColumns);
  }

  constructor(
    public changeDetector: ChangeDetectorRef,
    public reportTableDataService: ReportTableDataService,
    public reportService: ReportService,
    public route: ActivatedRoute
  ) {
    super('SURGICAL_TREATMENTS', changeDetector, reportTableDataService, reportService, route);
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }
}

@NgModule({
  declarations: [SurgicalTreatmentReportTableComponent],
  imports: [
    MatTableModule,
    MatSortModule,
    TranslateModule,
    CommonModule,
    MatIconModule,
    RouterModule,
    MatPaginatorModule,
    PatientLinkComponentModule,
    TreatmentStatusIndicatorComponentModule,
    TreatmentDateComponentModule,
    EmptyTableStateComponentModule,
    ImageThumbnailComponentModule,
    FlexModule,
    TreatmentViolationComponentModule,
    SimpleReportCellComponentModule,
    GeneralCommonsModule,
    LocalNamePipeModule
  ],
  exports: [SurgicalTreatmentReportTableComponent]
})
export class SurgicalTreatmentReportTableComponentModule {}
