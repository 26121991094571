import { MediaContentType } from '@/src/app/shared/enums/media.enum';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { MediaResource } from '@shared/models/media-resource.model';
import { saveAs } from 'file-saver';
import { MediaUrl } from '../features/media/models/media-url.model';

export const dataTransferFileKind = 'file';

export class FileUtils {
  static downloadFile(data: any, fileName?: string, type = 'text/csv') {
    const blob = new Blob([data], { type });
    saveAs(blob, fileName);
  }

  static findAllByParentOrderName(mediaResources: MediaResource[] | null, parentOrderName: ParentOrderName): MediaUrl[] | null {
    if (!mediaResources) {
      return null;
    }
    const foundList = mediaResources.find(mediaResource => mediaResource.parentOrderName === parentOrderName);
    return foundList ? foundList.urlList : null;
  }

  static isTypeOfFileInfo(candidate: any) {
    return !!candidate.fileId;
  }
}

export enum Mime {
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  GIF = 'image/gif',
  PDF = 'application/pdf',
  VIDEO = 'video/*',
  AUDIO = 'audio/*',
  ZIP = 'application/zip',
  CSV = 'text/csv',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
}

export enum FileExtension {
  PDF = '.pdf',
  ZIP = '.zip',
  ZIPPED_EXE = '.exe.zip',
  STU = '.stu',
  EXCEL = '.xlsx',
  DOCX = '.docx',
  MP4 = '.mp4',
  PPTX = '.pptx'
}

export const safetyQualityProtocolFilename = 'Safety_and_Quality_Protocol';

export function mapMimeToMediaContentType(type: string): MediaContentType {
  const imageTypes: string[] = [Mime.JPEG, Mime.PNG, Mime.JPG];

  if (type === Mime.AUDIO) {
    return MediaContentType.AUDIO;
  } else if (type === Mime.VIDEO) {
    return MediaContentType.VIDEO;
  } else if (imageTypes.includes(type)) {
    return MediaContentType.IMAGE;
  } else {
    return MediaContentType.OTHER;
  }
}
