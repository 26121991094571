import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DetailsCommonsModule } from '@shared/modules/details-commons/details-commons.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { StatisticsModule } from '@shared/modules/statistics/statistics.module';
import { PermissionModule } from 'src/app/core/permissions/permission.module';
import { TableModule } from 'src/app/shared/components/table/table.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { SearchModule } from '../search/search.module';
import { UsersModule } from '../user/users.module';
import { PartnerDetailsPageComponent } from './components/partner-details-page/partner-details-page.component';
import { PartnerDetailsComponent } from './components/partner-details/partner-details.component';
import { PartnerSearchPageComponent } from './components/partner-search-page/partner-search-page.component';
import { PartnerSearchComponent } from './components/partner-search/partner-search.component';
import { PartnersRoutingModule } from './partners-routing.module';

@NgModule({
  declarations: [PartnerSearchPageComponent, PartnerDetailsComponent, PartnerDetailsPageComponent, PartnerSearchComponent],
  imports: [
    CommonModule,
    DetailsCommonsModule,
    FlexLayoutModule,
    GeneralCommonsModule,
    MatGridListModule,
    MaterialModule,
    PartnersRoutingModule,
    PermissionModule,
    ReactiveFormsModule,
    RouterModule,
    SearchModule,
    StatisticsModule,
    TableModule,
    TranslateModule.forChild(),
    UsersModule
  ],
  exports: []
})
export class PartnerModule {}
