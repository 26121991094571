<div class="wrapper">
  <stx-patient-treatment-dates class="enlarge-for-print" [treatment]="treatment" [hideAgeMonth]="true"></stx-patient-treatment-dates>
  <div>
    <div *ngFor="let treatmentType of treatmentTypes">
      <div class="enlarge-for-print" *ngIf="isNutritionTreatment">{{ treatmentType | translate }} - {{ ageGroupLabel | translate }}</div>

      <div class="enlarge-for-print" *ngIf="!isNutritionTreatment">
        {{ treatmentType | translate }}
      </div>

      <div *ngIf="isTreatmentOutOfScopeForUser()">
        {{ treatmentOutsideOfScopeLabel | translate }}
      </div>
    </div>
  </div>
</div>
