import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { StringUtils } from '@utils/string.utils';

export const assessmentInstrumentalSectionErrorName = 'assessmentInstrumentalSectionError';

export const assessmentStagePreVpdValue = 2;
export const assessmentStagePostVpdValue = 3;

export const speechAssessmentParentOrderNames = [
  ParentOrderName.SPEECH_ASSESSMENT_SAMPLE_SYLLABLE_REPETITION,
  ParentOrderName.SPEECH_ASSESSMENT_SAMPLE_SENTENCE_REPETITION,
  ParentOrderName.SPEECH_ASSESSMENT_INTRAORAL_ASSESSMENT_PICTURE,
  ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_VIDEO,
  ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_REST,
  ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_PHONATION,
  ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_VIDEO,
  ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_REST,
  ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_PHONATION
];
export const emissionRatingFields = ['nasalEmissionNone', 'nasalEmissionAmplified', 'nasalEmissionPhoneme'];
export const emissionPhonemeFields = [
  'nasalEmissionPhonemeS',
  'nasalEmissionPhonemeZ',
  'nasalEmissionPhonemeSh',
  'nasalEmissionPhonemeZh',
  'nasalEmissionPhonemeCh',
  'nasalEmissionPhonemeJ',
  'nasalEmissionPhonemeOther'
];

export const notNoneRecommendationControls = [
  'recommendVpdAssessment',
  'recommendInitiateSpeechTherapy',
  'recommendParentEducation',
  'recommendReeval',
  'recommendReevalMonths',
  'recommendDentalConsult',
  'recommendDentalObturator',
  'recommendTonsillectomy',
  'recommendFistulaRepair',
  'recommendVpd',
  'recommendVpdSecondaryFurlow',
  'recommendVpdPharyngealFlap',
  'recommendVpdIntravelarVeloplasty',
  'recommendVpdSphincterPharyngoplasty',
  'recommendVpdBuccalFlap',
  'recommendOther'
];

export const assessmentRecommendationFields = ['recommendNoSpeechServices', ...notNoneRecommendationControls];

export const validatedRecommendationFields = assessmentRecommendationFields.filter(fieldName => fieldName !== 'recommendReevalMonths');

export const speechAssessmentDynamicFormControlsNames = ['treatmentCenterId'];

export const assessmentInstrumentalSectionValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const assessmentStageControl = formGroup.get('assessmentStage');
  if (assessmentStageControl.value !== assessmentStagePreVpdValue) {
    return null;
  }

  const nasoscopySectionFilled: () => boolean = () => {
    return (
      !StringUtils.isEmpty(formGroup.get('nasoscopyDate').value) &&
      !!formGroup.get(ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_PHONATION).value &&
      !!formGroup.get(ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_VIDEO).value &&
      !!formGroup.get(ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_REST).value
    );
  };
  const videoscopySectionFilled: () => boolean = () => {
    return (
      !StringUtils.isEmpty(formGroup.get('videoscopyDate').value) &&
      !!formGroup.get(ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_PHONATION).value &&
      !!formGroup.get(ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_VIDEO).value &&
      !!formGroup.get(ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_REST).value
    );
  };

  if (nasoscopySectionFilled() || videoscopySectionFilled()) {
    return null;
  }
  return { [assessmentInstrumentalSectionErrorName]: true };
};
