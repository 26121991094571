import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReportConfig } from '../../components/report-filters/models/report-filters.model';
import { ReportFiltersModule } from '../../components/report-filters/report-filters.component';
import { ReportTableDataService } from '../../components/report-filters/services/report-table-data.service';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import { QaOrthoCasesReportTableComponentModule } from './components/qa-ortho-cases-report-table/qa-ortho-cases-report-table.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';

@Component({
  selector: 'stx-qa-ortho-cases',
  templateUrl: './qa-ortho-cases.component.html'
})
export class QaOrthoCasesComponent {
  readonly reportConfig: ReportConfig = {
    type: 'QA_ORTHO_CASES',
    downloadOptions: {
      excel: true
    },
    filters: {
      date: true,
      geography: true,
      organizationsTree: true,
      orthodontists: true,
      reviewers: true
    }
  };

  constructor(public reportService: ReportService, private reportTableDataService: ReportTableDataService) {}

  applyFilters(pagination: PaginationSortingModel) {
    this.reportTableDataService.prepareList(this.reportConfig.type, this.reportService.model, pagination);
  }
}

@NgModule({
  declarations: [QaOrthoCasesComponent],
  imports: [
    CommonModule,
    QaOrthoCasesReportTableComponentModule,
    ReportFiltersModule,
    ReportHeaderComponentModule,
    TranslateModule,
    GeneralCommonsModule
  ],
  exports: [QaOrthoCasesComponent]
})
export class QaOrthoCasesComponentModule {}
