import { Component } from '@angular/core';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { dictionaryFromMapProvider } from '@shared/services/dictionary-provider';
import { SpeechAssessment } from '@src/app/features/speech/models/speech-assessment.model';
import { SpeechService } from '@src/app/features/speech/speech.service';
import { BaseReadonlyViewComponent } from '@shared/modules/readonly-commons/utils/base-readonly-view.component';

@Component({
  selector: 'stx-speech-assessment-readonly',
  templateUrl: './speech-assessment-readonly.component.html',
  providers: [dictionaryFromMapProvider(SpeechService, s => s.getSpeechAssessmentStaticDictionaries())]
})
export class SpeechAssessmentReadonlyComponent extends BaseReadonlyViewComponent<SpeechAssessment> {
  readonly parentOrderNames = ParentOrderName;

  protected afterTreatmentSet(): void {
    // part of baseReadOnlyViewComponentClass
  }
  get hasInstrumentalAssessment(): boolean {
    const instrumentalAssessmentInputFormNames: string[] = [
      'nasalanceScoreNasals',
      'nasalanceScorePb',
      'nasalanceScoreTd',
      'nasalanceScoreKg',
      'nasalanceScoreSz'
    ];

    return instrumentalAssessmentInputFormNames.filter(attributeName => !!this.treatment[attributeName]).length > 0;
  }

  get hasNasopharyngoscopy(): boolean {
    return (
      !!this.treatment.nasoscopyDate ||
      !!this.findMediaWithLabels(ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_VIDEO, ['video']) ||
      !!this.findMediaWithLabels(ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_REST, [
        'speech_assessment.picture_at_rest'
      ]) ||
      !!this.treatment.nasoscopyFindings ||
      !!this.findMediaWithLabels(ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_NASOPHARYNGOSCOPY_PICTURE_PHONATION, [
        'speech_assessment.picture_during_phonation'
      ])
    );
  }

  get hasIntraoralAssessment(): boolean {
    return !!this.treatment.intraoralFistula || !!this.treatment.intraoralAssessmentComment;
  }

  get hasVideofluoroscopy(): boolean {
    return (
      !!this.treatment.videoscopyDate ||
      !!this.findMediaWithLabels(ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_VIDEO, ['video']) ||
      !!this.findMediaWithLabels(ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_REST, [
        'speech_assessment.picture_at_rest'
      ]) ||
      !!this.findMediaWithLabels(ParentOrderName.SPEECH_ASSESSMENT_INSTRUMENTAL_VIDEOFLUOROSCOPY_PICTURE_PHONATION, [
        'speech_assessment.picture_during_phonation'
      ]) ||
      !!this.treatment.videoscopyFindings
    );
  }
}
