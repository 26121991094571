import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { NotFoundErrorType } from '@src/app/features/resource-not-found-page/enums/not-found-error.type';

@Component({
  templateUrl: './resource-not-found-page.component.html'
})
export class ResourceNotFoundPageComponent extends BaseComponent implements OnInit {
  public readonly errorTypeEnum = NotFoundErrorType;
  errType: NotFoundErrorType;

  constructor(private readonly route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.connectRouteData();
  }

  private connectRouteData(): void {
    this.subSink.sink = this.route.data.subscribe(data => {
      this.errType = data.errorType;
    });
  }
}

@NgModule({
  declarations: [ResourceNotFoundPageComponent],
  imports: [CommonModule, TranslateModule, GeneralCommonsModule]
})
export class NotFoundPageModule {}
