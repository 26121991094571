<mat-expansion-panel *ngIf="showTempLangSwitcher">
  <mat-expansion-panel-header class="text-uppercase" (click)="$event.stopPropagation()">
    {{ 'menu.language_test' | translate }}
  </mat-expansion-panel-header>
  <ng-container *ngFor="let lang of translateService.getLangs()">
    <mat-action-row>
      <button
        data-testid="button-language-temp"
        mat-menu-item
        class="menu-item menu-button"
        [ngClass]="{ 'selected-language': lang === activeTempLang }"
        (click)="switchLanguage(lang); $event.stopPropagation()"
      >
        {{ lang }}
      </button>
    </mat-action-row>
  </ng-container>
</mat-expansion-panel>
