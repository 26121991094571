import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseFormControlComponent } from '@shared/components/form/components/base-form-control/base-form-control.component';

@Component({
  selector: 'stx-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends BaseFormControlComponent {
  @Input() label: string;
  @Input() tooltip: string;
  @Input() rowNumber: number;
  @Input() disablePadding: boolean;

  @Output() textareaHasValue = new EventEmitter();

  textareaChanged(): void {
    if (this.formGroup.get(this.controlName).value) {
      this.textareaHasValue.emit(true);
    } else {
      this.textareaHasValue.emit(false);
    }
  }

  get rows(): number {
    return this.rowNumber ?? 2;
  }

  get shouldMakeSpaceForErrorMessage(): boolean {
    return this.disablePadding && this.formControl.errors!! && this.formControl.touched;
  }
}
