<stx-patient-forms-headers-container cssClass="m-l-25 m-b-25" *ngIf="!expandable" [formType]="formType">
</stx-patient-forms-headers-container>
<stx-surgical-treatment-status-bar
  *ngIf="!expandable"
  [surgicalTreatment]="surgicalIntervention"
  [patient]="patient"
  [suspiciousRecordReviewer]="suspiciousRecordReviewerName"
  [reviewModel]="qaReview"
  (unlockClicked)="unlock()"
  (deleteClicked)="delete()"
></stx-surgical-treatment-status-bar>

<ng-container *ngIf="isReadonlyView">
  <div class="page-section-container">
    <stx-surgical-intervention-readonly [treatment]="surgicalIntervention"></stx-surgical-intervention-readonly>
  </div>
</ng-container>

<ng-container *ngIf="!isReadonlyView">
  <stx-surgical-intervention-form
    [expandable]="expandable"
    [formMode]="formMode"
    [patient]="patient"
    [surgicalIntervention]="surgicalIntervention"
    [print]="print"
    (dataSaved)="dataSaved.emit()"
  ></stx-surgical-intervention-form>
</ng-container>
