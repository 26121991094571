<div #overlay class="image-viewer-overlay" [ngStyle]="{ height: overlayHeight, width: overlayWidth }">
  <div class="image-viewer-nav box-container">
    <img
      src="/assets/icons/close.png"
      class="cursor-pointer user-select-none"
      tabindex="0"
      (click)="close()"
      (keyup.enter)="close()"
      alt=""
    />
    <img
      src="/assets/icons/flip.png"
      class="cursor-pointer user-select-none"
      tabindex="0"
      (click)="flipImage()"
      (keyup.enter)="flipImage()"
      alt=""
    />
    <stx-image-rotator
      [imageContainerNativeElement]="imageContainer"
      [adjustHorizontalMargins]="true"
      [imageNativeElement]="image"
      (rotated)="rotate($event)"
      [usePng]="true"
      [rotateContainer]="false"
    ></stx-image-rotator>
  </div>
</div>
<div #imageContainer class="image-viewer-container" [ngStyle]="{ height: containerHeight, width: containerWidth }">
  <div [ngClass]="{ 'flip-x': isFlipped }">
    <img #image [src]="imageUrl" class="rotated-image" alt="image" />
  </div>
</div>
