import { environment } from '@/src/environments/environment';
import { MediaType } from '@shared/enums/media.enum';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { MediaResource } from '@shared/models/media-resource.model';

export function getStaticFileUrl(fileName: string): string {
  return `${environment.apiUrl}/media/static?filePath=${fileName}`;
}

function getMediaUrlFromResource(resource: MediaResource, mediaType: MediaType) {
  if (resource) {
    const converted = resource.urlList.find(url => url.urlType === MediaType.CONVERTED);
    const lowRes = resource.urlList.find(url => url.urlType === MediaType.LOW_RES);
    const highRes = resource.urlList.find(url => url.urlType === MediaType.HIGH_RES);

    if (mediaType === MediaType.LOW_RES && lowRes) {
      return lowRes.url;
    }

    if (mediaType === MediaType.HIGH_RES && highRes) {
      return highRes.url;
    }

    if (!converted || mediaType === MediaType.RAW) {
      return resource.urlList.find(url => url.urlType === MediaType.RAW).url;
    }

    return converted.url;
  }
}

export function getImageUrl(mediaResources: MediaResource[], parentOrderName: ParentOrderName, mediaType?: MediaType): string | null {
  if (mediaResources && mediaResources.length > 0) {
    const resource = mediaResources.find(photo => photo.parentOrderName === parentOrderName);

    return getMediaUrlFromResource(resource, mediaType);
  }
}

export const requiredPlaceholderImage = 'assets/images/placeholder_photo_mandatory.png';
export const optionalPlaceholderImage = 'assets/images/placeholder_photo_optional.png';
