import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../modules/material/material.module';
import { ImageRotatorComponent } from './image-rotator.component';

@NgModule({
  declarations: [ImageRotatorComponent],
  imports: [CommonModule, MaterialModule],
  exports: [ImageRotatorComponent]
})
export class ImageRotatorModule {}
