import { NutritionRadioItem } from '@src/app/features/nutrition-management/models/nutrition-item.model';
import { Component, Input, OnInit } from '@angular/core';
import { BaseFormControlComponent } from '@shared/components/form/components/base-form-control/base-form-control.component';
import { RadioTreeChildOption } from '@src/app/features/reports/components/report-filters/components/radio-tree-filter/radio-tree-filter.model';
import { RadioGroupOptions, RadioGroupOptionValues } from 'src/app/shared/models/form.model';
import { UiConfig } from '../form-components.utils';

@Component({
  selector: 'stx-radio-group',
  templateUrl: './radio-group.component.html'
})
export class RadioGroupComponent extends BaseFormControlComponent implements OnInit {
  @Input() label: string;
  @Input() labelSuffix: string | number;
  @Input() options: RadioGroupOptions | RadioTreeChildOption[] | NutritionRadioItem[];
  @Input() defaultOptionValue: RadioGroupOptionValues;
  @Input() layoutDirection: string;
  @Input() addStyling: UiConfig;
  @Input() lastOptionSeparateLabel?: string;
  @Input() radioGroupId: string;
  @Input() disableWarning: boolean;
  @Input() tooltipContent: string;

  ngOnInit(): void {
    super.ngOnInit();
    if (this.defaultOptionValue) {
      this.setControlDefaultValue();
    }
  }

  setControlDefaultValue() {
    this.formGroup.get(this.controlName).setValue(this.defaultOptionValue);
  }
}
