<ng-container *ngIf="records.length">
  <mat-paginator
    [length]="totalResults"
    (page)="changePage($event)"
    [pageSize]="pageSize"
    [pageIndex]="currentPageIndex"
    [pageSizeOptions]="pageSizeOptions"
  >
  </mat-paginator>

  <div class="data-table-container">
    <div class="data-table-wrapper">
      <table [dataSource]="records" class="mat-elevation-z2" mat-table aria-describedby="data-table-container">
        <ng-container [matColumnDef]="columns.treatmentCenter">
          <th mat-header-cell *matHeaderCellDef class="wrap-conent">
            {{ headers.treatmentCenter }}
          </th>
          <td class="wrap-content" mat-cell *matCellDef="let record">
            <stx-simple-report-cell
              [rowInputs]="[record.treatmentCenter, record.country, record.province, record.district]"
            ></stx-simple-report-cell>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="columns.patient">
          <th mat-header-cell *matHeaderCellDef>
            {{ headers.patient }}
          </th>
          <td mat-cell *matCellDef="let record">
            <div class="align-middle flex-row-container">
              <stx-patient-link [showIcon]="false" [patientId]="record.patientId">
                <stx-simple-report-cell [rowInputs]="[record.hospitalRecordId]"></stx-simple-report-cell>
              </stx-patient-link>
              <stx-patient-link [patientHistoryRoute]="true" [patientId]="record.patientId"> </stx-patient-link>
            </div>
            <stx-simple-report-cell
              [rowInputs]="[record.hospitalRecordIdLoc, record.patientName, record.patientLocalName, record.patientId]"
              [inlineInputsToTranslate]="[record.gender]"
              [inlineInputs]="[record.dateOfBirth]"
            ></stx-simple-report-cell>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="columns.speechAssessment">
          <th mat-header-cell *matHeaderCellDef>
            {{ headers.speechAssessment }}
          </th>
          <td mat-cell *matCellDef="let record">
            <stx-treatment-status-indicator
              [addBottomPadding]="true"
              [showLabel]="false"
              [treatmentStatus]="record.status"
            ></stx-treatment-status-indicator>
            <div>
              <a
                [routerLink]="
                  record.assessmentDate ? speechAssessmentBaseUrl + record.businessId : speechTreatmentBaseUrl + record.businessId
                "
                [queryParams]="{ patientId: record.patientId }"
              >
                <!--  Available only in user selects Speech assesment option in filters -->
                <ng-container *ngIf="record.assessmentDate"> {{ record.assessmentDate }} <br /> </ng-container>

                <!--  Available only in user selects Speech treatment option in filters -->
                <ng-container *ngIf="record.reportDate">{{ record.reportDate }} <br /></ng-container>
                <ng-container *ngIf="record.speechTreatmentFrom">{{ record.speechTreatmentFrom }}</ng-container>
                <ng-container *ngIf="record.speechTreatmentFrom && record.speechTreatmentTo">&nbsp;&mdash;&nbsp;</ng-container>
                <ng-container *ngIf="record.speechTreatmentTo">{{ record.speechTreatmentTo }}</ng-container>
              </a>
              <stx-treatment-date class="bold" [direction]="'column'" [submitDate]="record.recordSubmitted"></stx-treatment-date>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="!!columns.speechStage" [matColumnDef]="columns.speechStage">
          <th mat-header-cell *matHeaderCellDef>
            {{ headers.speechStage }}
          </th>
          <td class="wrap-content" mat-cell *matCellDef="let record">
            {{ record.speechStage | translate }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="columns.speechPractitioner">
          <th mat-header-cell *matHeaderCellDef>
            {{ headers.speechPractitioner }}
          </th>
          <td mat-cell *matCellDef="let record">
            <stx-simple-report-cell
              [rowInputs]="[record.speechPractitioner | localName : record.speechPractitionerLoc]"
            ></stx-simple-report-cell>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="columns.recommendations">
          <th mat-header-cell *matHeaderCellDef>
            {{ headers.recommendations }}
          </th>
          <td class="wrap-content" mat-cell *matCellDef="let record">
            <ng-container *ngFor="let reccomendation of record.recommendations; last as isLast">
              {{ reccomendation | translate }}
              <ng-container *ngIf="isReevaluateIn(reccomendation)"
                >{{ record.recommendReevalMonths }} {{ 'speech_assessment.recommend_reevaluate_in_months' | translate }}</ng-container
              >
              <ng-container *ngIf="isLast">
                <ng-container *ngIf="record.recommendOther">
                  <stx-value-separator></stx-value-separator>
                  {{ record.recommendOther }}
                </ng-container>
                <ng-container *ngIf="record.recommendComment">
                  <stx-value-separator></stx-value-separator>
                  {{ record.recommendComment }}
                </ng-container>
              </ng-container>
              <stx-value-separator *ngIf="!isLast"></stx-value-separator>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsList"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsList" class="table-row"></tr>
      </table>
    </div>
  </div>
  <mat-paginator
    [length]="totalResults"
    (page)="changePage($event)"
    [pageSize]="pageSize"
    [pageIndex]="currentPageIndex"
    [pageSizeOptions]="pageSizeOptions"
  >
  </mat-paginator>
</ng-container>

<div *ngIf="reportTableDataService.dataReady && !records.length">
  <stx-empty-table-state></stx-empty-table-state>
</div>
