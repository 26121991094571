import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-expandable-form-panel',
  templateUrl: './expandable-form-panel.component.html',
  styleUrls: ['./expandable-form-panel.component.scss']
})
export class ExpandableFormPanelComponent {
  @Input()
  expanded: boolean = false;

  get icon(): IconDefinition {
    return this.expanded ? faChevronUp : faChevronDown;
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }
}
