<ng-container *ngIf="enabled && !!availableDownloadOptions.length">
  <a class="link_icon">
    <mat-icon [matMenuTriggerFor]="menu" class="csv-icon" tabindex="0"
      >save_alt
      <mat-menu #menu="matMenu">
        <button
          data-testid="button-report-downloader"
          mat-menu-item
          *ngFor="let downloadOption of availableDownloadOptions"
          class="text-uppercase option-button"
          (click)="downloadReport(downloadOption)"
        >
          {{ downloadOption.label | translate }}
        </button>
      </mat-menu>
    </mat-icon>
  </a>
</ng-container>
