<ng-container *ngIf="treatmentCenter">
  <p *ngIf="treatmentCenter.id">
    <a *ngIf="isTcLinkActive; else tcLinkInactive" routerLink="/{{ treatmentCentersRoute }}/{{ treatmentCenter.id }}">
      {{ treatmentCenter.name }}
    </a>
    <ng-template #tcLinkInactive>
      <p>
        {{ treatmentCenter.name }}
      </p>
    </ng-template>
  </p>
  <p *ngIf="!treatmentCenter.id">
    {{ treatmentCenter.name }}
  </p>
  <p *ngIf="treatmentCenter.nameLoc">
    {{ treatmentCenter.nameLoc }}
  </p>
</ng-container>
