import { ReadonlyImageModule } from '@/src/app/shared/components/readonly-image/readonly-image.module';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { TableStructure } from '@shared/components/table/models/table-structure.model';
import { PatientLinkComponentModule } from 'src/app/features/reports/components/patient-link/patient-link.component';
import { ReportTableDataService } from 'src/app/features/reports/components/report-filters/services/report-table-data.service';
import { ReportService } from 'src/app/features/reports/components/report-filters/services/report.service';
import { PhotoReportRecord } from '../photo-report.model';
import { TreatmentModule } from '@shared/components/treatment/treatment.module';
import { TreatmentStatusIndicatorComponentModule } from '@shared/components/treatment-status-indicator/treatment-status-indicator.component';
import { mapToStatusEnum } from '@/src/app/utils/treatment.utils';
import { FormStatus } from '@shared/enums/form-status.enum';
import { BaseReportTableComponent } from '@src/app/features/reports/components/base-report-table/base-report-table.component';
import { NutritionStatsReportModel } from '@src/app/features/reports/features/nutrition-stats-report/nutrition-stats-report.model';
import { ActivatedRoute } from '@angular/router';
import { SimpleReportTableColumn } from '@src/app/features/reports/components/report-filters/models/report-data.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-photo-report-table',
  templateUrl: './photo-report-table.component.html',
  styleUrls: ['./photo-report-table.component.scss']
})
export class PhotoReportTableComponent extends BaseReportTableComponent<PhotoReportRecord> implements OnInit, OnDestroy {
  displayedColumns: string[] = [];
  columns: SimpleReportTableColumn[] = [];

  constructor(
    public reportTableDataService: ReportTableDataService,
    public reportService: ReportService,
    public changeDetectorRef: ChangeDetectorRef,
    public route: ActivatedRoute
  ) {
    super('PHOTO_REPORT', changeDetectorRef, reportTableDataService, reportService, route);
  }

  ngOnInit(): void {
    super.onInit();

    this.subSink.sink = this.reportTableDataService.tableStructure$.subscribe((tableStructure: TableStructure<PhotoReportRecord[]>) => {
      this.columns = tableStructure?.labels || [];
      this.displayedColumns = this.columns.map(a => a.name);
    });
  }

  ngOnDestroy() {
    this.reportTableDataService.flush();
    super.onDestroy();
  }

  public mapToStatusEnum(status: number | null): FormStatus | null {
    return mapToStatusEnum(status);
  }
}

@NgModule({
  imports: [
    CommonModule,
    EmptyTableStateComponentModule,
    FlexModule,
    MatPaginatorModule,
    PatientLinkComponentModule,
    ReadonlyImageModule,
    TreatmentModule,
    TreatmentStatusIndicatorComponentModule
  ],
  declarations: [PhotoReportTableComponent],
  exports: [PhotoReportTableComponent]
})
export class PhotoReportTableComponentModule {}
