import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { FormType } from '@shared/enums/form-type.enum';
import { FormGuardService } from '@shared/services/form-guard.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { BaseNutritionManagement } from '@src/app/features/nutrition-management/models/nutrition-management.model';
import { NutritionDictionariesService } from '@src/app/features/nutrition-management/services/nutrition-dictionaries-service';
import { NutritionAssessmentService } from '@src/app/features/nutrition-management/services/nutrition-assessment.service';
import { BaseNutritionFormComponent } from '@src/app/features/nutrition-management/shared/base-nutrition-form.component';
import { NutritionStage } from '@src/app/features/nutrition-management/utils/nutrition-management.utils';
import { PatientService } from '@src/app/features/patient/patient.service';
import { assessment, editRoute, nutritionRoute } from '@utils/routing.utils';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Component({
  selector: 'stx-nutrition-assessment',
  templateUrl: './nutrition-assessment.component.html',
  styleUrls: ['./nutrition-assessment.component.scss']
})
export class NutritionAssessmentComponent extends BaseNutritionFormComponent<BaseNutritionManagement> implements OnInit, AfterViewInit {
  constructor(
    nutritionDictionariesService: NutritionDictionariesService,
    formGuardService: FormGuardService,
    formBuilder: UntypedFormBuilder,
    cd: ChangeDetectorRef,
    spinnerService: SpinnerService,
    globalErrorHandlerService: GlobalErrorHandlerService,
    patientService: PatientService,
    nutritionManagementAssessmentService: NutritionAssessmentService,
    router: Router,
    activatedRoute: ActivatedRoute,
    snackBarService: SnackBarService,
    elementRef: ElementRef,
    zone: NgZone,
    wsHelper: WsHelperService
  ) {
    super(
      nutritionManagementAssessmentService,
      nutritionDictionariesService,
      NutritionStage.ASSESSMENT,
      formBuilder,
      formGuardService,
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      cd,
      globalErrorHandlerService,
      wsHelper,
      patientService
    );
    this.formType = FormType.NUTRITION_ASSESSMENT;
    this.treatmentType = TreatmentType.NUTRITION_ASSESSMENT;
  }

  ngOnInit(): void {
    if (!this.isReadonlyView) {
      this.configureForm(this.initializeForm(), {
        controlNamesForFullValidation: ['treatmentCenterId'],
        nestedPartialValidationConfigs: {
          nutritionInfo: {
            controlNamesForFullValidation: ['evaluationDate']
          }
        }
      });
      this.initializeForm();
      this.getPatientTreatmentCenters();
    }
  }

  ngAfterViewInit() {
    this.fillInEditMode();
  }

  protected getEditRoute(treatmentId: TreatmentId): string {
    return `${nutritionRoute}/${assessment}/${editRoute}/${this.nutritionManagement.id}`;
  }

  protected getViewRoute(treatmentId: TreatmentId): string {
    return `${nutritionRoute}/${assessment}/${treatmentId}`;
  }
}
