import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PermissionEnum } from 'src/app/core/permissions/permission.enum';
import { FormGuardService } from '@shared/services/form-guard.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

export const PermissionGuard: CanActivateFn | CanMatchFn = (route, segments) => {
  const router: Router = inject(Router);
  const currentUserService = inject(CurrentUserService);
  const formGuardService = inject(FormGuardService);

  const checkPermission: (route: ActivatedRouteSnapshot) => boolean | UrlTree = route => {
    let userPermissions: string[];
    const user = currentUserService.getCurrentUser();
    let canActivate = false;
    if (user) {
      userPermissions = user.permissions.map(x => x.name);
      canActivate = userPermissions.includes(route.data.permission);
    }
    if (!canActivate) {
      const redirectTree = fallbackToAllowedRoute(route, userPermissions);
      if (redirectTree) {
        return redirectTree;
      }
    }
    return canActivate;
  };

  const fallbackToAllowedRoute: (route: ActivatedRouteSnapshot, userPermissions: string[]) => void | UrlTree = (route, userPermissions) => {
    if (route.data.permission === PermissionEnum.VIEW_DASHBOARD_MAIN) {
      dashboardRedirects(userPermissions);
    } else if (formGuardService.canFallbackToReadonly(route, userPermissions)) {
      return formGuardService.getReadonlyPathFromEditPath(route);
    } else {
      router.navigateByUrl('/');
    }
  };

  const dashboardRedirects: (userPermissions: string[]) => void = userPermissions => {
    if (userPermissions.includes(PermissionEnum.VIEW_DASHBOARD_REDIRECT_USERS)) {
      router.navigateByUrl('/users');
    }
    if (userPermissions.includes(PermissionEnum.VIEW_DASHBOARD_REDIRECT_PATIENTS)) {
      router.navigateByUrl('/patients');
    }
  };

  return checkPermission(route);
};
