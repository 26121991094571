import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'stx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {}

@NgModule({
  exports: [FooterComponent],
  imports: [FlexLayoutModule, CommonModule, MatIconModule, GeneralCommonsModule, TranslateModule],
  declarations: [FooterComponent]
})
export class FooterComponentModule {}
