export interface StxConfiguration {
  redirectTo: string;
  currentArea: InstanceArea;
  instanceUrls: { [key in InstanceArea]: string };
}

export enum InstanceArea {
  CHINA = 'CHINA',
  GLOBAL = 'GLOBAL'
}
