import { CompactPatientDataComponent } from '@/src/app/features/reports/components/compact-patient-data/compact-patient-data.component';
import { PatientLinkComponentModule } from '@/src/app/features/reports/components/patient-link/patient-link.component';
import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { MaterialModule } from '@/src/app/shared/modules/material/material.module';
import { ReadonlyCommonsModule } from '@/src/app/shared/modules/readonly-commons/readonly-commons.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { TreatmentStatusIndicatorComponentModule } from '@shared/components/treatment-status-indicator/treatment-status-indicator.component';

@NgModule({
  declarations: [CompactPatientDataComponent],
  imports: [
    CommonModule,
    PatientLinkComponentModule,
    MaterialModule,
    ReadonlyCommonsModule,
    FlexLayoutModule,
    TreatmentStatusIndicatorComponentModule,
    TranslateModule,
    GeneralCommonsModule
  ],
  exports: [CompactPatientDataComponent]
})
export class CompactPatientDataModule {}
