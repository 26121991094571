<stx-readonly-image
  [imageClass]="'image-thumbnail'"
  [highResUrl]="imageUrl"
  [lowResUrl]="imageUrl"
  [rawUrl]="imageUrl"
  [imageWidth]="280"
  [heightAuto]="true"
  [fullWidthImage]="true"
  [altText]="imageAlt"
  [id]="thumbnailId"
  [mediaId]="mediaId"
  [mediaAnonymized]="mediaAnonymized"
>
  <label [for]="thumbnailId" class="m-r-15">
    <ng-content></ng-content>
  </label>
</stx-readonly-image>
