<ng-container *ngIf="treatment">
  <stx-readonly-view-columns>
    <ng-container *stxReadonlyViewColumn>
      <stx-simple-readonly-field label="borescope.scan_date" [value]="treatment.scanDate"></stx-simple-readonly-field>
      <stx-simple-readonly-field label="status" [value]="treatmentStatusLabel | translate"> </stx-simple-readonly-field>
    </ng-container>
    <ng-container *stxReadonlyViewColumn>
      <stx-simple-readonly-field label="age" [value]="treatment.ageMonth | ageYearMonth"></stx-simple-readonly-field>
    </ng-container>
    <ng-container *stxReadonlyViewColumn>
      <stx-simple-readonly-field label="borescope.scan_notes" [value]="treatment.notes"></stx-simple-readonly-field>
    </ng-container>
  </stx-readonly-view-columns>
  <stx-readonly-media-group [mediaSets]="media"></stx-readonly-media-group>
</ng-container>
