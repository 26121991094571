import { SurgicalIntervention } from '@/src/app/features/surgical/models/surgical-intervention.model';
import { SurgicalInterventionType } from '@/src/app/features/surgical/surgical.enum';
import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { MediaSet } from '@/src/app/shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';
import { BaseReadonlyViewUtils } from '@/src/app/shared/modules/readonly-commons/utils/base-readonly-view.utils';

export function mapToSurgicalTypeEnum(value: number): SurgicalInterventionType {
  return SurgicalInterventionType[SurgicalInterventionType[value] as string];
}

export const repairTypeMap = new Map<string, string>([
  ['primary_lip_nose_unilateral', 'primaryLipNoseUnilateral'],
  ['primary_lip_nose_bilateral', 'primaryLipNoseBilateral'],
  ['lip_nose_revision', 'lipNoseRevision'],
  ['primary_cleft_palate', 'primaryCleftPalate'],
  ['fistula_repair', 'fistulaRepair'],
  ['secondary_cleft_palate', 'secondaryCleftPalate'],
  ['velopharyngeal_disfunction', 'velopharyngealDisfunction'],
  ['alveolar_bone_graft', 'alveolarBoneGraft'],
  ['other', 'other']
]);

export function getSurgicalPhotos(surgery: SurgicalIntervention): MediaSet[] {
  return [
    {
      mediaWithLabels: [
        BaseReadonlyViewUtils.findMediaWithLabels(ParentOrderName.OPERATION_PRE_FRONTAL, ['pre_surgery', 'order_name.frontal'], surgery),
        BaseReadonlyViewUtils.findMediaWithLabels(
          ParentOrderName.OPERATION_PRE_NASAL_BASE,
          ['pre_surgery', 'ortho.photo.worms_inferior_nasal_base_pre'],
          surgery
        ),
        BaseReadonlyViewUtils.findMediaWithLabels(
          ParentOrderName.OPERATION_PRE_CLEFT_SIDE,
          ['pre_surgery', 'ortho.photo.cleft_side_lateral_pre'],
          surgery
        ),
        BaseReadonlyViewUtils.findMediaWithLabels(
          ParentOrderName.OPERATION_PRE_INTRA_ORAL,
          ['pre_surgery', 'ortho.photo.intraoral'],
          surgery
        ),
        BaseReadonlyViewUtils.findMediaWithLabels(
          ParentOrderName.OPERATION_FRONTAL,
          ['report.filters.photo_types.type.frontal_smiling'],
          surgery
        )
      ]
    },
    {
      mediaWithLabels: [
        BaseReadonlyViewUtils.findMediaWithLabels(ParentOrderName.OPERATION_POST_FRONTAL, ['post_surgery', 'order_name.frontal'], surgery),
        BaseReadonlyViewUtils.findMediaWithLabels(
          ParentOrderName.OPERATION_POST_NASAL_BASE,
          ['post_surgery', 'ortho.photo.worms_inferior_nasal_base_post'],
          surgery
        ),
        BaseReadonlyViewUtils.findMediaWithLabels(
          ParentOrderName.OPERATION_POST_CLEFT_SIDE,
          ['post_surgery', 'ortho.photo.cleft_side_lateral_post'],
          surgery
        ),
        BaseReadonlyViewUtils.findMediaWithLabels(
          ParentOrderName.OPERATION_POST_INTRA_ORAL,
          ['post_surgery', 'ortho.photo.intraoral'],
          surgery
        ),
        BaseReadonlyViewUtils.findMediaWithLabels(ParentOrderName.OPERATION_FOLLOW_UP, ['order_name.follow_up_visit'], surgery)
      ]
    }
  ];
}
