<stx-generic-page>
  <stx-report-header>
    {{ 'reports.orthodontic_treatments_compact' | translate }}
  </stx-report-header>
  <stx-report-filters [reportConfig]="reportConfig" (applyFilters)="applyFilters($event)"></stx-report-filters>
  <stx-orthodontic-treatment-compact-report-table
    *ngIf="reportService.initialized$ | async"
    [reportConfig]="reportService.model"
    [deIdentified]="deIdentified"
    [showQaOnly]="showQaOnlySection"
    [showPagination]="showPagination"
  ></stx-orthodontic-treatment-compact-report-table>
</stx-generic-page>
