import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MediaContentType } from '@shared/enums/media.enum';
import { MediaResourceWithLabels } from '@shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'stx-readonly-media-field',
  templateUrl: './readonly-media-field.component.html'
})
export class ReadonlyMediaFieldComponent {
  static readonly mediaLabelSeparator = ' - ';
  @Input()
  media: MediaResourceWithLabels | null;
  @Input() shouldDisablePhotoControls: boolean;

  readonly MediaContentType = MediaContentType;

  constructor(private readonly translateService: TranslateService) {}

  get label(): Observable<string> | null {
    return this.translateAndJoin(this.media?.labelKeys);
  }

  private translateAndJoin(keys?: string[]): Observable<string> | null {
    if ((keys?.length || 0) < 1) {
      return null;
    }
    return combineLatest(keys.map(key => this.translateService.get(key))).pipe(
      map(array => array.join(ReadonlyMediaFieldComponent.mediaLabelSeparator))
    );
  }

  get mediaType(): MediaContentType {
    if ((this.media?.urlList?.length || 0) < 1) {
      return MediaContentType.OTHER;
    } else {
      return this.media?.urlList[0]?.mediaType;
    }
  }
}
