import { NgModule } from '@angular/core';
import { ErrorSnackBarComponent } from './error-snack-bar/error-snack-bar.component';
import { MaterialModule } from '../../modules/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ErrorSnackBarComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, TranslateModule.forChild()],
  exports: [ErrorSnackBarComponent]
})
export class ErrorHandlingModule {}
