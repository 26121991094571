import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OrthognathicModule } from 'src/app/features/orthognathic/orthognathic.module';
import { PatientModule } from 'src/app/features/patient/patient.module';
import { SpeechModule } from 'src/app/features/speech/speech.module';
import { SurgicalModule } from 'src/app/features/surgical/surgical.module';
import { OrthoModule } from '../../../features/ortho/ortho.module';
import { MaterialModule } from '../../modules/material/material.module';
import { PatientDocumentUploadModule } from '../patient-document-upload/patient-document-upload.module';
import { PatientFormsContainerPageComponent } from './patient-forms-container-page.component';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { PatientDetailsBlockComponentModule } from '../patient-details-block/patient-details-block.component';
import { NutritionManagementModule } from '@src/app/features/nutrition-management/nutrition-management.module';
import { NutritionModule } from '@src/app/features/nutrition/nutrition.module';

@NgModule({
  declarations: [PatientFormsContainerPageComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    GeneralCommonsModule,
    MaterialModule,
    OrthoModule,
    OrthognathicModule,
    PatientDocumentUploadModule,
    PatientModule,
    ReactiveFormsModule,
    SpeechModule,
    SurgicalModule,
    PatientDetailsBlockComponentModule,
    TranslateModule,
    NutritionManagementModule,
    NutritionModule
  ]
})
export class PatientFormsContainerModule {}
