import { expandAndRevealAnimationMetadata } from '@/src/app/utils/animation.utils';
import { trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-data-block',
  templateUrl: './data-block.component.html',
  animations: [trigger('headerExpansion', expandAndRevealAnimationMetadata), trigger('bodyExpansion', expandAndRevealAnimationMetadata)]
})
export class DataBlockComponent {
  @Input() headerExpanded = true;
  @Input() bodyExpanded = false;
  @Input() expandableHeader = false;
  @Input() expandableBody = false;
  @Input() header: string | undefined;
  @Input() headerSubtitle: string | undefined;
  @Input() light = false;
}
