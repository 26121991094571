import { Injectable } from '@angular/core';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { DictionaryTranslation } from '@src/app/features/patient/models/patient.model';
import { PatientService } from '@src/app/features/patient/patient.service';
import { yesNoAnswers, yesNoDontKnow } from '@utils/form.utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class PatientDictionaries {
  readonly yesNo = yesNoAnswers;
  readonly yesNoDontKnow: DictionaryTranslation[] = yesNoDontKnow;
  readonly abnormalities: DictionaryTranslation[] = [
    { name: 'heart', label: 'patient.heart', value: false },
    { name: 'ears', label: 'patient.ears', value: false },
    { name: 'tongue', label: 'patient.tongue', value: false },
    { name: 'growth', label: 'patient.growth', value: false },
    { name: 'urinary', label: 'patient.urinary', value: false },
    { name: 'limbs', label: 'patient.limbs', value: false },
    { name: 'skull', label: 'patient.skull', value: false },
    { name: 'mental', label: 'patient.mental', value: false },
    { name: 'eyes', label: 'patient.eyes', value: false },
    { name: 'fingersToes', label: 'patient.fingersToes', value: false },
    { name: 'mandible', label: 'patient.mandible', value: false },
    null,
    { name: 'nose', label: 'patient.nose', value: false },
    { name: 'skin', label: 'patient.skin', value: false },
    { name: 'speech', label: 'patient.speech', value: false }
  ];
  readonly genders: DictionaryTranslation[];
  readonly guardianRel: DictionaryTranslation[];
  readonly knowAboutSt: DictionaryTranslation[];
  readonly cleftLipOptions: DictionaryTranslation[];
  readonly cleftPalateOptions: DictionaryTranslation[];
  readonly races: DictionaryTranslation[];
  readonly previousSurgeryOptions: DictionaryTranslation[] = [];

  constructor(dictionary: Map<string, DictionaryTranslation[]>) {
    const get = (name: string) => dictionary.get(name) || [];
    this.genders = get('gender');
    this.races = get('race');
    this.cleftLipOptions = get('leftCleftLip');
    this.cleftPalateOptions = get('leftHardCleftPalate');
    this.guardianRel = get('guardianRelationship');
    this.knowAboutSt = get('knowAboutST');
    this.previousSurgeryOptions = get('previousSurgery');
  }
}

@Injectable()
export class PatientDictionariesService {
  constructor(private readonly wsHelper: WsHelperService, private readonly patientService: PatientService) {}

  private _dictionaries$: Observable<PatientDictionaries>;

  get dictionaries$() {
    if (!this._dictionaries$) {
      this._dictionaries$ = this.wsHelper
        .callWithSpinner(this.patientService.getStaticDictionaries())
        .pipe(map(dictionaries => new PatientDictionaries(dictionaries)));
    }
    return this._dictionaries$;
  }
}
