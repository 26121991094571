import { AuthService } from '@/src/app/features/authentication/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/app/shared/components/spinner/service/spinner.service';
import { LoginTokenCredentials } from '@src/app/features/authentication/models/login-token-credentaials.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'stx-token',
  templateUrl: './token.component.html'
})
export class TokenComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.login(this.route.snapshot.params.profileId, this.route.snapshot.params.token);
  }

  login(profileId: number, token: string) {
    const credentials: LoginTokenCredentials = {
      profileId,
      token
    };
    return this.authService.loginWithToken(credentials, this.onLoginError.bind(this)).subscribe(() => {});
  }

  onLoginError(): void {
    this.spinnerService.removeTask();
    this.router.navigateByUrl('/login');
  }
}
