<stx-modal>
  <ng-container *ngIf="!isEmailSet; else successMessage">
    <div class="text-important m-b-25">
      {{ 'settings.reset_password_instructions' | translate }}
    </div>
    <form [formGroup]="emailForm">
      <div class="flex-column-container">
        <label for="emailReset" class="m-b-5">{{ 'email' | translate }}</label>
        <mat-form-field appearance="outline">
          <input
            data-testid="input-reset-password-email"
            type="email"
            matInput
            formControlName="emailFormControl"
            class="input-field"
            id="emailReset"
          />
          <mat-error class="stx-mat-error-align">{{ 'error.email' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <stx-action-buttons
        [showSecondary]="false"
        [asyncPrimaryAction]="sendResetLink"
        [primaryDisabled]="!emailForm.valid"
        [primaryText]="'send' | translate"
      ></stx-action-buttons>
    </form>
  </ng-container>
  <ng-template #successMessage>
    <div class="flex-row-container">
      <mat-icon class="success-icon"> check_circle </mat-icon>
      <div>{{ 'settings.reset_password_message' | translate }}</div>
    </div>
  </ng-template>
</stx-modal>
