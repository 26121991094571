<ng-container *ngIf="elements.length > 0">
  <p *ngIf="headerKey">{{ headerKey | translate }}:</p>
  <ul>
    <li *ngFor="let element of elements">
      <ng-container *ngIf="element.labelKey"> {{ element.labelKey | translate }} </ng-container>
      <ng-container *ngIf="element.labelKey && element.value"> &mdash; </ng-container>
      <ng-container *ngIf="element.value"> {{ element.value }} </ng-container>
    </li>
  </ul>
</ng-container>
