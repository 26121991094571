import { Component, OnInit } from '@angular/core';
import { BaseNutritionSectionComponent } from '@src/app/features/nutrition-management/shared/base-nutrition-section.component';
import {
  outpatientPatientCareFields,
  referralPatientCareFields,
  typesOfPatientCare
} from '@src/app/features/nutrition-management/utils/nutrition-management.utils';
import { PatientCareType } from '@src/app/features/nutrition-management/enums/patient-care-type.enum';
import { NutritionCheckboxItem, NutritionRadioItem } from '@src/app/features/nutrition-management/models/nutrition-item.model';

@Component({
  selector: 'stx-nutrition-patient-care-type',
  templateUrl: './nutrition-patient-care-type.component.html'
})
export class NutritionPatientCareTypeComponent extends BaseNutritionSectionComponent implements OnInit {
  readonly typesOfPatientCare = typesOfPatientCare;
  readonly patientCareTypeControlName = 'patientCareType';

  get patientCareTypeControl() {
    return this.formGroup.get(this.patientCareTypeControlName);
  }

  ngOnInit(): void {
    this.subSink.sink = this.patientCareTypeControl.valueChanges.subscribe(value => {
      this.manageSectionsAvailability(value);
      this.markSubsectionAsTouched(value);
    });
  }

  private manageSectionsAvailability(currentCareType: PatientCareType): void {
    switch (currentCareType) {
      case PatientCareType.INPATIENT:
        this.clearSubsection(PatientCareType.REFERRAL);
        this.clearSubsection(PatientCareType.OUTPATIENT);
        break;
      case PatientCareType.OUTPATIENT:
        this.clearSubsection(PatientCareType.REFERRAL);
        break;
      case PatientCareType.REFERRAL:
        this.clearSubsection(PatientCareType.OUTPATIENT);
        break;
    }
  }

  private markSubsectionAsTouched(currentCareType: PatientCareType): void {
    if (currentCareType !== PatientCareType.INPATIENT) {
      this.formGroup.updateValueAndValidity();
    }
  }

  getSubsectionErrorNames(section: PatientCareType): string {
    if (section === PatientCareType.INPATIENT) {
      return '';
    } else if (section === PatientCareType.OUTPATIENT) {
      return 'outpatientPatientCareFields';
    } else if (section === PatientCareType.REFERRAL) {
      return 'referralPatientCareFields';
    }
  }

  private clearSubsection(section: PatientCareType): void {
    if (section == PatientCareType.OUTPATIENT) {
      outpatientPatientCareFields.forEach(controlName => {
        this.formGroup.get(controlName).reset();
      });
    } else if (section === PatientCareType.REFERRAL) {
      referralPatientCareFields.forEach(controlName => {
        this.formGroup.get(controlName).reset();
      });
    }
  }

  filterOptions(checkboxes: NutritionCheckboxItem[]): NutritionCheckboxItem[] {
    return checkboxes.filter(checkbox => checkbox.showFor.includes(this.nutritionFormType));
  }

  setParentWhenChildSelected(option: NutritionRadioItem): void {
    this.patientCareTypeControl.setValue(option.value);
  }

  getDeselectTriggerName(careOptionValue: PatientCareType): string {
    if (careOptionValue === PatientCareType.OUTPATIENT || careOptionValue === PatientCareType.REFERRAL)
      return careOptionValue.toLowerCase() + 'FollowupNone';
  }
}
