import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}
  transform(value: any): string {
    return this.translateService.instant(value ? 'yes' : 'no');
  }
}

@NgModule({
  providers: [],
  declarations: [YesNoPipe],
  exports: [YesNoPipe]
})
export class YesNoPipeModule {}
