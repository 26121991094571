import { BaseOrthoFormComponent } from '@/src/app/features/ortho/shared/base-ortho-form.component';
import { OrthoStage } from '@/src/app/features/ortho/utils/ortho-stage';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { TreatmentType } from '@shared/components/treatment/treatment.enum';
import { FormType } from '@shared/enums/form-type.enum';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { TreatmentCenterDictionary } from '@shared/models/treatment-center.model';
import { FormGuardService } from '@shared/services/form-guard.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { requiredIfValidator, selectionInvalidIf, StxValidators } from '@shared/validation/validators';
import { OrthoGeneralMidAssessmentInfoComponent } from '@src/app/features/ortho/components/shared/ortho-general-mid-assessment-info/ortho-general-mid-assessment-info.component';
import { OrthoSharedGeneralInfoComponent } from '@src/app/features/ortho/components/shared/ortho-shared-general-info/ortho-shared-general-info.component';
import { MidAssessment } from '@src/app/features/ortho/models/mid-assessment.model';
import { PermanentDentitionMidAssessmentService } from '@src/app/features/ortho/services/permanent-dentition-mid-assessment.service';
import { dentitionMidAssessmentParentOrderNames, inProgressImagesConfigs } from '@src/app/features/ortho/utils/dentition.utils';
import { OrthoFormType } from '@src/app/features/ortho/utils/ortho-form-type';
import { permanentDentitionMidApiRoute } from '@src/app/features/ortho/utils/ortho-routes.consts';
import { OrthoFormUtils } from '@src/app/features/ortho/utils/ortho.utils';
import { PatientService } from '@src/app/features/patient/patient.service';
import { FormMediaUtils } from '@utils/form-media.utils';
import { FormMode } from '@utils/form.utils';
import { orthoRoute } from '@utils/routing.utils';
import { Observable } from 'rxjs';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';
import { PatientId } from '@src/app/features/patient/models/patient.model';

@Component({
  selector: 'stx-permanent-dentition-mid-assessment',
  templateUrl: './permanent-dentition-mid-assessment.component.html'
})
export class PermanentDentitionMidAssessmentComponent extends BaseOrthoFormComponent<MidAssessment> implements OnInit {
  readonly OrthoFormType = OrthoFormType;
  readonly inProgressImagesConfigs = inProgressImagesConfigs;

  @ViewChild('#generalMidInfo') generalMidInfo: OrthoGeneralMidAssessmentInfoComponent;
  tcList: TreatmentCenterDictionary[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly orthoFormService: PermanentDentitionMidAssessmentService,
    private readonly formGuardService: FormGuardService,
    spinnerService: SpinnerService,
    changeDetector: ChangeDetectorRef,
    globalErrorHandlerService: GlobalErrorHandlerService,
    router: Router,
    activatedRoute: ActivatedRoute,
    snackBarService: SnackBarService,
    elementRef: ElementRef,
    zone: NgZone,
    wsHelper: WsHelperService,
    patientService: PatientService
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      changeDetector,
      globalErrorHandlerService,
      wsHelper,
      patientService,
      orthoFormService
    );
    this.formType = FormType.ORTHO_PD_MID_ASSESSMENT;
    this.treatmentType = TreatmentType.ORTHO_PD_MID_ASSESSMENT;
  }

  protected callDelete(id: number): Observable<void> {
    return this.orthoFormService.delete(id);
  }

  protected callSave(data: MidAssessment): Observable<MidAssessment> {
    return this.orthoFormService.save(data);
  }

  protected callSubmit(data: MidAssessment): Observable<MidAssessment> {
    return this.orthoFormService.submit(data);
  }

  protected callUnlock(id: number): Observable<void> {
    return this.orthoFormService.unlock(id);
  }

  protected getEditRoute(treatmentId: number): string {
    return `${orthoRoute}/${permanentDentitionMidApiRoute}/edit/${treatmentId}`;
  }

  protected getPatientId(): PatientId {
    return this.patient.id;
  }

  protected getTreatmentDataToSave(): MidAssessment {
    return {
      ...this.treatmentFormGroup.value,
      id: this.orthoFormModel ? this.orthoFormModel.id : null,
      patientId: this.patient.id,
      newFiles: FormMediaUtils.extractNewMediaFromFormGroup([this.treatmentFormGroup], dentitionMidAssessmentParentOrderNames)
    };
  }

  protected getTreatmentId(): TreatmentId {
    return this.orthoFormModel.id;
  }

  protected getViewRoute(treatmentId: number): string {
    return `${orthoRoute}/${permanentDentitionMidApiRoute}/${treatmentId}`;
  }

  protected setTreatmentData(data: MidAssessment): void {
    this.orthoFormModel = data;
    this.treatmentFormGroup.patchValue(this.orthoFormModel);
    this.treatmentFormGroup.patchValue(FormMediaUtils.getMediaForFormGroup(this.orthoFormModel, dentitionMidAssessmentParentOrderNames));
    if (this.formMode !== FormMode.READONLY) {
      super.callFetchAdditionalDataForSetUp();
    }
  }

  ngOnInit(): void {
    this.configureForm(this.createTreatmentFormGroup(), {
      controlNamesForFullValidation: [
        OrthoSharedGeneralInfoComponent.getOrthoCareProvidedDate(OrthoStage.MID_TREATMENT_ASSESSMENT),
        OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName
      ]
    });
    if (!this.isReadonlyView) {
      this.getPatientTreatmentCenters();
    }
    this.fillInEditMode();
  }

  private createTreatmentFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      evaluationDate: [null, StxValidators.required],
      treatmentCenterId: [null, StxValidators.required],
      practitionerId: [
        null,
        [
          StxValidators.required,
          selectionInvalidIf(
            () => !!this.generalMidInfo?.sharedGeneralInfo && !!this.generalMidInfo.sharedGeneralInfo.isSelectedPractitionerIdInvalid
          )
        ]
      ],
      complication: [null, StxValidators.required],
      complicationMore: [null, requiredIfValidator(() => OrthoFormUtils.complicationsOccurredSelected(this.treatmentFormGroup))],
      oralHealth: [null, StxValidators.required],
      oralHealthMore: [],
      [ParentOrderName.ORTHO_IN_PROGRESS_1]: [],
      [ParentOrderName.ORTHO_IN_PROGRESS_2]: [],
      [ParentOrderName.ORTHO_IN_PROGRESS_3]: [],
      [ParentOrderName.ORTHO_IN_PROGRESS_4]: [],
      [ParentOrderName.ORTHO_IN_PROGRESS_5]: [],
      [ParentOrderName.ORTHO_IN_PROGRESS_6]: [],
      [ParentOrderName.ORTHO_IN_PROGRESS_7]: [],
      [ParentOrderName.ORTHO_IN_PROGRESS_8]: [],
      [ParentOrderName.ORTHO_IN_PROGRESS_9]: []
    });
  }
  private fillInEditMode() {
    if (this.formMode === FormMode.NEW) {
      super.callFetchAdditionalDataForSetUp();
      return;
    }

    if (this.orthoFormModel) {
      this.setTreatmentData(this.orthoFormModel);
      return;
    }

    this.subSink.sink = this.activatedRoute.params.subscribe(params => {
      this.wsHelper
        .callWithSpinner(this.orthoFormService.get(params.id), { redirectOn404StatusCode: true })
        .subscribe(permanentDentitionMidTreatmentModel => {
          this.formGuardService.redirectTreatmentFormToCorrectModeIfRequired(
            permanentDentitionMidTreatmentModel,
            this.formMode,
            this.activatedRoute.snapshot
          );
          this.setTreatmentData(permanentDentitionMidTreatmentModel);
        });
    });
  }
}
