import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RangeSliderComponentModule } from 'src/app/shared/components/form/components/range-slider/range-slider.component';
import { TranslateModule } from '@ngx-translate/core';
import { AgeChangePayload, AgeFilterModel, ReportFilterChange, ReportFiltersParams } from '../../models/report-filters.model';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MatRadioModule } from '@angular/material/radio';
import { FormModule } from '@shared/components/form/form.module';
import { FlexModule } from '@angular/flex-layout';
import { CheckboxGroupFilterComponentModule } from '@src/app/features/reports/components/report-filters/components/checkbox-group-filter/checkbox-group-filter.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-age-filter',
  templateUrl: './age-filter.component.html',
  styleUrls: ['./age-filter.component.scss']
})
export class AgeFilterComponent extends BaseComponent implements OnInit {
  @Input() formGroupReference: UntypedFormGroup;
  @Input() controlName: string;
  @Input() isNutritionStatsReport: boolean;
  @Output() filterChange = new EventEmitter<ReportFilterChange<AgeChangePayload>>();

  formControl: AbstractControl;
  labelId = 'report-age-filter';
  maxSliderValue = 100;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.formControl = this.formGroupReference.get(this.controlName);
    this.watchFilterChanges();
    this.watchQueryParamsChange();
  }

  private setValueFromParams(ageFrom: number, ageTo: number) {
    this.resetValue();
    this.formControl.setValue({ from: ageFrom, to: ageTo });
  }

  private emitFilterModel(ageRange: AgeFilterModel): void {
    if (ageRange) {
      this.filterChange.emit({
        filterName: 'ages',
        payload: {
          ageFrom: [ageRange.from],
          ageTo: [ageRange.to]
        }
      });
    }
  }

  private watchFilterChanges() {
    this.subSink.sink = this.formControl.valueChanges.subscribe(this.emitFilterModel.bind(this));
  }

  private resetValue() {
    this.formControl.reset();
  }

  private watchQueryParamsChange() {
    this.subSink.sink = this.route.queryParams
      .pipe(filter((params: ReportFiltersParams) => !!params.ageFrom || !!params.ageTo))
      .subscribe((params: ReportFiltersParams) => {
        if (params.ageFrom && params.ageTo) {
          this.setValueFromParams(params.ageFrom, params.ageTo);
        } else if (params.ageFrom && !params.ageTo) {
          this.setValueFromParams(params.ageFrom, this.maxSliderValue);
        } else if (!params.ageFrom && params.ageTo) {
          this.setValueFromParams(0, params.ageTo);
        }
      });
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RangeSliderComponentModule,
    TranslateModule,
    MatRadioModule,
    FormModule,
    FlexModule,
    CheckboxGroupFilterComponentModule
  ],
  declarations: [AgeFilterComponent],
  exports: [AgeFilterComponent]
})
export class AgeFilterComponentModule {}
