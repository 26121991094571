import { LoggerService } from '@/src/app/shared/services/logger.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private taskCountSubject = new BehaviorSubject<number>(0);
  private taskCounter = 0;
  constructor(private readonly loggerService: LoggerService) {}

  addTask(): void {
    this.taskCounter += 1;
    this.taskCountSubject.next(this.taskCounter);
  }

  removeTask(): void {
    --this.taskCounter;
    if (this.taskCounter < 0) {
      this.loggerService.error('SpinnerService task counter is below 0', new Error().stack);
      this.taskCounter = 0;
    }
    this.taskCountSubject.next(this.taskCounter);
  }

  clearTasks(): void {
    this.taskCounter = 0;
    this.taskCountSubject.next(this.taskCounter);
  }

  get activeTasks(): Observable<boolean> {
    return this.taskCountSubject.asObservable().pipe(map(count => count > 0));
  }
}
