import { Component, Input } from '@angular/core';
import { NutritionRadioItem } from '@src/app/features/nutrition-management/models/nutrition-item.model';
import { BaseNutritionSectionComponent } from '@src/app/features/nutrition-management/shared/base-nutrition-section.component';

@Component({
  selector: 'stx-nutrition-birthweight',
  templateUrl: './nutrition-birthweight.component.html'
})
export class NutritionBirthweightComponent extends BaseNutritionSectionComponent {
  @Input() nutritionBirthweightOptions: NutritionRadioItem[];
}
