import { ReleaseInfoModule } from '@/src/app/features/dashboard/components/release-info/release-info.module';
import { PartnerSurveysModule } from '@/src/app/features/dashboard/partner-surveys/partner-survey.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerModule } from '@shared/components/spinner/spinner.module';
import { DetailsCommonsModule } from '@shared/modules/details-commons/details-commons.module';
import { StatisticsModule } from '@shared/modules/statistics/statistics.module';
import { PermissionModule } from 'src/app/core/permissions/permission.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { DataBlockModule } from '@shared/components/data-block/data-block.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { StaticFilesModule } from '@shared/modules/static-files/static-files.module';
import { EducationalResourcesModule } from '../educational-resources/educational-resources.module';
import { GenericResourceListModule } from '../educational-resources/shared/generic-resource-list.module';
import { CurrentMonthBlockComponent } from './components/current-month-block/current-month-block.component';
import { DashboardAnnouncementsPanelComponent } from './components/dashboard-announcements-panel/dashboard-announcements-panel.component';
import { DashboardGradeItemComponent } from './components/dashboard-grade-item/dashboard-grade-item.component';
import { DashboardPageComponent } from './components/dashboard-page/dashboard-page.component';
import { DashboardResourcesPanelComponent } from './components/dashboard-resources-panel/dashboard-resources-panel.component';
import { OrthoQaDashboardPageComponent } from './components/ortho-qa-dashboard-page/ortho-qa-dashboard-page.component';
import { SpeechQaDashboardPageComponent } from './components/speech-qa-dashboard-page/speech-qa-dashboard-page.component';
import { SurgicalQaDashboardPageComponent } from './components/surgical-qa-dashboard-page/surgical-qa-dashboard-page.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { AuthenticationModule } from '@src/app/features/authentication/authentication.module';

@NgModule({
  declarations: [
    CurrentMonthBlockComponent,
    DashboardAnnouncementsPanelComponent,
    DashboardGradeItemComponent,
    DashboardPageComponent,
    DashboardResourcesPanelComponent,
    OrthoQaDashboardPageComponent,
    SpeechQaDashboardPageComponent,
    SurgicalQaDashboardPageComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    DataBlockModule,
    DetailsCommonsModule,
    EducationalResourcesModule,
    FlexLayoutModule,
    GeneralCommonsModule,
    GenericResourceListModule,
    MaterialModule,
    PermissionModule,
    RouterModule,
    SpinnerModule,
    StaticFilesModule,
    StatisticsModule,
    PartnerSurveysModule,
    ReleaseInfoModule,
    TranslateModule.forRoot(),
    ReadonlyCommonsModule,
    AuthenticationModule
  ]
})
export class DashboardModule {}
