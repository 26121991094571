import { getFirstErrorMessage } from '@/src/app/shared/validation/error.utils';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { BaseFormControlComponent } from '@shared/components/form/components/base-form-control/base-form-control.component';
import { DateFormControl } from '@shared/components/form/components/date/date-form-control';
import { DatepickerI18nService } from '@shared/services/localisation/datepicker-i18n.service';
import { dateInputMaxValidationError, dateInputMinValidationError } from '@shared/validation/validation.utils';
import { ArrayUtils } from '@utils/array.utils';
import { getToday, MOMENT_ADAPTER_PROVIDERS_ARRAY } from '@utils/date.utils';
import { FormMode } from '@utils/form.utils';
import * as moment from 'moment';

@Component({
  selector: 'stx-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: MOMENT_ADAPTER_PROVIDERS_ARRAY
})
export class DateComponent extends BaseFormControlComponent implements OnInit {
  readonly labelSuffix = 'date_format';

  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment | null = null;
  @Input() set maxDateToday(maxDateToday: boolean) {
    this.maxDate = !this.maxDate && maxDateToday ? getToday() : this.maxDate;
  }
  @Input() label: string;

  @Input() preserveValue = false;

  get validationMessagePath() {
    const errorKeys = Object.keys(this.formControl.errors);
    if (ArrayUtils.intersectionNotEmpty(errorKeys, [dateInputMaxValidationError, dateInputMinValidationError])) {
      return 'error.date_range';
    } else {
      return getFirstErrorMessage(this.formControl.errors) || 'error.date';
    }
  }

  constructor(controlContainer: ControlContainer, private readonly datepickerI18nService: DatepickerI18nService) {
    super(controlContainer);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.formMode === FormMode.NEW && !this.preserveValue) {
      this.formControl.reset();
    }

    this.subSink.sink = this.datepickerI18nService.followLocaleUpdatesAndGetSubscription();
  }

  protected configureFromFormControl(formControl: AbstractControl) {
    super.configureFromFormControl(formControl);
    if (formControl instanceof DateFormControl) {
      if (formControl.minDate !== undefined) {
        this.subSink.sink = formControl.minDate().subscribe(value => (this.minDate = value));
      }
      if (formControl.maxDate !== undefined) {
        this.subSink.sink = formControl.maxDate().subscribe(value => (this.maxDate = value));
      }
    }
  }
}
