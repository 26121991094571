<stx-ortho-shared-general-info
  #sharedGeneralInfo
  [tcList]="tcList"
  [orthoFormType]="orthoFormType"
  [orthoStage]="OrthoStage.ASSESSMENT"
  [minCareProvidedDate]="minCareProvidedDate"
  [maxCareProvidedDate]="maxCareProvidedDate"
  [print]="print"
  [formMode]="formMode"
></stx-ortho-shared-general-info>
<hr class="form-subsection-separator" />
<div class="form-row">
  <stx-radio-group
    [label]="this.orthoFormType === OrthoFormType.PRESURGICAL_ORTHOPEDICS ? 'presurgical.info_given' : 'ortho.info_given'"
    [layoutDirection]="'row'"
    [controlName]="'infoGiven'"
    [formMode]="formMode"
    [options]="yesNo"
    [print]="print"
  ></stx-radio-group>
  <stx-textarea [label]="'comments'" [controlName]="'infoGivenMore'" [formMode]="formMode"> </stx-textarea>
</div>

<hr class="form-subsection-separator" />

<div class="form-row">
  <stx-checkbox-group
    [label]="orthodonticApplianceLabel"
    [layoutDirection]="'column'"
    [options]="orthodonticApplianceOptions"
    [formMode]="formMode"
    [print]="print"
    [formGroupErrorNames]="orthodonticApplianceErrorNames"
  ></stx-checkbox-group>
  <stx-textarea [label]="'other_explain'" [controlName]="'otherDevice'" [formMode]="formMode"> </stx-textarea>
</div>
