import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component, Input } from '@angular/core';
import { NutritionType } from '@src/app/features/nutrition-management/enums/nutrition-type.enum';
import { NutritionSection } from '@src/app/features/nutrition-management/utils/nutrition-management.utils';

@Component({ template: '' })
export abstract class BaseNutritionSectionComponent extends BaseFormElementComponent {
  readonly NutritionType = NutritionType;
  readonly NutritionSection = NutritionSection;
  @Input() nutritionFormType: NutritionType;
}
