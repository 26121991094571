<stx-expandable-form-panel [expanded]="isGoalSectionExpanded">
  <ng-container slot="heading">
    {{ 'speech_treatment.goal' + goalNumber | translate }}

    <button data-testid="button-treatment-goal" *ngIf="!print" mat-button color="primary" (click)="clearGoalSection()">
      <mat-icon>clear</mat-icon>
      {{ 'speech_treatment.clear_goal' | translate }}
    </button></ng-container
  >

  <div class="form-columns-1-1-1" [ngClass]="{ 'flex-row-important': print }">
    <div>
      <div class="form-row" [ngClass]="{ 'print-view-container': print }">
        <stx-radio-group
          [formGroup]="formGroup"
          [label]="'speech_treatment.goal'"
          [formMode]="formMode"
          [controlName]="'goal' + goalNumber"
          [options]="speechTreatmentGoals"
          layoutDirection="3cols"
          [print]="print"
          (click)="clearGoal('goal' + goalNumber + 'Other')"
        ></stx-radio-group>
      </div>
      <div class="form-row">
        <stx-input
          [label]="'other'"
          [formGroup]="formGroup"
          [formMode]="formMode"
          [controlName]="'goal' + goalNumber + 'Other'"
          [type]="'text'"
          [print]="print"
          (click)="clearGoal('goal' + goalNumber)"
        ></stx-input>
      </div>
    </div>
    <div>
      <div class="form-row">
        <stx-date
          [label]="'speech_treatment.goal_date'"
          [controlName]="'goal' + goalNumber + 'Start'"
          [formGroup]="formGroup"
          [formMode]="formMode"
          [print]="print"
          [minDate]="minGoalStartedDate"
        ></stx-date>
      </div>
    </div>
    <div>
      <div class="form-row">
        <stx-radio-group
          [label]="'speech_treatment.treatment_status'"
          [formGroup]="formGroup"
          [formMode]="formMode"
          [controlName]="'goal' + goalNumber + 'Status'"
          [options]="staticDictionaries.get('goal_status')"
          [layoutDirection]="'column'"
          [lastOptionSeparateLabel]="'speech_treatment.goal_completed'"
          [print]="print"
        ></stx-radio-group>
      </div>
      <div class="form-row">
        <stx-date
          *ngIf="formGroup.get('goal' + goalNumber + 'Status').value === goalCorrectAllTimes || print"
          [label]="'speech_treatment.goal_completed_date'"
          [controlName]="'goal' + goalNumber + 'End'"
          [formGroup]="formGroup"
          [minDate]="formGroup.get('goal' + goalNumber + 'Start').value"
          [print]="print"
          [formMode]="formMode"
        ></stx-date>
      </div>
    </div>
  </div>
</stx-expandable-form-panel>
