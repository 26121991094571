<div class="data-table-container">
  <div class="data-table-wrapper">
    <table aria-describedby="data-table-container">
      <thead>
        <tr>
          <th>{{ tableTreatmentTitle | translate }}</th>
          <th>{{ 'all' | translate }}</th>
          <th>{{ currentMonthLabel | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="patientCount >= 0">
          <td>{{ 'patients' | translate }}</td>
          <td>{{ patientCount | number }}</td>
          <td>{{ '-' }}</td>
        </tr>
        <tr *ngIf="treatmentStats.inProgress">
          <td>{{ 'treatment.status.in_progress' | translate }}</td>
          <td>{{ treatmentStats.inProgress.allTimeCount | number }}</td>
          <td>{{ treatmentStats.inProgress.currentMonthCount | number }}</td>
        </tr>
        <tr *ngIf="treatmentStats.submitted">
          <td>{{ 'treatment.status.submitted' | translate }}</td>
          <td>{{ treatmentStats.submitted.allTimeCount | number }}</td>
          <td>{{ treatmentStats.submitted.currentMonthCount | number }}</td>
        </tr>
        <tr *ngIf="treatmentStats.accepted">
          <td>{{ 'treatment.status.accepted' | translate }}</td>
          <td>{{ treatmentStats.accepted.allTimeCount | number }}</td>
          <td>{{ treatmentStats.accepted.currentMonthCount | number }}</td>
        </tr>
        <tr *ngIf="treatmentStats.reimbursable">
          <td>{{ 'treatment.status.reimbursable' | translate }}</td>
          <td>{{ treatmentStats.reimbursable.allTimeCount | number }}</td>
          <td>{{ treatmentStats.reimbursable.currentMonthCount | number }}</td>
        </tr>
        <tr *ngIf="treatmentStats.rejected">
          <td>{{ 'treatment.status.rejected' | translate }}</td>
          <td>{{ treatmentStats.rejected.allTimeCount | number }}</td>
          <td>{{ treatmentStats.rejected.currentMonthCount | number }}</td>
        </tr>
        <tr *ngIf="treatmentStats.discontinued">
          <td>{{ 'treatment.status.discontinued' | translate }}</td>
          <td>{{ treatmentStats.discontinued.allTimeCount | number }}</td>
          <td>{{ treatmentStats.discontinued.currentMonthCount | number }}</td>
        </tr>
        <tr *ngIf="treatmentStats.exceedsQuota && !isOnDashboard">
          <td>{{ 'treatment.status.exceeds_quota' | translate }}</td>
          <td>{{ treatmentStats.exceedsQuota.allTimeCount | number }}</td>
          <td>{{ treatmentStats.exceedsQuota.currentMonthCount | number }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
