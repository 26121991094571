import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgModule, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/features/authentication/services/auth.service';
import { BaseNavComponent } from '../base-nav.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/modules/material/material.module';
import { LanguageTempComponentModule } from '../../language-switcher/components/language-temp/language-temp.component';
import { PermissionModule } from '@core/permissions/permission.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';
import { PermissionEnum } from '@core/permissions/permission.enum';

@Component({
  selector: 'stx-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarComponent extends BaseNavComponent implements OnInit {
  routerLink: string = '/';
  isAccountMenuOpened: boolean = false;
  isToolTipDisabled: boolean = false;
  @ViewChild('tooltipSpanElement', { static: false }) tooltipSpanElement: ElementRef<HTMLSpanElement>;

  constructor(
    public authService: AuthService,
    public currentUserService: CurrentUserService,
    public router: Router,
    public cd: ChangeDetectorRef,
    public translateService: TranslateService,
    public wsHelperService: WsHelperService
  ) {
    super(authService, currentUserService, router, cd, translateService, wsHelperService);
  }

  ngOnInit() {
    this.subSink.sink = this.wsHelperService.callToSubject(this.currentUserService.getUserSubject()).subscribe(user => {
      if (user) {
        this.getNavItems();
      }
    });
  }

  updateToolTipVisibility(): void {
    this.isToolTipDisabled = !this.tooltipSpanElement.nativeElement.style.display;
  }

  get canSeeEditSettingsRoute(): boolean {
    return this.authService.checkUserPermission(PermissionEnum.EDIT_USER_SETTINGS);
  }
}

@NgModule({
  declarations: [NavBarComponent],
  imports: [CommonModule, MaterialModule, TranslateModule, RouterModule, LanguageTempComponentModule, PermissionModule, FlexLayoutModule],
  exports: [NavBarComponent]
})
export class NavBarComponentModule {}
