import { formLanguages, indiaDeclarationFormLanguages } from '@/src/app/utils/language.utils';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileExtension, FileUtils, Mime } from '@utils/file.utils';
import { FileInfo } from '@src/app/features/media/models/media.model';
import { MediaService } from '@src/app/features/media/services/media.service';
import { UploadDocumentType } from '@shared/enums/document-type.enum';
import { FormType } from '@shared/enums/form-type.enum';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { WsHelperService } from '@shared/services/ws-helper.service';

const releaseFormFileName = 'Release_Form';
const declarationFormFileName = 'Declaration_Form';

@Component({
  selector: 'stx-patient-document-file-upload',
  templateUrl: './patient-document-file-upload.component.html',
  styleUrls: ['./patient-document-file-upload.component.scss']
})
export class PatientDocumentFileUploadComponent extends BaseComponent implements OnInit {
  readonly releaseFormAcceptedTypes = [Mime.GIF, Mime.JPEG, Mime.JPG, Mime.PNG, Mime.PDF];
  readonly formLanguages = formLanguages;
  readonly indiaDeclarationFormLanguages = indiaDeclarationFormLanguages;
  readonly FormType = FormType;
  readonly DocumentType = UploadDocumentType;

  @Input() disabled: boolean;
  @Input() formType: FormType;
  @Output() newFileUploaded = new EventEmitter<FileInfo>();
  documentType: UploadDocumentType;

  constructor(private readonly mediaService: MediaService, private readonly wsHelperService: WsHelperService) {
    super();
  }

  private _newFileInfo: FileInfo;

  get newFileInfo(): FileInfo {
    return this._newFileInfo;
  }

  set newFileInfo(uploadedFileData: FileInfo) {
    this._newFileInfo = uploadedFileData;
    this.newFileUploaded.emit(uploadedFileData);
  }

  ngOnInit() {
    this.documentType = PatientDocumentFileUploadComponent.getDocumentType(this.formType);
  }

  private static getDocumentType(formType: FormType) {
    if (formType === FormType.SURGICAL_INTERVENTION) {
      return UploadDocumentType.DECLARATION_FORM;
    } else {
      return UploadDocumentType.RELEASE_FORM;
    }
  }

  downloadReleaseFormTemplate(languageShortcut: string): void {
    const fileName = `pdf/${this.getTemplateFileName()}_${languageShortcut}${FileExtension.PDF}`;
    this.wsHelperService
      .call(this.mediaService.getStaticFile(fileName, Mime.PDF))
      .subscribe(file => FileUtils.downloadFile(file, fileName, Mime.PDF));
  }

  private getTemplateFileName(): string {
    switch (this.formType) {
      case FormType.PATIENT:
        return releaseFormFileName;
      case FormType.SURGICAL_INTERVENTION:
        return declarationFormFileName;
    }
  }
}
