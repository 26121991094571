import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { DetailsPageComponent } from '@shared/modules/details-commons/components/details-page/details-page.component';
import { PageWithSidebarComponent } from '@shared/modules/details-commons/components/page-with-sidebar/page-with-sidebar.component';
import { SimpleDetailsValueComponent } from '@shared/modules/details-commons/components/simple-details-value/simple-details-value.component';
import { DetailsValueListComponent } from '@shared/modules/details-commons/components/details-value-list/details-value-list.component';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FileExportIconComponent } from '@shared/modules/details-commons/components/file-export-icon/file-export-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    DetailsPageComponent,
    DetailsValueListComponent,
    FileExportIconComponent,
    PageWithSidebarComponent,
    SimpleDetailsValueComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FlexModule,
    FontAwesomeModule,
    GeneralCommonsModule,
    MatTooltipModule,
    RouterModule,
    TranslateModule.forChild()
  ],
  exports: [DetailsPageComponent, DetailsValueListComponent, FileExportIconComponent, PageWithSidebarComponent, SimpleDetailsValueComponent]
})
export class DetailsCommonsModule {}
