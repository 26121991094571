<div [ngClass]="{ 'p-t-30 page-break-inside-avoid': print }">
  <div>
    <p class="form-section-heading">
      {{ 'nutrition_management.feeding_assessment' | translate | sectionNumber : nutritionFormType : NutritionSection.FEEDING_ASSESSMENT }}
    </p>
  </div>
  <div class="form-row-valign-top">
    <stx-checkbox-group-with-text-field
      *ngFor="let option of options"
      [layoutDirection]="'column'"
      [options]="option"
      [formGroup]="formGroup"
      [formMode]="formMode"
      [deselectTriggerControlName]="'noProblem'"
      [print]="print"
      [textFieldControlName]="'otherMore'"
      [textFieldParentControlName]="'other'"
    ></stx-checkbox-group-with-text-field>
  </div>
  <div class="form-row">
    <mat-error *ngIf="showError && !print" class="checkbox-group-error">
      {{ 'error.none_selected_above' | translate }}
    </mat-error>
  </div>
</div>
