import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/services/http/base-http.service';
import { HttpClient } from '@angular/common/http';
import { OrthognathicTreatment } from './orthognathic-treatment.model';
import { Observable } from 'rxjs';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Injectable({
  providedIn: 'root'
})
export class OrthognathicService extends BaseHttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient, { baseUrl: 'orthognathics' });
  }

  public submitOrthognathicTreatment(orthognathicTreatment: OrthognathicTreatment): Observable<OrthognathicTreatment> {
    return this.httpClient.post<OrthognathicTreatment>(`${this.baseUrl}/submit`, orthognathicTreatment);
  }

  public unlockOrthognathic(orthognathicId: TreatmentId): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}/unlock/${orthognathicId}`, null);
  }

  public deleteOrthognathic(orthognathicId: TreatmentId): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${orthognathicId}`);
  }

  getOrthognathicTreatment(orthognathicTreatmentId: TreatmentId): Observable<OrthognathicTreatment> {
    return this.httpClient.get<OrthognathicTreatment>(`${this.baseUrl}/${orthognathicTreatmentId}`);
  }
}
