import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectOptions } from '@shared/components/form/components/select/select.model';
import { ReportDictionariesService } from '../../services/report-dictionaries.service';
import { ReportFiltersHelperService } from '../../services/report-filters-helper.service';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { GroupByScopeChangePayload, ReportFilterChange, ReportFiltersParams } from '../../models/report-filters.model';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { FormModule } from '@shared/components/form/form.module';
import { filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'stx-group-by-scope',
  templateUrl: './group-by-scope.component.html',
  styleUrls: []
})
export class GroupByScopeComponent extends BaseComponent implements OnInit {
  @Input() formGroupReference: UntypedFormGroup;
  @Input() controlName: string;
  @Output() filterChange = new EventEmitter<ReportFilterChange<GroupByScopeChangePayload>>();
  scopeOptions: SelectOptions = [];
  control: AbstractControl;
  label = 'reports.filters.group_by_scope.label';
  scopeInitialParams: string;

  constructor(
    private reportDictionariesService: ReportDictionariesService,
    private reportFiltersHelperService: ReportFiltersHelperService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.control = this.formGroupReference.get(this.controlName);
    this.scopeOptions = this.getScopeOptions();
    this.watchQueryParamsChange();
    this.watchFilterModel();
    this.setDefaultValue();
  }

  private setDefaultValue() {
    if (this.scopeInitialParams) {
      this.setValueFromParams();
    } else {
      this.control.setValue(this.scopeOptions[0]?.id);
    }
  }

  private emitFilterModel(groupByScopeId: string): void {
    this.filterChange.emit({
      filterName: 'groupByScope',
      payload: {
        groupByData: [groupByScopeId]
      }
    });
  }

  private watchFilterModel() {
    this.subSink.sink = this.control.valueChanges.subscribe(this.emitFilterModel.bind(this));
  }

  private getScopeOptions(): SelectOptions {
    return this.reportFiltersHelperService.mapDictionaryToSelectOptions(this.reportDictionariesService.dictionaries.groupByData);
  }

  private watchQueryParamsChange() {
    this.subSink.sink = this.route.queryParams
      .pipe(filter((params: ReportFiltersParams) => !!params.scope))
      .subscribe((params: ReportFiltersParams) => {
        this.scopeInitialParams = params.scope;

        this.setValueFromParams();
      });
  }

  setValueFromParams() {
    this.control.setValue(this.scopeInitialParams);
  }
}

@NgModule({
  declarations: [GroupByScopeComponent],
  imports: [CommonModule, FormModule],
  exports: [GroupByScopeComponent]
})
export class GroupByScopeComponentModule {}
