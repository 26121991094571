import { PatientBase, PatientListItem } from '@/src/app/features/patient/models/patient.model';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { VisibilityScope } from '@shared/models/visibility-scope.model';
import { MediaUrl } from '@src/app/features/media/models/media-url.model';
import { ArrayUtils } from '@utils/array.utils';
import { patientDetailsPath } from './routing.utils';

export const patientStatusDraftValue = 10;

export class PatientUtils {
  static getPatientUrl(patient: PatientListItem): string {
    return patientDetailsPath(patient.id);
  }

  static isPatientValidForTreatmentAndInScope(patient: PatientListItem) {
    // TODO#11043: this should be a flag set on BE
    return (
      this.isPhysicalPatientValidForNewTreatment(patient) &&
      ArrayUtils.contains([VisibilityScope.USER_SCOPE, VisibilityScope.PARTNER_SCOPE], patient.visibilityScope)
    );
  }

  static isPhysicalPatientValidForNewTreatment(patient: PatientListItem): boolean {
    return !!patient.patientIdForNewTreatment;
  }

  static getPatientReleaseForms(patient: PatientBase): MediaUrl[] {
    const result = (patient?.mediaResources || [])
      .filter(
        media =>
          media.parentOrderName === ParentOrderName.ELECTRONICALLY_SIGNED_RELEASE_FORM ||
          media.parentOrderName === ParentOrderName.RELEASE_FORM
      )
      .map(media => media.urlList);
    return result.reduce((a, b) => a.concat(b, []), []);
  }
}
