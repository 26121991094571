import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, NgModule, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReportConfig, ReportDownloadTypes } from '../../components/report-filters/models/report-filters.model';
import { ReportFiltersModule } from '../../components/report-filters/report-filters.component';
import { ReportTableDataService } from '../../components/report-filters/services/report-table-data.service';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import { SpeechStatsReportTableComponentModule } from './components/speech-stats-report-table/speech-stats-report-table.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { ActivatedRoute } from '@angular/router';
import { checkRegion } from '@src/app/features/reports/components/reports.utils';
import { ReportRegions } from '@src/app/features/reports/models/report-regions.model';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { speechIndiaStatsFormDefaults, speechStatsFormDefaults } from '@src/app/features/reports/stats-reports.utils';

@Component({
  selector: 'stx-speech-stats-report',
  templateUrl: './speech-stats-report.component.html'
})
export class SpeechStatsReportComponent extends BaseComponent implements OnInit {
  reportConfig: ReportConfig = {
    type: 'SPEECH_STATS',
    downloadOptions: {
      [ReportDownloadTypes.EXCEL]: true
    },
    filters: {
      exclusions: true,
      geography: true,
      groupByScope: true,
      mohGrades: true,
      organizationsTree: true,
      periodPresets: true,
      periodType: true,
      recordDate: true,
      speechAssessmentStatuses: true,
      speechTreatmentStatuses: true
    }
  };

  constructor(
    public reportService: ReportService,
    public reportTableDataService: ReportTableDataService,
    public changeDetector: ChangeDetectorRef,
    public route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.subSink.sink = this.reportService.initialized$.subscribe(() => {
      if (checkRegion(this.subSink, this.route, this.reportService, false) === ReportRegions.INDIA) {
        this.reportService.reportFiltersFormGroup?.patchValue(speechIndiaStatsFormDefaults);
      } else {
        this.reportService.reportFiltersFormGroup?.patchValue(speechStatsFormDefaults);
      }
    });
  }

  applyFilters(pagination: PaginationSortingModel) {
    this.reportTableDataService.prepareList(this.reportConfig.type, this.reportService.model, pagination);
  }
}

@NgModule({
  declarations: [SpeechStatsReportComponent],
  imports: [
    CommonModule,
    ReportFiltersModule,
    ReportHeaderComponentModule,
    SpeechStatsReportTableComponentModule,
    TranslateModule,
    GeneralCommonsModule
  ],
  exports: [SpeechStatsReportComponent]
})
export class SpeechStatsReportComponentModule {}
