import { SurveyBlocks } from '@/src/app/features/dashboard/partner-surveys/survey.model';
import { BaseHttpService } from '@/src/app/shared/services/http/base-http.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurveyService extends BaseHttpService {
  constructor(protected readonly httpClient: HttpClient) {
    super(httpClient, { baseUrl: 'surveys' });
  }

  getPartnerSurveyBlocks(): Observable<SurveyBlocks | null> {
    return this.httpClient.get<SurveyBlocks>(`${this.baseUrl}/partner`);
  }
}
