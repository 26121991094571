<div class="patient-release-form-container">
  <div class="flex">
    <stx-patient-release-form-online
      [patientDataProvider]="patientDataProvider"
      [patientDataMatchesReleaseFormData]="patientDataMatchesOnlineReleaseFormData"
    ></stx-patient-release-form-online>
  </div>
  <div class="compare-divider middle-divider">
    <mat-divider class="hide-gt-md"></mat-divider>
    <div class="compare-divider-text">{{ 'or' | translate }}</div>
    <mat-divider [vertical]="greaterThanMedium"></mat-divider>
  </div>
  <div class="flex">
    <stx-patient-release-form-offline
      [patientDataMatchesReleaseFormData]="patientDataMatchesOfflineReleaseFormData"
    ></stx-patient-release-form-offline>
  </div>
</div>
