import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { TableColumn, TableData, TableStructure } from '@shared/components/table/models/table-structure.model';
import { SimpleReportTableComponentModule } from '@src/app/features/reports/components/simple-report-table/simple-report-table.component';
import { ReportTableDataService } from 'src/app/features/reports/components/report-filters/services/report-table-data.service';
import { ReportService } from '../../../../components/report-filters/services/report.service';
import { QaEvaluatorReviewsRecord, QaEvaluatorReviewType } from '../../qa-evaluator-reviews-report.model';

@Component({
  selector: 'stx-qa-evaluator-review-report-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./qa-evaluator-reviews-report-table.component.scss'],
  templateUrl: './qa-evaluator-reviews-report-table.component.html'
})
export class QaEvaluatorReviewsReportTableComponent extends BaseComponent {
  @Input() type: QaEvaluatorReviewType;
  @Input() set tableStructure(tableStructure: TableStructure<QaEvaluatorReviewsRecord[]>) {
    const tableData: TableData<QaEvaluatorReviewsRecord[]> = tableStructure?.data;

    this._tableStructure = tableStructure;
    this.columns = tableStructure?.labels || [];
    this.displayedColumns = this.columns.map(a => a.name);
    this.dataSource = new MatTableDataSource(tableData?.page?.content);
    this.totalResults = tableData?.page?.totalElements;
    this.changeDetector.detectChanges();
  }

  get tableStructure() {
    return this._tableStructure;
  }
  @Input() dataReady = false;

  columns: TableColumn[] = [];
  dataSource: MatTableDataSource<QaEvaluatorReviewsRecord[]>;
  totalResults: number;
  private _tableStructure: TableStructure<QaEvaluatorReviewsRecord[]>;
  displayedColumns: string[];

  constructor(
    public changeDetector: ChangeDetectorRef,
    public reportTableDataService: ReportTableDataService,
    public reportService: ReportService
  ) {
    super();
  }
}

@NgModule({
  declarations: [QaEvaluatorReviewsReportTableComponent],
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    MatPaginatorModule,
    EmptyTableStateComponentModule,
    SimpleReportTableComponentModule
  ],
  exports: [QaEvaluatorReviewsReportTableComponent]
})
export class QaEvaluatorReviewsReportTableComponentModule {}
