<h1>{{ 'patient.registration' | translate }}</h1>

<form *ngIf="areDictionariesLoaded" [formGroup]="formGroup">
  <stx-expandable-form-section [expanded]="true">
    <ng-container slot="heading">{{ 'user.personal_data' | translate }}</ng-container>
    <stx-form-panel>
      <div class="form-row">
        <stx-date [formMode]="formMode" formGroupName="diagnosis" controlName="evaluationDate" label="patient.evaluation_date"></stx-date>
        <stx-select
          [formMode]="formMode"
          controlName="partnerId"
          label="partner.partner"
          [dictionaryList]="partnerList$ | async"
        ></stx-select>
        <stx-select
          [formMode]="formMode"
          controlName="treatmentCenterId"
          label="treatment_center.treatment_center"
          [dictionaryList]="tcList$ | async"
        ></stx-select>
      </div>
      <div class="form-row">
        <stx-input [formMode]="formMode" controlName="recordNumberId" label="patient.record_number"></stx-input>
        <stx-input [formMode]="formMode" controlName="recordNumberIdLoc" label="patient.record_number_local"></stx-input>
        <div></div>
      </div>
      <div class="form-row">
        <stx-input [formMode]="formMode" controlName="lastName" label="last_name"></stx-input>
        <stx-input [formMode]="formMode" controlName="firstName" label="first_name"></stx-input>
        <stx-input [formMode]="formMode" controlName="middleName" label="patient.middle_name"></stx-input>
      </div>
      <div class="form-row" *ngIf="form.isChineseTcSelected">
        <stx-input [formMode]="formMode" controlName="lastNameLoc" label="patient.last_name_local"></stx-input>
        <stx-input [formMode]="formMode" controlName="firstNameLoc" label="patient.first_name_local"></stx-input>
        <stx-input [formMode]="formMode" controlName="middleNameLoc" label="patient.middle_name_local"></stx-input>
      </div>
      <div class="form-row">
        <stx-radio-group
          [formMode]="formMode"
          layoutDirection="row"
          controlName="gender"
          label="patient.gender"
          [options]="dictionaries.genders"
          [print]="print"
        ></stx-radio-group>
        <stx-date [formMode]="formMode" controlName="dateOfBirth" label="patient.date_of_birth"></stx-date>
        <stx-checkbox [formMode]="formMode" controlName="dateOfBirthEstimated" name="patient.date_of_birth_estimated"></stx-checkbox>
      </div>
      <div class="form-row">
        <stx-radio-group
          [formMode]="formMode"
          layoutDirection="row"
          controlName="race"
          label="patient.race"
          [options]="dictionaries.races"
          [print]="print"
        ></stx-radio-group>
      </div>
      <hr class="form-subsection-separator" />
      <ng-container formGroupName="address">
        <div class="form-row">
          <stx-input [formMode]="formMode" controlName="street1" label="patient.address"></stx-input>
          <stx-input [formMode]="formMode" controlName="city" label="patient.city"></stx-input>
          <stx-input [formMode]="formMode" controlName="zip" label="patient.zip_code"></stx-input>
        </div>
        <div class="form-row">
          <stx-select
            [formMode]="formMode"
            controlName="countryId"
            label="geography.country"
            [dictionaryList]="countryList$ | async"
          ></stx-select>
          <stx-select
            [formMode]="formMode"
            controlName="stateId"
            label="patient.province"
            [dictionaryList]="provinceList$ | async"
          ></stx-select>
          <stx-select
            [formMode]="formMode"
            controlName="districtId"
            label="patient.district"
            [dictionaryList]="districtList$ | async"
          ></stx-select>
        </div>
        <div class="form-row">
          <stx-input [formMode]="formMode" controlName="phone" label="patient.phone"></stx-input>
          <stx-input [formMode]="formMode" controlName="mobile" label="patient.mobile"></stx-input>
          <div></div>
        </div>
      </ng-container>
    </stx-form-panel>
  </stx-expandable-form-section>

  <stx-expandable-form-section [expanded]="true">
    <ng-container slot="heading">{{ 'patient.parent_info' | translate }}</ng-container>
    <stx-form-panel>
      <div class="form-row">
        <stx-input [formMode]="formMode" controlName="guardianFirstName" label="first_name"></stx-input>
        <stx-input [formMode]="formMode" controlName="guardianLastName" label="last_name"></stx-input>
        <div></div>
      </div>
      <div class="form-row">
        <stx-radio-group
          [formMode]="formMode"
          layoutDirection="row"
          controlName="guardianRelationship"
          label="patient.guardian_relationship"
          [options]="dictionaries.guardianRel"
        ></stx-radio-group>
      </div>
      <hr class="form-section-separator" />
      <ng-container *ngIf="form.hasReleaseFormError; else validReleaseForm">
        <mat-error class="form-section-heading">
          {{ 'patient.release_form' | translate }}
        </mat-error>
      </ng-container>
      <ng-template #validReleaseForm>
        <p class="form-section-heading">
          {{ 'patient.release_form' | translate }}
        </p>
      </ng-template>
      <p *ngIf="isReleaseFormCloned">{{ 'patient.release_form_cloned' | translate }}</p>
      <stx-patient-release-form-block
        [patientDataProvider]="patientDataForReleaseForm"
        [patientDataMatchesOnlineReleaseFormData]="patientDataMatchesOnlineReleaseFormData"
        [patientDataMatchesOfflineReleaseFormData]="patientDataMatchesOfflineReleaseFormData"
      ></stx-patient-release-form-block>
      <hr class="form-section-separator" />
      <div class="form-row">
        <stx-radio-group
          [formMode]="formMode"
          layoutDirection="row"
          controlName="knowAboutSt"
          label="patient.know_about_st"
          [options]="dictionaries.knowAboutSt"
        ></stx-radio-group>
      </div>
      <div class="form-row">
        <stx-input [formMode]="formMode" controlName="referringOrg" label="patient.referring_org"></stx-input>
        <div></div>
        <div></div>
      </div>
    </stx-form-panel>
  </stx-expandable-form-section>

  <stx-expandable-form-section [expanded]="true" formGroupName="diagnosis">
    <ng-container slot="heading">{{ 'patient.family_history' | translate }}</ng-container>
    <stx-form-panel>
      <div class="form-columns-1-1">
        <div>
          <div class="form-row">
            <div class="form-columns-2-1">
              <div class="form-row">
                <stx-input [formMode]="formMode" controlName="pregnancyLength" label="patient.pregnancy_length"></stx-input>
                <stx-checkbox [formMode]="formMode" controlName="pregnancyLengthUnknown" name="dont_know"></stx-checkbox>
              </div>
            </div>
          </div>
          <div class="form-row">
            <stx-radio-group
              [formMode]="formMode"
              layoutDirection="row"
              controlName="pregnancyComplication"
              label="patient.pregnancy_complication"
              [options]="dictionaries.yesNoDontKnow"
            ></stx-radio-group>
          </div>
          <div class="form-row">
            <stx-radio-group
              [formMode]="formMode"
              layoutDirection="row"
              controlName="birthComplication"
              label="patient.birth_complication"
              [options]="dictionaries.yesNoDontKnow"
            ></stx-radio-group>
          </div>
          <div class="form-row">
            <stx-radio-group
              [formMode]="formMode"
              layoutDirection="row"
              controlName="motherSmoked"
              label="patient.mother_smoked"
              [options]="dictionaries.yesNoDontKnow"
            ></stx-radio-group>
          </div>
        </div>
        <div>
          <div class="form-row">
            <stx-radio-group
              [formMode]="formMode"
              layoutDirection="row"
              controlName="motherAlcohol"
              label="patient.mother_alcohol"
              [options]="dictionaries.yesNoDontKnow"
            ></stx-radio-group>
          </div>
          <div class="form-row">
            <stx-radio-group
              [formMode]="formMode"
              layoutDirection="row"
              controlName="immedRelativeCleft"
              label="patient.immed_relative_cleft"
              [options]="dictionaries.yesNoDontKnow"
            ></stx-radio-group>
          </div>
          <div class="form-row">
            <stx-radio-group
              [formMode]="formMode"
              layoutDirection="row"
              controlName="distRelativeCleft"
              label="patient.dist_relative_cleft"
              [options]="dictionaries.yesNoDontKnow"
            ></stx-radio-group>
          </div>
        </div>
      </div>
    </stx-form-panel>
  </stx-expandable-form-section>

  <stx-expandable-form-section [expanded]="true" formGroupName="diagnosis">
    <ng-container slot="heading">{{ 'patient.diagnosis' | translate }}</ng-container>
    <stx-form-panel>
      <div class="form-columns-2-1">
        <div>
          <div class="form-row form-row-valign-bottom">
            <stx-input
              [formMode]="formMode"
              controlName="evaluationWeight"
              type="number"
              label="patient.weight"
              [subLabel]="('unit.kg' | translate) + ' (1.00 - 200.00)'"
            ></stx-input>
            <stx-input
              [formMode]="formMode"
              controlName="evaluationHeight"
              type="number"
              label="height"
              [subLabel]="('unit.cm' | translate) + ' (1.00 - 200.00)'"
            ></stx-input>
          </div>
        </div>
        <div>
          <div class="form-row">
            <stx-radio-group
              [formMode]="formMode"
              layoutDirection="row"
              controlName="beforeSurgery"
              label="patient.before_surgery"
              [options]="dictionaries.yesNo"
            ></stx-radio-group>
          </div>
          <div class="form-row">
            <stx-radio-group
              [formMode]="formMode"
              layoutDirection="column"
              controlName="previousSurgery"
              label="patient.previous_surgery"
              [options]="dictionaries.previousSurgeryOptions"
            ></stx-radio-group>
          </div>
        </div>
      </div>
      <hr class="form-section-separator" />
      <h3>
        <small>{{ 'patient.diagnosis_title' | translate }}</small>
      </h3>
      <div class="form-row" *ngIf="form.hasAllCleftFieldsEqualNotCleftError">
        <p class="mat-error">
          {{ 'error.field_empty' | translate }}
        </p>
      </div>
      <div class="form-row">
        <div class="form-columns-1-1">
          <div>
            <p class="form-subsection-heading">{{ 'patient.type_left_clip' | translate }}</p>
            <div class="form-columns-1-1">
              <div>
                <div class="form-row">
                  <stx-radio-group
                    [formMode]="formMode"
                    layoutDirection="column"
                    controlName="leftCleftLip"
                    label="patient.left"
                    [options]="dictionaries.cleftLipOptions"
                  ></stx-radio-group>
                </div>
              </div>
              <div>
                <div class="form-row">
                  <stx-radio-group
                    [formMode]="formMode"
                    layoutDirection="column"
                    controlName="rightCleftLip"
                    label="patient.right"
                    [options]="dictionaries.cleftLipOptions"
                  ></stx-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p class="form-subsection-heading">{{ 'patient.type_alveolus' | translate }}</p>
            <div class="form-columns-1-1">
              <div>
                <div class="form-row">
                  <stx-radio-group
                    [formMode]="formMode"
                    layoutDirection="column"
                    controlName="leftAlveolus"
                    label="patient.left"
                    [options]="dictionaries.cleftLipOptions"
                  ></stx-radio-group>
                </div>
              </div>
              <div>
                <div class="form-row">
                  <stx-radio-group
                    [formMode]="formMode"
                    layoutDirection="column"
                    controlName="rightAlveolus"
                    label="patient.right"
                    [options]="dictionaries.cleftLipOptions"
                  ></stx-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="form-subsection-separator" />
      <div class="form-row">
        <div class="form-columns-1-1">
          <div>
            <p class="form-subsection-heading">{{ 'patient.type_hard_palate' | translate }}</p>
            <div class="form-columns-1-1">
              <div>
                <div class="form-row">
                  <stx-radio-group
                    [formMode]="formMode"
                    layoutDirection="column"
                    controlName="leftHardCleftPalate"
                    label="patient.left"
                    [options]="dictionaries.cleftPalateOptions"
                  ></stx-radio-group>
                </div>
              </div>
              <div>
                <div class="form-row">
                  <stx-radio-group
                    [formMode]="formMode"
                    layoutDirection="column"
                    controlName="rightHardCleftPalate"
                    label="patient.right"
                    [options]="dictionaries.cleftPalateOptions"
                  ></stx-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p class="form-subsection-heading">{{ 'patient.type_soft_palate' | translate }}</p>
            <div class="form-row">
              <stx-radio-group
                [formMode]="formMode"
                layoutDirection="column"
                controlName="softCleftPalate"
                [label]="isMobileScreenSize ? '' : '&nbsp;'"
                [options]="dictionaries.cleftPalateOptions"
              ></stx-radio-group>
            </div>
          </div>
        </div>
      </div>
      <hr class="form-subsection-separator" />
      <div class="form-row">
        <stx-textarea [formMode]="formMode" controlName="comment" label="patient.comment"></stx-textarea>
      </div>
      <hr class="form-section-separator" />
      <div class="form-row">
        <stx-radio-group
          [formMode]="formMode"
          layoutDirection="row"
          controlName="otherCleft"
          label="patient.other_cleft"
          [options]="dictionaries.yesNoDontKnow"
        ></stx-radio-group>
      </div>
      <div class="form-row">
        <stx-checkbox-group
          [formMode]="formMode"
          [layoutDirection]="isMobileScreenSize ? '3cols' : '4cols'"
          label="patient.abnormalities"
          [options]="dictionaries.abnormalities"
        ></stx-checkbox-group>
      </div>
      <div class="form-row">
        <stx-radio-group
          [formMode]="formMode"
          layoutDirection="row"
          controlName="allergies"
          label="patient.allergies"
          [options]="dictionaries.yesNoDontKnow"
        ></stx-radio-group>
      </div>
      <div class="form-row">
        <stx-input [formMode]="formMode" controlName="otherAllergies" label="patient_other_allergies"></stx-input>
        <stx-input [formMode]="formMode" controlName="medAllergies" label="patient_med_allergies"></stx-input>
        <stx-input [formMode]="formMode" controlName="otherProblems" label="patient_other_problems"></stx-input>
      </div>
    </stx-form-panel>
  </stx-expandable-form-section>

  <stx-action-buttons
    *ngIf="!print"
    [asyncSecondaryAction]="onSaveButtonClicked()"
    [asyncPrimaryAction]="onSubmitButtonClicked()"
  ></stx-action-buttons>
</form>
