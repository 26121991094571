import { MaintenanceService } from '@/src/app/core/maintenance/maintenance.service';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'stx-maintenance-page',
  templateUrl: './maintenance-page.component.html'
})
export class MaintenancePageComponent extends BaseComponent implements OnInit, OnDestroy {
  private intervalHandle: number;
  get message() {
    return this.maintenanceService.message;
  }
  get isTranslated() {
    return this.maintenanceService.hasTranslationKey;
  }
  constructor(private readonly maintenanceService: MaintenanceService) {
    super();
  }

  ngOnInit(): void {
    this.intervalHandle = this.maintenanceService.pollCurrentUserAndNavigateOnSuccess(this.subSink);
  }

  ngOnDestroy(): void {
    window.clearInterval(this.intervalHandle);
  }
}
