import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';

import { BaseComponent } from '@shared/components/base-component/base.component';
import { CheckboxGroupFilterComponentModule } from '@src/app/features/reports/components/report-filters/components/checkbox-group-filter/checkbox-group-filter.component';
import {
  ReportDictionaryTreeItem,
  ReportDictionaryTreeSubsection,
  ReportFilterChange,
  ReportFilterChangePayload,
  ReportFilterName
} from '@src/app/features/reports/components/report-filters/models/report-filters.model';
import { ReportFiltersHelperService } from '@src/app/features/reports/components/report-filters/services/report-filters-helper.service';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-checkbox-tree-filter',
  templateUrl: './checkbox-tree-filter.component.html'
})
export class CheckboxTreeFilterComponent extends BaseComponent implements OnInit {
  @Input() dictionariesTree: ReportDictionaryTreeItem[];
  @Input() formGroupReference: UntypedFormGroup;
  @Input() rootOptionParamName: ReportFilterName;
  @Input() makeSecondSubSectionExpand: boolean;
  @Input() makeSubSectionExpand: boolean;
  @Output() filterChange = new EventEmitter<ReportFilterChange>();

  rootFormGroup: UntypedFormGroup;
  childrenFormGroup: UntypedFormGroup;
  showSecondSubSection: boolean;
  subSectionsVisibilityState: boolean[] = [];
  checkboxesMap = new Map<string, string[]>();

  constructor(private reportFiltersHelperService: ReportFiltersHelperService) {
    super();
  }

  ngOnInit(): void {
    this.initializeCheckboxesMap();
    this.rootFormGroup = this.formGroupReference.get('rootOptions') as UntypedFormGroup;
    this.childrenFormGroup = this.formGroupReference.get('childrenOptions') as UntypedFormGroup;
    this.emitFilterModel();
    this.watchFilterModel();
    this.watchFormGroupValuesChanges();
    this.showSecondSubSection = false;
  }

  private watchFormGroupValuesChanges() {
    this.toggleRootBasedOnChildren();
    this.uncheckChildrenWhenRootIsUnselected();
  }

  private toggleRootBasedOnChildren() {
    this.checkboxesMap.forEach((children, root) => {
      this.subSink.sink = this.childrenFormGroup.valueChanges.subscribe(() => {
        if (!!children && !this.anyChildrenFromGroupControlsAreSelected(children)) {
          this.rootFormGroup.get(root).reset(false, { emitEvent: false });
        } else if (!this.rootFormGroup.get(root).value) {
          this.rootFormGroup.get(root).setValue(true, { emitEvent: false });
        }
      });
    });
  }

  private uncheckChildrenWhenRootIsUnselected() {
    this.checkboxesMap.forEach((children, root) => {
      this.subSink.sink = this.rootFormGroup.get(root).valueChanges.subscribe(rootValue => {
        if (!rootValue) {
          this.checkboxesMap.get(root)?.forEach(x => {
            this.childrenFormGroup.get(x).reset(false, { emitEvent: false });
          });
        }
      });
    });
  }

  private anyChildrenFromGroupControlsAreSelected(children: string[]) {
    return (
      children
        .map(child => Object.values(this.childrenFormGroup.get(child).value))
        .reduce((acc, val) => acc.concat(val), [])
        .filter(x => !!x).length > 0
    );
  }

  private emitFilterModel() {
    const rootOptionsModel = this.getSelectedRootOptions();
    const childrenOptionsModel = this.getSelectedChildrenOptions();
    const payload: ReportFilterChangePayload = Object.assign({}, rootOptionsModel, childrenOptionsModel);

    this.filterChange.emit({
      filterName: this.rootOptionParamName,
      payload
    });
  }

  private watchFilterModel() {
    this.subSink.sink = this.formGroupReference.valueChanges.subscribe(() => {
      this.emitFilterModel();
    });
  }

  private getSelectedRootOptions(): Record<string, string[]> {
    const selectedOptions = this.reportFiltersHelperService.getSelectedCheckboxesFromGroup(this.rootFormGroup);

    return { [this.rootOptionParamName]: selectedOptions };
  }

  private getSelectedChildrenOptions(): Record<string, string[]> {
    return Object.entries(this.childrenFormGroup.controls)
      .map(([groupName, group]) => {
        const selectedOptions = this.reportFiltersHelperService.getSelectedCheckboxesFromGroup(group as UntypedFormGroup);

        return { [groupName]: selectedOptions };
      })
      .reduce((allChildren, selectedChild) => {
        return Object.assign({}, allChildren, selectedChild);
      }, {});
  }

  toggleVisibility(index: number) {
    if (this.makeSecondSubSectionExpand) {
      this.showSecondSubSection = !this.showSecondSubSection;
    }
    if (this.makeSubSectionExpand) {
      this.subSectionsVisibilityState[index] = !this.subSectionsVisibilityState[index];
    }
  }

  showSubsection(rootOption: ReportDictionaryTreeItem, subSection: ReportDictionaryTreeSubsection): boolean {
    return !this.makeSecondSubSectionExpand || rootOption.subSections[1].name !== subSection.name || this.showSecondSubSection;
  }

  private initializeCheckboxesMap(): void {
    this.dictionariesTree.forEach(dictionaryEntry =>
      this.checkboxesMap.set(
        dictionaryEntry.value,
        dictionaryEntry.subSections?.map(subsection => subsection.name)
      )
    );
  }
}

@NgModule({
  declarations: [CheckboxTreeFilterComponent],
  exports: [CheckboxTreeFilterComponent],
  imports: [
    CheckboxGroupFilterComponentModule,
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    MatDividerModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    FlexModule
  ]
})
export class CheckboxTreeFilterComponentModule {}
