import { Component, Input } from '@angular/core';

export interface ListElement {
  labelKey?: string;
  value?: any;
}

@Component({
  selector: 'stx-details-value-list',
  templateUrl: './details-value-list.component.html'
})
export class DetailsValueListComponent {
  @Input()
  headerKey?: string;

  @Input()
  elements: ListElement[] = [];
}
