import { transition, trigger, useAnimation } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { TIMINGS } from '@core/animations/constant.animations';
import { slideDown, slideUp } from '@core/animations/slide.animations';
import { TranslateModule } from '@ngx-translate/core';
import {
  CommonReportDictionaryItem,
  PeriodPresetsChangePayload,
  PeriodPresetsGroupModel,
  PeriodTypeChangePayload,
  ReportFilterChange,
  ReportPeriod,
  ReportPeriodGroup,
  ReportPeriodQuarterGroup,
  ReportType
} from 'src/app/features/reports/components/report-filters/models/report-filters.model';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { FormModule } from 'src/app/shared/components/form/form.module';
import { ReportDictionariesService } from '../../services/report-dictionaries.service';
import { ReportFiltersHelperService } from '../../services/report-filters-helper.service';
import { ReportPeriodsService } from '../../services/report-periods.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-period-filter',
  templateUrl: './period-filter.component.html',
  styleUrls: ['./period-filter.component.scss'],
  animations: [
    trigger('periodRanges', [
      transition(
        'void => *',
        useAnimation(slideDown, {
          params: {
            timing: TIMINGS.FAST
          }
        })
      ),
      transition(
        '* => void',
        useAnimation(slideUp, {
          params: {
            timing: TIMINGS.FAST
          }
        })
      )
    ])
  ]
})
export class PeriodFilterComponent extends BaseComponent implements OnInit {
  @Input() allowPresets = false;
  @Input() formGroupReference: UntypedFormGroup;
  @Input() reportType: ReportType;

  @Output() periodTypeChange = new EventEmitter<ReportFilterChange<PeriodTypeChangePayload>>();
  @Output() periodPresetsChange = new EventEmitter<ReportFilterChange<PeriodPresetsChangePayload>>();

  readonly sinceInceptionValue = 'SI';
  periodPresetsVisible = false;

  periodTypeControlName = 'periodType';
  periodTypeControl: AbstractControl;
  periodPresetsGroup: UntypedFormGroup;
  calendarYearsGroup: UntypedFormGroup;
  fiscalYearsGroup: UntypedFormGroup;
  quartersGroup: UntypedFormGroup;
  monthsGroup: UntypedFormGroup;

  periodTypes: CommonReportDictionaryItem[] = [];

  calendarYears: ReportPeriod[] = [];
  fiscalYears: ReportPeriod[] = [];
  months: ReportPeriodGroup[] = [];
  monthsByQuarter: ReportPeriodQuarterGroup[] = [];
  quarters: ReportPeriodGroup[] = [];

  constructor(
    private reportPeriodsService: ReportPeriodsService,
    private reportDictionariesService: ReportDictionariesService,
    private reportFiltersHelperService: ReportFiltersHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.periodTypes = this.reportDictionariesService.dictionaries.periodTypes;
    this.setFormReferences();
    this.watchPeriodTypeChanges();
    this.setDefaultPeriodType();

    if (this.allowPresets) {
      this.preparePresets();
      this.watchPresetsChanges();
    }
  }

  private setDefaultPeriodType() {
    this.periodTypeControl.setValue(this.sinceInceptionValue);
  }

  private checkPeriodsPickerVisibility(selectedPeriod: string) {
    this.periodPresetsVisible = this.allowPresets && this.reportDictionariesService.isPeriodTypePreset(selectedPeriod);

    if (!this.periodPresetsVisible && this.periodPresetsGroup) {
      this.periodPresetsGroup.reset();
    }
  }

  private preparePresets() {
    const months = this.reportPeriodsService.getMonths();
    const quarters = this.reportPeriodsService.getQuarters();

    this.calendarYears = this.reportPeriodsService.getCalendarYears();
    this.fiscalYears = this.reportPeriodsService.getFiscalYears();
    this.months = this.reportPeriodsService.groupByYear(months);
    this.monthsByQuarter = this.reportPeriodsService.groupMonthsByQuarter(this.months);
    this.quarters = this.reportPeriodsService.groupByYear(quarters);
  }

  private setFormReferences() {
    this.periodTypeControl = this.formGroupReference.get(this.periodTypeControlName);
    this.periodPresetsGroup = this.formGroupReference.get('periodPresets') as UntypedFormGroup;

    if (this.periodPresetsGroup) {
      this.calendarYearsGroup = this.periodPresetsGroup.get('calendarYears') as UntypedFormGroup;
      this.fiscalYearsGroup = this.periodPresetsGroup.get('fiscalYears') as UntypedFormGroup;
      this.quartersGroup = this.periodPresetsGroup.get('quarters') as UntypedFormGroup;
      this.monthsGroup = this.periodPresetsGroup.get('months') as UntypedFormGroup;
    }
  }

  private watchPeriodTypeChanges() {
    this.subSink.sink = this.periodTypeControl.valueChanges.subscribe((periodType: string) => {
      this.checkPeriodsPickerVisibility(periodType);
      this.periodTypeChange.emit({
        filterName: 'periodType',
        payload: {
          periodType: [periodType]
        }
      });
    });
  }

  private watchPresetsChanges() {
    this.subSink.sink = this.periodPresetsGroup.valueChanges.subscribe((group: PeriodPresetsGroupModel) => {
      this.periodPresetsChange.emit({
        filterName: 'periodPresets',
        payload: {
          calendarYears: this.reportFiltersHelperService.getSelectedCheckboxOptions(group.calendarYears),
          fiscalYears: this.reportFiltersHelperService.getSelectedCheckboxOptions(group.fiscalYears),
          calendarMonths: this.reportFiltersHelperService.getSelectedCheckboxOptions(group.months),
          fiscalQuarters: this.reportFiltersHelperService.getSelectedCheckboxOptions(group.quarters)
        }
      });
    });
  }
}

@NgModule({
  declarations: [PeriodFilterComponent],
  exports: [PeriodFilterComponent],
  imports: [CommonModule, ExtendedModule, FlexModule, FormModule, MatDividerModule, TranslateModule]
})
export class PeriodFilterComponentModule {}
