<stx-generic-wide-page>
  <stx-report-header>
    {{ 'reports.surgical_treatments' | translate }}
  </stx-report-header>
  <stx-report-filters [reportConfig]="reportConfig" (applyFilters)="applyFilters($event)"></stx-report-filters>
  <stx-surgical-treatment-report-table
    *ngIf="reportService.initialized$ | async"
    [reportConfig]="reportService.model"
  ></stx-surgical-treatment-report-table>
</stx-generic-wide-page>
