import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { GeoEnum } from 'src/app/features/search/enums/geo.enum';
import { SearchService } from 'src/app/features/search/search.service';
import { SearchBaseComponent } from '../../../search/components/search-base/search-base.component';
import { getIdsFromItemFormControl } from '../../../search/search.utils';
import { SearchFilterService } from '../../../search/services/search-filter.service';
import { PractitionerSearchModel } from '../../models/practitioner-search.model';
import { OrgTreeEnum } from '@src/app/features/search/enums/org-tree.enum';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  selector: 'stx-practitioner-search',
  templateUrl: './practitioner-search.component.html'
})
export class PractitionerSearchComponent extends SearchBaseComponent implements OnInit {
  GeoEnum = GeoEnum;
  OrgTreeEnum = OrgTreeEnum;
  constructor(
    private searchService: SearchService,
    private cd: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private readonly wsHelperService: WsHelperService,
    searchFilterService: SearchFilterService
  ) {
    super(searchFilterService);
  }

  ngOnInit() {
    this.initializePractitionerSearchForm();
    this.getFilters();
  }

  protected getSearchFilters(): PractitionerSearchModel {
    return Object.assign({}, this.searchFormGroup.value, this.getOrgTreeRequestParams(), this.getGeographyTreeRequestParams());
  }

  private getFilters(): void {
    this.wsHelperService.callWithSpinner(this.searchService.getOrgTreeFilters()).subscribe(filters => {
      this.orgTreeFiltersData = filters;
      this.cd.detectChanges();
    });
  }

  private initializePractitionerSearchForm(): void {
    this.searchFormGroup = this.formBuilder.group({
      active: [false],
      inactive: [false],
      surgeon: [false],
      orthodontist: [false],
      speechProvider: [false],
      nutritionist: [false],
      anesthesiologist: [false],
      dentalProvider: [false],
      entProvider: [false],
      hearingSpecialist: [false],
      psychosocialProvider: [false],
      partners: [[]],
      countries: [[]],
      crms: [[]],
      crmRegions: [[]]
    });
  }

  private getOrgTreeRequestParams(): Record<string, string[]> {
    const keysToMap = ['partners'];

    return getIdsFromItemFormControl(this.searchFormGroup, keysToMap);
  }

  private getGeographyTreeRequestParams(): Record<string, string[]> {
    const keysToMap = ['countries', 'crms', 'crmRegions'];

    return getIdsFromItemFormControl(this.searchFormGroup, keysToMap);
  }
}
