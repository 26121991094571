<div class="page-section">
  <div class="problem-container flex-row-container wrap">
    <stx-simple-readonly-field [value]="problemLabels"></stx-simple-readonly-field>
  </div>
  <div class="m-t-20 flex-column-container">
    <div class="flex-row-container gap-10">
      <div class="flex-column-container">
        <stx-simple-readonly-field
          [value]="[patient.lastName, patient.middleName, patient.firstName, patient.recordNumberId, patient.recordNumberIdLoc]"
        ></stx-simple-readonly-field>
        <div>
          <ng-container *ngIf="patientLocalNameIsDifferent() || patientLocalRecordNoIsDifferent()">
            <stx-simple-readonly-field
              [value]="patientLocalNameIsDifferent() ? [patient.firstNameLoc, patient.middleNameLoc, patient.lastNameLoc] : []"
            ></stx-simple-readonly-field>
          </ng-container>
        </div>
      </div>
      <div>
        <a (click)="$event.stopPropagation()" routerLink="{{ printPatientRoute }}" target="_blank" class="m-r-24 patient-block-icon">
          <stx-patient-record-icon></stx-patient-record-icon>
        </a>
      </div>
    </div>
    <hr class="form-subsection-separator" />
    <stx-simple-readonly-field
      [value]="[
        ReadonlyFormUtils.getRadioBtnValue(genders, patient.gender) | translate,
        patient.dateOfBirth,
        patient.guardianLastName + ' ' + patient.guardianFirstName + ' ',
        ReadonlyFormUtils.getRadioBtnValue(guardianRelationships, patient.guardianRelationship) | translate
      ]"
    ></stx-simple-readonly-field>
    <hr class="form-subsection-separator" />
    <stx-simple-readonly-field
      [value]="[surgery?.treatmentCenter?.name, tcLocalNameIsDifferent() ? surgery?.treatmentCenter?.nameLoc : '', countryName()?.name]"
    ></stx-simple-readonly-field>
    <hr class="form-subsection-separator" />
    <div class="flex-column-container gap-10">
      <div *ngIf="treatmentStatus" class="flex-row-container gap-10 treatment-status align-middle wrap">
        <mat-icon>{{ treatmentStatus.icon }}</mat-icon>
        <a
          *ngFor="let type of operationTypes"
          class="flex-row-container flex-center"
          [routerLink]="surgeryRoute(surgery.id)"
          [queryParams]="{ patientId: patient.id }"
        >
          {{ type | translate }}
        </a>
        <div>{{ surgery.id }}</div>
        <stx-treatment-date
          [operationDate]="surgery.operationDate | date : 'yyyy-MM-dd'"
          [submitDate]="surgery.submitted | date : 'yyyy-MM-dd'"
        ></stx-treatment-date>
      </div>
      <div class="flex-row-container wrap">
        <ng-container *ngFor="let type of repairTypes; last as isLast">
          <div class="repair-type">
            {{ type | translate }}
            <ng-container *ngIf="!isLast">
              <stx-value-separator [value]="separator"></stx-value-separator>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <stx-readonly-media-group *ngIf="!!photos" [mediaSets]="photos" [columns]="true"></stx-readonly-media-group>
    </div>
    <hr *ngIf="includesActions" class="form-subsection-separator" />
    <div [formGroup]="actionFormGroup" *ngIf="includesActions">
      <stx-checkbox
        [name]="'suspicious_records.reject_as_duplicate'"
        [controlName]="'isDuplicate'"
        [formGroup]="actionFormGroup"
      ></stx-checkbox>
      <div class="m-t-20">
        <stx-radio-group
          *ngIf="isPotentialMatch"
          [layoutDirection]="'row'"
          [controlName]="'isSamePatient'"
          [formGroup]="actionFormGroup"
          [options]="patientOptions"
          radioGroupId="{{ 'isSamePatient_' + index }}"
        ></stx-radio-group>
      </div>
    </div>
  </div>
</div>
