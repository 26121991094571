import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { TreatmentDateType } from '@/src/app/shared/components/treatment/components/treatment-record-date/treatment-record-date-type.enum';
import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: ''
})
export abstract class StatusBarBaseComponent extends BaseComponent {
  readonly PermissionEnum = PermissionEnum;
  readonly TreatmentDateType = TreatmentDateType;
  @Output() unlockClicked = new EventEmitter<void>();
  @Output() deleteClicked = new EventEmitter<void>();
  constructor(
    public dialog: MatDialog,
    protected readonly translateService: TranslateService,
    protected readonly cd: ChangeDetectorRef,
    protected readonly patientService: PatientService
  ) {
    super();
  }
}
