import { Component, Input } from '@angular/core';
import { SurgicalStatistics } from '@shared/modules/statistics/statistics.model';
import { getCurrentMonthString, getCurrentYear, getToday } from 'src/app/utils/date.utils';

@Component({
  selector: 'stx-current-month-block',
  templateUrl: './current-month-block.component.html',
  styleUrls: ['./current-month-block.component.scss']
})
export class CurrentMonthBlockComponent {
  @Input() surgicalStats: SurgicalStatistics;

  get currentMonthYear(): string {
    return `${getCurrentMonthString()} ${getCurrentYear()}`;
  }

  get numberOfDaysLeftCurrentMonth(): number {
    const today = getToday();
    const daysInCurrentMonth = today.daysInMonth();
    const dayOfCurrentMonth = today.date();
    return daysInCurrentMonth - dayOfCurrentMonth;
  }
}
