import { Injectable } from '@angular/core';
import { AllReportTypes, ReportDownloadTypes, ReportType } from '../models/report-filters.model';

@Injectable({ providedIn: 'root' })
export class ReportApiUrlService {
  private readonly baseStaticDictionariesUrl = '/static-dictionaries';
  private readonly baseReportEndpointMap: Partial<Record<ReportType, string>> = {
    SURGICAL_TREATMENTS: '/surgical-treatments',
    SURGICAL_TREATMENTS_COMPACT: '/surgical-treatment-compact',
    SURGICAL_FOLLOW_UP_VISITS: '/followup',
    ORTHODONTIC_TREATMENTS: '/orthodontic',
    ORTHODONTIC_TREATMENTS_COMPACT: '/orthodontic-compact',
    ORTHOGNATHIC_TREATMENTS: '/orthognathic-treatments',
    SURGERY_STATS: '/surgical/surgery-stats',
    ORTHODONTIC_STATS: '/orthodontic-stats',
    ORTHODONTIC_STATS_INDIA: '/orthodontic-stats',
    SPEECH_TREATMENTS: '/speech-treatment',
    SPEECH_ASSESSMENTS: '/speech-assessment',
    PARTNER_SURGERY_COUNTS: '/partner-surgery-counts',
    PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT: '/partner-surgery-counts/foundation-connect',
    SPEECH_STATS: '/speech-stats',
    NUTRITION_STATS: '/nutrition/nutrition-stats',

    // TODO#30210: update url when api ready
    SURGICAL_QA: '/surgical-qa',
    QA_ORTHO_CASES: '/qa-ortho-cases',
    QA_REVIEWER_RELIABILITY: '/qa/reviewer-reliability/records',
    PHOTO_REPORT: '/photo-report',
    NUTRITION_FORMS: '/nutrition'
  };
  private readonly downloadTypeEndpoints: Record<ReportDownloadTypes, string> = {
    [ReportDownloadTypes.EXCEL]: '',
    [ReportDownloadTypes.EXCEL_ADMIN]: '/admin',
    [ReportDownloadTypes.EXCEL_ADMIN_ID]: '/adminids',
    [ReportDownloadTypes.EXCEL_WITH_AGE_GROUPS]: '',
    [ReportDownloadTypes.EXCEL_ADMIN_WITH_AGE_GROUPS]: '/admin',
    [ReportDownloadTypes.EXCEL_ADMIN_ID_WITH_AGE_GROUPS]: '/adminids'
  };
  private readonly reportEndpointMap: Partial<Record<ReportType, string>> = {
    SURGICAL_TREATMENTS: `${this.baseReportEndpointMap.SURGICAL_TREATMENTS}/filter`,
    ORTHOGNATHIC_TREATMENTS: `${this.baseReportEndpointMap.ORTHOGNATHIC_TREATMENTS}/filter`,
    ORTHODONTIC_TREATMENTS: `${this.baseReportEndpointMap.ORTHODONTIC_TREATMENTS}/filter`,
    ORTHODONTIC_TREATMENTS_COMPACT: `${this.baseReportEndpointMap.ORTHODONTIC_TREATMENTS_COMPACT}/filter`,
    SURGICAL_TREATMENTS_COMPACT: `${this.baseReportEndpointMap.SURGICAL_TREATMENTS_COMPACT}`,
    SURGICAL_FOLLOW_UP_VISITS: `${this.baseReportEndpointMap.SURGICAL_FOLLOW_UP_VISITS}/filter`,
    SURGERY_STATS: `${this.baseReportEndpointMap.SURGERY_STATS}`,
    ORTHODONTIC_STATS: `${this.baseReportEndpointMap.ORTHODONTIC_STATS}`,
    ORTHODONTIC_STATS_INDIA: `${this.baseReportEndpointMap.ORTHODONTIC_STATS}`,
    SPEECH_TREATMENTS: `${this.baseReportEndpointMap.SPEECH_TREATMENTS}/filter`,
    SPEECH_ASSESSMENTS: `${this.baseReportEndpointMap.SPEECH_ASSESSMENTS}/filter`,
    PHOTO_REPORT: `${this.baseReportEndpointMap.PHOTO_REPORT}/filter`,
    SPEECH_STATS: `${this.baseReportEndpointMap.SPEECH_STATS}`,
    NUTRITION_STATS: `${this.baseReportEndpointMap.NUTRITION_STATS}`,

    // TODO: update url when api ready
    SURGICAL_QA: `${this.baseReportEndpointMap.SURGICAL_QA}`,
    QA_ORTHO_CASES: `${this.baseReportEndpointMap.QA_ORTHO_CASES}`,
    NUTRITION_FORMS: `${this.baseReportEndpointMap.NUTRITION_FORMS}/filter`
  };
  private readonly downloadReportEndpointMap: Partial<Record<ReportType, { [key in ReportDownloadTypes]: string }>> = {
    SURGICAL_TREATMENTS: this.getDefaultDownloadEndpoints('SURGICAL_TREATMENTS'),
    ORTHOGNATHIC_TREATMENTS: this.getDefaultDownloadEndpoints('ORTHOGNATHIC_TREATMENTS'),
    ORTHODONTIC_TREATMENTS: this.getDefaultDownloadEndpoints('ORTHODONTIC_TREATMENTS'),
    QA_ORTHO_CASES: this.getDefaultDownloadEndpoints('QA_ORTHO_CASES'),
    QA_REVIEWER_RELIABILITY: this.getDefaultDownloadEndpoints('QA_REVIEWER_RELIABILITY'),
    SPEECH_TREATMENTS: this.getDefaultDownloadEndpoints('SPEECH_TREATMENTS'),
    SPEECH_ASSESSMENTS: this.getDefaultDownloadEndpoints('SPEECH_ASSESSMENTS'),
    SURGICAL_FOLLOW_UP_VISITS: this.getDefaultDownloadEndpoints('SURGICAL_FOLLOW_UP_VISITS'),
    ORTHODONTIC_STATS: this.getBaseDownloadEndpoints('ORTHODONTIC_STATS'),
    ORTHODONTIC_STATS_INDIA: this.getBaseDownloadEndpoints('ORTHODONTIC_STATS'),
    SPEECH_STATS: this.getBaseDownloadEndpoints('SPEECH_STATS'),
    SURGERY_STATS: this.getBaseDownloadEndpoints('SURGERY_STATS'),
    PARTNER_SURGERY_COUNTS: this.getSurgeryCountsDownloadEndpoints('PARTNER_SURGERY_COUNTS'),
    PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT: this.getSurgeryCountsDownloadEndpoints('PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT'),
    NUTRITION_STATS: this.getBaseDownloadEndpoints('NUTRITION_STATS'),
    NUTRITION_FORMS: this.getDefaultDownloadEndpoints('NUTRITION_FORMS')
  };
  private readonly dictionariesReportEndpointMap: Partial<Record<ReportType, string>> = {
    SURGERY_STATS: `/surgical/surgery-stats${this.baseStaticDictionariesUrl}`,
    SPEECH_STATS: `${this.baseReportEndpointMap.SPEECH_STATS}${this.baseStaticDictionariesUrl}`,
    SPEECH_TREATMENTS: `/speech-treatment${this.baseStaticDictionariesUrl}`,
    SPEECH_ASSESSMENTS: `/speech-assessment${this.baseStaticDictionariesUrl}`,
    ORTHODONTIC_STATS: `/orthodontic-stats${this.baseStaticDictionariesUrl}`,
    ORTHODONTIC_STATS_INDIA: `/orthodontic-stats${this.baseStaticDictionariesUrl}?region=INDIA`,
    ORTHODONTIC_TREATMENTS: `${this.baseReportEndpointMap.ORTHODONTIC_TREATMENTS}${this.baseStaticDictionariesUrl}`,
    ORTHODONTIC_TREATMENTS_COMPACT: `${this.baseReportEndpointMap.ORTHODONTIC_TREATMENTS_COMPACT}${this.baseStaticDictionariesUrl}`,
    PHOTO_REPORT: `${this.baseReportEndpointMap.PHOTO_REPORT}${this.baseStaticDictionariesUrl}`,
    SURGICAL_FOLLOW_UP_VISITS: `${this.baseReportEndpointMap.SURGICAL_FOLLOW_UP_VISITS}${this.baseStaticDictionariesUrl}`,
    NUTRITION_FORMS: `${this.baseReportEndpointMap.NUTRITION_FORMS}${this.baseStaticDictionariesUrl}`,
    NUTRITION_STATS: `${this.baseReportEndpointMap.NUTRITION_STATS}${this.baseStaticDictionariesUrl}`,
    SURGICAL_TREATMENTS: `${this.baseReportEndpointMap.SURGICAL_TREATMENTS}${this.baseStaticDictionariesUrl}`
  };

  getListApiUrl(baseUrl: string, reportType: ReportType): string {
    return `${baseUrl}${this.reportEndpointMap[reportType]}`;
  }

  getDownloadApiUrl(baseUrl: string, reportType: ReportType, downloadOptionType: ReportDownloadTypes): string {
    return baseUrl + this.downloadReportEndpointMap[reportType][downloadOptionType];
  }

  getStaticDictionariesUrl(baseUrl: string, reportType: ReportType): string {
    // not all reports have own static dictionaries url
    const defaultStaticDictionariesUrl = baseUrl + this.baseStaticDictionariesUrl;

    if (this.dictionariesReportEndpointMap[reportType]) {
      return baseUrl + this.dictionariesReportEndpointMap[reportType];
    }

    return defaultStaticDictionariesUrl;
  }

  private getDefaultDownloadEndpoints(reportType: ReportType): { [key in ReportDownloadTypes]: string } {
    return {
      [ReportDownloadTypes.EXCEL]: `${this.baseReportEndpointMap[reportType]}${this.downloadTypeEndpoints.excel}/filter`,
      [ReportDownloadTypes.EXCEL_ADMIN]: `${this.baseReportEndpointMap[reportType]}${this.downloadTypeEndpoints.excelAdmin}/filter`,
      [ReportDownloadTypes.EXCEL_ADMIN_ID]: `${this.baseReportEndpointMap[reportType]}${this.downloadTypeEndpoints.excelAdminId}/filter`,
      [ReportDownloadTypes.EXCEL_WITH_AGE_GROUPS]: `${this.baseReportEndpointMap[reportType]}${this.downloadTypeEndpoints.excel}/filter`,
      [ReportDownloadTypes.EXCEL_ADMIN_WITH_AGE_GROUPS]: `${this.baseReportEndpointMap[reportType]}${this.downloadTypeEndpoints.excelAdmin}/filter`,
      [ReportDownloadTypes.EXCEL_ADMIN_ID_WITH_AGE_GROUPS]: `${this.baseReportEndpointMap[reportType]}${this.downloadTypeEndpoints.excelAdminId}/filter`
    };
  }

  private getBaseDownloadEndpoints(reportType: ReportType): { [key in ReportDownloadTypes]: string } {
    return {
      [ReportDownloadTypes.EXCEL]: `${this.baseReportEndpointMap[reportType]}${this.downloadTypeEndpoints.excel}`,
      [ReportDownloadTypes.EXCEL_ADMIN]: `${this.baseReportEndpointMap[reportType]}${this.downloadTypeEndpoints.excelAdmin}`,
      [ReportDownloadTypes.EXCEL_ADMIN_ID]: `${this.baseReportEndpointMap[reportType]}${this.downloadTypeEndpoints.excelAdminId}`,
      [ReportDownloadTypes.EXCEL_WITH_AGE_GROUPS]: `${this.baseReportEndpointMap[reportType]}${this.downloadTypeEndpoints.excel}`,
      [ReportDownloadTypes.EXCEL_ADMIN_WITH_AGE_GROUPS]: `${this.baseReportEndpointMap[reportType]}${this.downloadTypeEndpoints.excelAdmin}`,
      [ReportDownloadTypes.EXCEL_ADMIN_ID_WITH_AGE_GROUPS]: `${this.baseReportEndpointMap[reportType]}${this.downloadTypeEndpoints.excelAdminId}`
    };
  }

  private getSurgeryCountsDownloadEndpoints(
    surgeryCountsType: typeof AllReportTypes.PARTNER_SURGERY_COUNTS | typeof AllReportTypes.PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT
  ): { [key in ReportDownloadTypes]: string } {
    return {
      [ReportDownloadTypes.EXCEL]: `${this.baseReportEndpointMap[surgeryCountsType]}${this.downloadTypeEndpoints.excel}`,
      [ReportDownloadTypes.EXCEL_ADMIN]: `${this.baseReportEndpointMap[surgeryCountsType]}${this.downloadTypeEndpoints.excelAdmin}`,
      [ReportDownloadTypes.EXCEL_ADMIN_ID]: `${this.baseReportEndpointMap[surgeryCountsType]}${this.downloadTypeEndpoints.excelAdminId}`,
      [ReportDownloadTypes.EXCEL_WITH_AGE_GROUPS]: `${this.baseReportEndpointMap[surgeryCountsType]}${this.downloadTypeEndpoints.excel}`,
      [ReportDownloadTypes.EXCEL_ADMIN_WITH_AGE_GROUPS]: `${this.baseReportEndpointMap[surgeryCountsType]}${this.downloadTypeEndpoints.excelAdmin}`,
      [ReportDownloadTypes.EXCEL_ADMIN_ID_WITH_AGE_GROUPS]: `${this.baseReportEndpointMap[surgeryCountsType]}${this.downloadTypeEndpoints.excelAdminId}`
    };
  }
}
