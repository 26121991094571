<stx-generic-page>
  <stx-page-header [name]="'practitioner.all_qa_results' | translate"></stx-page-header>
  <div class="data-tile-container fit-content">
    <div class="data-tile-row">
      <div class="data-tile">
        <div class="data-tile-content">
          <ng-container *ngIf="practitionerDetails">
            <stx-simple-details-value [href]="practitionerPage" [value]="practitionerDetails.name"></stx-simple-details-value>
            <stx-simple-details-value [value]="practitionerDetails.id"></stx-simple-details-value>
            <stx-simple-details-value [email]="practitionerDetails.email"></stx-simple-details-value>
            <div>
              <span class="small-text-dark-gray">{{ 'practitioner.number_of_surgeries' | translate }}:</span>
              {{ practitionerDetails.numberOfSurgeries }}
            </div>
            <div>
              <span class="small-text-dark-gray">{{ 'practitioner.activity_range' | translate }}:</span>
              {{ practitionerDetails.activityRangeFrom | date : 'yyyy-MM-dd' }} -
              {{ practitionerDetails.activityRangeTo | date : 'yyyy-MM-dd' }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="m-t-75"></div>
  <stx-expandable-row-table *ngIf="qaStagesForDisplay" [data]="qaStagesForDisplay" [columns]="qaTableColumns"></stx-expandable-row-table>
</stx-generic-page>
