export enum QaStageType {
  NEW = 'NEW',
  PERIODIC = 'PERIODIC',
  FURTHER_RESEARCH = 'FURTHER_RESEARCH',
  MONITOR = 'MONITOR',
  TRAIN = 'TRAIN',
  MONITOR_POST_TRAINING = 'MONITOR_POST_TRAINING',
  TERMINATION = 'TERMINATION'
}

export enum QaStageStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FAIL = 'FAIL',
  PASS = 'PASS',
  OVERRIDE = 'OVERRIDE',
  COMPLETED = 'COMPLETED',
  TERMINATE = 'TERMINATE',
  TRAINING_SCHEDULED = 'TRAINING_SCHEDULED',
  DISCONTINUED = 'DISCONTINUED'
}

export enum TreatmentGroup {
  SURGERY_LIP_NOSE_REVISION = 'SURGERY_LIP_NOSE_REVISION',
  SURGERY_LIP_UNILATERAL = 'SURGERY_LIP_UNILATERAL',
  SURGERY_LIP_BILATERAL = 'SURGERY_LIP_BILATERAL',
  SURGERY_ALVEOLAR_BONE_GRAFT = 'SURGERY_ALVEOLAR_BONE_GRAFT',
  SURGERY_PALATE = 'SURGERY_PALATE',
  SURGERY_PALATE_SECONDARY = 'SURGERY_PALATE_SECONDARY'
}

export enum OverrideQaStageMode {
  NEW_REVIEW_STAGE,
  ASSIGN_TRAINING_DATE
}
