import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-readonly-field',
  templateUrl: './readonly-field.component.html',
  styleUrls: ['./readonly-field.component.scss']
})
export class ReadonlyFieldComponent {
  @Input()
  label: string;
}
