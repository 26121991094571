import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input() relativeToParent: boolean;
  @Input() showSpinner: boolean;
  wrapperClass = 'wrapper';
  @Input() set useAbsolutePosition(value: boolean) {
    if (!value) {
      this.wrapperClass = 'relative-spinner-wrapper';
    } else {
      this.wrapperClass = 'wrapper';
    }
  }
}
