<stx-readonly-view-columns *ngIf="treatment">
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field label="treatment.admission_date" [value]="treatment.admissionDate"></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="treatment.operation_date"
      [value]="[treatment.operationDate, treatment.operationTime]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field label="treatment.discharge_date" [value]="treatment.dischargeDate"></stx-simple-readonly-field>
    <stx-simple-readonly-field label="treatment.followup_visit_date" [value]="treatment.followVisitDate"></stx-simple-readonly-field>
    <stx-simple-readonly-field label="age" [value]="treatment.ageMonth | ageYearMonth"></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="patient.weight"
      [value]="treatment.weight"
      [suffix]="'unit.weight' | translate"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field label="height" [value]="treatment.height" [suffix]="'unit.height' | translate"></stx-simple-readonly-field>
    <stx-readonly-field label="treatment_center.treatment_center">
      <stx-tc-link [treatmentCenter]="treatment.treatmentCenter"></stx-tc-link>
    </stx-readonly-field>
    <stx-simple-readonly-field label="status" [value]="treatmentStatusLabel | translate"> </stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="surgical_intervention.phil_health"
      [value]="'patient.pregnancy_compilation.yes' | if : treatment.philHealth | translate"
    >
    </stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="surgical_intervention.china_support"
      *ngIf="displayChinaSupportOptions"
      [value]="[
        treatment.chinaSupport | yesNoOrNull,
        'surgical_intervention.china_support_orphan' | if : treatment.chinaSupportOrphan | translate,
        'surgical_intervention.china_support_migrant' | if : treatment.chinaSupportMigrant | translate,
        'surgical_intervention.china_support_region_nofund' | if : treatment.chinaSupportRegionNofund | translate
      ]"
    >
    </stx-simple-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field label="practitioner.surgeon" [value]="practitionersNames"></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="practitioner.anesthesiologist"
      [value]="[treatment.anesthesiologist?.name, treatment.anesthesiologist?.nameLoc]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="surgical_intervention.anesth_name"
      [value]="[treatment.anesthesiologistName, treatment.anesthesiologistNameLoc]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="reports.anesthesia_method"
      [value]="treatment.anesthMethod | dict : 'anesthesiaMethod' | async | translate"
    ></stx-simple-readonly-field>
    <stx-readonly-field *ngIf="preAnesthesiaMedia" label="order_name.anesthesia_pre_operative">
      <a [href]="preAnesthesiaMedia.url" target="_blank">{{ preAnesthesiaMedia.createdAt | date : dateFormat }}</a>
    </stx-readonly-field>
    <stx-readonly-field *ngIf="intraAnesthesiaMedia" label="order_name.anesthesia_intra_operative">
      <a [href]="intraAnesthesiaMedia.url" target="_blank">{{ intraAnesthesiaMedia.createdAt | date : dateFormat }}</a>
    </stx-readonly-field>
    <stx-readonly-field *ngIf="postAnesthesiaMedia" label="order_name.anesthesia_post_operative">
      <a [href]="postAnesthesiaMedia.url" target="_blank">{{ postAnesthesiaMedia.createdAt | date : dateFormat }}</a>
    </stx-readonly-field>
    <stx-simple-readonly-field
      label="surgical_intervention.operation_type"
      [value]="[
        'surgery.primary_lip_nose_unilateral' | if : treatment.primaryLipNoseUnilateral | translate,
        'surgery.primary_lip_nose_bilateral' | if : treatment.primaryLipNoseBilateral | translate,
        'surgery.primary_cleft_palate' | if : treatment.primaryCleftPalate | translate,
        'treatment.surgery_type.fistula' | if : treatment.fistula | translate,
        'treatment.surgery_type.secondary_cleft_palate' | if : treatment.secondaryCleftPalate | translate,
        'surgical_intervention.velopharyngeal_disfunction' | if : treatment.velopharyngealDisfunction | translate,
        'surgical_qa_panel.surgical_intervention_type_lip_nose_revision' | if : treatment.lipNoseRevision | translate,
        'treatment.surgery_type.abg' | if : treatment.alveolarBoneGraft | translate,
        'treatment.surgery_type.other' | if : treatment.other > 0 | translate
      ]"
    >
    </stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="report.filters.repair_types.header"
      [value]="[
        treatment.primaryLipNoseUnilateralRepairType | dict : 'primaryLipNoseUnilateralType' | async | translate,
        treatment.primaryLipNoseBilateralRepairType | dict : 'primaryLipNoseBilateralType' | async | translate,
        treatment.primaryCleftPalateRepairType | dict : 'primaryCleftPalateType' | async | translate,
        treatment.fistulaRepairType | dict : 'fistulaRepairType' | async | translate,
        treatment.secondaryCleftPalateRepairType | dict : 'secondaryCleftPalate' | async | translate,
        treatment.velopharyngealDisfunctionRepairType | dict : 'velopharyngealDisfunctionType' | async | translate,
        treatment.lipNoseRevisionRepairType | dict : 'lipNoseRevisionType' | async | translate,
        treatment.alveolarBoneGraftRepairType | dict : 'alveolarBoneGraftType' | async | translate,
        treatment.repairComment
      ]"
    >
    </stx-simple-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-simple-readonly-field label="complications" [value]="treatment.complicationAny | yesNo"></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="treatment.complication.injury"
      [value]="[
        treatment.complicationInjury | yesNo,
        'surgical_intervention.complication_breathing_problems' | if : treatment.complicationBreathingProblems | translate,
        'treatment.complication.fistula' | if : treatment.complicationFistula | translate,
        'surgical_intervention.complication_dehiscence' | if : treatment.complicationDehiscence | translate,
        'surgical_intervention.complication_back_to_or' | if : treatment.complicationBackToOr | translate
      ]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="surgical_intervention.additional_comments"
      [value]="treatment.additionalComment"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field label="surgical_qa_panel.comments" [value]="treatment.otherComment"></stx-simple-readonly-field>
    <stx-readonly-field label="medical_forms.printable_forms.declaration" *ngIf="indiaDeclaration">
      <a [href]="indiaDeclaration.url" target="_blank">{{ indiaDeclaration.createdAt | date : dateFormat }}</a>
    </stx-readonly-field>
  </ng-container>
</stx-readonly-view-columns>
<stx-readonly-media-group [mediaSets]="photos" [columns]="true"></stx-readonly-media-group>
