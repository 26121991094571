import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { AuthService } from '../../../features/authentication/services/auth.service';
import { LanguageTempComponentModule } from '../language-switcher/components/language-temp/language-temp.component';
import { BaseNavComponent } from './base-nav.component';
import { MobileNavBarComponentModule } from './mobile-nav/mobile-nav.component';
import { NavBarComponentModule } from './nav-bar/nav-bar.component';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

@Component({
  selector: 'stx-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent extends BaseNavComponent {
  greaterThanSmall: boolean;

  constructor(
    public router: Router,
    public changeDetector: ChangeDetectorRef,
    public translateService: TranslateService,
    public authService: AuthService,
    public currentUserService: CurrentUserService,
    public wsHelperService: WsHelperService,
    private mediaObserver: MediaObserver
  ) {
    super(authService, currentUserService, router, changeDetector, translateService, wsHelperService);

    this.observeViewport();
  }

  private observeViewport() {
    this.subSink.sink = this.mediaObserver.asObservable().subscribe(changes => {
      this.greaterThanSmall = changes.map(item => item.mqAlias).includes('gt-sm');
      this.changeDetector.detectChanges();
    });
  }
}

@NgModule({
  declarations: [NavigationComponent],
  exports: [NavigationComponent],
  imports: [CommonModule, NavBarComponentModule, MobileNavBarComponentModule, LanguageTempComponentModule]
})
export class NavigationComponentModule {}
