export enum TreatmentType {
  FOLLOW_UP = 'FOLLOW_UP',
  SURGERY = 'SURGERY',
  BORESCOPE = 'BORESCOPE',
  SPEECH_ASSESSMENT = 'SPEECH_ASSESSMENT',
  SPEECH_TREATMENT = 'SPEECH_TREATMENT',
  ORTHOGNATHIC = 'ORTHOGNATHIC',
  NUTRITION_ASSESSMENT = 'NUTRITION_ASSESSMENT',
  NUTRITION_FOLLOW_UP = 'NUTRITION_FOLLOW_UP',
  NUTRITION_SCREENING_HISTORICAL = 'NUTRITION_SCREENING_HISTORICAL',
  NUTRITION_FOLLOW_UP_HISTORICAL = 'NUTRITION_FOLLOW_UP_HISTORICAL',
  ORTHO_PSIO_ASSESSMENT = 'ORTHO_PSIO_ASSESSMENT',
  ORTHO_PSIO_TREATMENT = 'ORTHO_PSIO_TREATMENT',
  ORTHO_MD_ASSESSMENT = 'ORTHO_MD_ASSESSMENT',
  ORTHO_PD_ASSESSMENT = 'ORTHO_PD_ASSESSMENT',
  ORTHO_MD_TREATMENT = 'ORTHO_MD_TREATMENT',
  ORTHO_PD_TREATMENT = 'ORTHO_PD_TREATMENT',
  ORTHO_PD_MID_ASSESSMENT = 'ORTHO_PD_MID_ASSESSMENT',
  ORTHO_MD_MID_ASSESSMENT = 'ORTHO_MD_MID_ASSESSMENT'
}

export function isUsingBusinessId(t: TreatmentType): boolean {
  return [
    TreatmentType.SPEECH_TREATMENT,
    TreatmentType.SPEECH_ASSESSMENT,
    TreatmentType.SURGERY,
    TreatmentType.ORTHOGNATHIC,
    TreatmentType.NUTRITION_ASSESSMENT,
    TreatmentType.NUTRITION_FOLLOW_UP,
    TreatmentType.NUTRITION_SCREENING_HISTORICAL,
    TreatmentType.NUTRITION_FOLLOW_UP_HISTORICAL,
    TreatmentType.ORTHO_PSIO_ASSESSMENT,
    TreatmentType.ORTHO_PSIO_TREATMENT,
    TreatmentType.ORTHO_MD_ASSESSMENT,
    TreatmentType.ORTHO_PD_ASSESSMENT,
    TreatmentType.ORTHO_MD_TREATMENT,
    TreatmentType.ORTHO_PD_TREATMENT,
    TreatmentType.ORTHO_PD_MID_ASSESSMENT,
    TreatmentType.ORTHO_MD_MID_ASSESSMENT
  ].includes(t);
}
