import { ResetPasswordConfirmationModalComponent } from '@/src/app/features/authentication/components/reset-password-confirmation-modal/reset-password-confirmation-modal.component';
import { AuthService } from '@/src/app/features/authentication/services/auth.service';
import { checkPasswords, isValidPassword, StxValidators } from '@/src/app/shared/validation/validators';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PasswordExpiryService } from '@src/app/features/authentication/services/password-expiry.service';
import { SpinnerService } from '@/src/app/shared/components/spinner/service/spinner.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'stx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  hidePassword = true;
  hideConfirmPassword = true;
  resetPasswordForm: UntypedFormGroup;
  key: string;
  showExpiredCredentialsMessage: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private passwordExpiryService: PasswordExpiryService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initializeResetPasswordForm();
    this.route.queryParams.subscribe({
      next: params => {
        this.key = params.key;
      }
    });
    this.showExpiredCredentialsMessage = this.passwordExpiryService.getShowExpiredCredentialsMessage();
  }

  private initializeResetPasswordForm(): void {
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: [null, [StxValidators.required, isValidPassword]],
        passwordConfirm: [null, StxValidators.required]
      },
      { validators: checkPasswords }
    );
  }

  hasErrors(controlName: string): boolean {
    const control = this.resetPasswordForm.get(controlName);
    return control && control.touched && control.invalid;
  }

  resetPassword: AsyncButtonClickAction = () => {
    if (!this.resetPasswordForm.valid) {
      return of(null);
    }
    this.spinnerService.addTask();

    return this.authService.resetPassword(this.resetPasswordForm.controls.password.value, this.key).pipe(
      tap({
        next: () => {
          this.spinnerService.removeTask();
          this.dialog.open(ResetPasswordConfirmationModalComponent, { data: { isSuccess: true } });
        },
        error: err => {
          this.spinnerService.removeTask();

          this.showPopUpOnlyWhenThereIsAnExceptionNotCommingFromPasswordValidation(err);

          this.resetPasswordForm.get('password').setErrors(err.error['password'].split(','));
          this.resetPasswordForm.updateValueAndValidity();
          this.cd.detectChanges();
        }
      })
    );
  };

  private showPopUpOnlyWhenThereIsAnExceptionNotCommingFromPasswordValidation(err: HttpErrorResponse) {
    if (!err.error) {
      this.dialog.open(ResetPasswordConfirmationModalComponent, { data: { isSuccess: false } });
    }
  }

  get confirmPasswordNotMatch(): boolean {
    if (this.resetPasswordForm.errors) {
      return this.resetPasswordForm.errors.passswordsNotMatch && this.resetPasswordForm.touched;
    }
  }
}
