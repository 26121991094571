import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { ReportService } from '@src/app/features/reports/components/report-filters/services/report.service';
import { ReportDictionariesService } from '@src/app/features/reports/components/report-filters/services/report-dictionaries.service';
import { UntypedFormGroup } from '@angular/forms';
import {
  AgeChangePayload,
  CommonReportDictionaryItem,
  ReportFilterChange
} from '@src/app/features/reports/components/report-filters/models/report-filters.model';
import { CommonModule } from '@angular/common';
import { CheckboxGroupFilterComponentModule } from '@src/app/features/reports/components/report-filters/components/checkbox-group-filter/checkbox-group-filter.component';

@Component({
  selector: 'stx-age-groups-filter',
  templateUrl: './age-groups-filter.component.html',
  styleUrls: ['./age-groups-filter.component.scss']
})
export class AgeGroupsFilterComponent {
  @Input() formGroupReference: UntypedFormGroup;
  @Input() dictionaryOptions: CommonReportDictionaryItem[];
  @Output() filterChange = new EventEmitter<ReportFilterChange<AgeChangePayload>>();

  constructor(public reportService: ReportService, public reportDictionariesService: ReportDictionariesService) {}
}

@NgModule({
  imports: [CommonModule, CheckboxGroupFilterComponentModule],
  declarations: [AgeGroupsFilterComponent],
  exports: [AgeGroupsFilterComponent]
})
export class AgeGroupsFilterComponentModule {}
