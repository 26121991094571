import { alternativeMappings, languages } from '@/src/app/utils/language.utils';
import { getStaticFileUrl } from '@/src/app/utils/media.utils';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base.component';

@Component({
  selector: 'stx-static-file-link',
  templateUrl: './static-file-link.component.html'
})
export class StaticFileLinkComponent extends BaseComponent implements OnInit {
  constructor(private readonly translateService: TranslateService) {
    super();
  }
  private fileNameTemplate: string;
  href: string;

  @Input()
  set file(fileNameTemplate: string) {
    this.fileNameTemplate = fileNameTemplate;
    this.buildLink(this.translateService.currentLang);
  }

  ngOnInit() {
    this.subSink.sink = this.translateService.onLangChange.subscribe(language => {
      const lang = language.lang;
      this.buildLink(lang);
    });
  }

  private buildLink(lang: string) {
    if (this.fileNameTemplate) {
      const langMappings = [].concat(alternativeMappings, languages);
      const mappedLang = langMappings.find(lp => lp.name === lang)?.shortcut || 'en';
      const fileName = this.fileNameTemplate.replace(/{lang}/, mappedLang);

      this.href = getStaticFileUrl(fileName);
    }
  }
}
