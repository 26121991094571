import { DictionaryTranslation, Patient } from '@/src/app/features/patient/models/patient.model';
import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientDataService } from '@src/app/features/patient/services/patient-data.service';
import { forkJoin, Observable } from 'rxjs';
import { PatientService } from 'src/app/features/patient/patient.service';
import { FormMode } from 'src/app/utils/form.utils';
import { FormType } from '../../enums/form-type.enum';
import { PatientDetailsBlockMode } from '../../enums/patient-details-block.enum';
import { CountryDictionary } from '../../models/geo.model';
import { GeoService } from '../../services/geo.service';
import { BaseComponent } from '../base-component/base.component';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  templateUrl: './patient-forms-container-page.component.html',
  providers: [PatientDataService]
})
export class PatientFormsContainerPageComponent extends BaseComponent implements OnInit {
  readonly PatientDetailsBlockMode = PatientDetailsBlockMode;
  countries: CountryDictionary[] = [];
  genders: DictionaryTranslation[];
  countryDictionaries$: Observable<CountryDictionary[]> = this.geoService.getCountryDictionaries;
  patient: Patient;
  print: boolean;
  formMode: FormMode;
  formType: FormType;
  FormType = FormType;
  FormMode = FormMode;

  @HostListener('window:beforeprint')
  onBeforePrint() {
    this.print = true;
  }
  @HostListener('window:afterprint')
  onAfterPrint() {
    this.print = false;
  }

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly geoService: GeoService,
    private readonly patientDataService: PatientDataService,
    private readonly patientService: PatientService,
    private readonly route: ActivatedRoute,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.connectRouteData();
    this.initStaticDictionaries();
    this.connectQueryParams();
    this.connectPatient();
  }

  private connectRouteData(): void {
    this.subSink.sink = this.route.data.subscribe(data => {
      this.formMode = data.formMode;
      this.formType = data.formType;
      this.cd.detectChanges();
    });
  }

  private initStaticDictionaries() {
    this.wsHelperService
      .callWithSpinner(forkJoin([this.patientService.getStaticDictionaries(), this.countryDictionaries$]))
      .subscribe(result => {
        const patientServiceDictionary = result[0] as any;
        this.genders = patientServiceDictionary.get('gender').map(value => value);
        this.countries = result[1] as CountryDictionary[];
        this.cd.detectChanges();
      });
  }

  private connectQueryParams() {
    this.subSink.sink = this.route.queryParams.subscribe(queryParams => {
      this.patientDataService.load(queryParams.patientId || null);
    });
  }

  private connectPatient() {
    this.subSink.sink = this.patientDataService.patient$.subscribe(patient => {
      this.patient = patient;
      this.cd.detectChanges();
    });
  }

  refreshPatient() {
    this.patientDataService.refreshLoadedPatient();
  }
}
