import { PartnerTreatmentCenterSurveysBlock, Survey } from '@/src/app/features/dashboard/partner-surveys/survey.model';
import { SurveyService } from '@/src/app/features/dashboard/partner-surveys/survey.service';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { staticFilesRegistry } from '@/src/app/shared/modules/static-files/static-files.utils';
import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  selector: 'stx-partner-surveys',
  templateUrl: './partner-surveys.component.html'
})
export class PartnerSurveysComponent extends BaseComponent {
  readonly staticFilesRegistry = staticFilesRegistry;
  private readonly partnerSurveysSubject$ = new BehaviorSubject<Survey[] | null>(null);
  private readonly partnerTcsSurveysSubject$ = new BehaviorSubject<PartnerTreatmentCenterSurveysBlock[] | null>(null);

  get partnerSurveyBlocks$() {
    return this.partnerSurveysSubject$.asObservable();
  }

  get partnerTcsSurveyBlocks$() {
    return this.partnerTcsSurveysSubject$.asObservable();
  }

  constructor(private readonly surveyService: SurveyService, private readonly wsHelperService: WsHelperService) {
    super();
    this.subSink.sink = this.wsHelperService.call(this.surveyService.getPartnerSurveyBlocks()).subscribe(surveyBlocks => {
      if (surveyBlocks.partnerSurveys) {
        this.partnerSurveysSubject$.next(surveyBlocks.partnerSurveys);
      }
      if (surveyBlocks.partnerTreatmentCentersSurveyBlocks) {
        this.partnerTcsSurveysSubject$.next(surveyBlocks.partnerTreatmentCentersSurveyBlocks);
      }
    });
  }
}
