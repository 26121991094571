<p *ngIf="!isEmpty">
  <fa-icon *ngIf="preIcon" [icon]="preIcon"></fa-icon>
  <ng-container *ngIf="labelKey"> {{ labelKey | translate }} &mdash; </ng-container>
  <ng-container *ngIf="href">
    <a [href]="href" target="{{ target }}">{{ value }}</a>
    <fa-icon *ngIf="postIcon" [icon]="postIcon" class="post-icon"></fa-icon>
  </ng-container>
  <ng-container *ngIf="!href">
    {{ value }}
  </ng-container>
</p>
