<stx-resources-navigation selectedItemId="{{ NavigationSubTabEnum.RESOURCES_MEDICAL_FORMS }}"></stx-resources-navigation>
<stx-generic-wide-page>
  <stx-page-header [name]="'medical_forms.title' | translate"></stx-page-header>
  <div class="layout-columns-1-1-1">
    <div>
      <stx-generic-resource-list [sections]="[patientSection]"></stx-generic-resource-list>
    </div>
    <div>
      <stx-generic-resource-list [sections]="[surgerySection]"></stx-generic-resource-list>
      <div *stxPermitted="PermissionEnum.SAVE_SURGERY">
        <stx-generic-resource-list [sections]="offlineAppLinks"></stx-generic-resource-list>
        <div>
          <ul class="data-spaced-column">
            <li>
              <div class="resource-spaced-column">
                <div>
                  <span class="prefix-label">
                    {{ practitionersList.urlListPrefix | translate }}
                  </span>
                  <span class="separated-item">
                    <a (click)="getPractitionersList()">{{ practitionersList.label }}</a>
                  </span>
                </div>
                <p>
                  {{ practitionersList.bottomNote | translate }}
                </p>
              </div>
            </li>
            <hr class="data-thin-section-separator" />
            <li>
              <div class="resource-spaced-column">
                <a [href]="getEducationalResourceUrl(comdlg)">{{ comdlg.label }}</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <stx-generic-resource-list [sections]="[speechSection, nutritionSection]"></stx-generic-resource-list>
    </div>
    <div>
      <stx-generic-resource-list [sections]="[orthoSection]"></stx-generic-resource-list>
    </div>
  </div>
</stx-generic-wide-page>
