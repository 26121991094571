import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UppyErrorService {
  private fileIsEmptySource: BehaviorSubject<Map<string, boolean>> = new BehaviorSubject<Map<string, boolean>>(new Map<string, boolean>());
  fileIsEmpty$: Observable<Map<string, boolean>> = this.fileIsEmptySource.asObservable();

  isUploadedFileEmptyBy(uuid: string): boolean {
    return this.fileIsEmptySource.value.get(uuid) || false;
  }

  public setUploadedFileToEmptyBy(uuid: string): void {
    const updatedMap: Map<string, boolean> = new Map(this.fileIsEmptySource.value);
    updatedMap.set(uuid, true);
    this.fileIsEmptySource.next(updatedMap);
  }

  public resetBy(uuid: string): void {
    const updatedMap: Map<string, boolean> = new Map(this.fileIsEmptySource.value);
    updatedMap.set(uuid, false);
    this.fileIsEmptySource.next(updatedMap);
  }
}
