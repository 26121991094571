import { DictionaryTranslation, PatientId } from '@/src/app/features/patient/models/patient.model';
import { SurgeryFollowUp } from '@/src/app/features/surgical/models/surgery-follow-up.model';
import { SurgicalService } from '@/src/app/features/surgical/surgical.service';
import { BaseTreatmentFormComponent } from '@/src/app/shared/components/base-treatment-form/base-treatment-form.component';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { requiredIfValidator, StxValidators } from '@/src/app/shared/validation/validators';
import { getNextDay } from '@/src/app/utils/date.utils';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { emptyImageCell } from '@shared/components/form/components/image-group/image-group.model';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { FormGuardService } from '@shared/services/form-guard.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { FormMediaUtils } from '@utils/form-media.utils';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { PatientService } from 'src/app/features/patient/patient.service';
import { ParentOrderName } from 'src/app/shared/enums/parent-order-name.enum';
import { FormFieldType, FormMode, HorizontalFormElementSetup } from 'src/app/utils/form.utils';
import { surgeryFollowUpFull } from 'src/app/utils/routing.utils';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Component({
  selector: 'stx-surgical-follow-up',
  templateUrl: './surgical-follow-up.component.html',
  styleUrls: ['surgical-follow-up.component.scss']
})
export class SurgicalFollowUpComponent extends BaseTreatmentFormComponent<SurgeryFollowUp> implements OnInit {
  readonly followUpInfoSetup = new Map<string, HorizontalFormElementSetup>()
    .set('dateVisit', { name: 'surgery_follow_up.follow_up_date', nameSuffix: 'date_format', type: FormFieldType.DATE, err: 'error.date' })
    .set('treatmentCenterId', {
      name: 'treatment_center.treatment_center',
      type: FormFieldType.SELECT,
      dataSrc: () => this.tcList,
      err: 'error.select'
    })
    .set('telehealth', {
      name: 'treatment.telehealth_assessment',
      type: FormFieldType.CHECKBOX
    })
    .set('healWell', { name: 'surgery_follow_up.surgery_site_healed', type: FormFieldType.BOOLEAN, err: 'error.select' })
    .set('healComplication', {
      name: 'surgery_follow_up.surgery_site_complications',
      type: FormFieldType.BOOLEAN,
      err: 'error.select'
    })
    .set('healComplicationComment', { name: 'if_yes_explain', type: FormFieldType.TEXTAREA, err: 'error.field_empty' })
    .set('needSurgery', { name: 'surgery_follow_up.additional_surgery', type: FormFieldType.BOOLEAN, err: 'error.select' })
    .set('needSurgeryComment', { name: 'if_yes_explain', type: FormFieldType.TEXTAREA, err: 'error.field_empty' })
    .set('needSpeech', { name: 'surgery_follow_up.speech_services_needed', type: FormFieldType.BOOLEAN, err: 'error.select' })
    .set('needSpeechComment', { name: 'if_yes_explain', type: FormFieldType.TEXTAREA, err: 'error.field_empty' })
    .set('needOrtho', { name: 'surgery_follow_up.ortho_treatment_needed', type: FormFieldType.BOOLEAN, err: 'error.select' })
    .set('needOrthoComment', { name: 'if_yes_explain', type: FormFieldType.TEXTAREA, err: 'error.field_empty' })
    .set('properWeight', {
      name: 'surgery_follow_up.appropriate_weight_height',
      type: FormFieldType.BOOLEAN,
      err: 'error.select'
    })
    .set('properWeightImprove', {
      name: 'surgery_follow_up.nutritional_status_improvement',
      type: FormFieldType.RADIO,
      dataSrc: () => this.threeAnswers,
      err: 'error.field_empty'
    })
    .set('attendSchool', {
      name: 'surgery_follow_up.school_attendance',
      type: FormFieldType.RADIO,
      dataSrc: () => this.threeAnswers,
      err: 'error.field_empty'
    })
    .set('attendSchoolImprove', {
      name: 'surgery_follow_up.school_attendace_chances_improvement',
      type: FormFieldType.TEXTAREA,
      err: 'error.field_empty'
    })
    .set('outcomeSentiment', { name: 'surgery_follow_up.feelings_about_treatment', type: FormFieldType.TEXTAREA });

  readonly imageConfigs = [
    {
      label: 'order_name.frontal_smiling',
      parentOrderName: ParentOrderName.FOLLOW_UP_FRONT,
      isRequired: true
    },
    emptyImageCell,
    { label: 'order_name.frontal_lip', parentOrderName: ParentOrderName.FOLLOW_UP_FRONT_LIP, isRequired: false },
    { label: 'order_name.palate', parentOrderName: ParentOrderName.FOLLOW_UP_PALATE, isRequired: false },
    { label: 'order_name.other', parentOrderName: ParentOrderName.FOLLOW_UP_OTHER_1, isRequired: false },
    { label: 'order_name.other', parentOrderName: ParentOrderName.FOLLOW_UP_OTHER_2, isRequired: false }
  ];
  threeAnswers: DictionaryTranslation[];
  minDate: moment.Moment;

  @Input() surgeryFollowUp: SurgeryFollowUp;

  get isReadonlyView(): boolean {
    return this.formMode === FormMode.READONLY;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private surgicalService: SurgicalService,
    private cd: ChangeDetectorRef,
    patientService: PatientService,
    router: Router,
    spinnerService: SpinnerService,
    globalErrorHandlerService: GlobalErrorHandlerService,
    private formGuardService: FormGuardService,
    activatedRoute: ActivatedRoute,
    snackBarService: SnackBarService,
    elementRef: ElementRef,
    zone: NgZone,
    wsHelper: WsHelperService
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      cd,
      globalErrorHandlerService,
      wsHelper,
      patientService
    );
    this.formType = FormType.SURGERY_FOLLOW_UP;
    this.treatmentType = TreatmentType.FOLLOW_UP;
  }

  ngOnInit() {
    this.configureForm(this.initFollowUpInfoForm(), { controlNamesForFullValidation: ['dateVisit', 'treatmentCenterId'] });
    this.initStaticDictionaries();
    this.loadSurgicalFollowUp();
    if (!this.isReadonlyView) {
      this.getPatientTreatmentCenters();
    }

    if (this.patient) {
      this.minDate = getNextDay(this.patient.dateOfBirth);
    }
  }

  private initFollowUpInfoForm(): UntypedFormGroup {
    return this.formBuilder.group({
      dateVisit: [null, StxValidators.required],
      treatmentCenterId: [null, StxValidators.required],
      telehealth: [null],
      healWell: [null, StxValidators.required],
      healComplication: [null, StxValidators.required],
      healComplicationComment: [null, requiredIfValidator(() => this.formGroup.get('healComplication').value)],
      needSurgery: [null, StxValidators.required],
      needSurgeryComment: [null, requiredIfValidator(() => this.formGroup.get('needSurgery').value)],
      needSpeech: [null, StxValidators.required],
      needSpeechComment: [null, requiredIfValidator(() => this.formGroup.get('needSpeech').value)],
      needOrtho: [null, StxValidators.required],
      needOrthoComment: [null, requiredIfValidator(() => this.formGroup.get('needOrtho').value)],
      properWeight: [null, StxValidators.required],
      properWeightImprove: [null, requiredIfValidator(() => !this.formGroup.get('properWeight').value)],
      attendSchool: [null, StxValidators.required],
      attendSchoolImprove: [null, requiredIfValidator(() => this.formGroup.get('attendSchool').value === 0)],
      outcomeSentiment: [null],
      [ParentOrderName.FOLLOW_UP_FRONT]: [null, StxValidators.required],
      [ParentOrderName.FOLLOW_UP_FRONT_LIP]: [null],
      [ParentOrderName.FOLLOW_UP_PALATE]: [null],
      [ParentOrderName.FOLLOW_UP_OTHER_1]: [null],
      [ParentOrderName.FOLLOW_UP_OTHER_2]: [null]
    });
  }

  private loadSurgicalFollowUp(): void {
    if (!this.surgeryFollowUp) {
      this.subSink.sink = this.activatedRoute.params.subscribe(params => {
        if (params.id) {
          this.wsHelper.call(this.surgicalService.getSurgeryFollowUp(params.id), { redirectOn404StatusCode: true }).subscribe(followUp => {
            this.formGuardService.redirectTreatmentFormToCorrectModeIfRequired(followUp, this.formMode, this.activatedRoute.snapshot);
            this.surgeryFollowUp = followUp;
            this.fillWithSurgicalFollowUpData(followUp);
          });
        }
      });

      this.checkMode();
    } else {
      this.fillWithSurgicalFollowUpData(this.surgeryFollowUp);
    }
  }

  private fillWithSurgicalFollowUpData(followUp: SurgeryFollowUp) {
    this.formGroup.reset();
    this.formGroup.patchValue(followUp);
    this.formGroup.patchValue(FormMediaUtils.getMediaForFormGroup(followUp, this.parentOrderNames));
    this.cd.detectChanges();
  }

  private initStaticDictionaries() {
    this.wsHelper.call(this.surgicalService.getFollowUpStaticDictionaries()).subscribe(dictionary => {
      this.threeAnswers = dictionary.get('yesNoNa');
      this.cd.detectChanges();
    });
  }

  private checkMode(): void {
    this.subSink.sink = this.activatedRoute.data.subscribe(data => {
      this.formMode = data.formMode;
      this.cd.detectChanges();
    });
  }

  private extractNewFiles() {
    return FormMediaUtils.extractNewMediaFromFormGroup([this.formGroup], this.parentOrderNames);
  }

  private get parentOrderNames(): ParentOrderName[] {
    return this.imageConfigs.filter(config => !!config).map(config => config.parentOrderName);
  }

  protected callDelete(id: number): Observable<void> {
    return this.surgicalService.deleteSurgeryFollowUp(id);
  }

  protected callSave(data: SurgeryFollowUp): Observable<SurgeryFollowUp> {
    return this.surgicalService.saveSurgeryFollowUp(data);
  }

  protected callSubmit(data: SurgeryFollowUp): Observable<SurgeryFollowUp> {
    return this.surgicalService.submitSurgeryFollowUp(data);
  }

  protected callUnlock(id: number): Observable<void> {
    return this.surgicalService.unlockSurgeryFollowUp(id);
  }

  protected getTreatmentDataToSave(): SurgeryFollowUp {
    const surgeryFollowUp = this.formGroup.value;
    surgeryFollowUp.patientId = this.patient.id;
    surgeryFollowUp.newFiles = this.extractNewFiles();
    if (this.surgeryFollowUp) {
      surgeryFollowUp.id = this.surgeryFollowUp.id;
    }
    return surgeryFollowUp;
  }

  protected getEditRoute(treatmentId: number): string {
    return `${surgeryFollowUpFull}/edit/${treatmentId}`;
  }

  protected getPatientId(): PatientId {
    return this.patient.id;
  }

  protected getTreatmentId(): TreatmentId {
    return this.surgeryFollowUp.id;
  }

  protected getViewRoute(treatmentId: number): string {
    return `${surgeryFollowUpFull}/${treatmentId}`;
  }

  protected setTreatmentData(data: SurgeryFollowUp): void {
    this.surgeryFollowUp = data;
    this.formGroup.patchValue(data);
    this.formGroup.patchValue(FormMediaUtils.getMediaForFormGroup(data, this.parentOrderNames));
    this.cd.detectChanges();
  }
}
