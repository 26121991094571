import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NoticeComponentModule } from '@shared/components/notice/notice.component';
import { MaterialModule } from '../../modules/material/material.module';
import { FormModule } from '../form/form.module';
import { UppyModule } from '../uppy/uppy.module';
import { DocumentLanguageSelectComponent } from './document-language-select/document-language-select.component';
import { PatientReleaseFormBlockComponent } from './patient-release-form-block/patient-release-form-block.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PatientDocumentFileUploadComponent } from '@shared/components/patient-document-upload/patient-document-file-upload/patient-document-file-upload.component';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { SignaturePadComponent } from '@shared/components/patient-document-upload/signature-pad/signature-pad.component';
import { FormsCommonsModule } from '@shared/modules/forms-commons/forms-commons.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { PatientReleaseFormOfflineComponent } from '@shared/components/patient-document-upload/patient-release-form-offline/patient-release-form-offline.component';
import { PatientReleaseFormOnlineComponent } from '@shared/components/patient-document-upload/patient-release-form-online/patient-release-form-online.component';
import { PatientReleaseFormOnlineDialogComponent } from '@shared/components/patient-document-upload/patient-release-form-online-dialog/patient-release-form-online-dialog.component';

@NgModule({
  declarations: [
    DocumentLanguageSelectComponent,
    PatientReleaseFormOnlineDialogComponent,
    PatientDocumentFileUploadComponent,
    PatientReleaseFormBlockComponent,
    PatientReleaseFormOfflineComponent,
    PatientReleaseFormOnlineComponent,
    SignaturePadComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FontAwesomeModule,
    FormModule,
    MaterialModule,
    NoticeComponentModule,
    ReactiveFormsModule,
    ReadonlyCommonsModule,
    TranslateModule.forChild(),
    UppyModule,
    FormsCommonsModule,
    GeneralCommonsModule
  ],
  exports: [PatientDocumentFileUploadComponent, PatientReleaseFormBlockComponent, DocumentLanguageSelectComponent]
})
export class PatientDocumentUploadModule {}
