export const DURATION = {
  SLOW: '300ms',
  DEFAULT: '200ms',
  FAST: '125ms',
  VERY_FAST: '75ms'
};

export const EASING = {
  DEFAULT: 'ease-in'
};

export const TIMINGS = {
  SLOW: `${DURATION.SLOW} ${EASING.DEFAULT}`,
  DEFAULT: `${DURATION.DEFAULT} ${EASING.DEFAULT}`,
  FAST: `${DURATION.FAST} ${EASING.DEFAULT}`,
  VERY_FAST: `${DURATION.VERY_FAST} ${EASING.DEFAULT}`
};
