import { AuthService } from '@/src/app/features/authentication/services/auth.service';
import { resetFormGroupErrors } from '@/src/app/shared/validation/validation.utils';
import { checkPasswords, isValidPassword, StxValidators } from '@/src/app/shared/validation/validators';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '@shared/components/form/form.module';
import {
  LanguageSwitcherComponent,
  LanguageSwitcherComponentModule
} from '@shared/components/language-switcher/components/language-switcher/language-switcher.component';
import { FormsCommonsModule } from '@shared/modules/forms-commons/forms-commons.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { HorizontalFormElementSetup } from '@utils/form.utils';
import { MultiErrorValidatorComponent } from '@src/app/features/edit-settings/password-validator/multi-error-validator.component';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

@Component({
  selector: 'stx-edit-settings',
  templateUrl: './edit-settings.component.html',
  styleUrls: ['./edit-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSettingsComponent implements OnInit {
  hidePassword = true;
  hideConfirmPassword = true;
  hideNewPassword = true;
  changePasswordForm: UntypedFormGroup;

  @ViewChild(LanguageSwitcherComponent, { static: true }) language: LanguageSwitcherComponent;

  readonly changePasswordSetup = new Map<string, HorizontalFormElementSetup>()
    .set('currentPassword', {
      name: 'settings.current_password',
      err: 'error.current_password',
      hidePassword: this.hidePassword
    })
    .set('password', {
      name: 'settings.new_password',
      err: 'error.password_length',
      hidePassword: this.hideNewPassword
    })
    .set('passwordConfirm', {
      name: 'settings.password_confirmation',
      err: 'error.confirm_password',
      hidePassword: this.hideConfirmPassword
    });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetection: ChangeDetectorRef,
    private authService: AuthService,
    private currentUserService: CurrentUserService,
    private spinnerService: SpinnerService,
    private globalErrorHandler: GlobalErrorHandlerService
  ) {}

  ngOnInit() {
    this.initializeChangePasswordForm();
  }

  initializeChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group(
      {
        currentPassword: [null, StxValidators.required],
        password: [null, [StxValidators.required, isValidPassword]],
        passwordConfirm: [null, StxValidators.required]
      },
      { validators: [checkPasswords] }
    );
  }

  hasErrors(controlName: string): boolean {
    const control = this.changePasswordForm.get(controlName);
    return control && control.touched && control.invalid && !control.hasError('required');
  }

  changePassword(): void {
    if (!this.changePasswordForm.valid) {
      return;
    }

    this.spinnerService.addTask();

    this.authService
      .changePassword(this.changePasswordForm.get('currentPassword').value, this.changePasswordForm.get('password').value)
      .subscribe({
        next: () => {
          this.authService.getUserAndManageTempLang().subscribe({
            next: user => {
              this.currentUserService.setCurrentUser(user);
            }
          });

          this.changePasswordForm.reset();
          resetFormGroupErrors(this.changePasswordForm);
          this.spinnerService.removeTask();
        },
        error: err => {
          this.spinnerService.removeTask();

          this.showPopUpOnlyWhenThereIsAnExceptionNotCommingFromPasswordValidation(err);

          this.changePasswordForm.get('password').setErrors(err.error['newPassword']?.split(','));
          this.changePasswordForm.get('currentPassword').setErrors(err.error['currentPassword']?.split(','));
          this.changeDetection.detectChanges();
        }
      });
  }

  getUserEmail(): string {
    return this.currentUserService.getCurrentUser()?.email;
  }

  private showPopUpOnlyWhenThereIsAnExceptionNotCommingFromPasswordValidation(err: any) {
    if (!err.error) {
      this.globalErrorHandler.handleErrorAndInformUser(err);
    }
  }
}

@NgModule({
  exports: [EditSettingsComponent, MultiErrorValidatorComponent],
  imports: [
    GeneralCommonsModule,
    CommonModule,
    FormsCommonsModule,
    LanguageSwitcherComponentModule,
    FormModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    TranslateModule,
    ReactiveFormsModule,
    FlexModule
  ],
  declarations: [EditSettingsComponent, MultiErrorValidatorComponent]
})
export class EditSettingsComponentModule {}
