import { TreatmentFilter, TreatmentFilterFlatNode } from '@/src/app/features/search/models/treatment-filter.model';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

/** Angular Material Tree API implementation with helpers */
@Component({
  template: ''
})
export abstract class TreatmentTypeFilterBaseComponent extends BaseComponent {
  checklistSelection = new SelectionModel<TreatmentFilterFlatNode>(true);

  treeControl: FlatTreeControl<TreatmentFilterFlatNode>;

  dataSource: MatTreeFlatDataSource<TreatmentFilter, TreatmentFilterFlatNode>;

  protected treatmentTypeTree: TreatmentFilter[];

  protected nestedNodeMap = new Map<TreatmentFilter, TreatmentFilterFlatNode>();

  protected treeFlattener: MatTreeFlattener<TreatmentFilter, TreatmentFilterFlatNode>;
  protected getLevel = (node: TreatmentFilterFlatNode) => node.level;

  protected isExpandable = (node: TreatmentFilterFlatNode) => node.expandable;

  protected getChildren = (node: TreatmentFilter): TreatmentFilter[] => node.children;
  protected getParentNode(node: TreatmentFilterFlatNode): TreatmentFilterFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  hasChild = (_: number, nodeData: TreatmentFilterFlatNode) => nodeData.expandable;

  areAllDescendantsSelected(node: TreatmentFilterFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);

    if (descendants.length > 0) {
      return descendants.every(child => this.checklistSelection.isSelected(child));
    }

    return false;
  }

  areDescendantsPartiallySelected(node: TreatmentFilterFlatNode): boolean {
    return this.isAnyDescendantSelected(node) && !this.areAllDescendantsSelected(node);
  }

  protected isAnyDescendantSelected(node: TreatmentFilterFlatNode): boolean {
    return this.treeControl.getDescendants(node).some(child => this.checklistSelection.isSelected(child));
  }

  protected isNoDescendantSelected(node: TreatmentFilterFlatNode): boolean {
    return this.treeControl.getDescendants(node).every(child => !this.checklistSelection.isSelected(child));
  }

  protected hasIndeterminateSelectionState(node: TreatmentFilterFlatNode): boolean {
    return !this.checklistSelection.isSelected(node) && this.areDescendantsPartiallySelected(node);
  }

  protected isDeselected(node: TreatmentFilterFlatNode): boolean {
    return !this.checklistSelection.isSelected(node) && !this.hasIndeterminateSelectionState(node);
  }

  abstract transformer: (node: TreatmentFilter, level: number) => TreatmentFilterFlatNode;
}
