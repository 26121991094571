import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { LayoutDirection } from '@shared/components/form/components/checkbox-group/checkbox-group.component';
import { CheckboxWithSubtitle, NutritionCheckboxItem } from '@src/app/features/nutrition-management/models/nutrition-item.model';

@Component({
  selector: 'stx-checkbox-group-with-text-field',
  templateUrl: './checkbox-group-with-text-field.component.html'
})
export class CheckboxGroupWithTextFieldComponent extends BaseFormElementComponent implements OnInit {
  @Input() options: CheckboxWithSubtitle[] | NutritionCheckboxItem[] | string;
  @Input() layoutDirection: LayoutDirection;
  @Input() formGroupErrorNames: Array<string>;
  @Input() deselectTriggerControlName?: string;
  @Input() textFieldControlName: string;
  @Input() textFieldParentControlName: string;

  private get textFieldParent(): AbstractControl {
    return this.formGroup.get(this.textFieldParentControlName);
  }

  private get textField(): AbstractControl {
    return this.formGroup.get(this.textFieldControlName);
  }

  private get deselectTrigger(): AbstractControl {
    return this.formGroup.get(this.deselectTriggerControlName);
  }

  ngOnInit(): void {
    this.disableTextFieldOnDeselectTriggerSelected();
    this.selectParentControlWhenTextFieldHasValue();
    this.updateValidityOnParentSelected();
  }

  private disableTextFieldOnDeselectTriggerSelected(): void {
    if (this.deselectTriggerControlName) {
      this.subSink.sink = this.deselectTrigger?.valueChanges.subscribe(value => {
        if (value) {
          this.textField.disable();
        } else {
          this.textField.enable();
        }
      });
    }
  }

  private selectParentControlWhenTextFieldHasValue(): void {
    this.subSink.sink = this.textField?.valueChanges.subscribe(value => {
      if (value && !this.textFieldParent?.value) {
        this.textFieldParent.setValue(true);
      }
    });
  }

  private updateValidityOnParentSelected(): void {
    this.subSink.sink = this.textFieldParent?.valueChanges.subscribe(() => this.textField.updateValueAndValidity());
  }
}
