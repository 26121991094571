import { DictionaryTranslation } from '@src/app/features/patient/models/patient.model';
import { Observable } from 'rxjs';
import { Provider, Type } from '@angular/core';
import { map } from 'rxjs/operators';

export abstract class DictionaryProvider {
  abstract getDictionary(name: string): Observable<DictionaryTranslation[]>;
}

export function dictionaryFromMapProvider<T>(
  service: Type<T>,
  getDictionary: (service: T) => Observable<Map<string, DictionaryTranslation[]>>
): Provider {
  return {
    provide: DictionaryProvider,
    useFactory: (dedicatedService: T) => {
      return new (class extends DictionaryProvider {
        getDictionary(name: string): Observable<DictionaryTranslation[]> {
          return getDictionary(dedicatedService).pipe(map(response => response.get(name)));
        }
      })();
    },
    deps: [service]
  };
}

export function dictionaryFromObjectProvider<T>(service: Type<T>, getDictionary: (service: T) => Observable<any>): Provider {
  return {
    provide: DictionaryProvider,
    useFactory: (dedicatedService: T) => {
      return new (class extends DictionaryProvider {
        getDictionary(name: string): Observable<DictionaryTranslation[]> {
          return getDictionary(dedicatedService).pipe(map(response => response[name]));
        }
      })();
    },
    deps: [service]
  };
}
