import { Injectable } from '@angular/core';

import { AbstractControl } from '@angular/forms';

/**
 * @deprecated To be removed once not used
 */
@Injectable({ providedIn: 'root' })
export class FormUtilsService {
  getControlName(abstractControl: AbstractControl): string {
    if (!abstractControl.parent) {
      return '';
    }

    const controls: { [p: string]: AbstractControl } | AbstractControl[] = abstractControl.parent.controls;

    return Object.keys(controls).find(name => abstractControl === (controls as { [p: string]: AbstractControl })[name]) || null;
  }
}
