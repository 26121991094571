<stx-page-with-sidebar>
  <ng-container slot="body">
    <!-- TODO#9047 Temporary hide until we determine wording
    <div class="data-section" *stxPermitted="PermissionEnum.VIEW_DASHBOARD_MAIN_CURRENT_MONTH">
      <div class="data-tile-container">
        <div class="data-tile-row">
          <stx-current-month-block *ngIf="statisticsTable" [surgicalStats]="statisticsTable.surgical"></stx-current-month-block>
        </div>
      </div>
    </div>
    -->
    <stx-release-info></stx-release-info>
    <div class="main-information-container gap-20">
      <div class="dashboard-resource-panel">
        <stx-dashboard-resources-panel></stx-dashboard-resources-panel>
      </div>
      <div class="dashboard-announcement-panel">
        <stx-dashboard-announcements-panel> </stx-dashboard-announcements-panel>
      </div>
      <div class="hide-gt-md"></div>
    </div>
  </ng-container>
  <ng-container slot="sidebar">
    <stx-spinner [showSpinner]="hasActiveTasks" [useAbsolutePosition]="false"></stx-spinner>
    <stx-statistics [statisticsTable]="statisticsTable" [isOnDashboard]="true"></stx-statistics>
  </ng-container>
</stx-page-with-sidebar>
