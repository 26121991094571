import { Component, Input } from '@angular/core';
import { FormType } from '../../../../enums/form-type.enum';

@Component({
  selector: 'stx-patient-forms-headers-container',
  templateUrl: './patient-forms-headers-container.component.html',
  styleUrls: ['./patient-forms-headers-container.component.scss']
})
export class PatientFormsHeadersContainerComponent {
  readonly FormType = FormType;
  @Input() formType: FormType;
  @Input() cssClass: string;
}
