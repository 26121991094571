<ng-container [formGroup]="formGroupReference">
  <div class="form-element-label" id="latest-cases">
    {{ 'reports.filters.latest_cases.radio_group_label' | translate }}
  </div>
  <mat-radio-group aria-labelledby="latest-cases" class="flex-row-container wrap align-left" [formControlName]="controlName">
    <ng-container *ngFor="let option of latestCasesOptions; last as last">
      <mat-radio-button
        data-testid="radio-latest-cases-filter"
        [value]="option.value"
        class="element-text m-t-10 m-r-24 radio-button"
        [ngClass]="last ? '' : 'm-b-10'"
      >
        {{ option.label | translate }}
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>
</ng-container>
