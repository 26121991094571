import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Item } from '@shared/models/item.model';
import { AutocompleteInput } from 'src/app/shared/models/form.model';
import { GeoEnum } from '../../../enums/geo.enum';
import { OrgTreeFilterModel } from '../../../models/org-tree-filter.model';

@Component({
  selector: 'stx-geography-filter',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './geography-filter.component.html'
})
export class GeographyFilterComponent implements OnInit {
  @Input() set orgTreeFilters(orgTreeFilters: OrgTreeFilterModel) {
    if (orgTreeFilters) {
      this.orgTreeFiltersData = orgTreeFilters;

      if (this.afterOnInitPhase) {
        this.setUpForms();
      }
    }
  }
  @Input() formGroupReference: UntypedFormGroup;
  @Input() set showPartial(partial: GeoEnum) {
    this.partialType = partial;
  }

  geoSetup = new Map<string, AutocompleteInput>();
  orgTreeFiltersData: OrgTreeFilterModel;
  partialType: GeoEnum;
  afterOnInitPhase = false;

  ngOnInit(): void {
    if (this.orgTreeFiltersData) {
      this.setUpForms();
    }
    this.afterOnInitPhase = true;
  }

  onRemoveChip($event: Item, control: AbstractControl) {
    const newValues = (control.value as Array<Item>).filter((value: Item) => value.id !== $event.id);

    control.setValue(newValues);
  }

  isShown(controlKey: string): boolean {
    return this.geoSetup.get(controlKey)?.data?.length > 0;
  }

  private setUpForms(): void {
    if (!this.partialType) {
      this.geoSetup
        .set('crmRegions', {
          name: 'geography.crm_region',
          formControlName: 'crmRegions',
          data: this.orgTreeFiltersData?.crmRegions
        })
        .set('countries', {
          name: 'geography.country',
          formControlName: 'countries',
          data: this.orgTreeFiltersData?.countries
        })
        .set('zoneCountries', {
          name: 'geography.zone_country',
          formControlName: 'zoneCountries',
          data: this.orgTreeFiltersData?.zoneCountries
        })
        .set('provinces', {
          name: 'geography.province',
          formControlName: 'provinces',
          data: this.orgTreeFiltersData?.provinces
        })
        .set('zoneProvinces', {
          name: 'geography.zone_province',
          formControlName: 'zoneProvinces',
          data: this.orgTreeFiltersData?.zoneProvinces
        })
        .set('crms', {
          name: 'geography.crm',
          formControlName: 'crms',
          data: this.orgTreeFiltersData?.crms
        });
    }
    if (this.partialType === GeoEnum.CRM) {
      this.geoSetup
        .set('crmRegions', {
          name: 'geography.crm_region',
          formControlName: 'crmRegions',
          data: this.orgTreeFiltersData?.crmRegions
        })
        .set('crms', {
          name: 'geography.crm',
          formControlName: 'crms',
          data: this.orgTreeFiltersData?.crms
        });
    }
    if (this.partialType === GeoEnum.COUNTRY) {
      this.geoSetup
        .set('crmRegions', {
          name: 'geography.crm_region',
          formControlName: 'crmRegions',
          data: this.orgTreeFiltersData?.crmRegions
        })
        .set('countries', {
          name: 'geography.country',
          formControlName: 'countries',
          data: this.orgTreeFiltersData?.countries
        });
    }
    if (this.partialType === GeoEnum.ONLY_COUNTRY) {
      this.geoSetup.set('countries', {
        name: 'geography.country',
        formControlName: 'countries',
        data: this.orgTreeFiltersData?.countries
      });
    }
  }
}
