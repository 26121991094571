<div class="data-tile-row">
  <div class="data-tile flex-column-container">
    <div class="data-tile-header bold p-t-20 p-b-20 display-flex layout-align-center">
      <div>{{ 'patient' | translate }} # {{ rowNumber }} &nbsp;</div>
      <ng-content select="[slot=prefix]"></ng-content>
      <stx-treatment-status-indicator *ngIf="status" [treatmentStatus]="status" [defaultIconColor]="true"></stx-treatment-status-indicator>
      <ng-content select="[slot=suffix]"></ng-content>
    </div>
  </div>
</div>
