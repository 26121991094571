import { AuthService } from '@/src/app/features/authentication/services/auth.service';
import { UserService } from '@/src/app/features/user/services/user.service';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { StxValidators } from '@/src/app/shared/validation/validators';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UntypedFormBuilder, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { TempLangService } from '@shared/services/localisation/temp-lang.service';
import { getLanguageByShortcut, languages } from '@utils/language.utils';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

@Component({
  selector: 'stx-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSwitcherComponent extends BaseComponent implements OnInit {
  @Input() allowLangSwitch = true;

  languageSelectorForm: UntypedFormGroup;
  userLanguage: string;
  expanded: false;

  constructor(
    public translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private currentUserService: CurrentUserService,
    private tempLangService: TempLangService,
    private wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeLanguageForm();
    this.getCurrentLanguage();
  }

  initializeLanguageForm() {
    this.languageSelectorForm = this.formBuilder.group({ lang: ['English', StxValidators.required] });
  }

  getCurrentLanguage() {
    this.subSink.sink = this.currentUserService.getUserSubject().subscribe({
      next: user => {
        if (user) {
          this.userLanguage = getLanguageByShortcut(user.lang).name;
          this.languageSelectorForm.get('lang').setValue(this.userLanguage);
        } else {
          this.languageSelectorForm.get('lang').setValue(this.translateService.currentLang || 'English');
        }
      },
      error: () => {
        this.languageSelectorForm.get('lang').setValue('English');
      }
    });
  }

  saveLanguage(lang: string): void {
    const langShortcut = languages.find(language => language.name === lang).shortcut;
    this.wsHelperService.call(this.userService.setUserLanguage(langShortcut)).subscribe({
      next: user => {
        const currentUser = this.currentUserService.getUserSubject().getValue();
        currentUser.lang = user.lang;
        currentUser.tempLang = null;
        this.tempLangService.clearTempLanguage();
        this.currentUserService.getUserSubject().next(currentUser);
        this.userLanguage = getLanguageByShortcut(user.lang).name;
        this.translateService.use(lang);
      }
    });
  }

  switchLanguage(lang: string) {
    if (this.allowLangSwitch) {
      this.translateService.use(lang);
    }
  }
}

@NgModule({
  exports: [LanguageSwitcherComponent],
  imports: [FlexLayoutModule, CommonModule, MatIconModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule],
  declarations: [LanguageSwitcherComponent]
})
export class LanguageSwitcherComponentModule {}
