import { ScopeTreeComponent } from '@/src/app/features/user/components/scope-tree/scope-tree.component';
import { Scope } from '@/src/app/features/user/models/scope.model';
import { Profile } from '@/src/app/features/user/models/user-data.model';
import { UserService } from '@/src/app/features/user/services/user.service';
import { SelectOption, SelectOptions } from '@shared/components/form/components/select/select.model';
import { InstanceArea } from '@/src/app/shared/models/stxconfiguration.model';
import { StxValidators } from '@/src/app/shared/validation/validators';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Item } from 'src/app/shared/models/item.model';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  selector: 'stx-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  roles: Item[];
  geoScopes: Scope[];
  orgTreeScopes: Scope[];
  checkedOrg: Scope[];
  checkedGeo: Scope[];
  instanceAreaList: SelectOptions;
  readonly maximumProfileNameLength: number = 255;
  profileForm = new UntypedFormGroup({
    selectedRole: new UntypedFormControl(null, StxValidators.required),
    isDomainAccess: new UntypedFormControl(false),
    profileNameFormControl: new UntypedFormControl(null, Validators.maxLength(this.maximumProfileNameLength)),
    designatedArea: new UntypedFormControl(InstanceArea.GLOBAL, StxValidators.required)
  });

  get isDomainAccess(): boolean {
    return this.profileForm.controls.isDomainAccess.value;
  }
  static readonly surgicalQaReviewerSelectValue = 7;

  activeProfileForLogUser: Profile;
  userProfile: Profile;
  domainScope: Scope;
  @ViewChild('geotree') geoTreeComponent: ScopeTreeComponent;
  @ViewChild('orgtree') orgTreeComponent: ScopeTreeComponent;
  @Input()
  set profile(profile: Profile) {
    if (profile) {
      const domainScopeId = 1;
      this.userProfile = profile;
      this.profileForm.controls.selectedRole.setValue(profile.role.id);
      this.profileForm.controls.profileNameFormControl.setValue(profile.name);
      this.profileForm.controls.designatedArea.setValue(profile.designatedArea);
      this.checkedGeo = profile.scopes.filter(scope => scope.type === 'COUNTRY' || scope.type === 'REGION');
      this.checkedOrg = profile.scopes.filter(scope => scope.type === 'PARTNER' || scope.type === 'TREATMENT_CENTER');
      if (!!profile.scopes && profile.scopes.length > 0 && profile.scopes[0].id === domainScopeId) {
        this.profileForm.controls.isDomainAccess.setValue(true);
      }
      this.cd.detectChanges();
    }
  }

  constructor(private userService: UserService, private cd: ChangeDetectorRef, private readonly wsHelperService: WsHelperService) {}

  ngOnInit() {
    this.getUserData();
    this.onChangeDesignatedArea();
    this.initializeInstanceAreaOptions();
    this.setDomainAccessWhenSelectedSurgicalQaReviewer();
  }

  initializeInstanceAreaOptions() {
    this.instanceAreaList = [
      {
        id: InstanceArea.GLOBAL,
        name: InstanceArea.GLOBAL
      } as SelectOption,
      {
        id: InstanceArea.CHINA,
        name: InstanceArea.CHINA
      } as SelectOption
    ];
  }

  getUserData() {
    this.wsHelperService
      .callWithSpinner(
        forkJoin([
          this.userService.getRoles(),
          this.userService.getGeoScope(),
          this.userService.getOrgTreeScope(),
          this.userService.getDomainAccess()
        ])
      )
      .subscribe(result => {
        this.roles = result[0] as Item[];
        this.geoScopes = result[1] as Scope[];
        this.orgTreeScopes = result[2] as Scope[];
        this.domainScope = result[3] as Scope;
      });
  }

  private getScopeIds(): number[] {
    if (this.isDomainAccess) {
      return [this.domainScope.id];
    }
    return this.orgTreeComponent.getSelectionIds().concat(this.geoTreeComponent.getSelectionIds());
  }

  getProfile(): Profile {
    let profile: Profile = new Profile();
    profile.scopesId = this.getScopeIds();
    if (this.profileForm.valid && profile.scopesId.length > 0) {
      if (this.userProfile) {
        profile.id = this.userProfile.id;
      }
      profile.name = this.profileForm.controls.profileNameFormControl.value;
      profile.roleId = this.profileForm.controls.selectedRole.value;
      profile.designatedArea = this.profileForm.controls.designatedArea.value;
    } else {
      profile = null;
    }
    return profile;
  }

  private setDomainAccessWhenSelectedSurgicalQaReviewer() {
    this.profileForm.get('selectedRole').valueChanges.subscribe(value => {
      const domainAccessControl = this.profileForm.get('isDomainAccess');
      if (value === UserProfileComponent.surgicalQaReviewerSelectValue) {
        domainAccessControl.setValue(true);
        domainAccessControl.disable();
      } else if (!!domainAccessControl.value) {
        domainAccessControl.enable();
      }
    });
  }

  isProfileNameLengthNotValid(): boolean {
    return !this.profileForm.get('profileNameFormControl').valid;
  }

  isDesignatedAreaNotGlobal(): boolean {
    return this.profileForm.controls.designatedArea.value !== InstanceArea.GLOBAL;
  }

  private onChangeDesignatedArea() {
    this.profileForm.get('designatedArea').valueChanges.subscribe(designatedArea => {
      if (designatedArea !== InstanceArea.GLOBAL) {
        this.profileForm.controls.isDomainAccess.setValue(false);
      }
    });
  }
}
