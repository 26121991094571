<ng-container *ngIf="!expandable">
  <stx-patient-forms-headers-container [formType]="formType"></stx-patient-forms-headers-container>
  <h1>
    <small>{{ 'presurgical.presentation' | translate }}</small>
  </h1>
  <stx-ortho-treatment-guidelines-link *ngIf="!print"></stx-ortho-treatment-guidelines-link>
  <stx-ortho-treatment-status-bar
    [orthoTreatment]="orthoFormModel"
    (unlockClicked)="unlock()"
    (deleteClicked)="delete()"
  ></stx-ortho-treatment-status-bar>
</ng-container>

<div class="page-section-container" *ngIf="isReadonlyView">
  <stx-ortho-readonly-treatment [treatment]="orthoFormModel"></stx-ortho-readonly-treatment>
</div>
<ng-container *ngIf="!isReadonlyView">
  <form *ngIf="tcList && patient" [formGroup]="treatmentFormGroup">
    <stx-form-panel>
      <p class="form-section-heading">
        {{ 'ortho.general_patient_information' | translate }}
      </p>

      <stx-ortho-general-treatment-info
        #generalTreatmentInfo
        [orthoFormType]="OrthoFormType.PRESURGICAL_ORTHOPEDICS"
        [tcList]="tcList"
        [minCareProvidedDate]="startDateMinValue"
        [maxCareProvidedDate]="startDateMaxValue"
        [minCareProvidedEndDate]="endDateMinValue"
        [formMode]="formMode"
      >
        <div class="form-row">
          <stx-input [label]="'presurgical.follow_up'" [formMode]="formMode" [controlName]="'followup'" [type]="'number'"></stx-input>
          <stx-empty-form-element></stx-empty-form-element>
          <stx-empty-form-element></stx-empty-form-element>
          <stx-empty-form-element></stx-empty-form-element>
        </div>
        <hr class="form-subsection-separator" />
        <div class="form-row">
          <stx-radio-group
            [label]="'presurgical.info_given'"
            [layoutDirection]="'row'"
            [controlName]="'infoGiven'"
            [formMode]="formMode"
            [options]="yesNo"
            [print]="print"
          ></stx-radio-group>
          <stx-textarea [label]="'comments'" [controlName]="'infoGivenMore'" [formMode]="formMode"> </stx-textarea>
        </div>
      </stx-ortho-general-treatment-info>
    </stx-form-panel>

    <stx-expandable-form-panel [expanded]="true">
      <ng-container slot="heading">
        {{ 'photos' | translate }}
      </ng-container>
      <div>
        <p>{{ 'ortho.piso.photo.info.mirror' | translate }}</p>
        <p>{{ 'ortho.piso.photo.info.no_piso_all' | translate }}</p>
        <p>
          {{ 'ortho.piso.photo.info.resources.pre_link' | translate }}
          <stx-static-file-link file="pdf/Orthopedic_Orthodontic_Record_Checklist_{lang}.pdf">
            {{ 'ortho.piso.photo.info.resources.link' | translate }}
          </stx-static-file-link>
          {{ 'ortho.piso.photo.info.resources.post_link' | translate }}
        </p>
      </div>
      <stx-image-group
        [formGroup]="treatmentFormGroup"
        [formMode]="formMode"
        [imageConfigs]="presurgicalOrthopedicsTreatmentImagesConfigs"
        [print]="print"
      >
      </stx-image-group>
    </stx-expandable-form-panel>

    <stx-action-buttons
      *ngIf="!print"
      [asyncSecondaryAction]="onSaveButtonClicked()"
      [asyncPrimaryAction]="treatmentFormGroup.get('isAbandoned').value === true ? onDiscontinue() : onSubmitButtonClicked()"
    ></stx-action-buttons>
  </form>
</ng-container>
