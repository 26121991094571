import { FormStatus } from '@/src/app/shared/enums/form-status.enum';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-compact-patient-header',
  templateUrl: './compact-patient-header.component.html'
})
export class CompactPatientHeaderComponent {
  @Input() rowNumber = 0;
  @Input() status: FormStatus | null = null;
}
