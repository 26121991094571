import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  template: ''
})
export abstract class BaseMediaComponent extends BaseComponent {
  @Input() showFileDownload: boolean;
  @Input() urlConverted: string;
  @Input() urlRaw: string | SafeUrl;
  private _newUploadPreviewUrl: SafeUrl;
  @Input() set newUploadPreviewUrl(value: SafeUrl) {
    this._newUploadPreviewUrl = value;
    this.showConversionInProgressNotice = false;
  }

  showConversionInProgressNotice = false;

  get mediaSourceUrl(): string | SafeUrl {
    return this._newUploadPreviewUrl ?? this.urlConverted ?? this.urlRaw;
  }

  get showConvertedFileDownload(): boolean {
    return this.urlConverted && this.showFileDownload;
  }

  get showRawFileDownload(): boolean {
    return this.urlRaw && this.showFileDownload;
  }

  onMediaSourceFetchError() {
    this.showConversionInProgressNotice = true;
  }

  onMediaSourceFetchSuccess() {
    this.showConversionInProgressNotice = false;
  }
}
