import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { v4 } from 'uuid';
import { UppyErrorService } from '@shared/components/uppy/uppy-error.service';

@Component({
  selector: 'stx-audio-video',
  templateUrl: './audio-video.component.html',
  styleUrls: ['./audio-video.component.scss']
})
export class AudioVideoComponent extends BaseFormElementComponent implements OnInit {
  @Input() label: string;
  @Input() controlName: string;
  @Input() parentOrderName: ParentOrderName;
  @Input() tooltip?: string;

  showEmptyAudioVideoError: boolean = false;

  uuid: string = v4();

  constructor(private uppyErrorService: UppyErrorService, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.watchUppyCustomErrors();
  }

  watchUppyCustomErrors(): void {
    this.subSink.sink = this.uppyErrorService.fileIsEmpty$.subscribe(() => {
      this.showEmptyAudioVideoError = this.uppyErrorService.isUploadedFileEmptyBy(this.uuid);

      if (this.showEmptyAudioVideoError) {
        this.formGroup.get(this.parentOrderName).reset();
        this.formGroup.get(this.parentOrderName).updateValueAndValidity();
      }

      this.changeDetectorRef.markForCheck();
    });
  }
}
