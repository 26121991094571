<ng-container *ngIf="isReadonlyView">
  <stx-patient-forms-headers-container
    *ngIf="!expandable"
    [formType]="formType"
    cssClass="m-l-25 m-b-25"
  ></stx-patient-forms-headers-container>

  <stx-treatment-status-bar
    *ngIf="!expandable"
    [treatment]="surgeryFollowUp"
    (unlockClicked)="unlock()"
    (deleteClicked)="delete()"
  ></stx-treatment-status-bar>

  <div class="page-section-container">
    <stx-surgical-follow-up-readonly [treatment]="surgeryFollowUp"></stx-surgical-follow-up-readonly>
  </div>
</ng-container>
<ng-container *ngIf="!isReadonlyView">
  <stx-patient-forms-headers-container
    *ngIf="!expandable"
    [formType]="formType"
    cssClass="m-l-25 m-b-25"
  ></stx-patient-forms-headers-container>

  <stx-treatment-status-bar
    *ngIf="!expandable"
    [treatment]="surgeryFollowUp"
    (unlockClicked)="unlock()"
    (deleteClicked)="delete()"
  ></stx-treatment-status-bar>

  <form [formGroup]="treatmentFormGroup">
    <stx-expandable-form-section [expanded]="true">
      <ng-container slot="heading">{{ 'surgery_follow_up.genereal_patient_info' | translate }}</ng-container>
      <stx-form-panel>
        <div class="form-row form-columns-1-2-1">
          <stx-generic-form-element
            key="dateVisit"
            [formElementSetup]="followUpInfoSetup.get('dateVisit')"
            [minDate]="minDate"
          ></stx-generic-form-element>
          <stx-generic-form-element
            key="treatmentCenterId"
            [formElementSetup]="followUpInfoSetup.get('treatmentCenterId')"
          ></stx-generic-form-element>
          <stx-generic-form-element key="telehealth" [formElementSetup]="followUpInfoSetup.get('telehealth')"></stx-generic-form-element>
        </div>
        <hr class="form-subsection-separator" />
        <div class="form-row">
          <stx-generic-form-element key="healWell" [formElementSetup]="followUpInfoSetup.get('healWell')"></stx-generic-form-element>
        </div>
        <hr class="form-subsection-separator" />
        <div class="form-columns-1-2">
          <stx-generic-form-element
            key="healComplication"
            [formElementSetup]="followUpInfoSetup.get('healComplication')"
          ></stx-generic-form-element>
          <stx-generic-form-element
            key="healComplicationComment"
            [formElementSetup]="followUpInfoSetup.get('healComplicationComment')"
          ></stx-generic-form-element>
        </div>
        <hr class="form-subsection-separator" />
        <div class="form-columns-1-2">
          <stx-generic-form-element key="needSurgery" [formElementSetup]="followUpInfoSetup.get('needSurgery')"></stx-generic-form-element>
          <stx-generic-form-element
            key="needSurgeryComment"
            [formElementSetup]="followUpInfoSetup.get('needSurgeryComment')"
          ></stx-generic-form-element>
        </div>
        <hr class="form-subsection-separator" />
        <div class="form-columns-1-2">
          <stx-generic-form-element key="needSpeech" [formElementSetup]="followUpInfoSetup.get('needSpeech')"></stx-generic-form-element>
          <stx-generic-form-element
            key="needSpeechComment"
            [formElementSetup]="followUpInfoSetup.get('needSpeechComment')"
          ></stx-generic-form-element>
        </div>
        <hr class="form-subsection-separator" />
        <div class="form-columns-1-2">
          <stx-generic-form-element key="needOrtho" [formElementSetup]="followUpInfoSetup.get('needOrtho')"></stx-generic-form-element>
          <stx-generic-form-element
            key="needOrthoComment"
            [formElementSetup]="followUpInfoSetup.get('needOrthoComment')"
          ></stx-generic-form-element>
        </div>
        <hr class="form-subsection-separator" />
        <div>
          <stx-generic-form-element
            key="properWeight"
            [formElementSetup]="followUpInfoSetup.get('properWeight')"
          ></stx-generic-form-element>
        </div>
        <hr class="form-subsection-separator" />
        <div>
          <stx-generic-form-element
            key="properWeightImprove"
            [formElementSetup]="followUpInfoSetup.get('properWeightImprove')"
          ></stx-generic-form-element>
        </div>
        <hr class="form-subsection-separator" />
        <div class="form-columns-1-2">
          <stx-generic-form-element
            key="attendSchool"
            [formElementSetup]="followUpInfoSetup.get('attendSchool')"
          ></stx-generic-form-element>
          <stx-generic-form-element
            key="attendSchoolImprove"
            [formElementSetup]="followUpInfoSetup.get('attendSchoolImprove')"
          ></stx-generic-form-element>
        </div>
        <hr class="form-subsection-separator" />
        <div class="form-row">
          <stx-generic-form-element
            key="outcomeSentiment"
            [formElementSetup]="followUpInfoSetup.get('outcomeSentiment')"
          ></stx-generic-form-element>
        </div>
      </stx-form-panel>
    </stx-expandable-form-section>

    <stx-action-buttons *ngIf="!print" [showPrimary]="false" [asyncSecondaryAction]="onSaveButtonClicked()"></stx-action-buttons>

    <div [ngClass]="{ 'page-break-inside-avoid': print }">
      <stx-expandable-form-section [expanded]="true">
        <ng-container slot="heading">{{ 'photos' | translate }}</ng-container>
        <stx-form-panel>
          <stx-image-group [formGroup]="treatmentFormGroup" [formMode]="formMode" [imageConfigs]="imageConfigs" [print]="print">
          </stx-image-group>
        </stx-form-panel>
      </stx-expandable-form-section>
    </div>

    <stx-action-buttons
      *ngIf="!print"
      [asyncSecondaryAction]="onSaveButtonClicked()"
      [asyncPrimaryAction]="onSubmitButtonClicked()"
    ></stx-action-buttons>
  </form>
</ng-container>
