<ng-container *ngIf="dataSource?.data.length">
  <mat-paginator
    *ngIf="showPagination"
    [length]="totalResults"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [pageIndex]="currentPageIndex"
    (page)="changePage($event)"
  ></mat-paginator>
  <div class="data-tile-container table-container-common">
    <table
      mat-table
      aria-hidden="true"
      [dataSource]="dataSource"
      class="mat-elevation-z2"
      aria-describedby="data-tile-container table-container-common"
    >
      <ng-container matColumnDef="patient">
        <td mat-cell *matCellDef="let recordData; let i = index" class="p-0">
          <stx-compact-patient-header [rowNumber]="calcAbsoluteRowIndex(i)"></stx-compact-patient-header>
          <div class="p-r-24 p-l-24 p-b-10">
            <stx-compact-patient-data *ngIf="!deIdentified" [recordData]="recordData"></stx-compact-patient-data>
            <stx-inline-readonly-field>
              <stx-inline-readonly-field *ngIf="!deIdentified" labelKey="patient.gender" [values]="[recordData.gender, '']">
              </stx-inline-readonly-field>
              <stx-inline-readonly-field labelKey="age" [values]="[recordData.ageMonth | ageYearMonth, '']"> </stx-inline-readonly-field>
              <stx-inline-readonly-field labelKey="patient.weight" [values]="[recordData.weight | unit : 'unit.weight']">
              </stx-inline-readonly-field>
            </stx-inline-readonly-field>
            <stx-inline-readonly-field
              *ngIf="!deIdentified"
              labelKey="surgeon"
              [values]="[recordData.surgeon | localName : recordData.surgeonLoc]"
            >
            </stx-inline-readonly-field>
            <stx-inline-readonly-field>
              <stx-inline-readonly-field
                labelKey="surgical_intervention.operation_type"
                [translationKeys]="recordData.operations"
              ></stx-inline-readonly-field>
              <stx-value-separator></stx-value-separator>
              <stx-inline-readonly-field
                labelKey="treatment.operation_date"
                [values]="[recordData.operationDate, '']"
              ></stx-inline-readonly-field>
              <stx-inline-readonly-field
                labelKey="treatment.discharge_date"
                [values]="[recordData.dischargeDate, '']"
              ></stx-inline-readonly-field>
              <stx-inline-readonly-field
                labelKey="report.filters.repair_types.header"
                [dictionaryValues]="recordData.repairTypes"
              ></stx-inline-readonly-field>
            </stx-inline-readonly-field>
            <hr class="form-section-separator" />
            <div class="wrap m-b-30 flex-row-container gap-50-grid">
              <div *ngFor="let photo of recordData.photos" class="m-b-30 wrap flex-row-container gap-50-grid">
                <div class="wrap flex-row-container gap-50-grid" *ngFor="let preSurgeryPhoto of photo.preTreatment; index as index">
                  <stx-image-thumbnail
                    thumbnailId="{{ 'pre-surgery-' + photo.preTreatmentId }}"
                    imageAlt="{{ 'surgical_intervention.pre_surgery' | translate }}"
                    [imageUrl]="preSurgeryPhoto"
                    [mediaId]="photo.preTreatmentId"
                  >
                    {{ 'surgical_intervention.pre_surgery' | translate }}
                  </stx-image-thumbnail>
                </div>
                <div class="wrap flex-row-container gap-50-grid" *ngFor="let postSurgeryPhoto of photo.postTreatment; index as index">
                  <stx-image-thumbnail
                    thumbnailId="{{ 'post-surgery-' + photo.postTreatmentId }}"
                    imageAlt="{{ 'surgical_intervention.post_surgery' | translate }}"
                    [imageUrl]="postSurgeryPhoto"
                    [mediaId]="photo.postTreatmentId"
                  >
                    {{ 'surgical_intervention.post_surgery' | translate }}
                  </stx-image-thumbnail>
                </div>
              </div>
              <div *ngIf="!!recordData?.otherPhotos?.length">
                <div class="wrap flex-row-container gap-50-grid">
                  <div class="align-self-bottom" *ngFor="let imageUrl of recordData.otherPhotos; first as isFirst; index as index">
                    <stx-image-thumbnail
                      thumbnailId="{{ 'other_' + index }}"
                      imageAlt="{{ 'other' | translate }}"
                      [imageUrl]="imageUrl.url"
                      [mediaId]="imageUrl.id"
                    >
                      <span *ngIf="isFirst">{{ 'other' | translate }}</span>
                    </stx-image-thumbnail>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    *ngIf="showPagination"
    [length]="totalResults"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [pageIndex]="currentPageIndex"
    (page)="changePage($event)"
  ></mat-paginator>
</ng-container>
<div *ngIf="reportTableDataService.dataReady && dataSource?.data.length === 0">
  <stx-empty-table-state></stx-empty-table-state>
</div>
