<ng-container *ngIf="dataSource?.data.length">
  <mat-paginator
    [length]="totalResults"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [pageIndex]="currentPageIndex"
    (page)="changePage($event)"
  ></mat-paginator>
  <div class="data-table-container">
    <stx-simple-report-table [columns]="columns" [dataSource]="dataSource" [displayedColumns]="displayedColumns"></stx-simple-report-table>
    <mat-paginator
      [length]="totalResults"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="changePage($event)"
    ></mat-paginator>
  </div>
</ng-container>
<div *ngIf="reportTableDataService.dataReady && dataSource?.data.length === 0">
  <stx-empty-table-state></stx-empty-table-state>
</div>
