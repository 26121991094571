import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { getTreatmentTypeData } from '@utils/treatment.utils';
import { TreatmentType } from '@shared/components/treatment/treatment.enum';
import { VisibilityScope } from '@shared/models/visibility-scope.model';
import { nutritionTypeToShortLabelMap } from '@src/app/features/nutrition-management/utils/nutrition-management.utils';
import { TreatmentItemData } from '@src/app/features/patient/models/patient.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-patient-treatment-minimized',
  templateUrl: './patient-treatment-minimized.component.html',
  styleUrls: ['./patient-treatment-minimized.component.scss']
})
export class PatientTreatmentMinimizedComponent implements OnInit {
  @Input() treatment: TreatmentItemData;

  treatmentTypes: string[];
  ageGroupLabel: string;
  treatmentOutsideOfScopeLabel: string;

  readonly TreatmentType = TreatmentType;

  ngOnInit(): void {
    this.treatmentTypes = this.initialiseTreatmentTypes();
    this.ageGroupLabel = this.initialiseNutritionLabel();
    this.treatmentOutsideOfScopeLabel = this.setTreatmentOutsideOfScopeLabelIfNecessary();
  }

  isTreatmentOutOfScopeForUser(): boolean {
    return this.treatment?.visibilityScope === VisibilityScope.OUT_OF_SCOPE;
  }

  private initialiseNutritionLabel(): string {
    if (this.isTreatmentLoaded()) {
      return nutritionTypeToShortLabelMap.get(this.treatment.nutritionManagementAgeGroup);
    }

    return '';
  }

  private initialiseTreatmentTypes(): string[] {
    if (this.isTreatmentLoaded()) {
      switch (this.treatment.treatmentType) {
        case this.TreatmentType.SURGERY:
          return this.treatment.surgeryOperationType?.map(type => `surgery.${type}`);

        case this.TreatmentType.SPEECH_ASSESSMENT:
          return [getTreatmentTypeData(this.treatment.treatmentType).name, this.treatment.speechAssessmentStage];

        default:
          return [getTreatmentTypeData(this.treatment.treatmentType).name];
      }
    }

    return [];
  }

  private setTreatmentOutsideOfScopeLabelIfNecessary(): string {
    if (!this.isTreatmentLoaded()) return '';

    if (this.isTreatmentOutOfScopeForUser()) {
      return 'partner.other_st_partner';
    }

    return '';
  }

  private isTreatmentLoaded(): boolean {
    return !!this.treatment;
  }

  get isNutritionTreatment(): boolean {
    return (
      this.treatment.treatmentType === TreatmentType.NUTRITION_ASSESSMENT ||
      this.treatment.treatmentType === TreatmentType.NUTRITION_FOLLOW_UP
    );
  }
}
