<button
  data-testid="button-submit"
  type="submit"
  mat-raised-button
  color="primary"
  class="text-uppercase"
  (click)="handleClick()"
  [disabled]="buttonDisablerService.hasActiveTasks || buttonDisabled || actionInProgress"
>
  <span class="flex-row-container align-center layout-align-center">
    <span class="flex">
      <ng-content></ng-content>
    </span>
  </span>
</button>
