import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionModule } from 'src/app/core/permissions/permission.module';
import { FormModule } from '@shared/components/form/form.module';
import { FormsCommonsModule } from '@shared/modules/forms-commons/forms-commons.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { MaterialModule } from '@shared/modules/material/material.module';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { IfEmptyPipeModule } from '@shared/pipes/if-empty.pipe';
import { HistoricDiagnosesDetailsComponent } from './surgical/details/historic-diagnoses-details/historic-diagnoses-details.component';
import { HistoricDiagnoseDetailRowComponent } from './surgical/details/historic-diagnoses-details/historic-diagonose-details-row/historic-diagnose-detail-row.component';
import { HistoricTreatmentDetailRowComponent } from './surgical/details/historic-treatments-details/historic-treatment-detail-row/historic-treatment-detail-row.component';
import { HistoricTreatmentsDetailsComponent } from './surgical/details/historic-treatments-details/historic-treatments-details.component';
import { SurgeryDetailsComponent } from './surgical/details/surgery-details/surgery-details.component';
import { GradingFormComponent } from './surgical/grading-form/grading-form.component';
import { SurgicalReviewPageComponent } from './surgical/surgical-review-page.component';

@NgModule({
  declarations: [
    SurgicalReviewPageComponent,
    GradingFormComponent,
    SurgeryDetailsComponent,
    HistoricTreatmentsDetailsComponent,
    HistoricDiagnosesDetailsComponent,
    HistoricDiagnoseDetailRowComponent,
    HistoricTreatmentDetailRowComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PermissionModule,
    TranslateModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    FormModule,
    FormsCommonsModule,
    GeneralCommonsModule,
    ReadonlyCommonsModule,
    IfEmptyPipeModule
  ]
})
export class ReviewModule {}
