import { MediaUrl } from '@/src/app/features/media/models/media-url.model';
import { DisplayFileInfo } from '@/src/app/features/media/models/media.model';
import { MediaType } from '@/src/app/shared/enums/media.enum';
import { LoggerService } from '@/src/app/shared/services/logger.service';
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { UppyComponent } from '@shared/components/uppy/components/uppy.component';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { dataTransferFileKind } from '@utils/file.utils';
import { UppyErrorService } from '@shared/components/uppy/uppy-error.service';
import { v4 } from 'uuid';
import { BaseComponent } from '@shared/components/base-component/base.component';

@Component({
  selector: 'stx-media-component-base',
  template: ''
})
export class MediaComponentBaseComponent extends BaseComponent implements OnDestroy {
  @ViewChild(UppyComponent) uppyComponent: UppyComponent;

  rawUrl: string;
  convertedUrl: string;
  uploadedFileUrl: string;
  previousUploadedFileUrl: string;

  @Input() uuid: string = v4();

  constructor(
    protected loggerService: LoggerService,
    protected globalErrorHandlerService: GlobalErrorHandlerService,
    protected uppyErrorService: UppyErrorService
  ) {
    super();
  }

  protected setNewValue(singleControlRawAndConvertedUrls?: MediaUrl[]): void {
    if (!singleControlRawAndConvertedUrls) {
      return;
    }
    if (Array.isArray(singleControlRawAndConvertedUrls)) {
      this.setApiData(singleControlRawAndConvertedUrls);
    } else {
      this.loggerService.error('Unable to handle audio/video file from API', singleControlRawAndConvertedUrls);
      throw new TypeError(`Unable to handle ${singleControlRawAndConvertedUrls}`);
    }
  }

  protected setApiData(existingMedia: MediaUrl[]) {
    if (!existingMedia) {
      this.rawUrl = null;
      this.convertedUrl = null;
    }
    const raw = existingMedia.find(mediaUrl => mediaUrl.urlType === MediaType.RAW);
    this.rawUrl = raw ? raw.url : null;

    const converted = existingMedia.find(mediaUrl => mediaUrl.urlType === MediaType.CONVERTED);
    this.convertedUrl = converted ? converted.url : null;
  }

  preventOpenForDragOver(dragEvent: DragEvent) {
    dragEvent.preventDefault();
  }

  onDrop(dragEvent: DragEvent) {
    dragEvent.preventDefault();

    const files = MediaComponentBaseComponent.getFilesFromDragEvent(dragEvent);
    if (!this.uppyComponent) {
      this.globalErrorHandlerService.handleErrorAndInformUser(new Error('MediaComponentBaseComponent.uppyComponent not initialised!'));
      return;
    }
    files.forEach(file => {
      this.uppyComponent.addFileDragAndDropFile(file);
    });
  }

  private static getFilesFromDragEvent(dragEvent: DragEvent): File[] {
    const files: File[] = [];
    if (dragEvent.dataTransfer.items) {
      for (let i = 0; i < dragEvent.dataTransfer.items.length; i++) {
        if (dragEvent.dataTransfer.items[i].kind === dataTransferFileKind) {
          files.push(dragEvent.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      if (dragEvent.dataTransfer.files) {
        for (let i = 0; i < dragEvent.dataTransfer.files.length; i++) {
          files.push(dragEvent.dataTransfer.files[i]);
        }
      }
    }
    return files;
  }

  displayFile(fileInfo: DisplayFileInfo) {
    this.uploadedFileUrl = URL.createObjectURL(fileInfo.file);
  }

  protected revokeUrlIfExists(url: string) {
    if (url) {
      URL.revokeObjectURL(url);
    }
  }

  restoreOldPreview(): void {
    this.revokeUrlIfExists(this.uploadedFileUrl);
    this.uploadedFileUrl = this.previousUploadedFileUrl;
  }

  ngOnDestroy(): void {
    this.revokeUrlIfExists(this.uploadedFileUrl);
    this.revokeUrlIfExists(this.previousUploadedFileUrl);
  }
}
