<div class="display-flex align-middle p-b-5 p-t-5 flex-row-container wrap">
  <span *ngIf="labelKey" class="bold m-r-5">{{ labelKey | translate }}</span>
  <ng-content></ng-content>
  <ng-container *ngFor="let value of values; last as isLast">
    <span>
      {{ value | translate }}
    </span>
    <stx-value-separator *ngIf="!isLast"></stx-value-separator>
  </ng-container>
  <ng-container *ngFor="let value of dictionaryValues; last as isLast">
    <span>
      {{ value.label | translate }}
    </span>
    <stx-value-separator *ngIf="!isLast"></stx-value-separator>
  </ng-container>
  <ng-container *ngFor="let translationKey of translationKeys; last as isLast">
    <span>
      {{ translationKey | translate }}
    </span>
    <stx-value-separator *ngIf="!isLast"></stx-value-separator>
  </ng-container>
</div>
