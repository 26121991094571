import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { filter } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { merge } from 'rxjs';
import { slideDown, slideUp } from '@core/animations';
import { transition, trigger, useAnimation } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { TIMINGS } from '@core/animations/constant.animations';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ChevronLineComponentModule } from '@shared/components/chevron-line/chevron-line.component';
import { FormModule } from '@shared/components/form/form.module';
import {
  ReportDictionaryTree,
  ReportDictionaryTreeSubsection,
  ReportFilterChange
} from '@src/app/features/reports/components/report-filters/models/report-filters.model';
import { RadioTreeChildOption, RadioTreeChildOptionValue, RadioTreeRootOption } from './radio-tree-filter.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-radio-tree-filter',
  templateUrl: './radio-tree-filter.component.html',
  animations: [
    trigger('childrenOptions', [
      transition(
        'void => *',
        useAnimation(slideDown, {
          params: {
            timing: TIMINGS.VERY_FAST
          }
        })
      ),
      transition(
        '* => void',
        useAnimation(slideUp, {
          params: {
            timing: TIMINGS.VERY_FAST
          }
        })
      )
    ])
  ]
})
export class RadioTreeComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() dictionariesTree: ReportDictionaryTree;
  @Input() formGroupReference: UntypedFormGroup;
  @Input() rootOptionParamName: string;
  @Input() showChevron = true;

  @Output() filterChange = new EventEmitter<ReportFilterChange<Record<string, number[]>, string>>();

  childrenOptionsGroup: UntypedFormGroup;
  rootOptionsControl: UntypedFormControl;
  optionsTree: RadioTreeRootOption[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.childrenOptionsGroup = this.formGroupReference.get('childrenOptions') as UntypedFormGroup;
    this.rootOptionsControl = this.formGroupReference.get('rootOption') as UntypedFormControl;
    this.syncChildParentSelection();
    this.syncParentChildSelection();
    this.emitModel();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dictionariesTree) {
      this.setOptionsTree();
    }
  }

  toggleChildrenOptions(rootOptionValue: string) {
    const rootOption = this.optionsTree.find(option => option.value === rootOptionValue);

    rootOption.visible = !rootOption.visible;
  }

  private emitModel() {
    const rootOptionValue = this.rootOptionsControl.value;
    const childOptionValue: RadioTreeChildOptionValue = this.childrenOptionsGroup.get(rootOptionValue)?.value;
    const payload = {};

    if (rootOptionValue) {
      payload[this.rootOptionParamName] = [rootOptionValue];
    }

    if (childOptionValue) {
      payload[childOptionValue.paramName] = [childOptionValue.raw];
    }

    this.filterChange.emit({
      filterName: this.rootOptionParamName,
      payload
    });
  }

  private syncChildParentSelection() {
    const childrenOptionsChanges = Object.values(this.childrenOptionsGroup.controls).map(control => control.valueChanges);

    this.subSink.sink = merge(...childrenOptionsChanges)
      .pipe(filter(selectedOption => !!selectedOption))
      .subscribe((selectedChildOption: RadioTreeChildOptionValue) => {
        if (this.rootOptionsControl.value !== selectedChildOption.rootOptionValue) {
          this.rootOptionsControl.setValue(selectedChildOption.rootOptionValue);
        }
        this.emitModel();
      });
  }

  private syncParentChildSelection() {
    this.subSink.sink = this.rootOptionsControl.valueChanges.subscribe((value: string | number) => {
      const notRelatedChildrenControls = Object.entries(this.childrenOptionsGroup.controls)
        .filter(([controlName]) => controlName !== value?.toString())
        .map(([, control]) => control as UntypedFormControl);

      notRelatedChildrenControls
        .filter(control => !!control.value)
        .forEach(control => {
          control.setValue(null);
        });
      this.emitModel();
    });
  }

  private flatChildrenOptions(rootOptionValue, dictionarySubSections: ReportDictionaryTreeSubsection[] = []): RadioTreeChildOption[] {
    return dictionarySubSections
      .map(subSection => {
        const paramName = subSection.name;

        return subSection.values.map<RadioTreeChildOption>(dictionarySubSection => {
          const childOption: RadioTreeChildOption = {
            formControlName: dictionarySubSection.value,
            label: dictionarySubSection.label,
            tooltipText: dictionarySubSection.tooltipText || '',
            value: {
              paramName,
              raw: dictionarySubSection.value,
              rootOptionValue
            }
          };

          return childOption;
        });
      })
      .reduce((childrenOptionsFlat, childrenOptions) => {
        return [...childrenOptionsFlat, ...childrenOptions];
      }, []);
  }

  private setOptionsTree() {
    this.optionsTree = Object.entries(this.dictionariesTree).map(([rootOptionName, rootOption]) => {
      const childrenOptions = this.flatChildrenOptions(rootOption.value, rootOption.subSections);

      return {
        formControlName: rootOptionName,
        label: rootOption.label,
        value: rootOption.value,
        tooltipText: rootOption.tooltipText || '',
        childrenOptions,
        visible: true,
        description: rootOption.description
      };
    });
  }
}

@NgModule({
  imports: [
    ChevronLineComponentModule,
    CommonModule,
    FormModule,
    FormsModule,
    MatIconModule,
    MatRadioModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [RadioTreeComponent],
  exports: [RadioTreeComponent]
})
export class RadioTreeComponentModule {}
