import { TreatmentTypeFilterBaseComponent } from '@/src/app/features/search/components/filters/treatment-type-filter/treatment-type-filter-base';
import { TreatmentFilter, TreatmentFilterFlatNode } from '@/src/app/features/search/models/treatment-filter.model';
import { getTypeAndStatusFilters } from '@/src/app/features/search/search.utils';
import { mapToSurgicalTypeEnum } from '@/src/app/features/surgical/utils/surgical.utils';
import { ArrayUtils } from '@/src/app/utils/array.utils';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FormStatus } from '@shared/enums/form-status.enum';

@Component({
  selector: 'stx-treatment-type-filter',
  templateUrl: './treatment-type-filter.component.html',
  styleUrls: ['./treatment-type-filter.component.scss']
})
export class TreatmentTypeFilterComponent extends TreatmentTypeFilterBaseComponent implements OnInit {
  @Input() formGroupReference: UntypedFormGroup;
  @Input() treatmentTree: TreatmentFilter[];
  @Input() surgicalOperationTypes: TreatmentFilter[];
  @Input() statusesForEmptyStatusList: FormStatus[];
  @Input() expanded = false;

  private previousSurgicalInterventionStatuses: FormStatus[];
  @Input() nutritionOperationTypes: TreatmentFilter[];
  constructor() {
    super();
  }

  transformer = (node: TreatmentFilter, level: number): TreatmentFilterFlatNode => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.treatment === node ? existingNode : new TreatmentFilterFlatNode();
    flatNode.treatment = node;
    flatNode.level = level;
    flatNode.expandable = node.children && node.children.length > 0;
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  ngOnInit(): void {
    this.initTreatmentTree();

    this.treatmentTypeTree = this.filterTreatmentTypesByFeatureToggle(this.treatmentTypeTree);
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TreatmentFilterFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    if (this.treatmentTypeTree) {
      this.dataSource.data = this.treatmentTypeTree;
    }
    if (this.expanded) {
      this.treeControl.expandAll();
    }
  }

  initTreatmentTree(): void {
    if (this.surgicalOperationTypes) {
      this.treatmentTypeTree = getTypeAndStatusFilters(this.surgicalOperationTypes);
    } else if (this.treatmentTree) {
      this.treatmentTypeTree = this.treatmentTree;
    }
  }

  filterTreatmentTypesByFeatureToggle(treatmentFilters: TreatmentFilter[]): TreatmentFilter[] {
    const anyActiveHaveChildren = treatmentFilters.filter(f => f.children).length > 0;
    if (anyActiveHaveChildren) {
      return treatmentFilters.map(parentNode => {
        if (parentNode.children) {
          return {
            ...parentNode,
            children: this.filterTreatmentTypesByFeatureToggle(parentNode.children)
          };
        } else {
          return parentNode;
        }
      });
    } else {
      return treatmentFilters;
    }
  }

  toggleExpandableCheckboxNode(node: TreatmentFilterFlatNode, checkStatuses = true): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);

    if (this.hasIndeterminateSelectionState(node)) {
      this.checklistSelection.select(...descendants);
      this.checklistSelection.toggle(node);
    } else if (this.checklistSelection.isSelected(node)) {
      this.checklistSelection.select(...descendants);
    } else {
      this.checklistSelection.deselect(...descendants);
    }

    if (checkStatuses) {
      this.checkAndDeselectNodeStatuses(node, true);
    }
    this.checkAndToggleParentNode(node, checkStatuses);
    this.writeFilterStateToFormGroup();
  }

  toggleCheckboxLeaf(node: TreatmentFilterFlatNode, checkStatuses = true): void {
    this.checklistSelection.toggle(node);
    if (checkStatuses) {
      this.checkAndDeselectNodeStatuses(node);
    }
    this.checkAndToggleParentNode(node, checkStatuses);
    this.parseAndWriteSelectedTreatmentTypes();
  }

  private checkAndToggleParentNode(node: TreatmentFilterFlatNode, checkStatuses = true): void {
    let parent = this.getParentNode(node);
    while (parent) {
      this.checkAndToggleRootNode(parent, checkStatuses);
      parent = this.getParentNode(parent);
    }
  }

  private checkAndToggleRootNode(rootNode: TreatmentFilterFlatNode, checkStatuses = true): void {
    const rootSelected = this.checklistSelection.isSelected(rootNode);
    const areAllDescendantsSelected = this.areAllDescendantsSelected(rootNode);
    const isNoDescendantSelected = this.isNoDescendantSelected(rootNode);
    if (rootSelected && isNoDescendantSelected) {
      this.checklistSelection.deselect(rootNode);
    } else if (!rootSelected && areAllDescendantsSelected) {
      this.checklistSelection.select(rootNode);
    }
    if (checkStatuses) {
      this.checkAndDeselectNodeStatuses(rootNode, true);
    }
  }

  private checkAndDeselectNodeStatuses(node: TreatmentFilterFlatNode, checkDescendants = false) {
    if (checkDescendants) {
      const descendants = this.treeControl.getDescendants(node);
      descendants.forEach(descendant => this.checkAndDeselectNodeStatuses(descendant));
    }

    if (node.treatment.treatmentStatuses && this.isDeselected(node)) {
      this.formGroupReference.get(node.treatment.treatmentType.value).reset();
    }
  }

  onSurgeryInterventionStatusChange(statuses: FormStatus[], node: TreatmentFilterFlatNode): void {
    const operationTypeDescendants = this.treeControl.getDescendants(node);
    const isFirstStatusSelection = !this.previousSurgicalInterventionStatuses?.length && statuses.length === 1;
    const anyOperationTypeAlreadySelected = this.isAnyDescendantSelected(node);

    if (isFirstStatusSelection && !anyOperationTypeAlreadySelected) {
      operationTypeDescendants
        .filter(descendant => !this.checklistSelection.isSelected(descendant))
        .forEach(descendant => this.toggleCheckboxLeaf(descendant, false));
    }

    this.previousSurgicalInterventionStatuses = statuses;
    this.writeFilterStateToFormGroup();
  }

  onLeafCheckboxStatusChange(statuses: FormStatus[], node: TreatmentFilterFlatNode) {
    if (statuses.length > 0 && !this.checklistSelection.isSelected(node)) {
      this.toggleCheckboxLeaf(node, false);
    }
    this.writeFilterStateToFormGroup();
  }

  /** Reading and writing filter state to form group */
  private writeFilterStateToFormGroup(): void {
    if (this.surgicalOperationTypes) {
      this.parseAndWriteSurgicalOperationTypes();
    }
    if (this.nutritionOperationTypes) {
      this.parseAndWriteNutritionOperationTypes();
    }
    this.parseAndWriteSelectedTreatmentTypes();
  }

  private parseAndWriteNutritionOperationTypes(): void {
    const selectedNodes = this.checklistSelection.selected.filter(node => node.level === 2);

    const operationTypes = selectedNodes
      .map(node => node.treatment.treatmentType)
      .map(type => mapToSurgicalTypeEnum(type.value.toUpperCase()));

    this.formGroupReference.get('nutritionInterventionTypes').setValue(operationTypes);
  }

  private parseAndWriteSurgicalOperationTypes(): void {
    const selectedNodes = this.checklistSelection.selected.filter(node => node.level === 2);

    const operationTypes = selectedNodes
      .map(node => node.treatment.treatmentType)
      .map(type => mapToSurgicalTypeEnum(type.value.toUpperCase()));

    this.formGroupReference.get('surgicalInterventionTypes').setValue(operationTypes);
  }

  private parseAndWriteSelectedTreatmentTypes(): void {
    const selectedTreatments = this.checklistSelection.selected.filter(node => node.level === 1);
    const selectedTreatmentSubTypeValues = this.checklistSelection.selected
      .filter(node => node.level === 2)
      .map(selectedSubTypes => selectedSubTypes.treatment.treatmentType.value);

    this.nestedNodeMap.forEach((treatmentFilterFlatNode, treatmentFilter) => {
      if (treatmentFilterFlatNode.level !== 1) {
        return;
      }

      const matchingTreatmentTypeControl = this.formGroupReference.get(treatmentFilterFlatNode.treatment.treatmentType.value);

      const isSubTypeSelectedForCurrType =
        treatmentFilter?.children?.length > 0
          ? treatmentFilter.children
              .map(treatmentSubType => treatmentSubType.treatmentType.value)
              .filter(treatmentSubTypes => ArrayUtils.contains(selectedTreatmentSubTypeValues, treatmentSubTypes)).length > 0
          : false;

      // Nodes with indeterminate selection state are not included in selectedTreatments
      const shouldCurrTypeBeSelected =
        !!selectedTreatments.find(t => t.treatment.treatmentType.value === treatmentFilter.treatmentType.value) ||
        isSubTypeSelectedForCurrType;

      if (shouldCurrTypeBeSelected) {
        // Despite not selecting any status we send to backend all statuses
        if (!matchingTreatmentTypeControl?.value || matchingTreatmentTypeControl?.value?.length === 0) {
          if (treatmentFilterFlatNode.treatment.treatmentStatuses.length > 0) {
            matchingTreatmentTypeControl.setValue(
              treatmentFilterFlatNode.treatment.treatmentStatuses.map(status => status.status),
              { emitEvent: false, onlySelf: true }
            );
          } else if (this.statusesForEmptyStatusList) {
            matchingTreatmentTypeControl.setValue(this.statusesForEmptyStatusList, { emitEvent: false, onlySelf: true });
          }
        }
      } else {
        matchingTreatmentTypeControl.reset();
      }
    });
  }
}
