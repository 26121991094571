import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReportFiltersModule } from '../../../components/report-filters/report-filters.component';
import { ReportHeaderComponentModule } from '../../../components/report-header/report-header.component';
import { SpeechTreatmentsReportTableComponentModule } from '../components/speech-treatments-report-table/speech-treatments-report-table.component';
import { PaginationSortingModel } from '@/src/app/shared/components/table/models/pagination-sorting.model';
import { ReportConfig, ReportDownloadTypes } from '../../../components/report-filters/models/report-filters.model';
import { ReportTableDataService } from '../../../components/report-filters/services/report-table-data.service';
import { ReportService } from '../../../components/report-filters/services/report.service';

@Component({
  selector: 'stx-speech-treatment-report',
  templateUrl: './speech-treatment-report.component.html'
})
export class SpeechTreatmentReportComponent {
  reportConfig: ReportConfig = {
    type: 'SPEECH_TREATMENTS',
    downloadOptions: {
      [ReportDownloadTypes.EXCEL]: true,
      [ReportDownloadTypes.EXCEL_ADMIN]: true,
      [ReportDownloadTypes.EXCEL_ADMIN_ID]: true
    },
    filters: {
      age: true,
      gender: true,
      geography: true,
      status: true,
      organizationsTree: true,
      practitioners: true,
      recordDate: true,
      speechTreatmentStates: true
    }
  };
  constructor(private reportTableDataService: ReportTableDataService, public reportService: ReportService) {}

  applyFilters(pagination: PaginationSortingModel) {
    this.reportTableDataService.prepareList(this.reportConfig.type, this.reportService.model, pagination);
  }
}
@NgModule({
  imports: [
    CommonModule,
    ReportFiltersModule,
    ReportHeaderComponentModule,
    SpeechTreatmentsReportTableComponentModule,
    TranslateModule,
    GeneralCommonsModule
  ],
  declarations: [SpeechTreatmentReportComponent],
  exports: [SpeechTreatmentReportComponent]
})
export class SpeechTreatmentReportComponentModule {}
