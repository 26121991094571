import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { SubSink } from 'subsink';

export const dateInputMaxValidationError = 'matDatepickerMax';
export const dateInputMinValidationError = 'matDatepickerMin';

export function resetFormGroupValidationState(formGroup: UntypedFormGroup) {
  formGroup.markAsPristine();
  formGroup.markAsUntouched();
}

export function registerUpdateValueAndValidityForAllControls(formGroup: UntypedFormGroup, subSink: SubSink) {
  subSink.sink = formGroup.valueChanges.subscribe(() => {
    updateValueAndValidityForAllControls(formGroup);
  });
}

export function updateValueAndValidityForAllControls(formGroup: UntypedFormGroup): void {
  Object.keys(formGroup.controls).forEach(key => {
    updateValueAndValidityForControl(formGroup.get(key));
  });
}

export function updateValueAndValidityForControl(control: AbstractControl): void {
  control.updateValueAndValidity({ onlySelf: false, emitEvent: false });
}

export function resetFormGroupErrors(formGroup: UntypedFormGroup) {
  resetControlErrors(formGroup);
  Object.keys(formGroup.controls ?? []).forEach(key => resetControlErrors(formGroup.get(key)));
}

export function resetControlErrors(control: AbstractControl) {
  control.setErrors(null);
}
