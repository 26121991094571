import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormMode } from '@utils/form.utils';
import { recommendationsOptions } from '../utils/speech-treatment.utils';

@Component({
  selector: 'stx-treatment-recommendations',
  templateUrl: './treatment-recommendations.component.html',
  styleUrls: ['./treatment-recommendations.component.scss']
})
export class TreatmentRecommendationsComponent {
  readonly recommendationsOptions = recommendationsOptions;

  @Input() formGroup: UntypedFormGroup;
  @Input() formMode: FormMode;
  @Input() print: boolean;
}
