import { ReleaseInfoComponent } from '@/src/app/features/dashboard/components/release-info/release-info.component';
import { NoticeComponentModule } from '@/src/app/shared/components/notice/notice.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ReleaseInfoComponent],
  imports: [CommonModule, NoticeComponentModule, TranslateModule.forChild()],
  exports: [ReleaseInfoComponent]
})
export class ReleaseInfoModule {}
