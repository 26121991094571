<div [class]="'header text-h1' + (additionalClasses ? ' ' + additionalClasses : '')">
  <p *ngIf="pageTitleKey" class="module-name">
    {{ (pageTitleKey | translate) || '&nbsp;' }}
  </p>

  <div class="flex-row-container">
    <p *ngIf="namePrefix !== null" class="text-strong">{{ namePrefix }} &nbsp;</p>
    <p *ngIf="name" class="text-strong">
      {{ name }}
      <ng-content></ng-content>
    </p>
  </div>
  <p *ngIf="localName" class="text-small">{{ localName }}</p>
</div>
