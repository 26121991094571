import { Component, Input } from '@angular/core';
import { BaseNutritionSectionComponent } from '@src/app/features/nutrition-management/shared/base-nutrition-section.component';
import { NutritionRadioItem } from '@src/app/features/nutrition-management/models/nutrition-item.model';

@Component({
  selector: 'stx-nutrition-current-feeding',
  templateUrl: './nutrition-current-feeding.component.html'
})
export class NutritionCurrentFeedingComponent extends BaseNutritionSectionComponent {
  @Input() newbornFeedingStatusOptions: NutritionRadioItem[];
}
