export const videosSections = [
  {
    section: 'surgery_videos.unilateral_cleft',
    subsections: [
      {
        subsection: 'surgery_videos.unilateral_cleft.overview',
        name: [
          'surgery_videos.unilateral_cleft.overview.presurgical_orthopedics',
          'surgery_videos.unilateral_cleft.overview.four_components_of_lip_nose_repair',
          'surgery_videos.unilateral_cleft.overview.long_term_results',
          'surgery_videos.unilateral_cleft.overview.rotation_advancement_cleft',
          'surgery_videos.unilateral_cleft.overview.noordhoff_variation',
          'surgery_videos.unilateral_cleft.overview.mohler_variation'
        ],
        link: [
          'https://www.youtube.com/watch?v=j52FWyLCwRg',
          'https://www.youtube.com/watch?v=BQIYhh70ABk',
          'https://www.youtube.com/watch?v=fU7pMMfExk8',
          'https://www.youtube.com/watch?v=_H9eJLuH0rA',
          'https://www.youtube.com/watch?v=IYbkKoHQGyY',
          'https://www.youtube.com/watch?v=RVXqf1r4EX4'
        ]
      },
      {
        subsection: 'surgery_videos.unilateral_cleft.markings_and_downward',
        name: [
          'surgery_videos.unilateral_cleft.markings_and_downward.lower_triangular_repair',
          'surgery_videos.unilateral_cleft.markings_and_downward.rotation_advancement_cleft2',
          'surgery_videos.unilateral_cleft.markings_and_downward.lateral_lip',
          'surgery_videos.unilateral_cleft.markings_and_downward.classic_millard_cleft_lip_repair',
          'surgery_videos.unilateral_cleft.markings_and_downward.noordgoff_variation2',
          'surgery_videos.unilateral_cleft.markings_and_downward.cutting_extended_mohler_repair'
        ],
        link: [
          'https://www.youtube.com/watch?v=Au6IyfvFYnU',
          'https://www.youtube.com/watch?v=JPJKUE3jlIc',
          'https://www.youtube.com/watch?v=szwd9nLJRC8',
          'https://www.youtube.com/watch?v=ouI4wCSo5I8',
          'https://www.youtube.com/watch?v=fhrytL-_dK0',
          'https://www.youtube.com/watch?v=RMvi2PUo0nw'
        ]
      },
      {
        subsection: 'surgery_videos.unilateral_cleft.primary_nasal_correction',
        name: [
          'surgery_videos.unilateral_cleft.primary_nasal_correction.cleft_nose_anatomy',
          'surgery_videos.unilateral_cleft.primary_nasal_correction.noordhoff_nasal_reconstruction',
          'surgery_videos.unilateral_cleft.primary_nasal_correction.mccomb_nasal_reconstruction',
          'surgery_videos.unilateral_cleft.primary_nasal_correction.cutting_nasal_reconstruction',
          'surgery_videos.unilateral_cleft.primary_nasal_correction.millard_nasal_reconstruction'
        ],
        link: [
          'https://www.youtube.com/watch?v=mrrkS6ebAmM',
          'https://www.youtube.com/watch?v=tbycupx2As0',
          'https://www.youtube.com/watch?v=48AaXrt4tT8',
          'https://www.youtube.com/watch?v=qZZ4j9ZgiZ4',
          'https://www.youtube.com/watch?v=JNx0u6WXrk8'
        ]
      },
      {
        subsection: 'surgery_videos.unilateral_cleft.mobilizing_lateral_lip',
        name: [
          'surgery_videos.unilateral_cleft.mobilizing_lateral_lip.noordhoff_method',
          'surgery_videos.unilateral_cleft.mobilizing_lateral_lip.millard_method',
          'surgery_videos.unilateral_cleft.mobilizing_lateral_lip.cutting_method'
        ],
        link: [
          'https://www.youtube.com/watch?v=o5_nXkAE8OM',
          'https://www.youtube.com/watch?v=U3tk6TGT4-Q',
          'https://www.youtube.com/watch?v=IeSpvxvY1oc'
        ]
      },
      {
        subsection: 'surgery_videos.unilateral_cleft.lip_repair_details',
        name: [
          'surgery_videos.unilateral_cleft.lip_repair_details.muscle_repair_cutting',
          'surgery_videos.unilateral_cleft.lip_repair_details.vermilion_repair',
          'surgery_videos.unilateral_cleft.lip_repair_details.noordhoff_vermilion'
        ],
        link: [
          'https://www.youtube.com/watch?v=5NEbAkX_VCw',
          'https://www.youtube.com/watch?v=TPrsZb0c6Rk',
          'https://www.youtube.com/watch?v=WVbymivPhSc'
        ]
      }
    ]
  },
  {
    section: 'surgery_videos.bilateral_cleft',
    subsections: [
      {
        subsection: 'surgery_videos.bilateral_cleft.bilateral_cleft_lip_repair',
        name: [
          'surgery_videos.bilateral_cleft.bilateral_cleft_lip_repair.use_of_prolabial_mucosa',
          'surgery_videos.bilateral_cleft.bilateral_cleft_lip_repair.user_of_lateral_turndown_flaps',
          'surgery_videos.bilateral_cleft.bilateral_cleft_lip_repair.muscle_repair',
          'surgery_videos.bilateral_cleft.bilateral_cleft_lip_repair.cupid_bow_construction',
          'surgery_videos.bilateral_cleft.bilateral_cleft_lip_repair.lip_suturing_technique'
        ],
        link: [
          'https://www.youtube.com/watch?v=VVEcrfRuvx8',
          'https://www.youtube.com/watch?v=HsI33KfGmsA',
          'https://www.youtube.com/watch?v=siotJwOs03o',
          'https://www.youtube.com/watch?v=5DKTm_fgdro',
          'https://www.youtube.com/watch?v=2q2hntv3fV8'
        ]
      },
      {
        subsection: 'surgery_videos.bilateral_cleft.bilateral_cleft_nasal_repair',
        name: [
          'surgery_videos.bilateral_cleft.bilateral_cleft_nasal_repair.skin_versus_cartilege_based_repair',
          'surgery_videos.bilateral_cleft.bilateral_cleft_nasal_repair.mccomb_concept_of_cartilege_based_repair',
          'surgery_videos.bilateral_cleft.bilateral_cleft_nasal_repair.trott_repair',
          'surgery_videos.bilateral_cleft.bilateral_cleft_nasal_repair.mulliken_repair',
          'surgery_videos.bilateral_cleft.bilateral_cleft_nasal_repair.grayson_nasoalveolar_molding',
          'surgery_videos.bilateral_cleft.bilateral_cleft_nasal_repair.cutting_modified_l_flap',
          'surgery_videos.bilateral_cleft.bilateral_cleft_nasal_repair.retrograde_nasal_dissection',
          'surgery_videos.bilateral_cleft.bilateral_cleft_nasal_repair.retrograde_nasal_suturing',
          'surgery_videos.bilateral_cleft.bilateral_cleft_nasal_repair.combining_cutting_and_mulliken'
        ],
        link: [
          'https://www.youtube.com/watch?v=H5-2lvijzRY',
          'https://www.youtube.com/watch?v=v1FYct5YqF4',
          'https://www.youtube.com/watch?v=GyHWLTp-_3U',
          'https://www.youtube.com/watch?v=_-fFB30rxo8',
          'https://www.youtube.com/watch?v=tF99i6IAahU',
          'https://www.youtube.com/watch?v=lRnRwZP3jxc',
          'https://www.youtube.com/watch?v=nMCUUy5Eaww',
          'https://www.youtube.com/watch?v=-B-IynCh9ZA',
          'https://www.youtube.com/watch?v=7AOS3zyDZSE'
        ]
      }
    ]
  },
  {
    section: 'surgery_videos.cleft_palate',
    subsections: [
      {
        subsection: 'surgery_videos.cleft_palate.hard_palate_repair',
        name: [
          'surgery_videos.cleft_palate.hard_palate_repair.bardach',
          'surgery_videos.cleft_palate.hard_palate_repair.langen',
          'surgery_videos.cleft_palate.hard_palate_repair.oxford',
          'surgery_videos.cleft_palate.hard_palate_repair.vomer',
          'surgery_videos.cleft_palate.hard_palate_repair.mobilizing'
        ],
        link: [
          'https://www.youtube.com/watch?v=LSM3AZm8UlI',
          'https://www.youtube.com/watch?v=lxOcgZppyQ0',
          'https://www.youtube.com/watch?v=WXwDIsMku2c',
          'https://www.youtube.com/watch?v=lTudBhuA1oQ',
          'https://www.youtube.com/watch?v=t8U4Cwql2ig'
        ]
      },
      {
        subsection: 'surgery_videos.cleft_palate.soft_palate_repair',
        name: [
          'surgery_videos.cleft_palate.soft_palate_repair.palate_musculature',
          'surgery_videos.cleft_palate.soft_palate_repair.tensor_veli',
          'surgery_videos.cleft_palate.soft_palate_repair.cutting_muscle',
          'surgery_videos.cleft_palate.soft_palate_repair.furlow_double',
          'surgery_videos.cleft_palate.soft_palate_repair.comparison'
        ],
        link: [
          'https://www.youtube.com/watch?v=bZ5uiZTMQzE',
          'https://www.youtube.com/watch?v=dg9jjaiH7QE',
          'https://www.youtube.com/watch?v=iTPOBKQc_ww',
          'https://www.youtube.com/watch?v=Z2g_yHDpCuY',
          'https://www.youtube.com/watch?v=Pq2x7GxqDmA'
        ]
      }
    ]
  },
  {
    section: 'surgery_videos.secondary_palate_repair',
    subsections: [
      {
        subsection: 'surgery_videos.secondary_palate_repair.velopharyngeal_incompetence',
        name: [
          'surgery_videos.secondary_palate_repair.velopharyngeal_incompetence.intro_to_compensatory_articulations',
          'surgery_videos.secondary_palate_repair.velopharyngeal_incompetence.hogan_pharyngeal.flap',
          'surgery_videos.secondary_palate_repair.velopharyngeal_incompetence.jackson_orticochea_pharyngoplasty'
        ],
        link: [
          'https://www.youtube.com/watch?v=7e7g-8MTHsM',
          'https://www.youtube.com/watch?v=Xd6aJ4HSu8Y',
          'https://www.youtube.com/watch?v=74wdGC8plZE'
        ]
      },
      {
        subsection: 'surgery_videos.secondary_palate_repair.palatal_fistula',
        name: [
          'surgery_videos.secondary_palate_repair.palatal_fistula.small_large_palate',
          'surgery_videos.secondary_palate_repair.palatal_fistula.facial_artery',
          'surgery_videos.secondary_palate_repair.palatal_fistula.tongue_flap',
          'surgery_videos.secondary_palate_repair.palatal_fistula.radial_forearm'
        ],
        link: [
          'https://www.youtube.com/watch?v=FD1vloJrcug',
          'https://www.youtube.com/watch?v=-nZJmE9E0tw',
          'https://www.youtube.com/watch?v=_KWk1g8ehRw',
          'https://www.youtube.com/watch?v=-7T8V9rnlrY'
        ]
      }
    ]
  },
  {
    section: 'surgery_videos.skeletal_deformities',
    subsections: [
      {
        subsection: '',
        name: [
          'surgery_videos.skeletal_deformities.modified_abyholm',
          'surgery_videos.skeletal_deformities.premaxillary_repositioning',
          'surgery_videos.skeletal_deformities.lefort_osteotomy',
          'surgery_videos.skeletal_deformities.lefort_four_piece',
          'surgery_videos.skeletal_deformities.lefort_one_piece',
          'surgery_videos.skeletal_deformities.lefort_rigid'
        ],
        link: [
          'https://www.youtube.com/watch?v=2ljbmlTPPj0',
          'https://www.youtube.com/watch?v=bWoT323hApc',
          'https://www.youtube.com/watch?v=P05GfliIW10',
          'https://www.youtube.com/watch?v=TqM0hAWpfSo',
          'https://www.youtube.com/watch?v=U3ZamzVt7sM',
          'https://www.youtube.com/watch?v=9qwOcpll7Bs'
        ]
      }
    ]
  },
  {
    section: 'surgery_videos.secondary_nose_repair',
    subsections: [
      {
        subsection: '',
        name: [
          'surgery_videos.secondary_nose_repair.introduction',
          'surgery_videos.secondary_nose_repair.monoblock',
          'surgery_videos.secondary_nose_repair.potter',
          'surgery_videos.secondary_nose_repair.dibbel',
          'surgery_videos.secondary_nose_repair.avoid_cartilage',
          'surgery_videos.secondary_nose_repair.secondary_bilateral_nasap_repair'
        ],
        link: [
          'https://www.youtube.com/watch?v=SK3jOnZPPsU',
          'https://www.youtube.com/watch?v=bmx9CP5PjfM',
          'https://www.youtube.com/watch?v=5d6YWDnWFgE',
          'https://www.youtube.com/watch?v=KvwNwf4IoJI',
          'https://www.youtube.com/watch?v=onEWDMPHRsg',
          'https://www.youtube.com/watch?v=PhXZafhbzIU'
        ]
      }
    ]
  },
  {
    section: 'surgery_videos.animations_for_parents',
    subsections: [
      {
        subsection: '',
        name: [
          'surgery_videos.animations_for_parents.unilateral_cleft',
          'surgery_videos.animations_for_parents.bilateral_cleft',
          'surgery_videos.animations_for_parents.cleft_palate'
        ],
        link: [
          'https://www.youtube.com/watch?v=Q0l_aI8yGIM',
          'https://www.youtube.com/watch?v=GWzmJ27kc7Q',
          'https://www.youtube.com/watch?v=hBQwMuT7LKo'
        ]
      }
    ]
  },
  {
    section: 'surgery_videos.secondary_lip_repair',
    subsections: [
      {
        subsection: '',
        name: ['surgery_videos.secondary_lip_repair.noordhoff_bilateral_lip_re_repair', 'surgery_videos.secondary_lip_repair.abbe_flap'],
        link: ['https://www.youtube.com/watch?v=AiXNptdgHmo', 'https://www.youtube.com/watch?v=AaagZsWsIuQ']
      }
    ]
  }
];
