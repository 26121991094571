import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'unit'
})
export class UnitPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}
  transform(value: any, unitLabel: string) {
    return value ? [value, this.translateService.instant(unitLabel)].join(' ') : value;
  }
}
