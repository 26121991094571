import { Component, Input } from '@angular/core';
import { NutritionType } from '@src/app/features/nutrition-management/enums/nutrition-type.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'stx-nutrition-status-subtitle',
  templateUrl: './nutrition-status-subtitle.component.html'
})
export class NutritionStatusSubtitleComponent {
  readonly whoReferenceResourceRoute = '/resources/who-reference-resources';
  readonly whoAnthroSoftwareLink = 'https://www.who.int/tools/child-growth-standards/software';
  readonly whoAnthroPlusSoftwareLink = 'https://www.who.int/tools/growth-reference-data-for-5to19-years/application-tools';
  readonly NutritionType = NutritionType;
  @Input() nutritionFormType: NutritionType;

  constructor(private router: Router) {}

  navigateToResources() {
    this.router.navigateByUrl(this.whoReferenceResourceRoute);
  }
}
