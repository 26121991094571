export enum PractitionerType {
  SURGEON = 'SURGEON',
  ORTHODONTIST = 'ORTHODONTIST',
  SPEECH_PROVIDER = 'SPEECH_PROVIDER',
  NUTRITIONIST = 'NUTRITIONIST',
  ANESTHESIOLOGIST = 'ANESTHESIOLOGIST',
  DENTAL_PROVIDER = 'DENTAL_PROVIDER',
  ENT_PROVIDER = 'ENT_PROVIDER',
  HEARING_SPECIALIST = 'HEARING_SPECIALIST',
  PSYCHOSOCIAL_PROVIDER = 'PSYCHOSOCIAL_PROVIDER'
}
