import { Component, Input } from '@angular/core';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'stx-remove-icon',
  template: '<fa-icon [icon]="faTrash" [class.display-none]="!shouldDisplayIcon"></fa-icon>',
  styleUrls: ['./remove-icon.component.scss']
})
export class RemoveIconComponent {
  @Input() shouldDisplayIcon: boolean;

  readonly faTrash = faTrashAlt;
}
