import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

const paginatorOf = 'paginator.of';
const itemsPerPage = 'paginator.items_per_page';
const nextPage = 'paginator.next_page';
const previousPage = 'paginator.previous_page';

@Injectable({
  providedIn: 'root'
})
export class PaginatorI18nService implements OnDestroy {
  private readonly subSink = new SubSink();

  private readonly paginatorIntl = new MatPaginatorIntl();
  private keyTranslation: Map<string, string> = new Map();

  constructor(private readonly translateService: TranslateService) {}

  getPaginatorIntl(): MatPaginatorIntl {
    this.subSink.sink = this.translateService.stream([paginatorOf, itemsPerPage, nextPage, previousPage]).subscribe(keyTranslation => {
      this.keyTranslation = keyTranslation;
      this.assignStaticTranslations();
      this.paginatorIntl.changes.next();
    });
    return this.paginatorIntl;
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.keyTranslation[paginatorOf]} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.keyTranslation[paginatorOf]} ${length}`;
  }

  assignStaticTranslations() {
    this.paginatorIntl.itemsPerPageLabel = this.keyTranslation[itemsPerPage] + ':';
    this.paginatorIntl.nextPageLabel = this.keyTranslation[nextPage];
    this.paginatorIntl.previousPageLabel = this.keyTranslation[previousPage];
    this.paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
