import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { CheckboxBoolOption } from '@/src/app/shared/models/form.model';
import { HorizontalFormElementSetup } from '@/src/app/utils/form.utils';

//This is a legacy code we should not use it in future
export const screeningBreastfeedingInfoOptions: CheckboxBoolOption[] = [
  {
    label: 'nutrition_screening.given_info.importance',
    name: 'givenInfoImportance',
    value: false
  },
  {
    label: 'nutrition_screening.given_info.strategies',
    name: 'givenInfoStrategies',
    value: false
  },
  {
    label: 'nutrition_screening.given_info.handson',
    name: 'givenInfoHandson',
    value: false
  },
  {
    label: 'nutrition_screening.given_info.expressmilk',
    name: 'givenInfoExpressmilk',
    value: false
  }
];

export const screeningBreastfeedingOptions: CheckboxBoolOption[] = [
  {
    label: 'nutrition_screening.breastfeeding.no_milk',
    name: 'breastfeeingNomilk',
    value: false
  },
  {
    label: 'nutrition_screening.breastfeeding.no_latch',
    name: 'breastfeeingNolatch',
    value: false
  },
  {
    label: 'nutrition_screening.breastfeeding.pain',
    name: 'breastfeeingPain',
    value: false
  },
  {
    label: 'nutrition_screening.breastfeeding.too_old',
    name: 'breastfeeingTooold',
    value: false
  },
  {
    label: 'nutrition_screening.breastfeeding.against',
    name: 'breastfeeingAgainst',
    value: false
  },
  {
    label: 'nutrition_screening.breastfeeding.cries',
    name: 'breastfeeingBabycries',
    value: false
  }
];

export const screeningFoodOptions: CheckboxBoolOption[] = [
  {
    label: 'nutrition_screening.food.breastmilk',
    name: 'feedBreastmilk',
    value: false
  },
  {
    label: 'nutrition_screening.food.expressed',
    name: 'feedExprmilk',
    value: false
  },
  {
    label: 'nutrition_screening.food.cowmilk',
    name: 'feedCowmilk',
    value: false
  },
  {
    label: 'nutrition_screening.food.formula',
    name: 'feedFormula',
    value: false
  },
  {
    label: 'nutrition_screening.food.mashed',
    name: 'feedMacerated',
    value: false
  },
  {
    label: 'nutrition_screening.food.solid',
    name: 'feedSolids',
    value: false
  }
];

export const screeningFeedingMethodOptions: CheckboxBoolOption[] = [
  {
    label: 'nutrition_screening.feeding_method.breast',
    name: 'feedhowBreast',
    value: false
  },
  {
    label: 'nutrition_screening.feeding_method.bottle',
    name: 'feedhowBottle',
    value: false
  },
  {
    label: 'nutrition_screening.feeding_method.spoon',
    name: 'feedhowSpoon',
    value: false
  },
  {
    label: 'nutrition_screening.feeding_method.cup',
    name: 'feedhowCup',
    value: false
  },
  {
    label: 'nutrition_screening.feeding_method.hand',
    name: 'feedhowHand',
    value: false
  },
  {
    label: 'nutrition_screening.feeding_method.syringe',
    name: 'feedhowSyringe',
    value: false
  },
  {
    label: 'nutrition_screening.feeding_method.tube',
    name: 'feedhowIv',
    value: false
  }
];

export const screeningChallengesOptions: CheckboxBoolOption[] = [
  {
    label: 'nutrition_screening.challenges.tire',
    name: 'probfeedTire',
    value: false
  },
  {
    label: 'nutrition_screening.challenges.nose',
    name: 'probfeedNose',
    value: false
  },
  {
    label: 'nutrition_screening.challenges.choke',
    name: 'probfeedChoke',
    value: false
  },
  {
    label: 'nutrition_screening.challenges.refuse',
    name: 'probfeedRefuse',
    value: false
  }
];

export const screeningGeneralInfoFields = new Map<string, HorizontalFormElementSetup>()
  .set('evaluation_date', {
    name: 'nutrition_screening.evaluation_date',
    formControlName: 'dateScreen'
  })
  .set('age', {
    name: 'age',
    formControlName: 'ageMonth'
  })
  .set('weight', {
    name: 'nutrition.child_weight',
    formControlName: 'weightIs'
  })
  .set('height', {
    name: 'nutrition.child_height',
    formControlName: 'heightIs'
  })
  .set('hemoglobin', {
    name: 'nutrition_management.hemoglobin',
    formControlName: 'hemoglobinIs'
  })
  .set('muac', {
    name: 'nutrition_management.muac',
    formControlName: 'muacIs'
  })
  .set('provider_other', {
    name: 'other',
    formControlName: 'providerOther'
  })
  .set('frontal_photo', {
    name: 'nutrition_screening.frontal_photo',
    formControlName: ParentOrderName.NUTRITION_SCREENING_HISTORICAL
  });
