<stx-modal>
  <ng-container *ngIf="data.isSuccess; else tokenExpired">
    <div class="flex-row-container">
      <mat-icon class="success-icon"> check_circle </mat-icon>
      <div>{{ 'settings.reset_password_success_message' | translate }}</div>
    </div>
    <stx-action-buttons
      [showSecondary]="false"
      (primaryAction)="navigateToLogin()"
      [primaryText]="'login' | translate"
    ></stx-action-buttons>
  </ng-container>
  <ng-template #tokenExpired>
    <div class="flex-row-container">
      <mat-icon class="failure-icon"> cancel </mat-icon>
      <div>{{ 'error.reset_password_failed_message' | translate }}</div>
    </div>
  </ng-template>
</stx-modal>
