<stx-resources-navigation selectedItemId="{{ NavigationSubTabEnum.RESOURCES_CLEFT_PUBLICATIONS }}"></stx-resources-navigation>
<stx-generic-wide-page>
  <div class="flex-row-container gap-15 align-middle">
    <stx-page-header [name]="'cleft_publications.title' | translate"></stx-page-header>
    <a (click)="isExpanded = !isExpanded">
      <span class="flex-center">
        <mat-icon class="expand-icon cursor-pointer">tune</mat-icon>
      </span>
    </a>
  </div>
  <div>
    <div></div>
    <div [@searchFormTrigger]="isExpanded ? 'open' : 'closed'">
      <stx-form-panel>
        <form [formGroup]="publicationSearchForm" class="flex-column-container">
          <p>{{ 'cleft_publications.description' | translate }}</p>
          <hr class="form-subsection-separator" />
          <div class="form-columns-1-1-1 form-row form-control-margin">
            <div class="form-row">
              <div *ngIf="publicationYears" class="flex-row-container wrap">
                <div formArrayName="years" *ngFor="let year of publicationSearchForm.controls.years['controls']; let i = index">
                  <mat-checkbox data-testid="checkbox-publication-years" [formControlName]="i" class="m-r-10">
                    {{ publicationYears[i] }}
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div></div>
            <div></div>
          </div>
          <div>
            <stx-action-buttons
              [noMargin]="true"
              [showSecondary]="false"
              [primaryText]="'search' | translate"
              (primaryAction)="search()"
            ></stx-action-buttons>
          </div>
        </form>
      </stx-form-panel>
    </div>
  </div>
  <div class="flex-row-container align-self-bottom align-right">
    <div [formGroup]="publicationSearchForm" class="custom-flex-23">
      <stx-input [label]="'search'" [formMode]="FormMode.EDIT" [controlName]="'searchTerm'" [type]="'text'"></stx-input>
    </div>
  </div>
  <div>
    <stx-data-table
      [dataProvider]="publicationDataProvider"
      [config]="{ topPaginator: true, colWidthConfig: this.colWidthConfig }"
      (paginationEvent)="updatePaginationModel($event)"
    >
      <tr mat-row *matRowDef="let row; columns: []"></tr>
    </stx-data-table>
  </div>
  <div>
    <stx-cleft-publications-form (newPublicationAdded)="newPublicationAdded()"></stx-cleft-publications-form>
  </div>
</stx-generic-wide-page>
