<div mat-dialog-content class="m-0 flex-column-container">
  <h3 class="m-b-25">{{ data.promptHeader | translate }}</h3>
  <div class="m-b-30">
    <ul *ngIf="hasMultipleMessages; else singleMessageParagraph">
      <li *ngFor="let userMessage of data.promptBody">{{ userMessage | translate }}</li>
    </ul>
    <ng-template #singleMessageParagraph>
      <p>{{ singleMessage | translate }}</p>
    </ng-template>
  </div>
  <stx-action-buttons
    (primaryAction)="submit()"
    [primaryText]="data.submitButtonText | translate"
    (secondaryAction)="cancel()"
    [secondaryText]="data.cancelButtonText | translate"
  ></stx-action-buttons>
</div>
