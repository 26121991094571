<stx-details-page *ngIf="details" pageTitleKey="partner.partner" [name]="details.name" [localName]="details.nameLoc">
  <div class="data-section">
    <div class="layout-columns-1-1">
      <div class="data-tile-container">
        <div class="data-tile-row">
          <div class="data-tile">
            <div class="data-tile-content">
              <stx-simple-details-value [value]="details.contact"></stx-simple-details-value>
              <stx-simple-details-value [phone]="details.phone"></stx-simple-details-value>
              <stx-simple-details-value [email]="details.email"></stx-simple-details-value>
              <stx-simple-details-value [externalLink]="details.website"></stx-simple-details-value>
            </div>
          </div>
        </div>
        <hr class="data-tile-row-separator" />
        <div class="data-tile-row">
          <div class="data-tile">
            <div class="data-tile-content">
              <stx-simple-details-value [value]="details.street1"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.street1Loc"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.street2"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.street2Loc"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.city"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.province"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.district"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.country"></stx-simple-details-value>
              <stx-simple-details-value [value]="details.zip"></stx-simple-details-value>
            </div>
          </div>
        </div>
      </div>
      <div class="data-tile-container">
        <div class="data-tile-row">
          <div class="data-tile">
            <div class="data-tile-content">
              <stx-simple-details-value [value]="details.vendorId"></stx-simple-details-value>
              <stx-simple-details-value labelKey="partner.partner_crm" [value]="details.crmName"></stx-simple-details-value>
              <stx-simple-details-value [email]="details.crmEmail"></stx-simple-details-value>
              <stx-simple-details-value labelKey="partner.partner_since" [value]="details.dateSigned"></stx-simple-details-value>
              <p>
                <a class="grant-button" href="https://smiletrain.force.com/grantee/" target="_blank">
                  <button data-testid="button-partner-details" mat-stroked-button color="primary" class="grant-button">
                    {{ 'partner.grant_account' | translate }}
                  </button>
                </a>
              </p>
              <stx-simple-details-value labelKey="partner.paper_records" [value]="details.paperCount"></stx-simple-details-value>
            </div>
          </div>
        </div>
        <ng-container *ngIf="latestSubmissions.length > 0">
          <hr class="data-tile-row-separator" />
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-content">
                <stx-details-value-list headerKey="common.latest_submissions" [elements]="latestSubmissions"></stx-details-value-list>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="programs.length > 0">
          <hr class="data-tile-row-separator" />
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-content">
                <stx-details-value-list [headerKey]="'treatment.programs'" [elements]="programs"></stx-details-value-list>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="affiliatedPartners.length > 0">
          <hr class="data-tile-row-separator" />
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-content">
                <stx-details-value-list [headerKey]="'search.affiliated_partners'" [elements]="affiliatedPartners"></stx-details-value-list>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="data-section" *stxPermitted="permissionEnum.GET_PARTNER_TREATMENT_CENTERS">
    <div class="data-section-heading">
      {{ 'treatment_center.treatment_centers' | translate }}
      <stx-file-export-icon (download)="treatmentCentersTableDataProvider.download()"></stx-file-export-icon>
    </div>
    <stx-data-table [dataProvider]="treatmentCentersTableDataProvider">
      <!--prettier-ignore-->
      <tr mat-row *matRowDef="let row; columns: []" [class.data-table-inactive]="!row.active"></tr>
    </stx-data-table>
  </div>

  <div class="data-section" *stxPermitted="permissionEnum.GET_PARTNER_USERS">
    <div class="data-section-heading">
      {{ 'users' | translate }}
    </div>
    <stx-data-table [dataProvider]="usersTableDataProvider">
      <!--prettier-ignore-->
      <tr mat-row *matRowDef="let row; columns: []" [class.data-table-inactive]="!row.enabled"></tr>
    </stx-data-table>
  </div>
</stx-details-page>
