import { getToday } from '@/src/app/utils/date.utils';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { FormFieldType, FormMode, HorizontalFormElementSetup, yesNoAnswers } from 'src/app/utils/form.utils';

@Component({
  selector: 'stx-generic-form-element',
  templateUrl: './generic-form-element.component.html'
})
export class GenericFormElementComponent implements OnInit {
  readonly FormDataTypes = FormFieldType;
  readonly formMode = FormMode.EDIT;
  readonly yesNo = yesNoAnswers;
  readonly today = getToday();

  @Input()
  key: string;

  @Input()
  formElementSetup: HorizontalFormElementSetup;

  @Input()
  minDate: moment.Moment;

  @Input()
  maxDate: moment.Moment;

  formGroup: UntypedFormGroup;

  constructor(private readonly controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.formGroup = <UntypedFormGroup>this.controlContainer.control;
  }
}
