import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { Mime } from '@utils/file.utils';
import { shareReplayBufferSize } from '@utils/http.utils';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BaseHttpService } from 'src/app/shared/services/http/base-http.service';
import { TempMediaUploadInfo } from '../media/models/media.model';
import { SurgicalTreatmentUploadedFileProcessingResult } from './models/offline-app-api.model';

@Injectable({
  providedIn: 'root'
})
export class OfflineAppService extends BaseHttpService {
  private showDeprecatedVersions$: Observable<boolean>;

  constructor(httpClient: HttpClient, private wsHelper: WsHelperService) {
    super(httpClient, { baseUrl: 'offline-app' });
  }

  getSurgicalTreatmentFileUploadUrl(fileName: string): Observable<TempMediaUploadInfo> {
    const params = new HttpParams().set('fileName', fileName);
    return this.httpClient.get<TempMediaUploadInfo>(`${this.baseUrl}/surgical-treatment/upload-url`, {
      params
    });
  }
  getSurgicalTreatmentFileStatus(fileId: string): Observable<SurgicalTreatmentUploadedFileProcessingResult> {
    const params = new HttpParams().set('id', fileId);
    return this.httpClient.get<SurgicalTreatmentUploadedFileProcessingResult>(`${this.baseUrl}/surgical-treatment/upload-result`, {
      params
    });
  }

  getPractitionersCsvFile() {
    const headers = new HttpHeaders().set('Accept', Mime.CSV);
    return this.httpClient.get(`${this.baseUrl}/practitioners`, { responseType: 'blob', headers });
  }

  getShowDeprecatedVersions(): Observable<boolean> {
    if (!this.showDeprecatedVersions$) {
      this.showDeprecatedVersions$ = this.wsHelper
        .callWithSpinner(this.httpClient.get<boolean>(`${this.baseUrl}/deprecated-version`))
        .pipe(shareReplay(shareReplayBufferSize));
    }
    return this.showDeprecatedVersions$;
  }
}
