import { YesNoOrNullPipeModule } from '@/src/app/shared/modules/readonly-commons/pipes/yes-no/yes-no-or-null.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PermissionModule } from '@core/permissions/permission.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { FormLabelModule } from '@shared/components/form/components/form-label/form-label.component';
import { InputBaseComponentModule } from '@shared/components/form/components/input-base/input-base.component';
import { ImageRotatorModule } from '@shared/components/image-rotator/image-rotator.module';
import { MetadataModule } from '@shared/components/metadata/metadata.module';
import { PatientDocumentUploadModule } from '@shared/components/patient-document-upload/patient-document-upload.module';
import { TreatmentModule } from '@shared/components/treatment/treatment.module';
import { UppyModule } from '@shared/components/uppy/uppy.module';
import { FormsCommonsModule } from '@shared/modules/forms-commons/forms-commons.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { MaterialModule } from '@shared/modules/material/material.module';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { BorescopeReadonlyComponent } from '@src/app/features/surgical/components/borescope-readonly/borescope-readonly.component';
import { CheckboxSectionComponent } from '@src/app/features/surgical/components/checkbox-section/checkbox-section.component';
import { GenericFormElementComponent } from '@src/app/features/surgical/components/generic-form-element/generic-form-element.component';
import { SurgicalFollowUpReadonlyComponent } from '@src/app/features/surgical/components/surgical-follow-up-readonly/surgical-follow-up-readonly.component';
import { SurgicalInterventionFormComponent } from '@src/app/features/surgical/components/surgical-intervention-form/surgical-intervention-form.component';
import { SurgicalInterventionReadonlyComponent } from '@src/app/features/surgical/components/surgical-intervention-readonly/surgical-intervention-readonly.component';
import { FormModule } from 'src/app/shared/components/form/form.module';
import { AnesthesiologistSelectComponent } from './components/anesthesiologist-select/anesthesiologist-select.component';
import { BorescopeComponent } from './components/borescope/borescope.component';
import { SurgicalFollowUpComponent } from './components/surgical-follow-up/surgical-follow-up.component';
import { SurgicalInterventionComponent } from './components/surgical-intervention/surgical-intervention.component';

@NgModule({
  declarations: [
    BorescopeComponent,
    BorescopeReadonlyComponent,
    CheckboxSectionComponent,
    GenericFormElementComponent,
    SurgicalFollowUpComponent,
    SurgicalFollowUpReadonlyComponent,
    SurgicalInterventionComponent,
    SurgicalInterventionFormComponent,
    SurgicalInterventionReadonlyComponent,
    AnesthesiologistSelectComponent
  ],
  exports: [SurgicalInterventionComponent, SurgicalFollowUpComponent, BorescopeComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FontAwesomeModule,
    FormModule,
    FormsCommonsModule,
    FormsModule,
    ImageRotatorModule,
    MaterialModule,
    MetadataModule,
    PatientDocumentUploadModule,
    PermissionModule,
    ReactiveFormsModule,
    ReadonlyCommonsModule,
    RouterModule,
    TranslateModule.forChild(),
    TreatmentModule,
    UppyModule,
    FormLabelModule,
    InputBaseComponentModule,
    GeneralCommonsModule,
    YesNoOrNullPipeModule
  ]
})
export class SurgicalModule {}
