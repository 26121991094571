import { Injectable } from '@angular/core';
import { BaseHttpService } from './http/base-http.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CountryDictionary, ZoneInput, ZoneItem } from '../models/geo.model';
import { shareReplay } from 'rxjs/operators';
import { geoRoute } from '@utils/routing.utils';

@Injectable({
  providedIn: 'root'
})
export class GeoService extends BaseHttpService {
  countryDictionaryUrl = 'countries';
  provinceZonesEndpoint = '/zone/provinces';
  countryZonesEndpoint = '/zone/countries';
  bufferSize = 1;

  private countryDictionary$: Observable<CountryDictionary[]>;
  selectedZone$: BehaviorSubject<ZoneItem> = new BehaviorSubject<ZoneItem>(null);

  constructor(httpClient: HttpClient) {
    super(httpClient, { baseUrl: geoRoute });
  }

  get getCountryDictionaries(): Observable<CountryDictionary[]> {
    if (!this.countryDictionary$) {
      this.countryDictionary$ = this.httpClient
        .get<CountryDictionary[]>(`${this.baseUrl}/${this.countryDictionaryUrl}`)
        .pipe(shareReplay(this.bufferSize));
    }
    return this.countryDictionary$;
  }

  getProvinceZones(): Observable<ZoneItem[]> {
    return this.httpClient.get<ZoneItem[]>(`${this.baseUrl}${this.provinceZonesEndpoint}`);
  }

  saveProvinceZone(provinceZone: ZoneInput): Observable<ZoneItem> {
    return this.httpClient.post<ZoneItem>(`${this.baseUrl}${this.provinceZonesEndpoint}`, provinceZone);
  }

  deleteProvinceZone(zoneId: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}${this.provinceZonesEndpoint}/${zoneId}`);
  }

  getCountryZones(): Observable<ZoneItem[]> {
    return this.httpClient.get<ZoneItem[]>(`${this.baseUrl}${this.countryZonesEndpoint}`);
  }

  saveCountryZone(countryZone: ZoneInput): Observable<ZoneItem> {
    return this.httpClient.post<ZoneItem>(`${this.baseUrl}${this.countryZonesEndpoint}`, countryZone);
  }

  deleteCountryZone(zoneId: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}${this.countryZonesEndpoint}/${zoneId}`);
  }
}
