import { TreatmentFilter } from '@src/app/features/search/models/treatment-filter.model';
import { NutritionStage } from '@src/app/features/nutrition-management/utils/nutrition-management.utils';
import { formStatusToTreatmentStatusMap } from '@src/app/features/search/search.utils';
import { FormStatus } from '@shared/enums/form-status.enum';
import { DictionaryTranslation } from '@src/app/features/patient/models/patient.model';

const nutritionFormsStageToTreatmentType = new Map<NutritionStage, DictionaryTranslation>([
  [
    NutritionStage.ASSESSMENT,
    {
      value: 'nutritionAssessmentStates',
      label: 'treatment.nutrition_management.assessment.shorter'
    }
  ],
  [
    NutritionStage.FOLLOW_UP,
    {
      value: 'nutritionFollowUpStates',
      label: 'nutrition_follow_up'
    }
  ]
]);

export function getNutritionFilters(): TreatmentFilter[] {
  return [
    {
      treatmentType: {
        value: 0,
        label: 'treatment.nutrition'
      },
      children: getSingleNutritionFilter(true)
    }
  ];
}

function getSingleNutritionFilter(isSeparated: boolean): TreatmentFilter[] {
  return [
    {
      treatmentType: nutritionFormsStageToTreatmentType.get(NutritionStage.ASSESSMENT),
      treatmentStatuses: [
        formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED),
        formStatusToTreatmentStatusMap.get(FormStatus.REJECTED),
        formStatusToTreatmentStatusMap.get(FormStatus.IN_PROGRESS)
      ],
      isSeparated: isSeparated
    },
    {
      treatmentType: nutritionFormsStageToTreatmentType.get(NutritionStage.FOLLOW_UP),
      treatmentStatuses: [
        formStatusToTreatmentStatusMap.get(FormStatus.SUBMITTED),
        formStatusToTreatmentStatusMap.get(FormStatus.REJECTED),
        formStatusToTreatmentStatusMap.get(FormStatus.IN_PROGRESS)
      ]
    }
  ];
}
