import { PlotItem } from '@shared/modules/statistics/components/statistics-plot/statistics-plot.model';

export function transformToPlots(stats: Map<string, number>): PlotItem[] {
  const treatmentPlots: PlotItem[] = [];
  if (stats) {
    Object.keys(stats).forEach(key => {
      treatmentPlots.push({ label: key, value: stats[key] });
    });
  }
  return treatmentPlots.sort();
}

type PlotColors = { primary: string; secondary: string };
export const orthoColors: PlotColors = {
  primary: '#90d307',
  secondary: '#d0d7c1'
};
export const surgeryColors: PlotColors = {
  primary: '#4b79BC',
  secondary: '#6DAADC'
};
export const speechColors: PlotColors = {
  primary: '#ad24a9',
  secondary: '#dcb6d6'
};
export const nutritionColors: PlotColors = {
  primary: '#f37654',
  secondary: '#eda88c'
};

export const statisticsPlotsTooltips = [
  ['Surgical Intervention'],
  ['Speech Treatment', 'Speech Assessment'],
  ['Presurgical Orthopedics', 'Mixed Dentition', 'Permanent Dentition'],
  ['Nutrition Management']
];
