<ng-container *ngIf="reportType !== 'SURGERY_STATS'; else surgeryStats">
  <stx-checkbox-group-filter
    [formGroupReference]="formGroupReference"
    filterName="treatmentStatus"
    [dictionaryOptions]="dictionaryOptions"
    (filterChange)="onFilterChange($event)"
  ></stx-checkbox-group-filter>
</ng-container>
<ng-template #surgeryStats>
  <stx-checkbox-group-filter
    [formGroupReference]="formGroupReference"
    filterName="treatmentStatus"
    [dictionaryOptions]="inProgressToComboOptions"
    (filterChange)="onFilterChange($event)"
  ></stx-checkbox-group-filter>
  <stx-checkbox-tree-filter
    [dictionariesTree]="reimbursableOptions"
    [formGroupReference]="formGroupReference.get(REIMBURSABLE_VALUE)"
    [makeSecondSubSectionExpand]="true"
    (filterChange)="onFilterChange($event)"
  ></stx-checkbox-tree-filter>
  <stx-checkbox-group-filter
    [formGroupReference]="formGroupReference"
    filterName="treatmentStatus"
    [dictionaryOptions]="rejectedToSecondaryOptions"
    (filterChange)="onFilterChange($event)"
  ></stx-checkbox-group-filter>
</ng-template>
