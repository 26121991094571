import { ModalComponent } from '@/src/app/shared/components/modal/modal.component';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';

@Component({
  selector: 'stx-help-modal',
  templateUrl: './help-modal.component.html'
})
export class EmailHelpModalComponent {
  constructor(public dialogRef: MatDialogRef<ModalComponent>) {}

  closeModal: AsyncButtonClickAction = () => {
    this.dialogRef.close();
    return of({});
  };
}
