import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component } from '@angular/core';

@Component({
  selector: 'stx-intraoral-assessment',
  templateUrl: './intraoral-assessment.component.html'
})
export class IntraoralAssessmentComponent extends BaseFormElementComponent {
  constructor() {
    super();
  }
}
