import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { TooltipTemplate } from '@shared/modules/general-commons/components/tooltip/tooltip.model';
import { StringUtils } from '@utils/string.utils';

function toTemplate(input: string): TooltipTemplate {
  return {
    template: '{0}',
    translationKeys: [input]
  };
}

function asTemplate(input: string | TooltipTemplate): TooltipTemplate {
  return typeof input === 'string' ? toTemplate(input) : input;
}

@Component({
  selector: 'stx-tooltip',
  templateUrl: './tooltip.component.html'
})
export class TooltipComponent {
  readonly faQuestionCircle = faQuestionCircle;
  tooltipContent: Observable<string>;

  constructor(private readonly translateService: TranslateService) {}

  @Input()
  set content(input: string | TooltipTemplate) {
    const { template, translationKeys } = asTemplate(input);
    this.tooltipContent = this.translateService.get(translationKeys).pipe(
      map(translatedStrings => {
        const texts = translationKeys.map(key => translatedStrings[key]);
        return StringUtils.format(template, ...texts);
      })
    );
  }
}
