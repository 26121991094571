import { AuthService } from '@/src/app/features/authentication/services/auth.service';
import { PartnerService } from '@/src/app/features/partner/partner.service';
import { treatmentCentersRoute } from '@/src/app/utils/routing.utils';
import { Component, Input, OnInit } from '@angular/core';
import { TreatmentCenterDictionary } from '@shared/models/treatment-center.model';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { PartnerDictionary } from '@src/app/features/partner/models/partner-dictionary-model';

@Component({
  selector: 'stx-tc-link',
  templateUrl: './tc-link.component.html',
  styleUrls: ['./tc-link.component.scss']
})
export class TcLinkComponent extends BaseComponent implements OnInit {
  readonly treatmentCentersRoute = treatmentCentersRoute;
  isTcLinkActive: boolean;

  constructor(private authService: AuthService, private partnerService: PartnerService) {
    super();
  }

  @Input()
  set treatmentCenter(treatmentCenter: TreatmentCenterDictionary) {
    this._tc = treatmentCenter;

    if (this._tc.id) {
      this.checkIfTcLinkIsActive(this._tc.id);
    }
  }

  get treatmentCenter() {
    return this._tc;
  }

  private _tc: TreatmentCenterDictionary;

  ngOnInit(): void {
    this._tc = this.treatmentCenter;
  }

  checkIfTcLinkIsActive(treatmentCenterId: number): void {
    if (!this.authService.checkUserPermission(PermissionEnum.AFFILIATED_PARTNERS_FILTER)) {
      this.isTcLinkActive = true;
      return;
    }

    this.subSink.sink = this.partnerService.checkIfCurrentUserPartnerHasAffiliation().subscribe((isAffiliated: boolean): void => {
      if (!isAffiliated) {
        this.isTcLinkActive = true;
        return;
      }

      this.subSink.sink = this.partnerService.getUserPartners().subscribe((partnerDetails: PartnerDictionary[]) => {
        const allIndexesArray: number[][] = partnerDetails
          .map((partnerDetail: PartnerDictionary) => partnerDetail.treatmentCenters)
          .map((treatmentCentersForProfile: TreatmentCenterDictionary[]) =>
            treatmentCentersForProfile.map((tc: TreatmentCenterDictionary) => tc.id)
          );

        const partnerAffiliatedDetailsIds: number[] = ([] as number[]).concat(...allIndexesArray);

        this.isTcLinkActive = !isAffiliated || (isAffiliated && partnerAffiliatedDetailsIds.includes(treatmentCenterId));
      });
    });
  }
}
