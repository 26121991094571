<stx-resources-navigation selectedItemId="{{ NavigationSubTabEnum.RESOURCES_USER_GUIDE }}"></stx-resources-navigation>
<stx-generic-wide-page>
  <stx-page-header [name]="'menu.resources.user_guide' | translate">
    <mat-icon class="vertical-align-middle m-l-5">videocam</mat-icon>
  </stx-page-header>
  <div class="layout-columns-1-1">
    <div class="layout-columns-1-1">
      <div class="data-section">
        <stx-generic-resource-list [sections]="firstCol"></stx-generic-resource-list>
      </div>
      <div class="data-section">
        <stx-generic-resource-list [sections]="secondCol"></stx-generic-resource-list>
      </div>
    </div>
    <div class="layout-columns-1-1">
      <div class="data-section">
        <stx-generic-resource-list [sections]="thirdCol"></stx-generic-resource-list>
      </div>
      <div class="data-section">
        <stx-generic-resource-list [sections]="fourthCol"></stx-generic-resource-list>
      </div>
    </div>
  </div>
</stx-generic-wide-page>
