<stx-patient-forms-headers-container *ngIf="!expandable" [formType]="formType" cssClass="m-l-24 m-b-24">
  <ng-container slot="additionalText" *ngIf="nutritionAgeGroupHeader">- {{ nutritionAgeGroupHeader | translate }}</ng-container>
</stx-patient-forms-headers-container>

<div *ngIf="isReadonlyView">
  <stx-treatment-status-bar
    *ngIf="!expandable"
    [treatment]="nutritionManagement"
    (unlockClicked)="unlock()"
    (deleteClicked)="delete()"
  ></stx-treatment-status-bar>
  <div class="page-section-container">
    <stx-nutrition-readonly [treatment]="nutritionManagement"></stx-nutrition-readonly>
  </div>
</div>

<div *ngIf="!isReadonlyView" [formGroup]="treatmentFormGroup">
  <stx-treatment-status-bar
    *ngIf="!expandable"
    [treatment]="nutritionManagement"
    (unlockClicked)="unlock()"
    (deleteClicked)="delete()"
  ></stx-treatment-status-bar>
  <stx-form-panel class="p-b-15">
    <div class="form-row">
      <stx-nutrition-stage-indicator [print]="print" [stage]="nutritionStage"></stx-nutrition-stage-indicator>
    </div>
    <stx-nutrition-general-info
      [tcList]="tcList"
      [nutritionManagementFormGroup]="treatmentFormGroup"
      [dateOfBirth]="patient.dateOfBirth"
      [minDate]="assessmentMinDate"
      [maxDate]="assessmentMaxDate"
      [formMode]="formMode"
      (nutritionTypeChange)="updateFormType($event)"
      [print]="print"
      [nutritionFormType]="nutritionFormType"
    ></stx-nutrition-general-info>
    <ng-container *ngIf="nutritionFormType && nutritionFormType === ageGroups.INFANT">
      <hr class="form-section-separator" />

      <div class="form-columns-1-1" [ngClass]="{ 'flex-row-important': print }">
        <stx-nutrition-birthweight
          [formGroup]="treatmentFormGroup.get('birthWeightAndFeedingInformation')"
          [formMode]="formMode"
          [print]="print"
          [nutritionFormType]="nutritionFormType"
          [nutritionBirthweightOptions]="infantBirthweightOptions"
        ></stx-nutrition-birthweight>

        <hr class="form-section-separator hide-gt-md hide-on-print" />

        <stx-feeding-assessment
          [formGroup]="formGroup.get('birthWeightAndFeedingInformation')"
          [formMode]="formMode"
          [print]="print"
          [nutritionFormType]="nutritionFormType"
        ></stx-feeding-assessment>
      </div>

      <hr class="form-section-separator" />

      <div class="form-columns-1-1" [ngClass]="{ 'flex-row-important': print }">
        <stx-nutrition-current-feeding
          [formGroup]="treatmentFormGroup.get('birthWeightAndFeedingInformation')"
          [formMode]="formMode"
          [print]="print"
          [nutritionFormType]="nutritionFormType"
          [newbornFeedingStatusOptions]="newbornFeedingStatusOptions"
        ></stx-nutrition-current-feeding>
        <hr class="form-section-separator hide-on-print hide-gt-md" />
        <stx-nutritional-status
          [formGroup]="treatmentFormGroup"
          [formMode]="formMode"
          [nutritionFormType]="nutritionFormType"
          [print]="print"
        ></stx-nutritional-status>
      </div>

      <hr class="form-section-separator hide-on-print" />

      <div class="form-columns-1-1" [ngClass]="{ 'flex-row-important': print }">
        <stx-nutrition-patient-care-type
          [formGroup]="treatmentFormGroup.get('initialTypeOfPatientCare')"
          [formMode]="formMode"
          [print]="print"
          [nutritionFormType]="nutritionFormType"
        ></stx-nutrition-patient-care-type>

        <hr class="form-section-separator hide-on-print hide-gt-md" />

        <stx-nutrition-care-plan
          [formGroup]="formGroup.get('nutritionCarePlan')"
          [formMode]="formMode"
          [print]="print"
          [nutritionFormType]="nutritionFormType"
        ></stx-nutrition-care-plan>
      </div>

      <hr class="form-section-separator" />

      <div class="form-column-1-1 half-full-width-until-lt-md">
        <stx-nutrition-anemia-management
          [formGroup]="formGroup.get('anemiaManagement')"
          [formMode]="formMode"
          [nutritionFormType]="nutritionFormType"
          [print]="print"
        ></stx-nutrition-anemia-management>
      </div>
    </ng-container>
    <ng-container *ngIf="nutritionFormType && nutritionFormType !== ageGroups.INFANT">
      <hr class="form-section-separator" />
      <div class="form-columns-1-1" [ngClass]="{ 'flex-row-important': print }">
        <stx-feeding-assessment
          [formGroup]="formGroup.get('birthWeightAndFeedingInformation')"
          [formMode]="formMode"
          [print]="print"
          [nutritionFormType]="nutritionFormType"
        ></stx-feeding-assessment>

        <hr class="form-section-separator hide-on-print hide-gt-md" />

        <stx-nutritional-status
          [formGroup]="treatmentFormGroup"
          [formMode]="formMode"
          [nutritionFormType]="nutritionFormType"
          [print]="print"
        ></stx-nutritional-status>
      </div>

      <hr class="form-section-separator" />
      <div class="form-columns-1-1" [ngClass]="{ 'flex-row-important': print }">
        <hr class="form-section-separator hide-on-print hide-gt-md" />
        <stx-nutrition-patient-care-type
          [formGroup]="treatmentFormGroup.get('initialTypeOfPatientCare')"
          [formMode]="formMode"
          [print]="print"
          [nutritionFormType]="nutritionFormType"
        ></stx-nutrition-patient-care-type>
        <stx-nutrition-care-plan
          [formGroup]="formGroup.get('nutritionCarePlan')"
          [formMode]="formMode"
          [print]="print"
          [nutritionFormType]="nutritionFormType"
        ></stx-nutrition-care-plan>
      </div>

      <hr class="form-section-separator" />
      <div class="form-columns-1-1">
        <stx-nutrition-anemia-management
          [formGroup]="formGroup.get('anemiaManagement')"
          [formMode]="formMode"
          [nutritionFormType]="nutritionFormType"
          [print]="print"
        ></stx-nutrition-anemia-management>
        <div class="form-row"></div>
      </div>
    </ng-container>
  </stx-form-panel>
  <stx-action-buttons
    *ngIf="!print"
    [asyncSecondaryAction]="onSaveButtonClicked()"
    [asyncPrimaryAction]="onSubmitButtonClicked()"
  ></stx-action-buttons>
</div>
