import { Resource } from '@src/app/features/educational-resources/models/resource.model';

export const patientRegistrationResource: Resource = {
  label: 'medical_forms.printable_forms.registration',
  resourceName: 'Patient_Registration_',
  translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn', 'th']],
  isSeparated: true
};
export const patientReleaseFormResource: Resource = {
  label: 'medical_forms.printable_forms.release',
  resourceName: 'Release_Form_',
  translationList: [
    [
      'ar',
      'as',
      'bn',
      'my',
      'en',
      'en-ph',
      'en-th',
      'es',
      'fr',
      'gu',
      'ht',
      'hi',
      'id',
      'kn',
      'ml',
      'mr',
      'or',
      'pt-br',
      'pt-mz',
      'pa',
      'ru',
      'tl',
      'ta',
      'te',
      'th',
      'ur',
      'vi',
      'yo',
      'cn'
    ]
  ],
  isSeparated: true
};
export const speechAssessmentMedicalForm: Resource = {
  label: 'medical_forms.printable_forms.speech_assessment',
  resourceName: 'Speech_VPD_Assessment_',
  translationList: [['en', 'es', 'fr', 'id', 'pt', 'cn', 'vi']]
};
export const speechTreatmentMedicalForm: Resource = {
  label: 'medical_forms.printable_forms.speech_treatment_report',
  resourceName: 'Speech_Treatment_',
  translationList: [['en', 'es', 'fr', 'id', 'pt', 'cn', 'vi']]
};
