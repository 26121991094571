import { Scope } from './scope.model';
import { Item } from 'src/app/shared/models/item.model';

export class PersonalData {
  id?: number;
  firstName: string;
  lastName: string;
  middleName?: string;
  email: string;
  lang: string;
  enabled: boolean;
}

export class UserData extends PersonalData {
  profiles: Profile[];
}

export class Profile {
  id?: number;
  name?: string;
  role: Item;
  roleId: number;
  scopesId: number[];
  scopes?: Scope[];
  designatedArea: string;
}
