import { PartnerDetailsModel } from '@src/app/features/partner/models/partner-details.model';
import { TreatmentCenterDetails } from '@src/app/features/treatment-center/models/treatment-center-details.model';

export function buildDetailsList(details: PartnerDetailsModel | TreatmentCenterDetails): { labelKey: string }[] {
  return [
    details.surgery ? 'treatment.surgery' : null,
    details.orthognathicTreatment ? 'treatment.orthognathic_treatment' : null,
    details.borescope ? 'treatment.borescope' : null,
    details.speech ? 'treatment.speech' : null,
    details.vpd ? 'treatment.vpd_surgery' : null,
    details.orthodontics ? 'treatment.ortho' : null,
    details.nutrition ? 'treatment.nutrition' : null,
    details.ent ? 'treatment.ent' : null,
    details.dental ? 'treatment.dental' : null,
    details.psychosocial ? 'treatment.psychosocial' : null
  ]
    .filter(programKey => programKey !== null)
    .map(programKey => ({ labelKey: programKey }));
}

export function buildLatestSubmissionsList(details: PartnerDetailsModel | TreatmentCenterDetails) {
  return [
    { labelKey: 'treatment.surgery', value: details.latestSurgical },
    { labelKey: 'treatment.speech', value: details.latestSpeech },
    { labelKey: 'treatment.ortho', value: details.latestOrtho },
    { labelKey: 'treatment.nutrition', value: details.latestNutrition }
  ].filter(element => element.value != null);
}
