import { emptyImageCell } from '@shared/components/form/components/image-group/image-group.model';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';

export const dentitionAssessmentParentOrderNames = [
  ParentOrderName.ORTHO_FRONTAL_PRE,
  ParentOrderName.ORTHO_LEFT_PRE,
  ParentOrderName.ORTHO_RIGHT_PRE,
  ParentOrderName.ORTHO_BASAL_PRE,
  ParentOrderName.ORTHO_FRONTAL_SMILE_PRE,
  ParentOrderName.ORTHO_INTRA_ORAL_FRONTAL_PRE,
  ParentOrderName.ORTHO_LEFT_OCCLUSION_PRE,
  ParentOrderName.ORTHO_RIGHT_OCCLUSION_PRE,
  ParentOrderName.ORTHO_MAXILLARY_ARCH_PRE,
  ParentOrderName.ORTHO_MANDIBULAR_ARCH_PRE,
  ParentOrderName.ORTHO_CEPHALOMETRIC_RADIOGRAPH_PRE,
  ParentOrderName.ORTHO_PANORAMIC_RADIOGRAPH_PRE,
  ParentOrderName.ORTHO_CLEFT_SITE_OCCLUSAL_OR_PERIAPICAL_RADIOGRAPH_PRE
];

export const dentitionTreatmentParentOrderNames = [
  ParentOrderName.ORTHO_FRONTAL_POST,
  ParentOrderName.ORTHO_LEFT_POST,
  ParentOrderName.ORTHO_RIGHT_POST,
  ParentOrderName.ORTHO_BASAL_POST,
  ParentOrderName.ORTHO_FRONTAL_SMILE_POST,
  ParentOrderName.ORTHO_INTRA_ORAL_FRONTAL_POST,
  ParentOrderName.ORTHO_LEFT_OCCLUSION_POST,
  ParentOrderName.ORTHO_RIGHT_OCCLUSION_POST,
  ParentOrderName.ORTHO_MAXILLARY_ARCH_POST,
  ParentOrderName.ORTHO_MANDIBULAR_ARCH_POST,
  ParentOrderName.ORTHO_CEPHALOMETRIC_RADIOGRAPH_POST,
  ParentOrderName.ORTHO_PANORAMIC_RADIOGRAPH_POST,
  ParentOrderName.ORTHO_CLEFT_SITE_OCCLUSAL_OR_PERIAPICAL_RADIOGRAPH_POST
];
export const dentitionMidAssessmentParentOrderNames = [
  ParentOrderName.ORTHO_IN_PROGRESS_1,
  ParentOrderName.ORTHO_IN_PROGRESS_2,
  ParentOrderName.ORTHO_IN_PROGRESS_3,
  ParentOrderName.ORTHO_IN_PROGRESS_4,
  ParentOrderName.ORTHO_IN_PROGRESS_5,
  ParentOrderName.ORTHO_IN_PROGRESS_6,
  ParentOrderName.ORTHO_IN_PROGRESS_7,
  ParentOrderName.ORTHO_IN_PROGRESS_8,
  ParentOrderName.ORTHO_IN_PROGRESS_9
];

export const mixedDentitionApplianceTypes = [
  {
    name: 'fixedApp',
    label: 'mixed_dentition.fixed_app',
    value: false
  },
  {
    name: 'maxExp',
    label: 'mixed_dentition.max_exp',
    value: false
  },
  {
    name: 'faceMask',
    label: 'mixed_dentition.face_mask',
    value: false
  }
];

export const permanentDentitionApplianceTypes = [
  {
    name: 'fullFixedApp',
    label: 'permanent_dentition.full_fixed_app',
    value: false
  },
  {
    name: 'splints',
    label: 'permanent_dentition.splints',
    value: false
  }
];
export const mixedDentitionApplianceFields = ['fixedApp', 'maxExp', 'faceMask', 'otherDevice'];
export const permanentDentitionApplianceFields = ['fullFixedApp', 'splints', 'otherDevice'];

export const fistulaOptions = [
  {
    name: 'fistulaSoftPalate',
    label: 'ortho.fistula_soft_palate',
    value: false
  },
  {
    name: 'fistulaHardPalate',
    label: 'ortho.fistula_hard_palate',
    value: false
  },
  {
    name: 'fistulaAlveolarRidge',
    label: 'ortho.fistula_alveolar_ridge',
    value: false
  },
  {
    name: 'fistulaPreSurgery',
    label: 'ortho.fistula_pre_surgery',
    value: false
  }
];

export const dentitionAssessmentFacialImagesConfigs = [
  {
    parentOrderName: ParentOrderName.ORTHO_FRONTAL_PRE,
    label: 'order_name.facial_frontal',
    isRequired: true
  },
  emptyImageCell,
  {
    parentOrderName: ParentOrderName.ORTHO_LEFT_PRE,
    label: 'order_name.facial_left',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_RIGHT_PRE,
    label: 'order_name.facial_right',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_BASAL_PRE,
    label: 'order_name.facial_basal',
    isRequired: true
  },
  emptyImageCell,
  {
    parentOrderName: ParentOrderName.ORTHO_FRONTAL_SMILE_PRE,
    label: 'order_name.facial_frontal_smile',
    isRequired: false
  }
];

export const dentitionTreatmentFacialImagesConfigs = [
  {
    parentOrderName: ParentOrderName.ORTHO_FRONTAL_POST,
    label: 'order_name.facial_frontal',
    isRequired: true
  },
  emptyImageCell,
  {
    parentOrderName: ParentOrderName.ORTHO_LEFT_POST,
    label: 'order_name.facial_left',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_RIGHT_POST,
    label: 'order_name.facial_right',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_BASAL_POST,
    label: 'order_name.facial_basal',
    isRequired: true
  },
  emptyImageCell,
  {
    parentOrderName: ParentOrderName.ORTHO_FRONTAL_SMILE_POST,
    label: 'order_name.facial_frontal_smile',
    isRequired: false
  }
];

export const dentitionAssessmentIntraOralImagesConfigs = [
  {
    parentOrderName: ParentOrderName.ORTHO_INTRA_ORAL_FRONTAL_PRE,
    label: 'order_name.intra_oral_frontal',
    isRequired: true
  },
  emptyImageCell,
  {
    parentOrderName: ParentOrderName.ORTHO_LEFT_OCCLUSION_PRE,
    label: 'order_name.left_occlusion',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_RIGHT_OCCLUSION_PRE,
    label: 'order_name.right_occlusion',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_MAXILLARY_ARCH_PRE,
    label: 'order_name.maxillary_arch',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_MANDIBULAR_ARCH_PRE,
    label: 'order_name.mandibular_arch',
    isRequired: true
  }
];

export const dentitionTreatmentIntraOralImagesConfigs = [
  {
    parentOrderName: ParentOrderName.ORTHO_INTRA_ORAL_FRONTAL_POST,
    label: 'order_name.intra_oral_frontal',
    isRequired: true
  },
  emptyImageCell,
  {
    parentOrderName: ParentOrderName.ORTHO_LEFT_OCCLUSION_POST,
    label: 'order_name.left_occlusion',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_RIGHT_OCCLUSION_POST,
    label: 'order_name.right_occlusion',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_MAXILLARY_ARCH_POST,
    label: 'order_name.maxillary_arch',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_MANDIBULAR_ARCH_POST,
    label: 'order_name.mandibular_arch',
    isRequired: true
  }
];

export const dentitionAssessmentXRayslImagesConfigs = [
  {
    parentOrderName: ParentOrderName.ORTHO_CEPHALOMETRIC_RADIOGRAPH_PRE,
    label: 'order_name.cephalometric_radiograph',
    isRequired: false
  },
  {
    parentOrderName: ParentOrderName.ORTHO_PANORAMIC_RADIOGRAPH_PRE,
    label: 'order_name.panoramic_radiograph',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_CLEFT_SITE_OCCLUSAL_OR_PERIAPICAL_RADIOGRAPH_PRE,
    label: 'order_name.cleft_site_occlusal_or_periapical_radiograph',
    isRequired: false
  }
];

export const dentitionTreatmentXRayslImagesConfigs = [
  {
    parentOrderName: ParentOrderName.ORTHO_CEPHALOMETRIC_RADIOGRAPH_POST,
    label: 'order_name.cephalometric_radiograph',
    isRequired: false
  },
  {
    parentOrderName: ParentOrderName.ORTHO_PANORAMIC_RADIOGRAPH_POST,
    label: 'order_name.panoramic_radiograph',
    isRequired: true
  },
  {
    parentOrderName: ParentOrderName.ORTHO_CLEFT_SITE_OCCLUSAL_OR_PERIAPICAL_RADIOGRAPH_POST,
    label: 'order_name.cleft_site_occlusal_or_periapical_radiograph',
    isRequired: false
  }
];

export const inProgressImagesConfigs = [
  {
    parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_1,
    label: ' ',
    isRequired: false
  },
  {
    parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_2,
    label: ' ',
    isRequired: false
  },
  {
    parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_3,
    label: ' ',
    isRequired: false
  },
  {
    parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_4,
    label: ' ',
    isRequired: false
  },
  {
    parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_5,
    label: ' ',
    isRequired: false
  },
  {
    parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_6,
    label: ' ',
    isRequired: false
  },
  {
    parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_7,
    label: ' ',
    isRequired: false
  },
  {
    parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_8,
    label: ' ',
    isRequired: false
  },
  {
    parentOrderName: ParentOrderName.ORTHO_IN_PROGRESS_9,
    label: ' ',
    isRequired: false
  }
];

export const fistulaFields = [`fistulaSoftPalate`, `fistulaHardPalate`, `fistulaAlveolarRidge`, `fistulaPreSurgery`];
