import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PartnerService } from '../../partner.service';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { StatisticsPlot, StatisticsTable } from '@shared/modules/statistics/statistics.model';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  templateUrl: './partner-details-page.component.html'
})
export class PartnerDetailsPageComponent extends BaseComponent {
  private _partnerId: number;

  statisticsTable: StatisticsTable;
  statisticsPlots: StatisticsPlot;

  constructor(
    route: ActivatedRoute,
    private readonly partnerService: PartnerService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
    this.subSink.sink = route.params.subscribe(params => (this.partnerId = params.id));
  }

  get partnerId(): number {
    return this._partnerId;
  }

  set partnerId(id: number) {
    if (this._partnerId !== id) {
      this._partnerId = id;
      this.loadStatistics(id);
      this.loadStatisticsPlots(id);
    }
  }

  loadStatistics(partnerId: number): void {
    this.wsHelperService.callWithSpinner(this.partnerService.getPartnerStatistics(partnerId)).subscribe(stats => {
      this.statisticsTable = stats;
      this.changeDetectorRef.markForCheck();
    });
  }

  loadStatisticsPlots(partnerId: number): void {
    this.wsHelperService.callWithSpinner(this.partnerService.getPartnerStatisticsPlot(partnerId)).subscribe(statisticsPlots => {
      this.statisticsPlots = statisticsPlots;
      this.changeDetectorRef.markForCheck();
    });
  }
}
