import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'stx-file-export-icon',
  templateUrl: './file-export-icon.component.html',
  styleUrls: ['./file-export-icon.component.scss']
})
export class FileExportIconComponent {
  readonly faFileExport = faFileExport;
  @Output() download = new EventEmitter<void>();

  onClick() {
    this.download.emit();
  }
}
