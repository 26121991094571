import packageJson from '@/package.json';
import { environment } from '@src/environments/environment';
import { init } from '@sentry/angular';

// https://develop.sentry.dev/sdk/data-handling/#variable-size
const sentryMaxLengthLimit = 8192;

export function initSentryIfApplicable() {
  if (!!environment.sentry_dsn) {
    init({
      dsn: environment.sentry_dsn,
      release: 'release-' + packageJson.version,
      integrations: [],
      maxValueLength: sentryMaxLengthLimit
    });
  }
}
