<div class="reason-modal">
  <div class="suspicious-problem-dialog-container gap-40" [formGroup]="reasonFormGroup">
    <div class="custom-flex-80 flex-column-container gap-20">
      <div class="suspicious-problem-dialog-container gap-20">
        <div class="flex-column-container half-flex-container">
          <mat-radio-group class="flex-column-container gap-10" formControlName="accept">
            <div class="flex-column-container gap-10">
              <mat-radio-button data-testid="radio-suspicious-records-accept" [value]="true">
                {{ 'suspicious_records.accept' | translate }}
              </mat-radio-button>
              <mat-radio-button
                data-testid="radio-suspicious-records-rejection"
                class="text-uppercase"
                [value]="false"
                (click)="setDefaultRejectionReason()"
                >{{ 'suspicious_records.rejection_reasons' | translate }}</mat-radio-button
              >
            </div>
            <div class="form-row m-l-20 gap-10">
              <stx-checkbox-group
                *ngIf="rejectionReasons"
                [formGroup]="reasonFormGroup"
                [layoutDirection]="'column'"
                [options]="rejectionReasons"
                [formGroupErrorNames]="['rejectionReasons']"
              ></stx-checkbox-group>
            </div>
          </mat-radio-group>
        </div>
        <div class="half-flex-container flex-column-container gap-10">
          <label for="problem-type" class="text-uppercase"> {{ 'suspicious_records.type_of_problem' | translate }} </label>
          <stx-radio-group
            [layoutDirection]="'column'"
            [controlName]="'status'"
            [formGroup]="reasonFormGroup"
            [options]="problematicOptions"
            id="problem-type"
          >
          </stx-radio-group>
        </div>
      </div>

      <div class="suspicious-problem-dialog-container gap-20">
        <div class="half-flex-container">
          <stx-textarea [label]="'suspicious_records.external_comments'" [controlName]="'externalComments'" [formGroup]="reasonFormGroup">
          </stx-textarea>
        </div>
        <div class="half-flex-container">
          <stx-textarea [label]="'suspicious_records.internal_comments'" [controlName]="'internalComments'" [formGroup]="reasonFormGroup">
          </stx-textarea>
        </div>
      </div>

      <div>
        <stx-action-buttons
          [primaryText]="'save' | translate"
          [secondaryText]="'suspicious_records.skip' | translate"
          (secondaryAction)="skipRecord()"
          (primaryAction)="save()"
        ></stx-action-buttons>
      </div>
    </div>

    <div class="custom-flex-20 flex-column-container">
      <div *ngIf="previousProblematicRecords">
        <label class="text-uppercase">
          {{ previousProblematicRecords.length }} {{ 'suspicious_records.last_two_years_records' | translate }}
        </label>
        <div *ngFor="let record of previousProblematicRecords" class="flex-row-container gap-10">
          <div>
            {{ record.statusKey | translate }}
          </div>
          <div>
            <a
              (click)="$event.stopPropagation()"
              [routerLink]="[surgicalRoute, intervention, record.treatmentId]"
              [queryParams]="{ patientId: record.patientBasicInfo.id }"
              target="_blank"
              >{{ record.patientBasicInfo.firstName }} {{ record.patientBasicInfo.middleName }} {{ record.patientBasicInfo.lastName }}</a
            >
          </div>
          <div>
            <a
              (click)="$event.stopPropagation()"
              [routerLink]="patientDetailsPath(record.patientBasicInfo.id)"
              target="_blank"
              class="m-r-24 patient-block-icon"
            >
              <stx-patient-record-icon></stx-patient-record-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
