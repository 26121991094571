<stx-generic-page>
  <div class="flex-row-container align-middle">
    <stx-page-header [name]="'menu.edit_settings' | translate"> </stx-page-header>
    <p class="email-display">{{ ' – ' + getUserEmail() }}</p>
  </div>
  <stx-form-panel>
    <div class="flex-column-container">
      <label>{{ 'user.language_preferences' | translate }}</label>
      <stx-language-switcher [allowLangSwitch]="false" class="lang-switcher"></stx-language-switcher>
    </div>
    <stx-action-buttons
      (primaryAction)="language.saveLanguage(language.languageSelectorForm.get('lang').value)"
      [primaryText]="'save' | translate"
      [showSecondary]="false"
    ></stx-action-buttons>
  </stx-form-panel>

  <stx-form-panel>
    <form
      [formGroup]="changePasswordForm"
      (ngSubmit)="changePassword()"
      class="password-form flex-column-container"
      [ngClass]="{ 'm-b-100': hasErrors('password') }"
    >
      <div class="page-section flex-row-container change-password-container">
        <ng-container *ngFor="let controlKey of changePasswordSetup.keys()">
          <div class="flex-column-container m-r-10">
            <label for="{{ controlKey }}">{{ changePasswordSetup.get(controlKey).name | translate }}</label>
            <mat-form-field appearance="outline" class="input-field--edit-settings">
              <input
                data-testid="input-password-edit-setting"
                [type]="changePasswordSetup.get(controlKey).hidePassword ? 'password' : 'text'"
                matInput
                [formControlName]="controlKey"
                class="input-field--edit-settings"
                id="{{ controlKey }}"
              />
              <mat-icon
                [ngClass]="{
                  visibility: !changePasswordSetup.get(controlKey).hidePassword,
                  'visibility-off': changePasswordSetup.get(controlKey).hidePassword
                }"
                matSuffix
                (click)="changePasswordSetup.get(controlKey).hidePassword = !changePasswordSetup.get(controlKey).hidePassword"
                >{{ changePasswordSetup.get(controlKey).hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon
              >
              <mat-error class="stx-mat-error-align">
                <ng-container *ngIf="changePasswordForm.get(controlKey).hasError('required'); else otherError">
                  {{ 'error.field_empty' | translate }}
                </ng-container>
                <ng-template #otherError>
                  <ng-container *ngIf="controlKey === 'password'">
                    <stx-multi-error-validator [violatedRules]="changePasswordForm.get('password').errors"></stx-multi-error-validator>
                  </ng-container>
                  <ng-container *ngIf="controlKey === 'currentPassword'">
                    <stx-multi-error-validator
                      [violatedRules]="changePasswordForm.get('currentPassword').errors"
                    ></stx-multi-error-validator>
                  </ng-container>
                  <ng-container *ngIf="controlKey === 'passwordConfirm'">
                    {{ changePasswordSetup.get(controlKey).err | translate }}
                  </ng-container>
                </ng-template>
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
      <stx-action-buttons
        [primaryText]="'settings.change_password' | translate"
        [showSecondary]="false"
        [primaryDisabled]="changePasswordForm.invalid"
      ></stx-action-buttons>
    </form>
  </stx-form-panel>
</stx-generic-page>
