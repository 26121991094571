import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@shared/services/http/base-http.service';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrgTreeFilterModel } from '../../../../search/models/org-tree-filter.model';
import { SearchService } from '../../../../search/search.service';
import {
  ReportDictionaries,
  ReportFilters,
  ReportsDictionariesResponse,
  ReportType,
  SectionedCommonReportDictionaryItem
} from '../models/report-filters.model';
import { ReportApiUrlService } from './report-api-url.service';

@Injectable({
  providedIn: 'root'
})
export class ReportDictionariesService extends BaseHttpService {
  private static PERIOD_TYPE_PRESET_ID = 'SP';

  dictionaries: ReportDictionaries = {
    genders: [],
    latestCases: [],
    postRanks: [],
    preRanks: [],
    qaStages: [],
    ageGroups: [],
    statuses: [],
    qualityAssuranceSurgicalInterventions: [],
    periodTypes: [],
    recordTypes: [],
    surgeons: [],
    practitioners: [],
    nutritionists: [],
    orthodontists: [],
    groupByData: [],
    surgeryStatus: [],
    surgicalInterventions: [],
    treatmentStatuses: [],
    repairTypes: {
      primaryLipNoseUnilateral: [],
      primaryLipNoseBilateral: [],
      lipNoseRevision: [],
      primaryCleftPalate: [],
      fistulaRepair: [],
      secondaryCleftPalate: [],
      other: [],
      velopharyngealDysfunction: [],
      alveolarBoneGraft: []
    },
    orthoPsioCategories: {} as SectionedCommonReportDictionaryItem,
    orthoMdCategories: {} as SectionedCommonReportDictionaryItem,
    orthoPdCategories: {} as SectionedCommonReportDictionaryItem,
    reviewers: [],
    speechAssessmentStatuses: [],
    speechAssessmentRecommendations: [],
    speechTreatmentStatuses: [],
    mohGrades: [],
    exclusions: [],
    photoTypes: [],
    speechAssessmentStates: [],
    speechTreatmentStates: [],
    statsTypes: [],
    orthoRecordDates: {},
    orthoPostGrading: [],
    orthoPreGrading: [],
    ageGroupsWithTreatmentStatuses: []
  };

  constructor(private searchService: SearchService, private reportApiUrlService: ReportApiUrlService, httpClient: HttpClient) {
    super(httpClient, { baseUrl: 'reports' });
  }

  private prepareOrganizationsTreeDictionary() {
    const orgTreeObservable$ = this.searchService.getOrgTreeFilters();

    return orgTreeObservable$.pipe(
      tap((orgTreeFilters: OrgTreeFilterModel) => {
        this.dictionaries.organizationsTree = orgTreeFilters;
      })
    );
  }

  isPeriodTypePreset(periodId: string) {
    return periodId === ReportDictionariesService.PERIOD_TYPE_PRESET_ID;
  }

  prepareRequiredDictionaries(reportFilters: ReportFilters, reportType: ReportType) {
    const dictionariesGetters: Observable<any>[] = [this.fetchReportDictionaries(reportType)];

    if (reportFilters.geography || reportFilters.organizationsTree) {
      dictionariesGetters.push(this.prepareOrganizationsTreeDictionary());
    }

    return forkJoin(dictionariesGetters);
  }

  private fetchReportDictionaries(reportType: ReportType) {
    return this.httpClient
      .get<ReportsDictionariesResponse>(this.reportApiUrlService.getStaticDictionariesUrl(this.baseUrl, reportType))
      .pipe(
        tap(dictionaries => {
          Object.assign(this.dictionaries, dictionaries);
        })
      );
  }
}
