import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-simple-readonly-field',
  templateUrl: './simple-readonly-field.component.html'
})
export class SimpleReadonlyFieldComponent {
  @Input()
  label: string;

  @Input()
  separator: string = ' | ';

  @Input()
  value: any | any[];

  @Input()
  suffix: string;

  get isNotEmpty(): boolean {
    return this.items.length > 0;
  }

  get items(): any[] {
    if (!this.value && typeof this.value !== 'number') {
      return [];
    } else if (Array.isArray(this.value)) {
      return this.value.filter(element => !!element);
    } else {
      return [this.value];
    }
  }
}
