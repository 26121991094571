import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { DictionaryTranslation } from '@/src/app/features/patient/models/patient.model';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { showStandardError } from '@/src/app/shared/validation/error.utils';
import { FormMode, ReadonlyFormUtils } from '@/src/app/utils/form.utils';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

/** Form element with common template properties */
@Component({ template: '' })
export abstract class BaseFormElementComponent extends BaseComponent {
  readonly PermissionEnum = PermissionEnum;
  readonly ParentOrderName = ParentOrderName;
  readonly ReadonlyFormUtils = ReadonlyFormUtils;
  readonly FormMode = FormMode;
  readonly TreatmentType = TreatmentType;
  readonly FormType = FormType;
  readonly showStandardError = showStandardError;
  private _formGroup: UntypedFormGroup;
  @Input() formMode: FormMode;
  @Input() print: boolean;
  @Input() expandable: boolean;

  @Input() set formGroup(formGroup: UntypedFormGroup) {
    this._formGroup = formGroup;
  }
  @Input() staticDictionaries: Map<string, DictionaryTranslation[]>;

  get formGroup() {
    return this._formGroup;
  }

  get isReadonlyView(): boolean {
    return this.formMode === FormMode.READONLY;
  }

  get isEditView(): boolean {
    return this.formMode === FormMode.EDIT;
  }
}
