import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Practitioner } from '@/src/app/shared/models/practitioner.model';
import { StxValidators } from '@/src/app/shared/validation/validators';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'stx-anesthesiologist-select',
  templateUrl: './anesthesiologist-select.component.html'
})
export class AnesthesiologistSelectComponent extends BaseFormElementComponent implements OnInit {
  static readonly idCtrlName = 'anesthesiologistId';

  static readonly requiredErrorName = StxValidators.requiredErrorName;
  static readonly requiredErrorKey = 'error.anesthesiologist';
  static readonly requiredSelectedAnesthesiologist = 'error.anesthesiologist_select';

  readonly selectIdControlName = AnesthesiologistSelectComponent.idCtrlName;

  readonly requiredErrorName = AnesthesiologistSelectComponent.requiredErrorName;
  readonly requiredErrorKey = AnesthesiologistSelectComponent.requiredErrorKey;

  readonly selectIdLabel = 'surgical_intervention.anesth_id';

  readonly registerAnesthesiologistLinkPrefixKey = 'surgical_intervention.anesthesia_link_prefix';
  readonly registerAnesthesiologistLinkKey = 'surgical_intervention.anesthesia_link_text';
  readonly registerAnesthesiologistLink = 'https://smiletrain.force.com/grantee/FGM_Portal__CommunitySignin';

  private _selectDataSource: Practitioner[] = [];
  @Input() set selectDataSource(anesthesiologists: Practitioner[]) {
    this._selectDataSource = anesthesiologists ?? [];
  }

  get selectDataSource() {
    return this._selectDataSource;
  }

  get selectControl() {
    return this.formGroup?.get(this.selectIdControlName);
  }

  get isSelectDataSourceEmpty(): boolean {
    return !this._selectDataSource || this._selectDataSource.length === 0;
  }

  constructor(private readonly controlContainer: ControlContainer) {
    super();
  }

  ngOnInit(): void {
    if (!!this.controlContainer?.control) {
      this.formGroup = this.controlContainer.control as UntypedFormGroup;
    } else {
      throw new Error('No parent form group present.');
    }

    this.subSink.sink = this.formGroup.get('treatmentCenterId').valueChanges.subscribe({
      next: () => {
        if (this.initialized) {
          this.selectControl.reset();
        }
      }
    });
  }

  get initialized(): boolean {
    return this.selectDataSource != null && this.selectDataSource.length > 0 && !!this.selectControl;
  }
}
