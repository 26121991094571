import { ReportConfig } from '@/src/app/features/reports/components/report-filters/models/report-filters.model';
import { ReportFiltersModule } from '@/src/app/features/reports/components/report-filters/report-filters.component';
import { ReportRequestService } from '@/src/app/features/reports/components/report-filters/services/report-request.service';
import { ReportService } from '@/src/app/features/reports/components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '@/src/app/features/reports/components/report-header/report-header.component';
import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { state, style, transition, trigger, useAnimation } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { slideDown, slideUp } from '@core/animations';
import { TIMINGS } from '@core/animations/constant.animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { NoticeComponentModule } from '@shared/components/notice/notice.component';
import { Mime } from '@utils/file.utils';
import { filter, finalize } from 'rxjs/operators';
import {
  AvailablePartnerSurgeryCountsReportTypes,
  PartnerSurgeryCountsReportParameters,
  PartnerSurgeryCountsReportTypes
} from './partner-surgery-counts-report.model';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  selector: 'stx-partner-surgery-counts-report',
  templateUrl: './partner-surgery-counts-report.component.html',
  animations: [
    trigger('successNotice', [
      state('visible', style({ display: '*' })),
      state(
        'hidden',
        style({
          height: '0',
          overflow: 'hidden'
        })
      ),
      transition('hidden => visible', [
        useAnimation(slideDown, {
          params: {
            timing: TIMINGS.FAST
          }
        })
      ]),
      transition('visible => hidden', [
        useAnimation(slideUp, {
          params: {
            timing: TIMINGS.DEFAULT
          }
        })
      ])
    ])
  ]
})
export class PartnerSurgeryCountsReportComponent extends BaseComponent implements OnInit {
  private readonly filename = 'VendorIDs.csv';
  reportConfig: ReportConfig = {
    type: 'PARTNER_SURGERY_COUNTS',
    downloadOptions: {},
    filters: {
      date: true,
      geography: true,
      organizationsTree: true
    }
  };

  title = '';
  successNoticeVisible = false;
  type: AvailablePartnerSurgeryCountsReportTypes;

  constructor(
    private reportService: ReportService,
    private reportRequestService: ReportRequestService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.watchRouteParams();
    this.enableNoticeAutoHide();
    this.reportService.showFilters();
  }

  downloadReport() {
    this.wsHelperService
      .callWithSpinner(
        this.reportRequestService.getBinary({
          endpoint: this.getApiUrl(),
          filename: this.filename,
          mime: Mime.CSV,
          filtersModel: this.reportService.model
        })
      )
      .pipe(
        finalize(() => {
          this.reportService.hideFilters();
        })
      )
      .subscribe(() => {
        this.reportService.hideFilters();
        this.successNoticeVisible = true;
      });
  }

  private watchRouteParams() {
    this.subSink.sink = this.activatedRoute.params.subscribe((params: PartnerSurgeryCountsReportParameters) => {
      this.type = params.type;

      this.setTitle();
      this.setReportType();
    });
  }

  private getApiUrl() {
    if (this.type === PartnerSurgeryCountsReportTypes.FOUNDATION_CONNECT) {
      return '/partner-surgery-counts/foundation-connect';
    }

    return '/partner-surgery-counts';
  }

  private enableNoticeAutoHide() {
    this.subSink.sink = this.reportService.filtersVisible$.pipe(filter(isVisible => isVisible)).subscribe(() => {
      this.successNoticeVisible = false;
    });
  }

  private setTitle() {
    if (this.type === PartnerSurgeryCountsReportTypes.FOUNDATION_CONNECT) {
      this.title = 'reports.partner_surgery_counts_foundation_connect';
    } else {
      this.title = 'reports.partner_surgery_counts';
    }
  }

  private setReportType() {
    if (this.type === PartnerSurgeryCountsReportTypes.FOUNDATION_CONNECT) {
      this.reportConfig.type = 'PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT';
    }
  }
}

@NgModule({
  declarations: [PartnerSurgeryCountsReportComponent],
  imports: [
    ReportFiltersModule,
    EmptyTableStateComponentModule,
    ReportHeaderComponentModule,
    GeneralCommonsModule,
    CommonModule,
    TranslateModule,
    NoticeComponentModule
  ],
  exports: [PartnerSurgeryCountsReportComponent]
})
export class PartnerSurgeryCountsReportComponentModule {}
