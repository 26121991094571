import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { ExpandableRowTableModule } from '@shared/components/expandable-row-table/expandable-row-table.module';
import { FormModule } from '@shared/components/form/form.module';
import { ModalModule } from '@shared/components/modal/modal.module';
import { DetailsCommonsModule } from '@shared/modules/details-commons/details-commons.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { PermissionModule } from 'src/app/core/permissions/permission.module';
import { TableModule } from 'src/app/shared/components/table/table.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { SearchModule } from '../search/search.module';
import { PractitionerCreateNoteModalComponent } from './components/modals/practitioner-create-note-modal/practitioner-create-note-modal.component';
import { PractitionerDetailsPageComponent } from './components/practitioner-details-page/practitioner-details-page.component';
import { PractitionerSearchPageComponent } from './components/practitioner-search-page/practitioner-search-page.component';
import { PractitionerSearchComponent } from './components/practitioner-search/practitioner-search.component';
import { OverrideQaStageModalComponent } from './components/qa-results/override-qa-stage-modal/override-qa-stage-modal.component';
import { QaResultsPageComponent } from './components/qa-results/qa-results-page.component';
import { PractitionerRoutingModule } from './practitioner-routing.module';

@NgModule({
  declarations: [
    OverrideQaStageModalComponent,
    PractitionerDetailsPageComponent,
    PractitionerCreateNoteModalComponent,
    PractitionerSearchPageComponent,
    PractitionerSearchComponent,
    QaResultsPageComponent
  ],
  imports: [
    CommonModule,
    DetailsCommonsModule,
    EmptyTableStateComponentModule,
    ExpandableRowTableModule,
    FlexLayoutModule,
    FormModule,
    GeneralCommonsModule,
    MaterialModule,
    ModalModule,
    PermissionModule,
    PractitionerRoutingModule,
    ReactiveFormsModule,
    SearchModule,
    TableModule,
    TranslateModule.forChild()
  ]
})
export class PractitionerModule {}
