import { Item } from '@/src/app/shared/models/item.model';
import { transition, trigger, useAnimation } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { slideDown, slideUp } from '@core/animations';
import { TIMINGS } from '@core/animations/constant.animations';

@Component({
  selector: 'stx-chips[ariaLabel][itemsList]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  animations: [
    trigger('chip', [
      transition(
        'void => *',
        useAnimation(slideDown, {
          params: {
            timing: TIMINGS.VERY_FAST
          }
        })
      ),
      transition(
        '* => void',
        useAnimation(slideUp, {
          params: {
            timing: TIMINGS.VERY_FAST
          }
        })
      )
    ])
  ]
})
export class ChipsComponent {
  @Input() itemsList: Item[];
  @Input() ariaLabel: string;

  @Input() removable = false;
  @Input() selectable = false;
  @Input() ellipsis = false;

  @Output() removeCallback: EventEmitter<Item> = new EventEmitter<Item>();
  @Output() selectCallback: EventEmitter<Item> = new EventEmitter<Item>();

  onRemove(item: Item) {
    this.removeCallback.emit(item);
  }

  onSelect(item: Item) {
    this.selectCallback.emit(item);
  }
}

@NgModule({
  declarations: [ChipsComponent],
  imports: [CommonModule, MatChipsModule, MatIconModule],
  exports: [ChipsComponent]
})
export class ChipsComponentModule {}
