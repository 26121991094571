<div *ngFor="let row of rows" class="form-row">
  <div *ngFor="let photo of row" class="form-column">
    <stx-image
      *ngIf="photo !== null"
      [label]="photo.label"
      [formMode]="formMode"
      [parentOrderName]="photo.parentOrderName"
      [controlName]="photo.parentOrderName.toString()"
      [formGroup]="formGroup"
      [isRequiredAlways]="photo.isRequired"
      [print]="print"
    ></stx-image>
  </div>
</div>
