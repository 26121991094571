import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ControlValueAccessor, UntypedFormGroup, NgControl, ReactiveFormsModule } from '@angular/forms';
import { ChangeContext, NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { SubSink } from 'subsink';

import { RangeSliderModel } from './range-slider.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements ControlValueAccessor, AfterContentInit, OnInit, OnDestroy {
  @Input() ariaLabelledBy: string;
  @Input() controlName: string;
  @Input() formGroupReference: UntypedFormGroup;
  @Input() minValue? = 0;
  @Input() maxValue? = 100;
  @Input() stepSize? = 1;
  @Input() valueFrom = 0;
  @Input() valueTo = 0;

  sliderOptions: Options = {
    animate: true,

    // This values will be updated in onInit hook
    ariaLabelledBy: '',
    ceil: this.maxValue,
    floor: this.minValue,
    step: 1,

    enforceRange: true,
    hideLimitLabels: true,
    keyboardSupport: true
  };

  onChangeCallback: () => void;
  onTouchedCallback: () => void;
  removeSliderPointerListener: () => void;

  private subSink = new SubSink();

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.updateSliderOptions({
      ariaLabelledBy: this.ariaLabelledBy,
      ceil: this.maxValue,
      floor: this.minValue,
      step: this.stepSize
    });
  }

  onChange(change: ChangeContext) {
    this.ngControl.control.setValue({
      from: change.value,
      to: change.highValue
    });
  }

  ngAfterContentInit() {
    // Ngx slider does not expose it's interal components nor any @Outputs that can
    // inform consumer component that user has focused/blured element,
    // which is required to correctly implement ControlValueAccesor.
    // This is why listener is handled manually

    const sliderPointer = this.elementRef.nativeElement.querySelector('.ngx-slider-pointer');

    this.removeSliderPointerListener = this.renderer.listen(sliderPointer, 'blur', () => {
      this.onTouchedCallback();
    });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
    if (this.removeSliderPointerListener) {
      this.removeSliderPointerListener();
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.updateSliderOptions({
      disabled: isDisabled
    });
    this.changeDetectorRef.detectChanges();
  }

  registerOnChange(onChangeCallback: any) {
    this.onChangeCallback = onChangeCallback;
  }

  registerOnTouched(onTouchedCallback: () => void) {
    this.onTouchedCallback = onTouchedCallback;
  }

  writeValue(range: RangeSliderModel) {
    if (range) {
      this.valueFrom = range.from;
      this.valueTo = range.to;
      this.changeDetectorRef.detectChanges();
    }
  }

  private updateSliderOptions(options: Partial<Options>) {
    this.sliderOptions = Object.assign({}, this.sliderOptions, options);
    this.changeDetectorRef.detectChanges();
  }
}

@NgModule({
  imports: [FlexLayoutModule, NgxSliderModule, ReactiveFormsModule],
  declarations: [RangeSliderComponent],
  exports: [RangeSliderComponent]
})
export class RangeSliderComponentModule {}
