export const syllableRepetitionTexts = new Map()
  .set(
    'English',
    'Pa pa pa, pi pi pi\n' +
      'Ba ba ba, bi bi bi\n' +
      'Ta ta ta, ti ti ti\n' +
      'Da da da, di di di\n' +
      'Ka ka ka, ki ki ki\n' +
      'Ga ga ga, gi gi gi\n' +
      'Sa sa sa, si si si\n' +
      'Ma ma ma, mi mi mi '
  )
  .set(
    'Español',
    'Pah Peh Pee, Pah, Pah Peh Pee\n' +
      'Bah Beh Bee, Bah Beh Bee\n' +
      'Tah Teh Tee, Tah Teh Tee\n' +
      'Dah Deh Dee, Dah Deh Dee\n' +
      'Kah Keh Kee, Kah Keh Kee\n' +
      'Gah Geh Gee, Gah Geh Gee\n' +
      'Sah Seh See, Sah Seh See\n' +
      'Fah Feh Fee, Fah Feh Fee'
  )
  .set(
    'Bahasa Indonesia',
    'Ba ba ba bi bi bi\n' +
      'Da da da di di di\n' +
      'Ga ga ga gi gi gi\n' +
      'Pa pa pa pi pi pi\n' +
      'Ta ta ta ti ti ti\n' +
      'Ka ka ka ki ki ki\n' +
      'Ma ma ma mi mi mi'
  )
  .set(
    'Português',
    'Pá Pé Pi, Pá Pé Pi\n' +
      'Bá Bé Bi, Bá Bé Bi\n' +
      'Tá Té Ti, Tá Té Ti\n' +
      'Dá Dé, Di, Dá Dé, Di\n' +
      'Ká Ké Ki, Ká Ké Ki\n' +
      'Gá Gué Gui, Gá Gué Gui\n' +
      'Sá Sé Si, Sá Sé Si\n' +
      'Fá Fé Fi, Fá Fé Fi'
  )
  .set(
    'ภาษาไทย',
    '(ป) Pah Pah Peh,  Pah Pah Peh\n' +
      '(พ) Phah Pheh Phee, Phah Pheh Phee\n' +
      '(บ) Bah Beh Bee, Bah Beh Bee\n' +
      '(ท/ถ) Thah Theh Thee, Thah Theh Thee\n' +
      '(ต) Tah Teh Tee, Tah Teh Tee \n' +
      '(ด) Dah Deh Dee, Dah Deh Dee \n' +
      '(จ) Cah Ceh Cee, Cah Ceh Cee \n' +
      '(ก) Kah Keh Kee, Kah Keh Kee \n' +
      '(ข/ค) Khah Kheh Khee, Khah Kheh Khee\n' +
      '(ส) Sah Seh See, Sah Seh See\n' +
      '(ฟ) Fah Feh Fee, Fah Feh Fee\n' +
      '(ช) Chah Cheh Chee, Chah Cheh Chee'
  );
export const sentenceRepetitionTexts = new Map()
  .set(
    'English',
    'Puppy will pull a rope\n' +
      'Buy baby a bib\n' +
      'Your turtle ate a hat\n' +
      'Do it today for dad\n' +
      'A cookie or a cake\n' +
      'Give Aggie a hug\n' +
      'Sissy saw Sally race\n' +
      'Zoey has roses\n' +
      'She washed a dish\n' +
      'Watch a choo choo '
  )
  .set(
    'Español',
    'El pollo Pepe\n' +
      'Veo el búho\n' +
      'La tía alta\n' +
      'Dale el dado\n' +
      'El coco cae\n' +
      'Guía el gallo\n' +
      'Seis osos\n' +
      'Echa el chile\n' +
      'Mi mamá\n' +
      '\n' +
      'Source: Cordero et al., 2022 '
  )
  .set(
    'Français',
    'Papa pèle la poire à Paris\n' +
      'Le bébé boit le lait\n' +
      'La tortue tire Toto\n' +
      'Le dos dodu et les dix doigts\n' +
      'Le coq crie cocorico\n' +
      'Le gros gorille et la guêpe goulue\n' +
      'Solal et Cécile sont assis ici\n' +
      'Zoé et Rose lisent au zoo\n' +
      'Le chat lèche le lait chaud\n' +
      'Ma Maman aime un homme '
  )
  .set(
    'हिन्दी',
    '/pappu papi:ta pakɨɖo/\n' +
      '/ba:har bahut ba:riʃ huji:/\n' +
      '/ta:ra: ta:la: to: ɖo:/\n' +
      '/da:di: du:d de:do:/\n' +
      '/ʈikku: ka: ʈi:ʋi: ʈu:ʈa:/\n' +
      '/ɖakiya: ɖa:k ɖa:lo/\n' +
      '/ko:jal ne kja: ga:ja:/\n' +
      '/go:lu: gi:t ga:o/\n' +
      '/si:ta sa:t paise do/\n' +
      '/ʧa:ʧa: ʧa:ʧi ʧalije:/\n' +
      '/ma:ma: ma:mi a:m la:je/\n' +
      '/na:ni ne: nadi: bana:ji/ '
  )
  .set(
    'Bahasa Indonesia',
    'Pipi punya payung\n' +
      'Bobi bawa bola\n' +
      'Topi tuti tua\n' +
      'Dadu dodi dua\n' +
      'Susi sayang sasa\n' +
      'Cici coba cuci cabe\n' +
      'Jeni jual jamu\n' +
      'Kuku kaki kiki luka\n' +
      'Gigi gajah gede-gede'
  )
  .set(
    'ಕನ್ನಡ',
    '/pa:pu pa:ʈa: paʈisida/\n' +
      '/kerayalli kappɨ kallu ittu/\n' +
      '/ba:lu ba:viya bali bittu/\n' +
      '/giɖadolage gili ittu/\n' +
      '/ta:ra tabhala: taʈʈidaɭu/\n' +
      '/su:si samudra: suttidaɭu/\n' +
      '/ʧikka ʧa:ku ʧu:pittu/\n' +
      '/la:lu la:li ha:ɖida:/\n' +
      '/va:su obbh:a vakki:la/\n' +
      '/hattu hasu hoguttide:/\n' +
      '/la:riya oɭage re:ɖio ittu/\n' +
      '/na:nu: ninne sinima: no:ɖide/ '
  )
  .set(
    'മലയാളം',
    '/pa:ʋa pappaɖam poɖiʧʧu/\n' +
      '/to:ppil ti: katti/\n' +
      '/ʈi:ʧər poʈʈɨ toʈʈu/\n' +
      '/kuʈʈi pa:kkɨ koɖɨttu/\n' +
      '/kakkaijuɖe kalar karappɨ/\n' +
      '/si:taijuɖe kasaʋɨ sa:ri/\n' +
      '/li:la ka:lɨ poɭɭiʧʧu/\n' +
      '/a:ja: pa:ja ʋiriʧʧu/\n' +
      '/ma:manɖe mukham ma:ri/\n' +
      '/na:lɨ na:ya vannu/ '
  )
  .set(
    'मराठी',
    '/pappu papai kap/\n' +
      '/tai ʃeteʈi dzate/\n' +
      '/ʈillu ta:ʈ va:ti de/\n' +
      '/pakat kairi ʈak/\n' +
      '/sasa kasav bastat/\n' +
      '/ʃaʃi a:ka:ʃ ba:gh/\n' +
      '/valavar rava lav/\n' +
      '/ravivari vaĵar lavli/'
  )
  .set(
    'Português',
    'Papai pediu pipoca\n' +
      'O tatu saiu da toca\n' +
      'O caqui caiu\n' +
      'A foto ficou feia\n' +
      'O saci saiu cedo\n' +
      'Chico chupa chupeta\n' +
      'Mamãe comeu mamão\n' +
      '\n' +
      'Source: Di Ninno CQ et al., 2009 '
  )
  .set(
    'தமிழ்',
    '/pa:pa: pa:ʈʈɨ pa:ɖɨ/\n' +
      '/li:la: pa:l kuɖitta:ɭ/\n' +
      '/ʈi:na: ʈəmblarai po:ʈʈa:ɭ/\n' +
      '/ka:mini pa:kkɨ koɖɨ/\n' +
      '/sa:mi ka:sɨ se:rta:n/\n' +
      '/rangan rajilil je:rina:n/\n' +
      '/ʋa:ɳi taʋaɭai ʋarainda:ɭ/\n' +
      '/ja:r ʋaɭajal ʋa:ŋgijadɨ/\n' +
      '/ma:man magaɭ mi:na/\n' +
      '/na:n ne:ttɨ naɖande:n/ '
  )
  .set(
    'తెలుగు',
    '/pa:pa:ki pa:lu iʋʋu/\n' +
      '/i: ba:bu pe:ru ba:bi/\n' +
      '/ta:ta: pai te:ɭu/\n' +
      '/ɖabbalo: du:di le:du/\n' +
      '/ʈi:ʋi:lo: pa:ʈalu ʧu:ɖu/\n' +
      '/ʋa:ɖi ɖo:lu kottadi/\n' +
      '/lata:ki ku:ra ka:ʋa:li/\n' +
      '/raʋi guɖiki pagalu ʋeɭɭa:ɖu/\n' +
      '/ʧa:ru ruʧi ʧsa:ru/\n' +
      '/sa:yi si:sa: te/\n' +
      '/ʃaʃi ʃiʋa u:riki ʋeɭɭa:ru/\n' +
      '/riʃi ʃiriɖi ʋeɭɭa:ɖu/\n' +
      '/u:ha hariki ʧellelu/\n' +
      '/me:mu sinima:ki veɭɭa:mu/\n' +
      '/ne:nu na:nini nammanu/ '
  )
  .set(
    'ภาษาไทย',
    'ปุ๋ยปาปิ๊บ\n' +
      'พี่พับผ้า , พ่อพบผี\n' +
      'บ็อบบีบบวบบู้บี้\n' +
      'ทิศถูถาดถั่ว\n' +
      'ตาตี๋ตัดตอ\n' +
      'ดุจดาวดีดด้าย\n' +
      'จิ๋วจัดจุดจอด\n' +
      'กุ๊กไก่กกไข่\n' +
      'แขกคิ้วเขียวคุกเข่า, ควายคึกคักเข้าคอก\n' +
      'สิทธิ์ซื้อโซ่สี่สาย, สุดสวยใส่เสื้อสีแสด\n' +
      'แฝดเฝ้าฝ้าย\n' +
      'โชติชี้ชุด'
  )
  .set(
    'Tiếng Việt',
    'Búp bê bằng bông biết bay\n' +
      'Tí ta tí tách\n' +
      'Đi đường gặp đèn đỏ\n' +
      'Mùa xuân xơi xúc xích\n' +
      'Cá rô bỏ rổ kêu rồ rồ\n' +
      'Kẻo cà kẻo kẹt\n' +
      'Gấm gầm gừ, chó gâu gâu\n' +
      'Sát sàn sạt '
  )
  .set(
    'Tagalog',
    'Ampalaya ang ulam ni Pilar\n' +
      'Ang lobo ay binili ng lolo\n' +
      'Tinuturuan ng nanay ang ate\n' +
      'Doon ang daanan\n' +
      'Kukuha na kahoy ang lalake\n' +
      'Magaling ang ginawa ng nanay\n' +
      'Sasayaw si Susi\n' +
      'Siya ay namasyal\n' +
      'May chicharon at tsinelas ang nanay\n' +
      'Mamaya mamimili ang mama '
  )
  .set(
    'مصري',
    'Baba beylab bil balona / بابا بيلعب بالبالونة\n' +
      'Talata we talateen / تلاتة وتلاتين\n' +
      'Didi ma3aha warda / ديدي معاها وردة\n' +
      'Kamal akal keka / كمال اكل كيكة\n' +
      'Goha ma3aa agala / جحا معاه عجلة\n' +
      'Susu w’ simsim / سوسو و سمسم\n' +
      'Zaman zein Zarar el zuraar / زمان زين ذرر الزرار\n' +
      'Shushu shaila el sham3a / شوشو شايلة شمعة'
  )
  .set(
    'বাংলা',
    'Paan pelam / পান পেলাম \n' +
      'Boro bala / বড় বালা\n' +
      'Bhalo bhela / ভাল ভেলা\n' +
      'Atta tolai / তলায় আটা\n' +
      'Daal delo / ডাল দিল\n' +
      'Dhol dhal / ঢোল ঢাল\n' +
      'Kukurer kamor / কুকুরের কামড়\n' +
      'Khela khele / খেলা খেলি\n' +
      'Gaan gao / গান গাও\n' +
      'Shosha shoyna / শসা সয়না\n' +
      'Chaal chai / চাল চাই'
  )
  .set(
    '中文',
    'Pópo tán pípa / 婆婆 彈 琵琶\n' +
      'Bàba bào bǎobǎo / 爸爸 抱 寶寶\n' +
      'Tiāntiān tàntǎo tiánpǐn / 天天 探討 甜品\n' +
      'Dìdi dédào dàjiǎng / 弟弟 得到 ⼤獎\n' +
      'Sìshísān zhī sōngshǔ / 四⼗三 隻 松⿏\n' +
      'Lǎoshī shuō gùshì / ⽼師 說 故事\n' +
      'Hēchá, chī chǎofàn / 喝茶 ， 吃 炒飯 '
  )
  .set(
    'Chichewa',
    'Paulo wapala pamwamba\n' +
      'Ababa abwera kubawo\n' +
      'Tapita tulo titatha tonse\n' +
      'Dona la kudowa ladusa\n' +
      'Kalulu wakomoka katatu konse\n' +
      'Galimoto yagunda galu\n' +
      'Somba ya Sala yasowa\n' +
      'Zama zina zamela zones\n' +
      'Chichewa cha achewa ndichokoma\n' +
      'Mawa mamawa kuli mame\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Igbo',
    'Papa pipuru apa\n' +
      'Ebe bere na abali\n' +
      'Aturu tetere tata\n' +
      'Daalu onye ode ndi nmadu\n' +
      'Ga guo agwa onu\n' +
      'Osa suru ose\n' +
      'Azu nmiri ozuzo\n' +
      'Isha shiri ashi\n' +
      'Chinelo chere were oche\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Kikamba',
    'Koki koka wone\n' +
      'Tata atila muti\n' +
      'Sila soma saa\n' +
      'Vava vaa vena ivui\n' +
      'Choa chalika chooni\n' +
      '\n' +
      'Source: LeadersProject '
  )
  .set(
    'Kikuyu',
    'Tata witū twī twana\n' +
      'Kahīī karia karaihu\n' +
      'Chai wa chacha\n' +
      'Karūkū komu\n' +
      'Tūria nī twīru\n' +
      'Kiūra kīrīa nī kīnene\n' +
      'Chora chati na chiru\n' +
      'Nyoni īrīa nī nene\n' +
      'Mami mere mehere\n' +
      'Ng’ang’a nī mūng’aru\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Kinyarwanda',
    'Papa ampaye ipera\n' +
      'Abana babiri bari hariya\n' +
      'Teta atuye tari\n' +
      'Dodo ya Didi n’indende\n' +
      'Akana kari kure\n' +
      'Gogo wa Gloria\n' +
      'Sosiso isa n’isaha\n' +
      'Zana izo nzu\n' +
      'Shushanya ishusho y’ishu\n' +
      'Hano na none\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Kisii',
    'Baba beria amabere\n' +
      'Tura tata totare\n' +
      'Ondora orire endoru\n' +
      'Kira kiri okore ekira\n' +
      'Genda ogore egunia\n' +
      'Susana seria esese\n' +
      'Chacha charara egechararo\n' +
      'Menda omoire emondo\n' +
      'Moraa mina omwana\n' +
      'Mama nomuya\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Kiswahili',
    'Pale ni papana\n' +
      'Wabebe babu na bibi\n' +
      'Toa tena na tena\n' +
      'Mdonoe dada Dina\n' +
      'Kale kakuku ka kaka\n' +
      'Gonga lile gogo\n' +
      'Sisi ni salama salimini\n' +
      'Lile zeze zuri\n' +
      'Shule yao shani\n' +
      'Mimi na mama\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Luganda\n',
    'Pawulo yampa epaapaali\n' +
      'Bomba ya baaba\n' +
      'Ttuula tuwaye\n' +
      'Dan yaddayo dda\n' +
      'Ka kaawa kawoomu\n' +
      'Gawuni yange n’eggaali yange\n' +
      'Ssali asula mumaaso awo\n' +
      'Zona yazizuula\n' +
      'Maama alina emaali\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Luyha',
    'Papa wa paulo\n' +
      'Omunyapara ali waena Palapa\n' +
      'Liembe lili mwisamba\n' +
      'Bhabhaana abho bhalia bhmlaayi\n' +
      'Watomola litere mwitoma\n' +
      'Tamara alile etwaya\n' +
      'Liisa lili mwisisi\n' +
      'Lelo sisiilima sikali wase\n' +
      'Lekha khuluma olakhala\n' +
      'Khakhaana khalayi khakwiile\n' +
      'Yiicha munchu omywe echai\n' +
      'Miicha chinyenyi chinoo\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Malagasy',
    'Mihinana papay i Paoly\n' +
      'Be Bibilava sy Babakoto eto\n' +
      'Lavitra ny tranon’i Trema\n' +
      'Mitete ny tafo\n' +
      'Davida no anaran’ny dadan’i Domoina\n' +
      'Mikosoka tsikoko i Kotokely\n' +
      'Mangataka manga izy\n' +
      'Misasa i Soa\n' +
      'Aiza ny zaza ?\n' +
      'Mamy ny manga\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Ndebele',
    'Phanga phela lapho\n' +
      'Bhala imbali bhango\n' +
      'Thatha uthule thuba\n' +
      'Mdube udubula amadada edamu\n' +
      'Khanka likhala khathesi\n' +
      'Ugogo ugiga ngomgigo\n' +
      'Sabela usisi silo\n' +
      'Zuza ngizuze lizwe\n' +
      'Tsheba isitshwala ngetshukela akula sitshebo\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Nyanja',
    'Peleka poto panja\n' +
      'Baja bathu bayenda\n' +
      'Tiye titege tomato\n' +
      'Davy doba mbale ku damu\n' +
      'Kasote kako kalikuti\n' +
      'Sam sunga saka\n' +
      'Bwelesa bola baby\n' +
      'Manzi yali munyumba\n' +
      'Robert Mugabe anali ma Rhodesia\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Runyankole',
    'Pawulo yarya eipapari\n' +
      'Abaana babara\n' +
      'Taata yateera omwana\n' +
      'Dora yaronda dodo\n' +
      'Ekyimuri kyikuktire\n' +
      'Guroriya arwire omugongo\n' +
      'Sara aresire ensenene\n' +
      'Zayoni areinzire ameizi\n' +
      'Sheila yashara enshaho\n' +
      'Oriya in maama wa Mariya\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Shona',
    'Pano pane pepa\n' +
      'Baba vane mabara\n' +
      'Tisu tine twunhu twacho\n' +
      'Danai dira dovi muduri\n' +
      'Kanyama kehuku kanoaka\n' +
      'Gore guru muna Gunyana\n' +
      'Simba sara pasi\n' +
      'Mazana nemazana evana vaZuma\n' +
      'Sisi Samu musasiye sahwira wenyu\n' +
      'Chingwa chinonaka\n' +
      'Chitoro chava Choto chine chingwa chete\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Twi',
    'Papa pɜ paano\n' +
      'Barima no bie buo\n' +
      'Tete Tawiah atareɜ\n' +
      'Serwa suro saman\n' +
      'Nhyira hyɜ\n' +
      'Kyin kyɜ\n' +
      'Ggyae gye\n' +
      'Fri afuo no mu ma me\n' +
      '\n' +
      'Source: LeadersProject'
  )
  .set(
    'Yoruba',
    'Pípé pupa wá\n' +
      'Bàbá Bíbí bèbè\n' +
      'Tìre atí ti wa\n' +
      'Dòdò dúdú dé\n' +
      'Kèké Kola kákò\n' +
      'Igi gíga wo ilé gíga\n' +
      'Sisí mi sa wá\n' +
      'Fùfú funfun félé '
  );
