<fieldset>
  <div class="form-element-group-row flex-row-container flex-wrap align-left layout-align-baseline">
    <legend
      *ngIf="legendTranslationNode"
      class="form-element-group-label"
      [ngClass]="layout.padding"
      [class.force-vt-spacing]="useLayout === 'rows'"
      [class.force-hz-spacing]="useLayout === 'shrink'"
    >
      {{ legendTranslationNode | translate }}
    </legend>
    <ng-container *ngFor="let option of options">
      <stx-checkbox
        [style.flex]="layout.flex"
        class="display-block"
        [ngClass]="layout.padding"
        [formGroup]="formGroupReference"
        [controlName]="option.name"
      >
        <span class="text-capitalize">
          {{ option.label | translate }}
        </span>
      </stx-checkbox>
    </ng-container>
  </div>
</fieldset>
