import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureToggleService } from '@core/feature-toggle/feature-toggle.service';
import { FeatureEnum } from '@core/feature-toggle/feature.enum';

@Directive({
  selector: '[stxFeatureToggle]'
})
export class FeatureToggleDirective {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private featureService: FeatureToggleService
  ) {}

  @Input() set stxFeatureToggle(feature: FeatureEnum) {
    this.featureService.isEnabled(feature).then(isEnabled => {
      if (isEnabled && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (!isEnabled && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }
}
