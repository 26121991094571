<p *ngIf="documentType === DocumentType.DECLARATION_FORM" class="form-element-label">
  {{ 'patient.' + documentType + '_form_upload' | translate }}
</p>

<div [class.form-columns-1-1]="documentType === DocumentType.DECLARATION_FORM">
  <div>
    <p *ngIf="newFileInfo">
      <stx-file-icon></stx-file-icon>
      {{ newFileInfo.fileName }} [100%]
      <stx-remove-icon (click)="newFileInfo = null"></stx-remove-icon>
    </p>
    <stx-uppy
      class="m-r-30 m-b-24"
      [width]="documentType !== DocumentType.DECLARATION_FORM ? 300 : 600"
      [types]="releaseFormAcceptedTypes"
      (uploadSuccessful)="newFileInfo = $event"
      [disabled]="disabled"
    ></stx-uppy>
  </div>
  <div>
    <div class="m-b-15">
      <div class="flex-row-container m-b-5">
        <mat-icon class="sign-step-icon" svgIcon="download"></mat-icon> <span class="p-l-10 p-r-10">+</span>
        <mat-icon class="sign-step-icon" svgIcon="printer"></mat-icon> <span class="p-l-10 p-r-10">+</span>
        <mat-icon class="sign-step-icon" svgIcon="contract"></mat-icon>
      </div>
    </div>
    <stx-document-language-select
      [formLanguages]="documentType === DocumentType.DECLARATION_FORM ? indiaDeclarationFormLanguages : formLanguages"
      (languageSelected)="downloadReleaseFormTemplate($event)"
      class="display-block m-b-20"
    ></stx-document-language-select>
    <stx-notice *ngIf="documentType === DocumentType.DECLARATION_FORM">
      {{ 'patient.' + documentType + '_form.general_info' | translate }}
    </stx-notice>
  </div>
</div>
