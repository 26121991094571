<div class="form-header">
  <h1>
    <ng-container [ngSwitch]="formType">
      <ng-template [ngSwitchCase]="FormType.PATIENT">
        {{ 'patient.patient' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.SURGERY_FOLLOW_UP">
        {{ 'surgery_follow_up.follow_up' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.SURGICAL_INTERVENTION">
        {{ 'treatment.surgical_intervention' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.SPEECH_ASSESSMENT">
        {{ 'speech_assessment.registration' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.BORESCOPE">
        {{ 'borescope.borescope' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHOGNATHIC_TREATMENT">
        {{ 'treatment.orthognathic_treatment' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.SPEECH_TREATMENT">
        {{ 'speech_treatment.registration' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.NUTRITION_SCREENING_HISTORICAL">
        {{ 'treatment.nutrition_screening' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.NUTRITION_ASSESSMENT">
        {{ 'treatment.nutrition_management.assessment' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.NUTRITION_FOLLOW_UP">
        {{ 'treatment.nutrition_management.follow_up' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.NUTRITION_FOLLOW_UP_HISTORICAL">
        {{ 'treatment.nutrition_follow_up' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_PSIO_ASSESSMENT">
        {{ 'presurgical.assessment.registration' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_PSIO_TREATMENT">
        {{ 'presurgical.treatment.registration' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_MD_ASSESSMENT">
        {{ 'treatment.ortho_md_assessment' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_PD_ASSESSMENT">
        {{ 'treatment.ortho_pd_assessment' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_MD_TREATMENT">
        {{ 'treatment.ortho_md_treatment' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_PD_TREATMENT">
        {{ 'treatment.ortho_pd_treatment' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_PD_MID_ASSESSMENT">
        {{ 'treatment.ortho_pd_mid_assessment' | translate }}
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_MD_MID_ASSESSMENT">
        {{ 'treatment.ortho_md_mid_assessment' | translate }}
      </ng-template>
    </ng-container>
    <ng-content select="[slot=additionalText]"></ng-content>
  </h1>
  <div class="form-header__arrow-icon">
    <ng-content select="mat-icon"></ng-content>
  </div>
</div>
