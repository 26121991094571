import { Component, Input } from '@angular/core';
import { FormType } from '../../../enums/form-type.enum';
import { FormStatus } from '@shared/enums/form-status.enum';
import { mapToStatusEnum } from '@utils/treatment.utils';

@Component({
  selector: 'stx-expandable-form-wrapper',
  templateUrl: './expandable-form-wrapper.component.html',
  styleUrls: ['./expandable-form-wrapper.component.scss']
})
export class ExpandableFormWrapperComponent {
  @Input() formType: FormType;
  @Input() print: boolean;
  showArrowUp = true;
  private _status;
  @Input() showStatus: boolean = true;
  get status() {
    return this._status;
  }
  @Input() set status(value) {
    this._status = this.getPatientTreatmentStatus(value);
  }
  private getPatientTreatmentStatus(status: number): FormStatus {
    return mapToStatusEnum(status);
  }
}
