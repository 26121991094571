import { Component, Input } from '@angular/core';
import { TreatmentCenterDetails } from '../../models/treatment-center-details.model';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { TreatmentCenterService } from '@src/app/features/treatment-center/treatment-center.service';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { ListElement } from '@shared/modules/details-commons/components/details-value-list/details-value-list.component';
import { PartnerDetailsModel } from '@src/app/features/partner/models/partner-details.model';
import {
  DataTableDataProvider,
  GenericDataTableDataProvider
} from '@shared/modules/general-commons/components/data-table/data-table-data-provider';
import { buildDetailsList, buildLatestSubmissionsList } from '@src/app/features/partner/org-tree.utils';
import { map } from 'rxjs/operators';
import { DataTableService } from '@shared/modules/general-commons/components/data-table/data-table.service';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';

@Component({
  selector: 'stx-treatment-center-details',
  templateUrl: './treatment-center-details.component.html'
})
export class TreatmentCenterDetailsComponent extends BaseComponent {
  readonly permissionEnum = PermissionEnum;
  private _treatmentCenterId: number;
  private _details: TreatmentCenterDetails;
  readonly practitionersTableDataProvider: DataTableDataProvider<any>;
  readonly usersTableDataProvider: DataTableDataProvider<any>;

  constructor(
    private readonly treatmentCenterService: TreatmentCenterService,
    private readonly dataTableService: DataTableService,
    private readonly wsHelperService: WsHelperService,
    private globalErrorHandlerService: GlobalErrorHandlerService
  ) {
    super();
    this.practitionersTableDataProvider = new GenericDataTableDataProvider(
      {
        loadFunction: pageSort =>
          wsHelperService.callWithSpinner(treatmentCenterService.getTreatmentCenterPractitioners(this._treatmentCenterId, pageSort)),
        noResultsMessageKey: 'treatment_center.no_practitioners'
      },
      wsHelperService,
      globalErrorHandlerService
    );
    this.usersTableDataProvider = new GenericDataTableDataProvider(
      {
        loadFunction: pageSort =>
          wsHelperService
            .callWithSpinner(this.treatmentCenterService.getTreatmentCenterUsers(this._treatmentCenterId, pageSort))
            .pipe(map(this.dataTableService.userDataTableTransformation)),
        noResultsMessageKey: 'treatment_center.no_users'
      },
      wsHelperService,
      globalErrorHandlerService
    );
  }

  @Input()
  set treatmentCenterId(id: number) {
    if (this._treatmentCenterId !== id) {
      this._treatmentCenterId = id;
      this.loadDetails(id);
      this.practitionersTableDataProvider.reload();
      this.usersTableDataProvider.reload();
    }
  }

  private loadDetails(id: number) {
    this.subSink.sink = this.wsHelperService
      .callWithSpinner(this.treatmentCenterService.getTreatmentCenterDetails(id), { redirectOn404StatusCode: true })
      .subscribe(details => {
        this._details = details;
      });
  }

  get details(): TreatmentCenterDetails {
    return this._details;
  }

  get partnerDetails(): PartnerDetailsModel {
    return this._details.partnerDetails;
  }

  get latestSubmissions(): ListElement[] {
    return buildLatestSubmissionsList(this.details);
  }

  get programs(): ListElement[] {
    return buildDetailsList(this.details);
  }
}
