import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-multi-error-validator',
  templateUrl: './multi-error-validator.component.html',
  styleUrls: ['./multi-error-validator.component.scss']
})
export class MultiErrorValidatorComponent {
  @Input() violatedRules;
  get listOfViolatedRules(): string[] {
    if (this.violatedRules?.violatedRules) {
      return this.violatedRules.violatedRules.map(rule => rule.message);
    } else if (Array.isArray(this.violatedRules)) {
      return this.violatedRules;
    }

    return [];
  }
}
