import { fistulaFields, fistulaOptions } from '@/src/app/features/ortho/utils/dentition.utils';
import { OrthoFormType } from '@/src/app/features/ortho/utils/ortho-form-type';
import { OrthoStage } from '@/src/app/features/ortho/utils/ortho-stage';
import { TreatmentCenterService } from '@/src/app/features/treatment-center/treatment-center.service';
import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { PractitionerType } from '@/src/app/shared/enums/practitioner.enum';
import { Practitioner } from '@/src/app/shared/models/practitioner.model';
import { TreatmentCenterDictionary } from '@/src/app/shared/models/treatment-center.model';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { yesNoShort } from '@/src/app/utils/form.utils';
import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'stx-ortho-shared-general-info',
  templateUrl: './ortho-shared-general-info.component.html',
  styleUrls: ['./ortho-shared-general-info.component.scss']
})
export class OrthoSharedGeneralInfoComponent extends BaseFormElementComponent implements OnInit {
  readonly yesNo = yesNoShort;
  readonly fistulaFields = fistulaFields;
  readonly fistulaOptions = fistulaOptions;
  readonly OrthoFormType = OrthoFormType;
  static getOrthoCareProvidedDate(orthoStage: OrthoStage) {
    switch (orthoStage) {
      case OrthoStage.ASSESSMENT:
        return 'assessmentDate';
      case OrthoStage.MID_TREATMENT_ASSESSMENT:
        return 'evaluationDate';
      case OrthoStage.TREATMENT:
        return 'startDate';
    }
  }

  get startDate() {
    return this.formGroup.get(OrthoSharedGeneralInfoComponent.getOrthoCareProvidedDate(this.orthoStage)).value;
  }

  get numberOfSessionsLabel(): string {
    if (!this.shouldShowNumberOfSessions) {
      throw new Error('Unexpected state');
    }

    if (this.isMixedDentitionEndOfTreatment()) {
      return 'ortho.number_of_sessions.mixed_dentition';
    }

    if (this.isPermanentDentitionEndOfTreatment()) {
      return 'ortho.number_of_sessions.permanent_dentition';
    }
  }

  get shouldShowNumberOfSessions() {
    return this.isMixedDentitionEndOfTreatment() || this.isPermanentDentitionEndOfTreatment();
  }

  private isPermanentDentitionEndOfTreatment() {
    return this.orthoFormType === OrthoFormType.PERMANENT_DENTITION && this.orthoStage === OrthoStage.TREATMENT;
  }

  private isMixedDentitionEndOfTreatment() {
    return this.orthoFormType === OrthoFormType.MIXED_DENTITION && this.orthoStage === OrthoStage.TREATMENT;
  }

  static readonly careProvidedEndDate = 'endDate';
  static readonly treatmentCenterIdControlName = 'treatmentCenterId';
  static readonly numberOfSessionsControlName = 'numberOfSessions';
  static readonly practitionerIdControlName = 'practitionerId';
  static readonly surgeonIdControlName = 'surgeonId';
  static readonly fistulaControlName = 'fistula';
  readonly OrthoStage = OrthoStage;
  @Input() orthoFormType: OrthoFormType;
  @Input() tcList: TreatmentCenterDictionary[];
  @Input() orthoStage: OrthoStage;
  @Input() minCareProvidedDate: moment.Moment;
  @Input() maxCareProvidedDate: moment.Moment;
  @Input() minCareProvidedEndDateOverride: moment.Moment;
  @Input() treatmentStartDate: moment.Moment;

  practitionerList: Practitioner[] = [];
  surgeonList: Practitioner[] = [];

  constructor(
    private readonly treatmentCenterService: TreatmentCenterService,
    private readonly wsHelper: WsHelperService,
    @Optional() private readonly controlContainer: ControlContainer
  ) {
    super();
  }

  get oralHealthLabel() {
    if (this.orthoStage == OrthoStage.ASSESSMENT) return 'ortho.oral_health_start';
    return 'ortho.oral_health';
  }

  get treatmentCenterIdControlName() {
    return OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName;
  }

  get practitionerIdControlName() {
    return OrthoSharedGeneralInfoComponent.practitionerIdControlName;
  }

  get surgeonIdControlName() {
    return OrthoSharedGeneralInfoComponent.surgeonIdControlName;
  }

  get fistulaControlName() {
    return OrthoSharedGeneralInfoComponent.fistulaControlName;
  }

  get minCareProvidedEndDate() {
    return (
      this.minCareProvidedEndDateOverride ??
      this.formGroup.get(OrthoSharedGeneralInfoComponent.getOrthoCareProvidedDate(this.orthoStage)).value ??
      this.minCareProvidedDate
    );
  }

  get careProvidedDateControlName() {
    return OrthoSharedGeneralInfoComponent.getOrthoCareProvidedDate(this.orthoStage);
  }

  get careProvidedEndDateLabel() {
    return 'ortho.end_date';
  }

  get careProvidedEndDateControlName() {
    return OrthoSharedGeneralInfoComponent.careProvidedEndDate;
  }

  get careProvidedDateLabel() {
    switch (this.orthoStage) {
      case OrthoStage.ASSESSMENT:
        return 'ortho.service_start_date';
      case OrthoStage.MID_TREATMENT_ASSESSMENT:
        return 'patient.evaluation_date';
      case OrthoStage.TREATMENT:
        return 'ortho.start_date';
    }
  }
  get surgeonIdControl() {
    return this.formGroup.get(OrthoSharedGeneralInfoComponent.surgeonIdControlName);
  }

  get practitionerIdControl() {
    return this.formGroup.get(OrthoSharedGeneralInfoComponent.practitionerIdControlName);
  }
  get surgeonIdValue() {
    return this.surgeonIdControl.value;
  }

  get practitionerIdValue() {
    return this.practitionerIdControl.value;
  }
  get isSelectedPractitionerIdInvalid(): boolean {
    return (
      this.practitionerList.length !== 0 &&
      !!this.practitionerIdValue &&
      this.practitionerList.find(practitioner => practitioner.id === this.practitionerIdValue) == null
    );
  }

  get isSelectedSurgeonIdInvalid(): boolean {
    return (
      this.surgeonList.length !== 0 && !!this.surgeonIdValue && this.surgeonList.find(surgeon => surgeon.id === this.surgeonIdValue) == null
    );
  }

  ngOnInit(): void {
    if (!this.formGroup) {
      this.formGroup = this.controlContainer!.control as UntypedFormGroup;
    }
    this.subscribeToTcIdControlValueChanges();
    this.subscribeToFistulaControlChanges();
    this.loadAvailablePractitionersForTc(this.formGroup.get(this.treatmentCenterIdControlName).value);
  }

  /** To be performed before the form validation */
  public checkPractitionerAndSurgeonValidity() {
    this.resetPractitionerIdIfInvalid();
    if (this.orthoStage === OrthoStage.TREATMENT) {
      this.resetSurgeonIdIfInvalid();
    }
  }

  private subscribeToTcIdControlValueChanges() {
    this.subSink.sink = this.formGroup.get(OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName).valueChanges.subscribe(tcId => {
      this.loadAvailablePractitionersForTc(tcId);
    });
  }

  private loadAvailablePractitionersForTc(tcId: number | null) {
    if (tcId) {
      this.getPractitioners(tcId, PractitionerType.ORTHODONTIST);
      if (this.orthoStage === OrthoStage.TREATMENT) {
        this.getPractitioners(tcId, PractitionerType.SURGEON);
      }
    }
  }

  private getPractitioners(tcId: number, practitionerType: PractitionerType) {
    this.subSink.sink = this.wsHelper
      .callWithSpinner(this.treatmentCenterService.getPractitionersByTreatmentCenterAndType(tcId, practitionerType))
      .subscribe(practitioners => {
        switch (practitionerType) {
          case PractitionerType.ORTHODONTIST:
            this.practitionerList = practitioners || [];
            break;
          case PractitionerType.SURGEON:
            this.surgeonList = practitioners || [];
            break;
          default:
            throw new Error('Incorrect practitioner type');
        }
      });
  }

  private resetPractitionerIdIfInvalid(): void {
    if (this.isSelectedPractitionerIdInvalid) {
      this.formGroup.get(OrthoSharedGeneralInfoComponent.practitionerIdControlName).setValue(null);
    }
  }

  private resetSurgeonIdIfInvalid(): void {
    if (this.isSelectedSurgeonIdInvalid) {
      this.formGroup.get(OrthoSharedGeneralInfoComponent.surgeonIdControlName).setValue(null);
    }
  }

  toggleFistulaControlIfAnyFistulaOptionChosen() {
    this.formGroup.get(OrthoSharedGeneralInfoComponent.fistulaControlName).setValue(1);
  }

  private subscribeToFistulaControlChanges() {
    if (this.orthoFormType === OrthoFormType.PRESURGICAL_ORTHOPEDICS || this.orthoStage === OrthoStage.MID_TREATMENT_ASSESSMENT) {
      return;
    }
    this.subSink.sink = this.formGroup.get(OrthoSharedGeneralInfoComponent.fistulaControlName).valueChanges.subscribe(value => {
      if (value === 0) {
        this.deselectAndDisableFistulaOptions();
      } else {
        this.enableFistulaOptions();
      }
    });
  }
  private deselectAndDisableFistulaOptions() {
    fistulaOptions.forEach(option => {
      const fistulaControl = this.formGroup.get(option.name);
      fistulaControl.setValue(0);
      fistulaControl.disable();
    });
  }

  private enableFistulaOptions() {
    fistulaOptions.forEach(option => this.formGroup.get(option.name).enable());
  }
}
