<stx-resources-navigation selectedItemId="{{ NavigationSubTabEnum.RESOURCES_SPEECH }}"></stx-resources-navigation>
<stx-generic-wide-page>
  <stx-page-header [name]="'menu.resources.speech' | translate"></stx-page-header>
  <div class="layout-columns-1-1-1">
    <div>
      <div class="data-section">
        <stx-generic-resource-list [sections]="leftColumnResources"></stx-generic-resource-list>
      </div>
    </div>
    <hr class="vertical-separator" />
    <div>
      <stx-generic-resource-list [sections]="middleColumnResources"></stx-generic-resource-list>
    </div>
    <hr class="vertical-separator" />
    <div class="layout-columns-1-1">
      <div>
        <stx-generic-resource-list [sections]="rightColumnResources"></stx-generic-resource-list>
      </div>
    </div>
  </div>
</stx-generic-wide-page>
