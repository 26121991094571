<div class="page-content flex-column-container">
  <h1>{{ 'speech_instructions.title' | translate }}</h1>
  <div class="page-section m-b-40">
    <h2>{{ 'speech_instructions.assessment.title' | translate }}</h2>
    <div>{{ 'speech_instructions.assessment.general' | translate }}</div>
    <ul *ngFor="let point of oneToFiveArray">
      <li>{{ 'speech_instructions.assessment.general_' + point | translate }}</li>
    </ul>
    <div>{{ 'speech_instructions.assessment.general_comment' | translate }}</div>

    <h3>{{ 'speech_assessment.perceptual_assessment' | translate }}</h3>

    <h4>{{ 'speech_instructions.methods' | translate }}</h4>
    <div class="tooltip">{{ 'speech_assessment.tooltip.perceptual_assessment' | translate }}</div>

    <h4>{{ 'speech_instructions.assessment.speech_smaples' | translate }}</h4>
    <div class="bold">{{ 'speech_instructions.assessment.hypernasality' | translate }}</div>
    <ul *ngFor="let point of oneToFiveArray">
      <li>{{ 'speech_instructions.assessment.hypernasality_' + point | translate }}</li>
    </ul>

    <div class="bold">{{ 'speech_instructions.assessment.hyponasality' | translate }}</div>
    <ul *ngFor="let point of oneToFourArray">
      <li>{{ 'speech_instructions.assessment.hyponasality_' + point | translate }}</li>
    </ul>

    <div class="bold">{{ 'speech_instructions.assessment.cul_de_sac' | translate }}</div>
    <ul>
      <li>{{ 'speech_instructions.assessment.cul_de_sac_1' | translate }}</li>
    </ul>

    <div class="bold">{{ 'speech_instructions.assessment.articulation' | translate }}</div>
    <ul>
      <li>{{ 'speech_instructions.assessment.articulation_1' | translate }}</li>
    </ul>

    <h3>{{ 'speech_assessment.resonance' | translate }}</h3>
    <ul *ngFor="let point of oneToFourArray">
      <li class="bold">{{ 'speech_instructions.assessment.resonance_' + point | translate }}</li>
      <span>{{ 'speech_instructions.assessment.resonance_' + point + '_comment' | translate }}</span>
    </ul>

    <h3>{{ 'speech_instructions.assessment.nasal_emission.title' | translate }}</h3>
    <ul *ngFor="let point of oneToFiveArray">
      <li class="bold">{{ 'speech_instructions.assessment.nasal_emission_' + point | translate }}</li>
      <span>{{ 'speech_instructions.assessment.nasal_emission_' + point + '_comment' | translate }}</span>
    </ul>

    <h3>{{ 'speech_instructions.assessment.sound.title' | translate }}</h3>
    <h4>{{ 'speech_instructions.assessment.sound.obligatory_distortions' | translate }}</h4>
    <span>{{ 'speech_instructions.assessment.sound.obligatory_distortions_subtitle' | translate }}</span>
    <ul *ngFor="let point of oneToTwoArray">
      <li class="bold">{{ 'speech_instructions.assessment.sound.due_to_' + point | translate }}</li>
      <span>{{ 'speech_instructions.assessment.sound.due_to_' + point + '.obligatory_distortions' | translate }}</span>
    </ul>

    <h4>{{ 'speech_instructions.assessment.sound.compensatory' | translate }}</h4>
    <span>{{ 'speech_instructions.assessment.sound.compensatory.subtitle' | translate }}</span>
    <ul *ngFor="let point of oneToTwoArray">
      <li class="bold">{{ 'speech_instructions.assessment.sound.due_to_' + point | translate }}</li>
      <span>{{ 'speech_instructions.assessment.sound.due_to_' + point + '.compensatory' | translate }}</span>
    </ul>

    <ul>
      <li>
        {{ 'speech_instructions.assessment.sound.compensatory' | translate }}
        <ul *ngFor="let point of oneToSixArray">
          <li class="bold">{{ 'speech_instructions.assessment.sound.compensatory_' + point | translate }}</li>
          <span>{{ 'speech_instructions.assessment.sound.compensatory_' + point + '_comment' | translate }}</span>
        </ul>
      </li>
    </ul>

    <h4>{{ 'speech_instructions.assessment.sound.errors' | translate }}</h4>
    <span>{{ 'speech_instructions.assessment.sound.errors_subtitle' | translate }}</span>

    <h4>{{ 'comments' | translate }}</h4>
    <span>{{ 'speech_instructions.assessment.sound.comments_subtitle' | translate }}</span>

    <h3>{{ 'speech_instructions.assessment.rating' | translate }}</h3>
    <ul *ngFor="let point of oneToFourArray">
      <li>{{ 'speech_instructions.assessment.rating_' + point | translate }}</li>
    </ul>

    <h3>{{ 'speech_assessment.intraoral_assessment' | translate }}</h3>
    <h4>{{ 'speech_instructions.methods' | translate }}</h4>
    <div>{{ 'speech_instructions.intraoral.methods_subtitle' | translate }}</div>
    <ul>
      <li class="bold">
        {{ 'speech_instructions.intraoral.methods_1' | translate }}
        <div class="normal">{{ 'speech_instructions.intraoral.methods_1_comment' | translate }}</div>
        <ul *ngFor="let point of twoToThreeArray">
          <li class="bold">{{ 'speech_assessment.malocclusion.type_' + point | translate }}</li>
          <div class="normal">{{ 'speech_assessment.tooltip.malocclusion.type_' + point | translate }}</div>
        </ul>
      </li>
    </ul>
    <ul *ngFor="let point of twoToFiveArray">
      <li class="bold">{{ 'speech_instructions.intraoral.methods_' + point | translate }}</li>
      <div>{{ 'speech_instructions.intraoral.methods_' + point + '_comment' | translate }}</div>
    </ul>

    <h3>{{ 'speech_instructions.assessment.speech_sample_recording' | translate }}</h3>
    <div>{{ 'speech_instructions.assessment.speech_sample_recording_subtitle' | translate }}</div>
    <ul *ngFor="let point of oneToThreeArray">
      <li>{{ 'speech_instructions.assessment.speech_sample_recording_' + point | translate }}</li>
    </ul>
    <h4>{{ 'speech_instructions.assessment.speech_sample' | translate }}</h4>
    <ul *ngFor="let point of oneToTwoArray">
      <li>{{ 'speech.repetition_' + point | translate }}</li>
      <div>{{ 'speech_assessment.tooltip.repetition_' + point | translate }}</div>
    </ul>

    <h3>{{ 'speech_assessment.instrumental_assessment' | translate }}</h3>
    <div>{{ 'speech_instructions.assessment.instrumental_subtitle_1' | translate }}</div>
    <div>{{ 'speech_instructions.assessment.instrumental_subtitle_2' | translate }}</div>

    <h3>{{ 'speech_treatment.recommendations' | translate }}</h3>
    <div>{{ 'speech_instructions.assessment.recommendations_subtitle' | translate }}</div>
  </div>

  <div class="page-section m-b-40">
    <h2>{{ 'speech_instructions.treatment.title' | translate }}</h2>

    <h4>{{ 'speech_treatment.treatment_frequency' | translate }}</h4>
    <div>{{ 'speech_instructions.treatment.frequency_comment_1' | translate }}</div>

    <h4>{{ 'speech_treatment.treatment_frequency' | translate }}</h4>
    <div>{{ 'speech_instructions.treatment.frequency_comment_2' | translate }}</div>
    <div>{{ 'speech_instructions.treatment.frequency_comment_3' | translate }}</div>

    <h4>{{ 'speech_instructions.treatment.period' | translate }}</h4>
    <div>{{ 'speech_instructions.treatment.period_comment' | translate }}</div>

    <h4>{{ 'speech_instructions.treatment.goals' | translate }}</h4>
    <div>{{ 'speech_instructions.treatment.goals_comment' | translate }}</div>
    <div class="m-t-10 column-until-gt-md-container">
      <ng-container *ngFor="let goal of goals">
        <div class="m-l-50">{{ goal.label | translate }}</div>
      </ng-container>
    </div>

    <h4>{{ 'discontinued' | translate }}</h4>
    <div>{{ 'speech_instructions.treatment.discontinued_comment' | translate }}</div>

    <h4>{{ 'discharged' | translate }}</h4>
    <div>{{ 'speech_instructions.treatment.discharged_comment' | translate }}</div>
  </div>
</div>
