import { TreatmentType } from '@shared/components/treatment/treatment.enum';

export enum ReportRegions {
  CHINA = 'CHINA',
  INDIA = 'INDIA',
  PHILIPPINES = 'PHILIPPINES'
}

export interface ReportQueryParams {
  region?: ReportRegions;
  treatmentType?: TreatmentType;
}

export interface RegionRouteQueries {
  china: ReportQueryParams;
  india: ReportQueryParams;
  philippines: ReportQueryParams;
}

export const reportRegionRouteQueries: RegionRouteQueries = {
  china: { region: ReportRegions.CHINA },
  india: { region: ReportRegions.INDIA },
  philippines: { region: ReportRegions.PHILIPPINES }
};
