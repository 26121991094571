import { Component, OnInit } from '@angular/core';
import { RoleEnum } from '@src/app/features/authentication/helpers/role.enum';
import { PasswordExpiryService } from '@src/app/features/authentication/services/password-expiry.service';
import { AuthService } from '@src/app/features/authentication/services/auth.service';

@Component({
  templateUrl: './surgical-qa-dashboard-page.component.html',
  styleUrls: ['./surgical-qa-dashboard-page.component.scss']
})
export class SurgicalQaDashboardPageComponent implements OnInit {
  constructor(private passwordExpiryService: PasswordExpiryService, private authService: AuthService) {}

  ngOnInit() {
    this.passwordExpiryService.showPasswordExpiryPopup();
  }
  isViewDashboardForUser(): boolean {
    return this.authService.checkUserRole(RoleEnum.QA_REVIEWER_SURGICAL);
  }
}
