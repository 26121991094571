import { ChangeDetectionStrategy, Component, Input, NgModule, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { Router, RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { PermissionModule } from '@core/permissions/permission.module';
import { PatientTreatment } from '../../../features/patient/models/patient.model';
import { TreatmentModalComponent } from '../treatment/components/treatment-status-bar/components/treatment-modal/treatment-modal.component';
import { DialogMode } from '../treatment/components/treatment-status-bar/treatment-status-bar.utils';
import { MatDialog } from '@angular/material/dialog';
import { MatchType } from '../../../features/patient/patient.enum';
import { PatientService } from '../../../features/patient/patient.service';
import { patientDetailsRoute, patientsRoute } from '@utils/routing.utils';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { AuthService } from '../../../features/authentication/services/auth.service';
import { PatientDataService } from '@src/app/features/patient/services/patient-data.service';
import { PatientActionType } from '@shared/components/patient-details-actions/patient-action-type.enum';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-patient-details-actions',
  templateUrl: './patient-details-actions.component.html',
  styleUrls: ['./patient-details-actions.component.scss']
})
export class PatientDetailsActionsComponent {
  @Input() patientTreatment: PatientTreatment;

  readonly DialogMode = DialogMode;

  constructor(
    private readonly dialog: MatDialog,
    private readonly patientService: PatientService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly wsHelperService: WsHelperService,
    @Optional() private readonly patientDataService: PatientDataService
  ) {}

  get canUnlink(): boolean {
    return (
      this.patientTreatment.id !== this.patientTreatment.uniPatientId && this.authService.checkUserPermission(PermissionEnum.UNLINK_PATIENT)
    );
  }

  openDialog(dialogMode: DialogMode) {
    const unlockDialogRef = this.dialog.open(TreatmentModalComponent, { autoFocus: false, data: { mode: dialogMode } });
    unlockDialogRef.afterClosed().subscribe(result => {
      if (result?.confirm) {
        switch (dialogMode) {
          case DialogMode.UNLOCK:
            this.unlockPatient();
            break;
          case DialogMode.DELETE_PATIENT:
            this.deletePatient();
            break;
          case DialogMode.UNLINK:
            this.unlinkPatient();
            break;
        }
      }
    });
  }

  private unlockPatient() {
    this.wsHelperService.callWithSpinner(this.patientService.unlockPatientById(this.patientTreatment.id)).subscribe(() => {
      this.router.navigate(patientDetailsRoute(this.patientTreatment.id));
      this.patientDataService?.refreshLoadedPatient();
    });
  }

  private deletePatient() {
    this.wsHelperService.callWithSpinner(this.patientService.deletePatientById(this.patientTreatment.id)).subscribe(() => {
      this.patientService.emitUpdatePatients(PatientActionType.DELETE);
      this.router.navigate([`${patientsRoute}`]);
    });
  }

  private unlinkPatient(): void {
    this.wsHelperService
      .callWithSpinner(
        this.patientService.matchPatients({
          patientId1: this.patientTreatment.uniPatientId,
          patientId2: this.patientTreatment.id,
          matchType: MatchType.NOT_SAME
        })
      )
      .subscribe(() => {
        this.patientService.emitUpdatePatients(PatientActionType.UNLINK);
      });
  }
}

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    MatTooltipModule,
    MatButtonModule,
    PermissionModule
  ],
  declarations: [PatientDetailsActionsComponent],
  exports: [PatientDetailsActionsComponent]
})
export class PatientDetailsActionsComponentModule {}
