<stx-readonly-view-columns *ngIf="treatment">
  <ng-container *stxReadonlyViewColumn>
    <stx-readonly-view-section-header labelKey="ortho.general_patient_information"></stx-readonly-view-section-header>
    <stx-simple-readonly-field label="nutrition_screening.evaluation_date" [value]="treatment.dateScreen"></stx-simple-readonly-field>
    <stx-readonly-field label="treatment_center.treatment_center">
      <stx-tc-link [treatmentCenter]="treatment.treatmentCenter"></stx-tc-link>
    </stx-readonly-field>
    <stx-simple-readonly-field label="status" [value]="treatmentStatusLabel | translate"> </stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="treatment.telehealth_assessment"
      [value]="'yes' | if : treatment.telehealth | translate"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field label="age" [value]="treatment.ageMonth | ageYearMonth"></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition.child_weight"
      [value]="treatment.weightIs | unit : 'unit.weight'"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition.child_height"
      [value]="treatment.heightIs | unit : 'unit.height'"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_follow_up.improv_reason.hemogl"
      [value]="treatment.hemoglobinIs | unit : 'unit.hemoglobin'"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field label="nutrition.muac" [value]="treatment.muacIs | unit : 'unit.height'"></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_screening.provider"
      [value]="[
        'nutrition_screening.provider.social_worker' | if : treatment.providerSocialworker | translate,
        'practitioner.nutritionist' | if : treatment.providerNutritionist | translate,
        'nutrition_screening.provider.nurse' | if : treatment.providerNurse | translate,
        'practitioner.surgeon' | if : treatment.providerSurgeon | translate,
        'nutrition_screening.provider.speech_therapist' | if : treatment.providerTherapist | translate,
        'nutrition_screening.provider.health_worker' | if : treatment.providerHealthWorker | translate,
        treatment.providerOther
      ]"
    ></stx-simple-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-readonly-view-section-header labelKey="nutrition_screening.by_parent"></stx-readonly-view-section-header>
    <stx-simple-readonly-field
      label="nutrition_screening.breastfeeding"
      [value]="treatment.breastfeeding | yesNo"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition.breastfeeding.challenges.view_label"
      [value]="[
        'nutrition_screening.breastfeeding.no_milk' | if : treatment.breastfeeingNomilk | translate,
        'nutrition_screening.breastfeeding.no_latch' | if : treatment.breastfeeingNolatch | translate,
        'nutrition_screening.breastfeeding.pain' | if : treatment.breastfeeingPain | translate,
        'nutrition_screening.breastfeeding.too_old' | if : treatment.breastfeeingTooold | translate,
        'nutrition_screening.breastfeeding.against' | if : treatment.breastfeeingAgainst | translate,
        'nutrition_screening.breastfeeding.cries' | if : treatment.breastfeeingBabycries | translate,
        treatment.breastfeeingOther
      ]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_screening.food"
      [value]="[
        'nutrition_screening.food.breastmilk' | if : treatment.feedBreastmilk | translate,
        'nutrition_screening.food.expressed' | if : treatment.feedExprmilk | translate,
        'nutrition_screening.food.cowmilk' | if : treatment.feedCowmilk | translate,
        'nutrition_screening.food.formula' | if : treatment.feedFormula | translate,
        'nutrition_screening.food.mashed' | if : treatment.feedMacerated | translate,
        'nutrition_screening.food.solid' | if : treatment.feedSolids | translate,
        treatment.feedOther
      ]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_screening.feeding_method"
      [value]="[
        'nutrition_screening.feeding_method.breast' | if : treatment.feedhowBreast | translate,
        'nutrition_screening.feeding_method.bottle' | if : treatment.feedhowBottle | translate,
        'nutrition_screening.feeding_method.spoon' | if : treatment.feedhowSpoon | translate,
        'nutrition_screening.feeding_method.cup' | if : treatment.feedhowCup | translate,
        'nutrition_screening.feeding_method.hand' | if : treatment.feedhowHand | translate,
        'nutrition_screening.feeding_method.syringe' | if : treatment.feedhowSyringe | translate,
        'nutrition_screening.feeding_method.tube' | if : treatment.feedhowIv | translate,
        treatment.feedhowOther
      ]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_screening.challenges"
      [value]="[
        'nutrition_screening.challenges.tire' | if : treatment.probfeedTire | translate,
        'nutrition_screening.challenges.nose' | if : treatment.probfeedNose | translate,
        'nutrition_screening.challenges.choke' | if : treatment.probfeedChoke | translate,
        'nutrition_screening.challenges.refuse' | if : treatment.probfeedRefuse | translate,
        treatment.probfeedOther
      ]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_screening.counseling"
      [value]="[treatment.feedGuidance | yesNo, treatment.feedCounsel]"
    ></stx-simple-readonly-field>
  </ng-container>
  <ng-container *stxReadonlyViewColumn>
    <stx-readonly-view-section-header labelKey="nutrition_screening.by_practitioner"></stx-readonly-view-section-header>
    <stx-simple-readonly-field
      label="nutrition_screening.given_info"
      [value]="[
        treatment.givenInfoFeed | yesNo,
        'nutrition_screening.given_info.importance' | if : treatment.givenInfoImportance | translate,
        'nutrition_screening.given_info.strategies' | if : treatment.givenInfoStrategies | translate,
        'nutrition_screening.given_info.handson' | if : treatment.givenInfoHandson | translate,
        'nutrition_screening.given_info.expressmilk' | if : treatment.givenInfoExpressmilk | translate,
        treatment.givenInfoOther
      ]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_screening.given_food"
      [value]="[treatment.givenFood | yesNo, treatment.givenFoodMore]"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field label="nutrition_screening.prep" [value]="treatment.givenInfoPrep | yesNo"></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_screening.treatment"
      [value]="treatment.givenInfoTreatment | yesNo"
    ></stx-simple-readonly-field>
    <stx-simple-readonly-field
      label="nutrition_screening.given_support"
      [value]="[treatment.givenSupport | yesNo, treatment.givenSupportMore]"
    ></stx-simple-readonly-field>
  </ng-container>
</stx-readonly-view-columns>
<stx-readonly-media-group [mediaSets]="photos"></stx-readonly-media-group>
