import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ifEmpty'
})
export class IfEmptyPipe implements PipeTransform {
  transform(value: string | string[], replaceWith: string | string[]): string | string[] {
    const isValueEmpty = !value || (Array.isArray(value) && value.length === 0);
    return isValueEmpty ? replaceWith : value;
  }
}

@NgModule({
  declarations: [IfEmptyPipe],
  exports: [IfEmptyPipe]
})
export class IfEmptyPipeModule {}
