import { dateFormat } from '@/src/app/utils/date.utils';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { AuthService } from '@src/app/features/authentication/services/auth.service';
import { allQaResults, practitioners } from '@utils/routing.utils';
import { switchMap, tap } from 'rxjs/operators';
import { PermissionEnum } from 'src/app/core/permissions/permission.enum';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { PaginationSortingModel } from 'src/app/shared/components/table/models/pagination-sorting.model';
import { TableStructure } from 'src/app/shared/components/table/models/table-structure.model';
import { PractitionerDetails, PractitionerNote, PractitionerSpecialization } from '../../models/practitioner.model';
import { PractitionerService } from '../../practitioner.service';
import { PractitionerCreateNoteModalComponent } from '../modals/practitioner-create-note-modal/practitioner-create-note-modal.component';

@Component({
  templateUrl: './practitioner-details-page.component.html'
})
export class PractitionerDetailsPageComponent extends BaseComponent implements OnInit {
  readonly permissionEnum = PermissionEnum;
  readonly allQaResults = allQaResults;
  readonly practitioners = practitioners;
  readonly dateFormat = dateFormat;

  practitionerId: number;
  practitioner: PractitionerDetails;
  treatmentCentersTableData: TableStructure;
  specializations: PractitionerSpecialization[] = [];
  showLatestCases: boolean;
  notes: PractitionerNote[] = [];
  isSurgeon: boolean;

  constructor(
    private route: ActivatedRoute,
    private practitionerService: PractitionerService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    public router: Router,
    private dialog: MatDialog,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.getPractitionerFromParams();
  }

  private getPractitionerFromParams(): void {
    this.subSink.sink = this.route.params
      .pipe(
        tap(params => (this.practitionerId = params.id)),
        switchMap(() =>
          this.wsHelperService.callWithSpinner(this.practitionerService.getPractitionerDetails(this.practitionerId), {
            redirectOn404StatusCode: true
          })
        )
      )
      .subscribe(practitioner => {
        this.practitioner = practitioner;
        this.getTreatmentCenters();
        this.loadNotes();
        this.displaySpecializations();
      });
  }

  private getTreatmentCenters(event?: PaginationSortingModel): void {
    this.wsHelperService
      .callWithSpinner(this.practitionerService.getPractitionerTreatmentCenters(this.practitionerId, event))
      .subscribe(treatmentCenters => {
        this.treatmentCentersTableData = treatmentCenters;
        this.changeDetector.detectChanges();
      });
  }

  private loadNotes(): void {
    if (this.authService.checkUserPermission(PermissionEnum.READ_PRACTITIONER_NOTE)) {
      this.wsHelperService.callWithSpinner(this.practitionerService.getNotes(this.practitionerId)).subscribe(notes => {
        this.notes = notes.content;
      });
    }
  }

  onNewNoteButtonClicked() {
    this.dialog
      .open(PractitionerCreateNoteModalComponent, { data: { practitionerId: this.practitionerId } })
      .afterClosed()
      .subscribe(() => {
        this.loadNotes();
        this.changeDetector.detectChanges();
      });
  }

  displaySpecializations(): void {
    let specializationTemp: PractitionerSpecialization;
    this.practitioner.practitionerSpecialization.forEach(specialization => {
      specializationTemp = specialization;
      switch (specialization.specialization) {
        case 'SURGEON':
          specializationTemp.specialization = 'surgeon';
          this.showLatestCases = true;
          this.isSurgeon = true;
          break;
        case 'ORTHODONTIST':
          specializationTemp.specialization = 'practitioner.orthodontist';
          break;
        case 'NUTRITIONIST':
          specializationTemp.specialization = 'practitioner.nutritionist';
          break;
        case 'SPEECH_PROVIDER':
          specializationTemp.specialization = 'practitioner.speech_provider';
          break;
        case 'DENTAL_PROVIDER':
          specializationTemp.specialization = 'practitioner.dental_provider';
          break;
        case 'ENT_PROVIDER':
          specializationTemp.specialization = 'practitioner.ent_provider';
          break;
        case 'PSYCHOSOCIAL_PROVIDER':
          specializationTemp.specialization = 'practitioner.psychosocial_provider';
          break;
        case 'HEARING_SPECIALIST':
          specializationTemp.specialization = 'practitioner.hearing_specialist';
          break;
      }
      this.specializations.push(specializationTemp);
    });
    this.specializations.reverse();
    this.changeDetector.detectChanges();
  }

  changeTreatmentCenterPageSort(event: PaginationSortingModel) {
    this.getTreatmentCenters(event);
  }
}
