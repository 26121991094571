<div class="form-section">
  <p class="form-section-heading">
    {{ 'speech_treatment.recommendations' | translate }}
  </p>
  <div class="form-row">
    <stx-checkbox-group
      [layoutDirection]="'column'"
      [options]="recommendationsOptions"
      [formGroup]="formGroup"
      [formMode]="formMode"
      [print]="print"
      [formGroupErrorNames]="['recommendationFields']"
    ></stx-checkbox-group>
  </div>
  <div class="form-row comment-container">
    <stx-textarea [label]="'comments'" [formGroup]="formGroup" [formMode]="formMode" [controlName]="'recommendComment'"></stx-textarea>
  </div>
</div>
