<div [formGroup]="formGroup">
  <mat-checkbox
    data-testid="checkbox-component"
    [dir]="direction"
    [formControlName]="controlName"
    (change)="checkboxChanged($event.checked)"
  >
    <ng-container *ngIf="name">
      <ng-container *ngIf="!print; else printed">
        {{ name | translate }}
      </ng-container>
      <ng-template #printed>
        <span [ngStyle]="{ 'font-size': '1.25rem' }">{{ name | translate }}</span>
      </ng-template>
      <stx-tooltip [ngClass]="{ 'hide-on-print': print }" *ngIf="tooltip" [content]="tooltip"></stx-tooltip>
    </ng-container>
    <ng-content></ng-content>
  </mat-checkbox>
  <div *ngIf="subtitles" class="form-element-secondary-label p-l-24 fs-13">
    <div *ngFor="let sub of subtitles; last as last">{{ sub | translate }}<br *ngIf="!last" /></div>
  </div>
</div>
