import {
  mixedDentitionAssessmentAppRoute,
  mixedDentitionMidAppRoute,
  mixedDentitionTreatmentAppRoute,
  permanentDentitionAssessmentAppRoute,
  permanentDentitionMidAppRoute,
  permanentDentitionTreatmentAppRoute,
  psioAssessmentAppRoute,
  psioTreatmentAppRoute
} from '@/src/app/features/ortho/utils/ortho-routes.consts';
import { ExternalTreatmentType } from '@/src/app/features/patient/models/external-treatment-type.enum';
import { TreatmentType } from '@shared/components/treatment/treatment.enum';
import { FormStatus } from '@shared/enums/form-status.enum';
import { formStatusMap } from './form.utils';
import { assessment, followUp, nutritionRoute, nutritionPilotRoute, orthoRoute, screening, surgeryFollowUpFull } from './routing.utils';

export const treatmentPaths = new Map<TreatmentType, TreatmentRoutesMapping>([
  [TreatmentType.SURGERY, { path: '/surgery/intervention', name: 'treatment.surgical_intervention' }],
  [TreatmentType.FOLLOW_UP, { path: `/${surgeryFollowUpFull}`, name: 'treatment.surgery_follow_up' }],
  [TreatmentType.BORESCOPE, { path: '/surgery/borescope', name: 'treatment.borescope' }],
  [TreatmentType.SPEECH_ASSESSMENT, { path: '/speech/assessment', name: 'treatment.speech_assessment' }],
  [TreatmentType.SPEECH_TREATMENT, { path: '/speech/treatment', name: 'treatment.speech_treatment' }],
  [TreatmentType.ORTHOGNATHIC, { path: '/orthognathic', name: 'treatment.orthognathic_treatment' }],
  [
    TreatmentType.NUTRITION_ASSESSMENT,
    { path: `/${nutritionRoute}/${assessment}`, name: 'treatment.nutrition_management.assessment.shorter' }
  ],
  [TreatmentType.NUTRITION_FOLLOW_UP, { path: `/${nutritionRoute}/${followUp}`, name: 'treatment.nutrition_management.follow_up.shorter' }],
  [TreatmentType.NUTRITION_SCREENING_HISTORICAL, { path: `/${nutritionPilotRoute}/${screening}`, name: 'treatment.nutrition_screening' }],
  [TreatmentType.NUTRITION_FOLLOW_UP_HISTORICAL, { path: `/${nutritionPilotRoute}/${followUp}`, name: 'treatment.nutrition_follow_up' }],
  [TreatmentType.ORTHO_PSIO_ASSESSMENT, { path: `/${orthoRoute}/${psioAssessmentAppRoute}`, name: 'treatment.ortho_psio_assessment' }],
  [TreatmentType.ORTHO_PSIO_TREATMENT, { path: `/${orthoRoute}/${psioTreatmentAppRoute}`, name: 'treatment.ortho_psio_treatment' }],
  [
    TreatmentType.ORTHO_MD_ASSESSMENT,
    { path: `/${orthoRoute}/${mixedDentitionAssessmentAppRoute}`, name: 'treatment.ortho_md_assessment' }
  ],
  [
    TreatmentType.ORTHO_PD_ASSESSMENT,
    { path: `/${orthoRoute}/${permanentDentitionAssessmentAppRoute}`, name: 'treatment.ortho_pd_assessment' }
  ],
  [TreatmentType.ORTHO_MD_TREATMENT, { path: `/${orthoRoute}/${mixedDentitionTreatmentAppRoute}`, name: 'treatment.ortho_md_treatment' }],
  [
    TreatmentType.ORTHO_PD_TREATMENT,
    { path: `/${orthoRoute}/${permanentDentitionTreatmentAppRoute}`, name: 'treatment.ortho_pd_treatment' }
  ],
  [
    TreatmentType.ORTHO_PD_MID_ASSESSMENT,
    { path: `/${orthoRoute}/${permanentDentitionMidAppRoute}`, name: 'treatment.ortho_pd_mid_assessment' }
  ],
  [
    TreatmentType.ORTHO_MD_MID_ASSESSMENT,
    { path: `/${orthoRoute}/${mixedDentitionMidAppRoute}`, name: 'treatment.ortho_md_mid_assessment' }
  ]
]);

export const externalTreatmentTranslationKeys = {
  [ExternalTreatmentType.ENT_ASSESSMENT]: 'treatment.ent_assessment',
  [ExternalTreatmentType.ENT_SURGERY]: 'treatment.ent_surgery',
  [ExternalTreatmentType.ENT_HEARING_ASSESSMENT]: 'treatment.ent_hearing_assessment',
  [ExternalTreatmentType.ORAL_HEALTH_NO_TEETH]: 'treatment.oh_no_teeth',
  [ExternalTreatmentType.ORAL_HEALTH]: 'treatment.oh',
  [ExternalTreatmentType.ORAL_HEALTH_DENTAL_TREATMENT]: 'treatment.oh_dental_treatment'
};

export const treatmentTypeBySection = [
  [TreatmentType.NUTRITION_ASSESSMENT, TreatmentType.NUTRITION_FOLLOW_UP],
  [TreatmentType.SURGERY, TreatmentType.FOLLOW_UP, TreatmentType.ORTHOGNATHIC],
  [TreatmentType.SPEECH_ASSESSMENT, TreatmentType.SPEECH_TREATMENT],
  [TreatmentType.ORTHO_PSIO_ASSESSMENT, TreatmentType.ORTHO_PSIO_TREATMENT],
  [TreatmentType.ORTHO_MD_ASSESSMENT, TreatmentType.ORTHO_MD_TREATMENT, TreatmentType.ORTHO_MD_MID_ASSESSMENT],
  [TreatmentType.ORTHO_PD_ASSESSMENT, TreatmentType.ORTHO_PD_TREATMENT, TreatmentType.ORTHO_PD_MID_ASSESSMENT]
];

export const getTreatmentTypeData = (treatmentType: TreatmentType) => treatmentPaths.get(treatmentType);

export interface TreatmentRoutesMapping {
  path: string;
  name: string;
}

export function mapToStatusEnum(statusValue: number | null): FormStatus | null {
  if (statusValue !== null) {
    for (const [key, value] of formStatusMap.entries()) {
      if (value === statusValue) {
        return key;
      }
    }
  }
  return null;
}
