import { UserService } from '@/src/app/features/user/services/user.service';
import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Item } from '@/src/app/shared/models/item.model';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormGroup } from '@angular/forms';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  selector: 'stx-user-role-filter',
  templateUrl: './user-role-filter.component.html'
})
export class UserRoleFilterComponent extends BaseFormElementComponent implements OnInit {
  roles: Item[];
  @Input() formControlName = 'roles';
  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly userService: UserService,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as UntypedFormGroup;
    this.subSink.sink = this.wsHelperService.callWithSpinner(this.userService.getRoles()).subscribe(roles => {
      this.roles = roles;
    });
  }
  onRemoveChip($event: Item, control: AbstractControl) {
    const newValues = (control.value as Array<Item>).filter((value: Item) => value.id !== $event.id);

    control.setValue(newValues);
  }
}
