import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-discontinue-ortho',
  templateUrl: './discontinue-ortho.component.html'
})
export class DiscontinueOrthoComponent extends BaseFormElementComponent {
  @Input() tooltipText: string;
}
