<div class="user-profile-container">
  <h1 *ngIf="isNewUser">{{ 'user.create_user' | translate }}</h1>
  <h1 *ngIf="!isNewUser">{{ 'user.edit_user' | translate }}</h1>
  <stx-user-personal-data [userData]="user"></stx-user-personal-data>
  <h4>{{ 'profile' | translate }}</h4>
  <ng-container *ngIf="user && isProfileReadonly; else editMode">
    <div *ngFor="let profile of user.profiles" class="profile-container m-b-24 flex-row-container">
      <div class="roles">{{ profile.role.name }}</div>
      <div class="flex-row-container" [ngClass]="{ 'no-profile-name': !(profile && profile.name), scopes: profile && profile.name }">
        <div class="flex-column-container" *ngIf="profile && profile.name">
          <div class="profile-name-label">Profile Name</div>
          <div class="profile-name">{{ profile.name }}</div>
        </div>
        <div class="profile-actions flex-row-container">
          <mat-icon class="m-r-20" (click)="editProfile(profile)">create</mat-icon>
          <mat-icon *ngIf="showDeleteIcon" (click)="deleteProfile(profile)">delete</mat-icon>
        </div>
      </div>
    </div>
    <div class="button-position m-t-24 m-b-35">
      <button data-testid="button-user-page" mat-stroked-button color="primary" class="send-link-button" (click)="addProfile()">
        {{ 'user.add_profile' | translate }}
      </button>
    </div>
  </ng-container>
  <ng-template #editMode>
    <stx-user-profile [profile]="selectedProfile"></stx-user-profile>
  </ng-template>
  <mat-divider></mat-divider>
  <stx-action-buttons
    [primaryText]="isNewUser ? ('user.add_user' | translate) : ('submit' | translate)"
    [secondaryText]="'user.send_link_save' | translate"
    [asyncSecondaryAction]="initiateSave(true)"
    [asyncPrimaryAction]="initiateSave()"
  ></stx-action-buttons>
  <div class="errors flex-column-container" *ngIf="errorKeys.length > 0">
    <mat-error *ngFor="let error of errorKeys">{{ error | translate }}</mat-error>
  </div>
</div>
