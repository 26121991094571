<stx-modal>
  <ng-container>
    <div class="bold m-b-15">
      {{ 'practitioner.add_new_note' | translate }}
    </div>
    <form [formGroup]="noteForm">
      <div class="flex-column-container">
        <div class="custom-flex-70">
          <stx-textarea class="width-1000p" [controlName]="'noteFormControl'" [formMode]="formMode" [formGroup]="noteForm"></stx-textarea>
        </div>
        <stx-action-buttons
          [primaryText]="'save' | translate"
          [secondaryText]="'dialog.back' | translate"
          (secondaryAction)="onBackClicked()"
          [asyncPrimaryAction]="submitNote"
          [primaryDisabled]="!noteForm.valid"
        ></stx-action-buttons>
      </div>
    </form>
  </ng-container>
</stx-modal>
