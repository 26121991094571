import { ButtonDisablerService } from '@/src/app/shared/services/button-disabler/button-disabler.service';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../../base-component/base.component';

@Component({
  selector: 'stx-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss']
})
export class ActionButtonsComponent extends BaseComponent implements OnInit {
  @Input() showPrimary = true;
  @Input() showSecondary = true;
  @Input() primaryText: string;
  @Input() primaryDisabled = false;
  @Input() noMargin = false;
  @Input() secondaryText: string;
  @Input() isRtlLanguage: boolean;
  /**Does not handle button locking, use asyncSecondaryAction input instead*/
  @Output() secondaryAction = new EventEmitter<void>();
  /**Does not handle button locking, use asyncPrimaryAction input instead*/
  @Output() primaryAction = new EventEmitter<void>();

  @Input() asyncPrimaryAction: AsyncButtonClickAction;
  @Input() asyncSecondaryAction: AsyncButtonClickAction;
  secondaryActionInProgress = false;

  constructor(public buttonDisablerService: ButtonDisablerService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subSink.sink = this.buttonDisablerService.disabler$.subscribe({
      next: () => {
        this.cd.detectChanges();
      }
    });
  }

  handleSecondaryButtonClick() {
    if (!this.asyncSecondaryAction) {
      this.secondaryAction.emit();
    }

    if (this.asyncSecondaryAction && !this.secondaryActionInProgress) {
      this.secondaryActionInProgress = true;
      this.subSink.sink = this.asyncSecondaryAction().subscribe({
        next: () => {
          this.secondaryActionInProgress = false;
          this.cd.detectChanges();
        },
        error: () => {
          this.secondaryActionInProgress = false;
          this.cd.detectChanges();
        }
      });
    }
  }
}
