<stx-checkbox-group
  *ngIf="options"
  [layoutDirection]="layoutDirection"
  [options]="options"
  [formGroup]="formGroup"
  [formMode]="formMode"
  [deselectTriggerControlName]="deselectTriggerControlName"
  [print]="print"
  [formGroupErrorNames]="formGroupErrorNames"
  [templateRef]="dependentInput"
></stx-checkbox-group>
<ng-template let-option #dependentInput>
  <div *ngIf="option.name === textFieldParentControlName">
    <stx-textarea
      [controlName]="textFieldControlName"
      [formGroup]="formGroup"
      [formMode]="formMode"
      [rowNumber]="1"
      [disablePadding]="true"
    ></stx-textarea>
  </div>
</ng-template>
