import { BaseTreatment } from '@/src/app/features/surgical/models/base-treatment.model';
import { FormStatus, TreatmentStatusLabel } from '@/src/app/shared/enums/form-status.enum';
import { BaseReadonlyViewUtils, WithMedia } from '@/src/app/shared/modules/readonly-commons/utils/base-readonly-view.utils';
import { Component, Input } from '@angular/core';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { MediaResource } from '@shared/models/media-resource.model';
import { MediaResourceWithLabels } from '@shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';
import { chinaCountryId } from '@utils/constants.utils';
import { mapToStatusEnum } from '@utils/treatment.utils';

@Component({
  template: ''
})
export abstract class BaseReadonlyViewComponent<T extends WithMedia> {
  treatmentStatusLabel: string;
  readonly chinaCountryId = chinaCountryId;
  private _treatment: T;

  get treatment(): T {
    return this._treatment;
  }

  @Input()
  set treatment(treatment: T) {
    this._treatment = treatment;
    this.treatmentStatusLabel = this.getTreatmentStatusLabel();
    this.afterTreatmentSet();
  }

  private findMedia(parentOrderName: ParentOrderName): MediaResource | null {
    return BaseReadonlyViewUtils.findMedia(parentOrderName, this.treatment);
  }

  findMediaWithLabels(parentOrderName: ParentOrderName, labels: string[]): MediaResourceWithLabels | null {
    const media = this.findMedia(parentOrderName);
    return media ? { labelKeys: labels, ...media } : null;
  }
  private getTreatmentStatusLabel(): string {
    let treatmentStatus: FormStatus = mapToStatusEnum((this._treatment as BaseTreatment)?.status);

    if (!treatmentStatus) {
      treatmentStatus = FormStatus.SUBMITTED;
    }

    return TreatmentStatusLabel.get(treatmentStatus);
  }
  protected abstract afterTreatmentSet(): void;

  protected getPhotosWithLabels(photoLabelsInOrder: { labels: string[]; parentOrderName: ParentOrderName }[]) {
    return photoLabelsInOrder
      .map(labelParentOrderName => {
        return BaseReadonlyViewUtils.findMediaWithLabels(labelParentOrderName.parentOrderName, labelParentOrderName.labels, this.treatment);
      })
      .filter(resource => !!resource);
  }
}
