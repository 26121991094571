import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { errorResourceNotFound } from '@utils/routing.utils';

@Injectable({
  providedIn: 'root'
})
export class ErrorPageService {
  constructor(private readonly router: Router) {}

  redirectToNotFoundComponent(): void {
    this.router.navigateByUrl('/' + errorResourceNotFound);
  }
}
