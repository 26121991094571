import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { DataPage, DataPageWithNames, Label } from '@shared/modules/general-commons/components/data-table/data-table.model';
import { saveAs } from 'file-saver';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';

export class DataTableData<T> {
  constructor(public readonly page: DataPage<T>, public readonly columnLabels?: Label<T>[]) {}
}

export abstract class DataTableDataProvider<T> {
  readonly data$: Subject<DataTableData<T>> = new ReplaySubject<DataTableData<T>>();
  abstract reload(pageable?: PaginationSortingModel): void;
  abstract download(): void;

  translateLabel(fieldName: string): Observable<string> {
    return this.data$.pipe(map(data => (data.columnLabels || []).find(label => label.name === fieldName)?.text));
  }
}

interface GenericDataTableDataProviderConfig<T> {
  loadFunction: (request: PaginationSortingModel) => Observable<DataPageWithNames<T>>;
  exportFunction?: () => Observable<Blob>;
  exportFileName?: string;
  noResultsMessageKey?: string;
}
export class GenericDataTableDataProvider<T> extends DataTableDataProvider<T> {
  public labels: Label<T>[] = [];

  constructor(
    protected readonly config: GenericDataTableDataProviderConfig<T>,
    protected readonly wsHelperService: WsHelperService,
    protected readonly globalErrorHandlerService: GlobalErrorHandlerService
  ) {
    super();
  }

  reload(pageable: PaginationSortingModel | undefined): void {
    this.config
      .loadFunction(pageable)
      .pipe(
        catchError(error => {
          this.globalErrorHandlerService.handleErrorAndInformUser(error);
          return of(null);
        }),
        filter(dataWithLabels => !!dataWithLabels),
        map(dataWithLabels => new DataTableData<T>(dataWithLabels.data.page, dataWithLabels.labels))
      )
      .subscribe({
        next: data => {
          this.data$.next(data);
        },
        error: error => {
          this.data$.error(error);
        }
      });
  }

  download(): void {
    if (!this.config.exportFunction) {
      return;
    }
    this.wsHelperService
      .callWithSpinner(this.config.exportFunction())
      .subscribe({ next: data => saveAs(data, this.config.exportFileName) });
  }
}
