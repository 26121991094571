import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'yesNoOrNull'
})
export class YesNoOrNullPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}
  transform(value: any): string {
    if (value == null) {
      return null;
    }
    return this.translateService.instant(value ? 'yes' : 'no');
  }
}

@NgModule({
  providers: [],
  declarations: [YesNoOrNullPipe],
  exports: [YesNoOrNullPipe]
})
export class YesNoOrNullPipeModule {}
