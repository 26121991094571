import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { ReadonlyImageModule } from '../readonly-image/readonly-image.module';
import { NoticeComponentModule } from '@shared/components/notice/notice.component';

@Component({
  selector: 'stx-image-thumbnail',
  templateUrl: './image-thumbnail.component.html',
  styleUrls: ['./image-thumbnail.component.scss']
})
export class ImageThumbnailComponent {
  @Input() imageUrl: string;
  @Input() imageAlt: string;
  @Input() thumbnailId: string;
  @Input() mediaId: number;
  @Input() mediaAnonymized = false;
}

@NgModule({
  declarations: [ImageThumbnailComponent],
  imports: [CommonModule, ReadonlyImageModule, NoticeComponentModule],
  exports: [ImageThumbnailComponent]
})
export class ImageThumbnailComponentModule {}
