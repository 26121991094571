<div>
  <label class="section-header form-element-label">
    <mat-checkbox data-testid="checkbox-section" (change)="expanded = $event.checked" [checked]="expanded">
      {{ label }} <fa-icon [icon]="icon" *ngIf="!print"></fa-icon>
    </mat-checkbox>
  </label>
  <div *ngIf="expanded">
    <ng-content></ng-content>
  </div>
</div>
