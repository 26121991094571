import { ChangeDetectorRef, Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { BaseReportTableComponent } from '../../../../components/base-report-table/base-report-table.component';
import { SimpleReportTableColumn } from '../../../../components/report-filters/models/report-data.model';
import { ReportService } from '../../../../components/report-filters/services/report.service';
import { ReportTableDataService } from '../../../../components/report-filters/services/report-table-data.service';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableStructure } from '@shared/components/table/models/table-structure.model';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SurgeryStatsReportRecord } from '../../surgery-stats-report.model';
import { ActivatedRoute } from '@angular/router';
import { SimpleReportTableComponentModule } from '@src/app/features/reports/components/simple-report-table/simple-report-table.component';

@Component({
  selector: 'stx-surgery-stats-report-table',
  templateUrl: './surgery-stats-report-table.component.html'
})
export class SurgeryStatsReportTableComponent extends BaseReportTableComponent<SurgeryStatsReportRecord> implements OnInit, OnDestroy {
  displayedColumns: string[] = [];
  columns: SimpleReportTableColumn[] = [];
  constructor(
    public changeDetector: ChangeDetectorRef,
    public reportTableDataService: ReportTableDataService,
    public reportService: ReportService,
    public route: ActivatedRoute
  ) {
    super('SURGERY_STATS', changeDetector, reportTableDataService, reportService, route);
  }

  ngOnInit(): void {
    super.onInit();
    this.subSink.sink = this.reportTableDataService.tableStructure$.subscribe(
      (tableStructure: TableStructure<SurgeryStatsReportRecord[]>) => {
        this.columns = tableStructure?.labels || [];
        this.displayedColumns = this.columns.map(a => a.name);
      }
    );
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }
}

@NgModule({
  declarations: [SurgeryStatsReportTableComponent],
  imports: [
    MatTableModule,
    MatSortModule,
    TranslateModule,
    CommonModule,
    MatIconModule,
    MatPaginatorModule,
    FlexLayoutModule,
    EmptyTableStateComponentModule,
    SimpleReportTableComponentModule
  ],
  exports: [SurgeryStatsReportTableComponent]
})
export class SurgeryStatsReportTableComponentModule {}
