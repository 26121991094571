<div class="m-t-20 m-b-20 flex-row-container">
  <div class="flex-column-container some-custom-flex-container">
    <div class="flex-row-container align-left layout-align-center empty-state normal p-15">
      <div class="icon-container flex-center align-center m-r-15">
        <mat-icon class="text-inverted">priority_high</mat-icon>
      </div>
      {{ textTranslationPath | translate }}
    </div>
  </div>
</div>
