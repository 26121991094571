import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { BaseType } from 'd3';
import { PlotItem, PlotLine } from './statistics-plot.model';
import { Selection } from 'd3-selection';

@Component({
  selector: 'stx-statistics-plot',
  templateUrl: './statistics-plot.component.html',
  styleUrls: ['./statistics-plot.component.scss']
})
export class StatisticsPlotComponent implements OnInit, AfterViewInit {
  readonly margin = { top: 30, right: 30, bottom: 30, left: 60 };
  readonly xAxisOffset = 0.05;
  readonly yTickCount = 3;
  readonly plotDotSize = 4;
  readonly gridColor = '#e2e2ec';

  @Input() plotLines: Array<PlotLine>;

  @ViewChild('tooltip', { static: false }) pointTooltipElement: ElementRef<HTMLDivElement>;

  private width: number;
  private height: number;
  private svg: any;
  private chart: any;
  private xScale: d3.ScaleLinear<number, number>;
  private yScale: d3.ScaleLinear<number, number>;
  private line: d3.Line<[number, number]>;
  private firstYear: number;
  private lastYear: number;
  private maxTreatments: number;
  private numberOfYears: number;
  private pointTooltipSelection: Selection<BaseType, unknown, undefined, any>;

  private createdPlots = 0;

  constructor(private elementRef: ElementRef<HTMLElement>) {
    this.width = 600 - this.margin.left - this.margin.right;
    this.height = 200 - this.margin.top - this.margin.bottom;
  }

  ngOnInit(): void {
    this.initializeData();
  }

  ngAfterViewInit() {
    this.buildSvg();
    this.createXAxisScale();
    this.createYAxisScale();
    this.addXAxis();
    this.addYAxis();
    this.drawPlots();
    this.setPointTooltipElement();
  }

  private drawPlots() {
    this.plotLines.forEach(plotLine => {
      this.drawLineAndPath(plotLine.plotPoints, plotLine.color);
    });
  }

  private initializeData() {
    this.firstYear = Math.min(...this.plotLines.map(plot => Math.min(...plot.plotPoints.map(x => Number(x?.label)))));
    this.lastYear = Math.max(...this.plotLines.map(plot => Math.max(...plot.plotPoints.map(x => Number(x?.label)))));
    this.maxTreatments = Math.max(...this.plotLines.map(plot => Math.max(...plot.plotPoints.map(x => Number(x?.value)))));
    this.numberOfYears = Math.max(1, Math.abs(this.lastYear - this.firstYear));
  }

  private buildSvg(): void {
    const chartContainer = d3.select(this.elementRef.nativeElement).select('.chart-container');
    this.svg = chartContainer.append('svg').attr('viewBox', `0 0 600 200`);

    this.chart = this.svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  private createXAxisScale(): void {
    this.xScale = d3
      .scaleLinear()
      .domain([this.firstYear - this.xAxisOffset, this.lastYear + this.xAxisOffset])
      .range([0, this.width]);
  }

  private createYAxisScale(): void {
    this.yScale = d3
      .scaleLinear()
      .range([this.height, 0])
      .domain(d3.extent([0, this.maxTreatments]))
      .nice(this.yTickCount);
  }

  private addXAxis(): void {
    this.chart
      .append('g')
      .call(d3.axisLeft(this.yScale).tickSizeInner(-this.width).tickSizeOuter(0).tickFormat(d3.format('d')).ticks(this.yTickCount))
      .call(g => g.select('.domain').attr('stroke', 'none'))
      .call(g => g.selectAll('.tick line').attr('stroke', this.gridColor))
      .call(g => g.selectAll('.tick text').attr('font-size', '0.875rem'));
  }

  private addYAxis(): void {
    this.chart
      .append('g')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(
        d3
          .axisBottom(this.xScale)
          .ticks(this.numberOfYears > 4 ? 4 : this.numberOfYears)
          .tickFormat(d3.format('.0f'))
          .tickSize(0)
      )
      .call(g => g.select('.domain').attr('stroke', 'none'))
      .call(g => g.selectAll('.tick text').attr('transform', 'translate(0, 10)'))
      .call(g => g.selectAll('.tick text').attr('font-size', '0.875rem'));
  }

  private drawLineAndPath(data: PlotItem[], color: string): void {
    this.drawLine();
    if (data) {
      this.drawPath(data, color);
      this.drawPoints(data, color);
      this.createdPlots++;
    }
  }

  private drawLine() {
    this.line = d3
      .line()
      .x((d: any) => this.xScale(d.label))
      .y((d: any) => this.yScale(d.value))
      .curve(d3.curveLinear);
  }

  private drawPath(data: PlotItem[], color: string) {
    this.chart
      .append('path')
      .datum(data)
      .attr('class', 'line')
      .attr('stroke', color)
      .attr('stroke-width', '3px')
      .attr('fill', 'none')
      .attr('d', this.line);
  }

  private drawPoints(data: PlotItem[], color: string) {
    this.chart
      .selectAll('dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', d => this.xScale(d.label))
      .attr('cy', d => this.yScale(d.value))
      .attr('r', this.plotDotSize)
      .style('fill', color)
      .on('mouseover', (event, d) => {
        this.pointTooltipSelection.transition().duration(200).style('opacity', 0.9);
        this.pointTooltipSelection
          .html(d.label + '<br/>' + d.value)
          .style('left', event.offsetX + 'px')
          .style('top', event.offsetY + 'px');
      })
      .on('mouseout', d => {
        this.pointTooltipSelection.transition().duration(500).style('opacity', 0);
      });
  }

  private setPointTooltipElement(): void {
    this.pointTooltipSelection = d3.select(this.elementRef.nativeElement).select('.tooltip').style('opacity', 0);
  }
}
