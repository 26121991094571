import { OrthoGeneralTreatmentInfoComponent } from '@/src/app/features/ortho/components/shared/ortho-general-treatment-info/ortho-general-treatment-info.component';
import { OrthoSharedGeneralInfoComponent } from '@/src/app/features/ortho/components/shared/ortho-shared-general-info/ortho-shared-general-info.component';
import { DentitionTreatment } from '@/src/app/features/ortho/models/dentition-treatment.model';
import { PermanentDentitionTreatmentService } from '@/src/app/features/ortho/services/permanent-dentition-treatment.service';
import { BaseOrthoFormComponent } from '@/src/app/features/ortho/shared/base-ortho-form.component';
import { OrthoTreatmentFormApi } from '@/src/app/features/ortho/shared/ortho-treatment-form-api';
import {
  dentitionTreatmentFacialImagesConfigs,
  dentitionTreatmentIntraOralImagesConfigs,
  dentitionTreatmentParentOrderNames,
  dentitionTreatmentXRayslImagesConfigs,
  fistulaFields
} from '@/src/app/features/ortho/utils/dentition.utils';
import { OrthoFormType } from '@/src/app/features/ortho/utils/ortho-form-type';
import { permanentDentitionTreatmentAppRoute } from '@/src/app/features/ortho/utils/ortho-routes.consts';
import { OrthoStage } from '@/src/app/features/ortho/utils/ortho-stage';
import { OrthoFormUtils } from '@/src/app/features/ortho/utils/ortho.utils';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { SpinnerService } from '@/src/app/shared/components/spinner/service/spinner.service';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { FormGuardService } from '@/src/app/shared/services/form-guard.service';
import { GlobalErrorHandlerService } from '@/src/app/shared/services/global-error-handler.service';
import { SnackBarService } from '@/src/app/shared/services/snack-bar.service';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import {
  atLeastOneRequired,
  invalidIf,
  requiredIfValidator,
  selectionInvalidIf,
  StxValidators
} from '@/src/app/shared/validation/validators';
import { FormMediaUtils } from '@/src/app/utils/form-media.utils';
import { FormMode, yesNoShort } from '@/src/app/utils/form.utils';
import { orthoRoute } from '@/src/app/utils/routing.utils';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';
import { PatientId } from '@src/app/features/patient/models/patient.model';

@Component({
  selector: 'stx-permanent-dentition-treatment',
  templateUrl: './permanent-dentition-treatment.component.html'
})
export class PermanentDentitionTreatmentComponent extends BaseOrthoFormComponent<DentitionTreatment> implements OnInit {
  readonly OrthoFormType = OrthoFormType;
  readonly yesNo = yesNoShort;
  readonly dentitionTreatmentFacialImagesConfigs = dentitionTreatmentFacialImagesConfigs;
  readonly dentitionTreatmentIntraOralImagesConfigs = dentitionTreatmentIntraOralImagesConfigs;
  readonly dentitionTreatmentXRayslImagesConfigs = dentitionTreatmentXRayslImagesConfigs;
  @ViewChild('generalTreatmentInfo') generalTreatmentInfo: OrthoGeneralTreatmentInfoComponent;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly orthoFormService: PermanentDentitionTreatmentService,
    private readonly formGuardService: FormGuardService,
    spinnerService: SpinnerService,
    changeDetector: ChangeDetectorRef,
    globalErrorHandlerService: GlobalErrorHandlerService,
    router: Router,
    activatedRoute: ActivatedRoute,
    snackBarService: SnackBarService,
    elementRef: ElementRef,
    zone: NgZone,
    wsHelper: WsHelperService,
    patientService: PatientService
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      changeDetector,
      globalErrorHandlerService,
      wsHelper,
      patientService,
      orthoFormService
    );
    this.formType = FormType.ORTHO_PD_TREATMENT;
    this.treatmentType = TreatmentType.ORTHO_PD_TREATMENT;
  }

  ngOnInit(): void {
    this.configureForm(
      this.createTreatmentFormGroup(),
      {
        controlNamesForFullValidation: [OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName]
      },
      {
        controlNamesForFullValidation: [
          OrthoSharedGeneralInfoComponent.careProvidedEndDate,
          OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName,
          OrthoSharedGeneralInfoComponent.practitionerIdControlName,
          'abandonReasonMore',
          'complicationMore',
          this.fistulaMainField,
          ...fistulaFields
        ]
      }
    );
    if (!this.isReadonlyView) {
      this.getPatientTreatmentCenters();
    }
    this.fillInEditMode();
  }

  protected createTreatmentFormGroup(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        [OrthoSharedGeneralInfoComponent.getOrthoCareProvidedDate(OrthoStage.TREATMENT)]: [],
        [OrthoSharedGeneralInfoComponent.careProvidedEndDate]: [null, [StxValidators.required]],
        [OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName]: [null, [StxValidators.required]],
        [OrthoSharedGeneralInfoComponent.numberOfSessionsControlName]: [
          null,
          [
            Validators.min(0),
            Validators.max(30),
            StxValidators.isInShortTypeRange,
            invalidIf(
              value => parseFloat(value) === 0 && !OrthoFormUtils.discontinuedSelected(this.treatmentFormGroup),
              'error.incomplete'
            ),
            StxValidators.required
          ]
        ],
        [OrthoSharedGeneralInfoComponent.practitionerIdControlName]: [
          null,
          [
            StxValidators.required,
            selectionInvalidIf(
              () =>
                !!this.generalTreatmentInfo?.sharedGeneralInfo &&
                !!this.generalTreatmentInfo.sharedGeneralInfo.isSelectedPractitionerIdInvalid
            )
          ]
        ],
        [OrthoSharedGeneralInfoComponent.surgeonIdControlName]: [
          null,
          [
            StxValidators.required,
            selectionInvalidIf(
              () =>
                !!this.generalTreatmentInfo?.sharedGeneralInfo && !!this.generalTreatmentInfo.sharedGeneralInfo.isSelectedSurgeonIdInvalid
            )
          ]
        ],
        fistula: [null, StxValidators.required],
        fistulaSoftPalate: [],
        fistulaHardPalate: [],
        fistulaAlveolarRidge: [],
        fistulaPreSurgery: [],
        oralHealth: [null, StxValidators.required],
        oralHealthMore: [],
        complication: [null, StxValidators.required],
        complicationMore: [null, requiredIfValidator(() => OrthoFormUtils.complicationsOccurredSelected(this.treatmentFormGroup))],
        isAbandoned: false,
        abandonReasonMore: [null, requiredIfValidator(() => OrthoFormUtils.discontinuedSelected(this.treatmentFormGroup))],
        [ParentOrderName.ORTHO_FRONTAL_POST]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_LEFT_POST]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_RIGHT_POST]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_BASAL_POST]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_FRONTAL_SMILE_POST]: [],
        [ParentOrderName.ORTHO_INTRA_ORAL_FRONTAL_POST]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_LEFT_OCCLUSION_POST]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_RIGHT_OCCLUSION_POST]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_MAXILLARY_ARCH_POST]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_MANDIBULAR_ARCH_POST]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_CEPHALOMETRIC_RADIOGRAPH_POST]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_PANORAMIC_RADIOGRAPH_POST]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_CLEFT_SITE_OCCLUSAL_OR_PERIAPICAL_RADIOGRAPH_POST]: []
      },
      {
        validators: [atLeastOneRequired(fistulaFields, 'fistulaFields', this.fistulaMainField, 1)]
      }
    );
  }

  private fillInEditMode() {
    if (this.formMode === FormMode.NEW) {
      this.callFetchAdditionalDataForSetUp();
      return;
    }

    if (this.orthoFormModel) {
      this.setTreatmentData(this.orthoFormModel);
      return;
    }

    this.subSink.sink = this.activatedRoute.params.subscribe(params => {
      this.wsHelper
        .callWithSpinner(this.orthoFormService.get(params.id), { redirectOn404StatusCode: true })
        .subscribe(permanentDentitionTreatment => {
          this.formGuardService.redirectTreatmentFormToCorrectModeIfRequired(
            permanentDentitionTreatment,
            this.formMode,
            this.activatedRoute.snapshot
          );
          this.setTreatmentData(permanentDentitionTreatment);
        });
    });
  }

  protected getTreatmentDataToSave() {
    return {
      ...this.treatmentFormGroup.value,
      id: this.orthoFormModel ? this.orthoFormModel.id : null,
      patientId: this.patient.id,
      newFiles: FormMediaUtils.extractNewMediaFromFormGroup([this.treatmentFormGroup], dentitionTreatmentParentOrderNames)
    };
  }

  protected override refreshValidityStateForDiscontinue(): boolean {
    const hasFistulaFieldNotBeenTouched = this.formGroup.get(this.fistulaMainField).value === null;
    this._discontinueConfig.controlNamesForFullValidation = this.getRequiredValidationControlNames(
      hasFistulaFieldNotBeenTouched,
      this.formGroup,
      this._discontinueConfig.controlNamesForFullValidation
    );

    return super.refreshValidityStateForDiscontinue();
  }

  protected override beforeValidationSync(): void {
    this.generalTreatmentInfo.sharedGeneralInfo.checkPractitionerAndSurgeonValidity();
    this.formGroup.markAsUntouched();
    this.changeDetector.detectChanges();
  }

  protected callDelete(id: number): Observable<void> {
    return this.orthoFormService.delete(id);
  }

  protected callSave(data: DentitionTreatment): Observable<DentitionTreatment> {
    return this.orthoFormService.save(data);
  }

  protected callDiscontinue(data: DentitionTreatment): Observable<DentitionTreatment> {
    return (this.orthoFormService as OrthoTreatmentFormApi<DentitionTreatment>).discontinue(data);
  }

  protected callSubmit(data: DentitionTreatment): Observable<DentitionTreatment> {
    return this.orthoFormService.submit(data);
  }

  protected callUnlock(id: number): Observable<void> {
    return this.orthoFormService.unlock(id);
  }
  protected getEditRoute(treatmentId: number): string {
    return `${orthoRoute}/${permanentDentitionTreatmentAppRoute}/edit/${treatmentId}`;
  }

  protected getPatientId(): PatientId {
    return this.patient.id;
  }

  protected getTreatmentId(): TreatmentId {
    return this.orthoFormModel.id;
  }

  protected getViewRoute(treatmentId: number): string {
    return `${orthoRoute}/${permanentDentitionTreatmentAppRoute}/${treatmentId}`;
  }
  protected setTreatmentData(data: DentitionTreatment): void {
    this.orthoFormModel = data;
    this.treatmentFormGroup.patchValue(this.orthoFormModel);
    this.treatmentFormGroup.patchValue(FormMediaUtils.getMediaForFormGroup(this.orthoFormModel, dentitionTreatmentParentOrderNames));

    if (this.formMode !== FormMode.READONLY) {
      this.callFetchAdditionalDataForSetUp();
    }
  }
}
