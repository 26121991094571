<stx-resources-navigation selectedItemId="{{ NavigationSubTabEnum.RESOURCES_VIDEOS }}"></stx-resources-navigation>
<stx-generic-page>
  <stx-page-header [name]="headerText | translate"></stx-page-header>

  <div class="row-until-lt-sm-container gap-30">
    <div class="simulator-link-container">
      <div class="p-b-24">
        {{ descriptionText | translate }}
        (<a [href]="simulatorUrl" target="_blank">{{ simulatorLink }}</a
        >)
      </div>
      <mat-divider class="m-b-25"></mat-divider>
      <div class="video-sections row-until-lt-md-container">
        <div *ngFor="let section of videosSections; let i = index" class="links-section p-l-20">
          <div class="m-t-24 flex-row-container align-center">
            <h3 class="m-0">{{ section.section | translate }}</h3>
            <mat-icon class="m-l-8">videocam</mat-icon>
          </div>
          <div class="m-0 flex-column-container" *ngFor="let subsection of section.subsections">
            <h4 *ngIf="subsection.subsection">{{ subsection.subsection | translate }}</h4>
            <div class="m-b-24" *ngIf="!subsection.subsection"></div>
            <a
              class="video-link flex-column-container"
              *ngFor="let item of subsection.name; let i = index"
              href="{{ subsection.link[i] }}"
              target="_blank"
              >{{ item | translate }}</a
            >
          </div>
          <mat-divider *ngIf="i % 3 === 0 && i < videosSections.length - 3" class="separated-divider"></mat-divider>
        </div>
      </div>
    </div>
  </div>
</stx-generic-page>
