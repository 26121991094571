import { AbstractControlOptions, AsyncValidatorFn, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

export interface DateControlOptions extends AbstractControlOptions {
  minDate?: () => Observable<moment.Moment>;
  maxDate?: () => Observable<moment.Moment>;
}

function isDateControlOptions(object): object is DateControlOptions {
  const options = object as DateControlOptions;
  return options.minDate !== undefined || options.maxDate !== undefined;
}

export class DateFormControl extends UntypedFormControl {
  minDate?: () => Observable<moment.Moment>;
  maxDate?: () => Observable<moment.Moment>;

  constructor(
    formState?: any,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | DateControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(formState, validatorOrOpts, asyncValidator);
    if (isDateControlOptions(validatorOrOpts)) {
      this.minDate = validatorOrOpts?.minDate;
      this.maxDate = validatorOrOpts?.maxDate;
    }
  }
}
