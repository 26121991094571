import { Component, Input, NgModule, OnInit } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { ReportDownloadOptions, ReportDownloadTypes, ReportType } from '../report-filters/models/report-filters.model';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ReportService } from '../report-filters/services/report.service';
import { ReportRequestService } from '../report-filters/services/report-request.service';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { Observable } from 'rxjs';

interface ReportDownloadOption {
  label: string;
  type: ReportDownloadTypes;
}

@Component({
  selector: 'stx-report-downloader',
  templateUrl: './report-downloader.component.html',
  styleUrls: ['./report-downloader.component.scss']
})
export class ReportDownloaderComponent extends BaseComponent implements OnInit {
  private static nutritionReportZippedFileName: string = 'nutrition_report.zip';

  private static downloadOptionsTranslationMap: Record<ReportDownloadTypes, string> = {
    [ReportDownloadTypes.EXCEL]: 'reports.download_option.excel',
    [ReportDownloadTypes.EXCEL_ADMIN]: 'reports.download_option.excel_admin',
    [ReportDownloadTypes.EXCEL_ADMIN_ID]: 'reports.download_option.excel_admin_id',
    [ReportDownloadTypes.EXCEL_WITH_AGE_GROUPS]: 'reports.download_option.excel.age_groups',
    [ReportDownloadTypes.EXCEL_ADMIN_WITH_AGE_GROUPS]: 'reports.download_option.excel_admin.age_groups',
    [ReportDownloadTypes.EXCEL_ADMIN_ID_WITH_AGE_GROUPS]: 'reports.download_option.excel_admin_id.age_groups'
  };

  @Input() reportDownloadOptions: Partial<ReportDownloadOptions>;
  @Input() reportType: ReportType;
  @Input() enabled = true;

  availableDownloadOptions: ReportDownloadOption[] = [];

  constructor(
    private reportService: ReportService,
    private reportRequestService: ReportRequestService,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setDownloadOptions();
  }

  downloadReport(downloadOption: ReportDownloadOption) {
    const csvOrZippedReportDownloadCall: Observable<ArrayBuffer | Blob> = this.isZippedReport(downloadOption.type)
      ? this.reportRequestService.downloadZippedReport(
          this.reportService.model,
          this.reportType,
          downloadOption.type,
          ReportDownloaderComponent.nutritionReportZippedFileName
        )
      : this.reportRequestService.downloadCsvReport(this.reportService.model, this.reportType, downloadOption.type);

    this.subSink.sink = this.wsHelperService.callWithSpinner(csvOrZippedReportDownloadCall).subscribe();
  }

  private isZippedReport(downloadOption: ReportDownloadTypes): boolean {
    const zippedFileTypes = [
      ReportDownloadTypes.EXCEL_WITH_AGE_GROUPS,
      ReportDownloadTypes.EXCEL_ADMIN_WITH_AGE_GROUPS,
      ReportDownloadTypes.EXCEL_ADMIN_ID_WITH_AGE_GROUPS
    ];

    return zippedFileTypes.includes(downloadOption);
  }

  private setDownloadOptions(): void {
    if (this.reportDownloadOptions) {
      this.availableDownloadOptions = Object.entries(this.reportDownloadOptions)
        .filter(([, optionIsEnabled]) => optionIsEnabled)
        .map(([downloadOptionKey]) => {
          return {
            label: ReportDownloaderComponent.downloadOptionsTranslationMap[downloadOptionKey],
            type: downloadOptionKey as ReportDownloadTypes
          };
        });
    }
  }
}
@NgModule({
  declarations: [ReportDownloaderComponent],
  imports: [MatMenuModule, CommonModule, TranslateModule, MatIconModule, MatButtonModule],
  exports: [ReportDownloaderComponent]
})
export class ReportDownloaderComponentModule {}
