<div class="validation-message text-error p-l-3">
  <ng-container *ngFor="let key of stxErrorMessageKeys">
    {{ key | translate }}
  </ng-container>

  <ng-container *ngIf="this.standardErrorsKeys.includes('min')">
    {{ 'error.min' | translate }}
  </ng-container>

  <ng-container *ngIf="standardErrorsKeys.includes('max')">
    {{ 'error.max' | translate }}
  </ng-container>

  <ng-container *ngIf="standardErrorsKeys.includes('required')">
    {{ 'error.field_empty' | translate }}
  </ng-container>

  <ng-container *ngIf="standardErrorsKeys.includes('minlength')">
    {{ 'error.min_length' | translate }}
  </ng-container>

  <ng-container *ngIf="standardErrorsKeys.includes('maxlength')">
    {{ 'error.max_length' | translate }}
  </ng-container>

  <ng-container *ngIf="standardErrorsKeys.includes('email')">
    {{ 'error.email' | translate }}
  </ng-container>

  <ng-container *ngIf="standardErrorsKeys.includes('pattern')">
    {{ 'error.pattern' | translate }}
  </ng-container>
</div>
