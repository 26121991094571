export enum OrthodonticInterventions {
  PresurgicalOrthopedicsType_0 = 'PresurgicalOrthopedicsType_0',
  PresurgicalOrthopedicsType_1 = 'PresurgicalOrthopedicsType_1',
  PresurgicalOrthopedicsType_2 = 'PresurgicalOrthopedicsType_2',
  PresurgicalOrthopedicsType_3 = 'PresurgicalOrthopedicsType_3',
  MixedDentitionType_0 = 'MixedDentitionType_0',
  MixedDentitionType_1 = 'MixedDentitionType_1',
  MixedDentitionType_2 = 'MixedDentitionType_2',
  PermanentDentitionType_0 = 'PermanentDentitionType_0',
  PermanentDentitionType_1 = 'PermanentDentitionType_1',
  DentitionTypeOther = 'DentitionTypeOther'
}

export enum OrthodonticInterventionTypes {
  presurgicalInfantOrthopedics = 'PRESURGICAL_INFANT_ORTHOPEDICS',
  mixedDentition = 'MIXED_DENTITION',
  permanentDentition = 'PERMANENT_DENTITION'
}

export interface OrthoRoutes {
  treatment: string;
  assessment: string;
  midAssessment?: string;
}

export interface OrthodonticTreatmentReportRecord {
  interventionType: OrthodonticInterventionTypes;

  id: number;
  age: number;
  evaluationDate?: string;
  country: string;
  createDate: string;
  crm: string;
  dateOfBirth: string;
  district: string;
  gender: string;
  hardLeftCleftPalate: string;
  hardRightCleftPalate: string;
  leftAlveolus: string;
  leftCleftLip: string;
  maxillaryPlate: string;
  mobile: string;
  namAppliance: string;
  nasalStent: string;
  otherDevice: string;
  otherPhone: string;
  parentOrGuardian: string;
  partner: string;
  patientCountry: string;
  patientDistrict: string;
  photo: string;
  photoId: number;
  patientId: number;
  patientLocalName: string;
  patientName: string;
  patientProvince: string;
  patientStreetAddress: string;
  patientTownVillageCity: string;
  patientZipPostalCode: string;
  phone: string;
  province: string;
  referringOrganization: string;
  rightAlveolus: string;
  rightCleftLip: string;
  softCleftPalate: string;
  submitDate: string;
  tapingAcrossLip: string;
  treatmentCenter: string;
  weight: number;

  // assessment
  assessmentId: number;
  assessmentStatus: number;
  assessmentSubmitted?: string;
  assessmentCreated: string;
  assessmentDate;
  assessmentOrthodontistName: string;
  assessmentOrthodontistNameLoc: string;
  assessmentInScope: boolean;
  assessmentTc: number;

  // treatment
  treatmentId?: number;
  treatmentStatus?: number;
  treatmentCreated?: string;
  treatmentSubmitted?: string;
  treatmentStartDate?: string;
  treatmentEndDate?: string;
  treatmentOrthodontistName?: string;
  treatmentSurgeonName?: string;
  treatmentOrthodontistNameLoc?: string;
  treatmentSurgeonNameLoc?: string;
  treatmentTc?: number;
  treatmentInScope?: boolean;

  // mid assessment
  reimbursableMidId?: number;
  reimbursableMidStatus?: number;
  reimbusrableMidCreated?: string;
  reimbursableMidSubmitted?: string;
  reimbursableMidOrthodontistName?: string;
  reimbursableMidOrthodontistNameLoc?: string;
  midCount: string;
  reimbursableMidEvaluationDate?: string;
  reimbursableMidTc?: number;
  reimbursableMidInScope?: boolean;
}
