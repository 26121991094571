import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { InfoService } from '@/src/app/shared/services/info.service';
import { formatDateTimeInLocalTimeZone } from '@/src/app/utils/date.utils';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  selector: 'stx-release-info',
  templateUrl: './release-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReleaseInfoComponent extends BaseComponent implements OnInit {
  private readonly infoService: InfoService = inject(InfoService);
  private readonly wsHelper: WsHelperService = inject(WsHelperService);
  private readonly changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  downtimeEnd?: string;
  downtimeStart?: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subSink.sink = this.wsHelper.call(this.infoService.getUpcomingReleaseInfo()).subscribe({
      next: releaseInfo => {
        if (!releaseInfo) {
          this.downtimeStart = null;
          this.downtimeEnd = null;
        } else {
          this.downtimeStart = formatDateTimeInLocalTimeZone(releaseInfo.downtimeStart);
          this.downtimeEnd = formatDateTimeInLocalTimeZone(releaseInfo.downtimeEnd);
        }

        this.changeDetector.detectChanges();
      }
    });
  }
}
