import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brackets'
})
export class BracketsPipe implements PipeTransform {
  transform(text: string | null): string {
    return text ? `(${text})` : text;
  }
}

@NgModule({
  declarations: [BracketsPipe],
  exports: [BracketsPipe]
})
export class BracketsPipeModule {}
