import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { PatientDataForReleaseForm } from '@shared/components/patient-document-upload/patient-release-form.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'stx-patient-release-form-block',
  templateUrl: './patient-release-form-block.component.html',
  styleUrls: ['./patient-release-form-block.component.scss']
})
export class PatientReleaseFormBlockComponent extends BaseComponent {
  @Input() patientDataProvider: () => Observable<PatientDataForReleaseForm> | null;
  @Input() patientDataMatchesOnlineReleaseFormData: boolean | null;
  @Input() patientDataMatchesOfflineReleaseFormData: boolean | null;

  greaterThanMedium: boolean;

  constructor(mediaObserver: MediaObserver, changeDetector: ChangeDetectorRef) {
    super();
    this.subSink.sink = mediaObserver.asObservable().subscribe(changes => {
      this.greaterThanMedium = changes.map(item => item.mqAlias).includes('gt-md');
      changeDetector.detectChanges();
    });
  }
}
