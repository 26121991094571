import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxGroupWithTextFieldComponent } from '@shared/components/form/components/checkbox-group-with-text-field/checkbox-group-with-text-field.component';
import { ErrorMessageWrapperComponent } from '@shared/components/form/components/error-message-wrapper/error-message-wrapper.component';
import { ValidationMessageModule } from '@shared/components/form/components/validation-message/validation-message.component';
import { TreatmentStatusIndicatorComponentModule } from '@shared/components/treatment-status-indicator/treatment-status-indicator.component';
import { FormsCommonsModule } from '@shared/modules/forms-commons/forms-commons.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from '../../modules/material/material.module';
import { ChipsComponentModule } from '../chips/chips.component';
import { ImageRotatorModule } from '../image-rotator/image-rotator.module';
import { UppyModule } from '../uppy/uppy.module';
import { ActionButtonsComponent } from './components/action-buttons/action-buttons.component';
import { AudioVideoComponent } from './components/audio-video/audio-video.component';
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DateComponent } from './components/date/date.component';
import { FileComponent } from './components/file/file.component';
import { GenericFormComponent } from './components/generic-form/generic-form.component';
import { ImageGroupComponent } from './components/image-group/image-group.component';
import { ImageComponent } from './components/image/image.component';
import { InputComponent } from './components/input/input.component';
import { PatientFormsHeadersContainerComponent } from './components/patient-forms-headers-container/patient-forms-headers-container.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { SelectComponent } from './components/select/select.component';
import { SubmitButtonComponent } from './components/submit-button/submit-button.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TimeSelectComponent } from './components/time-select/time-select.component';
import { ExpandableFormWrapperComponent } from './expandable-form-wrapper/expandable-form-wrapper.component';
import { LocalNamePipeModule } from '@shared/pipes/local-name.pipe';
import { ReadonlyDateControlComponent } from './components/readonly-date-control/readonly-date-control.component';

@NgModule({
  declarations: [
    ActionButtonsComponent,
    AudioVideoComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    DateComponent,
    ErrorMessageWrapperComponent,
    ExpandableFormWrapperComponent,
    FileComponent,
    GenericFormComponent,
    ImageComponent,
    ImageGroupComponent,
    InputComponent,
    PatientFormsHeadersContainerComponent,
    RadioGroupComponent,
    SelectComponent,
    SubmitButtonComponent,
    TextareaComponent,
    ReadonlyDateControlComponent,
    TimeSelectComponent,
    CheckboxGroupWithTextFieldComponent
  ],
  imports: [
    ChipsComponentModule,
    CommonModule,
    FlexLayoutModule,
    FormsCommonsModule,
    GeneralCommonsModule,
    ImageRotatorModule,
    MaterialModule,
    NgxMaskModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    UppyModule,
    LocalNamePipeModule,
    ValidationMessageModule,
    TreatmentStatusIndicatorComponentModule
  ],
  exports: [
    ActionButtonsComponent,
    AudioVideoComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    DateComponent,
    ErrorMessageWrapperComponent,
    ExpandableFormWrapperComponent,
    FileComponent,
    GenericFormComponent,
    ImageComponent,
    ImageGroupComponent,
    InputComponent,
    PatientFormsHeadersContainerComponent,
    RadioGroupComponent,
    SelectComponent,
    SubmitButtonComponent,
    TextareaComponent,
    ReadonlyDateControlComponent,
    TimeSelectComponent,
    CheckboxGroupWithTextFieldComponent
  ]
})
export class FormModule {}
