import { ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { DatepickerI18nService } from '@shared/services/localisation/datepicker-i18n.service';
import { environment } from '@src/environments/environment';
import { imageViewerRoute, maintenancePageRoute } from '@utils/routing.utils';
import { filter } from 'rxjs/operators';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

export const supportLanguages = ['English', 'Español', 'Français', 'Indonesia', 'Português', 'Tiếng Việt', '中文'];

declare let gtag: Function;

@Component({
  selector: 'stx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends BaseComponent implements OnInit {
  isLoggedIn: boolean;
  hideNavigation = false;
  showSpinner = false;
  private routesWithHiddenNavigation = [imageViewerRoute, maintenancePageRoute];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private currentUserService: CurrentUserService,
    public spinnerService: SpinnerService,
    private changeRef: ChangeDetectorRef,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private datepickerI18nService: DatepickerI18nService
  ) {
    super();
    this.scrollToTop();
    this.changeLayoutOnRouterEvent();
  }

  ngOnInit() {
    this.initializeIcons();
    this.subSink.sink = this.currentUserService.getUserSubject().subscribe(user => {
      this.isLoggedIn = !!user;
    });
    this.subSink.sink = this.spinnerService.activeTasks.subscribe(activeTask => {
      this.showSpinner = activeTask;
      this.changeRef.detectChanges();
    });
    this.subSink.sink = this.datepickerI18nService.followLocaleUpdatesAndGetSubscription();
    this.setupGoogleAnalyticsIfApplicable();
  }

  private scrollToTop() {
    this.subSink.sink = this.router.events
      .pipe(filter((e: unknown): e is Scroll => e instanceof Scroll))
      .subscribe((scrollEvent: Scroll) => {
        if (scrollEvent.position) {
          this.viewportScroller.scrollToPosition(scrollEvent.position);
        } else if (scrollEvent.anchor) {
          this.viewportScroller.scrollToAnchor(scrollEvent.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }

  private changeLayoutOnRouterEvent() {
    this.subSink.sink = this.router.events.pipe(filter((e: any) => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      this.hideNavigation = false;
      const splitUrl = e.url.split('/');
      if (splitUrl.length > 1) {
        const firstRoute = splitUrl[1].split('?')[0];
        this.hideNavigation = this.routesWithHiddenNavigation.includes(firstRoute);
      }
    });
  }

  private initializeIcons(): void {
    this.matIconRegistry.addSvgIcon('upload', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/upload.svg'));
    this.matIconRegistry.addSvgIcon('fb', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/post-facebook.svg'));
    this.matIconRegistry.addSvgIcon('insta', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/post-instagram.svg'));
    this.matIconRegistry.addSvgIcon('twitter', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/post-twitter.svg'));
    this.matIconRegistry.addSvgIcon('snap', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/snapchat.svg'));
    this.matIconRegistry.addSvgIcon('yt', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/youtube.svg'));
    this.matIconRegistry.addSvgIcon(
      'lang-switcher',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/language-switcher.svg')
    );
    this.matIconRegistry.addSvgIcon('static-doc', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/list_alt-24px.svg'));
    this.matIconRegistry.addSvgIcon('creation-date', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/creation-date.svg'));
    this.matIconRegistry.addSvgIcon('modified-date', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/modified-date.svg'));
    this.matIconRegistry.addSvgIcon(
      'submission-date',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/submission-date.svg')
    );
    this.matIconRegistry.addSvgIcon('tablet-sign', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/tablet-sign.svg'));
    this.matIconRegistry.addSvgIcon('download', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/download.svg'));
    this.matIconRegistry.addSvgIcon('upload-alt', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/upload-alt.svg'));
    this.matIconRegistry.addSvgIcon('contract', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/contract.svg'));
    this.matIconRegistry.addSvgIcon('printer', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/printer.svg'));
    this.matIconRegistry.addSvgIcon('unlink', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/unlink.svg'));
    this.matIconRegistry.addSvgIcon('today', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/today.svg'));
    this.matIconRegistry.addSvgIcon('pin', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pin.svg'));
  }

  private setupGoogleAnalyticsIfApplicable() {
    if (!!environment.googleAnalyticsMeasurementId)
      this.subSink.sink = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
        gtag('config', environment.googleAnalyticsMeasurementId, {
          page_path: (event as NavigationEnd).urlAfterRedirects
        });
      });
  }
}
