import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { StatisticsTableComponent } from './components/statistics-table/statistics-table.component';
import { MaterialModule } from '../../modules/material/material.module';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { StatisticsPlotComponent } from '@shared/modules/statistics/components/statistics-plot/statistics-plot.component';
import { StatisticsSubmittedOnlyComponent } from './components/statistics-sumbitted-only/statistics-submitted-only.component';

@NgModule({
  declarations: [StatisticsComponent, StatisticsPlotComponent, StatisticsTableComponent, StatisticsSubmittedOnlyComponent],
  imports: [CommonModule, FlexModule, FlexLayoutModule, MaterialModule, TranslateModule],
  exports: [StatisticsComponent]
})
export class StatisticsModule {}
