<ng-container *ngIf="!!roles && roles?.length > 0">
  <div class="form-element-group-column" [formGroup]="formGroup">
    <label for="userRoles" class="form-element-label">{{ 'user.user_role' | translate }}</label>
    <stx-chips
      [class.display-none]="formGroup.get(formControlName)?.value?.length === 0"
      [itemsList]="formGroup.get(formControlName).value"
      [ariaLabel]="formControlName | translate"
      [removable]="roles?.length > 1"
      (removeCallback)="onRemoveChip($event, formGroup.get(formControlName))"
    ></stx-chips>
    <stx-autocomplete-multi-select
      *ngIf="roles"
      [formControlName]="formControlName"
      [itemList]="roles"
      id="userRoles"
    ></stx-autocomplete-multi-select>
  </div>
</ng-container>
