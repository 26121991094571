import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component, Input } from '@angular/core';
import { SpeechTreatment } from '../models/speech-treatment.model';

@Component({
  selector: 'stx-treatment-outcomes',
  templateUrl: './treatment-outcomes.component.html'
})
export class TreatmentOutcomesComponent extends BaseFormElementComponent {
  @Input() speechTreatment: SpeechTreatment;
  constructor() {
    super();
  }
}
