import { Component } from '@angular/core';
import { environment } from '@src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

@Component({
  selector: 'stx-password-expiry',
  templateUrl: './password-expiry.component.html',
  styleUrls: ['password-expiry.component.scss']
})
export class PasswordExpiryComponent {
  constructor(private currentUserService: CurrentUserService, private translateService: TranslateService) {}

  shouldShowPasswordExpiryMessage(): boolean {
    return this.getPasswordExpiryRemainingDays() <= environment.passwordExpiryNotificationThreshold;
  }

  getPasswordExpiryRemainingDays(): number {
    const remainingDays = this.currentUserService?.getCurrentUser()?.passwordExpiryRemainingDays;
    return remainingDays === undefined || remainingDays < 0 ? 0 : remainingDays;
  }

  getPasswordExpiryMessage(): string {
    let remainingDays = this.getPasswordExpiryRemainingDays();
    let message: string;

    this.translateService
      .get(
        remainingDays > environment.passwordExpiryPopupThreshold
          ? 'dashboard.password_expiry_message_consider_update'
          : 'dashboard.password_expiry_message'
      )
      .subscribe((translation: string) => {
        message = translation;
      });

    if (remainingDays > environment.passwordExpiryPopupThreshold) {
      return message;
    } else {
      return `${message} ${remainingDays}`;
    }
  }
}
