<stx-generic-page>
  <div [hidden]="!print">
    <img src="assets/images/SmileTrain_CMYK_Primary_logo_fullcolor.svg" class="st-print-logo" alt="Smile Train PDF Logo" />
  </div>
  <div class="flex-column-container">
    <stx-patient-details-block
      *ngIf="patient && countries && genders && (formMode !== FormMode.NEW || formType !== FormType.PATIENT) && !print"
      [patient]="patient.patientDetailsBlockPatient"
      [countries]="countries"
      [mode]="PatientDetailsBlockMode.FORM_ON_TOP"
      (releaseFormUpdated)="refreshPatient()"
      [genders]="genders"
      class="display-block m-b-20"
    ></stx-patient-details-block>
    <ng-container [ngSwitch]="formType">
      <ng-template [ngSwitchCase]="FormType.SURGERY_FOLLOW_UP">
        <stx-surgical-follow-up
          *ngIf="patient"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-surgical-follow-up>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.SURGICAL_INTERVENTION">
        <stx-surgical-intervention
          *ngIf="patient"
          [patient]="patient"
          [formMode]="formMode"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-surgical-intervention>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.SPEECH_ASSESSMENT">
        <stx-speech-assessment
          *ngIf="patient"
          [patient]="patient"
          [formMode]="formMode"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-speech-assessment>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.BORESCOPE">
        <stx-borescope
          [formMode]="formMode"
          [print]="print"
          [patient]="patient"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-borescope>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHOGNATHIC_TREATMENT">
        <stx-orthognathic-treatment
          *ngIf="patient"
          [patient]="patient"
          [formMode]="formMode"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-orthognathic-treatment>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_PSIO_ASSESSMENT">
        <stx-psio-assessment
          *ngIf="patient"
          [formMode]="formMode"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-psio-assessment>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_PSIO_TREATMENT">
        <stx-psio-treatment
          *ngIf="patient"
          [formMode]="formMode"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-psio-treatment>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_MD_ASSESSMENT">
        <stx-mixed-dentition-assessment
          *ngIf="patient"
          [formMode]="formMode"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-mixed-dentition-assessment>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_MD_TREATMENT">
        <stx-mixed-dentition-treatment
          *ngIf="patient"
          [formMode]="formMode"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-mixed-dentition-treatment>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_PD_ASSESSMENT">
        <stx-permanent-dentition-assessment
          *ngIf="patient"
          [formMode]="formMode"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-permanent-dentition-assessment>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_PD_TREATMENT">
        <stx-permanent-dentition-treatment
          *ngIf="patient"
          [formMode]="formMode"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-permanent-dentition-treatment>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_PD_MID_ASSESSMENT">
        <stx-permanent-dentition-mid-assessment
          *ngIf="patient"
          [formMode]="formMode"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        >
        </stx-permanent-dentition-mid-assessment>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.ORTHO_MD_MID_ASSESSMENT">
        <stx-mixed-dentition-mid-assessment
          *ngIf="patient"
          [formMode]="formMode"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-mixed-dentition-mid-assessment>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.SPEECH_TREATMENT">
        <stx-speech-treatment
          *ngIf="patient"
          [formMode]="formMode"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-speech-treatment>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.NUTRITION_ASSESSMENT">
        <stx-nutrition-assessment
          *ngIf="patient"
          [formMode]="formMode"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-nutrition-assessment>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.NUTRITION_FOLLOW_UP">
        <stx-nutrition-follow-up
          *ngIf="patient"
          [formMode]="formMode"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          (dataSaved)="refreshPatient()"
        ></stx-nutrition-follow-up>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.NUTRITION_SCREENING_HISTORICAL">
        <stx-nutrition-screening
          *ngIf="patient"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
        ></stx-nutrition-screening>
      </ng-template>
      <ng-template [ngSwitchCase]="FormType.NUTRITION_FOLLOW_UP_HISTORICAL">
        <stx-nutrition-follow-up-historical
          *ngIf="patient"
          [patient]="patient"
          [print]="print"
          [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
        ></stx-nutrition-follow-up-historical>
      </ng-template>
    </ng-container>
  </div>
</stx-generic-page>
