<div *ngIf="reportService.filtersReady$ | async">
  <form [formGroup]="reportService.reportFiltersFormGroup">
    <div [@filtersSection]="(reportService.filtersVisible$ | async) ? 'open' : 'closed'">
      <stx-form-panel>
        <stx-search-filter-columns
          [searchButtonText]="'report.filters.download_buttons.view_report'"
          [searchButtonDisabled]="reportService.reportFiltersFormGroup?.status !== 'VALID'"
          (searchButtonClicked)="applyFilters.emit(pagination)"
        >
          <ng-container *stxSearchFilterColumn>
            <ng-container *ngIf="reportService.config.filters.groupByScope">
              <div class="form-row">
                <stx-group-by-scope
                  (filterChange)="reportService.onFilterChange($event)"
                  controlName="groupByScope"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                ></stx-group-by-scope>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.nutritionStatuses">
              <stx-treatment-type-filter
                [formGroupReference]="reportService.reportFiltersFormGroup"
                [treatmentTree]="nutritionTypeAndStatusFilters"
                [statusesForEmptyStatusList]="[formStatus.SUBMITTED]"
                [expanded]="true"
              >
              </stx-treatment-type-filter>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="!isStatsReport">
              <ng-container *ngIf="reportService.config.filters.recordDate">
                <div class="form-row">
                  <stx-record-date-filter
                    [reportType]="reportService.config.type"
                    [recordGroupReference]="reportService.reportFiltersFormGroup.get('recordDate')"
                    [dateGroupReference]="reportService.reportFiltersFormGroup.get('dates')"
                    [recordTypeList]="reportDictionariesService.dictionaries.recordTypes"
                    (filterChange)="reportService.onFilterChange($event)"
                  >
                  </stx-record-date-filter>
                </div>
                <hr class="form-spaced-subsection-separator" />
              </ng-container>
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.statsType">
              <div class="form-row">
                <stx-stats-type
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="statsType"
                  (filterChange)="reportService.onFilterChange($event)"
                >
                </stx-stats-type>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="leftColumnInfoTemplate">
              <div class="form-row">
                <stx-notice>
                  <ng-container *ngTemplateOutlet="leftColumnInfoTemplate"></ng-container>
                </stx-notice>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.treatmentStatus && reportConfig.type !== 'NUTRITION_STATS'">
              <div class="form-row">
                <stx-report-treatment-status-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [reportType]="reportConfig.type"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('treatmentStatus')"
                ></stx-report-treatment-status-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.latestCases">
              <div class="form-row">
                <stx-latest-cases-filter
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="latestCases"
                  [latestCasesOptions]="reportDictionariesService.dictionaries.latestCases"
                  (filterChange)="reportService.onFilterChange($event)"
                >
                </stx-latest-cases-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.gender">
              <div class="form-row">
                <stx-gender-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  *ngIf="reportService.config.filters.gender"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('gender')"
                ></stx-gender-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.reviewers">
              <div class="form-row">
                <stx-report-multi-selection-filter
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  [dictionaryList]="reportDictionariesService.dictionaries.reviewers"
                  [labelTranslationNode]="'report.filters.reviewers.label'"
                  controlName="reviewers"
                >
                </stx-report-multi-selection-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.orthoCasesTypes">
              <div class="form-column">
                <h3>{{ 'treatment.presurgical_orthopedics' | translate }}</h3>
                <stx-sectioned-checkbox-group-filter
                  filterName="orthoCategories"
                  [enumToDicMapping]="reportDictionariesService.dictionaries.orthoPsioCategories"
                  formGroupReferenceName="orthoCasesTypes"
                  [enumValToTranslationLabel]="orthoStageToTranslationMap"
                ></stx-sectioned-checkbox-group-filter>
                <hr class="form-spaced-subsection-separator" />

                <h3>{{ 'treatment.mixed_dentition' | translate }}</h3>
                <stx-sectioned-checkbox-group-filter
                  filterName="orthoCategories"
                  [enumToDicMapping]="reportDictionariesService.dictionaries.orthoMdCategories"
                  formGroupReferenceName="orthoCasesTypes"
                  [enumValToTranslationLabel]="orthoStageToTranslationMap"
                >
                </stx-sectioned-checkbox-group-filter>
                <hr class="form-spaced-subsection-separator" />

                <h3>{{ 'treatment.permanent_dentition' | translate }}</h3>
                <stx-sectioned-checkbox-group-filter
                  filterName="orthoCategories"
                  [enumToDicMapping]="reportDictionariesService.dictionaries.orthoPdCategories"
                  formGroupReferenceName="orthoCasesTypes"
                  [enumValToTranslationLabel]="orthoStageToTranslationMap"
                >
                </stx-sectioned-checkbox-group-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.age && !reportService.config.filters.ageGroupsWithTreatmentStatuses">
              <div class="form-row">
                <stx-age-filter
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="age"
                  [isNutritionStatsReport]="reportConfig.type === 'NUTRITION_STATS'"
                  (filterChange)="reportService.onFilterChange($event)"
                ></stx-age-filter>
              </div>
              <ng-container *ngIf="reportConfig.type === 'NUTRITION_STATS'">
                <div class="form-row">
                  <stx-age-groups-filter
                    [formGroupReference]="reportService.reportFiltersFormGroup.get('ageGroups')"
                    [dictionaryOptions]="reportDictionariesService.dictionaries.ageGroups"
                  ></stx-age-groups-filter>
                </div>
              </ng-container>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.ageGroupsWithTreatmentStatuses">
              <div class="form-row">
                <stx-checkbox-tree-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [dictionariesTree]="reportDictionariesService.dictionaries.ageGroupsWithTreatmentStatuses"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('ageGroupsWithTreatmentStatuses')"
                  [rootOptionParamName]="'ageGroupsWithTreatmentStatuses'"
                ></stx-checkbox-tree-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.speechAssessmentStatuses">
              <div class="form-row">
                <stx-checkbox-tree-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [dictionariesTree]="reportDictionariesService.dictionaries.speechAssessmentStatuses"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('speechAssessmentStatuses')"
                  [rootOptionParamName]="'speechAssessmentStatuses'"
                ></stx-checkbox-tree-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.speechTreatmentStatuses">
              <div class="form-row">
                <stx-checkbox-tree-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [dictionariesTree]="reportDictionariesService.dictionaries.speechTreatmentStatuses"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('speechTreatmentStatuses')"
                  [rootOptionParamName]="'speechTreatmentStatuses'"
                ></stx-checkbox-tree-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.mohGrades">
              <div class="form-row">
                <stx-checkbox-group-filter
                  [dictionaryOptions]="reportDictionariesService.dictionaries.mohGrades"
                  [filterName]="'mohGrades'"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('mohGrades')"
                  [legendTranslationNode]="'report.filters.moh_grades.group_label'"
                  [useLayout]="'shrink'"
                  (filterChange)="reportService.onFilterChange($event)"
                >
                </stx-checkbox-group-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.orthodontists">
              <div class="form-row">
                <stx-report-multi-selection-filter
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  [controlName]="'orthodontists'"
                  [dictionaryList]="reportDictionariesService.dictionaries.orthodontists"
                  [labelTranslationNode]="'report.filters.orthodontist.label'"
                  [showChips]="true"
                >
                </stx-report-multi-selection-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.exclusions">
              <div class="form-row">
                <stx-report-exclusions-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('exclusions')"
                ></stx-report-exclusions-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
          </ng-container>

          <ng-container *stxSearchFilterColumn>
            <ng-container
              *ngIf="
                reportConfig.type === 'SURGERY_STATS' || reportConfig.type === 'NUTRITION_STATS' || reportConfig.type === 'SPEECH_STATS'
              "
            >
              <stx-record-date-filter
                [reportType]="reportService.config.type"
                [recordGroupReference]="reportService.reportFiltersFormGroup.get('recordDate')"
                [dateGroupReference]="reportService.reportFiltersFormGroup.get('dates')"
                [recordTypeList]="reportDictionariesService.dictionaries.recordTypes"
                [isStatsReport]="isStatsReport"
                (filterChange)="reportService.onFilterChange($event)"
              >
              </stx-record-date-filter>
            </ng-container>
            <ng-container *ngIf="reportConfig.filters.orthoRecordDates">
              <div class="form-row">
                <stx-radio-tree-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [dictionariesTree]="reportDictionariesService.dictionaries.orthoRecordDates"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('orthoRecordDates')"
                  [rootOptionParamName]="'recordDate'"
                  [showChevron]="false"
                ></stx-radio-tree-filter>
              </div>
              <div class="form-row m-b-10">
                <stx-date-filter
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('dates')"
                  [reportType]="reportConfig.type"
                  [useDefaultDates]="false"
                  (filterChange)="reportService.onFilterChange($event)"
                ></stx-date-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.date">
              <div class="form-column">
                <stx-date-filter
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('dates')"
                  [reportType]="reportConfig.type"
                  [useDefaultDates]="true"
                  (filterChange)="reportService.onFilterChange($event)"
                ></stx-date-filter>
                <mat-error class="stx-mat-error-align" *ngIf="hasFormError('dates', dateRangeToBigErrorName)">
                  <stx-error-message-wrapper>
                    {{ 'error.date_range' | translate }}
                  </stx-error-message-wrapper>
                </mat-error>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.periodType">
              <div class="form-row">
                <stx-period-filter
                  [allowPresets]="reportConfig.filters.periodPresets"
                  [reportType]="reportConfig.type"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('period')"
                  (periodTypeChange)="reportService.onFilterChange($event)"
                  (periodPresetsChange)="reportService.onFilterChange($event)"
                ></stx-period-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.surgicalIntervention">
              <div class="form-row">
                <stx-surgical-intervention-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('surgicalIntervention')"
                ></stx-surgical-intervention-filter>
              </div>
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.repairTypes">
              <div class="form-row">
                <stx-repair-types
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('repairTypes')"
                ></stx-repair-types>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.orthoTypes">
              <div class="form-row">
                <stx-treatment-type-filter
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  [treatmentTree]="orthoTypeAndStatusFilters"
                  [statusesForEmptyStatusList]="[formStatus.SUBMITTED]"
                  [expanded]="true"
                >
                </stx-treatment-type-filter>
              </div>
              <div class="form-row">
                <stx-report-boolean-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="allSelectedTreatmentTypes"
                  filterName="allSelectedTreatmentTypes"
                >
                  {{ 'reports.orthodontic.all_treatment_types' | translate }}
                </stx-report-boolean-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.speechAssessmentStates">
              <div class="form-row">
                <stx-speech-options-filter
                  [filterName]="'speechAssessmentStates'"
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('speechAssessmentStates')"
                ></stx-speech-options-filter>
              </div>
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.speechAssessmentRecommendations">
              <hr class="form-spaced-subsection-separator" />
              <div class="form-row">
                <stx-speech-options-filter
                  [filterName]="'speechAssessmentRecommendations'"
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('speechAssessmentRecommendations')"
                ></stx-speech-options-filter>
              </div>
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.speechTreatmentStates">
              <div class="form-row">
                <stx-speech-options-filter
                  [filterName]="'speechTreatmentStates'"
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('speechTreatmentStates')"
                ></stx-speech-options-filter>
              </div>
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.practitioners">
              <div class="form-row">
                <stx-report-multi-selection-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [dictionaryList]="reportDictionariesService.dictionaries.practitioners"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  [labelTranslationNode]="
                    reportConfig.type === 'SPEECH_TREATMENTS' || reportConfig.type === 'SPEECH_ASSESSMENTS'
                      ? 'practitioner.practitioner'
                      : 'practitioner.orthodontist'
                  "
                  [showChips]="true"
                  controlName="practitioners"
                >
                </stx-report-multi-selection-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.surgeons">
              <div class="form-row">
                <stx-report-multi-selection-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [dictionaryList]="reportDictionariesService.dictionaries.surgeons"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  [labelTranslationNode]="'report.filters.surgeon.select_label'"
                  controlName="surgeons"
                  [showChips]="true"
                >
                </stx-report-multi-selection-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.ageGroups && reportConfig.type !== 'NUTRITION_STATS'">
              <stx-age-groups-filter
                [formGroupReference]="reportService.reportFiltersFormGroup.get('ageGroups')"
                [dictionaryOptions]="reportDictionariesService.dictionaries.ageGroups"
              ></stx-age-groups-filter>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.nutritionists">
              <div class="form-row">
                <stx-report-multi-selection-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [dictionaryList]="reportDictionariesService.dictionaries.nutritionists"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  [labelTranslationNode]="'practitioner.nutritionist'"
                  controlName="nutritionists"
                  [showChips]="true"
                >
                </stx-report-multi-selection-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.deIdentification">
              <div class="form-row">
                <stx-report-boolean-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="deIdentification"
                  filterName="deIdentification"
                >
                  {{ 'reports.filters.de_identified' | translate }}
                </stx-report-boolean-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.orthoQaGrading">
              <div class="form-row">
                <stx-report-boolean-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="goodCaseExample"
                  filterName="goodCaseExample"
                >
                  {{ 'reports.filters.good_example' | translate }}
                </stx-report-boolean-filter>
              </div>
              <div class="form-row">
                <stx-report-boolean-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="needsImmediateAttention"
                  filterName="needsImmediateAttention"
                >
                  {{ 'reports.filters.needs_immediate_attention' | translate }}
                </stx-report-boolean-filter>
              </div>
              <div class="form-row">
                <stx-report-boolean-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="poorPatientSelection"
                  filterName="poorPatientSelection"
                >
                  {{ 'reports.filters.poor_patient_selection' | translate }}
                </stx-report-boolean-filter>
              </div>
              <div class="form-row">
                <stx-checkbox-group-filter
                  [dictionaryOptions]="reportDictionariesService.dictionaries.orthoPreGrading"
                  [filterName]="'preGrading'"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('preGrading')"
                  [useLayout]="'shrink'"
                  [legendTranslationNode]="'reports.filters.quality_assurance.pre_rank.title'"
                  (filterChange)="reportService.onFilterChange($event)"
                >
                </stx-checkbox-group-filter>
              </div>
              <div class="form-row">
                <stx-checkbox-group-filter
                  [dictionaryOptions]="reportDictionariesService.dictionaries.orthoPostGrading"
                  [filterName]="'postGrading'"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('postGrading')"
                  [useLayout]="'shrink'"
                  [legendTranslationNode]="'reports.filters.quality_assurance.post_rank.title'"
                  (filterChange)="reportService.onFilterChange($event)"
                >
                </stx-checkbox-group-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.qaOnly">
              <div class="form-row">
                <stx-report-boolean-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="qaOnly"
                  filterName="qaOnly"
                >
                  {{ 'reports.filters.qa_only' | translate }}
                </stx-report-boolean-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.photoTypes">
              <div class="form-row">
                <stx-checkbox-group-filter
                  [dictionaryOptions]="reportDictionariesService.dictionaries.photoTypes"
                  [filterName]="'photoTypes'"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('photoTypes')"
                  (filterChange)="reportService.onFilterChange($event)"
                >
                </stx-checkbox-group-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.reportedComplications">
              <div class="form-row">
                <stx-report-boolean-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="reportedComplications"
                  filterName="reportedComplications"
                >
                  {{ 'report.filters.reported_complications.label' | translate }}
                </stx-report-boolean-filter>
              </div>
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.referringOrganization">
              <div class="form-row">
                <stx-report-boolean-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="referringOrganization"
                  filterName="referringOrganization"
                >
                  {{ 'report.filters.referring_organization.label' | translate }}
                </stx-report-boolean-filter>
              </div>
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.includePhotos">
              <div class="form-row">
                <stx-report-boolean-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="includePhotos"
                  filterName="includePhotos"
                >
                  {{ 'report.filters.include_photos.label' | translate }}
                </stx-report-boolean-filter>
              </div>
            </ng-container>
            <ng-container *ngIf="reportService.config.filters.treatmentNotCompliant">
              <div class="form-row">
                <stx-report-boolean-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="treatmentNotCompliant"
                  filterName="treatmentNotCompliant"
                  labelTranslationPath="report.filters.referring_organization.label"
                >
                  {{ 'report.filters.treatment_not_compliant.label' | translate }}
                  <a [attr.href]="getSafetyAndQualityProtocolHref()" target="_blank" rel="noreferrer noopener">
                    {{ 'protocols_advisories_guidelines.safety_and_quality_protocol' | translate }}
                  </a>
                </stx-report-boolean-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.qualityAssurance">
              <div class="form-row">
                <stx-quality-assurance-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('qualityAssurance')"
                ></stx-quality-assurance-filter>
              </div>
            </ng-container>

            <ng-container *ngIf="reportService.config.filters.rejectedRecords">
              <div class="form-row">
                <stx-report-boolean-filter
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup"
                  controlName="rejectedRecords"
                  filterName="rejectedRecords"
                >
                  {{ 'report.filters.rejected_records.label' | translate }}
                </stx-report-boolean-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
          </ng-container>

          <ng-container *stxSearchFilterColumn>
            <ng-container *ngIf="reportService.config.filters.qaStages">
              <div class="form-row">
                <stx-checkbox-group-filter
                  [dictionaryOptions]="reportDictionariesService.dictionaries.qaStages"
                  [filterName]="'qaStages'"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('qaStages')"
                  [legendTranslationNode]="'report.filters.qa_stages.group_label'"
                  (filterChange)="reportService.onFilterChange($event)"
                >
                </stx-checkbox-group-filter>
              </div>
              <hr class="form-spaced-subsection-separator" />
            </ng-container>
            <ng-container>
              <div class="form-row">
                <stx-report-organizations-filter
                  *ngIf="reportService.config.filters.organizationsTree"
                  [orgTreeFilterData]="reportDictionariesService.dictionaries.organizationsTree"
                  class="m-b-15"
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('organizationsTree')"
                ></stx-report-organizations-filter>
              </div>
            </ng-container>
            <ng-container>
              <div class="form-row">
                <stx-report-geography-filter
                  *ngIf="reportService.config.filters.geography"
                  [geographyFilterData]="reportDictionariesService.dictionaries.organizationsTree"
                  (filterChange)="reportService.onFilterChange($event)"
                  [formGroupReference]="reportService.reportFiltersFormGroup.get('geographyTree')"
                ></stx-report-geography-filter>
              </div>
            </ng-container>
          </ng-container>
        </stx-search-filter-columns>
      </stx-form-panel>
    </div>
  </form>
</div>
