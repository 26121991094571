<div [ngClass]="{ 'make-space-for-error-message': shouldMakeSpaceForErrorMessage }">
  <div [formGroup]="formGroup" fxLayout="column" class="stx-form-field">
    <label class="form-label" [for]="controlName">
      <ng-container *ngIf="!print; else printed"> {{ label | translate }} {{ 'optional' | translate | if : isOptional }} </ng-container>
      <ng-template #printed>
        <span [ngStyle]="{ 'font-size': '1.25rem', 'line-height': '1.75rem' }"
          >{{ label | translate }} {{ 'optional' | translate | if : isOptional }}</span
        >
      </ng-template>
      <stx-tooltip [ngClass]="{ 'hide-on-print': print }" *ngIf="tooltip" [content]="tooltip"></stx-tooltip>
    </label>
    <mat-form-field [ngClass]="{ 'mat-textarea-no-padding': disablePadding, 'p-t-10': print }" appearance="outline">
      <textarea matInput [rows]="rows" [formControlName]="controlName" (change)="textareaChanged()" [id]="controlName"></textarea>
      <mat-error class="stx-mat-error-align" [ngClass]="{ 'slide-down-error-message ': disablePadding }">
        <stx-error-message-wrapper>
          <stx-validation-message [errors]="formControl.errors"></stx-validation-message>
        </stx-error-message-wrapper>
      </mat-error>
    </mat-form-field>
  </div>
</div>
