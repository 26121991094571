<h5 class="subtitle-4">
  <span>
    {{ label }}
  </span>
</h5>
<div class="p-0 user-select-none signature-pad-container">
  <mat-icon [class.visibility-hidden]="hideClearIcon" class="clear-icon" (click)="clearPad()">clear</mat-icon>
  <div class="signature-pad" [class.disabled-background]="disabled">
    <canvas class="flex-fill" #canvas></canvas>
  </div>
</div>
