<div class="m-50">
  <h1 class="m-b-40">{{ 'partner.paper_records' | translate }}</h1>
  <div class="data-table-container">
    <div class="data-table-wrapper">
      <table mat-table [dataSource]="paperRecordsData" aria-describedby="data-table-container">
        <ng-container matColumnDef="partner">
          <th mat-header-cell *matHeaderCellDef>{{ 'partner.partner' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.partner }}</td>
        </ng-container>

        <ng-container matColumnDef="treatmentCenter">
          <th mat-header-cell *matHeaderCellDef>{{ 'treatment_center.treatment_center' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.treatmentCenter }}</td>
        </ng-container>

        <ng-container matColumnDef="paperRecords">
          <th mat-header-cell *matHeaderCellDef>{{ 'partner.paper_records' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.paperRecords }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="flex-row-container align-justify layout-align-center m-t-24">
    <div class="bold">{{ 'total' | translate | uppercase }}</div>
    <div>{{ paperRecordsTotal }}</div>
  </div>
</div>
