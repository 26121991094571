<div [hidden]="!print">
  <img src="assets/images/SmileTrain_CMYK_Primary_logo_fullcolor.svg" class="st-print-logo" alt="Smile Train PDF Logo" />
</div>
<ng-container *ngIf="isDataLoaded">
  <div class="flex-column-container">
    <mat-card appearance="outlined" class="hide-on-print">
      <stx-patient-details-heading [patient]="patient" [genders]="genders" [countries]="countries"></stx-patient-details-heading>
    </mat-card>
    <stx-expandable-form-wrapper [formType]="FormType.PATIENT" [showStatus]="false" [print]="print">
      <stx-patient-readonly class="p-t-45" [patient]="patient"></stx-patient-readonly>
    </stx-expandable-form-wrapper>
  </div>

  <ng-container *ngFor="let treatment of treatments">
    <ng-container
      *ngTemplateOutlet="readonlyTreatment; context: { treatmentHeader: treatment.header, treatment: treatment.treatment }"
    ></ng-container>
  </ng-container>

  <ng-template #readonlyTreatment let-header="treatmentHeader" let-treatment="treatment">
    <ng-container [ngSwitch]="header?.treatmentType">
      <ng-template [ngSwitchCase]="TreatmentType.FOLLOW_UP">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.SURGERY_FOLLOW_UP" [print]="print">
          <stx-surgical-follow-up
            *ngIf="patient && treatment"
            [patient]="patient"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [formMode]="formMode"
            [surgeryFollowUp]="treatment"
            [expandable]="true"
          ></stx-surgical-follow-up>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"></stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.SURGERY">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.SURGICAL_INTERVENTION" [print]="print">
          <stx-surgical-intervention
            *ngIf="patient && treatment"
            [patient]="patient"
            [formMode]="formMode"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [surgicalIntervention]="treatment"
            [expandable]="true"
          ></stx-surgical-intervention>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.SPEECH_ASSESSMENT"
        ><stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.SPEECH_ASSESSMENT" [print]="print">
          <stx-speech-assessment
            *ngIf="patient && treatment"
            [speechAssessment]="treatment"
            [patient]="patient"
            [formMode]="formMode"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
          ></stx-speech-assessment>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.BORESCOPE">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.BORESCOPE" [print]="print">
          <stx-borescope
            *ngIf="patient && treatment"
            [formMode]="formMode"
            [expandable]="true"
            [borescope]="treatment"
            [patient]="patient"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
          ></stx-borescope>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.ORTHOGNATHIC">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.ORTHOGNATHIC_TREATMENT" [print]="print">
          <stx-orthognathic-treatment
            *ngIf="patient && treatment"
            [orthognathicTreatment]="treatment"
            [patient]="patient"
            [formMode]="formMode"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
          ></stx-orthognathic-treatment>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.ORTHO_PD_ASSESSMENT">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.ORTHO_PD_ASSESSMENT" [print]="print">
          <stx-permanent-dentition-assessment
            *ngIf="patient && treatment"
            [formMode]="formMode"
            [patient]="patient"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
            [orthoFormModel]="treatment"
          ></stx-permanent-dentition-assessment>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.ORTHO_PD_MID_ASSESSMENT">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.ORTHO_PD_MID_ASSESSMENT" [print]="print">
          <stx-permanent-dentition-mid-assessment
            *ngIf="patient && treatment"
            [formMode]="formMode"
            [patient]="patient"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
            [orthoFormModel]="treatment"
          ></stx-permanent-dentition-mid-assessment>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.ORTHO_PD_TREATMENT">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.ORTHO_PD_TREATMENT" [print]="print">
          <stx-permanent-dentition-treatment
            *ngIf="patient && treatment"
            [formMode]="formMode"
            [patient]="patient"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
            [orthoFormModel]="treatment"
          ></stx-permanent-dentition-treatment>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.ORTHO_MD_ASSESSMENT">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.ORTHO_MD_ASSESSMENT" [print]="print">
          <stx-mixed-dentition-assessment
            *ngIf="patient && treatment"
            [formMode]="formMode"
            [patient]="patient"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
            [orthoFormModel]="treatment"
          ></stx-mixed-dentition-assessment>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.ORTHO_MD_MID_ASSESSMENT">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.ORTHO_MD_MID_ASSESSMENT" [print]="print">
          <stx-mixed-dentition-mid-assessment
            *ngIf="patient && treatment"
            [formMode]="formMode"
            [patient]="patient"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
            [orthoFormModel]="treatment"
          ></stx-mixed-dentition-mid-assessment>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.ORTHO_MD_TREATMENT">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.ORTHO_MD_TREATMENT" [print]="print">
          <stx-mixed-dentition-treatment
            *ngIf="patient && treatment"
            [formMode]="formMode"
            [patient]="patient"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
            [orthoFormModel]="treatment"
          ></stx-mixed-dentition-treatment>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.ORTHO_PSIO_ASSESSMENT">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.ORTHO_PSIO_ASSESSMENT" [print]="print">
          <stx-psio-assessment
            *ngIf="patient && treatment"
            [formMode]="formMode"
            [patient]="patient"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
            [orthoFormModel]="treatment"
          ></stx-psio-assessment>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.ORTHO_PSIO_TREATMENT">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.ORTHO_PSIO_TREATMENT" [print]="print">
          <stx-psio-treatment
            *ngIf="patient && treatment"
            [orthoFormModel]="treatment"
            [patient]="patient"
            [formMode]="formMode"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
          ></stx-psio-treatment>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.SPEECH_TREATMENT">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.SPEECH_TREATMENT" [print]="print">
          <stx-speech-treatment
            *ngIf="patient && treatment"
            [speechTreatment]="treatment"
            [patient]="patient"
            [formMode]="formMode"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
          ></stx-speech-treatment>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.NUTRITION_SCREENING_HISTORICAL">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.NUTRITION_SCREENING_HISTORICAL" [print]="print">
          <stx-nutrition-screening
            *ngIf="patient && treatment"
            [nutritionScreening]="treatment"
            [patient]="patient"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
          ></stx-nutrition-screening>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.NUTRITION_FOLLOW_UP_HISTORICAL">
        <stx-expandable-form-wrapper [status]="header?.status" [formType]="FormType.NUTRITION_FOLLOW_UP_HISTORICAL" [print]="print">
          <stx-nutrition-follow-up-historical
            *ngIf="patient && treatment"
            [nutritionFollowUpPilot]="treatment"
            [patient]="patient"
            [print]="print"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
          ></stx-nutrition-follow-up-historical>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.NUTRITION_ASSESSMENT">
        <stx-expandable-form-wrapper [formType]="FormType.NUTRITION_ASSESSMENT" [print]="print" [status]="header?.status">
          <stx-nutrition-assessment
            *ngIf="patient && treatment"
            [nutritionManagement]="treatment"
            [patient]="patient"
            [print]="print"
            [formMode]="formMode"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
          ></stx-nutrition-assessment>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
      <ng-template [ngSwitchCase]="TreatmentType.NUTRITION_FOLLOW_UP">
        <stx-expandable-form-wrapper [formType]="FormType.NUTRITION_FOLLOW_UP" [print]="print" [status]="header?.status">
          <stx-nutrition-follow-up
            *ngIf="patient && treatment"
            [nutritionManagement]="treatment"
            [patient]="patient"
            [print]="print"
            [formMode]="formMode"
            [ngClass]="{ 'readonly-form-mode': formMode === FormMode.READONLY }"
            [expandable]="true"
          ></stx-nutrition-follow-up>
          <stx-patient-treatment-minimized [treatment]="getTreatmentItemData(header, treatment)"> </stx-patient-treatment-minimized>
        </stx-expandable-form-wrapper>
      </ng-template>
    </ng-container>
  </ng-template>
</ng-container>
