import { ChangeDetectorRef, Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { BaseReportTableComponent } from 'src/app/features/reports/components/base-report-table/base-report-table.component';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { ReportService } from 'src/app/features/reports/components/report-filters/services/report.service';
import { ReportTableDataService } from 'src/app/features/reports/components/report-filters/services/report-table-data.service';
import { TableColumn, TableStructure } from '@shared/components/table/models/table-structure.model';
import { SpeechStatsReportRecord } from 'src/app/features/reports/components/report-filters/models/report-data.model';
import { ActivatedRoute } from '@angular/router';
import { SimpleReportTableComponentModule } from '@src/app/features/reports/components/simple-report-table/simple-report-table.component';

@Component({
  selector: 'stx-speech-stats-report-table',
  templateUrl: './speech-stats-report-table.component.html'
})
export class SpeechStatsReportTableComponent extends BaseReportTableComponent<SpeechStatsReportRecord> implements OnInit, OnDestroy {
  displayedColumns: string[] = [];
  columns: TableColumn[] = [];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    public reportTableDataService: ReportTableDataService,
    public reportService: ReportService,
    public route: ActivatedRoute
  ) {
    super('SPEECH_STATS', changeDetectorRef, reportTableDataService, reportService, route);
  }

  ngOnInit(): void {
    super.onInit();

    this.subSink.sink = this.reportTableDataService.tableStructure$.subscribe((tableStructure: TableStructure<{ [key: string]: any }>) => {
      this.columns = tableStructure?.labels || [];
      this.displayedColumns = this.columns.map(a => a.name);
    });
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }
}

@NgModule({
  imports: [
    CommonModule,
    EmptyTableStateComponentModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    SimpleReportTableComponentModule
  ],
  declarations: [SpeechStatsReportTableComponent],
  exports: [SpeechStatsReportTableComponent]
})
export class SpeechStatsReportTableComponentModule {}
