<div class="data-section">
  <div class="data-section-heading">
    <strong>
      {{ grade | translate }}
    </strong>
    <span class="tag text-uppercase">
      {{ tagName | translate }}
    </span>
  </div>
  <div class="data-section-content">
    <ng-container *ngIf="descriptionParagraph">
      <p>{{ descriptionParagraph | translate }}</p>
    </ng-container>
    <ng-container *ngFor="let paragraph of paragraphList">
      <p>{{ paragraph | translate }}</p>
    </ng-container>
    <ng-container *ngIf="bulletedList?.length > 0">
      <ul>
        <ng-container *ngFor="let item of bulletedList">
          <li>{{ item | translate }}</li>
        </ng-container>
      </ul>
    </ng-container>
    <ng-container *ngIf="boldParagraph">
      <p>
        <strong>{{ boldParagraph | translate }}</strong>
      </p>
    </ng-container>
  </div>
</div>
