import { Component } from '@angular/core';
import { dictionaryFromMapProvider } from '@shared/services/dictionary-provider';
import { BaseReadonlyViewComponent } from '@shared/modules/readonly-commons/utils/base-readonly-view.component';
import { NutritionType } from '@src/app/features/nutrition-management/enums/nutrition-type.enum';
import { PatientCareType } from '@src/app/features/nutrition-management/enums/patient-care-type.enum';
import { MediaResource } from '@shared/models/media-resource.model';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { BaseNutritionManagement } from '@src/app/features/nutrition-management/models/nutrition-management.model';
import { NutritionDictionariesService } from '@src/app/features/nutrition-management/services/nutrition-dictionaries-service';

interface Value {
  labelKey: string;
  value: any;
  unitKey?: string;
}

@Component({
  selector: 'stx-nutrition-readonly',
  templateUrl: './nutrition-management-readonly.component.html',
  styleUrls: ['./nutrition-management-readonly.component.scss'],
  providers: [dictionaryFromMapProvider(NutritionDictionariesService, s => s.getStaticDictionaries())]
})
export class NutritionManagementReadonlyComponent extends BaseReadonlyViewComponent<BaseNutritionManagement> {
  readonly NutritionType = NutritionType;
  readonly PatientCareType = PatientCareType;

  protected afterTreatmentSet(): void {
    // No logic right here because it had to be implemented from parent class
  }

  get ageGroupKey(): string | null {
    if (!this.treatment.ageGroup) {
      return null;
    }
    switch (this.treatment.ageGroup) {
      case NutritionType.INFANT:
        return 'nutrition_management.infant.short';
      case NutritionType.TODDLER:
        return 'nutrition_management.toddler.short';
      case NutritionType.ADOLESCENT:
        return 'nutrition_management.adolescent.short';
      case NutritionType.ADULT:
        return 'nutrition_management.adult';
    }
  }

  get photo(): MediaResource | null {
    return (this.treatment.mediaResources || []).find(media => media.parentOrderName === ParentOrderName.NUTRITION_MANAGEMENT);
  }

  get generalNutritionInformation(): Value[] {
    const nutritionInfo = this.treatment?.generalNutritionInformation;
    return [
      { labelKey: 'patient.weight', value: nutritionInfo?.weight, unitKey: 'unit.kg' },
      { labelKey: 'height', value: nutritionInfo?.height, unitKey: 'unit.cm' },
      { labelKey: 'nutrition_management.hemoglobin', value: nutritionInfo?.hemoglobin, unitKey: 'unit.hemoglobin' },
      { labelKey: 'nutrition_management.muac', value: nutritionInfo?.muac, unitKey: 'unit.cm' },
      { labelKey: 'nutrition_management.head_circumference', value: nutritionInfo?.headCircumference, unitKey: 'unit.cm' },
      { labelKey: 'nutrition_management.triceps_skinhold', value: nutritionInfo?.tricepsSkinfold, unitKey: 'unit.mm' },
      { labelKey: 'nutrition_management.subscapular_skinfold', value: nutritionInfo?.subscapularSkinfold, unitKey: 'unit.mm' }
    ].filter(element => !!element.value);
  }

  get feedingInfo(): Value[] {
    const feedingInfo = this.treatment?.feedingInformation;
    const translationsForPatientAgeGroup = this.feedingInfoTranslations.get(this.treatment.ageGroup);

    return [
      { labelKey: 'nutrition_management.feeding_assessment.not_bio_mother', value: feedingInfo?.notBiologicalMother },
      { labelKey: translationsForPatientAgeGroup.difficulty, value: feedingInfo?.difficulty },
      { labelKey: translationsForPatientAgeGroup.otherComplications, value: feedingInfo?.otherComplications },
      { labelKey: 'nutrition_management.birthweight_feeding_info.mother_difficulty', value: feedingInfo?.difficultyBreast },
      { labelKey: 'nutrition_management.feeding_info.mother_doesnt_feed', value: feedingInfo?.noBreastfeeding },
      { labelKey: 'nutrition_management.feeding_assessment.no_complementary', value: feedingInfo?.noComplementary },
      { labelKey: 'nutrition_management.feeding_assessment.no_texture', value: feedingInfo?.noTexture },
      { labelKey: 'nutrition_management.feeding_assessment.other_remarks', value: feedingInfo?.other }
    ].filter(element => !!element.value);
  }

  get nutritionalStatus(): Value[] {
    const nutritionalStatus = this.treatment?.nutritionalStatus;
    return [
      { labelKey: 'nutrition_management.nutritional_status.good_nutritional_status', value: nutritionalStatus?.good },
      { labelKey: 'nutrition_management.nutritional_status.failing_to_thrive_or_at_risk', value: nutritionalStatus?.failingToThrive },
      { labelKey: 'nutrition_management.nutritional_status.wasted', value: nutritionalStatus?.wasted },
      { labelKey: 'nutrition_management.nutritional_status.moderately_wasted', value: nutritionalStatus?.moderatelyWasted },
      { labelKey: 'nutrition_management.nutritional_status.severely_wasted', value: nutritionalStatus?.severelyWasted },
      { labelKey: 'nutrition_management.nutritional_status.stunted', value: nutritionalStatus?.stunted },
      { labelKey: 'nutrition_management.nutritional_status.moderately_stunted', value: nutritionalStatus?.moderatelyStunted },
      { labelKey: 'nutrition_management.nutritional_status.severely_stunted', value: nutritionalStatus?.severelyStunted },
      { labelKey: 'nutrition_management.nutritional_status.underweight', value: nutritionalStatus?.underweight },
      { labelKey: 'nutrition_management.nutritional_status.edema', value: nutritionalStatus?.edema },
      { labelKey: 'nutrition_management.nutritional_status.at_risk_of_overweight', value: nutritionalStatus?.overweightRisk },
      { labelKey: 'nutrition_management.nutritional_status.overweight', value: nutritionalStatus?.overweight },
      { labelKey: 'nutrition_management.nutritional_status.obese', value: nutritionalStatus?.obese },
      { labelKey: 'nutrition_management.nutritional_status.anemic', value: nutritionalStatus?.anemic },
      { labelKey: 'nutrition_management.nutritional_status.malnourished', value: nutritionalStatus?.malnourished },
      { labelKey: 'nutrition_management.nutritional_status.not_gaining_weight', value: nutritionalStatus?.noWeightGain },
      { labelKey: 'nutrition_management.nutritional_status.other', value: nutritionalStatus?.other }
    ].filter(element => !!element.value);
  }

  get nutritionCarePlan(): Value[] {
    const nutritionCarePlan = this.treatment?.nutritionCarePlan;
    const translationsForPatientAgeGroup = this.feedingInfoTranslations.get(this.treatment.ageGroup);

    return [
      { labelKey: 'nutrition_management.nutrition_care_plan.breastfeeding_support', value: nutritionCarePlan?.breastFeeding },
      { labelKey: 'nutrition_management.nutrition_care_plan.therapeutic_rehabilitation', value: nutritionCarePlan?.foodTherapeutic },
      { labelKey: 'nutrition_management.nutrition_care_plan.supplemental_feeds', value: nutritionCarePlan?.foodSupplemental },
      { labelKey: translationsForPatientAgeGroup.foodRations, value: nutritionCarePlan?.foodRations },
      { labelKey: 'nutrition_management.nutrition_care_plan.rutf', value: nutritionCarePlan?.rutf },
      { labelKey: 'nutrition_management.nutrition_care_plan.food_commodities', value: nutritionCarePlan?.foodFunds },
      { labelKey: translationsForPatientAgeGroup.foodPrep, value: nutritionCarePlan?.foodPrep },
      { labelKey: 'nutrition_management.nutrition_care_plan.food_commodities_advice', value: nutritionCarePlan?.foodCommoditiesAdvice },
      { labelKey: 'nutrition_management.nutrition_care_plan.breastmilk_expression', value: nutritionCarePlan?.supportBreastmilkExpression },
      {
        labelKey: 'nutrition_management.nutrition_care_plan.advice_tools_and_techniques',
        value: nutritionCarePlan?.adviceBreastmilkTechniques
      },
      {
        labelKey: 'nutrition_management.nutrition_care_plan.advice_tools_and_techniques.toddler',
        value: nutritionCarePlan?.adviceAnimalMilk
      },
      { labelKey: 'nutrition_management.nutrition_care_plan.advice_substitutes_and_tools', value: nutritionCarePlan?.adviceSubstitutes },
      { labelKey: 'nutrition_management.nutrition_care_plan.provision_substitutes', value: nutritionCarePlan?.provisionSubstitutes },
      { labelKey: translationsForPatientAgeGroup.provisionFeedingTools, value: nutritionCarePlan?.provisionFeedingTools },
      { labelKey: 'nutrition_management.nutrition_care_plan.advice_introduction', value: nutritionCarePlan?.adviceComplementaryFeeding },
      { labelKey: 'nutrition_management.nutrition_care_plan.multivitamin', value: nutritionCarePlan?.mvm },
      { labelKey: 'nutrition_management.nutrition_care_plan.other', value: nutritionCarePlan?.other }
    ].filter(element => !!element.value);
  }

  get anemiaManagement(): Value[] {
    const anemiaManagement = this.treatment?.anemiaManagement;
    return [
      { labelKey: 'nutrition_management.anemia_management.iron_supplements', value: anemiaManagement?.ironSupplement },
      { labelKey: 'nutrition_management.anemia_management.infant.advice_iron_and_vitamins', value: anemiaManagement?.ironBSource },
      {
        labelKey: 'nutrition_management.anemia_management.infant.provision_multivitamins_and_minerals',
        value: anemiaManagement?.ironBMvmSupplement
      },
      { labelKey: 'nutrition_management.anemia_management.infant.financial_support', value: anemiaManagement?.foodFunds },
      { labelKey: 'nutrition_management.anemia_management.infant.investigation_referral', value: anemiaManagement?.referral },
      { labelKey: 'nutrition_management.anemia_management.infant.other', value: anemiaManagement?.other }
    ].filter(element => !!element.value);
  }

  isElementOtherOption(element: Value): boolean {
    return element?.labelKey?.includes('.other') && !element?.labelKey?.includes('.feeding_info.other');
  }

  private feedingInfoTranslations = new Map<
    NutritionType,
    { difficulty: string; otherComplications: string; foodRations?: string; foodPrep: string; provisionFeedingTools?: string }
  >()
    .set(NutritionType.INFANT, {
      difficulty: 'nutrition_management.birthweight_feeding_info.difficulty',
      otherComplications: 'nutrition_management.birthweight_feeding_info.other',
      foodRations: 'nutrition_management.nutrition_care_plan.food_provisions',
      foodPrep: 'nutrition_management.nutrition_care_plan.advice',
      provisionFeedingTools: 'nutrition_management.nutrition_care_plan.provision_feeding_tools'
    })
    .set(NutritionType.TODDLER, {
      difficulty: 'nutrition_management.feeding_info.difficulty.child',
      otherComplications: 'nutrition_management.feeding_info.other.child',
      foodRations: 'nutrition_management.nutrition_care_plan.food_provisions.toddler',
      foodPrep: 'nutrition_management.nutrition_care_plan.food_commodities_advice',
      provisionFeedingTools: 'nutrition_management.nutrition_care_plan.provision_feeding_tools.toddler'
    })
    .set(NutritionType.ADOLESCENT, {
      difficulty: 'nutrition_management.feeding_info.difficulty',
      otherComplications: 'nutrition_management.feeding_info.other',
      foodRations: 'nutrition_management.nutrition_care_plan.food_provisions.adolescent',
      foodPrep: 'nutrition_management.nutrition_care_plan.food_commodities.patient'
    })
    .set(NutritionType.ADULT, {
      difficulty: 'nutrition_management.feeding_info.difficulty',
      otherComplications: 'nutrition_management.feeding_info.other',
      foodRations: 'nutrition_management.nutrition_care_plan.food_provisions',
      foodPrep: 'nutrition_management.nutrition_care_plan.food_commodities.patient'
    });
}
