<stx-notice [noticeType]="noticeType">
  <div class="normal fs-16 m-b-20 flex-row-container wrap align-left layout-align-center">
    <div class="align-left layout-align-center display-flex">
      <mat-icon class="sign-step-icon" svgIcon="upload-alt"></mat-icon>
    </div>
    <div class="fs-14 m-l-10">{{ 'patient.release_form.upload' | translate }}</div>
  </div>
  <p *ngIf="patientDataMatchesReleaseFormData === false">
    <em>{{ 'patient.offline_release_form.warning' | translate }}</em>
  </p>
  <ng-container *ngIf="!existingPdf">
    <stx-patient-document-file-upload
      [formType]="formType"
      (newFileUploaded)="onNewFileUploaded($event)"
    ></stx-patient-document-file-upload>
  </ng-container>
  <ng-container *ngIf="existingPdf">
    <p>
      <stx-file-icon></stx-file-icon>
      <a [href]="existingPdf?.url" target="_blank">
        {{ existingPdf?.createdAt | date : 'yyyy-MM-dd' }}
      </a>
      <stx-remove-icon (click)="remove()"></stx-remove-icon>
    </p>
  </ng-container>
</stx-notice>
