<div class="form-row">
  <p class="form-subsection-heading">{{ instrumentalAssessmentColumnConfig.headerLabel | translate }}</p>
</div>
<div class="form-row">
  <stx-date
    [formMode]="formMode"
    [formGroup]="formGroup"
    [controlName]="instrumentalAssessmentColumnConfig.dateControlName"
    label="speech_assessment.date_of_recording"
    [maxDateToday]="true"
    [minDate]="minDate"
  ></stx-date>
</div>
<div class="form-row">
  <stx-audio-video
    label="video"
    [formGroup]="formGroup"
    [formMode]="formMode"
    [parentOrderName]="instrumentalAssessmentColumnConfig.videoParentOrderName"
    [controlName]="instrumentalAssessmentColumnConfig.videoParentOrderName.toString()"
    [print]="print"
  ></stx-audio-video>
</div>
<div class="form-row">
  <stx-image
    label="speech_assessment.picture_at_rest"
    [formMode]="formMode"
    [parentOrderName]="instrumentalAssessmentColumnConfig.atRestParentOrderName"
    [controlName]="instrumentalAssessmentColumnConfig.atRestParentOrderName.toString()"
    [formGroup]="formGroup"
    [print]="print"
  ></stx-image>
</div>
<div class="form-row">
  <stx-image
    label="speech_assessment.picture_during_phonation"
    [formMode]="formMode"
    [parentOrderName]="instrumentalAssessmentColumnConfig.duringPhonationParentOrderName"
    [controlName]="instrumentalAssessmentColumnConfig.duringPhonationParentOrderName.toString()"
    [formGroup]="formGroup"
    [print]="print"
  ></stx-image>
</div>
<div class="form-row">
  <stx-textarea
    [formMode]="formMode"
    [controlName]="instrumentalAssessmentColumnConfig.findingControlName"
    label="speech_assessment.findings"
    [formGroup]="formGroup"
  ></stx-textarea>
</div>
