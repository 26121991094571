import { Component } from '@angular/core';
import { NavigationSubTabEnum } from '@shared/components/navigation/model/nav-item-enum.enum';
import { ResourceSection } from '@src/app/features/educational-resources/models/resource.model';
import { FileExtension, Mime } from '@utils/file.utils';
import {
  patientRegistrationResource,
  patientReleaseFormResource,
  speechAssessmentMedicalForm,
  speechTreatmentMedicalForm
} from '@src/app/features/educational-resources/utils/shared-resources-definitions.utils';

@Component({
  templateUrl: './speech-resources-page.component.html'
})
export class SpeechResourcesPageComponent {
  readonly NavigationSubTabEnum = NavigationSubTabEnum;
  readonly leftColumnResources = leftColumnResources;
  readonly middleColumnResources = middleColumnResources;
  readonly rightColumnResources = rightColumnResources;
}
const leftColumnResources: readonly ResourceSection[] = [
  {
    sectionTitle: 'resources.speech.grant_title',
    resources: [
      {
        label: 'resources.speech.grant_partner_guidelines',
        resourceName: 'Guidelines_Speech_Service_Grant_',
        mime: Mime.PDF,
        fileExtension: FileExtension.PDF,
        translationList: [['en', 'es', 'fr', 'id', 'my', 'pt', 'th', 'vi', 'cn']]
      },
      {
        label: 'resources.speech.grant_practitioner_application',
        resourceName: 'Speech_Practitioner_Application_Form_',
        mime: Mime.DOCX,
        fileExtension: FileExtension.DOCX,
        translationList: [['en', 'es', 'fr', 'id', 'my', 'pt', 'th', 'vi', 'cn']]
      }
    ],
    isSeparated: true
  },
  {
    sectionTitle: 'resources.speech.practitioner_title',
    resources: [
      {
        label: 'resources.speech.practitioner_quick_start_guide',
        resourceName: 'Speech_Practitioner_Quick_Start_Guide_',
        mime: Mime.PPTX,
        fileExtension: FileExtension.PPTX,
        translationList: [['en', 'es', 'fr', 'id', 'my', 'pt', 'th', 'vi', 'cn']]
      },
      {
        label: 'resources.speech.practitioner_cleft_clinical_cheat_sheet',
        resourceName: 'Cleft_Speech_Clinical_Cheat_Sheet_',
        mime: Mime.PDF,
        fileExtension: FileExtension.PDF,
        translationList: [['en', 'es', 'fr', 'id', 'my', 'pt', 'th', 'vi', 'cn']]
      },
      {
        label: 'resources.speech.practitioner_intensive_model_requirements',
        resourceName: 'Intensive_Speech_Camp_Model_Requirements_',
        mime: Mime.PDF,
        fileExtension: FileExtension.PDF,
        translationList: [['en', 'es', 'fr', 'id']]
      }
    ],
    isSeparated: true
  }
];
const middleColumnResources: readonly ResourceSection[] = [
  {
    sectionTitle: 'resources.speech.assistance',
    resources: [
      {
        label: 'resources.speech.assistance_assessment_guide',
        resourceName: 'Guide_to_STX_Assessment_',
        mime: Mime.PDF,
        fileExtension: FileExtension.PDF,
        translationList: [['en']]
      },
      {
        label: 'resources.speech.assistance_treatment_guide',
        resourceName: 'Guide_to_STX_Treatment_Report_',
        mime: Mime.PDF,
        fileExtension: FileExtension.PDF,
        translationList: [['en']]
      }
    ],
    isSeparated: true
  },
  {
    sectionTitle: 'medical_forms.title',
    resources: [
      patientRegistrationResource,
      patientReleaseFormResource,
      {
        ...speechAssessmentMedicalForm,
        isSeparated: true
      },
      speechTreatmentMedicalForm
    ]
  }
];
const rightColumnResources: readonly ResourceSection[] = [{ sectionTitle: 'resources_for_families.title', resources: [] }];
