import { Pipe, PipeTransform } from '@angular/core';
import { chunk } from 'lodash';

@Pipe({
  name: 'transposeColumnsToRows'
})
export class TransposeColumnsToRowsPipe implements PipeTransform {
  transform(value: any[], expectedColumnCount: number) {
    const columnCount = Math.ceil((value?.length || 0) / expectedColumnCount);
    const columns = chunk(value, columnCount);
    const result = [];
    let lastValue;
    do {
      for (const column of columns) {
        lastValue = column.shift();
        if (lastValue !== undefined) {
          result.push(lastValue);
        }
      }
    } while (lastValue !== undefined);
    return result;
  }
}
