import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FormModule } from '@shared/components/form/form.module';
import { ReportFilterChange, ReportFiltersParams } from '../../models/report-filters.model';
import { ReportDictionariesService } from '../../services/report-dictionaries.service';
import { SearchModule } from '../../../../../search/search.module';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Item } from '@shared/models/item.model';
import { OrgTreeFilterModel } from '../../../../../search/models/org-tree-filter.model';
import { ReportFiltersHelperService } from '../../services/report-filters-helper.service';

@Component({
  selector: 'stx-report-organizations-filter',
  templateUrl: './report-organizations-tree-filter.component.html'
})
export class ReportOrganizationsTreeFilterComponent extends BaseComponent implements OnInit {
  @Input() orgTreeFilterData: OrgTreeFilterModel;
  @Input() formGroupReference: UntypedFormGroup;
  @Output() filterChange = new EventEmitter<ReportFilterChange>();

  constructor(
    public reportDictionariesService: ReportDictionariesService,
    private route: ActivatedRoute,
    private reportHelperService: ReportFiltersHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subSink.sink = this.formGroupReference.valueChanges.subscribe(this.handleValueChanges.bind(this));
    this.watchQueryParamsChange();
  }

  private handleValueChanges(change: Record<string, Array<Item>>): void {
    this.reportHelperService.mapAndEmitMultiselectChanges(change, this.filterChange);
  }

  private watchQueryParamsChange(): void {
    this.subSink.sink = this.route.queryParams
      .pipe(first(params => !!params.partners || !!params.treatmentCenters))
      .subscribe((params: ReportFiltersParams) => {
        if (params.partners) {
          const partnersParams = this.getIntegerIds(params.partners);

          this.setValuesFromParams('partners', partnersParams);
        }
        if (params.treatmentCenters) {
          const treatmentCentersParams = this.getIntegerIds(params.treatmentCenters);

          this.setValuesFromParams('treatmentCenters', treatmentCentersParams);
        }
      });
  }

  private getIntegerIds(values: string): number[] {
    return values.split(',').map((value: string) => parseInt(value, 10));
  }

  private setValuesFromParams(formControlName: string, values: number[]): void {
    const items = this.orgTreeFilterData[formControlName].filter(val => values.includes(val.id));
    this.formGroupReference.get(formControlName).patchValue(items);
  }
}

@NgModule({
  declarations: [ReportOrganizationsTreeFilterComponent],
  imports: [FormModule, SearchModule, CommonModule],
  exports: [ReportOrganizationsTreeFilterComponent]
})
export class ReportOrganizationsTreeFilterModule {}
