<div class="form-element-group-row status-container">
  <div *ngFor="let status of statuses">
    <button
      data-testid="button-treatment-status-filter"
      (click)="statusSelection.toggle(status.status)"
      [ngClass]="{ 'selected-status': statusSelection.isSelected(status.status) }"
      class="flex-row-container"
    >
      <mat-icon class="m-r-8">{{ status.icon }}</mat-icon> {{ status.label | translate }}
    </button>
  </div>
</div>
