<div class="data-section" *ngIf="statisticsTable?.lastUpdated">
  <p>{{ 'dashboard.statistics.last_updated' | translate }} - {{ this.statisticsTable.lastUpdated | date : dateTimeFormat }}</p>
</div>

<div class="data-section" *ngIf="statisticsTable?.surgical">
  <stx-statistics-table
    [treatmentStats]="statisticsTable.surgical.surgicalTreatment"
    [tableTreatmentTitle]="'treatment.surgical_intervention' | translate"
    [patientCount]="statisticsTable.surgical.totalPatientCount"
    [isOnDashboard]="isOnDashboard"
  ></stx-statistics-table>
  <div class="data-tile-container" *ngIf="showPlot(surgeryPlots)">
    <div class="data-tile-row">
      <div class="data-tile">
        <div class="data-tile-content">
          <stx-statistics-plot [plotLines]="surgeryPlots"></stx-statistics-plot>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="data-section" *ngIf="statisticsTable?.speech">
  <stx-statistics-table
    [treatmentStats]="statisticsTable.speech.speechAssessment"
    [tableTreatmentTitle]="'treatment.speech_assessment' | translate"
    [patientCount]="statisticsTable.speech.speechAssessment.totalPatientCount"
  ></stx-statistics-table>

  <stx-statistics-table
    [treatmentStats]="statisticsTable.speech.speechTreatment"
    [tableTreatmentTitle]="'treatment.speech_treatment' | translate"
    [patientCount]="statisticsTable.speech.speechTreatment.totalPatientCount"
  ></stx-statistics-table>

  <div class="data-tile-container" *ngIf="showPlot(speechPlots)">
    <div class="data-tile-row">
      <div class="data-tile">
        <div class="data-tile-content">
          <stx-statistics-plot [plotLines]="speechPlots"></stx-statistics-plot>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="data-section" *ngIf="statisticsTable?.orthodontics">
  <stx-statistics-table
    [treatmentStats]="statisticsTable.orthodontics.presurgicalOrthopedicsAssessment"
    [tableTreatmentTitle]="'treatment.ortho_psio_assessment' | translate"
    [patientCount]="statisticsTable.orthodontics.presurgicalOrthopedicsAssessment.totalPatientCount"
  ></stx-statistics-table>
  <stx-statistics-table
    [treatmentStats]="statisticsTable.orthodontics.presurgicalOrthopedicsTreatment"
    [tableTreatmentTitle]="'treatment.ortho_psio_treatment' | translate"
    [patientCount]="statisticsTable.orthodontics.presurgicalOrthopedicsTreatment.totalPatientCount"
  ></stx-statistics-table>

  <div class="data-tile-container" *ngIf="showPlot(orthoPsioPlots)">
    <div class="data-tile-row">
      <div class="data-tile">
        <div class="data-tile-content">
          <stx-statistics-plot [plotLines]="orthoPsioPlots"></stx-statistics-plot>
        </div>
      </div>
    </div>
  </div>

  <stx-statistics-table
    [treatmentStats]="statisticsTable.orthodontics.mixedDentitionAssessment"
    [tableTreatmentTitle]="'treatment.ortho_md_assessment' | translate"
    [patientCount]="statisticsTable.orthodontics.mixedDentitionAssessment.totalPatientCount"
  ></stx-statistics-table>
  <stx-statistics-table
    [treatmentStats]="statisticsTable.orthodontics.mixedDentitionTreatment"
    [tableTreatmentTitle]="'treatment.ortho_md_treatment' | translate"
    [patientCount]="statisticsTable.orthodontics.mixedDentitionTreatment.totalPatientCount"
  ></stx-statistics-table>

  <div class="data-tile-container" *ngIf="showPlot(orthoMdPlots)">
    <div class="data-tile-row">
      <div class="data-tile">
        <div class="data-tile-content">
          <stx-statistics-plot [plotLines]="orthoMdPlots"></stx-statistics-plot>
        </div>
      </div>
    </div>
  </div>

  <stx-statistics-table
    [treatmentStats]="statisticsTable.orthodontics.permanentDentitionAssessment"
    [tableTreatmentTitle]="'treatment.ortho_pd_assessment' | translate"
    [patientCount]="statisticsTable.orthodontics.permanentDentitionAssessment.totalPatientCount"
  ></stx-statistics-table>
  <stx-statistics-table
    [treatmentStats]="statisticsTable.orthodontics.permanentDentitionTreatment"
    [tableTreatmentTitle]="'treatment.ortho_pd_treatment' | translate"
    [patientCount]="statisticsTable.orthodontics.permanentDentitionTreatment.totalPatientCount"
  ></stx-statistics-table>

  <div class="data-tile-container" *ngIf="showPlot(orthoPdPlots)">
    <div class="data-tile-row">
      <div class="data-tile">
        <div class="data-tile-content">
          <stx-statistics-plot [plotLines]="orthoPdPlots"></stx-statistics-plot>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="data-section" *ngIf="statisticsTable?.nutrition">
  <stx-statistics-table
    [treatmentStats]="statisticsTable.nutrition.nutritionManagement"
    [tableTreatmentTitle]="'treatment.nutrition_management' | translate"
    [patientCount]="statisticsTable.nutrition.nutritionManagement.totalPatientCount"
  ></stx-statistics-table>

  <div class="data-tile-container" *ngIf="showPlot(nutritionManagementPlots)">
    <div class="data-tile-row">
      <div class="data-tile">
        <div class="data-tile-content">
          <stx-statistics-plot [plotLines]="nutritionManagementPlots"></stx-statistics-plot>
        </div>
      </div>
    </div>
  </div>

  <div class="data-tile-container" *ngIf="showPlot(nutritionFormsPlots)">
    <div class="data-tile-row">
      <div class="data-tile">
        <div class="data-tile-content">
          <stx-statistics-plot [plotLines]="nutritionFormsPlots"></stx-statistics-plot>
        </div>
      </div>
    </div>
  </div>

  <stx-statistics-submitted-only
    *ngIf="statisticsTable.nutrition.nutritionScreeningSubmittedCount"
    [treatmentStats]="statisticsTable.nutrition.nutritionScreeningSubmittedCount"
    [tableTreatmentTitle]="'treatment.nutrition_screening'"
  ></stx-statistics-submitted-only>

  <stx-statistics-submitted-only
    *ngIf="statisticsTable.nutrition.nutritionFollowUpPilotSubmittedCount"
    [treatmentStats]="statisticsTable.nutrition.nutritionFollowUpPilotSubmittedCount"
    [tableTreatmentTitle]="'treatment.nutrition_follow_up'"
  ></stx-statistics-submitted-only>
</div>
