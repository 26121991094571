<div class="full-width">
  <ng-container *ngIf="!showConversionInProgressNotice">
    <audio [src]="mediaSourceUrl" controls (error)="onMediaSourceFetchError()" (loadeddata)="onMediaSourceFetchSuccess()"></audio>
  </ng-container>
  <ng-container *ngIf="showConversionInProgressNotice">
    <stx-notice>
      {{ 'media.converting_message' | translate }}
    </stx-notice>
  </ng-container>
</div>

<div class="flex-row-container m-t-10 m-b-10">
  <a *ngIf="urlRaw" href="{{ urlRaw }}" target="_blank" class="flex-row-container m-r-5">
    <mat-icon> save_alt </mat-icon>{{ 'media.original_file_download' | translate }}
  </a>
  <a class="flex-row-container" *ngIf="urlConverted" href="{{ urlConverted }}" target="_blank">
    <mat-icon> save_alt </mat-icon>{{ 'media.converted_file_download' | translate }}
  </a>
</div>
