<div [ngClass]="{ 'p-t-30 page-break-inside-avoid': print }">
  <div class="form-row">
    <p class="form-section-heading">
      {{
        'nutrition_management.type_of_patient_care' | translate | sectionNumber : nutritionFormType : NutritionSection.TYPE_OF_PATIENT_CARE
      }}
    </p>
  </div>
  <div class="form-row" [formGroup]="formGroup">
    <div>
      <mat-radio-group
        [formControlName]="patientCareTypeControlName"
        name="{{ patientCareTypeControlName }}"
        id="{{ patientCareTypeControlName }}"
      >
        <div *ngFor="let careOption of typesOfPatientCare">
          <div>
            <mat-radio-button data-testid="radio-care-option" [value]="careOption.value">
              {{ careOption.label | translate }}
            </mat-radio-button>
            <div *ngIf="careOption.subtitle" class="form-element-secondary-label p-l-25 fs-13">{{ careOption.subtitle | translate }}</div>
          </div>
          <div class="indented-form-block p-b-10 p-l-25" *ngIf="careOption.checkboxes">
            <stx-checkbox-group
              *ngIf="careOption.checkboxes"
              [layoutDirection]="'column'"
              [options]="filterOptions(careOption.checkboxes)"
              [formGroup]="formGroup"
              [formMode]="formMode"
              [print]="print"
              [deselectTriggerControlName]="getDeselectTriggerName(careOption.value)"
              [formGroupErrorNames]="[getSubsectionErrorNames(careOption.value)]"
              (anyOptionSelected)="setParentWhenChildSelected(careOption)"
            >
            </stx-checkbox-group>
          </div>
        </div>
      </mat-radio-group>
      <mat-error
        class="stx-mat-error-align"
        *ngIf="formGroup.get(patientCareTypeControlName)?.invalid && formGroup.get(patientCareTypeControlName)?.touched"
      >
        {{ 'error.select' | translate }}
      </mat-error>
    </div>
  </div>
</div>
