<stx-generic-page>
  <div class="header-container">
    <stx-page-header
      [namePrefix]="totalNumberOfRecords()"
      [name]="'tools.suspicious_records' | translate"
      additionalClasses="m-t-10 m-b-10"
    >
    </stx-page-header>
    <ng-container *ngIf="!!isRecordLoaded()">
      <ng-container *ngTemplateOutlet="lastModifiedSuspiciousRecordDate"> </ng-container>
    </ng-container>
  </div>

  <div *ngIf="isRecordLoaded()" class="data-section">
    <div class="data-section-container">
      <div class="data-tile-container records-pane w-1-2">
        <div class="data-tile">
          <div class="data-tile-content">
            <stx-suspicious-record
              *ngIf="suspiciousRecord()"
              [patient]="suspiciousRecord().patient"
              [surgery]="suspiciousRecord().surgery"
              [problems]="suspiciousRecord().fraudTypes"
              [country]="suspiciousRecord().countryName"
            ></stx-suspicious-record>
          </div>
        </div>
      </div>
      <div *ngIf="suspiciousRecord()?.frauds?.length > 0" class="records-pane w-1-2">
        <stx-scrollable-container>
          <div class="data-tile-container" *ngFor="let fraud of suspiciousRecord().frauds; let i = index">
            <div class="data-tile">
              <div class="data-tile-content">
                <div>
                  <stx-suspicious-record
                    [index]="i"
                    [patient]="fraud.patient"
                    [surgery]="fraud.surgery"
                    [problems]="fraud.fraudTypes"
                    [isPotentialMatch]="fraud.potentialPatientMatch"
                    [country]="fraud.countryName"
                    [includesActions]="true"
                    (isRejected)="toggleAcceptButton($event)"
                  ></stx-suspicious-record>
                </div>
              </div>
            </div>
          </div>
        </stx-scrollable-container>
      </div>
    </div>
  </div>

  <div *ngIf="isRecordLoaded()" class="m-t-10">
    <ng-container *ngTemplateOutlet="actionButtons"> </ng-container>
  </div>

  <ng-template #actionButtons>
    <div class="action-buttons">
      <button
        data-testid="button-suspicious-records-comparison-accept"
        mat-flat-button
        class="comparison-button comparison-button-accept m-r-24"
        [disabled]="isAcceptDisabled || !isRecordLoaded()"
        (click)="assessRecord(true)"
      >
        <mat-icon>done</mat-icon>
        {{ 'suspicious_records.accept' | translate }}
      </button>
      <button
        data-testid="button-suspicious-records-comparison-reject"
        mat-flat-button
        class="comparison-button comparison-button-reject m-r-24"
        (click)="assessRecord(false)"
        [disabled]="!isRecordLoaded()"
      >
        <mat-icon>close</mat-icon>
        {{ 'suspicious_records.problem' | translate }}
      </button>
      <button
        mat-flat-button
        data-testid="button-suspicious-records-comparison-skip"
        class="comparison-button comparison-button-skip m-r-24"
        (click)="skipRecord()"
        [disabled]="!isRecordLoaded()"
      >
        <mat-icon>arrow_forward_ios</mat-icon>
        {{ 'suspicious_records.skip' | translate }}
      </button>
      <button
        mat-flat-button
        class="comparison-button comparison-button-refresh m-r-24"
        (click)="refreshRecord()"
        [disabled]="!isRecordLoaded()"
      >
        <mat-icon>refresh</mat-icon>
        {{ 'refresh' | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template #lastModifiedSuspiciousRecordDate>
    <div class="refresh-button-container">
      <div class="m-t-10 m-b-10 text-h4">
        {{ 'suspicious_records.last_modified' | translate }}: {{ suspiciousRecord()?.lastAsyncProcessRun | date : 'short' }}
      </div>
    </div>
  </ng-template>
</stx-generic-page>
