import { ActivatedRoute } from '@angular/router';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { ReportFilterName } from '@src/app/features/reports/components/report-filters/models/report-filters.model';
import { ReportService } from '@src/app/features/reports/components/report-filters/services/report.service';
import { PartnerSurgeryCountsReportTypes } from '@src/app/features/reports/features/partner-surgery-counts-report/partner-surgery-counts-report.model';
import { reportRegionRouteQueries, ReportRegions } from '@src/app/features/reports/models/report-regions.model';
import { ReportOptions } from '@src/app/features/reports/models/reports.model';
import {
  monthEndReportRoute,
  nutritionReportRoute,
  nutritionStatsReportRoute,
  orthodonticStatsReportRoute,
  orthodonticTreatmentsCompactReportRoute,
  orthodonticTreatmentsReportRoute,
  orthognathicRoute,
  orthognathicTreatmentsRoute,
  paperRecordsReportRoute,
  partnerSurgeryCountsReportRoute,
  photoReportRoute,
  qaEvaluatorReviewsReportRoute,
  speechAssessmentReportRoute,
  speechStatsReportRoute,
  speechTreatmentReportRoute,
  speechTreatmentsReportRoute,
  surgeryStatsReportRoute,
  surgicalFollowUpVisitsRoute,
  surgicalRoute,
  surgicalTreatmentsCompactRoute,
  surgicalTreatmentsRoute,
  yearToDateReportRoute
} from '@utils/routing.utils';
import { filter, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

export const routes = {
  monthEndSurgicalReportRoute: `./${monthEndReportRoute}/${surgicalRoute}`,
  monthEndOrthognathicReportRoute: `./${monthEndReportRoute}/${orthognathicRoute}`,
  speechTreatmentRaportRoute: `./${speechTreatmentReportRoute}`,
  speechAssessmentRaportRoute: `./${speechAssessmentReportRoute}`,
  paperRecordsReport: `./${paperRecordsReportRoute}`,
  orthodonticTreatmentsReportRoute: `./${orthodonticTreatmentsReportRoute}`,
  orthodonticTreatmentsCompactReportRoute: `./${orthodonticTreatmentsCompactReportRoute}`,
  yearToDateReportRoute: `./${yearToDateReportRoute}`,
  photoReportRoute: `./${photoReportRoute}`,
  orthognathicTreatmentsReportRoute: `./${orthognathicTreatmentsRoute}`,
  surgicalTreatmentsCompactReportRoute: `./${surgicalTreatmentsCompactRoute}`,
  surgicalTreatmentsReportRoute: `./${surgicalTreatmentsRoute}`,
  surgicalFollowUpVisits: `./${surgicalFollowUpVisitsRoute}`,
  orthodonticQaEvaluatorReviewsReportRoute: `./${qaEvaluatorReviewsReportRoute}/orthodontic`,
  orthodonticQaCasesReportRoute: `./qa-ortho-cases`,
  speechQaEvaluatorReviewsReportRoute: `./${qaEvaluatorReviewsReportRoute}/speech`,
  surgicalQaEvaluatorReviewsReportRoute: `./${qaEvaluatorReviewsReportRoute}/surgical`,
  surgicalQaReviewerReliabilityRoute: `./qa-reviewer-reliability`,
  partnerSurgeryCountsReportRoute: `./${partnerSurgeryCountsReportRoute}`,
  partnerSurgeryCountsFoundationConnectReportRoute: `./${partnerSurgeryCountsReportRoute}/${PartnerSurgeryCountsReportTypes.FOUNDATION_CONNECT}`,
  surgeryStatsReportRoute: `./${surgeryStatsReportRoute}`,
  speechStatsReportRoute: `./${speechStatsReportRoute}`,
  orthodonticStatsReportRoute: `./${orthodonticStatsReportRoute}`,
  nutritionStatsReportRoute: `./${nutritionStatsReportRoute}`,
  nutritionReportRoute: `./${nutritionReportRoute}`
};

export const surgeryListOptions: ReportOptions[] = [
  {
    permission: PermissionEnum.GET_REPORT_SURGICAL_TREATMENTS,
    routes: [
      {
        route: routes.surgicalTreatmentsReportRoute,
        name: 'reports.surgical_treatments'
      }
    ]
  },
  {
    permission: PermissionEnum.GET_REPORT_SURGICAL_TREATMENTS_COMPACT,
    routes: [
      {
        route: routes.surgicalTreatmentsCompactReportRoute,
        name: 'reports.surgical_treatments_compact'
      }
    ]
  },
  {
    permission: PermissionEnum.GET_REPORT_FOLLOWUP_TREATMENTS,
    routes: [
      {
        route: routes.surgicalFollowUpVisits,
        name: 'reports.surgical_follow_up_visits'
      }
    ]
  },
  {
    permission: PermissionEnum.GET_REPORT_ORTHOGNATHIC_TREATMENTS,
    routes: [
      {
        route: routes.orthognathicTreatmentsReportRoute,
        name: 'treatment.orthognathic_treatment'
      }
    ]
  },
  {
    permission: PermissionEnum.GET_REPORT_PHOTO,
    routes: [
      {
        route: routes.photoReportRoute,
        name: 'reports.photo_report'
      }
    ]
  }
];

export const surgeryStatsOptions: ReportOptions[] = [
  {
    permission: PermissionEnum.GET_REPORT_SURGERY_STATS,
    routes: [
      {
        route: routes.surgeryStatsReportRoute,
        name: 'reports.surgery_stats'
      }
    ]
  },
  {
    permission: PermissionEnum.GET_REPORT_YEAR_TO_DATE,
    routes: [{ route: routes.yearToDateReportRoute, name: 'reports.year_to_date' }]
  },
  {
    permission: PermissionEnum.GET_REPORT_MONTH_END,
    routes: [
      {
        route: routes.monthEndSurgicalReportRoute,
        name: 'reports.month_end'
      },
      {
        route: routes.monthEndSurgicalReportRoute,
        queryParams: reportRegionRouteQueries.china,
        name: 'reports.month_end.china',
        region: ReportRegions.CHINA
      },
      {
        route: routes.monthEndSurgicalReportRoute,
        queryParams: reportRegionRouteQueries.philippines,
        name: 'reports.month_end.philippines',
        region: ReportRegions.PHILIPPINES
      },
      {
        route: routes.monthEndSurgicalReportRoute,
        queryParams: reportRegionRouteQueries.india,
        name: 'reports.month_end.india_audit',
        region: ReportRegions.INDIA
      }
    ]
  },
  {
    permission: PermissionEnum.GET_REPORT_MONTH_END,
    routes: [
      {
        route: routes.monthEndOrthognathicReportRoute,
        name: 'reports.month_end_orthognathic'
      }
    ]
  }
];

export const surgeryEvaluationOptions: ReportOptions[] = [
  {
    permission: PermissionEnum.GET_REPORT_QA_EVALUATOR_REVIEWS_SURGERY,
    routes: [
      {
        route: routes.surgicalQaEvaluatorReviewsReportRoute,
        name: 'reports.qa_reviewers'
      }
    ]
  }
  // TODO#30125 Add get report qa ortho cases route to orthodonticEvaluationOptions
  // ,
  // {
  //   permission: PermissionEnum.GET_REPORT_QA_REVIEWER_RELIABILITY,
  //   routes: [
  //     {
  //       route: routes.surgicalQaReviewerReliabilityRoute,
  //       name: 'reports.qa_reliability.link'
  //     }
  //   ]
  // }
];

export const speechListOptions: ReportOptions[] = [
  {
    permission: PermissionEnum.GET_REPORT_SPEECH_TREATMENTS,
    routes: [
      {
        route: routes.speechAssessmentRaportRoute,
        name: 'reports.treatment.speech_assessment'
      }
    ]
  },
  {
    permission: PermissionEnum.GET_REPORT_SPEECH_TREATMENTS,
    routes: [{ route: routes.speechTreatmentRaportRoute, name: 'reports.treatment.speech_treatment' }]
  }
];

export const speechStatsOptions: ReportOptions[] = [
  {
    permission: PermissionEnum.GET_REPORT_SPEECH_STATS,
    routes: [
      {
        route: routes.speechStatsReportRoute,
        name: 'reports.speech_stats'
      },
      {
        route: routes.speechStatsReportRoute,
        queryParams: reportRegionRouteQueries.india,
        name: 'reports.month_end.india',
        region: ReportRegions.INDIA,
        permission: PermissionEnum.GROUP_REPORT_RESULT_BY_PARTNER
      }
    ]
  }
];

export const orthodonticListOptions: ReportOptions[] = [
  {
    permission: PermissionEnum.GET_REPORT_ORTHODONTIC_TREATMENTS,
    routes: [
      {
        route: routes.orthodonticTreatmentsReportRoute,
        name: 'reports.orthodontic_treatments'
      }
    ]
  },
  {
    permission: PermissionEnum.GET_REPORT_ORTHODONTIC_TREATMENTS_COMPACT,
    routes: [
      {
        route: routes.orthodonticTreatmentsCompactReportRoute,
        name: 'reports.orthodontic_treatments_compact'
      }
    ]
  }
];

export const orthodonticStatsOptions: ReportOptions[] = [
  {
    permission: PermissionEnum.GET_REPORT_ORTHODONTIC_STATS,
    routes: [
      {
        route: routes.orthodonticStatsReportRoute,
        name: 'reports.orthodontic_stats'
      },
      {
        route: routes.orthodonticStatsReportRoute,
        queryParams: reportRegionRouteQueries.india,
        name: 'reports.month_end.india',
        region: ReportRegions.INDIA,
        permission: PermissionEnum.GROUP_REPORT_RESULT_BY_PARTNER
      }
    ]
  }
];

export const orthodonticEvaluationOptions: ReportOptions[] = [
  {
    permission: PermissionEnum.GET_REPORT_QA_EVALUATOR_REVIEWS_ORTHO,
    routes: [
      {
        route: routes.orthodonticQaEvaluatorReviewsReportRoute,
        name: 'reports.qa_reviewers'
      }
    ]
  }
  // TODO#30125 Add get report qa ortho cases route to orthodonticEvaluationOptions
  // ,
  // {
  //   permission: PermissionEnum.GET_REPORT_QA_ORTHO_CASES,
  //   routes: [
  //     {
  //       route: routes.orthodonticQaCasesReportRoute,
  //       name: 'reports.qa_ortho_cases'
  //     }
  //   ]
  // }
];

export const programsListOptions: ReportOptions[] = [
  {
    permission: PermissionEnum.GET_REPORT_PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT,
    routes: [
      {
        route: routes.partnerSurgeryCountsFoundationConnectReportRoute,
        name: 'reports.partner_surgery_counts_foundation_connect'
      }
    ]
  },
  {
    permission: PermissionEnum.GET_REPORT_PARTNER_SURGERY_COUNTS,
    routes: [
      {
        route: routes.partnerSurgeryCountsReportRoute,
        name: 'reports.partner_surgery_counts'
      }
    ]
  }
];

export const nutritionListOptions: ReportOptions[] = [
  {
    permission: PermissionEnum.GET_REPORT_NUTRITION_TREATMENTS,
    routes: [
      {
        route: routes.nutritionReportRoute,
        name: 'menu.resources.nutrition'
      }
    ]
  }
];

export const nutritionStatsOptions: ReportOptions[] = [
  {
    permission: PermissionEnum.GET_REPORT_NUTRITION_STATS,
    routes: [
      {
        route: routes.nutritionStatsReportRoute,
        name: 'reports.nutrition_stats'
      },
      {
        route: routes.nutritionStatsReportRoute,
        queryParams: reportRegionRouteQueries.india,
        name: 'reports.month_end.india',
        region: ReportRegions.INDIA,
        permission: PermissionEnum.GROUP_REPORT_RESULT_BY_PARTNER
      }
    ]
  }
];

export const reportListOptionsMap = new Map<string, ReportOptions[]>()
  .set('surgeryListOptions', surgeryListOptions)
  .set('surgeryStatsOptions', surgeryStatsOptions)
  .set('surgeryEvaluationOptions', surgeryEvaluationOptions)
  .set('speechListOptions', speechListOptions)
  .set('speechStatsOptions', speechStatsOptions)
  .set('orthodonticListOptions', orthodonticListOptions)
  .set('orthodonticStatsOptions', orthodonticStatsOptions)
  .set('nutritionListOptions', nutritionListOptions)
  .set('nutritionStatsOptions', nutritionStatsOptions)
  .set('orthodonticEvaluationOptions', orthodonticEvaluationOptions)
  .set('programsListOptions', programsListOptions);

export function checkRegion(
  subSink: SubSink,
  activatedRoute: ActivatedRoute,
  reportService: ReportService,
  hideGeoFilters: boolean
): ReportRegions {
  let reportRegion: ReportRegions;
  subSink.sink = activatedRoute.queryParams
    .pipe(
      filter(query => !!query.region),
      map(query => query.region)
    )
    .subscribe(region => {
      const filterName: ReportFilterName = 'region';

      if (hideGeoFilters) {
        reportService.setFilterVisibility('geography', false);
      }
      reportService.onFilterChange({
        filterName,
        payload: {
          [filterName]: [region]
        }
      });
      reportRegion = region;
    });
  return reportRegion;
}
