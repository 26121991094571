import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ImageRotatorModule } from '@shared/components/image-rotator/image-rotator.module';
import { MetadataModule } from '@shared/components/metadata/metadata.module';
import { ReadonlyImageComponent } from '@shared/components/readonly-image/readonly-image.component';
import { ReadonlyImageModule } from '@shared/components/readonly-image/readonly-image.module';
import { ValueSeparatorComponent } from '@shared/modules/general-commons/components/value-separator/value-separator.component';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { ReadonlyFieldComponent } from '@shared/modules/readonly-commons/components/readonly-field/readonly-field.component';
import { ReadonlyMediaFieldComponent } from '@shared/modules/readonly-commons/components/readonly-media-field/readonly-media-field.component';
import { ReadonlyMediaGroupComponent } from '@shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.component';
import {
  ReadonlyViewColumnDirective,
  ReadonlyViewColumnsComponent
} from '@shared/modules/readonly-commons/components/readonly-view-columns/readonly-view-columns.component';
import { ReadonlyViewImageComponent } from '@shared/modules/readonly-commons/components/readonly-view-image/readonly-view-image.component';
import { ReadonlyViewSectionHeaderComponent } from '@shared/modules/readonly-commons/components/readonly-view-section-header/readonly-view-section-header.component';
import { SimpleReadonlyFieldComponent } from '@shared/modules/readonly-commons/components/simple-readonly-field/simple-readonly-field.component';
import { TcLinkComponent } from '@shared/modules/readonly-commons/components/tc-link/tc-link.component';
import { AgeYearMonthPipe, AgeYearMonthPipeModule } from '@shared/modules/readonly-commons/pipes/age-year-month/age-year-month.pipe';
import { DictPipe } from '@shared/modules/readonly-commons/pipes/dict/dict.pipe';
import { UnitPipe } from '@shared/modules/readonly-commons/pipes/unit/unit.pipe';
import { YesNoPipe, YesNoPipeModule } from '@shared/modules/readonly-commons/pipes/yes-no/yes-no.pipe';
import { MediaModule } from '@src/app/features/media/media.module';
import { DataBlockModule } from '../../components/data-block/data-block.module';
import { IfEmptyPipeModule } from '../../pipes/if-empty.pipe';
import { InlineReadonlyFieldComponent } from './components/inline-readonly-field/inline-readonly-field.component';
import { ReadonlyViewAudioComponent } from './components/readonly-view-audio/readonly-view-audio.component';
import { ReadonlyViewVideoComponent } from './components/readonly-view-video/readonly-view-video.component';
import { FormatNumberPipe } from './pipes/number/stx-number.pipe';
import { NoticeComponentModule } from '@shared/components/notice/notice.component';

@NgModule({
  declarations: [
    DictPipe,
    ReadonlyFieldComponent,
    ReadonlyMediaFieldComponent,
    ReadonlyMediaGroupComponent,
    ReadonlyViewColumnDirective,
    ReadonlyViewColumnsComponent,
    ReadonlyViewImageComponent,
    ReadonlyViewSectionHeaderComponent,
    ReadonlyViewVideoComponent,
    SimpleReadonlyFieldComponent,
    TcLinkComponent,
    UnitPipe,
    InlineReadonlyFieldComponent,
    FormatNumberPipe,
    ReadonlyViewAudioComponent
  ],
  imports: [
    AgeYearMonthPipeModule,
    CommonModule,
    FlexModule,
    GeneralCommonsModule,
    ImageRotatorModule,
    MediaModule,
    MetadataModule,
    ReadonlyImageModule,
    RouterModule,
    TranslateModule.forChild(),
    YesNoPipeModule,
    IfEmptyPipeModule,
    DataBlockModule,
    NoticeComponentModule
  ],
  exports: [
    AgeYearMonthPipe,
    DictPipe,
    ReadonlyFieldComponent,
    ReadonlyImageComponent,
    ReadonlyMediaFieldComponent,
    ReadonlyMediaGroupComponent,
    ReadonlyViewColumnDirective,
    ReadonlyViewColumnsComponent,
    ReadonlyViewImageComponent,
    ReadonlyViewSectionHeaderComponent,
    ReadonlyViewVideoComponent,
    SimpleReadonlyFieldComponent,
    TcLinkComponent,
    UnitPipe,
    ValueSeparatorComponent,
    YesNoPipe,
    InlineReadonlyFieldComponent,
    FormatNumberPipe
  ]
})
export class ReadonlyCommonsModule {}
