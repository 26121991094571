<div class="date-container">
  <div *ngIf="noTreatmentDatesProvided">
    <a
      *ngIf="hasTreatmentAccess"
      [routerLink]="patientTreatmentPath"
      [queryParams]="{ patientId: treatment.patientId }"
      target="_self"
      (click)="$event.stopPropagation()"
      class="text-decoration-underline"
      >{{ treatment.created | date : 'yyyy-MM-dd' }}</a
    >
    <div *ngIf="!hasTreatmentAccess">
      {{ treatment.created | date : 'yyyy-MM-dd' }}
    </div>
    <div *ngIf="!hideAgeMonth">
      {{ treatment.ageMonth | ageYearMonth }}
    </div>
  </div>

  <div *ngIf="treatment.treatmentDate">
    <a
      *ngIf="hasTreatmentAccess"
      [routerLink]="patientTreatmentPath"
      [queryParams]="{ patientId: treatment.patientId }"
      target="_self"
      (click)="$event.stopPropagation()"
      class="text-decoration-underline"
      >{{ treatment.treatmentDate }}</a
    >
    <div *ngIf="!hasTreatmentAccess">
      {{ treatment.treatmentDate }}
    </div>
    <div *ngIf="!hideAgeMonth">
      {{ treatment.ageMonth | ageYearMonth }}
    </div>
  </div>

  <div *ngIf="treatment.startDate">
    <a
      *ngIf="hasTreatmentAccess"
      [routerLink]="patientTreatmentPath"
      [queryParams]="{ patientId: treatment.patientId }"
      target="_self"
      (click)="$event.stopPropagation()"
      class="text-decoration-underline"
      >{{ treatment.startDate }}</a
    >
    <div *ngIf="!hasTreatmentAccess">
      {{ treatment.startDate }}
    </div>
  </div>

  <div *ngIf="treatment.endDate">
    <a
      *ngIf="hasTreatmentAccess"
      [routerLink]="patientTreatmentPath"
      [queryParams]="{ patientId: treatment.patientId }"
      target="_self"
      (click)="$event.stopPropagation()"
      class="text-decoration-underline"
      >{{ treatment.endDate }}
    </a>
    <div *ngIf="!hasTreatmentAccess">
      {{ treatment.endDate }}
    </div>
  </div>

  <div *ngIf="shouldHaveEndDatePlaceholder">
    <a
      *ngIf="hasTreatmentAccess"
      [routerLink]="patientTreatmentPath"
      [queryParams]="{ patientId: treatment.patientId }"
      target="_self"
      (click)="$event.stopPropagation()"
      >____-__-__
    </a>
    <div *ngIf="!hasTreatmentAccess">____-__-__</div>
  </div>

  <div *ngIf="treatment.startDate && !hideAgeMonth">
    {{ treatment.ageMonth | ageYearMonth }}
  </div>
</div>
