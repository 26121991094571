<div [formGroup]="formGroupReference" *ngIf="formGroupReference">
  <mat-radio-group formControlName="rootOption" class="form-element-group-column">
    <ng-container *ngFor="let rootOption of optionsTree; first as first">
      <mat-radio-button
        data-testid="radio-tree-filter"
        class="display-block full-width"
        [name]="'rootOption'"
        [id]="rootOption.value"
        [value]="rootOption.value"
      >
        <stx-chevron-line
          *ngIf="rootOption.childrenOptions.length; else label"
          [isExpanded]="rootOption.visible"
          [showChevron]="showChevron"
          (toggle)="toggleChildrenOptions(rootOption.value)"
        >
          {{ rootOption.label | translate }}
        </stx-chevron-line>

        <ng-template #label>
          {{ rootOption.label | translate }}
        </ng-template>
      </mat-radio-button>

      <div *ngIf="rootOption.description" class="display-block full-width indented-form-block">
        <ng-container *ngFor="let line of rootOption.description">
          <span class="form-element-secondary-label">
            {{ line | translate }}
          </span>
        </ng-container>
      </div>

      <div
        class="display-block full-width indented-form-block form-element-group-column force-vt-spacing"
        *ngIf="rootOption.childrenOptions.length && rootOption.visible"
      >
        <stx-radio-group
          @childrenOptions
          [formGroup]="childrenOptionsGroup"
          [layoutDirection]="'column'"
          [options]="rootOption.childrenOptions"
          [controlName]="rootOption.value"
        >
        </stx-radio-group>
      </div>
    </ng-container>
  </mat-radio-group>
</div>
