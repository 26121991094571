<div [formGroup]="timeSelectionForm" class="flex-column-container flex-fill stx-form-field">
  <label *ngIf="label" class="form-element-label"> {{ label | translate }} {{ timeFormatSuffix | translate }}</label>

  <div class="flex-row-container">
    <mat-form-field appearance="outline" [ngClass]="{ 'mat-form-field-invalid': ngControl.touched && ngControl.invalid }">
      <mat-select [formControlName]="'hourControl'" (selectionChange)="writeValue(value)" (blur)="onTouched()">
        <mat-option *ngFor="let hourOption of hourOptions" [value]="hourOption.timeValue">
          {{ hourOption.timeValueLabel }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="p-t-15">&nbsp;:&nbsp;</div>
    <mat-form-field appearance="outline" [ngClass]="{ 'mat-form-field-invalid': ngControl.touched && ngControl.invalid }">
      <mat-select [formControlName]="'minuteControl'" (selectionChange)="writeValue(value)" (blur)="onTouched()">
        <mat-option *ngFor="let minuteOption of minuteOptions" [value]="minuteOption.timeValue">
          {{ minuteOption.timeValueLabel }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-error class="stx-mat-error-align" *ngIf="shouldShowValidationError()">
    <stx-error-message-wrapper>
      {{ 'error.time' | translate }}
    </stx-error-message-wrapper>
  </mat-error>
</div>
