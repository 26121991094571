import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationMessageModule } from '@shared/components/form/components/validation-message/validation-message.component';
import { FormModule } from '@shared/components/form/form.module';
import { TreatmentModule } from '@shared/components/treatment/treatment.module';
import { FormsCommonsModule } from '@shared/modules/forms-commons/forms-commons.module';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { MaterialModule } from '@shared/modules/material/material.module';
import { ReadonlyCommonsModule } from '@shared/modules/readonly-commons/readonly-commons.module';
import { NutritionAnemiaManagementComponent } from '@src/app/features/nutrition-management/components/nutrition-anemia-management/nutrition-anemia-management.component';
import { NutritionBirthweightComponent } from '@src/app/features/nutrition-management/components/nutrition-birthweight/nutrition-birthweight.component';
import { NutritionCurrentFeedingComponent } from '@src/app/features/nutrition-management/components/nutrition-current-feeding/nutrition-current-feeding.component';
import { NutritionGeneralInfoComponent } from '@src/app/features/nutrition-management/components/nutrition-general-info/nutrition-general-info.component';
import { NutritionAssessmentComponent } from '@src/app/features/nutrition-management/components/nutrition-assessment/nutrition-assessment.component';
import { NutritionFollowUpComponent } from '@src/app/features/nutrition-management/components/nutrition-follow-up/nutrition-follow-up.component';
import { NutritionManagementReadonlyComponent } from '@src/app/features/nutrition-management/components/nutrition-management-readonly/nutrition-management-readonly.component';
import { NutritionStageIndicatorComponent } from '@src/app/features/nutrition-management/components/nutrition-stage-indicator/nutrition-stage-indicator.component';
import { NutritionStatusSubtitleComponent } from '@src/app/features/nutrition-management/components/nutritional-status/nutrition-status-subtitle/nutrition-status-subtitle.component';
import { NutritionalStatusComponent } from '@src/app/features/nutrition-management/components/nutritional-status/nutritional-status.component';
import { SectionNumberPipe } from '@src/app/features/nutrition-management/shared/section-number.pipe';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NutritionFeedingAssessmentComponent } from 'src/app/features/nutrition-management/components/nutrition-feeding-assessment/nutrition-feeding-assessment.component';
import { NutritionPatientCareTypeComponent } from 'src/app/features/nutrition-management/components/nutrition-patient-care-type/nutrition-patient-care-type.component';
import { NutritionCarePlanComponent } from './components/nutrition-care-plan/nutrition-care-plan.component';
import { NutritionDecimalNumbersInputComponent } from './components/nutrition-decimal-numbers-input/nutrition-decimal-numbers-input.component';
import { LocalNamePipeModule } from '@shared/pipes/local-name.pipe';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false
  };
};
@NgModule({
  declarations: [
    NutritionalStatusComponent,
    NutritionFollowUpComponent,
    NutritionAssessmentComponent,
    NutritionGeneralInfoComponent,
    NutritionStageIndicatorComponent,
    SectionNumberPipe,
    NutritionBirthweightComponent,
    NutritionCurrentFeedingComponent,
    NutritionFeedingAssessmentComponent,
    NutritionCarePlanComponent,
    NutritionStatusSubtitleComponent,
    NutritionAnemiaManagementComponent,
    NutritionManagementReadonlyComponent,
    NutritionPatientCareTypeComponent,
    NutritionDecimalNumbersInputComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormModule,
    FormsCommonsModule,
    GeneralCommonsModule,
    MaterialModule,
    ReactiveFormsModule,
    ReadonlyCommonsModule,
    RouterModule,
    TranslateModule.forChild(),
    TreatmentModule,
    ValidationMessageModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    LocalNamePipeModule
  ],
  exports: [NutritionFollowUpComponent, NutritionAssessmentComponent]
})
export class NutritionManagementModule {}
