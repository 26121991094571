import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { AuthService } from '@/src/app/features/authentication/services/auth.service';
import { RecordType } from '@/src/app/features/patient/patient.enum';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { OrgTreeFilterModel } from '@/src/app/features/search/models/org-tree-filter.model';
import { SearchService } from '@/src/app/features/search/search.service';
import { getIdsFromItemFormControl, mapToRecordTypeEnum } from '@/src/app/features/search/search.utils';
import { repairTypeMap } from '@/src/app/features/surgical/utils/surgical.utils';
import { SuspiciousRecordsSearch } from '@/src/app/features/suspicious-records/models/suspicious-records-search.model';
import { SuspiciousRecordsService } from '@/src/app/features/suspicious-records/suspicious-records.service';
import { RadioGroupOption } from '@/src/app/shared/models/form.model';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { suspiciousRecordsFull } from '@/src/app/utils/routing.utils';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RecordDatePreset } from '@src/app/features/reports/components/report-filters/components/record-date-filter/record-date-filter.component';
import { getToday, isAfterDay } from '@utils/date.utils';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { of } from 'rxjs';

@Component({
  templateUrl: './suspicious-records-search-page.component.html'
})
export class SuspiciousRecordsSearchPageComponent implements OnInit {
  readonly PermissionEnum = PermissionEnum;
  suspiciousRecordsSearchForm: UntypedFormGroup;
  orgTreeFiltersData: OrgTreeFilterModel;
  repairTypes: RadioGroupOption[];
  recordTypes: RadioGroupOption[];
  searchFilters: SuspiciousRecordsSearch;
  datePreset: RecordDatePreset;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private searchService: SearchService,
    private cd: ChangeDetectorRef,
    private patientService: PatientService,
    private suspiciousRecordsService: SuspiciousRecordsService,
    private readonly wsHelperService: WsHelperService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setDatePreset();
    this.initializeSearchForm();
    this.getFilters();
    this.initStaticDictionaries();
  }

  review(skippedRecords: boolean = false): AsyncButtonClickAction {
    return () => {
      this.setRecordType();

      this.searchFilters = Object.assign(
        {},
        this.suspiciousRecordsSearchForm.value,
        this.getOrgTreeRequestParams(),
        this.getGeoographyTreeRequestParams()
      );

      this.searchFilters.skippedRecords = skippedRecords;

      this.suspiciousRecordsService.searchFilters.set(this.searchFilters);

      this.router.navigate([`${suspiciousRecordsFull}`]);

      return of(this.searchFilters);
    };
  }

  private initializeSearchForm(): void {
    this.suspiciousRecordsSearchForm = this.formBuilder.group({
      dateFrom: [],
      dateTo: [],
      datePreset: [],
      recordType: [],
      alveolarBoneGraft: [],
      fistulaRepair: [],
      lipNoseRevision: [],
      primaryCleftPalate: [],
      primaryLipNoseBilateral: [],
      primaryLipNoseUnilateral: [],
      secondaryCleftPalate: [],
      velopharyngealDisfunction: [],
      other: [],
      partners: [[]],
      treatmentCenters: [[]],
      crmRegions: [[]],
      countries: [[]],
      zoneCountries: [[]],
      provinces: [[]],
      zoneProvinces: [[]],
      crms: [[]]
    });
  }

  private getOrgTreeRequestParams(): Record<string, string[]> {
    const keysToMap = ['partners', 'treatmentCenters'];

    return getIdsFromItemFormControl(this.suspiciousRecordsSearchForm, keysToMap);
  }

  private getGeoographyTreeRequestParams(): Record<string, string[]> {
    const keysToMap = ['crms', 'crmRegions', 'countries', 'zoneCountries', 'provinces', 'zoneProvinces'];

    return getIdsFromItemFormControl(this.suspiciousRecordsSearchForm, keysToMap);
  }

  private initStaticDictionaries() {
    this.wsHelperService.callWithSpinner(this.patientService.getStaticDictionaries()).subscribe(dictionary => {
      this.recordTypes = dictionary.get('recordTypes');
      const surgicalInterventions = dictionary.get('surgicalInterventions');

      this.repairTypes = [];
      surgicalInterventions.forEach(type => {
        this.repairTypes.push({ ...type, value: repairTypeMap.get(type.value.toString()) });
      });

      this.cd.detectChanges();
    });
  }

  private setRecordType(): void {
    const recordTypeNumber: number = parseInt(this.suspiciousRecordsSearchForm.get('recordType').value, 10);
    const recordType: RecordType = mapToRecordTypeEnum(recordTypeNumber);
    this.suspiciousRecordsSearchForm.get('recordType').setValue(recordType);
  }

  private setDatePreset() {
    if (isAfterDay(getToday(), getToday().date(5))) {
      this.datePreset = 'THIS_MONTH';
    } else {
      this.datePreset = 'LAST_MONTH';
    }
  }

  private getFilters(): void {
    if (this.authService.checkUserPermission(PermissionEnum.GET_ORG_TREE_FILTERS)) {
      this.wsHelperService.callWithSpinner(this.searchService.getOrgTreeFilters()).subscribe(filters => {
        this.orgTreeFiltersData = filters;
        this.cd.detectChanges();
      });
    }
  }
}
