import { openImageViewer } from '@/src/app/features/image-viewer/image-viewer.utils';
import { optionalPlaceholderImage, requiredPlaceholderImage } from '@/src/app/utils/media.utils';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageRotatedEvent } from '../image-rotator/image-rotator.component';

@Component({
  selector: 'stx-readonly-image',
  templateUrl: './readonly-image.component.html',
  styleUrls: ['./readonly-image.component.scss']
})
export class ReadonlyImageComponent {
  @Output() rotated: EventEmitter<any> = new EventEmitter();
  @Input() showRawUrl: boolean;
  @Input() imageWidth: number = 400;
  @Input() heightAuto: boolean;
  @Input() fullWidthImage: boolean;
  @Input() highResUrl: string;
  @Input() lowResUrl: string;
  @Input() rawUrl: string;
  @Input() imageClass: string;
  @Input() altText: string = '';
  @Input() id: string = '';
  @Input() mediaId: string;
  @Input() mediaAnonymized = false;

  get imageWidthPx() {
    return this.heightAuto ? `${this.imageWidth}px` : '';
  }

  openImageViewer(): void {
    openImageViewer(this.mediaId);
  }

  onImageRotated(event: ImageRotatedEvent) {
    this.rotated.emit(event);
  }

  isPlaceholderImage(url: string): boolean {
    return url === requiredPlaceholderImage || url === optionalPlaceholderImage;
  }
}
