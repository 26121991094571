import { Component, Input } from '@angular/core';
import { ParentOrderNameImageConfig } from '@shared/components/form/components/image-group/image-group.model';
import { chunk } from 'lodash';
import { BaseFormElementComponent } from '../../../base-treatment-form/base-form-element.component';

type Row = (ParentOrderNameImageConfig | null)[];

@Component({
  selector: 'stx-image-group',
  templateUrl: './image-group.component.html',
  styleUrls: ['./image-group.component.scss']
})
export class ImageGroupComponent extends BaseFormElementComponent {
  private _columns = 2;
  private _imageConfigs: ParentOrderNameImageConfig[] = [];
  rows: Row[] = [];

  constructor() {
    super();
  }

  @Input() set imageConfigs(configs: ParentOrderNameImageConfig[]) {
    this._imageConfigs = configs;
    this.recalculateRows();
  }

  @Input() set columns(columns: number) {
    this._columns = columns;
    this.recalculateRows();
  }

  private recalculateRows() {
    this.rows = chunk(this._imageConfigs, this._columns);
  }
}
