import { Injectable } from '@angular/core';
import { FeatureEnum } from '@core/feature-toggle/feature.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { FeatureToggleDto } from '@core/feature-toggle/feature-toggle.utils';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {
  private enabledFeaturesPromise: Promise<FeatureEnum[]>;
  private enabledFeaturesValue: FeatureEnum[] = [];

  constructor(private readonly httpClient: HttpClient) {}

  initFeatureToggles(): Promise<FeatureEnum[]> {
    this.enabledFeaturesPromise = firstValueFrom(this.httpClient.get<FeatureToggleDto>(environment.apiUrl + '/features')).then(
      dto => {
        this.enabledFeaturesValue = dto.enabledFeatures;
        return dto.enabledFeatures;
      },
      _ => []
    );

    return this.enabledFeaturesPromise;
  }

  async isEnabled(feature: FeatureEnum): Promise<boolean> {
    return this.enabledFeaturesPromise.then(features => features.includes(feature));
  }

  getEnabledFeatures(): FeatureEnum[] {
    return this.enabledFeaturesValue;
  }
}
