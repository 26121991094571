import { ResourceSection } from '@/src/app/features/educational-resources/models/resource.model';
import { FileExtension, Mime } from '@/src/app/utils/file.utils';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationSubTabEnum } from '@shared/components/navigation/model/nav-item-enum.enum';

@Component({
  templateUrl: './user-guide-page.component.html'
})
export class UserGuidePageComponent {
  constructor(private readonly translateService: TranslateService) {}

  private readonly systemLangs: string[][] = [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']];

  readonly NavigationSubTabEnum = NavigationSubTabEnum;

  readonly firstCol: readonly ResourceSection[] = [
    {
      sectionTitle: 'patient',
      resources: [
        {
          label: 'patient.registration',
          resourceName: 'STX_patient_registration_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs,
          isSeparated: true
        },
        {
          label: 'patient.release_form',
          resourceName: 'STX_patient_release_form_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs,
          isSeparated: true
        },
        {
          label: 'patient.search_header',
          resourceName: 'STX_patient_search_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs,
          isSeparated: true
        }
      ]
    }
  ];

  readonly secondCol: readonly ResourceSection[] = [
    {
      sectionTitle: 'treatment.surgery',
      resources: [
        {
          label: 'treatment.surgical_intervention',
          resourceName: 'STX_surgery_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs
        },
        {
          label: 'treatment.surgery_follow_up',
          resourceName: 'STX_surgical_follow_up_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs
        }
      ],
      isSeparated: true
    },
    {
      sectionTitle: 'treatment.speech',
      resources: [
        {
          label: 'medical_forms.printable_forms.speech_assessment',
          resourceName: 'STX_speech_assessment_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs
        },
        {
          label: 'medical_forms.printable_forms.speech_treatment_report',
          resourceName: 'STX_speech_treatment_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs
        }
      ]
    }
  ];

  readonly thirdCol: readonly ResourceSection[] = [
    {
      sectionTitle: 'treatment.orthodontics',
      resources: [
        {
          resourceName: 'STX_PSIO_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs
        }
      ],
      isSeparated: true,
      useDivs: true
    },
    {
      sectionTitle: 'treatment.nutrition',
      resources: [
        {
          resourceName: 'STX_nutrition_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs
        }
      ],
      isSeparated: true,
      useDivs: true
    }
  ];

  readonly fourthCol: readonly ResourceSection[] = [
    {
      sectionTitle: `${this.translateService.instant('save_submit')}/${this.translateService.instant('submit')}`,
      isTitleTranslated: true,
      resources: [
        {
          resourceName: 'STX_save_and_submit_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs
        }
      ],
      isSeparated: true,
      useDivs: true
    },

    {
      sectionTitle: 'unlock.header',
      resources: [
        {
          resourceName: 'STX_unlock_record_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs
        }
      ],
      isSeparated: true,
      useDivs: true
    },
    {
      sectionTitle: 'delete.header',
      resources: [
        {
          resourceName: 'STX_delete_record_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs
        }
      ],
      isSeparated: true,
      useDivs: true
    },
    {
      sectionTitle: 'menu.resources',
      resources: [
        {
          resourceName: 'STX_resources_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs
        }
      ],
      isSeparated: true,
      useDivs: true
    },
    {
      sectionTitle: `${this.translateService.instant('partner.partner')} / ${this.translateService.instant(
        'treatment_center.treatment_center'
      )} / ${this.translateService.instant('practitioner')}`,
      isTitleTranslated: true,
      resources: [
        {
          resourceName: 'STX_org_tree_',
          mime: Mime.VIDEO,
          fileExtension: FileExtension.MP4,
          directory: 'video/',
          translationList: this.systemLangs
        }
      ],
      isSeparated: true,
      useDivs: true
    }
  ];
}
