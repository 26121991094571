<ng-container *ngIf="dataSource?.data.length">
  <mat-paginator
    [length]="totalResults"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [pageIndex]="currentPageIndex"
    (page)="changePage($event)"
  ></mat-paginator>
  <div class="data-table-container">
    <div class="data-table-wrapper">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z2" aria-describedby="data-table-container">
        <ng-container matColumnDef="treatmentCenter">
          <th mat-header-cell *matHeaderCellDef>{{ 'treatment_center.treatment_center' | translate }}</th>
          <td class="wrap-content" mat-cell *matCellDef="let recordData">
            <stx-simple-report-cell
              [rowInputs]="[recordData.treatmentCenter, recordData.country, recordData.province]"
            ></stx-simple-report-cell>
          </td>
        </ng-container>
        <ng-container matColumnDef="patient">
          <th mat-header-cell *matHeaderCellDef>{{ 'patient.patient' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <div class="align-middle flex-row-container">
              <stx-patient-link [showIcon]="false" [patientId]="recordData.patientId">
                <stx-simple-report-cell [rowInputs]="[recordData.hospitalRecordId]"></stx-simple-report-cell>
              </stx-patient-link>
              <stx-patient-link [patientHistoryRoute]="true" [patientId]="recordData.patientId"> </stx-patient-link>
            </div>
            <stx-simple-report-cell
              [rowInputs]="[recordData.hospitalRecordIdLoc, recordData.patientName, recordData.patientLocalName, recordData.patientId]"
              [inlineInputsToTranslate]="[recordData.gender]"
              [inlineInputs]="[recordData.dateOfBirth]"
            ></stx-simple-report-cell>
          </td>
        </ng-container>
        <ng-container matColumnDef="orthognathicTreatment">
          <th mat-header-cell *matHeaderCellDef>{{ 'treatment.orthognathic_treatment' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <stx-treatment-status-indicator
              [addBottomPadding]="true"
              [showLabel]="false"
              [treatmentStatus]="recordData.status"
            ></stx-treatment-status-indicator>
            <stx-treatment-date
              class="bold"
              [operationDate]="recordData.orthognathicTreatment"
              [operationDateLink]="{
                route: '/' + orthognathicRoute + '/' + recordData.businessId,
                params: {
                  patientId: recordData.patientId
                }
              }"
              [submitDate]="recordData.uploadDate"
            ></stx-treatment-date>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="sticky-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      [length]="totalResults"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="changePage($event)"
    ></mat-paginator>
  </div>
</ng-container>
<div *ngIf="reportTableDataService.dataReady && dataSource?.data.length === 0">
  <stx-empty-table-state></stx-empty-table-state>
</div>
