import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseHttpService } from '@shared/services/http/base-http.service';
import { HttpClient } from '@angular/common/http';
import { TableStructure } from '@shared/components/table/models/table-structure.model';
import {
  OrthodonticQaEvaluatorReviewsRecord,
  QaEvaluatorReviewType,
  QaEvaluatorReviewTypes,
  SurgicalQaEvaluatorReliabilityReviewsRecord,
  SurgicalQaEvaluatorReviewsRecord
} from './qa-evaluator-reviews-report.model';
import { AuthService } from '@src/app/features/authentication/services/auth.service';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Injectable({
  providedIn: 'root'
})
export class QaEvaluatorReviewsReportService extends BaseHttpService {
  private static readonly apiUrlsMap: Record<QaEvaluatorReviewType, string> = {
    orthodontic: '/qa/evaluator-reviews/ortho',
    surgical: '/qa/evaluator-reviews/surgical',
    surgicalReliability: '/qa/evaluator-trial-reviews/surgical'
  };
  surgicalReviewList$: Observable<TableStructure<SurgicalQaEvaluatorReviewsRecord>>;
  surgicalReliabilityReviewList$: Observable<TableStructure<SurgicalQaEvaluatorReliabilityReviewsRecord>>;
  orthodonticReviewList$: Observable<TableStructure<OrthodonticQaEvaluatorReviewsRecord>>;
  surgicalReviewDataReady = false;
  orthodonticReviewDataReady = false;
  surgicalReliabilityDataReady = false;
  private surgicalReviewListSubject: Subject<TableStructure<SurgicalQaEvaluatorReviewsRecord>> = new Subject();
  private surgicalReliabilityReviewListSubject: Subject<TableStructure<SurgicalQaEvaluatorReliabilityReviewsRecord>> = new Subject();
  private orthodonticReviewListSubject: Subject<TableStructure<OrthodonticQaEvaluatorReviewsRecord>> = new Subject();

  constructor(public httpClient: HttpClient, private authService: AuthService, private readonly wsHelperService: WsHelperService) {
    super(httpClient, { baseUrl: 'reports' });

    this.surgicalReviewList$ = this.surgicalReviewListSubject.asObservable();
    this.surgicalReliabilityReviewList$ = this.surgicalReliabilityReviewListSubject.asObservable();
    this.orthodonticReviewList$ = this.orthodonticReviewListSubject.asObservable();
  }

  prepareLists(rangeParam: string) {
    this.surgicalReviewDataReady = false;
    this.orthodonticReviewDataReady = false;
    this.surgicalReliabilityDataReady = false;
    if (this.authService.checkUserPermission(PermissionEnum.GET_REPORT_QA_EVALUATOR_REVIEWS_SURGERY)) {
      this.prepareSurgicalReviewHistory(rangeParam);
      this.prepareSurgicalReliabilityReviewHistory(rangeParam);
    }
    if (this.authService.checkUserPermission(PermissionEnum.GET_REPORT_QA_EVALUATOR_REVIEWS_ORTHO)) {
      this.prepareOrthodonticReviewHistory(rangeParam);
    }
  }

  prepareSurgicalReviewHistory(rangeParam: string) {
    const url = this.getApiUrl(QaEvaluatorReviewTypes.SURGICAL);

    this.wsHelperService
      .callWithSpinner(this.httpClient.get<TableStructure<SurgicalQaEvaluatorReviewsRecord>>(url, { params: { range: rangeParam } }))
      .subscribe({
        next: (response: TableStructure<SurgicalQaEvaluatorReviewsRecord>) => {
          this.surgicalReviewListSubject.next(response);
          this.surgicalReviewDataReady = true;
        },
        error: () => {
          this.surgicalReviewDataReady = true;
        }
      });
  }

  prepareSurgicalReliabilityReviewHistory(rangeParam: string) {
    const url = this.getApiUrl(QaEvaluatorReviewTypes.SURGICAL_RELIABILITY);

    this.wsHelperService
      .callWithSpinner(
        this.httpClient.get<TableStructure<SurgicalQaEvaluatorReliabilityReviewsRecord>>(url, { params: { range: rangeParam } })
      )
      .subscribe({
        next: (response: TableStructure<SurgicalQaEvaluatorReliabilityReviewsRecord>) => {
          this.surgicalReliabilityReviewListSubject.next(response);
          this.surgicalReliabilityDataReady = true;
        },
        error: () => {
          this.surgicalReliabilityDataReady = true;
        }
      });
  }

  prepareOrthodonticReviewHistory(rangeParam: string) {
    const url = this.getApiUrl(QaEvaluatorReviewTypes.ORTHODONTIC);

    this.wsHelperService
      .callWithSpinner(this.httpClient.get<TableStructure<OrthodonticQaEvaluatorReviewsRecord>>(url, { params: { range: rangeParam } }))
      .subscribe({
        next: (response: TableStructure<OrthodonticQaEvaluatorReviewsRecord>) => {
          this.orthodonticReviewDataReady = true;
          this.orthodonticReviewListSubject.next(response);
        },
        error: () => {
          this.orthodonticReviewDataReady = true;
        }
      });
  }

  private getApiUrl(type: QaEvaluatorReviewType) {
    return `${this.baseUrl}${QaEvaluatorReviewsReportService.apiUrlsMap[type]}`;
  }
}
