import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import { QaEvaluatorReviewsReportTableComponentModule } from './components/qa-evaluator-reviews-report-table/qa-evaluator-reviews-report-table.component';
import { QaEvaluatorReviewsSectionComponentModule } from './components/qa-evaluator-reviews-section/qa-evaluator-reviews-section.component';
import { QaEvaluatorReviewType, QaEvaluatorReviewTypes } from './qa-evaluator-reviews-report.model';
import { QaEvaluatorReviewsReportService } from './qa-evaluator-reviews-report.service';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { PermissionModule } from '@core/permissions/permission.module';
import { getStaticFileUrl } from '@utils/media.utils';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'stx-qa-evaluator-reviews-report',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './qa-evaluator-reviews-report.component.html',
  styleUrls: ['./qa-evaluator-reviews-report.component.scss']
})
export class QaEvaluatorReviewsReportComponent extends BaseComponent implements OnInit {
  readonly PermissionEnum = PermissionEnum;
  readonly QaReviewsReportRange = QaReviewsReportRange;
  reviewType: QaEvaluatorReviewType;
  QaEvaluatorReviewTypes = QaEvaluatorReviewTypes;

  constructor(
    public qaEvaluatorReviewsReportService: QaEvaluatorReviewsReportService,
    public reportService: ReportService,
    private route: ActivatedRoute
  ) {
    super();

    this.watchRouteParams();
  }

  ngOnInit(): void {
    this.subSink.sink = this.route.queryParams.subscribe(params => {
      let range = QaReviewsReportRange.LAST_TWO_YEARS;
      if (!!params.range) {
        range = params.range;
      }
      this.qaEvaluatorReviewsReportService.prepareLists(range);
    });
  }

  watchRouteParams() {
    this.subSink.sink = this.route.data.subscribe(data => {
      this.reviewType = data.qaReportType;
    });
  }

  get oldStatsUrl(): string {
    return getStaticFileUrl('pdf/QA_surgical_reviewer_period_count-OLD.csv');
  }
}

@NgModule({
  declarations: [QaEvaluatorReviewsReportComponent],
  imports: [
    TranslateModule,
    CommonModule,
    QaEvaluatorReviewsReportTableComponentModule,
    FlexModule,
    QaEvaluatorReviewsSectionComponentModule,
    ReportHeaderComponentModule,
    GeneralCommonsModule,
    RouterModule,
    PermissionModule,
    MatIconModule
  ],
  exports: [QaEvaluatorReviewsReportComponent]
})
export class QaEvaluatorReviewsReportComponentModule {}

export enum QaReviewsReportRange {
  LAST_YEAR = 'LAST_YEAR',
  LAST_TWO_YEARS = 'LAST_TWO_YEARS',
  ALL = 'ALL'
}
