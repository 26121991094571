import { Component, Input } from '@angular/core';

@Component({
  selector: 'stx-readonly-date-control',
  templateUrl: './readonly-date-control.component.html',
  styleUrls: ['./readonly-date-control.component.scss']
})
export class ReadonlyDateControlComponent {
  @Input() label: string;
  @Input() date: moment.Moment;
}
