import { OrthoSharedGeneralInfoComponent } from '@/src/app/features/ortho/components/shared/ortho-shared-general-info/ortho-shared-general-info.component';
import { DentitionTreatment } from '@/src/app/features/ortho/models/dentition-treatment.model';
import { MidAssessment } from '@/src/app/features/ortho/models/mid-assessment.model';
import { MixedDentitionAssessment } from '@/src/app/features/ortho/models/mixed-dentition-assessment.model';
import { OrthoStageValidationData } from '@/src/app/features/ortho/models/ortho-stage-validation-data.model';
import { PermanentDentitionAssessment } from '@/src/app/features/ortho/models/permanent-dentition-assessment.model';
import { PsioAssessment } from '@/src/app/features/ortho/models/psio-assessment.model';
import { PsioTreatment } from '@/src/app/features/ortho/models/psio-treatment.model';
import { OrthoFormApiBase } from '@/src/app/features/ortho/shared/ortho-form-api-base';
import { OrthoStage } from '@/src/app/features/ortho/utils/ortho-stage';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { BaseTreatmentFormComponent } from '@/src/app/shared/components/base-treatment-form/base-treatment-form.component';
import { FormActionFlowControl } from '@/src/app/shared/components/base-treatment-form/form-action-flow-control.models';
import { SpinnerService } from '@/src/app/shared/components/spinner/service/spinner.service';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { GlobalErrorHandlerService } from '@/src/app/shared/services/global-error-handler.service';
import { SnackBarService } from '@/src/app/shared/services/snack-bar.service';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { getNextDay } from '@/src/app/utils/date.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import moment from 'moment';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';
import { PatientId, patientIdAsNumber } from '@src/app/features/patient/models/patient.model';

export type OrthoTreatmentFormModel =
  | PsioTreatment
  | DentitionTreatment
  | PsioAssessment
  | MixedDentitionAssessment
  | PermanentDentitionAssessment
  | MidAssessment;
@Component({ template: '' })
export abstract class BaseOrthoFormComponent<T extends OrthoTreatmentFormModel> extends BaseTreatmentFormComponent<T> {
  protected datesForValidation: OrthoStageValidationData | null = null;
  private readonly _orthoFormService: OrthoFormApiBase<T>;
  protected readonly fistulaMainField = 'fistula';
  @Input() orthoFormModel: T;

  constructor(
    elementRef: ElementRef,
    zone: NgZone,
    snackBarService: SnackBarService,
    spinnerService: SpinnerService,
    router: Router,
    activatedRoute: ActivatedRoute,
    changeDetector: ChangeDetectorRef,
    globalErrorHandlerService: GlobalErrorHandlerService,
    wsHelper: WsHelperService,
    patientService: PatientService,
    orthoFormService: OrthoFormApiBase<T>
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      changeDetector,
      globalErrorHandlerService,
      wsHelper,
      patientService
    );
    this._orthoFormService = orthoFormService;
  }

  protected override beforeValidationAsync(): Observable<FormActionFlowControl> {
    return super.beforeValidationAsync().pipe(
      mergeMap(() => this.fetchAdditionalDataForSetUp()),
      tap({
        error: error => BaseOrthoFormComponent.handleGetValidationDataError(error, this.globalErrorHandlerService)
      })
    );
  }

  private get isAssessment() {
    return (
      this.formType === FormType.ORTHO_MD_ASSESSMENT ||
      this.formType === FormType.ORTHO_PD_ASSESSMENT ||
      this.formType === FormType.ORTHO_PSIO_ASSESSMENT
    );
  }

  private get isTreatment() {
    return (
      this.formType === FormType.ORTHO_MD_TREATMENT ||
      this.formType === FormType.ORTHO_PD_TREATMENT ||
      this.formType === FormType.ORTHO_PSIO_TREATMENT
    );
  }

  protected callFetchAdditionalDataForSetUp(): void {
    this.subSink.sink = this.wsHelper
      .call(this.fetchAdditionalDataForSetUp(), {
        handleErrorCallback: BaseOrthoFormComponent.handleGetValidationDataError
      })
      .subscribe();
  }

  protected fetchAdditionalDataForSetUp(): Observable<FormActionFlowControl> {
    // can only retrieve validation data for assessment when it has id set
    if (this.isAssessment && !this.orthoFormModel?.id) {
      return of({ proceed: true });
    }
    return this.fetchValidationDatesFromOtherStages(this.patient.id, this.orthoFormModel?.id);
  }

  private fetchValidationDatesFromOtherStages(patientId: PatientId, orthoRecordId?: TreatmentId): Observable<FormActionFlowControl> {
    return this._orthoFormService.getValidationData(patientId, orthoRecordId).pipe(
      tap(validationData => {
        if (validationData) {
          this.datesForValidation = validationData;
          if (this.isTreatment) {
            this.setReadonlyStartDate(validationData.assessmentDate);
          }
          this.changeDetector.markForCheck();
        }
      }),
      map(validationData => (!!validationData ? { proceed: true } : { proceed: false }))
    );
  }
  private setReadonlyStartDate(startDate: moment.Moment) {
    this.treatmentFormGroup.patchValue({ startDate: startDate });
  }

  get startDateMaxValue(): moment.Moment {
    return (
      this.datesForValidation?.earliestMidEvaluationDate ??
      this.treatmentFormGroup?.get(OrthoSharedGeneralInfoComponent.careProvidedEndDate)?.value ??
      this.today
    );
  }
  get startDateMinValue(): moment.Moment {
    return this.datesForValidation?.assessmentDate ?? this.patient.dateOfBirth;
  }
  get endDateMinValue(): moment.Moment {
    return (
      this.datesForValidation?.latestMidEvaluationDate ??
      getNextDay(this.treatmentFormGroup.get(OrthoSharedGeneralInfoComponent.getOrthoCareProvidedDate(OrthoStage.TREATMENT))?.value)
    );
  }

  get minPsioAssessmentDate() {
    return moment(this.patient.dateOfBirth);
  }

  get maxPsioAssessmentDate() {
    return this.datesForValidation?.treatmentEndDate ?? this.today;
  }

  get minDentitionAssessmentDate() {
    return getNextDay(this.patient.dateOfBirth);
  }

  get maxDentitionAssessmentDate() {
    return this.datesForValidation?.earliestMidEvaluationDate ?? this.datesForValidation?.treatmentEndDate ?? this.today;
  }
  get minEvaluationDate(): moment.Moment {
    return this.datesForValidation?.assessmentDate ?? this.patient.dateOfBirth;
  }

  get maxEvaluationDate(): moment.Moment {
    return this.datesForValidation?.treatmentEndDate ?? this.today;
  }

  private static handleGetValidationDataError = (error: HttpErrorResponse, errorHandlerService: GlobalErrorHandlerService) => {
    if (GlobalErrorHandlerService.isResourceNotFoundError(error)) {
      errorHandlerService.handleErrorAndInformUserWithAlertDialog(error, {
        title: 'error.treatment_action',
        message: error?.error?.translationKey
      });
    } else {
      errorHandlerService.handleErrorAndInformUser(error);
    }
  };

  get startDate(): moment.Moment {
    return this.datesForValidation.assessmentDate;
  }

  protected getRequiredValidationControlNames(
    hasFistulaFieldNotBeenTouched: boolean,
    formGroup: UntypedFormGroup,
    controlNamesForFullValidation: string[]
  ): string[] {
    if (hasFistulaFieldNotBeenTouched) {
      return controlNamesForFullValidation;
    } else if (!hasFistulaFieldNotBeenTouched && !!formGroup.get(this.fistulaMainField)?.value) {
      return controlNamesForFullValidation;
    } else {
      return controlNamesForFullValidation.filter(controlName => !controlName.includes(this.fistulaMainField));
    }
  }
}
