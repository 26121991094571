<div class="container">
  <div>
    <div class="align-middle flex-row-container gap-20">
      <mat-icon *ngIf="print" class="m-r-10">photo_camera</mat-icon>
      <label class="image-label form-label display-block" *ngIf="label"
        >{{ label | translate }} <ng-container *ngIf="!isRequired">{{ 'optional' | translate }}</ng-container>
      </label>
      <div class="not-print" [class.display-none]="uppyWithPreview?.placeholderShown">
        <stx-image-rotator
          class="align-center layout-align-center display-flex"
          [imageNativeElement]="uppyWithPreview?.previewImage?.nativeElement"
          [imageContainerNativeElement]="uppyWithPreview?.previewImageContainer?.nativeElement"
          [heightAuto]="true"
        ></stx-image-rotator>
      </div>
    </div>

    <form [formGroup]="formGroup" [ngClass]="{ 'hide-on-print': print }">
      <stx-uppy-with-preview
        [parentOrderName]="parentOrderName"
        [isReadonly]="formMode === FormMode.READONLY"
        [isRequired]="isRequired"
        [formControlName]="controlName"
        [print]="print"
        [label]="label"
        [uuid]="uuid"
      ></stx-uppy-with-preview>
    </form>

    <mat-error class="stx-mat-error-align" *ngIf="showStandardError(formGroup, controlName)">{{
      'error.upload_photo' | translate
    }}</mat-error>

    <mat-error class="stx-mat-error-align" *ngIf="showError">{{ 'error.upload_photo_empty' | translate }}</mat-error>
  </div>
</div>
