<stx-modal>
  <ng-container>
    <div class="flex-row-container">
      <div class="text-important m-b-25 bold">
        {{ dialogHeader | translate }}
      </div>
    </div>
    <div class="flex-row-container">
      <div class="text-important m-b-25">
        {{ dialogConfirmation | translate }}
      </div>
    </div>
    <stx-action-buttons
      [primaryText]="'dialog.yes' | translate"
      (primaryAction)="confirm()"
      [secondaryText]="'dialog.back' | translate"
      (secondaryAction)="back()"
    ></stx-action-buttons>
  </ng-container>
</stx-modal>
