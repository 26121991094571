/**
 * Config for partial validation actions (e.g. save, discontinue)
 * All non-required validators are checked by default
 */

export interface PartialValidationConfig {
  /**
   * Control names that will have all their validators (including required) checked
   */
  controlNamesForFullValidation: string[];

  /**
   * Configuration for the nested form groups
   */
  nestedPartialValidationConfigs?: NestedPartialValidationConfigs;

  /**
   * Cross-field validation errors (formGroup-level) that will be checked. By default the validators attached at FormGroup level are NOT checked for partial validation.
   */
  crossFieldErrorNames?: string[];
}

export const emptyPartialValidationConfig: PartialValidationConfig = { controlNamesForFullValidation: [] };

export type NestedPartialValidationConfigs = { [key: string]: PartialValidationConfig };
