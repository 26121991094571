import { MediaUrl } from '@/src/app/features/media/models/media-url.model';
import { DisplayFileInfo, FileInfo } from '@/src/app/features/media/models/media.model';
import { MediaComponentBaseComponent } from '@/src/app/shared/components/uppy/components/media-component-base/media-component-base.component';
import { MediaContentType } from '@/src/app/shared/enums/media.enum';
import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { LoggerService } from '@/src/app/shared/services/logger.service';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { Mime } from '@utils/file.utils';
import { FormMode } from '@utils/form.utils';
import { noop } from 'rxjs';
import { UppyErrorService } from '@shared/components/uppy/uppy-error.service';

@Component({
  selector: 'stx-uppy-audio-video',
  templateUrl: './uppy-audio-video.component.html',
  styleUrls: ['./uppy-audio-video.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UppyAudioVideoComponent),
      multi: true
    }
  ]
})
export class UppyAudioVideoComponent extends MediaComponentBaseComponent implements ControlValueAccessor {
  readonly audioVideoAcceptedTypes = [Mime.VIDEO, Mime.AUDIO];
  readonly FormMode = FormMode;

  uploadedFileUrlSanitized: SafeUrl;

  @Input() formMode: FormMode;
  @Input() parentOrderNameToSet: ParentOrderName;

  uploadedFileName: string;
  mediaType: MediaContentType;
  showFileDownload: boolean;

  onTouchedFn: () => void = noop;
  onChangedFn: (_: FileInfo) => void = (_: FileInfo) => {
    /* This is part of ControlValueAccessor base impl */
  };

  constructor(
    loggerService: LoggerService,
    globalErrorHandlerService: GlobalErrorHandlerService,
    private domSanitizer: DomSanitizer,
    protected uppyErrorService: UppyErrorService
  ) {
    super(loggerService, globalErrorHandlerService, uppyErrorService);
  }

  registerOnChange(fn: any): void {
    this.onChangedFn = fn;
  }

  writeValue(singleControlRawAndConvertedUrls?: MediaUrl[]): void {
    this.uploadedFileName = null;
    this.mediaType =
      singleControlRawAndConvertedUrls && singleControlRawAndConvertedUrls.length > 0
        ? singleControlRawAndConvertedUrls[0].mediaType
        : null;
    this.showFileDownload = true;
    this.setNewValue(singleControlRawAndConvertedUrls);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // empty body for value accessor
  }

  uploadedFile(fileInfo: FileInfo) {
    this.uppyErrorService.resetBy(this.uuid);
    this.uploadedFileName = fileInfo.fileName;
    this.revokeUrlIfExists(this.previousUploadedFileUrl);
    this.previousUploadedFileUrl = this.uploadedFileUrl;
    this.onChangedFn({ ...fileInfo, parentOrderName: this.parentOrderNameToSet });
    this.showFileDownload = true;
  }

  displayFile(displayFileInfo: DisplayFileInfo) {
    super.displayFile(displayFileInfo);
    this.uploadedFileUrlSanitized = this.domSanitizer.bypassSecurityTrustUrl(this.uploadedFileUrl);
    this.mediaType = displayFileInfo.mediaContentType;
    this.showFileDownload = false;
  }

  get showPlaceholder(): boolean {
    return !this.rawUrl && !this.convertedUrl && !this.uploadedFileUrlSanitized;
  }

  uploadError() {
    this.rawUrl = undefined;
    this.convertedUrl = undefined;
    this.uploadedFileUrlSanitized = undefined;
  }

  get showUploadedFileName(): boolean {
    return this.uploadedFileName && this.showFileDownload;
  }

  get showVideoPlayer(): boolean {
    return this.mediaType === MediaContentType.VIDEO;
  }

  get showAudioPlayer(): boolean {
    return this.mediaType === MediaContentType.AUDIO;
  }

  touched() {
    this.onTouchedFn();
  }

  restoreOldPreview() {
    super.restoreOldPreview();
    this.showFileDownload = true;
    if (this.uploadedFileUrl) {
      this.uploadedFileUrlSanitized = this.domSanitizer.bypassSecurityTrustUrl(this.uploadedFileUrl);
      return;
    }
    this.uploadedFileUrlSanitized = undefined;
    if (this.showPlaceholder) {
      this.mediaType = undefined;
    }
  }
}
