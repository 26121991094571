import { ErrorData } from '@/src/app/shared/components/error-handling/error-data.model';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'stx-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ErrorData) {}

  get alertTitle() {
    return this.data?.title ?? 'error.something_wrong_title';
  }

  get alertBody() {
    return this.data?.message ?? 'error.contact_admin_and_pause_work';
  }
}
