import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnChanges, Output } from '@angular/core';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { PatientLinkComponentModule } from 'src/app/features/reports/components/patient-link/patient-link.component';
import { QaReviewerReliabilityRecord } from '@src/app/features/reports/components/report-filters/models/report-data.model';
import { QaReviewReliabilityColumn, QaReviewReliabilityViewRecord } from './qa-reviewer-reliability.model';
import { ReportUtilsService } from '@src/app/features/reports/components/report-filters/services/report-utils.service';
import { TableStructure } from '@shared/components/table/models/table-structure.model';

@Component({
  selector: 'stx-qa-reviewer-reliability-records',
  templateUrl: './qa-reviewer-reliability-records.component.html'
})
export class QaReviewerReliabilityRecordsComponent implements OnChanges {
  static readonly nonIterableColumns = new Set<QaReviewReliabilityColumn>([
    'firstName',
    'firstNameLoc',
    'lastName',
    'lastNameLoc',
    'middleName',
    'middleNameLoc',
    'patientId'
  ]);
  private static readonly booleanColumns: QaReviewReliabilityColumn[] = ['invalid'];
  private static readonly percentageColumns: QaReviewReliabilityColumn[] = ['accuracy'];

  @Input() recordsPage: TableStructure<QaReviewerReliabilityRecord>;
  @Output() pageChange = new EventEmitter<PaginationSortingModel>();

  columnsAll: Array<string | QaReviewReliabilityColumn> = [];
  columnsIterable: QaReviewReliabilityColumn[] = [];
  headers: Record<string, string> = {
    patient: 'patient.patient'
  };

  pagination: PaginationSortingModel = {
    pageNumber: 0,
    pageSize: 25,
    sortedBy: '',
    sortDirection: ''
  };
  pageSizes = [25, 100];

  records: QaReviewReliabilityViewRecord[] = [];

  constructor(private reportUtilsService: ReportUtilsService) {}

  ngOnChanges() {
    this.setColumns();
    this.setHeaders();
    this.setRecords();
  }

  changePage(page: PageEvent) {
    this.pagination.pageNumber = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pageChange.emit(this.pagination);
  }

  sort(event: Sort) {
    this.pagination.sortedBy = event.active;
    this.pagination.sortDirection = event.direction;
    this.pageChange.emit(this.pagination);
  }

  private setHeaders() {
    this.headers = this.reportUtilsService.getPageHeaders({
      columns: this.recordsPage.labels,
      columnsToIgnore: QaReviewerReliabilityRecordsComponent.nonIterableColumns,
      mergeWith: this.headers
    });
  }

  private setColumns() {
    this.columnsIterable = this.reportUtilsService.getColumnsList(
      this.recordsPage.labels,
      QaReviewerReliabilityRecordsComponent.nonIterableColumns
    );

    this.columnsAll = ['patient', ...this.columnsIterable];
  }

  private setRecords() {
    this.records = this.recordsPage.data.page.content
      .map(record =>
        this.reportUtilsService.translateRecordBooleanColumns<QaReviewReliabilityViewRecord>(
          record,
          QaReviewerReliabilityRecordsComponent.booleanColumns
        )
      )
      .map(record =>
        this.reportUtilsService.formatRecordNumericColumns({
          record,
          columns: QaReviewerReliabilityRecordsComponent.percentageColumns,
          postfix: '%',
          precision: 0
        })
      );
  }
}

@NgModule({
  imports: [
    CommonModule,
    EmptyTableStateComponentModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    PatientLinkComponentModule,
    TranslateModule
  ],
  declarations: [QaReviewerReliabilityRecordsComponent],
  exports: [QaReviewerReliabilityRecordsComponent]
})
export class QaReviewerReliabilityRecordsModule {}
