import { getToday } from '@/src/app/utils/date.utils';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { DateFormControl } from '@shared/components/form/components/date/date-form-control';
import { PatientDataForReleaseForm } from '@shared/components/patient-document-upload/patient-release-form.model';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { CountryDictionary, DistrictDictionary, ProvinceDictionary } from '@shared/models/geo.model';
import { findMediaUrl } from '@shared/models/media-resource.model';
import { TreatmentCenterDictionary } from '@shared/models/treatment-center.model';
import { atLeastOneRequired, errorIf, invalidIf, requiredIfValidator, StxValidators } from '@shared/validation/validators';
import { PartnerDictionary } from '@src/app/features/partner/models/partner-dictionary-model';
import { Diagnosis, Patient, PatientAddress } from '@src/app/features/patient/models/patient.model';
import { chinaCountryId, indiaCountryId } from '@utils/constants.utils';
import { findElementFromListById, validValue$, validWithInitialValue$, yesNoDontKnow } from '@utils/form.utils';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, concat, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { isBoolean } from 'lodash';

interface MainControls {
  partnerId: UntypedFormControl;
  treatmentCenterId: UntypedFormControl;
  recordNumberId: UntypedFormControl;
  recordNumberIdLoc: UntypedFormControl;
  lastName: UntypedFormControl;
  firstName: UntypedFormControl;
  middleName: UntypedFormControl;
  lastNameLoc: UntypedFormControl;
  firstNameLoc: UntypedFormControl;
  middleNameLoc: UntypedFormControl;
  gender: UntypedFormControl;
  dateOfBirth: UntypedFormControl;
  dateOfBirthEstimated: UntypedFormControl;
  race: UntypedFormControl;
  guardianFirstName: UntypedFormControl;
  guardianLastName: UntypedFormControl;
  guardianRelationship: UntypedFormControl;
  knowAboutSt: UntypedFormControl;
  referringOrg: UntypedFormControl;
}

type DiagnosisControls = { [P in keyof Diagnosis]: UntypedFormControl };
type AddressControls = { [P in keyof PatientAddress]: UntypedFormControl };

export class PatientFormModel extends BaseComponent {
  static readonly fieldsRequiredForSave: (keyof MainControls)[] = [
    'partnerId',
    'treatmentCenterId',
    'firstName',
    'lastName',
    'recordNumberId',
    'recordNumberIdLoc',
    'gender',
    'dateOfBirth',
    'firstNameLoc',
    'lastNameLoc'
  ];

  readonly mainControls: Readonly<MainControls>;
  readonly diagnosisControls: Readonly<DiagnosisControls & { pregnancyLengthUnknown: UntypedFormControl }>;
  readonly addressControls: Readonly<AddressControls>;
  readonly partner$: Observable<PartnerDictionary>;
  readonly treatmentCenter$: BehaviorSubject<TreatmentCenterDictionary | null>;
  readonly country$: Observable<CountryDictionary>;
  readonly province$: Observable<ProvinceDictionary>;
  readonly formGroup: UntypedFormGroup;
  readonly diagnosisFormGroup: UntypedFormGroup;
  readonly addressFormGroup: UntypedFormGroup;
  readonly patientDataForReleaseFormControls: AbstractControl[];
  readonly patientDataForReleaseForm: BehaviorSubject<PatientDataForReleaseForm | null>;
  readonly offlineReleaseForm: UntypedFormControl;
  readonly onlineReleaseForm: UntypedFormControl;
  private readonly cleftFieldsFormControls: AbstractControl[];
  private readonly releaseFormRequiredError = 'releaseFormRequired';
  private readonly allCleftFieldsEqualNotCleftError = 'allCleftFieldsEqualNotCleft';
  private readonly allCleftFieldsEqualNotCleft$ = new BehaviorSubject<boolean>(false);
  private isDistrictRequired: boolean;

  set districtRequired(value: boolean) {
    this.isDistrictRequired = value;
  }

  private isPositiveNumber = (value: unknown) => /^\d+(\.\d)?$/.test(value.toString());

  constructor(
    partners: Observable<PartnerDictionary[] | null>,
    treatmentCenters: Observable<TreatmentCenterDictionary[] | null>,
    countries: Observable<CountryDictionary[] | null>,
    provinces: Observable<ProvinceDictionary[] | null>,
    districtList$: BehaviorSubject<DistrictDictionary[] | null>
  ) {
    super();
    const now$ = new BehaviorSubject<moment.Moment>(getToday());
    this.mainControls = {
      partnerId: new UntypedFormControl(null, StxValidators.required),
      treatmentCenterId: new UntypedFormControl(null, StxValidators.required),
      recordNumberId: new UntypedFormControl(null, [StxValidators.required, StxValidators.maxLength64]),
      recordNumberIdLoc: new UntypedFormControl(null, [
        requiredIfValidator(() => this.isIndianTcSelected || this.isChineseTcSelected),
        StxValidators.maxLength64
      ]),
      lastName: new UntypedFormControl(null, [StxValidators.required, StxValidators.maxLength64]),
      firstName: new UntypedFormControl(null, [StxValidators.required, StxValidators.maxLength64]),
      middleName: new UntypedFormControl(null, StxValidators.maxLength64),
      lastNameLoc: new UntypedFormControl(null, [requiredIfValidator(() => this.isChineseTcSelected), StxValidators.maxLength64]),
      firstNameLoc: new UntypedFormControl(null, [requiredIfValidator(() => this.isChineseTcSelected), StxValidators.maxLength64]),
      middleNameLoc: new UntypedFormControl(null, StxValidators.maxLength64),
      gender: new UntypedFormControl(null, StxValidators.required),
      dateOfBirth: new DateFormControl(null, {
        maxDate: () =>
          combineLatest([this.evaluationDateMoment$, now$]).pipe(
            startWith([now$.value]),
            map(dates => moment.min(dates))
          ),
        validators: [StxValidators.required]
      }),
      dateOfBirthEstimated: new UntypedFormControl(null),
      race: new UntypedFormControl(null, StxValidators.required),
      guardianFirstName: new UntypedFormControl(null, [StxValidators.required, Validators.maxLength(128)]),
      guardianLastName: new UntypedFormControl(null, [StxValidators.required, StxValidators.maxLength64]),
      guardianRelationship: new UntypedFormControl(null, StxValidators.required),
      knowAboutSt: new UntypedFormControl(null, StxValidators.required),
      referringOrg: new UntypedFormControl(null, Validators.maxLength(255))
    };

    this.diagnosisControls = {
      pregnancyLength: new UntypedFormControl(null, [
        requiredIfValidator(() => !this.diagnosisControls.pregnancyLengthUnknown.value),
        invalidIf(value => !!value && !this.isPositiveNumber(value)),
        Validators.min(6),
        Validators.max(11)
      ]),
      pregnancyLengthUnknown: new UntypedFormControl(
        null,
        requiredIfValidator(() => !this.diagnosisControls.pregnancyLength.value)
      ),
      motherSmoked: new UntypedFormControl(),
      motherAlcohol: new UntypedFormControl(),
      immedRelativeCleft: new UntypedFormControl(),
      pregnancyComplication: new UntypedFormControl(),
      distRelativeCleft: new UntypedFormControl(),
      birthComplication: new UntypedFormControl(),
      evaluationDate: new DateFormControl(null, {
        maxDate: () => now$,
        minDate: () => this.dateOfBirthMoment$,
        validators: [StxValidators.required]
      }),
      evaluationWeight: new UntypedFormControl(null, {
        updateOn: 'blur',
        validators: [StxValidators.required, Validators.min(1), Validators.max(200)]
      }),
      evaluationHeight: new UntypedFormControl(null, [StxValidators.required, Validators.min(1), Validators.max(200)]),
      beforeSurgery: new UntypedFormControl(null, StxValidators.required),
      previousSurgery: new UntypedFormControl(null, StxValidators.required),
      leftCleftLip: new UntypedFormControl(null, StxValidators.required),
      rightCleftLip: new UntypedFormControl(null, StxValidators.required),
      leftAlveolus: new UntypedFormControl(null, StxValidators.required),
      rightAlveolus: new UntypedFormControl(null, StxValidators.required),
      leftHardCleftPalate: new UntypedFormControl(null, StxValidators.required),
      rightHardCleftPalate: new UntypedFormControl(null, StxValidators.required),
      softCleftPalate: new UntypedFormControl(null, StxValidators.required),
      comment: new UntypedFormControl(),
      otherCleft: new UntypedFormControl(),
      heart: new UntypedFormControl(),
      urinary: new UntypedFormControl(),
      eyes: new UntypedFormControl(),
      nose: new UntypedFormControl(),
      skin: new UntypedFormControl(),
      ears: new UntypedFormControl(),
      limbs: new UntypedFormControl(),
      fingersToes: new UntypedFormControl(),
      tongue: new UntypedFormControl(),
      skull: new UntypedFormControl(),
      speech: new UntypedFormControl(),
      growth: new UntypedFormControl(),
      mandible: new UntypedFormControl(),
      mental: new UntypedFormControl(),
      allergies: new UntypedFormControl(),
      medAllergies: new UntypedFormControl(null, Validators.maxLength(1024)),
      otherAllergies: new UntypedFormControl(null, Validators.maxLength(1024)),
      otherProblems: new UntypedFormControl(null, Validators.maxLength(1024))
    };

    this.cleftFieldsFormControls = [
      this.diagnosisControls.leftCleftLip,
      this.diagnosisControls.rightCleftLip,
      this.diagnosisControls.leftAlveolus,
      this.diagnosisControls.rightAlveolus,
      this.diagnosisControls.leftHardCleftPalate,
      this.diagnosisControls.rightHardCleftPalate,
      this.diagnosisControls.softCleftPalate
    ];
    this.subSink.sink = combineLatest(this.cleftFieldsFormControls.map(control => control.valueChanges))
      .pipe(map(values => values.every(value => value === 1)))
      .subscribe(this.allCleftFieldsEqualNotCleft$);
    this.subSink.sink = this.diagnosisControls.beforeSurgery.valueChanges.subscribe(value => {
      if (!!value) {
        this.diagnosisControls.previousSurgery.enable();
      } else {
        this.diagnosisControls.previousSurgery.reset();
        this.diagnosisControls.previousSurgery.disable();
      }
    });
    this.addressControls = {
      street1: new UntypedFormControl(null, [StxValidators.required, Validators.maxLength(128)]),
      zip: new UntypedFormControl(null, [StxValidators.required, Validators.maxLength(50)]),
      city: new UntypedFormControl(null, [StxValidators.required, Validators.maxLength(128)]),
      countryId: new UntypedFormControl(null, StxValidators.required),
      stateId: new UntypedFormControl(null, StxValidators.required),
      districtId: new UntypedFormControl(
        null,
        requiredIfValidator(() => this.isDistrictRequired && districtList$.value?.length > 0)
      ),
      phone: new UntypedFormControl(null, [StxValidators.required, Validators.maxLength(30)]),
      mobile: new UntypedFormControl(null, Validators.maxLength(30))
    };

    // controls dependent on partner & tc choices
    this.partner$ = findElementFromListById(this.mainControls.partnerId, partners);
    this.treatmentCenter$ = new BehaviorSubject<TreatmentCenterDictionary | null>(null);
    this.subSink.sink = findElementFromListById(this.mainControls.treatmentCenterId, treatmentCenters).subscribe(this.treatmentCenter$);
    this.subSink.sink = this.treatmentCenter$.subscribe(treatmentCenter => {
      const countryIdControl = this.addressControls.countryId;
      const isCountryControlChangedByUser = countryIdControl.dirty;
      if (!isCountryControlChangedByUser) {
        countryIdControl.setValue(treatmentCenter?.countryId);
      }
    });
    this.country$ = findElementFromListById(this.addressControls.countryId, countries);
    this.province$ = findElementFromListById(this.addressControls.stateId, provinces);
    this.subSink.sink = this.treatmentCenter$.subscribe(tc => {
      if (!!tc && tc.countryId !== chinaCountryId) {
        this.mainControls.firstNameLoc.reset();
        this.mainControls.middleNameLoc.reset();
        this.mainControls.lastNameLoc.reset();
      }
    });

    // diagnosis controls
    this.diagnosisFormGroup = new UntypedFormGroup({
      ...this.diagnosisControls
    });
    this.subSink.sink = this.diagnosisControls.pregnancyLength.valueChanges.subscribe(value => {
      if (!!value) {
        this.diagnosisControls.pregnancyLengthUnknown.reset();
      }
      this.diagnosisControls.pregnancyLength.updateValueAndValidity({ emitEvent: false });
    });
    this.subSink.sink = this.diagnosisControls.pregnancyLengthUnknown.valueChanges.subscribe(unknown => {
      if (unknown) {
        this.diagnosisControls.pregnancyLength.reset();
      }
      this.diagnosisControls.pregnancyLength.updateValueAndValidity({ emitEvent: false });
    });

    // controls related to patient release form
    this.addressFormGroup = new UntypedFormGroup({
      ...this.addressControls
    });
    this.offlineReleaseForm = new UntypedFormControl();
    this.onlineReleaseForm = new UntypedFormControl();
    this.patientDataForReleaseFormControls = [
      this.mainControls.lastName,
      this.mainControls.middleName,
      this.mainControls.firstName,
      this.mainControls.lastNameLoc,
      this.mainControls.middleNameLoc,
      this.mainControls.firstNameLoc,
      this.mainControls.guardianLastName,
      this.mainControls.guardianFirstName,
      this.addressFormGroup
    ];
    this.patientDataForReleaseForm = new BehaviorSubject<PatientDataForReleaseForm | null>(null);
    this.subSink.sink = combineLatest(this.patientDataForReleaseFormControls.map(control => concat(of(null), control.valueChanges)))
      .pipe(
        map(
          ([
            lastName,
            middleName,
            firstName,
            lastNameLoc,
            middleNameLoc,
            firstNameLoc,
            guardianLastName,
            guardianFirstName,
            patientAddress
          ]) => {
            const address = { ...(patientAddress as PatientAddress) };
            delete address.mobile;
            return {
              lastName,
              middleName,
              firstName,
              lastNameLoc,
              middleNameLoc,
              firstNameLoc,
              guardianLastName,
              guardianFirstName,
              address
            } as PatientDataForReleaseForm;
          }
        )
      )
      .subscribe(this.patientDataForReleaseForm);

    // declare patient formGroup
    this.formGroup = new UntypedFormGroup(
      {
        ...this.mainControls,
        diagnosis: this.diagnosisFormGroup,
        address: this.addressFormGroup,
        onlineReleaseForm: this.onlineReleaseForm,
        offlineReleaseForm: this.offlineReleaseForm
      },
      {
        validators: [
          atLeastOneRequired(['onlineReleaseForm', 'offlineReleaseForm'], 'releaseFormRequired'),
          () => errorIf(this.allCleftFieldsEqualNotCleft$.value, this.allCleftFieldsEqualNotCleftError)
        ]
      }
    );
    this.data = this.data; // fires value changes listeners for default values
  }

  get hasAllCleftFieldsEqualNotCleftError(): boolean {
    const touched = this.cleftFieldsFormControls.every(control => control.touched);
    return touched && this.formGroup.hasError(this.allCleftFieldsEqualNotCleftError);
  }

  get hasReleaseFormError(): boolean {
    const touched = this.offlineReleaseForm.touched || this.onlineReleaseForm.touched;
    return touched && this.formGroup.hasError(this.releaseFormRequiredError);
  }

  get isChineseTcSelected(): boolean {
    return this.treatmentCenter$.value?.countryId === chinaCountryId;
  }

  get isIndianTcSelected(): boolean {
    return this.treatmentCenter$.value?.countryId === indiaCountryId;
  }

  get dateOfBirthMoment$(): Observable<moment.Moment> {
    return validValue$(this.mainControls.dateOfBirth)
      .pipe(map(value => moment(value)))
      .pipe(distinctUntilChanged());
  }

  get gender$(): Observable<string> {
    return validWithInitialValue$(this.mainControls.gender, this.subSink);
  }

  get evaluationDateMoment$(): Observable<moment.Moment> {
    return validValue$(this.diagnosisControls.evaluationDate)
      .pipe(map(value => moment(value)))
      .pipe(distinctUntilChanged());
  }

  get evaluationWeight$(): Observable<number> {
    return validValue$(this.diagnosisControls.evaluationWeight).pipe(distinctUntilChanged());
  }

  public get data(): Patient {
    const data = {
      ...this.formGroup.value
    };
    const releaseFormFileIsAlreadySaved = !!data['offlineReleaseForm']?.id;
    if (releaseFormFileIsAlreadySaved) {
      delete data['offlineReleaseForm'];
    }
    return data;
  }

  public set data(data: Patient | null) {
    if (data != null) {
      this.formGroup.patchValue(data);
      this.overrideDefaultValuesWithPatchedValues(data);
    } else {
      this.resetFormGroupAndSetDefaultValues();
    }
    this.offlineReleaseForm.setValue(findMediaUrl(data, ParentOrderName.RELEASE_FORM));
  }

  private resetFormGroupAndSetDefaultValues() {
    this.formGroup.reset({
      gender: 'M',
      dateOfBirthEstimated: false,
      guardianRelationship: 1,
      knowAboutSt: 1,
      diagnosis: {
        pregnancyLengthUnknown: true,
        motherSmoked: yesNoDontKnow[2].value,
        motherAlcohol: yesNoDontKnow[2].value,
        immedRelativeCleft: yesNoDontKnow[2].value,
        pregnancyComplication: yesNoDontKnow[2].value,
        distRelativeCleft: yesNoDontKnow[2].value,
        birthComplication: yesNoDontKnow[2].value,
        beforeSurgery: false,
        otherCleft: yesNoDontKnow[2].value,
        allergies: yesNoDontKnow[2].value
      }
    });
  }

  private overrideDefaultValuesWithPatchedValues(data: Patient | null) {
    // these fields are calculated based on other fields so we have to set them again
    this.overrideControlValueAndMarkAsDirty(this.addressControls.countryId, data?.address?.countryId);
    this.overrideControlValueAndMarkAsDirty(this.mainControls.race, data?.race);
    this.overrideControlValueAndMarkAsDirty(this.diagnosisControls.pregnancyLengthUnknown, !data?.diagnosis?.pregnancyLength);
  }

  private overrideControlValueAndMarkAsDirty(control: AbstractControl, value: unknown) {
    if (!!value || isBoolean(value)) {
      control.setValue(value);
      control.markAsDirty();
    }
  }
}
