import { UntypedFormGroup } from '@angular/forms';
import { ValidationErrors } from '../models/validation-errors.model';

export function getFirstErrorMessage(errors: ValidationErrors): string {
  return Object.values(errors || {}).find(error => error.message)?.message || '';
}

export function showStandardError(
  formGroup: UntypedFormGroup,
  formControlName?: string,
  formGroupErrorNames?: Array<string>,
  dependentFormControlNames?: Array<string>
) {
  if (!formControlName && !formGroupErrorNames) {
    return false;
  }
  const fieldError =
    formControlName && formGroup.get(formControlName) && formGroup.get(formControlName).touched && formGroup.get(formControlName).invalid;
  const formGroupError =
    formGroupErrorNames && formGroupErrorNames.some(formGroupErrorName => formGroup.getError(formGroupErrorName)) && formGroup.touched;
  const dependentControlsTouched = dependentFormControlNames
    ? dependentFormControlNames
        .map(controlName => {
          if (formGroup.get(controlName)) {
            return formGroup.get(controlName).touched;
          }
        })
        .some(value => value)
    : true;
  return fieldError || (formGroupError && dependentControlsTouched);
}

export function getFirstInvalidField(nativeElement: Element): Element | null {
  return nativeElement.querySelector('.mat-mdc-form-field-error');
}
