<div [formGroup]="formGroupReference">
  <ng-container *ngFor="let controlKey of orgTreeSetup.keys()">
    <ng-container *ngIf="isShown(controlKey)">
      <div class="form-element-group-column">
        <label for="{{ controlKey }}" class="form-element-label">{{ orgTreeSetup.get(controlKey).name | translate }}</label>
        <stx-chips
          [class.display-none]="formGroupReference.get(orgTreeSetup.get(controlKey).formControlName)?.value?.length === 0"
          [itemsList]="formGroupReference.get(orgTreeSetup.get(controlKey).formControlName).value"
          [ariaLabel]="orgTreeSetup.get(controlKey).name | translate"
          [removable]="orgTreeSetup.get(controlKey).data.length > 1"
          (removeCallback)="onRemoveChip($event, formGroupReference.get(orgTreeSetup.get(controlKey).formControlName))"
        ></stx-chips>

        <stx-autocomplete-multi-select
          [formControlName]="orgTreeSetup.get(controlKey).formControlName"
          [itemList]="orgTreeSetup.get(controlKey).data"
          [id]="controlKey"
        ></stx-autocomplete-multi-select>
      </div>
    </ng-container>
  </ng-container>
</div>
