import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SearchFilterService } from '../../services/search-filter.service';
import { SearchQueryCharactersLimitEnum } from '@src/app/features/search/enums/searchQueryCharactersLimit.enum';

@Component({
  selector: 'stx-search-filter-container',
  templateUrl: './search-filter-container.component.html',
  styleUrls: ['./search-filter-container.component.scss']
})
export class SearchFilterContainerComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() maxCharactersLimitForQuery: number = SearchQueryCharactersLimitEnum.LONG_QUERY;
  @Input() config: Partial<SearchFilterContainerConfig> = {
    isCsvAvailable: false,
    routerLink: null
  };
  searchBarForm: UntypedFormGroup;
  @Input() isExpanded = false;
  @ViewChild('searchInput') searchInput: ElementRef;
  constructor(private formBuilder: UntypedFormBuilder, private searchFilterService: SearchFilterService) {
    super();
    this.searchFilterService.config.searchTermGetter = () => {
      return this.searchTerm;
    };
  }

  ngAfterViewInit(): void {
    this.searchInput.nativeElement.focus();
  }

  ngOnInit() {
    this.initializeForm();

    this.subSink.sink = this.searchFilterService.searchEvent$.subscribe(() => (this.isExpanded = false));
  }

  private initializeForm(): void {
    this.searchBarForm = this.formBuilder.group({
      searchBar: []
    });
  }

  get searchTerm(): string {
    return this.searchBarForm.controls.searchBar.value;
  }

  onSearchButtonClicked() {
    this.searchFilterService.initiateSearch();
  }

  toggleFilters(): void {
    this.isExpanded = !this.isExpanded;
  }

  onCsvExportClicked(): void {
    this.searchFilterService.initiateCsvDownload();
  }
}

export interface SearchFilterContainerConfig {
  isCsvAvailable: boolean;
  routerLink: Partial<SearchFilterContainerRouterLink>;
}

export interface SearchFilterContainerRouterLink {
  icon: string;
  text: string;
  url: string;
  permission: PermissionEnum;
}
