import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { Component, Input, ViewChild } from '@angular/core';
import { TreatmentCenterDictionary } from '@shared/models/treatment-center.model';
import { OrthoSharedGeneralInfoComponent } from '@src/app/features/ortho/components/shared/ortho-shared-general-info/ortho-shared-general-info.component';
import { OrthoFormType } from '@src/app/features/ortho/utils/ortho-form-type';
import { OrthoStage } from '@src/app/features/ortho/utils/ortho-stage';
import { yesNoShort } from '@utils/form.utils';

@Component({
  selector: 'stx-ortho-general-mid-assessment-info',
  templateUrl: './ortho-general-mid-assessment-info.component.html'
})
export class OrthoGeneralMidAssessmentInfoComponent extends BaseFormElementComponent {
  @Input() minEvaluationDate: moment.Moment;
  @Input() maxEvaluationDate: moment.Moment;
  @Input() tcList: TreatmentCenterDictionary[];
  @Input() orthoFormType: OrthoFormType;
  @ViewChild('sharedGeneralInfo') sharedGeneralInfo: OrthoSharedGeneralInfoComponent;
  readonly yesNo = yesNoShort;
  readonly OrthoStage = OrthoStage;

  constructor() {
    super();
  }
}
