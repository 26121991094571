import * as moment from 'moment';
import { getToday } from 'src/app/utils/date.utils';

export interface DateRange {
  from: moment.Moment;
  to: moment.Moment;
}

export class Range {
  halfYearFromToday() {
    const today = getToday();
    const halfYearBack = today.clone().subtract(6, 'months');

    return {
      from: today,
      to: halfYearBack
    };
  }

  lastMonth(): DateRange {
    const lastMonth = getToday().subtract(1, 'month');

    return {
      from: lastMonth.clone().startOf('month'),
      to: lastMonth.clone().endOf('month')
    };
  }

  thisMonthUpToNow(): DateRange {
    const now = getToday();

    return {
      from: now.clone().startOf('month'),
      to: now
    };
  }

  today(): DateRange {
    const now = getToday();

    return {
      from: now,
      to: now
    };
  }
}
