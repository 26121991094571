<div [ngClass]="{ 'm-t-24 m-b-15': !noMargin }">
  <div class="action-buttons-container align-left" [ngClass]="showSecondary ? 'action-buttons-changing-container' : 'gap-none'">
    <stx-submit-button
      *ngIf="showPrimary"
      class="block"
      (action)="primaryAction?.emit()"
      [asyncAction]="asyncPrimaryAction"
      [buttonDisabled]="primaryDisabled"
    >
      <span *ngIf="primaryText">{{ primaryText }}</span>
      <span *ngIf="!primaryText">{{ 'submit' | translate }}</span>
    </stx-submit-button>
    <div *ngIf="showSecondary" [ngClass]="{ 'p-r-0': !isRtlLanguage }">
      <button
        data-testid="button-action-button"
        class="block text-uppercase"
        mat-stroked-button
        color="primary"
        (click)="handleSecondaryButtonClick()"
        [disabled]="buttonDisablerService.hasActiveTasks || secondaryActionInProgress"
      >
        <span *ngIf="secondaryText">{{ secondaryText }}</span>
        <span *ngIf="!secondaryText">{{ 'save_submit' | translate }}</span>
      </button>
    </div>
  </div>
</div>
