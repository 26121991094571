import { ChangeDetectorRef, Component } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { ActivatedRoute } from '@angular/router';
import { TreatmentCenterService } from '../../treatment-center.service';
import { StatisticsPlot, StatisticsTable } from '@shared/modules/statistics/statistics.model';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  templateUrl: './treatment-center-details-page.component.html'
})
export class TreatmentCenterDetailsPageComponent extends BaseComponent {
  private _treatmentCenterId: number;

  statisticsTable: StatisticsTable;
  statisticsPlots: StatisticsPlot;

  constructor(
    route: ActivatedRoute,
    private readonly treatmentCenterService: TreatmentCenterService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
    this.subSink.sink = route.params.subscribe(params => (this.treatmentCenterId = params.id));
  }

  get treatmentCenterId(): number {
    return this._treatmentCenterId;
  }

  set treatmentCenterId(id: number) {
    if (this._treatmentCenterId !== id) {
      this._treatmentCenterId = id;
      this.loadStatistics(id);
      this.loadStatisticsPlots(id);
    }
  }

  loadStatistics(partnerId: number): void {
    this.wsHelperService.callWithSpinner(this.treatmentCenterService.getTCStatistics(partnerId)).subscribe(stats => {
      this.statisticsTable = stats;
      this.changeDetectorRef.markForCheck();
    });
  }

  loadStatisticsPlots(partnerId: number): void {
    this.wsHelperService.callWithSpinner(this.treatmentCenterService.getTCStatisticsPlot(partnerId)).subscribe(statisticsPlots => {
      this.statisticsPlots = statisticsPlots;
      this.changeDetectorRef.markForCheck();
    });
  }
}
