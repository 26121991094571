import { Component, Input } from '@angular/core';
import { resourcesNavigationSubItems } from 'src/app/shared/components/navigation/nav.utils';

@Component({
  selector: 'stx-resources-navigation',
  templateUrl: './resources-navigation.component.html',
  styleUrls: ['./resources-navigation.component.scss']
})
export class ResourcesNavigationComponent {
  @Input() selectedItemId: number;

  public navigationItemToDisplay = resourcesNavigationSubItems;
}
