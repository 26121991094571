import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { _isNumberValue } from '@angular/cdk/coercion';
import { langToMomentLocaleMap } from '@utils/language.utils';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    if (!_isNumberValue(value)) {
      return value;
    }

    if (value === 0) {
      return 0;
    }
    if (!value) {
      return;
    }

    const locale = langToMomentLocaleMap.get(this.translateService.currentLang) ?? 'en';
    return new Intl.NumberFormat(locale).format(<number>value);
  }
}
