import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { DateAdapter } from '@angular/material/core';
import { Moment } from 'moment';
import { langToMomentLocaleMap } from '@utils/language.utils';
import { Subscription } from 'rxjs';

@Injectable()
export class DatepickerI18nService {
  constructor(private translateService: TranslateService, private dateAdapter: DateAdapter<Moment>) {}

  followLocaleUpdatesAndGetSubscription(): Subscription {
    const momentLocale = langToMomentLocaleMap.get(this.translateService.currentLang);
    if (momentLocale) {
      this.dateAdapter.setLocale(momentLocale);
    }
    return this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.dateAdapter.setLocale(langToMomentLocaleMap.get(langChangeEvent.lang));
    });
  }
}
