import { Directive, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Directive({
  selector: '[stxFocusElement]'
})
export class FocusElementDirective implements OnDestroy {
  private subSink = new SubSink();

  /**
  If present, query for the child element
  */
  @Input() stxFocusElement: string | undefined;

  @Input() set focusWhen(event$: Observable<boolean>) {
    if (event$) {
      this.subSink.sink = event$.pipe(filter(value => !!value)).subscribe(() => this.focusElement());
    }
  }

  constructor(private el: ElementRef<HTMLElement>, private ngZone: NgZone) {}
  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  private focusElement() {
    // runOutsideAngular to avoid change detection, setTimeout due to browser rendering order
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        (document.activeElement as HTMLElement)?.blur();
        if (this.stxFocusElement) {
          const focusElement = this.el.nativeElement.querySelector(this.stxFocusElement) as HTMLElement;
          focusElement?.focus();
        } else {
          this.el.nativeElement.focus();
        }
      });
    });
  }
}
