import { NavigationSubTabEnum } from '@shared/components/navigation/model/nav-item-enum.enum';
import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { FileExtension, Mime } from '@/src/app/utils/file.utils';
import { Component } from '@angular/core';
import { Resource, ResourceSection } from '../../models/resource.model';
import { nutritionMedicalFormsSection } from '../medical-forms-page/medical-forms-page.component';
import { feedingBrochure, feedingVideo } from '../resources-for-families-page/resources-for-families-page.component';
import { resourcesRoute, whoResourcesRoute } from '@utils/routing.utils';

@Component({
  selector: 'stx-nutrition-resources-page',
  templateUrl: './nutrition-resources-page.component.html'
})
export class NutritionResourcesPageComponent {
  readonly PermissionEnum = PermissionEnum;
  readonly NavigationSubTabEnum = NavigationSubTabEnum;
  readonly source = nutritionResources;
  readonly resourcesRoute = resourcesRoute;
  readonly whoRoute = whoResourcesRoute;
}
export const feedingPractices: Resource = {
  label: 'nutrition_resources.feeding_practices_delivery',
  resourceName: 'Feeding_Practices-Good_Counseling_Skills_',
  translationList: [['bn', 'en', 'es', 'fr', 'hi', 'id', 'pt', 'vi', 'cn']]
};

export const antropometrySection: ResourceSection = {
  resources: [
    {
      label: 'resources.anthropometry.measuring_techniques',
      urlList: [
        {
          label: 'English',
          labelUrl: 'https://www.youtube.com/playlist?list=PLrEa6indHB6byJgek0w68R5qMx1nYqHrj'
        },
        {
          label: 'Español',
          labelUrl: 'https://www.youtube.com/playlist?list=PLrEa6indHB6bmj9VI28kb3L8VTWRshX0S'
        }
      ],
      labelSuffixIconName: 'videocam',
      isSeparated: true
    },
    {
      label: 'resources.anthropometry.younger_than_two_years',
      resourceName: 'Measuring_Weight_Length_Baby_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'ru', 'vi', 'cn']]
    },
    {
      label: 'resources.anthropometry.able_to_stand',
      resourceName: 'Measuring_Weight_Height_Child_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'ru', 'vi', 'cn']]
    },
    {
      label: 'resources.anthropometry.muac',
      resourceName: 'Measuring_MUAC_Head_Cirumference_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'ru', 'vi', 'cn']]
    },
    {
      label: 'resources.anthropometry.spoon',
      resourceName: 'SPOON_Lesson_7-Understanding_Growth_Charts_',
      translationList: [['en', 'es', 'fr', 'id', 'pt']]
    }
  ]
};

export const shortTalkForParents: Resource = {
  label: 'nutrition_resources.short_talks',
  resourceName: 'Feeding_Practices-How_to_Organize_Feeding_Talk_for_Parents_',
  translationList: [['bn', 'en', 'fr', 'hi', 'id', 'vi', 'cn']],
  isSeparated: true
};

const nutritionResources: readonly ResourceSection[] = [
  antropometrySection,
  {
    sectionTitle: 'medical_forms.title',
    resources: [
      {
        ...nutritionMedicalFormsSection.resources[0],
        labelSubtitle: 'nutrition_management.infant'
      },
      {
        ...nutritionMedicalFormsSection.resources[1],
        labelSubtitle: 'nutrition_management.toddler'
      },
      {
        ...nutritionMedicalFormsSection.resources[2],
        labelSubtitle: 'nutrition_management.adolescent'
      },
      {
        ...nutritionMedicalFormsSection.resources[3],
        labelSubtitle: 'nutrition_management.adult'
      }
    ]
  },
  {
    sectionTitle: 'resources_for_families.title',
    resources: [feedingBrochure, feedingVideo],
    isSeparated: true
  },
  {
    sectionTitle: 'nutrition_resources.families',
    resources: [
      feedingPractices,
      shortTalkForParents,
      {
        label: 'nutrition_resources.cup_feed_cleft',
        resourceName: 'Feeding_Practices-How_to_cup_feed_a_baby_with_cleft_',
        translationList: [['en', 'es', 'fr']]
      },
      {
        label: 'nutrition_resources.commercial_formula',
        resourceName: 'Feeding_Practices-How_to_prepared_commercial_formula_',
        translationList: [['en', 'es', 'fr']]
      },
      {
        label: 'nutrition_resources.evaporated_milk',
        resourceName: 'Feeding_Practices-How_to_prepare_evaporated_milk_',
        translationList: [['en', 'es', 'fr']]
      },
      {
        label: 'nutrition_resources.fresh_milk',
        resourceName: 'Feeding_Practices-How_to_prepare_fresh_milk_',
        translationList: [['en', 'es', 'fr']]
      },
      {
        label: 'nutrition_resources.full_cream_milk',
        resourceName: 'Feeding_Practices-How_to_prepare_powdered_full_cream_milk_',
        translationList: [['en', 'es', 'fr']]
      },
      {
        label: 'nutrition_resources.animal_milk_recipes',
        resourceName: 'Feeding-Practices-Recipes_for_modified_animal_milk_',
        translationList: [['en', 'fr']]
      }
    ]
  }
];
