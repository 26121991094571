import { FormStatus, TreatmentStatusLabel } from '@shared/enums/form-status.enum';
import { NoticeType } from '../../../notice/notice.model';

export interface StatusConfigData {
  label: string;
  icon: string;
  cssStyleExt?: string;
}

export interface StatusBarConfigData {
  noticeType: NoticeType;
  statusConfig: StatusConfigData;
}

export const getStatusLabel = treatmentStatus => TreatmentStatusLabel.get(treatmentStatus);

export const statusBarConfig: Map<FormStatus, StatusBarConfigData> = new Map<FormStatus, StatusBarConfigData>([
  [FormStatus.IN_PROGRESS, { noticeType: 'warn', statusConfig: { label: getStatusLabel(FormStatus.IN_PROGRESS), icon: 'pause' } }],
  [
    FormStatus.SUBMITTED,
    {
      noticeType: 'success',
      statusConfig: { label: getStatusLabel(FormStatus.SUBMITTED), icon: 'stop' }
    }
  ],
  [
    FormStatus.REJECTED,
    {
      noticeType: 'error',
      statusConfig: { label: getStatusLabel(FormStatus.REJECTED), icon: 'clear', cssStyleExt: 'status-icon-red' }
    }
  ],
  [
    FormStatus.ACCEPTED,
    {
      noticeType: 'success',
      statusConfig: { label: getStatusLabel(FormStatus.ACCEPTED), icon: 'check' }
    }
  ],
  [
    FormStatus.EXCEEDS_QUOTA,
    {
      noticeType: 'error',
      statusConfig: { label: getStatusLabel(FormStatus.EXCEEDS_QUOTA), icon: 'clear', cssStyleExt: 'status-icon-grey' }
    }
  ],
  [FormStatus.DISCONTINUED, { noticeType: 'warn', statusConfig: { label: getStatusLabel(FormStatus.DISCONTINUED), icon: 'block' } }]
]);

export enum DialogMode {
  UNLOCK,
  DELETE_TREATMENT,
  DELETE_PATIENT,
  CLONE,
  UNLINK
}
